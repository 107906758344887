import {Card, Form, FormInstance, Select, Switch} from 'antd';
import React, {RefObject} from "react";
import IFieldOptions from "../../../../../../../model/interface/form/elementOptions/IFieldOptions";
import IBaseProps from "../../../../../../../model/interface/IBaseProps";
import FormElementField from "../../FormElementField";
import IField from "../../../../../../../model/interface/dataStorage/IField";
import {IRestCompanyStructureAccept} from "../../../../../../../model/service/company/CompanyStructureService";

export interface ICompanyStructureEditorOptions {
    companyStructureRoot?: string | string[]
    companyStructureOnlyDirectChildren?: boolean
    companyStructureAccepts?: IRestCompanyStructureAccept
    companyStructureMultiple?: boolean
    companyStructureSearch?: string
    companyStructureOrder?: "ASC" | "DESC"
    companyReturnNode?: boolean
    companyStructureMode?: 'tree' | 'list'
}

interface IProps extends IBaseProps {
    onChange: (options: IFieldOptions) => void
    options: ICompanyStructureEditorOptions
    formRef: RefObject<FormInstance>
    field: IField
}

const types = [
    {
        value: "company",
        label: 'Společnost'
    },
    {
        value: "unit",
        label: 'Jednotka'
    },
    {
        value: "job",
        label: 'Pozice'
    },
    {
        value: "employee",
        label: 'Zaměstnanec'
    }
]

interface IState {

}

class FormFieldCompanyStructureEditor extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {};
    }

    render() {
        const {history, match, field} = this.props
        return (
            <Card>
                <Form.Item name={'companyStructureOnlyDirectChildren'} label={'Pouze přímé děti'}
                           valuePropName={'checked'}>
                    <Switch/>
                </Form.Item>
                <Form.Item name={'companyStructureAccepts'} label={'Typ'}>
                    <Select>
                        {types.map(type => (
                            <Select.Option key={type.value} value={type.value}>{type.label}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item name={'companyStructureMode'} label={'Mod'}>
                    <Select>
                        <Select.Option key={'tree'} value={'tree'}>Strom</Select.Option>
                        <Select.Option key={'list'} value={'list'}>Seznam</Select.Option>
                    </Select>
                </Form.Item>
                <FormElementField
                    field={field}
                    id={"companyStructureRoot"}
                    customFieldName={'companyStructureRoot'}
                    label={"Omezit na strom organigramu"}
                    type={"companyStructure"}
                    preview={true}
                    match={match}
                    functions={{} as any}
                    history={history}
                    options={{
                        type: "companyStructure",
                        label: "",
                        companyStructureAccepts: "node",
                        companyStructureMultiple: true
                    }}
                />
            </Card>
        )
    }
}


export default FormFieldCompanyStructureEditor