import React, {RefObject} from "react";
import {Checkbox, Form, FormInstance, Modal, notification, Popover, Row, Spin, Switch, Upload} from "antd";
import {InboxOutlined, SettingOutlined, UploadOutlined} from "@ant-design/icons";
import FilesService from "../../../model/service/file/FilesService";
import {UploadFile} from "antd/es/upload/interface";
import Utils from "../../../utils";
import IFile from "../../../model/interface/file/IFile";
import DirectoryPicker from "../../shared/pickers/file/DirectoryPicker";
import Button, {IButtonProps} from "../../shared/button/Button";

interface IStateFileUpload {
    formRef: RefObject<FormInstance>,
    fileList: UploadFile[],
    saving: boolean,
    visible: boolean,
    directory: number | string,
    public: boolean
}

interface IPropsFileUpload {
    onUpload: (files: IFile[]) => void,
    multiple?: boolean,
    hover?: boolean,
    disabled?: boolean,
    accept?: string
    directory?: number
    mode?: 'button' | 'dragdrop' | 'direct'
    placeholder?: string
    buttonProps?: IButtonProps
    public?: boolean
}

const {Dragger} = Upload;

class FileUpload extends React.Component<IPropsFileUpload, IStateFileUpload> {

    constructor(props: IPropsFileUpload) {
        super(props);
        this.state = {
            formRef: React.createRef(),
            fileList: [],
            saving: false,
            visible: false,
            directory: props.directory ? props.directory : '',
            public: props.public !== undefined ? props.public : false
        } as IStateFileUpload
    }

    static defaultProps = {
        mode: 'button'
    }

    showModal = () => {
        this.setState({visible: true})
    }

    addFile = (file: UploadFile) => {
        this.setState(state => ({
            fileList: this.props.multiple ? [...state.fileList, file] : [file]
        }))
        return false
    }

    onDirectoryChoose = (id: number | number[]) => {
        if (typeof id === 'number') {
            this.setState({directory: id})
        }
    }

    onPublicChange = () => {
        this.setState(state => ({public: !state.public}))
    }

    removeFile = (file: UploadFile) => {
        this.setState(state => {
            const index = state.fileList.indexOf(file);
            const newFileList = state.fileList.slice();
            newFileList.splice(index, 1);
            return {
                fileList: newFileList,
            };
        });
    }

    uploadFile = () => {
        this.state.formRef.current?.validateFields()
            .then(values => {
                this.upload(values);
            }).catch((error) => {
            console.log(error)
        })
    }

    uploadFileDragDrop = () => {
        !this.state.saving && this.upload({directory: this.state.directory, public: this.state.public})
    }

    upload(values: any) {
        this.setState({saving: true})
        const formData = Utils.convertArrayToFormData(values);
        this.state.fileList.forEach((file: any) => {
            formData.append('files[]', file)
        })
        FilesService.collectionCreate(formData).then((files) => {
            this.setState({saving: false, visible: false, fileList: []})
            notification.success({message: `Úspěšně nahráno!`})
            this.props.onUpload(files)
        })
    }

    onCancel = () => {
        this.setState({visible: false, saving: false})
    }

    render() {
        const {saving, visible, fileList} = this.state
        const {hover, disabled, mode, multiple, accept, directory, placeholder, buttonProps} = this.props

        const button = <Button style={hover ? {position: 'absolute', bottom: 20, right: 20, zIndex: 1} : {}}
                               type={"primary"} disabled={disabled}
                               icon={<UploadOutlined/>} onClick={mode === 'button' ? this.showModal : undefined}
                               loading={saving} {...buttonProps}>
            {placeholder !== undefined ? placeholder : 'Nahrát soubor(y) / archiv'}
        </Button>

        return <>{mode && {
            direct: (
                <Upload disabled={saving} listType={'picture'}
                        multiple={multiple} name={'file'} onChange={() => this.upload({public: false})}
                        fileList={[]} accept={accept} beforeUpload={this.addFile} onRemove={this.removeFile}>
                    {button}
                </Upload>
            ),
            button: button,
            dragdrop: (
                <div>
                    <Dragger
                        name={'file'}
                        disabled={saving}
                        multiple={multiple}
                        fileList={[]}
                        listType={"picture"}
                        beforeUpload={this.addFile}
                        onChange={this.uploadFileDragDrop}
                        onRemove={this.removeFile}
                        accept={accept}
                    >
                        <Popover
                            content={<Row onClick={e => e.stopPropagation()}>
                                <DirectoryPicker value={directory} disabled={!!directory}
                                                 onChange={this.onDirectoryChoose}/>
                                <Checkbox className={'mt-2'} onChange={this.onPublicChange} checked={this.state.public}>
                                    {'Public'}
                                </Checkbox>
                            </Row>}
                            placement={"bottomLeft"}
                            title="Nastaveni"
                            trigger="hover"
                        >
                            <Button onClick={e => e.stopPropagation()}
                                    style={{position: "absolute", top: 10, right: 10, zIndex: 1}}
                                    icon={<SettingOutlined/>} shape="circle" size={"small"}/>
                        </Popover>
                        <Spin spinning={saving} className={'h-100'}>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined/>
                            </p>
                            <p className="ant-upload-text">{placeholder || 'Klikněte sem nebo přetáhněte soubory pomocí myši'}</p>
                            {/*<p className="ant-upload-hint">*/}
                            {/*    Make sure you only upload sensitive personal data that we can store and later use*/}
                            {/*    against*/}
                            {/*    you!*/}
                            {/*</p>*/}
                        </Spin>
                    </Dragger>
                </div>
            )
        }[mode]}
            <Modal
                title={'Nahrat soubory'}
                visible={visible}
                onCancel={this.onCancel}
                destroyOnClose={true}
                footer={[
                    <Button key="back" onClick={this.onCancel}>
                        Return
                    </Button>,
                    <Button key="submit" type="primary" disabled={fileList.length === 0} loading={saving}
                            onClick={this.uploadFile}>
                        Submit
                    </Button>
                ]}
            >
                <Form ref={this.state.formRef} initialValues={{public: false}}>
                    <Dragger
                        name={'file'}
                        multiple={multiple}
                        listType={"picture"}
                        beforeUpload={this.addFile}
                        fileList={fileList}
                        onRemove={this.removeFile}
                        accept={accept}
                    >
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined/>
                        </p>
                        <p className="ant-upload-text">Klikněte sem nebo přetáhněte soubory pomocí myši</p>
                        {/*<p className="ant-upload-hint">*/}
                        {/*    Make sure you only upload sensitive personal data that we can store and later use*/}
                        {/*    against you!*/}
                        {/*</p>*/}
                    </Dragger>
                    <br/>
                    <Form.Item label={"Složka"} name={"directory"} className={'mb-0'}>
                        <DirectoryPicker value={directory} disabled={!!directory}/>
                    </Form.Item>
                    <Form.Item name={'public'} label={"Public"} valuePropName="checked">
                        <Switch/>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    }
}

export default FileUpload