import * as Icon from "@ant-design/icons";
import React from "react";

export const antIconsClasses: { [name: string]: any } = {
    AccountBookFilled: Icon.AccountBookFilled,
    AccountBookOutlined: Icon.AccountBookOutlined,
    AccountBookTwoTone: Icon.AccountBookTwoTone,
    AimOutlined: Icon.AimOutlined,
    AlertFilled: Icon.AlertFilled,
    AlertOutlined: Icon.AlertOutlined,
    AlertTwoTone: Icon.AlertTwoTone,
    AlibabaOutlined: Icon.AlibabaOutlined,
    AlignCenterOutlined: Icon.AlignCenterOutlined,
    AlignLeftOutlined: Icon.AlignLeftOutlined,
    AlignRightOutlined: Icon.AlignRightOutlined,
    AlipayCircleFilled: Icon.AlipayCircleFilled,
    AlipayCircleOutlined: Icon.AlipayCircleOutlined,
    AlipayOutlined: Icon.AlipayOutlined,
    AlipaySquareFilled: Icon.AlipaySquareFilled,
    AliwangwangFilled: Icon.AliwangwangFilled,
    AliwangwangOutlined: Icon.AliwangwangOutlined,
    AliyunOutlined: Icon.AliyunOutlined,
    AmazonCircleFilled: Icon.AmazonCircleFilled,
    AmazonOutlined: Icon.AmazonOutlined,
    AmazonSquareFilled: Icon.AmazonSquareFilled,
    AndroidFilled: Icon.AndroidFilled,
    AndroidOutlined: Icon.AndroidOutlined,
    AntCloudOutlined: Icon.AntCloudOutlined,
    AntDesignOutlined: Icon.AntDesignOutlined,
    ApartmentOutlined: Icon.ApartmentOutlined,
    ApiFilled: Icon.ApiFilled,
    ApiOutlined: Icon.ApiOutlined,
    ApiTwoTone: Icon.ApiTwoTone,
    AppleFilled: Icon.AppleFilled,
    AppleOutlined: Icon.AppleOutlined,
    AppstoreAddOutlined: Icon.AppstoreAddOutlined,
    AppstoreFilled: Icon.AppstoreFilled,
    AppstoreOutlined: Icon.AppstoreOutlined,
    AppstoreTwoTone: Icon.AppstoreTwoTone,
    AreaChartOutlined: Icon.AreaChartOutlined,
    ArrowDownOutlined: Icon.ArrowDownOutlined,
    ArrowLeftOutlined: Icon.ArrowLeftOutlined,
    ArrowRightOutlined: Icon.ArrowRightOutlined,
    ArrowUpOutlined: Icon.ArrowUpOutlined,
    ArrowsAltOutlined: Icon.ArrowsAltOutlined,
    AudioFilled: Icon.AudioFilled,
    AudioMutedOutlined: Icon.AudioMutedOutlined,
    AudioOutlined: Icon.AudioOutlined,
    AudioTwoTone: Icon.AudioTwoTone,
    AuditOutlined: Icon.AuditOutlined,
    BackwardFilled: Icon.BackwardFilled,
    BackwardOutlined: Icon.BackwardOutlined,
    BankFilled: Icon.BankFilled,
    BankOutlined: Icon.BankOutlined,
    BankTwoTone: Icon.BankTwoTone,
    BarChartOutlined: Icon.BarChartOutlined,
    BarcodeOutlined: Icon.BarcodeOutlined,
    BarsOutlined: Icon.BarsOutlined,
    BehanceCircleFilled: Icon.BehanceCircleFilled,
    BehanceOutlined: Icon.BehanceOutlined,
    BehanceSquareFilled: Icon.BehanceSquareFilled,
    BehanceSquareOutlined: Icon.BehanceSquareOutlined,
    BellFilled: Icon.BellFilled,
    BellOutlined: Icon.BellOutlined,
    BellTwoTone: Icon.BellTwoTone,
    BgColorsOutlined: Icon.BgColorsOutlined,
    BlockOutlined: Icon.BlockOutlined,
    BoldOutlined: Icon.BoldOutlined,
    BookFilled: Icon.BookFilled,
    BookOutlined: Icon.BookOutlined,
    BookTwoTone: Icon.BookTwoTone,
    BorderBottomOutlined: Icon.BorderBottomOutlined,
    BorderHorizontalOutlined: Icon.BorderHorizontalOutlined,
    BorderInnerOutlined: Icon.BorderInnerOutlined,
    BorderLeftOutlined: Icon.BorderLeftOutlined,
    BorderOuterOutlined: Icon.BorderOuterOutlined,
    BorderOutlined: Icon.BorderOutlined,
    BorderRightOutlined: Icon.BorderRightOutlined,
    BorderTopOutlined: Icon.BorderTopOutlined,
    BorderVerticleOutlined: Icon.BorderVerticleOutlined,
    BorderlessTableOutlined: Icon.BorderlessTableOutlined,
    BoxPlotFilled: Icon.BoxPlotFilled,
    BoxPlotOutlined: Icon.BoxPlotOutlined,
    BoxPlotTwoTone: Icon.BoxPlotTwoTone,
    BranchesOutlined: Icon.BranchesOutlined,
    BugFilled: Icon.BugFilled,
    BugOutlined: Icon.BugOutlined,
    BugTwoTone: Icon.BugTwoTone,
    BuildFilled: Icon.BuildFilled,
    BuildOutlined: Icon.BuildOutlined,
    BuildTwoTone: Icon.BuildTwoTone,
    BulbFilled: Icon.BulbFilled,
    BulbOutlined: Icon.BulbOutlined,
    BulbTwoTone: Icon.BulbTwoTone,
    CalculatorFilled: Icon.CalculatorFilled,
    CalculatorOutlined: Icon.CalculatorOutlined,
    CalculatorTwoTone: Icon.CalculatorTwoTone,
    CalendarFilled: Icon.CalendarFilled,
    CalendarOutlined: Icon.CalendarOutlined,
    CalendarTwoTone: Icon.CalendarTwoTone,
    CameraFilled: Icon.CameraFilled,
    CameraOutlined: Icon.CameraOutlined,
    CameraTwoTone: Icon.CameraTwoTone,
    CarFilled: Icon.CarFilled,
    CarOutlined: Icon.CarOutlined,
    CarTwoTone: Icon.CarTwoTone,
    CaretDownFilled: Icon.CaretDownFilled,
    CaretDownOutlined: Icon.CaretDownOutlined,
    CaretLeftFilled: Icon.CaretLeftFilled,
    CaretLeftOutlined: Icon.CaretLeftOutlined,
    CaretRightFilled: Icon.CaretRightFilled,
    CaretRightOutlined: Icon.CaretRightOutlined,
    CaretUpFilled: Icon.CaretUpFilled,
    CaretUpOutlined: Icon.CaretUpOutlined,
    CarryOutFilled: Icon.CarryOutFilled,
    CarryOutOutlined: Icon.CarryOutOutlined,
    CarryOutTwoTone: Icon.CarryOutTwoTone,
    CheckCircleFilled: Icon.CheckCircleFilled,
    CheckCircleOutlined: Icon.CheckCircleOutlined,
    CheckCircleTwoTone: Icon.CheckCircleTwoTone,
    CheckOutlined: Icon.CheckOutlined,
    CheckSquareFilled: Icon.CheckSquareFilled,
    CheckSquareOutlined: Icon.CheckSquareOutlined,
    CheckSquareTwoTone: Icon.CheckSquareTwoTone,
    ChromeFilled: Icon.ChromeFilled,
    ChromeOutlined: Icon.ChromeOutlined,
    CiCircleFilled: Icon.CiCircleFilled,
    CiCircleOutlined: Icon.CiCircleOutlined,
    CiCircleTwoTone: Icon.CiCircleTwoTone,
    CiOutlined: Icon.CiOutlined,
    CiTwoTone: Icon.CiTwoTone,
    ClearOutlined: Icon.ClearOutlined,
    ClockCircleFilled: Icon.ClockCircleFilled,
    ClockCircleOutlined: Icon.ClockCircleOutlined,
    ClockCircleTwoTone: Icon.ClockCircleTwoTone,
    CloseCircleFilled: Icon.CloseCircleFilled,
    CloseCircleOutlined: Icon.CloseCircleOutlined,
    CloseCircleTwoTone: Icon.CloseCircleTwoTone,
    CloseOutlined: Icon.CloseOutlined,
    CloseSquareFilled: Icon.CloseSquareFilled,
    CloseSquareOutlined: Icon.CloseSquareOutlined,
    CloseSquareTwoTone: Icon.CloseSquareTwoTone,
    CloudDownloadOutlined: Icon.CloudDownloadOutlined,
    CloudFilled: Icon.CloudFilled,
    CloudOutlined: Icon.CloudOutlined,
    CloudServerOutlined: Icon.CloudServerOutlined,
    CloudSyncOutlined: Icon.CloudSyncOutlined,
    CloudTwoTone: Icon.CloudTwoTone,
    CloudUploadOutlined: Icon.CloudUploadOutlined,
    ClusterOutlined: Icon.ClusterOutlined,
    CodeFilled: Icon.CodeFilled,
    CodeOutlined: Icon.CodeOutlined,
    CodeSandboxCircleFilled: Icon.CodeSandboxCircleFilled,
    CodeSandboxOutlined: Icon.CodeSandboxOutlined,
    CodeSandboxSquareFilled: Icon.CodeSandboxSquareFilled,
    CodeTwoTone: Icon.CodeTwoTone,
    CodepenCircleFilled: Icon.CodepenCircleFilled,
    CodepenCircleOutlined: Icon.CodepenCircleOutlined,
    CodepenOutlined: Icon.CodepenOutlined,
    CodepenSquareFilled: Icon.CodepenSquareFilled,
    CoffeeOutlined: Icon.CoffeeOutlined,
    ColumnHeightOutlined: Icon.ColumnHeightOutlined,
    ColumnWidthOutlined: Icon.ColumnWidthOutlined,
    CommentOutlined: Icon.CommentOutlined,
    CompassFilled: Icon.CompassFilled,
    CompassOutlined: Icon.CompassOutlined,
    CompassTwoTone: Icon.CompassTwoTone,
    CompressOutlined: Icon.CompressOutlined,
    ConsoleSqlOutlined: Icon.ConsoleSqlOutlined,
    ContactsFilled: Icon.ContactsFilled,
    ContactsOutlined: Icon.ContactsOutlined,
    ContactsTwoTone: Icon.ContactsTwoTone,
    ContainerFilled: Icon.ContainerFilled,
    ContainerOutlined: Icon.ContainerOutlined,
    ContainerTwoTone: Icon.ContainerTwoTone,
    ControlFilled: Icon.ControlFilled,
    ControlOutlined: Icon.ControlOutlined,
    ControlTwoTone: Icon.ControlTwoTone,
    CopyFilled: Icon.CopyFilled,
    CopyOutlined: Icon.CopyOutlined,
    CopyTwoTone: Icon.CopyTwoTone,
    CopyrightCircleFilled: Icon.CopyrightCircleFilled,
    CopyrightCircleOutlined: Icon.CopyrightCircleOutlined,
    CopyrightCircleTwoTone: Icon.CopyrightCircleTwoTone,
    CopyrightOutlined: Icon.CopyrightOutlined,
    CopyrightTwoTone: Icon.CopyrightTwoTone,
    CreditCardFilled: Icon.CreditCardFilled,
    CreditCardOutlined: Icon.CreditCardOutlined,
    CreditCardTwoTone: Icon.CreditCardTwoTone,
    CrownFilled: Icon.CrownFilled,
    CrownOutlined: Icon.CrownOutlined,
    CrownTwoTone: Icon.CrownTwoTone,
    CustomerServiceFilled: Icon.CustomerServiceFilled,
    CustomerServiceOutlined: Icon.CustomerServiceOutlined,
    CustomerServiceTwoTone: Icon.CustomerServiceTwoTone,
    DashOutlined: Icon.DashOutlined,
    DashboardFilled: Icon.DashboardFilled,
    DashboardOutlined: Icon.DashboardOutlined,
    DashboardTwoTone: Icon.DashboardTwoTone,
    DatabaseFilled: Icon.DatabaseFilled,
    DatabaseOutlined: Icon.DatabaseOutlined,
    DatabaseTwoTone: Icon.DatabaseTwoTone,
    DeleteColumnOutlined: Icon.DeleteColumnOutlined,
    DeleteFilled: Icon.DeleteFilled,
    DeleteOutlined: Icon.DeleteOutlined,
    DeleteRowOutlined: Icon.DeleteRowOutlined,
    DeleteTwoTone: Icon.DeleteTwoTone,
    DeliveredProcedureOutlined: Icon.DeliveredProcedureOutlined,
    DeploymentUnitOutlined: Icon.DeploymentUnitOutlined,
    DesktopOutlined: Icon.DesktopOutlined,
    DiffFilled: Icon.DiffFilled,
    DiffOutlined: Icon.DiffOutlined,
    DiffTwoTone: Icon.DiffTwoTone,
    DingdingOutlined: Icon.DingdingOutlined,
    DingtalkCircleFilled: Icon.DingtalkCircleFilled,
    DingtalkOutlined: Icon.DingtalkOutlined,
    DingtalkSquareFilled: Icon.DingtalkSquareFilled,
    DisconnectOutlined: Icon.DisconnectOutlined,
    DislikeFilled: Icon.DislikeFilled,
    DislikeOutlined: Icon.DislikeOutlined,
    DislikeTwoTone: Icon.DislikeTwoTone,
    DollarCircleFilled: Icon.DollarCircleFilled,
    DollarCircleOutlined: Icon.DollarCircleOutlined,
    DollarCircleTwoTone: Icon.DollarCircleTwoTone,
    DollarOutlined: Icon.DollarOutlined,
    DollarTwoTone: Icon.DollarTwoTone,
    DotChartOutlined: Icon.DotChartOutlined,
    DoubleLeftOutlined: Icon.DoubleLeftOutlined,
    DoubleRightOutlined: Icon.DoubleRightOutlined,
    DownCircleFilled: Icon.DownCircleFilled,
    DownCircleOutlined: Icon.DownCircleOutlined,
    DownCircleTwoTone: Icon.DownCircleTwoTone,
    DownOutlined: Icon.DownOutlined,
    DownSquareFilled: Icon.DownSquareFilled,
    DownSquareOutlined: Icon.DownSquareOutlined,
    DownSquareTwoTone: Icon.DownSquareTwoTone,
    DownloadOutlined: Icon.DownloadOutlined,
    DragOutlined: Icon.DragOutlined,
    DribbbleCircleFilled: Icon.DribbbleCircleFilled,
    DribbbleOutlined: Icon.DribbbleOutlined,
    DribbbleSquareFilled: Icon.DribbbleSquareFilled,
    DribbbleSquareOutlined: Icon.DribbbleSquareOutlined,
    DropboxCircleFilled: Icon.DropboxCircleFilled,
    DropboxOutlined: Icon.DropboxOutlined,
    DropboxSquareFilled: Icon.DropboxSquareFilled,
    EditFilled: Icon.EditFilled,
    EditOutlined: Icon.EditOutlined,
    EditTwoTone: Icon.EditTwoTone,
    EllipsisOutlined: Icon.EllipsisOutlined,
    EnterOutlined: Icon.EnterOutlined,
    EnvironmentFilled: Icon.EnvironmentFilled,
    EnvironmentOutlined: Icon.EnvironmentOutlined,
    EnvironmentTwoTone: Icon.EnvironmentTwoTone,
    EuroCircleFilled: Icon.EuroCircleFilled,
    EuroCircleOutlined: Icon.EuroCircleOutlined,
    EuroCircleTwoTone: Icon.EuroCircleTwoTone,
    EuroOutlined: Icon.EuroOutlined,
    EuroTwoTone: Icon.EuroTwoTone,
    ExceptionOutlined: Icon.ExceptionOutlined,
    ExclamationCircleFilled: Icon.ExclamationCircleFilled,
    ExclamationCircleOutlined: Icon.ExclamationCircleOutlined,
    ExclamationCircleTwoTone: Icon.ExclamationCircleTwoTone,
    ExclamationOutlined: Icon.ExclamationOutlined,
    ExpandAltOutlined: Icon.ExpandAltOutlined,
    ExpandOutlined: Icon.ExpandOutlined,
    ExperimentFilled: Icon.ExperimentFilled,
    ExperimentOutlined: Icon.ExperimentOutlined,
    ExperimentTwoTone: Icon.ExperimentTwoTone,
    ExportOutlined: Icon.ExportOutlined,
    EyeFilled: Icon.EyeFilled,
    EyeInvisibleFilled: Icon.EyeInvisibleFilled,
    EyeInvisibleOutlined: Icon.EyeInvisibleOutlined,
    EyeInvisibleTwoTone: Icon.EyeInvisibleTwoTone,
    EyeOutlined: Icon.EyeOutlined,
    EyeTwoTone: Icon.EyeTwoTone,
    FacebookFilled: Icon.FacebookFilled,
    FacebookOutlined: Icon.FacebookOutlined,
    FallOutlined: Icon.FallOutlined,
    FastBackwardFilled: Icon.FastBackwardFilled,
    FastBackwardOutlined: Icon.FastBackwardOutlined,
    FastForwardFilled: Icon.FastForwardFilled,
    FastForwardOutlined: Icon.FastForwardOutlined,
    FieldBinaryOutlined: Icon.FieldBinaryOutlined,
    FieldNumberOutlined: Icon.FieldNumberOutlined,
    FieldStringOutlined: Icon.FieldStringOutlined,
    FieldTimeOutlined: Icon.FieldTimeOutlined,
    FileAddFilled: Icon.FileAddFilled,
    FileAddOutlined: Icon.FileAddOutlined,
    FileAddTwoTone: Icon.FileAddTwoTone,
    FileDoneOutlined: Icon.FileDoneOutlined,
    FileExcelFilled: Icon.FileExcelFilled,
    FileExcelOutlined: Icon.FileExcelOutlined,
    FileExcelTwoTone: Icon.FileExcelTwoTone,
    FileExclamationFilled: Icon.FileExclamationFilled,
    FileExclamationOutlined: Icon.FileExclamationOutlined,
    FileExclamationTwoTone: Icon.FileExclamationTwoTone,
    FileFilled: Icon.FileFilled,
    FileGifOutlined: Icon.FileGifOutlined,
    FileImageFilled: Icon.FileImageFilled,
    FileImageOutlined: Icon.FileImageOutlined,
    FileImageTwoTone: Icon.FileImageTwoTone,
    FileJpgOutlined: Icon.FileJpgOutlined,
    FileMarkdownFilled: Icon.FileMarkdownFilled,
    FileMarkdownOutlined: Icon.FileMarkdownOutlined,
    FileMarkdownTwoTone: Icon.FileMarkdownTwoTone,
    FileOutlined: Icon.FileOutlined,
    FilePdfFilled: Icon.FilePdfFilled,
    FilePdfOutlined: Icon.FilePdfOutlined,
    FilePdfTwoTone: Icon.FilePdfTwoTone,
    FilePptFilled: Icon.FilePptFilled,
    FilePptOutlined: Icon.FilePptOutlined,
    FilePptTwoTone: Icon.FilePptTwoTone,
    FileProtectOutlined: Icon.FileProtectOutlined,
    FileSearchOutlined: Icon.FileSearchOutlined,
    FileSyncOutlined: Icon.FileSyncOutlined,
    FileTextFilled: Icon.FileTextFilled,
    FileTextOutlined: Icon.FileTextOutlined,
    FileTextTwoTone: Icon.FileTextTwoTone,
    FileTwoTone: Icon.FileTwoTone,
    FileUnknownFilled: Icon.FileUnknownFilled,
    FileUnknownOutlined: Icon.FileUnknownOutlined,
    FileUnknownTwoTone: Icon.FileUnknownTwoTone,
    FileWordFilled: Icon.FileWordFilled,
    FileWordOutlined: Icon.FileWordOutlined,
    FileWordTwoTone: Icon.FileWordTwoTone,
    FileZipFilled: Icon.FileZipFilled,
    FileZipOutlined: Icon.FileZipOutlined,
    FileZipTwoTone: Icon.FileZipTwoTone,
    FilterFilled: Icon.FilterFilled,
    FilterOutlined: Icon.FilterOutlined,
    FilterTwoTone: Icon.FilterTwoTone,
    FireFilled: Icon.FireFilled,
    FireOutlined: Icon.FireOutlined,
    FireTwoTone: Icon.FireTwoTone,
    FlagFilled: Icon.FlagFilled,
    FlagOutlined: Icon.FlagOutlined,
    FlagTwoTone: Icon.FlagTwoTone,
    FolderAddFilled: Icon.FolderAddFilled,
    FolderAddOutlined: Icon.FolderAddOutlined,
    FolderAddTwoTone: Icon.FolderAddTwoTone,
    FolderFilled: Icon.FolderFilled,
    FolderOpenFilled: Icon.FolderOpenFilled,
    FolderOpenOutlined: Icon.FolderOpenOutlined,
    FolderOpenTwoTone: Icon.FolderOpenTwoTone,
    FolderOutlined: Icon.FolderOutlined,
    FolderTwoTone: Icon.FolderTwoTone,
    FolderViewOutlined: Icon.FolderViewOutlined,
    FontColorsOutlined: Icon.FontColorsOutlined,
    FontSizeOutlined: Icon.FontSizeOutlined,
    ForkOutlined: Icon.ForkOutlined,
    FormOutlined: Icon.FormOutlined,
    FormatPainterFilled: Icon.FormatPainterFilled,
    FormatPainterOutlined: Icon.FormatPainterOutlined,
    ForwardFilled: Icon.ForwardFilled,
    ForwardOutlined: Icon.ForwardOutlined,
    FrownFilled: Icon.FrownFilled,
    FrownOutlined: Icon.FrownOutlined,
    FrownTwoTone: Icon.FrownTwoTone,
    FullscreenExitOutlined: Icon.FullscreenExitOutlined,
    FullscreenOutlined: Icon.FullscreenOutlined,
    FunctionOutlined: Icon.FunctionOutlined,
    FundFilled: Icon.FundFilled,
    FundOutlined: Icon.FundOutlined,
    FundProjectionScreenOutlined: Icon.FundProjectionScreenOutlined,
    FundTwoTone: Icon.FundTwoTone,
    FundViewOutlined: Icon.FundViewOutlined,
    FunnelPlotFilled: Icon.FunnelPlotFilled,
    FunnelPlotOutlined: Icon.FunnelPlotOutlined,
    FunnelPlotTwoTone: Icon.FunnelPlotTwoTone,
    GatewayOutlined: Icon.GatewayOutlined,
    GifOutlined: Icon.GifOutlined,
    GiftFilled: Icon.GiftFilled,
    GiftOutlined: Icon.GiftOutlined,
    GiftTwoTone: Icon.GiftTwoTone,
    GithubFilled: Icon.GithubFilled,
    GithubOutlined: Icon.GithubOutlined,
    GitlabFilled: Icon.GitlabFilled,
    GitlabOutlined: Icon.GitlabOutlined,
    GlobalOutlined: Icon.GlobalOutlined,
    GoldFilled: Icon.GoldFilled,
    GoldOutlined: Icon.GoldOutlined,
    GoldTwoTone: Icon.GoldTwoTone,
    GoldenFilled: Icon.GoldenFilled,
    GoogleCircleFilled: Icon.GoogleCircleFilled,
    GoogleOutlined: Icon.GoogleOutlined,
    GooglePlusCircleFilled: Icon.GooglePlusCircleFilled,
    GooglePlusOutlined: Icon.GooglePlusOutlined,
    GooglePlusSquareFilled: Icon.GooglePlusSquareFilled,
    GoogleSquareFilled: Icon.GoogleSquareFilled,
    GroupOutlined: Icon.GroupOutlined,
    HddFilled: Icon.HddFilled,
    HddOutlined: Icon.HddOutlined,
    HddTwoTone: Icon.HddTwoTone,
    HeartFilled: Icon.HeartFilled,
    HeartOutlined: Icon.HeartOutlined,
    HeartTwoTone: Icon.HeartTwoTone,
    HeatMapOutlined: Icon.HeatMapOutlined,
    HighlightFilled: Icon.HighlightFilled,
    HighlightOutlined: Icon.HighlightOutlined,
    HighlightTwoTone: Icon.HighlightTwoTone,
    HistoryOutlined: Icon.HistoryOutlined,
    HomeFilled: Icon.HomeFilled,
    HomeOutlined: Icon.HomeOutlined,
    HomeTwoTone: Icon.HomeTwoTone,
    HourglassFilled: Icon.HourglassFilled,
    HourglassOutlined: Icon.HourglassOutlined,
    HourglassTwoTone: Icon.HourglassTwoTone,
    Html5Filled: Icon.Html5Filled,
    Html5Outlined: Icon.Html5Outlined,
    Html5TwoTone: Icon.Html5TwoTone,
    IdcardFilled: Icon.IdcardFilled,
    IdcardOutlined: Icon.IdcardOutlined,
    IdcardTwoTone: Icon.IdcardTwoTone,
    IeCircleFilled: Icon.IeCircleFilled,
    IeOutlined: Icon.IeOutlined,
    IeSquareFilled: Icon.IeSquareFilled,
    ImportOutlined: Icon.ImportOutlined,
    InboxOutlined: Icon.InboxOutlined,
    InfoCircleFilled: Icon.InfoCircleFilled,
    InfoCircleOutlined: Icon.InfoCircleOutlined,
    InfoCircleTwoTone: Icon.InfoCircleTwoTone,
    InfoOutlined: Icon.InfoOutlined,
    InsertRowAboveOutlined: Icon.InsertRowAboveOutlined,
    InsertRowBelowOutlined: Icon.InsertRowBelowOutlined,
    InsertRowLeftOutlined: Icon.InsertRowLeftOutlined,
    InsertRowRightOutlined: Icon.InsertRowRightOutlined,
    InstagramFilled: Icon.InstagramFilled,
    InstagramOutlined: Icon.InstagramOutlined,
    InsuranceFilled: Icon.InsuranceFilled,
    InsuranceOutlined: Icon.InsuranceOutlined,
    InsuranceTwoTone: Icon.InsuranceTwoTone,
    InteractionFilled: Icon.InteractionFilled,
    InteractionOutlined: Icon.InteractionOutlined,
    InteractionTwoTone: Icon.InteractionTwoTone,
    IssuesCloseOutlined: Icon.IssuesCloseOutlined,
    ItalicOutlined: Icon.ItalicOutlined,
    KeyOutlined: Icon.KeyOutlined,
    LaptopOutlined: Icon.LaptopOutlined,
    LayoutFilled: Icon.LayoutFilled,
    LayoutOutlined: Icon.LayoutOutlined,
    LayoutTwoTone: Icon.LayoutTwoTone,
    LeftCircleFilled: Icon.LeftCircleFilled,
    LeftCircleOutlined: Icon.LeftCircleOutlined,
    LeftCircleTwoTone: Icon.LeftCircleTwoTone,
    LeftOutlined: Icon.LeftOutlined,
    LeftSquareFilled: Icon.LeftSquareFilled,
    LeftSquareOutlined: Icon.LeftSquareOutlined,
    LeftSquareTwoTone: Icon.LeftSquareTwoTone,
    LikeFilled: Icon.LikeFilled,
    LikeOutlined: Icon.LikeOutlined,
    LikeTwoTone: Icon.LikeTwoTone,
    LineChartOutlined: Icon.LineChartOutlined,
    LineHeightOutlined: Icon.LineHeightOutlined,
    LineOutlined: Icon.LineOutlined,
    LinkOutlined: Icon.LinkOutlined,
    LinkedinFilled: Icon.LinkedinFilled,
    LinkedinOutlined: Icon.LinkedinOutlined,
    Loading3QuartersOutlined: Icon.Loading3QuartersOutlined,
    LoadingOutlined: Icon.LoadingOutlined,
    LockFilled: Icon.LockFilled,
    LockOutlined: Icon.LockOutlined,
    LockTwoTone: Icon.LockTwoTone,
    LoginOutlined: Icon.LoginOutlined,
    LogoutOutlined: Icon.LogoutOutlined,
    MacCommandFilled: Icon.MacCommandFilled,
    MacCommandOutlined: Icon.MacCommandOutlined,
    MailFilled: Icon.MailFilled,
    MailOutlined: Icon.MailOutlined,
    MailTwoTone: Icon.MailTwoTone,
    ManOutlined: Icon.ManOutlined,
    MedicineBoxFilled: Icon.MedicineBoxFilled,
    MedicineBoxOutlined: Icon.MedicineBoxOutlined,
    MedicineBoxTwoTone: Icon.MedicineBoxTwoTone,
    MediumCircleFilled: Icon.MediumCircleFilled,
    MediumOutlined: Icon.MediumOutlined,
    MediumSquareFilled: Icon.MediumSquareFilled,
    MediumWorkmarkOutlined: Icon.MediumWorkmarkOutlined,
    MehFilled: Icon.MehFilled,
    MehOutlined: Icon.MehOutlined,
    MehTwoTone: Icon.MehTwoTone,
    MenuFoldOutlined: Icon.MenuFoldOutlined,
    MenuOutlined: Icon.MenuOutlined,
    MenuUnfoldOutlined: Icon.MenuUnfoldOutlined,
    MergeCellsOutlined: Icon.MergeCellsOutlined,
    MessageFilled: Icon.MessageFilled,
    MessageOutlined: Icon.MessageOutlined,
    MessageTwoTone: Icon.MessageTwoTone,
    MinusCircleFilled: Icon.MinusCircleFilled,
    MinusCircleOutlined: Icon.MinusCircleOutlined,
    MinusCircleTwoTone: Icon.MinusCircleTwoTone,
    MinusOutlined: Icon.MinusOutlined,
    MinusSquareFilled: Icon.MinusSquareFilled,
    MinusSquareOutlined: Icon.MinusSquareOutlined,
    MinusSquareTwoTone: Icon.MinusSquareTwoTone,
    MobileFilled: Icon.MobileFilled,
    MobileOutlined: Icon.MobileOutlined,
    MobileTwoTone: Icon.MobileTwoTone,
    MoneyCollectFilled: Icon.MoneyCollectFilled,
    MoneyCollectOutlined: Icon.MoneyCollectOutlined,
    MoneyCollectTwoTone: Icon.MoneyCollectTwoTone,
    MonitorOutlined: Icon.MonitorOutlined,
    MoreOutlined: Icon.MoreOutlined,
    NodeCollapseOutlined: Icon.NodeCollapseOutlined,
    NodeExpandOutlined: Icon.NodeExpandOutlined,
    NodeIndexOutlined: Icon.NodeIndexOutlined,
    NotificationFilled: Icon.NotificationFilled,
    NotificationOutlined: Icon.NotificationOutlined,
    NotificationTwoTone: Icon.NotificationTwoTone,
    NumberOutlined: Icon.NumberOutlined,
    OneToOneOutlined: Icon.OneToOneOutlined,
    OrderedListOutlined: Icon.OrderedListOutlined,
    PaperClipOutlined: Icon.PaperClipOutlined,
    PartitionOutlined: Icon.PartitionOutlined,
    PauseCircleFilled: Icon.PauseCircleFilled,
    PauseCircleOutlined: Icon.PauseCircleOutlined,
    PauseCircleTwoTone: Icon.PauseCircleTwoTone,
    PauseOutlined: Icon.PauseOutlined,
    PayCircleFilled: Icon.PayCircleFilled,
    PayCircleOutlined: Icon.PayCircleOutlined,
    PercentageOutlined: Icon.PercentageOutlined,
    PhoneFilled: Icon.PhoneFilled,
    PhoneOutlined: Icon.PhoneOutlined,
    PhoneTwoTone: Icon.PhoneTwoTone,
    PicCenterOutlined: Icon.PicCenterOutlined,
    PicLeftOutlined: Icon.PicLeftOutlined,
    PicRightOutlined: Icon.PicRightOutlined,
    PictureFilled: Icon.PictureFilled,
    PictureOutlined: Icon.PictureOutlined,
    PictureTwoTone: Icon.PictureTwoTone,
    PieChartFilled: Icon.PieChartFilled,
    PieChartOutlined: Icon.PieChartOutlined,
    PieChartTwoTone: Icon.PieChartTwoTone,
    PlayCircleFilled: Icon.PlayCircleFilled,
    PlayCircleOutlined: Icon.PlayCircleOutlined,
    PlayCircleTwoTone: Icon.PlayCircleTwoTone,
    PlaySquareFilled: Icon.PlaySquareFilled,
    PlaySquareOutlined: Icon.PlaySquareOutlined,
    PlaySquareTwoTone: Icon.PlaySquareTwoTone,
    PlusCircleFilled: Icon.PlusCircleFilled,
    PlusCircleOutlined: Icon.PlusCircleOutlined,
    PlusCircleTwoTone: Icon.PlusCircleTwoTone,
    PlusOutlined: Icon.PlusOutlined,
    PlusSquareFilled: Icon.PlusSquareFilled,
    PlusSquareOutlined: Icon.PlusSquareOutlined,
    PlusSquareTwoTone: Icon.PlusSquareTwoTone,
    PoundCircleFilled: Icon.PoundCircleFilled,
    PoundCircleOutlined: Icon.PoundCircleOutlined,
    PoundCircleTwoTone: Icon.PoundCircleTwoTone,
    PoundOutlined: Icon.PoundOutlined,
    PoweroffOutlined: Icon.PoweroffOutlined,
    PrinterFilled: Icon.PrinterFilled,
    PrinterOutlined: Icon.PrinterOutlined,
    PrinterTwoTone: Icon.PrinterTwoTone,
    ProfileFilled: Icon.ProfileFilled,
    ProfileOutlined: Icon.ProfileOutlined,
    ProfileTwoTone: Icon.ProfileTwoTone,
    ProjectFilled: Icon.ProjectFilled,
    ProjectOutlined: Icon.ProjectOutlined,
    ProjectTwoTone: Icon.ProjectTwoTone,
    PropertySafetyFilled: Icon.PropertySafetyFilled,
    PropertySafetyOutlined: Icon.PropertySafetyOutlined,
    PropertySafetyTwoTone: Icon.PropertySafetyTwoTone,
    PullRequestOutlined: Icon.PullRequestOutlined,
    PushpinFilled: Icon.PushpinFilled,
    PushpinOutlined: Icon.PushpinOutlined,
    PushpinTwoTone: Icon.PushpinTwoTone,
    QqCircleFilled: Icon.QqCircleFilled,
    QqOutlined: Icon.QqOutlined,
    QqSquareFilled: Icon.QqSquareFilled,
    QrcodeOutlined: Icon.QrcodeOutlined,
    QuestionCircleFilled: Icon.QuestionCircleFilled,
    QuestionCircleOutlined: Icon.QuestionCircleOutlined,
    QuestionCircleTwoTone: Icon.QuestionCircleTwoTone,
    QuestionOutlined: Icon.QuestionOutlined,
    RadarChartOutlined: Icon.RadarChartOutlined,
    RadiusBottomleftOutlined: Icon.RadiusBottomleftOutlined,
    RadiusBottomrightOutlined: Icon.RadiusBottomrightOutlined,
    RadiusSettingOutlined: Icon.RadiusSettingOutlined,
    RadiusUpleftOutlined: Icon.RadiusUpleftOutlined,
    RadiusUprightOutlined: Icon.RadiusUprightOutlined,
    ReadFilled: Icon.ReadFilled,
    ReadOutlined: Icon.ReadOutlined,
    ReconciliationFilled: Icon.ReconciliationFilled,
    ReconciliationOutlined: Icon.ReconciliationOutlined,
    ReconciliationTwoTone: Icon.ReconciliationTwoTone,
    RedEnvelopeFilled: Icon.RedEnvelopeFilled,
    RedEnvelopeOutlined: Icon.RedEnvelopeOutlined,
    RedEnvelopeTwoTone: Icon.RedEnvelopeTwoTone,
    RedditCircleFilled: Icon.RedditCircleFilled,
    RedditOutlined: Icon.RedditOutlined,
    RedditSquareFilled: Icon.RedditSquareFilled,
    RedoOutlined: Icon.RedoOutlined,
    ReloadOutlined: Icon.ReloadOutlined,
    RestFilled: Icon.RestFilled,
    RestOutlined: Icon.RestOutlined,
    RestTwoTone: Icon.RestTwoTone,
    RetweetOutlined: Icon.RetweetOutlined,
    RightCircleFilled: Icon.RightCircleFilled,
    RightCircleOutlined: Icon.RightCircleOutlined,
    RightCircleTwoTone: Icon.RightCircleTwoTone,
    RightOutlined: Icon.RightOutlined,
    RightSquareFilled: Icon.RightSquareFilled,
    RightSquareOutlined: Icon.RightSquareOutlined,
    RightSquareTwoTone: Icon.RightSquareTwoTone,
    RiseOutlined: Icon.RiseOutlined,
    RobotFilled: Icon.RobotFilled,
    RobotOutlined: Icon.RobotOutlined,
    RocketFilled: Icon.RocketFilled,
    RocketOutlined: Icon.RocketOutlined,
    RocketTwoTone: Icon.RocketTwoTone,
    RollbackOutlined: Icon.RollbackOutlined,
    RotateLeftOutlined: Icon.RotateLeftOutlined,
    RotateRightOutlined: Icon.RotateRightOutlined,
    SafetyCertificateFilled: Icon.SafetyCertificateFilled,
    SafetyCertificateOutlined: Icon.SafetyCertificateOutlined,
    SafetyCertificateTwoTone: Icon.SafetyCertificateTwoTone,
    SafetyOutlined: Icon.SafetyOutlined,
    SaveFilled: Icon.SaveFilled,
    SaveOutlined: Icon.SaveOutlined,
    SaveTwoTone: Icon.SaveTwoTone,
    ScanOutlined: Icon.ScanOutlined,
    ScheduleFilled: Icon.ScheduleFilled,
    ScheduleOutlined: Icon.ScheduleOutlined,
    ScheduleTwoTone: Icon.ScheduleTwoTone,
    ScissorOutlined: Icon.ScissorOutlined,
    SearchOutlined: Icon.SearchOutlined,
    SecurityScanFilled: Icon.SecurityScanFilled,
    SecurityScanOutlined: Icon.SecurityScanOutlined,
    SecurityScanTwoTone: Icon.SecurityScanTwoTone,
    SelectOutlined: Icon.SelectOutlined,
    SendOutlined: Icon.SendOutlined,
    SettingFilled: Icon.SettingFilled,
    SettingOutlined: Icon.SettingOutlined,
    SettingTwoTone: Icon.SettingTwoTone,
    ShakeOutlined: Icon.ShakeOutlined,
    ShareAltOutlined: Icon.ShareAltOutlined,
    ShopFilled: Icon.ShopFilled,
    ShopOutlined: Icon.ShopOutlined,
    ShopTwoTone: Icon.ShopTwoTone,
    ShoppingCartOutlined: Icon.ShoppingCartOutlined,
    ShoppingFilled: Icon.ShoppingFilled,
    ShoppingOutlined: Icon.ShoppingOutlined,
    ShoppingTwoTone: Icon.ShoppingTwoTone,
    ShrinkOutlined: Icon.ShrinkOutlined,
    SignalFilled: Icon.SignalFilled,
    SisternodeOutlined: Icon.SisternodeOutlined,
    SketchCircleFilled: Icon.SketchCircleFilled,
    SketchOutlined: Icon.SketchOutlined,
    SketchSquareFilled: Icon.SketchSquareFilled,
    SkinFilled: Icon.SkinFilled,
    SkinOutlined: Icon.SkinOutlined,
    SkinTwoTone: Icon.SkinTwoTone,
    SkypeFilled: Icon.SkypeFilled,
    SkypeOutlined: Icon.SkypeOutlined,
    SlackCircleFilled: Icon.SlackCircleFilled,
    SlackOutlined: Icon.SlackOutlined,
    SlackSquareFilled: Icon.SlackSquareFilled,
    SlackSquareOutlined: Icon.SlackSquareOutlined,
    SlidersFilled: Icon.SlidersFilled,
    SlidersOutlined: Icon.SlidersOutlined,
    SlidersTwoTone: Icon.SlidersTwoTone,
    SmallDashOutlined: Icon.SmallDashOutlined,
    SmileFilled: Icon.SmileFilled,
    SmileOutlined: Icon.SmileOutlined,
    SmileTwoTone: Icon.SmileTwoTone,
    SnippetsFilled: Icon.SnippetsFilled,
    SnippetsOutlined: Icon.SnippetsOutlined,
    SnippetsTwoTone: Icon.SnippetsTwoTone,
    SolutionOutlined: Icon.SolutionOutlined,
    SortAscendingOutlined: Icon.SortAscendingOutlined,
    SortDescendingOutlined: Icon.SortDescendingOutlined,
    SoundFilled: Icon.SoundFilled,
    SoundOutlined: Icon.SoundOutlined,
    SoundTwoTone: Icon.SoundTwoTone,
    SplitCellsOutlined: Icon.SplitCellsOutlined,
    StarFilled: Icon.StarFilled,
    StarOutlined: Icon.StarOutlined,
    StarTwoTone: Icon.StarTwoTone,
    StepBackwardFilled: Icon.StepBackwardFilled,
    StepBackwardOutlined: Icon.StepBackwardOutlined,
    StepForwardFilled: Icon.StepForwardFilled,
    StepForwardOutlined: Icon.StepForwardOutlined,
    StockOutlined: Icon.StockOutlined,
    StopFilled: Icon.StopFilled,
    StopOutlined: Icon.StopOutlined,
    StopTwoTone: Icon.StopTwoTone,
    StrikethroughOutlined: Icon.StrikethroughOutlined,
    SubnodeOutlined: Icon.SubnodeOutlined,
    SwapLeftOutlined: Icon.SwapLeftOutlined,
    SwapOutlined: Icon.SwapOutlined,
    SwapRightOutlined: Icon.SwapRightOutlined,
    SwitcherFilled: Icon.SwitcherFilled,
    SwitcherOutlined: Icon.SwitcherOutlined,
    SwitcherTwoTone: Icon.SwitcherTwoTone,
    SyncOutlined: Icon.SyncOutlined,
    TableOutlined: Icon.TableOutlined,
    TabletFilled: Icon.TabletFilled,
    TabletOutlined: Icon.TabletOutlined,
    TabletTwoTone: Icon.TabletTwoTone,
    TagFilled: Icon.TagFilled,
    TagOutlined: Icon.TagOutlined,
    TagTwoTone: Icon.TagTwoTone,
    TagsFilled: Icon.TagsFilled,
    TagsOutlined: Icon.TagsOutlined,
    TagsTwoTone: Icon.TagsTwoTone,
    TaobaoCircleFilled: Icon.TaobaoCircleFilled,
    TaobaoCircleOutlined: Icon.TaobaoCircleOutlined,
    TaobaoOutlined: Icon.TaobaoOutlined,
    TaobaoSquareFilled: Icon.TaobaoSquareFilled,
    TeamOutlined: Icon.TeamOutlined,
    ThunderboltFilled: Icon.ThunderboltFilled,
    ThunderboltOutlined: Icon.ThunderboltOutlined,
    ThunderboltTwoTone: Icon.ThunderboltTwoTone,
    ToTopOutlined: Icon.ToTopOutlined,
    ToolFilled: Icon.ToolFilled,
    ToolOutlined: Icon.ToolOutlined,
    ToolTwoTone: Icon.ToolTwoTone,
    TrademarkCircleFilled: Icon.TrademarkCircleFilled,
    TrademarkCircleOutlined: Icon.TrademarkCircleOutlined,
    TrademarkCircleTwoTone: Icon.TrademarkCircleTwoTone,
    TrademarkOutlined: Icon.TrademarkOutlined,
    TransactionOutlined: Icon.TransactionOutlined,
    TranslationOutlined: Icon.TranslationOutlined,
    TrophyFilled: Icon.TrophyFilled,
    TrophyOutlined: Icon.TrophyOutlined,
    TrophyTwoTone: Icon.TrophyTwoTone,
    TwitterCircleFilled: Icon.TwitterCircleFilled,
    TwitterOutlined: Icon.TwitterOutlined,
    TwitterSquareFilled: Icon.TwitterSquareFilled,
    UnderlineOutlined: Icon.UnderlineOutlined,
    UndoOutlined: Icon.UndoOutlined,
    UngroupOutlined: Icon.UngroupOutlined,
    UnlockFilled: Icon.UnlockFilled,
    UnlockOutlined: Icon.UnlockOutlined,
    UnlockTwoTone: Icon.UnlockTwoTone,
    UnorderedListOutlined: Icon.UnorderedListOutlined,
    UpCircleFilled: Icon.UpCircleFilled,
    UpCircleOutlined: Icon.UpCircleOutlined,
    UpCircleTwoTone: Icon.UpCircleTwoTone,
    UpOutlined: Icon.UpOutlined,
    UpSquareFilled: Icon.UpSquareFilled,
    UpSquareOutlined: Icon.UpSquareOutlined,
    UpSquareTwoTone: Icon.UpSquareTwoTone,
    UploadOutlined: Icon.UploadOutlined,
    UsbFilled: Icon.UsbFilled,
    UsbOutlined: Icon.UsbOutlined,
    UsbTwoTone: Icon.UsbTwoTone,
    UserAddOutlined: Icon.UserAddOutlined,
    UserDeleteOutlined: Icon.UserDeleteOutlined,
    UserOutlined: Icon.UserOutlined,
    UserSwitchOutlined: Icon.UserSwitchOutlined,
    UsergroupAddOutlined: Icon.UsergroupAddOutlined,
    UsergroupDeleteOutlined: Icon.UsergroupDeleteOutlined,
    VerifiedOutlined: Icon.VerifiedOutlined,
    VerticalAlignBottomOutlined: Icon.VerticalAlignBottomOutlined,
    VerticalAlignMiddleOutlined: Icon.VerticalAlignMiddleOutlined,
    VerticalAlignTopOutlined: Icon.VerticalAlignTopOutlined,
    VerticalLeftOutlined: Icon.VerticalLeftOutlined,
    VerticalRightOutlined: Icon.VerticalRightOutlined,
    VideoCameraAddOutlined: Icon.VideoCameraAddOutlined,
    VideoCameraFilled: Icon.VideoCameraFilled,
    VideoCameraOutlined: Icon.VideoCameraOutlined,
    VideoCameraTwoTone: Icon.VideoCameraTwoTone,
    WalletFilled: Icon.WalletFilled,
    WalletOutlined: Icon.WalletOutlined,
    WalletTwoTone: Icon.WalletTwoTone,
    WarningFilled: Icon.WarningFilled,
    WarningOutlined: Icon.WarningOutlined,
    WarningTwoTone: Icon.WarningTwoTone,
    WechatFilled: Icon.WechatFilled,
    WechatOutlined: Icon.WechatOutlined,
    WeiboCircleFilled: Icon.WeiboCircleFilled,
    WeiboCircleOutlined: Icon.WeiboCircleOutlined,
    WeiboOutlined: Icon.WeiboOutlined,
    WeiboSquareFilled: Icon.WeiboSquareFilled,
    WeiboSquareOutlined: Icon.WeiboSquareOutlined,
    WhatsAppOutlined: Icon.WhatsAppOutlined,
    WifiOutlined: Icon.WifiOutlined,
    WindowsFilled: Icon.WindowsFilled,
    WindowsOutlined: Icon.WindowsOutlined,
    WomanOutlined: Icon.WomanOutlined,
    YahooFilled: Icon.YahooFilled,
    YahooOutlined: Icon.YahooOutlined,
    YoutubeFilled: Icon.YoutubeFilled,
    YoutubeOutlined: Icon.YoutubeOutlined,
    YuqueFilled: Icon.YuqueFilled,
    YuqueOutlined: Icon.YuqueOutlined,
    ZhihuCircleFilled: Icon.ZhihuCircleFilled,
    ZhihuOutlined: Icon.ZhihuOutlined,
    ZhihuSquareFilled: Icon.ZhihuSquareFilled,
    ZoomInOutlined: Icon.ZoomInOutlined,
    ZoomOutOutlined: Icon.ZoomOutOutlined
}

export const antIcons = {
    AccountBookFilled: <Icon.AccountBookFilled/>,
    AccountBookOutlined: <Icon.AccountBookOutlined/>,
    AccountBookTwoTone: <Icon.AccountBookTwoTone/>,
    AimOutlined: <Icon.AimOutlined/>,
    AlertFilled: <Icon.AlertFilled/>,
    AlertOutlined: <Icon.AlertOutlined/>,
    AlertTwoTone: <Icon.AlertTwoTone/>,
    AlibabaOutlined: <Icon.AlibabaOutlined/>,
    AlignCenterOutlined: <Icon.AlignCenterOutlined/>,
    AlignLeftOutlined: <Icon.AlignLeftOutlined/>,
    AlignRightOutlined: <Icon.AlignRightOutlined/>,
    AlipayCircleFilled: <Icon.AlipayCircleFilled/>,
    AlipayCircleOutlined: <Icon.AlipayCircleOutlined/>,
    AlipayOutlined: <Icon.AlipayOutlined/>,
    AlipaySquareFilled: <Icon.AlipaySquareFilled/>,
    AliwangwangFilled: <Icon.AliwangwangFilled/>,
    AliwangwangOutlined: <Icon.AliwangwangOutlined/>,
    AliyunOutlined: <Icon.AliyunOutlined/>,
    AmazonCircleFilled: <Icon.AmazonCircleFilled/>,
    AmazonOutlined: <Icon.AmazonOutlined/>,
    AmazonSquareFilled: <Icon.AmazonSquareFilled/>,
    AndroidFilled: <Icon.AndroidFilled/>,
    AndroidOutlined: <Icon.AndroidOutlined/>,
    AntCloudOutlined: <Icon.AntCloudOutlined/>,
    AntDesignOutlined: <Icon.AntDesignOutlined/>,
    ApartmentOutlined: <Icon.ApartmentOutlined/>,
    ApiFilled: <Icon.ApiFilled/>,
    ApiOutlined: <Icon.ApiOutlined/>,
    ApiTwoTone: <Icon.ApiTwoTone/>,
    AppleFilled: <Icon.AppleFilled/>,
    AppleOutlined: <Icon.AppleOutlined/>,
    AppstoreAddOutlined: <Icon.AppstoreAddOutlined/>,
    AppstoreFilled: <Icon.AppstoreFilled/>,
    AppstoreOutlined: <Icon.AppstoreOutlined/>,
    AppstoreTwoTone: <Icon.AppstoreTwoTone/>,
    AreaChartOutlined: <Icon.AreaChartOutlined/>,
    ArrowDownOutlined: <Icon.ArrowDownOutlined/>,
    ArrowLeftOutlined: <Icon.ArrowLeftOutlined/>,
    ArrowRightOutlined: <Icon.ArrowRightOutlined/>,
    ArrowUpOutlined: <Icon.ArrowUpOutlined/>,
    ArrowsAltOutlined: <Icon.ArrowsAltOutlined/>,
    AudioFilled: <Icon.AudioFilled/>,
    AudioMutedOutlined: <Icon.AudioMutedOutlined/>,
    AudioOutlined: <Icon.AudioOutlined/>,
    AudioTwoTone: <Icon.AudioTwoTone/>,
    AuditOutlined: <Icon.AuditOutlined/>,
    BackwardFilled: <Icon.BackwardFilled/>,
    BackwardOutlined: <Icon.BackwardOutlined/>,
    BankFilled: <Icon.BankFilled/>,
    BankOutlined: <Icon.BankOutlined/>,
    BankTwoTone: <Icon.BankTwoTone/>,
    BarChartOutlined: <Icon.BarChartOutlined/>,
    BarcodeOutlined: <Icon.BarcodeOutlined/>,
    BarsOutlined: <Icon.BarsOutlined/>,
    BehanceCircleFilled: <Icon.BehanceCircleFilled/>,
    BehanceOutlined: <Icon.BehanceOutlined/>,
    BehanceSquareFilled: <Icon.BehanceSquareFilled/>,
    BehanceSquareOutlined: <Icon.BehanceSquareOutlined/>,
    BellFilled: <Icon.BellFilled/>,
    BellOutlined: <Icon.BellOutlined/>,
    BellTwoTone: <Icon.BellTwoTone/>,
    BgColorsOutlined: <Icon.BgColorsOutlined/>,
    BlockOutlined: <Icon.BlockOutlined/>,
    BoldOutlined: <Icon.BoldOutlined/>,
    BookFilled: <Icon.BookFilled/>,
    BookOutlined: <Icon.BookOutlined/>,
    BookTwoTone: <Icon.BookTwoTone/>,
    BorderBottomOutlined: <Icon.BorderBottomOutlined/>,
    BorderHorizontalOutlined: <Icon.BorderHorizontalOutlined/>,
    BorderInnerOutlined: <Icon.BorderInnerOutlined/>,
    BorderLeftOutlined: <Icon.BorderLeftOutlined/>,
    BorderOuterOutlined: <Icon.BorderOuterOutlined/>,
    BorderOutlined: <Icon.BorderOutlined/>,
    BorderRightOutlined: <Icon.BorderRightOutlined/>,
    BorderTopOutlined: <Icon.BorderTopOutlined/>,
    BorderVerticleOutlined: <Icon.BorderVerticleOutlined/>,
    BorderlessTableOutlined: <Icon.BorderlessTableOutlined/>,
    BoxPlotFilled: <Icon.BoxPlotFilled/>,
    BoxPlotOutlined: <Icon.BoxPlotOutlined/>,
    BoxPlotTwoTone: <Icon.BoxPlotTwoTone/>,
    BranchesOutlined: <Icon.BranchesOutlined/>,
    BugFilled: <Icon.BugFilled/>,
    BugOutlined: <Icon.BugOutlined/>,
    BugTwoTone: <Icon.BugTwoTone/>,
    BuildFilled: <Icon.BuildFilled/>,
    BuildOutlined: <Icon.BuildOutlined/>,
    BuildTwoTone: <Icon.BuildTwoTone/>,
    BulbFilled: <Icon.BulbFilled/>,
    BulbOutlined: <Icon.BulbOutlined/>,
    BulbTwoTone: <Icon.BulbTwoTone/>,
    CalculatorFilled: <Icon.CalculatorFilled/>,
    CalculatorOutlined: <Icon.CalculatorOutlined/>,
    CalculatorTwoTone: <Icon.CalculatorTwoTone/>,
    CalendarFilled: <Icon.CalendarFilled/>,
    CalendarOutlined: <Icon.CalendarOutlined/>,
    CalendarTwoTone: <Icon.CalendarTwoTone/>,
    CameraFilled: <Icon.CameraFilled/>,
    CameraOutlined: <Icon.CameraOutlined/>,
    CameraTwoTone: <Icon.CameraTwoTone/>,
    CarFilled: <Icon.CarFilled/>,
    CarOutlined: <Icon.CarOutlined/>,
    CarTwoTone: <Icon.CarTwoTone/>,
    CaretDownFilled: <Icon.CaretDownFilled/>,
    CaretDownOutlined: <Icon.CaretDownOutlined/>,
    CaretLeftFilled: <Icon.CaretLeftFilled/>,
    CaretLeftOutlined: <Icon.CaretLeftOutlined/>,
    CaretRightFilled: <Icon.CaretRightFilled/>,
    CaretRightOutlined: <Icon.CaretRightOutlined/>,
    CaretUpFilled: <Icon.CaretUpFilled/>,
    CaretUpOutlined: <Icon.CaretUpOutlined/>,
    CarryOutFilled: <Icon.CarryOutFilled/>,
    CarryOutOutlined: <Icon.CarryOutOutlined/>,
    CarryOutTwoTone: <Icon.CarryOutTwoTone/>,
    CheckCircleFilled: <Icon.CheckCircleFilled/>,
    CheckCircleOutlined: <Icon.CheckCircleOutlined/>,
    CheckCircleTwoTone: <Icon.CheckCircleTwoTone/>,
    CheckOutlined: <Icon.CheckOutlined/>,
    CheckSquareFilled: <Icon.CheckSquareFilled/>,
    CheckSquareOutlined: <Icon.CheckSquareOutlined/>,
    CheckSquareTwoTone: <Icon.CheckSquareTwoTone/>,
    ChromeFilled: <Icon.ChromeFilled/>,
    ChromeOutlined: <Icon.ChromeOutlined/>,
    CiCircleFilled: <Icon.CiCircleFilled/>,
    CiCircleOutlined: <Icon.CiCircleOutlined/>,
    CiCircleTwoTone: <Icon.CiCircleTwoTone/>,
    CiOutlined: <Icon.CiOutlined/>,
    CiTwoTone: <Icon.CiTwoTone/>,
    ClearOutlined: <Icon.ClearOutlined/>,
    ClockCircleFilled: <Icon.ClockCircleFilled/>,
    ClockCircleOutlined: <Icon.ClockCircleOutlined/>,
    ClockCircleTwoTone: <Icon.ClockCircleTwoTone/>,
    CloseCircleFilled: <Icon.CloseCircleFilled/>,
    CloseCircleOutlined: <Icon.CloseCircleOutlined/>,
    CloseCircleTwoTone: <Icon.CloseCircleTwoTone/>,
    CloseOutlined: <Icon.CloseOutlined/>,
    CloseSquareFilled: <Icon.CloseSquareFilled/>,
    CloseSquareOutlined: <Icon.CloseSquareOutlined/>,
    CloseSquareTwoTone: <Icon.CloseSquareTwoTone/>,
    CloudDownloadOutlined: <Icon.CloudDownloadOutlined/>,
    CloudFilled: <Icon.CloudFilled/>,
    CloudOutlined: <Icon.CloudOutlined/>,
    CloudServerOutlined: <Icon.CloudServerOutlined/>,
    CloudSyncOutlined: <Icon.CloudSyncOutlined/>,
    CloudTwoTone: <Icon.CloudTwoTone/>,
    CloudUploadOutlined: <Icon.CloudUploadOutlined/>,
    ClusterOutlined: <Icon.ClusterOutlined/>,
    CodeFilled: <Icon.CodeFilled/>,
    CodeOutlined: <Icon.CodeOutlined/>,
    CodeSandboxCircleFilled: <Icon.CodeSandboxCircleFilled/>,
    CodeSandboxOutlined: <Icon.CodeSandboxOutlined/>,
    CodeSandboxSquareFilled: <Icon.CodeSandboxSquareFilled/>,
    CodeTwoTone: <Icon.CodeTwoTone/>,
    CodepenCircleFilled: <Icon.CodepenCircleFilled/>,
    CodepenCircleOutlined: <Icon.CodepenCircleOutlined/>,
    CodepenOutlined: <Icon.CodepenOutlined/>,
    CodepenSquareFilled: <Icon.CodepenSquareFilled/>,
    CoffeeOutlined: <Icon.CoffeeOutlined/>,
    ColumnHeightOutlined: <Icon.ColumnHeightOutlined/>,
    ColumnWidthOutlined: <Icon.ColumnWidthOutlined/>,
    CommentOutlined: <Icon.CommentOutlined/>,
    CompassFilled: <Icon.CompassFilled/>,
    CompassOutlined: <Icon.CompassOutlined/>,
    CompassTwoTone: <Icon.CompassTwoTone/>,
    CompressOutlined: <Icon.CompressOutlined/>,
    ConsoleSqlOutlined: <Icon.ConsoleSqlOutlined/>,
    ContactsFilled: <Icon.ContactsFilled/>,
    ContactsOutlined: <Icon.ContactsOutlined/>,
    ContactsTwoTone: <Icon.ContactsTwoTone/>,
    ContainerFilled: <Icon.ContainerFilled/>,
    ContainerOutlined: <Icon.ContainerOutlined/>,
    ContainerTwoTone: <Icon.ContainerTwoTone/>,
    ControlFilled: <Icon.ControlFilled/>,
    ControlOutlined: <Icon.ControlOutlined/>,
    ControlTwoTone: <Icon.ControlTwoTone/>,
    CopyFilled: <Icon.CopyFilled/>,
    CopyOutlined: <Icon.CopyOutlined/>,
    CopyTwoTone: <Icon.CopyTwoTone/>,
    CopyrightCircleFilled: <Icon.CopyrightCircleFilled/>,
    CopyrightCircleOutlined: <Icon.CopyrightCircleOutlined/>,
    CopyrightCircleTwoTone: <Icon.CopyrightCircleTwoTone/>,
    CopyrightOutlined: <Icon.CopyrightOutlined/>,
    CopyrightTwoTone: <Icon.CopyrightTwoTone/>,
    CreditCardFilled: <Icon.CreditCardFilled/>,
    CreditCardOutlined: <Icon.CreditCardOutlined/>,
    CreditCardTwoTone: <Icon.CreditCardTwoTone/>,
    CrownFilled: <Icon.CrownFilled/>,
    CrownOutlined: <Icon.CrownOutlined/>,
    CrownTwoTone: <Icon.CrownTwoTone/>,
    CustomerServiceFilled: <Icon.CustomerServiceFilled/>,
    CustomerServiceOutlined: <Icon.CustomerServiceOutlined/>,
    CustomerServiceTwoTone: <Icon.CustomerServiceTwoTone/>,
    DashOutlined: <Icon.DashOutlined/>,
    DashboardFilled: <Icon.DashboardFilled/>,
    DashboardOutlined: <Icon.DashboardOutlined/>,
    DashboardTwoTone: <Icon.DashboardTwoTone/>,
    DatabaseFilled: <Icon.DatabaseFilled/>,
    DatabaseOutlined: <Icon.DatabaseOutlined/>,
    DatabaseTwoTone: <Icon.DatabaseTwoTone/>,
    DeleteColumnOutlined: <Icon.DeleteColumnOutlined/>,
    DeleteFilled: <Icon.DeleteFilled/>,
    DeleteOutlined: <Icon.DeleteOutlined/>,
    DeleteRowOutlined: <Icon.DeleteRowOutlined/>,
    DeleteTwoTone: <Icon.DeleteTwoTone/>,
    DeliveredProcedureOutlined: <Icon.DeliveredProcedureOutlined/>,
    DeploymentUnitOutlined: <Icon.DeploymentUnitOutlined/>,
    DesktopOutlined: <Icon.DesktopOutlined/>,
    DiffFilled: <Icon.DiffFilled/>,
    DiffOutlined: <Icon.DiffOutlined/>,
    DiffTwoTone: <Icon.DiffTwoTone/>,
    DingdingOutlined: <Icon.DingdingOutlined/>,
    DingtalkCircleFilled: <Icon.DingtalkCircleFilled/>,
    DingtalkOutlined: <Icon.DingtalkOutlined/>,
    DingtalkSquareFilled: <Icon.DingtalkSquareFilled/>,
    DisconnectOutlined: <Icon.DisconnectOutlined/>,
    DislikeFilled: <Icon.DislikeFilled/>,
    DislikeOutlined: <Icon.DislikeOutlined/>,
    DislikeTwoTone: <Icon.DislikeTwoTone/>,
    DollarCircleFilled: <Icon.DollarCircleFilled/>,
    DollarCircleOutlined: <Icon.DollarCircleOutlined/>,
    DollarCircleTwoTone: <Icon.DollarCircleTwoTone/>,
    DollarOutlined: <Icon.DollarOutlined/>,
    DollarTwoTone: <Icon.DollarTwoTone/>,
    DotChartOutlined: <Icon.DotChartOutlined/>,
    DoubleLeftOutlined: <Icon.DoubleLeftOutlined/>,
    DoubleRightOutlined: <Icon.DoubleRightOutlined/>,
    DownCircleFilled: <Icon.DownCircleFilled/>,
    DownCircleOutlined: <Icon.DownCircleOutlined/>,
    DownCircleTwoTone: <Icon.DownCircleTwoTone/>,
    DownOutlined: <Icon.DownOutlined/>,
    DownSquareFilled: <Icon.DownSquareFilled/>,
    DownSquareOutlined: <Icon.DownSquareOutlined/>,
    DownSquareTwoTone: <Icon.DownSquareTwoTone/>,
    DownloadOutlined: <Icon.DownloadOutlined/>,
    DragOutlined: <Icon.DragOutlined/>,
    DribbbleCircleFilled: <Icon.DribbbleCircleFilled/>,
    DribbbleOutlined: <Icon.DribbbleOutlined/>,
    DribbbleSquareFilled: <Icon.DribbbleSquareFilled/>,
    DribbbleSquareOutlined: <Icon.DribbbleSquareOutlined/>,
    DropboxCircleFilled: <Icon.DropboxCircleFilled/>,
    DropboxOutlined: <Icon.DropboxOutlined/>,
    DropboxSquareFilled: <Icon.DropboxSquareFilled/>,
    EditFilled: <Icon.EditFilled/>,
    EditOutlined: <Icon.EditOutlined/>,
    EditTwoTone: <Icon.EditTwoTone/>,
    EllipsisOutlined: <Icon.EllipsisOutlined/>,
    EnterOutlined: <Icon.EnterOutlined/>,
    EnvironmentFilled: <Icon.EnvironmentFilled/>,
    EnvironmentOutlined: <Icon.EnvironmentOutlined/>,
    EnvironmentTwoTone: <Icon.EnvironmentTwoTone/>,
    EuroCircleFilled: <Icon.EuroCircleFilled/>,
    EuroCircleOutlined: <Icon.EuroCircleOutlined/>,
    EuroCircleTwoTone: <Icon.EuroCircleTwoTone/>,
    EuroOutlined: <Icon.EuroOutlined/>,
    EuroTwoTone: <Icon.EuroTwoTone/>,
    ExceptionOutlined: <Icon.ExceptionOutlined/>,
    ExclamationCircleFilled: <Icon.ExclamationCircleFilled/>,
    ExclamationCircleOutlined: <Icon.ExclamationCircleOutlined/>,
    ExclamationCircleTwoTone: <Icon.ExclamationCircleTwoTone/>,
    ExclamationOutlined: <Icon.ExclamationOutlined/>,
    ExpandAltOutlined: <Icon.ExpandAltOutlined/>,
    ExpandOutlined: <Icon.ExpandOutlined/>,
    ExperimentFilled: <Icon.ExperimentFilled/>,
    ExperimentOutlined: <Icon.ExperimentOutlined/>,
    ExperimentTwoTone: <Icon.ExperimentTwoTone/>,
    ExportOutlined: <Icon.ExportOutlined/>,
    EyeFilled: <Icon.EyeFilled/>,
    EyeInvisibleFilled: <Icon.EyeInvisibleFilled/>,
    EyeInvisibleOutlined: <Icon.EyeInvisibleOutlined/>,
    EyeInvisibleTwoTone: <Icon.EyeInvisibleTwoTone/>,
    EyeOutlined: <Icon.EyeOutlined/>,
    EyeTwoTone: <Icon.EyeTwoTone/>,
    FacebookFilled: <Icon.FacebookFilled/>,
    FacebookOutlined: <Icon.FacebookOutlined/>,
    FallOutlined: <Icon.FallOutlined/>,
    FastBackwardFilled: <Icon.FastBackwardFilled/>,
    FastBackwardOutlined: <Icon.FastBackwardOutlined/>,
    FastForwardFilled: <Icon.FastForwardFilled/>,
    FastForwardOutlined: <Icon.FastForwardOutlined/>,
    FieldBinaryOutlined: <Icon.FieldBinaryOutlined/>,
    FieldNumberOutlined: <Icon.FieldNumberOutlined/>,
    FieldStringOutlined: <Icon.FieldStringOutlined/>,
    FieldTimeOutlined: <Icon.FieldTimeOutlined/>,
    FileAddFilled: <Icon.FileAddFilled/>,
    FileAddOutlined: <Icon.FileAddOutlined/>,
    FileAddTwoTone: <Icon.FileAddTwoTone/>,
    FileDoneOutlined: <Icon.FileDoneOutlined/>,
    FileExcelFilled: <Icon.FileExcelFilled/>,
    FileExcelOutlined: <Icon.FileExcelOutlined/>,
    FileExcelTwoTone: <Icon.FileExcelTwoTone/>,
    FileExclamationFilled: <Icon.FileExclamationFilled/>,
    FileExclamationOutlined: <Icon.FileExclamationOutlined/>,
    FileExclamationTwoTone: <Icon.FileExclamationTwoTone/>,
    FileFilled: <Icon.FileFilled/>,
    FileGifOutlined: <Icon.FileGifOutlined/>,
    FileImageFilled: <Icon.FileImageFilled/>,
    FileImageOutlined: <Icon.FileImageOutlined/>,
    FileImageTwoTone: <Icon.FileImageTwoTone/>,
    FileJpgOutlined: <Icon.FileJpgOutlined/>,
    FileMarkdownFilled: <Icon.FileMarkdownFilled/>,
    FileMarkdownOutlined: <Icon.FileMarkdownOutlined/>,
    FileMarkdownTwoTone: <Icon.FileMarkdownTwoTone/>,
    FileOutlined: <Icon.FileOutlined/>,
    FilePdfFilled: <Icon.FilePdfFilled/>,
    FilePdfOutlined: <Icon.FilePdfOutlined/>,
    FilePdfTwoTone: <Icon.FilePdfTwoTone/>,
    FilePptFilled: <Icon.FilePptFilled/>,
    FilePptOutlined: <Icon.FilePptOutlined/>,
    FilePptTwoTone: <Icon.FilePptTwoTone/>,
    FileProtectOutlined: <Icon.FileProtectOutlined/>,
    FileSearchOutlined: <Icon.FileSearchOutlined/>,
    FileSyncOutlined: <Icon.FileSyncOutlined/>,
    FileTextFilled: <Icon.FileTextFilled/>,
    FileTextOutlined: <Icon.FileTextOutlined/>,
    FileTextTwoTone: <Icon.FileTextTwoTone/>,
    FileTwoTone: <Icon.FileTwoTone/>,
    FileUnknownFilled: <Icon.FileUnknownFilled/>,
    FileUnknownOutlined: <Icon.FileUnknownOutlined/>,
    FileUnknownTwoTone: <Icon.FileUnknownTwoTone/>,
    FileWordFilled: <Icon.FileWordFilled/>,
    FileWordOutlined: <Icon.FileWordOutlined/>,
    FileWordTwoTone: <Icon.FileWordTwoTone/>,
    FileZipFilled: <Icon.FileZipFilled/>,
    FileZipOutlined: <Icon.FileZipOutlined/>,
    FileZipTwoTone: <Icon.FileZipTwoTone/>,
    FilterFilled: <Icon.FilterFilled/>,
    FilterOutlined: <Icon.FilterOutlined/>,
    FilterTwoTone: <Icon.FilterTwoTone/>,
    FireFilled: <Icon.FireFilled/>,
    FireOutlined: <Icon.FireOutlined/>,
    FireTwoTone: <Icon.FireTwoTone/>,
    FlagFilled: <Icon.FlagFilled/>,
    FlagOutlined: <Icon.FlagOutlined/>,
    FlagTwoTone: <Icon.FlagTwoTone/>,
    FolderAddFilled: <Icon.FolderAddFilled/>,
    FolderAddOutlined: <Icon.FolderAddOutlined/>,
    FolderAddTwoTone: <Icon.FolderAddTwoTone/>,
    FolderFilled: <Icon.FolderFilled/>,
    FolderOpenFilled: <Icon.FolderOpenFilled/>,
    FolderOpenOutlined: <Icon.FolderOpenOutlined/>,
    FolderOpenTwoTone: <Icon.FolderOpenTwoTone/>,
    FolderOutlined: <Icon.FolderOutlined/>,
    FolderTwoTone: <Icon.FolderTwoTone/>,
    FolderViewOutlined: <Icon.FolderViewOutlined/>,
    FontColorsOutlined: <Icon.FontColorsOutlined/>,
    FontSizeOutlined: <Icon.FontSizeOutlined/>,
    ForkOutlined: <Icon.ForkOutlined/>,
    FormOutlined: <Icon.FormOutlined/>,
    FormatPainterFilled: <Icon.FormatPainterFilled/>,
    FormatPainterOutlined: <Icon.FormatPainterOutlined/>,
    ForwardFilled: <Icon.ForwardFilled/>,
    ForwardOutlined: <Icon.ForwardOutlined/>,
    FrownFilled: <Icon.FrownFilled/>,
    FrownOutlined: <Icon.FrownOutlined/>,
    FrownTwoTone: <Icon.FrownTwoTone/>,
    FullscreenExitOutlined: <Icon.FullscreenExitOutlined/>,
    FullscreenOutlined: <Icon.FullscreenOutlined/>,
    FunctionOutlined: <Icon.FunctionOutlined/>,
    FundFilled: <Icon.FundFilled/>,
    FundOutlined: <Icon.FundOutlined/>,
    FundProjectionScreenOutlined: <Icon.FundProjectionScreenOutlined/>,
    FundTwoTone: <Icon.FundTwoTone/>,
    FundViewOutlined: <Icon.FundViewOutlined/>,
    FunnelPlotFilled: <Icon.FunnelPlotFilled/>,
    FunnelPlotOutlined: <Icon.FunnelPlotOutlined/>,
    FunnelPlotTwoTone: <Icon.FunnelPlotTwoTone/>,
    GatewayOutlined: <Icon.GatewayOutlined/>,
    GifOutlined: <Icon.GifOutlined/>,
    GiftFilled: <Icon.GiftFilled/>,
    GiftOutlined: <Icon.GiftOutlined/>,
    GiftTwoTone: <Icon.GiftTwoTone/>,
    GithubFilled: <Icon.GithubFilled/>,
    GithubOutlined: <Icon.GithubOutlined/>,
    GitlabFilled: <Icon.GitlabFilled/>,
    GitlabOutlined: <Icon.GitlabOutlined/>,
    GlobalOutlined: <Icon.GlobalOutlined/>,
    GoldFilled: <Icon.GoldFilled/>,
    GoldOutlined: <Icon.GoldOutlined/>,
    GoldTwoTone: <Icon.GoldTwoTone/>,
    GoldenFilled: <Icon.GoldenFilled/>,
    GoogleCircleFilled: <Icon.GoogleCircleFilled/>,
    GoogleOutlined: <Icon.GoogleOutlined/>,
    GooglePlusCircleFilled: <Icon.GooglePlusCircleFilled/>,
    GooglePlusOutlined: <Icon.GooglePlusOutlined/>,
    GooglePlusSquareFilled: <Icon.GooglePlusSquareFilled/>,
    GoogleSquareFilled: <Icon.GoogleSquareFilled/>,
    GroupOutlined: <Icon.GroupOutlined/>,
    HddFilled: <Icon.HddFilled/>,
    HddOutlined: <Icon.HddOutlined/>,
    HddTwoTone: <Icon.HddTwoTone/>,
    HeartFilled: <Icon.HeartFilled/>,
    HeartOutlined: <Icon.HeartOutlined/>,
    HeartTwoTone: <Icon.HeartTwoTone/>,
    HeatMapOutlined: <Icon.HeatMapOutlined/>,
    HighlightFilled: <Icon.HighlightFilled/>,
    HighlightOutlined: <Icon.HighlightOutlined/>,
    HighlightTwoTone: <Icon.HighlightTwoTone/>,
    HistoryOutlined: <Icon.HistoryOutlined/>,
    HomeFilled: <Icon.HomeFilled/>,
    HomeOutlined: <Icon.HomeOutlined/>,
    HomeTwoTone: <Icon.HomeTwoTone/>,
    HourglassFilled: <Icon.HourglassFilled/>,
    HourglassOutlined: <Icon.HourglassOutlined/>,
    HourglassTwoTone: <Icon.HourglassTwoTone/>,
    Html5Filled: <Icon.Html5Filled/>,
    Html5Outlined: <Icon.Html5Outlined/>,
    Html5TwoTone: <Icon.Html5TwoTone/>,
    IdcardFilled: <Icon.IdcardFilled/>,
    IdcardOutlined: <Icon.IdcardOutlined/>,
    IdcardTwoTone: <Icon.IdcardTwoTone/>,
    IeCircleFilled: <Icon.IeCircleFilled/>,
    IeOutlined: <Icon.IeOutlined/>,
    IeSquareFilled: <Icon.IeSquareFilled/>,
    ImportOutlined: <Icon.ImportOutlined/>,
    InboxOutlined: <Icon.InboxOutlined/>,
    InfoCircleFilled: <Icon.InfoCircleFilled/>,
    InfoCircleOutlined: <Icon.InfoCircleOutlined/>,
    InfoCircleTwoTone: <Icon.InfoCircleTwoTone/>,
    InfoOutlined: <Icon.InfoOutlined/>,
    InsertRowAboveOutlined: <Icon.InsertRowAboveOutlined/>,
    InsertRowBelowOutlined: <Icon.InsertRowBelowOutlined/>,
    InsertRowLeftOutlined: <Icon.InsertRowLeftOutlined/>,
    InsertRowRightOutlined: <Icon.InsertRowRightOutlined/>,
    InstagramFilled: <Icon.InstagramFilled/>,
    InstagramOutlined: <Icon.InstagramOutlined/>,
    InsuranceFilled: <Icon.InsuranceFilled/>,
    InsuranceOutlined: <Icon.InsuranceOutlined/>,
    InsuranceTwoTone: <Icon.InsuranceTwoTone/>,
    InteractionFilled: <Icon.InteractionFilled/>,
    InteractionOutlined: <Icon.InteractionOutlined/>,
    InteractionTwoTone: <Icon.InteractionTwoTone/>,
    IssuesCloseOutlined: <Icon.IssuesCloseOutlined/>,
    ItalicOutlined: <Icon.ItalicOutlined/>,
    KeyOutlined: <Icon.KeyOutlined/>,
    LaptopOutlined: <Icon.LaptopOutlined/>,
    LayoutFilled: <Icon.LayoutFilled/>,
    LayoutOutlined: <Icon.LayoutOutlined/>,
    LayoutTwoTone: <Icon.LayoutTwoTone/>,
    LeftCircleFilled: <Icon.LeftCircleFilled/>,
    LeftCircleOutlined: <Icon.LeftCircleOutlined/>,
    LeftCircleTwoTone: <Icon.LeftCircleTwoTone/>,
    LeftOutlined: <Icon.LeftOutlined/>,
    LeftSquareFilled: <Icon.LeftSquareFilled/>,
    LeftSquareOutlined: <Icon.LeftSquareOutlined/>,
    LeftSquareTwoTone: <Icon.LeftSquareTwoTone/>,
    LikeFilled: <Icon.LikeFilled/>,
    LikeOutlined: <Icon.LikeOutlined/>,
    LikeTwoTone: <Icon.LikeTwoTone/>,
    LineChartOutlined: <Icon.LineChartOutlined/>,
    LineHeightOutlined: <Icon.LineHeightOutlined/>,
    LineOutlined: <Icon.LineOutlined/>,
    LinkOutlined: <Icon.LinkOutlined/>,
    LinkedinFilled: <Icon.LinkedinFilled/>,
    LinkedinOutlined: <Icon.LinkedinOutlined/>,
    Loading3QuartersOutlined: <Icon.Loading3QuartersOutlined/>,
    LoadingOutlined: <Icon.LoadingOutlined/>,
    LockFilled: <Icon.LockFilled/>,
    LockOutlined: <Icon.LockOutlined/>,
    LockTwoTone: <Icon.LockTwoTone/>,
    LoginOutlined: <Icon.LoginOutlined/>,
    LogoutOutlined: <Icon.LogoutOutlined/>,
    MacCommandFilled: <Icon.MacCommandFilled/>,
    MacCommandOutlined: <Icon.MacCommandOutlined/>,
    MailFilled: <Icon.MailFilled/>,
    MailOutlined: <Icon.MailOutlined/>,
    MailTwoTone: <Icon.MailTwoTone/>,
    ManOutlined: <Icon.ManOutlined/>,
    MedicineBoxFilled: <Icon.MedicineBoxFilled/>,
    MedicineBoxOutlined: <Icon.MedicineBoxOutlined/>,
    MedicineBoxTwoTone: <Icon.MedicineBoxTwoTone/>,
    MediumCircleFilled: <Icon.MediumCircleFilled/>,
    MediumOutlined: <Icon.MediumOutlined/>,
    MediumSquareFilled: <Icon.MediumSquareFilled/>,
    MediumWorkmarkOutlined: <Icon.MediumWorkmarkOutlined/>,
    MehFilled: <Icon.MehFilled/>,
    MehOutlined: <Icon.MehOutlined/>,
    MehTwoTone: <Icon.MehTwoTone/>,
    MenuFoldOutlined: <Icon.MenuFoldOutlined/>,
    MenuOutlined: <Icon.MenuOutlined/>,
    MenuUnfoldOutlined: <Icon.MenuUnfoldOutlined/>,
    MergeCellsOutlined: <Icon.MergeCellsOutlined/>,
    MessageFilled: <Icon.MessageFilled/>,
    MessageOutlined: <Icon.MessageOutlined/>,
    MessageTwoTone: <Icon.MessageTwoTone/>,
    MinusCircleFilled: <Icon.MinusCircleFilled/>,
    MinusCircleOutlined: <Icon.MinusCircleOutlined/>,
    MinusCircleTwoTone: <Icon.MinusCircleTwoTone/>,
    MinusOutlined: <Icon.MinusOutlined/>,
    MinusSquareFilled: <Icon.MinusSquareFilled/>,
    MinusSquareOutlined: <Icon.MinusSquareOutlined/>,
    MinusSquareTwoTone: <Icon.MinusSquareTwoTone/>,
    MobileFilled: <Icon.MobileFilled/>,
    MobileOutlined: <Icon.MobileOutlined/>,
    MobileTwoTone: <Icon.MobileTwoTone/>,
    MoneyCollectFilled: <Icon.MoneyCollectFilled/>,
    MoneyCollectOutlined: <Icon.MoneyCollectOutlined/>,
    MoneyCollectTwoTone: <Icon.MoneyCollectTwoTone/>,
    MonitorOutlined: <Icon.MonitorOutlined/>,
    MoreOutlined: <Icon.MoreOutlined/>,
    NodeCollapseOutlined: <Icon.NodeCollapseOutlined/>,
    NodeExpandOutlined: <Icon.NodeExpandOutlined/>,
    NodeIndexOutlined: <Icon.NodeIndexOutlined/>,
    NotificationFilled: <Icon.NotificationFilled/>,
    NotificationOutlined: <Icon.NotificationOutlined/>,
    NotificationTwoTone: <Icon.NotificationTwoTone/>,
    NumberOutlined: <Icon.NumberOutlined/>,
    OneToOneOutlined: <Icon.OneToOneOutlined/>,
    OrderedListOutlined: <Icon.OrderedListOutlined/>,
    PaperClipOutlined: <Icon.PaperClipOutlined/>,
    PartitionOutlined: <Icon.PartitionOutlined/>,
    PauseCircleFilled: <Icon.PauseCircleFilled/>,
    PauseCircleOutlined: <Icon.PauseCircleOutlined/>,
    PauseCircleTwoTone: <Icon.PauseCircleTwoTone/>,
    PauseOutlined: <Icon.PauseOutlined/>,
    PayCircleFilled: <Icon.PayCircleFilled/>,
    PayCircleOutlined: <Icon.PayCircleOutlined/>,
    PercentageOutlined: <Icon.PercentageOutlined/>,
    PhoneFilled: <Icon.PhoneFilled/>,
    PhoneOutlined: <Icon.PhoneOutlined/>,
    PhoneTwoTone: <Icon.PhoneTwoTone/>,
    PicCenterOutlined: <Icon.PicCenterOutlined/>,
    PicLeftOutlined: <Icon.PicLeftOutlined/>,
    PicRightOutlined: <Icon.PicRightOutlined/>,
    PictureFilled: <Icon.PictureFilled/>,
    PictureOutlined: <Icon.PictureOutlined/>,
    PictureTwoTone: <Icon.PictureTwoTone/>,
    PieChartFilled: <Icon.PieChartFilled/>,
    PieChartOutlined: <Icon.PieChartOutlined/>,
    PieChartTwoTone: <Icon.PieChartTwoTone/>,
    PlayCircleFilled: <Icon.PlayCircleFilled/>,
    PlayCircleOutlined: <Icon.PlayCircleOutlined/>,
    PlayCircleTwoTone: <Icon.PlayCircleTwoTone/>,
    PlaySquareFilled: <Icon.PlaySquareFilled/>,
    PlaySquareOutlined: <Icon.PlaySquareOutlined/>,
    PlaySquareTwoTone: <Icon.PlaySquareTwoTone/>,
    PlusCircleFilled: <Icon.PlusCircleFilled/>,
    PlusCircleOutlined: <Icon.PlusCircleOutlined/>,
    PlusCircleTwoTone: <Icon.PlusCircleTwoTone/>,
    PlusOutlined: <Icon.PlusOutlined/>,
    PlusSquareFilled: <Icon.PlusSquareFilled/>,
    PlusSquareOutlined: <Icon.PlusSquareOutlined/>,
    PlusSquareTwoTone: <Icon.PlusSquareTwoTone/>,
    PoundCircleFilled: <Icon.PoundCircleFilled/>,
    PoundCircleOutlined: <Icon.PoundCircleOutlined/>,
    PoundCircleTwoTone: <Icon.PoundCircleTwoTone/>,
    PoundOutlined: <Icon.PoundOutlined/>,
    PoweroffOutlined: <Icon.PoweroffOutlined/>,
    PrinterFilled: <Icon.PrinterFilled/>,
    PrinterOutlined: <Icon.PrinterOutlined/>,
    PrinterTwoTone: <Icon.PrinterTwoTone/>,
    ProfileFilled: <Icon.ProfileFilled/>,
    ProfileOutlined: <Icon.ProfileOutlined/>,
    ProfileTwoTone: <Icon.ProfileTwoTone/>,
    ProjectFilled: <Icon.ProjectFilled/>,
    ProjectOutlined: <Icon.ProjectOutlined/>,
    ProjectTwoTone: <Icon.ProjectTwoTone/>,
    PropertySafetyFilled: <Icon.PropertySafetyFilled/>,
    PropertySafetyOutlined: <Icon.PropertySafetyOutlined/>,
    PropertySafetyTwoTone: <Icon.PropertySafetyTwoTone/>,
    PullRequestOutlined: <Icon.PullRequestOutlined/>,
    PushpinFilled: <Icon.PushpinFilled/>,
    PushpinOutlined: <Icon.PushpinOutlined/>,
    PushpinTwoTone: <Icon.PushpinTwoTone/>,
    QqCircleFilled: <Icon.QqCircleFilled/>,
    QqOutlined: <Icon.QqOutlined/>,
    QqSquareFilled: <Icon.QqSquareFilled/>,
    QrcodeOutlined: <Icon.QrcodeOutlined/>,
    QuestionCircleFilled: <Icon.QuestionCircleFilled/>,
    QuestionCircleOutlined: <Icon.QuestionCircleOutlined/>,
    QuestionCircleTwoTone: <Icon.QuestionCircleTwoTone/>,
    QuestionOutlined: <Icon.QuestionOutlined/>,
    RadarChartOutlined: <Icon.RadarChartOutlined/>,
    RadiusBottomleftOutlined: <Icon.RadiusBottomleftOutlined/>,
    RadiusBottomrightOutlined: <Icon.RadiusBottomrightOutlined/>,
    RadiusSettingOutlined: <Icon.RadiusSettingOutlined/>,
    RadiusUpleftOutlined: <Icon.RadiusUpleftOutlined/>,
    RadiusUprightOutlined: <Icon.RadiusUprightOutlined/>,
    ReadFilled: <Icon.ReadFilled/>,
    ReadOutlined: <Icon.ReadOutlined/>,
    ReconciliationFilled: <Icon.ReconciliationFilled/>,
    ReconciliationOutlined: <Icon.ReconciliationOutlined/>,
    ReconciliationTwoTone: <Icon.ReconciliationTwoTone/>,
    RedEnvelopeFilled: <Icon.RedEnvelopeFilled/>,
    RedEnvelopeOutlined: <Icon.RedEnvelopeOutlined/>,
    RedEnvelopeTwoTone: <Icon.RedEnvelopeTwoTone/>,
    RedditCircleFilled: <Icon.RedditCircleFilled/>,
    RedditOutlined: <Icon.RedditOutlined/>,
    RedditSquareFilled: <Icon.RedditSquareFilled/>,
    RedoOutlined: <Icon.RedoOutlined/>,
    ReloadOutlined: <Icon.ReloadOutlined/>,
    RestFilled: <Icon.RestFilled/>,
    RestOutlined: <Icon.RestOutlined/>,
    RestTwoTone: <Icon.RestTwoTone/>,
    RetweetOutlined: <Icon.RetweetOutlined/>,
    RightCircleFilled: <Icon.RightCircleFilled/>,
    RightCircleOutlined: <Icon.RightCircleOutlined/>,
    RightCircleTwoTone: <Icon.RightCircleTwoTone/>,
    RightOutlined: <Icon.RightOutlined/>,
    RightSquareFilled: <Icon.RightSquareFilled/>,
    RightSquareOutlined: <Icon.RightSquareOutlined/>,
    RightSquareTwoTone: <Icon.RightSquareTwoTone/>,
    RiseOutlined: <Icon.RiseOutlined/>,
    RobotFilled: <Icon.RobotFilled/>,
    RobotOutlined: <Icon.RobotOutlined/>,
    RocketFilled: <Icon.RocketFilled/>,
    RocketOutlined: <Icon.RocketOutlined/>,
    RocketTwoTone: <Icon.RocketTwoTone/>,
    RollbackOutlined: <Icon.RollbackOutlined/>,
    RotateLeftOutlined: <Icon.RotateLeftOutlined/>,
    RotateRightOutlined: <Icon.RotateRightOutlined/>,
    SafetyCertificateFilled: <Icon.SafetyCertificateFilled/>,
    SafetyCertificateOutlined: <Icon.SafetyCertificateOutlined/>,
    SafetyCertificateTwoTone: <Icon.SafetyCertificateTwoTone/>,
    SafetyOutlined: <Icon.SafetyOutlined/>,
    SaveFilled: <Icon.SaveFilled/>,
    SaveOutlined: <Icon.SaveOutlined/>,
    SaveTwoTone: <Icon.SaveTwoTone/>,
    ScanOutlined: <Icon.ScanOutlined/>,
    ScheduleFilled: <Icon.ScheduleFilled/>,
    ScheduleOutlined: <Icon.ScheduleOutlined/>,
    ScheduleTwoTone: <Icon.ScheduleTwoTone/>,
    ScissorOutlined: <Icon.ScissorOutlined/>,
    SearchOutlined: <Icon.SearchOutlined/>,
    SecurityScanFilled: <Icon.SecurityScanFilled/>,
    SecurityScanOutlined: <Icon.SecurityScanOutlined/>,
    SecurityScanTwoTone: <Icon.SecurityScanTwoTone/>,
    SelectOutlined: <Icon.SelectOutlined/>,
    SendOutlined: <Icon.SendOutlined/>,
    SettingFilled: <Icon.SettingFilled/>,
    SettingOutlined: <Icon.SettingOutlined/>,
    SettingTwoTone: <Icon.SettingTwoTone/>,
    ShakeOutlined: <Icon.ShakeOutlined/>,
    ShareAltOutlined: <Icon.ShareAltOutlined/>,
    ShopFilled: <Icon.ShopFilled/>,
    ShopOutlined: <Icon.ShopOutlined/>,
    ShopTwoTone: <Icon.ShopTwoTone/>,
    ShoppingCartOutlined: <Icon.ShoppingCartOutlined/>,
    ShoppingFilled: <Icon.ShoppingFilled/>,
    ShoppingOutlined: <Icon.ShoppingOutlined/>,
    ShoppingTwoTone: <Icon.ShoppingTwoTone/>,
    ShrinkOutlined: <Icon.ShrinkOutlined/>,
    SignalFilled: <Icon.SignalFilled/>,
    SisternodeOutlined: <Icon.SisternodeOutlined/>,
    SketchCircleFilled: <Icon.SketchCircleFilled/>,
    SketchOutlined: <Icon.SketchOutlined/>,
    SketchSquareFilled: <Icon.SketchSquareFilled/>,
    SkinFilled: <Icon.SkinFilled/>,
    SkinOutlined: <Icon.SkinOutlined/>,
    SkinTwoTone: <Icon.SkinTwoTone/>,
    SkypeFilled: <Icon.SkypeFilled/>,
    SkypeOutlined: <Icon.SkypeOutlined/>,
    SlackCircleFilled: <Icon.SlackCircleFilled/>,
    SlackOutlined: <Icon.SlackOutlined/>,
    SlackSquareFilled: <Icon.SlackSquareFilled/>,
    SlackSquareOutlined: <Icon.SlackSquareOutlined/>,
    SlidersFilled: <Icon.SlidersFilled/>,
    SlidersOutlined: <Icon.SlidersOutlined/>,
    SlidersTwoTone: <Icon.SlidersTwoTone/>,
    SmallDashOutlined: <Icon.SmallDashOutlined/>,
    SmileFilled: <Icon.SmileFilled/>,
    SmileOutlined: <Icon.SmileOutlined/>,
    SmileTwoTone: <Icon.SmileTwoTone/>,
    SnippetsFilled: <Icon.SnippetsFilled/>,
    SnippetsOutlined: <Icon.SnippetsOutlined/>,
    SnippetsTwoTone: <Icon.SnippetsTwoTone/>,
    SolutionOutlined: <Icon.SolutionOutlined/>,
    SortAscendingOutlined: <Icon.SortAscendingOutlined/>,
    SortDescendingOutlined: <Icon.SortDescendingOutlined/>,
    SoundFilled: <Icon.SoundFilled/>,
    SoundOutlined: <Icon.SoundOutlined/>,
    SoundTwoTone: <Icon.SoundTwoTone/>,
    SplitCellsOutlined: <Icon.SplitCellsOutlined/>,
    StarFilled: <Icon.StarFilled/>,
    StarOutlined: <Icon.StarOutlined/>,
    StarTwoTone: <Icon.StarTwoTone/>,
    StepBackwardFilled: <Icon.StepBackwardFilled/>,
    StepBackwardOutlined: <Icon.StepBackwardOutlined/>,
    StepForwardFilled: <Icon.StepForwardFilled/>,
    StepForwardOutlined: <Icon.StepForwardOutlined/>,
    StockOutlined: <Icon.StockOutlined/>,
    StopFilled: <Icon.StopFilled/>,
    StopOutlined: <Icon.StopOutlined/>,
    StopTwoTone: <Icon.StopTwoTone/>,
    StrikethroughOutlined: <Icon.StrikethroughOutlined/>,
    SubnodeOutlined: <Icon.SubnodeOutlined/>,
    SwapLeftOutlined: <Icon.SwapLeftOutlined/>,
    SwapOutlined: <Icon.SwapOutlined/>,
    SwapRightOutlined: <Icon.SwapRightOutlined/>,
    SwitcherFilled: <Icon.SwitcherFilled/>,
    SwitcherOutlined: <Icon.SwitcherOutlined/>,
    SwitcherTwoTone: <Icon.SwitcherTwoTone/>,
    SyncOutlined: <Icon.SyncOutlined/>,
    TableOutlined: <Icon.TableOutlined/>,
    TabletFilled: <Icon.TabletFilled/>,
    TabletOutlined: <Icon.TabletOutlined/>,
    TabletTwoTone: <Icon.TabletTwoTone/>,
    TagFilled: <Icon.TagFilled/>,
    TagOutlined: <Icon.TagOutlined/>,
    TagTwoTone: <Icon.TagTwoTone/>,
    TagsFilled: <Icon.TagsFilled/>,
    TagsOutlined: <Icon.TagsOutlined/>,
    TagsTwoTone: <Icon.TagsTwoTone/>,
    TaobaoCircleFilled: <Icon.TaobaoCircleFilled/>,
    TaobaoCircleOutlined: <Icon.TaobaoCircleOutlined/>,
    TaobaoOutlined: <Icon.TaobaoOutlined/>,
    TaobaoSquareFilled: <Icon.TaobaoSquareFilled/>,
    TeamOutlined: <Icon.TeamOutlined/>,
    ThunderboltFilled: <Icon.ThunderboltFilled/>,
    ThunderboltOutlined: <Icon.ThunderboltOutlined/>,
    ThunderboltTwoTone: <Icon.ThunderboltTwoTone/>,
    ToTopOutlined: <Icon.ToTopOutlined/>,
    ToolFilled: <Icon.ToolFilled/>,
    ToolOutlined: <Icon.ToolOutlined/>,
    ToolTwoTone: <Icon.ToolTwoTone/>,
    TrademarkCircleFilled: <Icon.TrademarkCircleFilled/>,
    TrademarkCircleOutlined: <Icon.TrademarkCircleOutlined/>,
    TrademarkCircleTwoTone: <Icon.TrademarkCircleTwoTone/>,
    TrademarkOutlined: <Icon.TrademarkOutlined/>,
    TransactionOutlined: <Icon.TransactionOutlined/>,
    TranslationOutlined: <Icon.TranslationOutlined/>,
    TrophyFilled: <Icon.TrophyFilled/>,
    TrophyOutlined: <Icon.TrophyOutlined/>,
    TrophyTwoTone: <Icon.TrophyTwoTone/>,
    TwitterCircleFilled: <Icon.TwitterCircleFilled/>,
    TwitterOutlined: <Icon.TwitterOutlined/>,
    TwitterSquareFilled: <Icon.TwitterSquareFilled/>,
    UnderlineOutlined: <Icon.UnderlineOutlined/>,
    UndoOutlined: <Icon.UndoOutlined/>,
    UngroupOutlined: <Icon.UngroupOutlined/>,
    UnlockFilled: <Icon.UnlockFilled/>,
    UnlockOutlined: <Icon.UnlockOutlined/>,
    UnlockTwoTone: <Icon.UnlockTwoTone/>,
    UnorderedListOutlined: <Icon.UnorderedListOutlined/>,
    UpCircleFilled: <Icon.UpCircleFilled/>,
    UpCircleOutlined: <Icon.UpCircleOutlined/>,
    UpCircleTwoTone: <Icon.UpCircleTwoTone/>,
    UpOutlined: <Icon.UpOutlined/>,
    UpSquareFilled: <Icon.UpSquareFilled/>,
    UpSquareOutlined: <Icon.UpSquareOutlined/>,
    UpSquareTwoTone: <Icon.UpSquareTwoTone/>,
    UploadOutlined: <Icon.UploadOutlined/>,
    UsbFilled: <Icon.UsbFilled/>,
    UsbOutlined: <Icon.UsbOutlined/>,
    UsbTwoTone: <Icon.UsbTwoTone/>,
    UserAddOutlined: <Icon.UserAddOutlined/>,
    UserDeleteOutlined: <Icon.UserDeleteOutlined/>,
    UserOutlined: <Icon.UserOutlined/>,
    UserSwitchOutlined: <Icon.UserSwitchOutlined/>,
    UsergroupAddOutlined: <Icon.UsergroupAddOutlined/>,
    UsergroupDeleteOutlined: <Icon.UsergroupDeleteOutlined/>,
    VerifiedOutlined: <Icon.VerifiedOutlined/>,
    VerticalAlignBottomOutlined: <Icon.VerticalAlignBottomOutlined/>,
    VerticalAlignMiddleOutlined: <Icon.VerticalAlignMiddleOutlined/>,
    VerticalAlignTopOutlined: <Icon.VerticalAlignTopOutlined/>,
    VerticalLeftOutlined: <Icon.VerticalLeftOutlined/>,
    VerticalRightOutlined: <Icon.VerticalRightOutlined/>,
    VideoCameraAddOutlined: <Icon.VideoCameraAddOutlined/>,
    VideoCameraFilled: <Icon.VideoCameraFilled/>,
    VideoCameraOutlined: <Icon.VideoCameraOutlined/>,
    VideoCameraTwoTone: <Icon.VideoCameraTwoTone/>,
    WalletFilled: <Icon.WalletFilled/>,
    WalletOutlined: <Icon.WalletOutlined/>,
    WalletTwoTone: <Icon.WalletTwoTone/>,
    WarningFilled: <Icon.WarningFilled/>,
    WarningOutlined: <Icon.WarningOutlined/>,
    WarningTwoTone: <Icon.WarningTwoTone/>,
    WechatFilled: <Icon.WechatFilled/>,
    WechatOutlined: <Icon.WechatOutlined/>,
    WeiboCircleFilled: <Icon.WeiboCircleFilled/>,
    WeiboCircleOutlined: <Icon.WeiboCircleOutlined/>,
    WeiboOutlined: <Icon.WeiboOutlined/>,
    WeiboSquareFilled: <Icon.WeiboSquareFilled/>,
    WeiboSquareOutlined: <Icon.WeiboSquareOutlined/>,
    WhatsAppOutlined: <Icon.WhatsAppOutlined/>,
    WifiOutlined: <Icon.WifiOutlined/>,
    WindowsFilled: <Icon.WindowsFilled/>,
    WindowsOutlined: <Icon.WindowsOutlined/>,
    WomanOutlined: <Icon.WomanOutlined/>,
    YahooFilled: <Icon.YahooFilled/>,
    YahooOutlined: <Icon.YahooOutlined/>,
    YoutubeFilled: <Icon.YoutubeFilled/>,
    YoutubeOutlined: <Icon.YoutubeOutlined/>,
    YuqueFilled: <Icon.YuqueFilled/>,
    YuqueOutlined: <Icon.YuqueOutlined/>,
    ZhihuCircleFilled: <Icon.ZhihuCircleFilled/>,
    ZhihuOutlined: <Icon.ZhihuOutlined/>,
    ZhihuSquareFilled: <Icon.ZhihuSquareFilled/>,
    ZoomInOutlined: <Icon.ZoomInOutlined/>,
    ZoomOutOutlined: <Icon.ZoomOutOutlined/>
}