import React from "react"
import _ from "underscore"
import {Button, Spin, Typography} from "antd";
import IViewType from "model/interface/dataStorage/view/IViewType";
import ContentTypePicker from "../../../../shared/pickers/ContentTypePicker";
import ViewTypesService, {VIEW_TYPE_CALENDAR} from "../../../../../model/service/dataStorage/ViewTypesService";
import {IViewStepProps} from "./ViewUnitForm";

interface IState {
    viewType?: IViewType
    viewTypes: IViewType[],
    loading: boolean
}

class ViewUnitContentTypes extends React.Component<IViewStepProps, IState> {
    constructor(props: IViewStepProps) {
        super(props);
        this.state = {
            loading: false,
            viewTypes: []
        }
    }

    componentDidMount() {
        this.load()
    }

    load(): void {
        this.setState({loading: true}, () => {
            ViewTypesService.collectionList().then(viewTypes => {
                this.setState({
                    viewTypes,
                    loading: false,
                    viewType: _.find(viewTypes, viewType => viewType.name === this.props.resource.type)
                })
            })
        })
    }

    onChange = (uuids?: string[] | string) => {
        const {viewType} = this.state
        const {label} = this.props.resource
        if (viewType?.multiple && Array.isArray(uuids)) {
            this.props.onChange({
                contentTypes: uuids
            })
        } else if (typeof uuids === "string") {
            const contentType = this.props.contentTypes.find(c => c.uuid === uuids)
            let values: any = {contentTypes: [uuids]}
            if (!label && contentType) {
                values.label = contentType.label
            }
            this.props.onChange(values)
        } else {
            this.props.onChange({contentTypes: []})
        }
    }

    onConfirm = () => {
        const {resource, view} = this.props
        const {viewType} = this.state
        const allIds = resource.contentTypes.length === 0 ? [view.contentType] : resource.contentTypes
        if (allIds.length > 0) {
            this.onChange(viewType?.multiple ? allIds : allIds[0])
        }
        this.props.setStep()
    }

    render() {
        const {resource, view} = this.props
        const {viewType, loading} = this.state

        const allIds = resource.contentTypes.length === 0 ? [view.contentType] : resource.contentTypes
        const contentTypeIds = (viewType?.multiple ? allIds : allIds[0])


        let extensions
        if (viewType?.name === VIEW_TYPE_CALENDAR) {
            extensions = ['App\\DataStorage\\Extension\\Event']
        }

        return (
            <Spin spinning={loading}>
                <Typography.Title level={3}>Vyberte typy obsahu</Typography.Title>

                <Typography>
                    {viewType?.multiple}
                    Pro pohled typu <Typography.Text strong>{viewType?.name}</Typography.Text> je možné
                    zvolit <Typography.Text
                    strong>{viewType?.multiple ? "více typů obsahu" : "právě jeden typ obsahu"} </Typography.Text>
                </Typography>
                <div>
                    <ContentTypePicker extensions={extensions} className={'mb-3'} value={contentTypeIds}
                                       multiple={viewType?.multiple}
                                       onChange={this.onChange}/>
                </div>

                <Button disabled={allIds.length < 1} type={"primary"} onClick={this.onConfirm}>
                    Následující krok
                </Button>
                <Button onClick={() => this.props.setStep(true)}>Předchozí krok</Button>
            </Spin>
        )
    }
}

export default ViewUnitContentTypes