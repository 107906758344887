import React from "react";
import {Form, Select, Slider, Switch} from "antd";
import IContainerOptions, {ContainerDisplay} from "../../../../../model/interface/widget/option/IContainerOptions";

interface IProps {
    options: IContainerOptions
}

const displayTypes: { value: ContainerDisplay, label: string }[] = [
    {value: 'd-block', label: 'V řádcích'},
    {value: 'd-flex', label: 'Za sebou'},
    {value: 'box-6', label: 'malé boxy - 6 - 1 boxů vedle sebe podle šířky displeje'},
    {value: 'box-3', label: 'střední boxy - 3 - 1 boxů vedle sebe podle šířky displeje'},
    {value: 'box-2', label: 'velké boxy - 2 - 1 boxů vedle sebe podle šířky displeje'}
]

class ContainerEditor extends React.Component<IProps> {

    render() {
        const {children} = this.props
        return (
            <div>
                <Form.Item name={'display'} label={'Zobrazení'} initialValue={'d-block'}>
                    <Select>
                        {displayTypes.map(({value, label}) => (
                            <Select.Option value={value} key={value}>{label}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item name={'bordered'} label={'Ohraničení'} valuePropName={'checked'}>
                    <Switch/>
                </Form.Item>
                <Form.Item name={'background'} label={'Pozadí'} valuePropName={'checked'}>
                    <Switch/>
                </Form.Item>
                <Form.Item name={'padding'} label={'Odsazení'} initialValue={1}>
                    <Slider min={0} max={5}/>
                </Form.Item>
                <Form.Item name={'margin'} label={'Okraj'} initialValue={0}>
                    <Slider min={0} max={5}/>
                </Form.Item>

                <Form.Item name={'childrenMargin'} label={'Mezery mezi sloupci'} initialValue={1}>
                    <Slider min={0} max={5}/>
                </Form.Item>
                {children}
            </div>
        )
    }
}

export default ContainerEditor