import _ from 'underscore'
import {RootStateOrAny} from "react-redux";
import IMenu from "../../model/interface/ui/IMenu";

export const findAll = (store: RootStateOrAny) => store.setup.menu

export const findOneBy = (store: RootStateOrAny, property: string, value: any): IMenu => _.findWhere(findAll(store), {[property]: value})

export const findOneByKey = (store: RootStateOrAny, key: string): IMenu => findOneBy(store, 'key', key)

export default {
    findAll,
    findOneBy,
    findOneByKey
}