import WidgetType from "../../widget/WidgetType";

class ReportWidgetType extends WidgetType{
    static VIEW = 'view'

    static EMPLOYEE_LOOKUP = 'employee-lookup'

    static NOTIFICATIONS = 'notifications'

    static VACATION = 'vacation'

    static RESOURCE_FINDER_BY_CODE = 'resource-finder-by-code'

    static MENU = 'menu'

    static WORKLOAD_REPORT = 'workload_report'

    static WORKLOAD_ATTENDANCE = 'workload_attendance'
}

export default ReportWidgetType