import React, {RefObject} from "react"
import _ from "underscore"
import {Button, Form, FormInstance, Input, Typography} from "antd";

import RoutePicker from "../../../shared/pickers/RoutePicker";
import {IViewSetStepProps} from "./ViewConfiguration";
import ContentTypePicker from "../../../shared/pickers/ContentTypePicker";


export const getNestedFieldOptions = (field: string) => ['options', field]

interface IStateViewProperties {
    formRef: RefObject<FormInstance>,
    values: { [x: string]: string }
}

interface IPropsViewPropertiesStep extends IViewSetStepProps {

}

class ViewProperties extends React.Component<IPropsViewPropertiesStep, IStateViewProperties> {
    constructor(props: IPropsViewPropertiesStep) {
        super(props);
        this.state = {
            formRef: React.createRef(),
            values: _.extend(props.resource, {})
        }
    }

    setValue = (values: any) => {
        this.setState(state => ({values: {...state.values, ...values}}), () => this.props.onChange(values))
    }

    confirm(back?: boolean) {
        this.state.formRef.current?.validateFields().then(values => {
            this.props.onChange(values)
            this.props.setStep(back)
        })
    }

    render() {
        const {resource} = this.props
        const {formRef} = this.state

        const formValues = {
            ...resource,
            route: resource.route
        }

        return (
            <>
                <Form ref={formRef} initialValues={formValues} onValuesChange={this.setValue}>
                    <Typography.Title level={3}>Obecné</Typography.Title>
                    <Form.Item label={"Titulek"} name={"label"}
                               rules={[{required: true, message: 'Vyplňte titulek'}]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item label={"Systémový název"} name={"name"}
                               rules={[{required: true, message: 'Vyplňte systémový název'}]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item label={"Popis"} name={"description"}>
                        <Input.TextArea/>
                    </Form.Item>

                    <Form.Item label={"Výchozí typ obsahu"} name={"contentType"}
                               rules={[{required: true, message: 'Vyplňte typ obsahu'}]}>
                        <ContentTypePicker/>
                    </Form.Item>

                    <Form.Item label={"URL"} name={"route"}>
                        <RoutePicker/>
                    </Form.Item>
                </Form>

                <Button type={"primary"} onClick={() => this.confirm()}>
                    Následující krok
                </Button>
            </>
        )
    }
}

export default ViewProperties