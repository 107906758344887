import _ from 'underscore'
import {RootStateOrAny} from "react-redux";
import INotification, {STATES} from "../../model/interface/ui/INotification";

export const findAll = (store: RootStateOrAny) => store.notifications.notifications

export const findBy = (store: RootStateOrAny, property: string, value: any, limit?: number): INotification[] => _.where(findAll(store), {[property]: value}).slice(0, limit)

export const findOneBy = (store: RootStateOrAny, property: string, value: any): INotification => _.findWhere(findAll(store), {[property]: value})

export const findOneById = (store: RootStateOrAny, id: number): INotification => findOneBy(store, 'id', id)

export const findNotDeleted = (store: RootStateOrAny, limit?: number): INotification[] => findAll(store).filter((notification: INotification) => notification.state !== STATES.STATE_DELETED).slice(0, limit)

export const findUnread = (store: RootStateOrAny, limit?: number): INotification[] => findBy(store, 'state', STATES.STATE_NEW, limit)

export const countBy = (store: RootStateOrAny, property: string, value: any): number => findBy(store, property, value).length

export const countUnread = (store: RootStateOrAny): number => findUnread(store).length

export default {
    findAll,
    findBy,
    findUnread,
    findOneBy,
    findOneById,
    findNotDeleted,
    countBy,
    countUnread
}