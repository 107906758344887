import RestService from "model/service/dataStorage/RestService";
import IRestServiceOptions from "../../interface/api/IRestServiceOptions";
import IRestServiceCollectionResponse from "../../interface/api/IRestServiceCollectionResponse";
import IMenuItem from "../../interface/ui/IMenuItem";

interface IRestMenuItemsServiceCollectionResponse extends IRestServiceCollectionResponse {
    results: Array<IMenuItem>
}

interface IRestMenuItemService {
    collectionList(menu: string | number, options?: IRestServiceOptions): Promise<IRestMenuItemsServiceCollectionResponse>,
    collectionCreate(menu: string | number, data: any): Promise<IMenuItem>,
    resourceRetrieve(menu: string | number, id: number|string): Promise<IMenuItem>,
    resourceUpdate(menu: string | number, id: number|string, data: any): Promise<IMenuItem>,
    resourceDelete(menu: string | number, id: number|string): Promise<void>
    runScript(menu: string | number, id: number|string): Promise<IMenuItem>
}

const MENU_COLLECTION = 'menu/'
const COLLECTION = '/items'
const RUN_SCRIPT_COLLECTION = '/items-run-script'

const MenuItemsService: IRestMenuItemService = {
    collectionList: function (menu, options?) {
        return RestService.collectionList(MENU_COLLECTION + menu + COLLECTION, options as unknown as IRestServiceOptions) as Promise<IRestMenuItemsServiceCollectionResponse>
    },
    collectionCreate: function ( menu,data) {
        return RestService.collectionCreate(MENU_COLLECTION + menu + COLLECTION, data) as Promise<IMenuItem>
    },
    resourceRetrieve: function (menu,id) {
        return RestService.resourceRetrieve(MENU_COLLECTION + menu + COLLECTION, id) as Promise<IMenuItem>
    },
    resourceDelete: function (menu,id) {
        return RestService.resourceDelete(MENU_COLLECTION + menu + COLLECTION, id)
    },
    resourceUpdate: function (menu,id, data) {
        return RestService.resourceUpdate(MENU_COLLECTION + menu + COLLECTION, id, data) as Promise<IMenuItem>
    },
    runScript: function (menu, id) {
        return RestService.resourceRetrieve(MENU_COLLECTION + menu + RUN_SCRIPT_COLLECTION, id, {cache: false}) as Promise<IMenuItem>
    },
}

export default MenuItemsService