import React, {RefObject} from "react"
import {Card, Col, Form, FormInstance, Input, InputNumber, Popover, Row, Select, Switch} from "antd";
import IContentType from "model/interface/dataStorage/IContentType";
import IField, {
    FIELD_FLOAT_TYPES,
    FIELD_NUMBER_TYPES,
    FIELD_TYPE,
    RELATION_FIELD_TYPE
} from "model/interface/dataStorage/IField";
import {connect, RootStateOrAny} from "react-redux";
import moment from "moment";
import IViewItem from "../../../../../../model/interface/dataStorage/view/IViewItem";
import {IViewStepProps} from "../ViewUnitForm";
import IRepositoryService from "../../../../../../model/interface/IRepositoryService";
import FormFieldType from "../../../form/FormElement/formField/FormFieldType";
import FieldOptionsEditor from "../../../content-type/field/FieldOptionsEditor";
import {DOWNLOAD_REQUEST_FORMAT} from "../../../../../../model/service/export/DownloadRequestsService";
import FieldPicker from "../../../../../shared/pickers/FieldPicker";
import selectors from "../../../../../../redux/selectors";
import ICompositeFieldType from "../../../../../../model/interface/dataStorage/field/ICompositeFieldType";
import IFieldType from "../../../../../../model/interface/dataStorage/IFieldType";
import ActionPicker from "../../../../../shared/pickers/ActionPicker";
import {ActionTypes} from "../../../../../../model/interface/dataStorage/IAction";
import FieldEditor from "../../../form/FormElement/optionEditor/FieldEditor";
import ResourcePicker from "../../../../../shared/pickers/ResourcePicker";
import PresenterPicker from "../../../../../shared/pickers/PresenterPicker";

interface IState {

}

interface IProps extends IViewStepProps {
    onChange: (values: IViewItem) => void,
    item: IViewItem
    field: IField
    contentType: IContentType,
    findOneByFullClassName: (className: string) => IRepositoryService,
    findContentTypeBy: (property: string, value: any) => IContentType,
    fieldTypes: IFieldType[],
    compositeFieldTypes: ICompositeFieldType[]
}

class ViewItemForm extends React.Component<IProps, IState> {

    formRef: RefObject<FormInstance> = React.createRef()

    constructor(props: IProps, context: any) {
        super(props, context);
        this.state = {}
    }

    onChange = (values: any) => {
        const {item} = this.props
        this.props.onChange({...item, ...values})
    }

    getField = (targetEntity: string, field: string) => {
        const service = this.props.findOneByFullClassName(targetEntity)
        return service.getFields().find(f => f.name === field)!
    }

    render() {
        const {resource, history, match, field, contentType, item, findOneByFullClassName} = this.props

        const initialValues = {
            ...item,
            options: {
                title: field.label,
                inPlaceType: FieldEditor.detectType(field),
                export: true,
                exportStripTags: true,
                fieldOptions: {...item.options, ...item.options?.fieldOptions},
                columnValueAlign: FIELD_NUMBER_TYPES.includes(field.type) ? 'text-right' : 'text-left',
                ...item.options,
            }
        }

        return (
            <>
                <Form ref={this.formRef} initialValues={initialValues}
                      onValuesChange={(_, values) => this.onChange(values)}>
                    <Row align={"middle"} className={'w-100'} gutter={[6, 6]}>
                        <Col span={12}>
                            <Form.Item label={'Název'} name={['options', 'label']}>
                                <Input size={"small"}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={'Skrýt podle oprávnění k akci'} name={'accessAction'}>
                                <ActionPicker contentTypeUuid={contentType.uuid}/>
                            </Form.Item>
                        </Col>
                        {field.mode === 'relation' && field.targetEntity && (
                            <Col span={12}>
                                <Form.Item label={'Zobrazení'} name={'presenter'}>
                                    <PresenterPicker serviceClassName={field.targetEntity}/>
                                </Form.Item>
                            </Col>
                        )}
                        {field.mode === 'relation' && field.targetEntity && (
                            <Col span={12}>
                                <Form.Item label={'Seřadit podle'} name={['options', 'sortBy']}>
                                    <FieldPicker output={'name'} className={'w-100'} multiple={true}
                                                 namePrefix={field.name + '.'}
                                                 serviceClassName={field.targetEntity}/>
                                </Form.Item>
                            </Col>
                        )}
                        {field.type === FIELD_TYPE.DATE_TIME && (
                            <Col span={12}>
                                <Popover trigger={"focus"}
                                         content={moment().format(item && item.options && item.options.dateFormat && item.options.dateFormat.toString())}>
                                    <Form.Item label={'Formát'} name={['options', 'dateFormat']}>
                                        <Input size={"small"}/>
                                    </Form.Item>
                                </Popover>
                            </Col>
                        )}
                        {FIELD_FLOAT_TYPES.includes(field.type) && (
                            <Col span={12}>
                                <Form.Item label={'Zaokrouhlit na'} name={['options', 'numberPrecision']}>
                                    <InputNumber min={0} max={Number.MAX_SAFE_INTEGER}/>
                                </Form.Item>
                            </Col>
                        )}
                        {field.type === FIELD_TYPE.DATE_TIME && (
                            <Col span={12}>
                                <Form.Item label={'Filtr'} name={['options', 'picker']}>
                                    <Select size={"small"}>
                                        {FormFieldType.FIELD_DATE_PICKER_TYPES.map(type => (
                                            <Select.Option key={type.value}
                                                           value={type.value}>{type.label}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        )}
                        {field.mode !== 'computed' && (
                            <Col span={12}>
                                <Form.Item label={'Editace'} name={['options', 'inlineEdit']} valuePropName={'checked'}>
                                    <Switch/>
                                </Form.Item>
                                {item && item.options && !!item.options.inlineEdit && (
                                    <Card className={'m-2'}>
                                        <Row gutter={[0, 6]}>
                                            <Col span={24}>
                                                <Form.Item label={'Akce'} name={['options', 'inPlaceAction']}>
                                                    <ActionPicker contentTypeUuid={contentType.uuid}/>
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item label={'Akce pro data'}
                                                           name={['options', 'inPlaceDataAction']}>
                                                    <ActionPicker contentTypeUuid={contentType.uuid}
                                                                  types={[ActionTypes.FORM]}/>
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item label={'Zvolte typ vstupu'}
                                                           name={['options', 'inPlaceType']}>
                                                    <Select size={"small"} dropdownMatchSelectWidth={false}>
                                                        {FormFieldType.FIELD_TYPES.map(type => (
                                                            <Select.Option key={type.value} value={type.value}>
                                                                {type.label}
                                                            </Select.Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col>
                                                <FieldOptionsEditor field={field} fieldName={['options', 'inPlaceFieldOptions']}
                                                                    contentType={contentType} forceType={item.options.inPlaceType}
                                                                    options={item.options.inPlaceFieldOptions || field.options}
                                                                    match={match} history={history} collapsable={true}
                                                                    title={'Vlastnosti pole'}/>
                                            </Col>
                                        </Row>
                                    </Card>
                                )}
                            </Col>
                        )}
                        <Col span={12}>
                            <Form.Item label={'Skrýt titulek'} name={['options', 'noLabel']} valuePropName={'checked'}>
                                <Switch/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={'Export'} name={['options', 'export']} valuePropName={'checked'}>
                                <Switch/>
                            </Form.Item>
                            {initialValues.options.export &&
                                (
                                    <Card className={'m-2'}>
                                        <Row gutter={[0, 6]}>
                                            <Col span={24}>
                                                <Form.Item label={'Nezpracovaná hodnota'} valuePropName={'checked'}
                                                           name={['options', 'exportRawValue']}>
                                                    <Switch/>
                                                </Form.Item>
                                            </Col>
                                            {resource.options.exportFormats?.includes(DOWNLOAD_REQUEST_FORMAT.XLSX) && (
                                                {
                                                    [FIELD_TYPE.DATE_TIME]: <>
                                                        <Col span={24}>
                                                            <Form.Item label={'[Xlsx] Povolit výchozí formát data'}
                                                                       valuePropName={'checked'}
                                                                       name={['options', 'exportXlsxDefaultDateFormat']}>
                                                                <Switch/>
                                                            </Form.Item>
                                                        </Col>
                                                        <Form.Item label={'[Xlsx] Vlastní formát data'}
                                                                   name={['options', 'exportXlsxCustomDateFormat']}>
                                                            <Input size={"small"}/>
                                                        </Form.Item>
                                                    </>,
                                                    [FIELD_TYPE.PRICE]:
                                                        <Col span={24}>
                                                            <Form.Item label={'[Xlsx] Povolit výchozí formát ceny'}
                                                                       valuePropName={'checked'}
                                                                       name={['options', 'exportXlsxDefaultPriceFormat']}>
                                                                <Switch/>
                                                            </Form.Item>
                                                        </Col>
                                                }[field.type])}
                                            {[FIELD_TYPE.HTML].includes(field.type) && (
                                                <Col span={24}>
                                                    <Col span={24}>
                                                        <Form.Item label={'Odstraňit HTML z řetězce'}
                                                                   valuePropName={'checked'}
                                                                   name={['options', 'exportStripTags']}>
                                                            <Switch/>
                                                        </Form.Item>
                                                    </Col>
                                                </Col>
                                            )}
                                        </Row>
                                    </Card>
                                )}
                        </Col>
                        <Col span={12}>
                            <Form.Item label={'Minimální šířka'} name={['options', 'minWidth']}>
                                <InputNumber min={0} size={"small"} max={item.options?.maxWidth}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={'Maximální šířka'} name={['options', 'maxWidth']}>
                                <InputNumber min={item?.options?.minWidth || 0} size={"small"}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={'Nezalamovat hodnotu ve sloupci'} name={['options', 'noLineBreak']}
                                       valuePropName={'checked'}>
                                <Switch/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={'Zarovnat hodnotu ve sloupci'} name={['options', 'columnValueAlign']}>
                                <Select size={"small"} dropdownMatchSelectWidth={false}>
                                    <Select.Option key={'left'} value={'text-left'}>
                                        {'Vlevo'}
                                    </Select.Option>
                                    <Select.Option key={'center'} value={'text-center'}>
                                        {'Střed'}
                                    </Select.Option>
                                    <Select.Option key={'right'} value={'text-right'}>
                                        {'Vpravo'}
                                    </Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        {field.targetEntity && field.type === RELATION_FIELD_TYPE.ONE_TO_MANY && (
                            <Col span={12}>
                                <Card size={'small'}>

                                    <Form.Item label={'Rozdělit hodnoty do podsloupců podle'}
                                               name={['options', 'subColumnField']}>
                                        <FieldPicker allowTypes={[RELATION_FIELD_TYPE.MANY_TO_ONE]} className={'w-100'}
                                                     output={'name'} serviceClassName={field.targetEntity}/>
                                    </Form.Item>

                                    {!!field.targetEntity && !!item.options?.subColumnField && (
                                        <>
                                            <Form.Item label={'Rozdělit pouze hodnoty'}
                                                       help={'zvolit hodnoty nebo zobrazit všechny'}
                                                       name={['options', 'subColumnValues']}>
                                                <ResourcePicker multiple={true}
                                                                fetchChoices={() => findOneByFullClassName(this.getField(field.targetEntity!, item.options?.subColumnField!).targetEntity!).choiceList('default')}/>
                                            </Form.Item>
                                            <Form.Item label={'Zobrazení pro název sloupce'}
                                                       name={['options', 'subColumnPresenter']}>
                                                <PresenterPicker
                                                    serviceClassName={this.getField(field.targetEntity, item.options.subColumnField).targetEntity!}/>
                                            </Form.Item>
                                        </>
                                    )}
                                </Card>
                            </Col>
                        )}
                        <Col span={24}>
                            <FieldOptionsEditor fieldName={['options', 'fieldOptions']} history={history} match={match}
                                                options={{...field.options, ...item.options?.fieldOptions}}
                                                contentType={contentType} field={field}/>
                        </Col>
                    </Row>
                </Form>
            </>
        )
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    return {
        fieldTypes: state.setup.fieldTypes,
        compositeFieldTypes: state.setup.compositeFieldTypes,
        findOneByFullClassName: (className: string) => selectors.services.findOneByFullClassName(state, className),
        findContentTypeBy: (property: string, value: any) => selectors.contentTypes.findOneBy(state, property, value),
    }
}

export default connect(mapStateToProps)(ViewItemForm)