import React, {Component, RefObject} from 'react';
import {Button, FormInstance, Input, Select} from "antd";
import {connect, RootStateOrAny} from "react-redux";
import {PlusOutlined} from "@ant-design/icons";
import {ActionType} from "../../../model/interface/dataStorage/IAction";
import IContentType from "../../../model/interface/dataStorage/IContentType";
import selectors from "../../../redux/selectors";
import Utils from "../../../utils";
import IRepositoryService from "../../../model/interface/IRepositoryService";
import IPresenter from "../../../model/interface/dataStorage/IPresenter";
import ContentTypePresentersForm from "../../app/configuration/content-type/presenter/ContentTypePresentersForm";

interface IProps {
    value?: string | IPresenter
    onChange?: (presenter?: string | IPresenter) => void
    className?: string,
    serviceClassName: string,
    findContentType: (value: string) => IContentType
    style?: React.CSSProperties,
    disabled?: boolean
    canAdd?: boolean,
    types?: ActionType[],
    autoSave?: boolean,
    output?: 'object' | 'name' | 'uuid'
    findOneByFullClassName: (className: string) => IRepositoryService
}

interface IState {
    value?: string
    formRef: RefObject<FormInstance>,
    showModal: boolean
}

class PresenterPicker extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            value: Utils.parseObjectToIdentifier(props.value, 'name'),
            formRef: React.createRef(),
            showModal: false
        }
    }

    static defaultProps = {
        canAdd: true,
        autoSave: true,
        output: 'name' as 'name'
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        const {value} = this.props
        if (prevProps.value !== value) {
            this.setState({value: Utils.parseObjectToIdentifier(value, 'uuid')})
        }
    }

    getContentType = () => {
        return this.getService().getContentType?.();
    }

    getService() {
        const {findOneByFullClassName, serviceClassName} = this.props
        return findOneByFullClassName(serviceClassName);
    }

    onPickerChoose = (presenter?: string) => {
        this.setState({value: presenter})
        const presenterObject = presenter && this.getService().getPresenter(presenter)
        presenterObject ? this.updateParent(presenterObject) : this.props.onChange?.(presenter)
    }

    onModalChange = (presenter?: IPresenter) => {
        if (presenter) {
            this.updateParent(presenter);
        }
        this.setState({showModal: false})
    }

    updateParent(presenter: IPresenter) {
        const {onChange, output} = this.props
        if (onChange) {
            switch (output) {
                case "object":
                    return onChange(presenter)
                case "uuid":
                    return onChange(presenter.uuid)
                case "name":
                    return onChange(presenter.name)
            }
        }
    }

    showModal = () => {
        this.setState(state => ({showModal: !state.showModal}))
    }

    getOptions() {
        return this.getService().getPresenterList()
    }

    render() {
        const {value, showModal} = this.state
        const {className, style, disabled, canAdd, autoSave} = this.props
        const contentType = this.getContentType();

        return (
            <div className="route-picker" style={style}>
                {showModal && contentType && (
                    <ContentTypePresentersForm modal={true} saveMode={autoSave ? 'update-state' : 'none'}
                                               contentType={contentType} onSubmit={this.onModalChange}
                    />
                )}
                <Input.Group compact className={'d-flex w-100'}>
                    <Select
                        allowClear
                        className={className + ' flex-grow-1 min-w-0'}
                        disabled={disabled}
                        value={value}
                        placeholder="Vyberte zobrazení"
                        onChange={this.onPickerChoose}
                        filterOption={(input, option) => Utils.stringContains(option?.label, input)}
                    >
                        {this.getOptions().map(option => (
                                <Select.Option key={option.value} value={option.value}>
                                    {option.label}
                                </Select.Option>
                            )
                        )}
                    </Select>
                    {canAdd && contentType &&
                        <Button className={'flex-shrink-0'} disabled={disabled} onClick={this.showModal}
                                icon={<PlusOutlined/>}/>}
                </Input.Group>
            </div>
        )
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    return {
        findOneByFullClassName: (className: string) => selectors.services.findOneByFullClassName(state, className),
        findContentType: (value: string) => selectors.contentTypes.findOneBy(state, 'uuid', value)
    }
}

export default connect(mapStateToProps)(PresenterPicker)
