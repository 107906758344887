import React, {RefObject} from "react";
import {Col, Collapse, Form, FormInstance, Row, Switch, Typography} from "antd";
import IActionOptions from "../../../../../model/interface/widget/option/IActionOptions";
import IAction from "../../../../../model/interface/dataStorage/IAction";
import ButtonProperties from "./ButtonProperties";

interface IProps {
    options: IActionOptions
    action: IAction,
    formRef: RefObject<FormInstance>
}

const {Text} = Typography;
const {Panel} = Collapse;

class ActionWidgetEditor extends React.Component<IProps, IActionOptions> {


    componentDidMount() {
        const {action, formRef, options} = this.props
        formRef.current?.setFieldsValue({
            type: 'primary', ...options, label: options.label || action.label
        })
    }

    render() {
        const {action, options} = this.props

        return (
            <div>
                <Collapse className={'mb-3'}>
                    <Panel header={`Akce [${action.name}]`} key="1">
                        <Row gutter={10}>
                            <Col span={8}><Text type="secondary">Nazev: </Text></Col>
                            <Col span={16}><Text type="secondary">{action.name}</Text></Col>
                            <Col span={8}><Text type="secondary">Titulek: </Text></Col>
                            <Col span={16}><Text type="secondary">{action.label}</Text></Col>
                            <Col span={8}><Text type="secondary">Typ: </Text></Col>
                            <Col span={16}><Text type="secondary">{action.type}</Text></Col>
                            <Col span={8}><Text type="secondary">ID: </Text></Col>
                            <Col span={16}><Text type="secondary">{action.id}</Text></Col>
                        </Row>
                    </Panel>
                </Collapse>
                <ButtonProperties defaultLabel={options.label || action.label}/>
                <Form.Item name={'disabledOnInvalid'} label={'Neaktivne ak je formular nevalidni'}
                           valuePropName={'checked'}>
                    <Switch/>
                </Form.Item>

            </div>
        )
    }
}

export default ActionWidgetEditor