import RestService from "model/service/dataStorage/RestService";
import IRestServiceCollectionResponse from "../../interface/api/IRestServiceCollectionResponse";
import IRestServiceOptions from "../../interface/api/IRestServiceOptions";
import ISavedCommand from "../../interface/symfony-console/ISavedCommand";


interface IRestSavedCommandsServiceCollectionResponse extends IRestServiceCollectionResponse {
    results: Array<ISavedCommand>
}

interface IRestSavedCommandsService {
    collectionList(options?: IRestServiceOptions): Promise<IRestSavedCommandsServiceCollectionResponse>,
    collectionCreate(data: ISavedCommand): Promise<ISavedCommand>,
    resourceRetrieve(id: number|string): Promise<ISavedCommand>,
    resourceUpdate(id: number|string, data: any): Promise<ISavedCommand>,
    resourceDelete(id: number|string): Promise<void>,
}

const SavedCommandsService_COLLECTION = 'symfony-console/saved-commands'

const SavedCommandService: IRestSavedCommandsService = {
    collectionList: function (options?) {
        return RestService.collectionList(SavedCommandsService_COLLECTION, options as unknown as IRestServiceOptions) as Promise<IRestSavedCommandsServiceCollectionResponse>
    },
    collectionCreate: function ( data) {
        return RestService.collectionCreate(SavedCommandsService_COLLECTION, data) as Promise<ISavedCommand>
    },
    resourceRetrieve: function (id) {
        return RestService.resourceRetrieve(SavedCommandsService_COLLECTION, id) as Promise<ISavedCommand>
    },
    resourceDelete: function (id) {
        return RestService.resourceDelete(SavedCommandsService_COLLECTION, id)
    },
    resourceUpdate: function (id, data) {
        return RestService.resourceUpdate(SavedCommandsService_COLLECTION, id, data) as Promise<ISavedCommand>
    }
}
export default SavedCommandService