import React from 'react';
import {Badge, Col, Dropdown, Popover, Row, Tooltip} from 'antd';
import {BellOutlined, CheckOutlined, DeleteOutlined, EllipsisOutlined, LoadingOutlined} from '@ant-design/icons';
import {connect} from "react-redux";
import selectors from "../../redux/selectors";
import actions from "../../redux/actions";
import ScrollContainer from "../shared/scrollContainer/ScrollContainer";
import NotificationList from "../app/message/notification/NotificationList";
import Button from "../shared/button/Button";
import {IAppState} from "../../redux/store";
import MyNotificationsService from "../../model/service/ui/MyNotificationsService";

interface IState {
    visible: boolean,
    deletingAll?: boolean
    markingAllRead?: boolean
}

interface IProps {
    loaded: boolean,
    loading: boolean,
    countUnread: () => number,
    reload: () => void
    iconStyle?: React.CSSProperties
}

class NavNotification extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props)
        this.state = {
            visible: false
        }
    }

    handleVisibleChange = (visible: boolean) => {
        this.setState({visible});
    }

    deleteAll = () => {
        const {reload} = this.props
        this.setState({deletingAll: true});
        MyNotificationsService.deleteAll().then(() => {
            this.setState({deletingAll: false})
            reload()
        })
    }

    markAllRead = () => {
        const {reload} = this.props
        this.setState({markingAllRead: true});
        MyNotificationsService.markAllRead().then(() => {
            this.setState({markingAllRead: false})
            reload()
        })
    }

    render() {
        const {loaded, loading, countUnread, iconStyle} = this.props
        const count = countUnread()
        const {visible, deletingAll, markingAllRead} = this.state
        return (
            <Dropdown
                placement="bottomRight"
                overlay={<div className="nav-dropdown nav-notification nav-notification-wide overflow-hidden">
                    <Row className="nav-notification-header" justify={'space-between'} align={'middle'}>
                        <h4 className="mb-0">Upozornění</h4>
                        {loading ? <Button disabled={true} size={"extraSmall"} type={'link'}
                                           icon={<EllipsisOutlined className={'font-size-lg'}/>}
                                           title={'Upravit'} className={'p-1'}/> :
                            (
                                <Popover visible={loading ? false : undefined} placement={'bottomLeft'}
                                         trigger={['click']}
                                         zIndex={1200} content={
                                    <Row className={'flex-column'} gutter={[8, 8]}>
                                        <Col>
                                            <Button loading={markingAllRead} size={"small"} icon={<CheckOutlined/>}
                                                    onClick={() => this.markAllRead()}>
                                                Označte vše jako přečtené
                                            </Button>
                                        </Col>
                                        <Col>
                                            <Button loading={deletingAll} size={"small"} danger={true} icon={<DeleteOutlined/>}
                                                    onClick={() => this.deleteAll()}>
                                                Smazat všechny
                                            </Button>
                                        </Col>
                                    </Row>}>
                                    <Button disabled={loading} size={"extraSmall"} type={'link'}
                                            icon={<EllipsisOutlined className={'font-size-lg'}/>}
                                            title={'Upravit'} className={'p-1'}/>
                                </Popover>
                            )}
                    </Row>
                    <ScrollContainer className="nav-notification-body" visibility={"visible"}>
                        <NotificationList/>
                    </ScrollContainer>
                </div>}
                onVisibleChange={this.handleVisibleChange}
                visible={visible}
                trigger={['click']}
            >
                <div onClick={() => this.handleVisibleChange(!visible)}>
                    {(loading || !loaded) ? (
                        <Badge count={<LoadingOutlined/>}>
                            <BellOutlined className="nav-icon mx-auto" style={{...iconStyle}}/>
                        </Badge>
                    ) : (
                        <>
                            {count > 0 ? (
                                <Tooltip title={"Máte nová upozornění"} placement={'bottom'}>
                                    <div>
                                        <Badge count={count}>
                                            <BellOutlined className="nav-icon mx-auto"/>
                                        </Badge>
                                    </div>

                                </Tooltip>
                            ) : (
                                <Tooltip title={"Žádná nová upozornění"} placement={'bottom'}>
                                    <div>
                                        <Badge count={0} showZero style={{backgroundColor: '#52c41a'}}>
                                            <BellOutlined className="nav-icon mx-auto"/>
                                        </Badge>
                                    </div>
                                </Tooltip>
                            )}
                        </>
                    )}
                </div>
            </Dropdown>
        )
    }
}

const mapStateToProps = (state: IAppState) => {
    const {loaded, loading} = state.notifications
    return {
        countUnread: (): number => selectors.notifications.countUnread(state),
        loaded,
        loading
    }
}

const mapDispatchToProps = {
    reload: actions.notifications.reload
}

export default connect(mapStateToProps, mapDispatchToProps)(NavNotification);
