import RestService from "model/service/dataStorage/RestService";
import IEntry from "../../../interface/company/workload/IEntry";
import IRestServiceCollectionResponse from "../../../interface/api/IRestServiceCollectionResponse";
import IRestServiceOptions from "../../../interface/api/IRestServiceOptions";
import {MomentBuilder} from "../../../../utils/MomentBuilder";
import MasterRepositoryService from "../../dataStorage/MasterRepositoryService";
import IField from "../../../interface/dataStorage/IField";
import {Moment} from "moment";
import {COMPANY_WORKLOAD_TYPE_MODE_WORKING} from "../../../interface/company/workload/IType";
import IReport from "../../../interface/company/workload/IReport";

interface IRestEntriesServiceCollectionResponse extends IRestServiceCollectionResponse {
    results: Array<IEntry>
}

interface IRestEntriesService {
    collectionList(options?: IRestServiceOptions): Promise<IRestEntriesServiceCollectionResponse>,

    collectionCreate(data: any): Promise<IEntry>,

    resourceRetrieve(id: number | string): Promise<IEntry>,

    resourceUpdate(id: number | string, data: any): Promise<IEntry>,

    resourceDelete(id: number | string): Promise<void>,

    getReportableEntries(entries: IEntry[]): IEntry[]

    getEditableEntries(entries: IEntry[], report?: IReport): IEntry[]

    isEntryEditable (entry: IEntry, report?: IReport): boolean

    countHours(entry: IEntry): number
}

const COLLECTION = 'company/workload/entries'
const CLASS_NAME = 'App\\Company\\Entity\\Workload\\Entry'

class EntriesService extends MasterRepositoryService implements IRestEntriesService {


    private static instance?: EntriesService

    public static getInstance(): EntriesService {
        if (!EntriesService.instance) {
            EntriesService.instance = new EntriesService()
        }
        return EntriesService.instance
    }

    collectionList(options?: IRestServiceOptions) {
        return RestService.collectionList(COLLECTION, options as unknown as IRestServiceOptions) as Promise<IRestEntriesServiceCollectionResponse>
    }

    collectionCreate(data: any) {
        return RestService.collectionCreate(COLLECTION, data) as Promise<IEntry>
    }

    resourceRetrieve(id: number | string) {
        return RestService.resourceRetrieve(COLLECTION, id) as Promise<IEntry>
    }

    resourceDelete(id: number | string) {
        return RestService.resourceDelete(COLLECTION, id)
    }

    resourceUpdate(id: number | string, data: any) {
        return RestService.resourceUpdate(COLLECTION, id, data) as Promise<IEntry>
    }

    countHours (entry: IEntry) {
        return entry.endAt ? Math.abs(MomentBuilder.build(entry.startAt).diff(MomentBuilder.build(entry.endAt), 'minutes') / 60) : 0
    }

    getReportableEntries (entries: IEntry[]) {
        return entries.filter(e => (e.type.mode === COMPANY_WORKLOAD_TYPE_MODE_WORKING && !e.type.approvalRequired)
            || e.state === "approved"  || !!e.type.reportInlineEdit);
    }

    getEditableEntries (entries: IEntry[], report?: IReport) {
        return entries.filter(e => this.isEntryEditable(e, report));
    }

    isEntryEditable (entry: IEntry, report?: IReport) {
        return ((entry.type.mode === COMPANY_WORKLOAD_TYPE_MODE_WORKING && entry.type.unlimitedFund
            && !entry.type.approvalRequired) || !!entry.type.reportInlineEdit) && !!report?._permissions?.['edit'];
    }

    filterByDate (entries: IEntry[], date: Moment) {
        const startAt = date.clone().startOf("day")
        const endAt = date.clone().endOf('day')
        return entries.filter(entry => {
            const entryStart = MomentBuilder.build(entry.startAt)
            const entryEnd = MomentBuilder.build(entry.endAt)
            return entryStart <= endAt && entryStart >= startAt && (!entryEnd || entryEnd >= startAt)
        })
    }

    getMasterClassName() {
        return CLASS_NAME
    }

    getBaseFields(): IField[] {
        return [
            {
                uuid: '',
                name: 'startAt',
                label: 'Příchod',
                mode: "scalar",
                type: 'date',
                targetEntity: null,
                options: [],
                weight: 1,
                contentTypeId: null,
                contentTypeName: false,
                locked: true,
                arguments: {}
            },
            {
                uuid: '',
                name: 'endAt',
                label: 'Odchod',
                mode: "scalar",
                type: 'date',
                targetEntity: null,
                options: [],
                weight: 1,
                contentTypeId: null,
                contentTypeName: false,
                locked: true,
                arguments: {}
            },
            {
                uuid: '',
                name: 'employee',
                label: 'Zaměstnanec',
                mode: "relation",
                type: 'many_to_one',
                targetEntity: 'App\\Company\\Entity\\Employee',
                options: [],
                weight: 1,
                contentTypeId: null,
                contentTypeName: false,
                locked: true,
                arguments: {}
            }
        ];
    }
}

export default EntriesService