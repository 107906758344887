import React from "react";
import WysiwygInlineFileInfo from "./WysiwygInlineFileInfo";


interface IProps {
    value: string
}

interface IState {

}

class WysiwygPresenter extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props)
        this.state = {}

    }

    componentDidMount() {
        const {value} = this.props
        WysiwygInlineFileInfo.replaceInlineFile(value)
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        const {value} = this.props
        WysiwygInlineFileInfo.replaceInlineFile(value)
    }

    render() {
        const {value} = this.props
        return (
            <div dangerouslySetInnerHTML={{__html: value}}/>
        )
    }
}

export default WysiwygPresenter