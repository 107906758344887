import {Select, Spin} from 'antd';
import React, {ReactNode} from "react";
import IFieldOptions from "../../../../../../model/interface/form/elementOptions/IFieldOptions";
import {debounce} from "underscore";
import RestService from "model/service/dataStorage/RestService";
import IRestServiceOptions from "../../../../../../model/interface/api/IRestServiceOptions";

const {Option} = Select;

interface IProps {
    value?: number,
    onChange?: (key: number) => void
    options: IFieldOptions
}

interface IState {
    lastFetchId: number,
    value?: any,
    data: { id: number, label: string }[],
    loading: boolean
    options: IFieldOptions
}

/**
 * @deprecated use FieldContentType
 */
class FormFieldAutocomplete extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            data: [],
            value: {value: props.value, label: ''},
            loading: false,
            lastFetchId: 0,
            options: props.options
        };
        this.fetch = debounce(this.fetch, 800);
    }

    isUnmounted = false;

    componentDidMount() {
        if (this.state.value.value && !this.isUnmounted) {
            this.fetchById(this.state.value.value)
        }
    }

    componentWillUnmount() {
        this.isUnmounted = true;
    }

    fetchById = (id: number) => {
        const {options} = this.props
        this.setState({data: [], loading: true});
        RestService.resourceRetrieve(options.autocompleteCollection as string, id).then((resource: any) => {
            this.setState({
                value: {value: id, label: (resource.name)} as { value: string | number, label?: ReactNode },
                loading: false
            });
        })
    }

    fetch = (value: string) => {
        const {options} = this.props
        if (value.length >= (options.autocompleteMin as number) && options.autocompleteField
            && options.autocompleteCollection && options.autocompleteMode) {
            this.setState({data: [], loading: true});
            const params: IRestServiceOptions = {
                filters:
                    {'0': {value: value, field: options.autocompleteField, type: options.autocompleteMode}}
            }
            RestService.collectionList(options.autocompleteCollection as string, params)
                .then(({results}) => {
                    const data = results.map((type: any) => ({
                        label: type[options.autocompleteField as string],
                        id: type.id,
                    }));
                    this.setState({data, loading: false});
                })
        }
    }

    handleChange = (option: any) => {
        this.setState({
            value: option,
            data: [],
            loading: false,
        });
        this.props.onChange && this.props.onChange(option ? option.value : option)
    };

    render() {
        const {loading, data, value} = this.state
        const {options} = this.props
        const props = {
            style: options.style,
            placeholder: options.placeholder,
            allowClear: options.showClear,
            disabled: options.disabled
        };

        return (
            <Select
                {...props}
                labelInValue
                showSearch
                value={value}
                allowClear={options.showClear}
                notFoundContent={loading ? <Spin size="small"/> : null}
                loading={loading}
                filterOption={false}
                onSearch={this.fetch}
                onChange={this.handleChange}
                style={{width: '100%'}}
            >
                {data.map(d => (
                    <Option key={d.id} value={d.id}>{d.label}</Option>
                ))}
            </Select>
        );
    }
}

export default FormFieldAutocomplete