import React from "react";
import {message} from "antd";
import IReportWidgetProps from "../../../../../model/interface/report/IReportWidgetProps";
import {IAppState} from "../../../../../redux/store";
import {connect} from "react-redux";
import IUser from "../../../../../model/interface/security/IUser";
import {API_FILTER_TYPE} from "../../../../../model/constants/ApiConstant";
import {DATE_FORMAT_YYYY_MM_DD_HH_mm_ss} from "../../../../../model/constants/DateConstant";
import EntriesService from "../../../../../model/service/company/workload/EntriesService";
import Start from "../../../company/workload/day/Start";
import IEntry from "../../../../../model/interface/company/workload/IEntry";
import moment from "moment";
import {Link} from "react-router-dom";
import IReportWidgetWorkloadAttendance from "../../../../../model/interface/report/IReportWidgetWorkloadAttendance";
import IReport from "../../../../../model/interface/company/workload/IReport";
import ReportsService from "../../../../../model/service/company/workload/ReportsService";
import Utils from "../../../../../utils";
import TypesService from "../../../../../model/service/company/workload/TypesService";
import IType from "../../../../../model/interface/company/workload/IType";

interface IProps extends IReportWidgetProps<IReportWidgetWorkloadAttendance> {
    user: IUser
}

interface IState {
    loadingData: boolean,
    entries: IEntry[],
    report?: IReport
    types: IType[]
}

class WorkloadAttendanceWidget extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props)
        this.state = {
            loadingData: false,
            entries: [],
            types: []
        }
    }

    componentDidMount() {
        this.setState({loadingData: true})
        Utils.loadCollections(
            () => {},
            (values: any) => this.setState(values),
            {types: TypesService},
            {depth: 5}
        ).then(() => this.loadData().then())

    }

    loadData = () => {
        const {user} = this.props
        const date = moment()
        if (!user.employees[0]) {
            message.warning('Není k dispozici zaměstnanecký profil').then()
            return Promise.reject()
        }
        return ReportsService.collectionList({
            filters: {
                employee: {
                    field: 'employee',
                    type: API_FILTER_TYPE.EQUAL,
                    value: user.employees[0].uuid
                },
                startAt: {
                    field: 'month',
                    type: API_FILTER_TYPE.GREATER_OR_EQUAL,
                    value: date.clone().startOf('month').format('YYYY-MM-DD')
                },
                endAt: {
                    field: 'month',
                    type: API_FILTER_TYPE.LESSER,
                    value: date.clone().add(1, 'day').format('YYYY-MM-DD')
                }
            },
            depth: 5,
            limit: 0,
            cache: false
        }).then(response => {
            let report = response.results[0]
            if (!report) {
                return this.createReport(user.employees[0].uuid, date)
            }
            return report
        }).then(report => {
            this.setState({
                report: report,
                entries: EntriesService.getInstance().filterByDate(report.entries, date),
                loadingData: false
            })
        })
    }

    createReport(employee: string, start: moment.Moment) {
        return ReportsService.collectionCreate({
            employee,
            month: start.format(DATE_FORMAT_YYYY_MM_DD_HH_mm_ss),
            state: 'new'
        })
    }

    manageEntry = (entry: IEntry, action: 'create' | 'update') => {
        switch (action) {
            case "create":
                return this.setState(state => ({entries: [...state.entries, entry]}))
            case "update":
                let updatedEntries = [...this.state.entries]
                const index = updatedEntries.findIndex(a => a.uuid === entry.uuid)
                if (index > -1) {
                    updatedEntries[index] = entry
                    return this.setState({entries: updatedEntries})
                }
        }
    }

    render() {
        const {loadingData, entries, report, types} = this.state
        const {options} = this.props
        let {workloadAttendanceShowPlanRequest} = options

        return <div className={'rounded border overflow-hidden bg-theme'}>
            <div className={'p-3'}>
                <Start types={types} report={report} showPlanRequest={!!workloadAttendanceShowPlanRequest} loading={loadingData} entries={entries}
                       manageEntry={this.manageEntry}/>
            </div>
            <Link className={'d-block bg-gray-lighter w-100 text-center border-top p-2 text-reset'}
                  to={'/app/company/workload/day'}>
                Zobrazit docházku</Link>
        </div>
    }
}

const mapStateToProps = (state: IAppState) => {
    return {
        user: state.setup.user
    }
}

export default connect(mapStateToProps)(WorkloadAttendanceWidget)
