import React from "react";
import {Button, notification} from "antd";
import {ZxingReader} from "./ZxingReader";
import Modal from "../../modal/Modal";


interface IProps {
    onChange?: (code?: string) => void
    onCancel?: () => void
    style?: React.CSSProperties
    title?: string,
    resultOnSubmit?: boolean,

    confirmText?: string
}

interface IState {
    loading: boolean,
    value?: string
}

class CodeReaderModal extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props)
        this.state = {
            loading: false
        }
    }

    handleChange = (code?: string, submit?: boolean) => {
        const {onChange, resultOnSubmit} = this.props
        this.setState({value: code})
        if (resultOnSubmit && submit) {
            return onChange?.(code)
        }
        if (!resultOnSubmit) {
            onChange?.(code)
            notification.success({message: code, description: 'Kod byl naskenován.'});
        }
    }

    render() {
        const {onCancel, resultOnSubmit, confirmText} = this.props
        const {value} = this.state

        return (
            <Modal destroyOnClose={true} fullScreen={true} zIndex={1050} width={600} visible={true}
                   footer={resultOnSubmit ? [
                       <Button disabled={!value} key="submit" type="primary"
                               onClick={() => this.handleChange(value, true)}>
                           {confirmText || 'Použít'}
                       </Button>
                   ] : false} title={'Zaměřte kameru na kód'}
                   onCancel={() => onCancel?.()}>
                <ZxingReader onResult={result => this.handleChange(result.getText())}/>
            </Modal>
        )
    }
}

export default CodeReaderModal