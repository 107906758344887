import {Rate} from 'antd';
import React, {RefObject} from "react";
import IFieldOptions from "../../../../../../model/interface/form/elementOptions/IFieldOptions";
import SignatureCanvas from 'react-signature-canvas'
import IconBuilder from "../../../../../../utils/IconBuilder";

interface IProps {
    value?: number,
    onChange?: (value?: number) => void
    options: IFieldOptions
}

interface IState {
    value?: number
}

class FormFieldRate extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            value: props.value
        }
    }

    ref: RefObject<SignatureCanvas> = React.createRef<SignatureCanvas>()

    handleChange = (value?: number) => {
        this.setState({value}, this.updateParent)
    }

    updateParent() {
        this.props.onChange && this.props.onChange(this.state.value)
    }

    render() {
        const {rateLength, rateIcon, disabled, showClear} = this.props.options
        const {value} = this.props

        return (
            <div>
                <Rate value={value} count={rateLength} character={rateIcon ? IconBuilder(rateIcon) : undefined}
                      disabled={disabled} allowClear={showClear} onChange={this.handleChange}/>
            </div>
        )
    }
}

export default FormFieldRate