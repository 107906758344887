import React from "react";
import {Form} from "antd";
import RoutePicker from "../../../../shared/pickers/RoutePicker";
import IBackButtonOptions from "../../../../../model/interface/widget/option/IBackButtonOptions";

interface IProps {
    options: IBackButtonOptions
}

class BackButtonWidgetEditor extends React.Component<IProps> {

    render() {
        return (
            <div>
                <Form.Item name={'route'} label={'Zvolte cestu'}>
                    <RoutePicker/>
                </Form.Item>
            </div>
        )
    }
}

export default BackButtonWidgetEditor