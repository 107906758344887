import React from "react";
import {Col, Modal, notification, Row, Upload} from "antd";
import {SaveOutlined, UploadOutlined} from "@ant-design/icons";
import FilesService from "../../../model/service/file/FilesService";
import {UploadFile} from "antd/es/upload/interface";
import IFile from "../../../model/interface/file/IFile";
import Button, {IButtonProps} from "../../shared/button/Button";
import ImageEditor from "../../shared/input/image-editor/ImageEditor";
import Utils from "../../../utils";
import FileList from "../../shared/pickers/file/filePicker/FileList";

interface IState {
    file?: UploadFile,
    fileUrl?: string
    editedFile?: Blob,
    saving: boolean,
    visible: boolean
}

interface IProps {
    onChange?: (file?: IFile) => void,
    value?: IFile
    disabled?: boolean,
    directory?: number
    placeholder?: string
    buttonProps?: IButtonProps
    bePublic?: boolean
    preview?: boolean
    allowRotation?: boolean
    aspectRatio?: number
    roundCropper?: boolean
    showCropperGrid?: boolean
    uploadInModal?: boolean
}

class FileImageEditorUpload extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            saving: false,
            visible: false
        }
    }

    static defaultProps = {
        bePublic: true,
        preview: true,
        uploadInModal: true
    }

    addFile = (file: UploadFile) => {
        this.setState({file, visible: true, fileUrl: URL.createObjectURL(file)})
        return false
    }

    setEditedFile = (editedFile?: Blob) => {
        this.setState({editedFile})
    }

    removeFile = () => {
        this.setState({file: undefined}, () => this.props.onChange?.());
    }

    upload = () => {
        const {directory, bePublic, onChange} = this.props
        const {file, editedFile} = this.state
        this.setState({saving: true})
        const formData = Utils.convertArrayToFormData({directory, public: bePublic});
        formData.append('files[]', editedFile || file as any, file?.name)
        FilesService.collectionCreate(formData).then((files) => {
            this.setState({saving: false, visible: false, file: undefined})
            notification.success({message: `Úspěšně nahráno!`})
            onChange?.(files[0])
        })
    }

    onCancel = () => {
        this.setState({visible: false, saving: false})
    }

    render() {
        const {saving, visible, file, fileUrl} = this.state
        const {
            disabled,
            placeholder,
            buttonProps,
            value,
            preview,
            aspectRatio,
            allowRotation,
            roundCropper,
            showCropperGrid,
            uploadInModal
        } = this.props

        const button = <Button type={"primary"} disabled={disabled}
                               icon={<UploadOutlined/>}
                               loading={saving} {...buttonProps}>
            {placeholder !== undefined ? placeholder : 'Nahrát soubor(y) / archiv'}
        </Button>


        return <div>
            <Row justify={"end"}>
                <Upload disabled={saving} listType={'picture'}
                        name={'file'}
                        fileList={[]} accept={'image/*'} beforeUpload={this.addFile} onRemove={this.removeFile}>
                    {button}
                </Upload>
            </Row>

            {preview && (
                <FileList showPreview={true} fileList={value ? [value] : []} onRemove={this.removeFile}
                          canDownload={true}/>
            )}
            {visible && (
                <Modal
                    title={'Upravit'}
                    visible={true}
                    destroyOnClose={true}
                    width={600}
                    onCancel={this.onCancel}
                    footer={[
                        <Row justify={"space-between"} key={'actions'}>
                            {uploadInModal && (
                                <Upload disabled={saving} listType={'picture'} name={'file'}
                                        fileList={[]} accept={'image/*'} beforeUpload={this.addFile}
                                        onRemove={this.removeFile}>
                                    {button}
                                </Upload>
                            )}
                            <Col>
                                <Button key="back" onClick={this.onCancel}>
                                    Zrušit
                                </Button>
                                <Button key="submit" type="primary" disabled={!file} loading={saving}
                                        onClick={this.upload} icon={<SaveOutlined/>}>
                                    Uložit
                                </Button>
                            </Col>
                        </Row>
                    ]}
                >
                    {file && fileUrl && (
                        <div style={{height: 500}}>
                            <ImageEditor mime={file.type || 'image/jpeg'} onChange={this.setEditedFile}
                                         src={fileUrl} round={roundCropper} aspect={aspectRatio}
                                         allowRotation={allowRotation} showGrid={showCropperGrid}/>
                        </div>
                    )}
                </Modal>
            )}
        </div>
    }
}

export default FileImageEditorUpload