import React from "react";
import {Button, Tooltip} from "antd";
import {FolderOpenOutlined} from "@ant-design/icons";
import IFile from "../../../../../model/interface/file/IFile";
import FileUpload from "../../../../app/file-manager/FileUpload";
import {IPropsFilePickerWidget} from "../FilePicker";
import PickerModal from "./PickerModal";
import FileList from "./FileList";

interface IState {
    search?: string,
    directory?: number[],
    loading: boolean,
    saving: boolean,
    visible: boolean
}

interface IProps extends IPropsFilePickerWidget {

}

class Extended extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            loading: false,
            saving: false,
            visible: false
        } as IState
    }

    removeFile = (file: IFile) => {
        this.props.onRemove(file)
    }

    onUpload = (files: IFile[]) => {
        this.props.onChange(this.props.multiple ? [...this.props.fileList, ...files] : files)
        this.setState({visible: false})
    }

    onChoose = (files: IFile[]) => {
        files.length > 0 && this.props.onChange(files)
        this.setState({visible: false})
    }

    showModal = () => {
        this.setState({visible: true})
    }

    render() {
        const {loading, visible} = this.state
        const {
            canChoose,
            canUpload,
            multiple,
            fileList,
            directory,
            canDownload,
            canRemove,
            extensions,
            placeholder
        } = this.props

        return (
            <div>
                <div className={'position-relative'}>
                    {visible && <PickerModal extensions={extensions} multiple={multiple} forceDirectory={directory}
                                             onFinish={this.onChoose} fileList={fileList}/>}
                    {canChoose && (<Tooltip title={'Vybrat soubor'}>
                        <Button onClick={this.showModal}
                                style={{position: "absolute", top: 10, left: 10, zIndex: 1}}
                                icon={<FolderOpenOutlined/>} shape="circle" size={"small"}/>
                    </Tooltip>)}
                    {canUpload &&
                        <FileUpload placeholder={placeholder} accept={extensions?.join(',')}
                                    directory={directory}
                                    mode={"dragdrop"}
                                    multiple={multiple}
                                    onUpload={this.onUpload}/>}
                </div>
                <FileList onRemove={canRemove ? this.removeFile : undefined} loading={loading}
                          canDownload={canDownload} fileList={fileList} showPreview={true}/>
            </div>
        )
    }
}

export default Extended