import RestService from "model/service/dataStorage/RestService";
import IRestServiceOptions from "../../interface/api/IRestServiceOptions";
import IRestServiceCollectionResponse from "../../interface/api/IRestServiceCollectionResponse";
import IField from "../../interface/dataStorage/IField";
import IContentType from "../../interface/dataStorage/IContentType";
import {findOneByFullClassName} from "../../../redux/selectors/Services";
import {RootStateOrAny} from "react-redux";

interface IRestFieldsServiceCollectionResponse extends IRestServiceCollectionResponse {
  results: Array<IField>
}

interface IRestFieldsService {
  collectionList(options?: IRestServiceOptions): Promise<IRestFieldsServiceCollectionResponse>,
  collectionCreate(data: any): Promise<IField>,
  resourceRetrieve(id: number|string): Promise<IField>,
  resourceUpdate(id: number|string, data: any): Promise<IField>,
  resourceDelete(id: number|string): Promise<void>,
  getFieldFromRelationChain(chainName: string, originContentType: IContentType, store:  RootStateOrAny): IField,
  sort(fields: IField[]): IField[]
}

const FieldsService_COLLECTION = 'fields'

const FieldsService: IRestFieldsService = {
  collectionList: function (options?) {
    return RestService.collectionList(FieldsService_COLLECTION, options as unknown as IRestServiceOptions) as Promise<IRestFieldsServiceCollectionResponse>
  },
  collectionCreate: function ( data) {
    return RestService.collectionCreate(FieldsService_COLLECTION, data) as Promise<IField>
  },
  resourceRetrieve: function (id) {
    return RestService.resourceRetrieve(FieldsService_COLLECTION, id) as Promise<IField>
  },
  resourceDelete: function (id) {
    return RestService.resourceDelete(FieldsService_COLLECTION, id)
  },
  resourceUpdate: function (id, data) {
    return RestService.resourceUpdate(FieldsService_COLLECTION, id, data) as Promise<IField>
  },
  getFieldFromRelationChain(chainName: string, originContentType: IContentType, store: RootStateOrAny): IField {
    let fields: IField[] = originContentType.fields;
    let field: IField | undefined
    chainName.split('.').forEach(fieldName => {
      field = fields.find(f => f.name === fieldName)
      fields = field?.targetEntity ? findOneByFullClassName(store, field?.targetEntity).getFields() : []
    })
    if (!field){
      throw new Error('Invalid field chain ' + chainName)
    }

    return field
  },
  sort(fields: IField[]): IField[] {
    return fields.sort((a, b) => {
      const aLabel = (a.label || a.name).toLowerCase()
      const bLabel = (b.label || b.name).toLowerCase()
      return aLabel > bLabel ? 1 : (bLabel > aLabel ? -1 : 0)
    })
  }
}

export default FieldsService