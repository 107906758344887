import React from "react";
import {FormInstance, Modal} from "antd";
import MessageForm from "./MessageForm";
import IUser from "../../../model/interface/security/IUser";

interface IProps {
    onCancel: () => void
    onAfterSend: () => void
    visible?: boolean
    sender: IUser
    recipients?: IUser[]
    lockRecipients?: boolean
}

interface IState {
    ref: React.Ref<FormInstance>
}

class MessageModal extends React.Component<IProps, IState> {

    state = {
        ref: React.createRef<FormInstance>()
    }

    onSubmit() {
        if(this.state.ref.current) {
            this.state.ref.current.submit()
        }
    }

    render() {
        const {visible} = this.props
        return (
            <Modal title={"Nová zpráva"} visible={typeof visible === 'undefined' ? true : visible} onCancel={() => this.props.onCancel()} cancelText={"Zrušit"} okText={"Odeslat"} onOk={() => this.onSubmit()}>
                <MessageForm {...this.props} showSubmit={false} formRef={this.state.ref}/>
            </Modal>
        );
    }
}

export default MessageModal