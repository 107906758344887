import React from "react";
import {InputNumberProps} from "antd";
import IFieldOptions from "../../../../../../model/interface/form/elementOptions/IFieldOptions";
import ColorPicker from "../../../../../shared/pickers/ColorPicker";

class FormFieldColor {
    public static render = (options: IFieldOptions & InputNumberProps) => {
        return (
            <ColorPicker output={"rgba"} disabled={options.disabled} placeholder={options.placeholder}
                         allowClear={options.showClear} value={options.value as any}/>
        )
    }
}

export default FormFieldColor