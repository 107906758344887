import React, {RefObject} from "react";
import {Form, FormInstance, Switch} from "antd";
import IType from "../../../../../../model/interface/company/workload/IType";
import IReportWidgetWorkloadReport from "../../../../../../model/interface/report/IReportWidgetWorkloadReport";

interface IProps {
    options: IReportWidgetWorkloadReport
    formRef?: RefObject<FormInstance>
}

interface IState {
    types?: IType[]
}

class WorkloadReportWidgetEditor extends React.Component<IProps, IState> {


    constructor(props: IProps) {
        super(props)
        this.state = {}
    }

    render() {
        return (
            <div>
                <Form.Item name={'workloadReportHideOnEmpty'} label={'Skrýt widget pokud nemáte žádnou žádost'}
                           valuePropName={'checked'}>
                    <Switch/>
                </Form.Item>
                <Form.Item name={'workloadReportApprovalConfirmation'} label={'Zobrazovat potvrzení'}
                           valuePropName={'checked'}>
                    <Switch/>
                </Form.Item>
            </div>
        )
    }
}

export default WorkloadReportWidgetEditor