import moment from "moment";
import store from "../redux/store";
import IContentType from "../model/interface/dataStorage/IContentType";
import _ from "underscore";
import IRestResource from "../model/interface/api/IRestResource";
import selectors from "../redux/selectors";
import IField, {
    COMPOSITE_FIELD_TYPE,
    FIELD_MODE_COMPOSITE,
    FIELD_MODE_COMPUTED, FIELD_MODE_RELATION,
    FIELD_MODE_SCALAR,
    FIELD_TYPE,
    RELATION_FIELD_TYPE
} from "../model/interface/dataStorage/IField";
import IPresenter from "../model/interface/dataStorage/IPresenter";
import PresenterBuilder from "../views/dataStorage/PresenterBuilder";
import {Divider, Image, Rate, Row, Tag} from "antd";
import React from "react";
import {History} from "history";
import Utils from "./index";
import IconBuilder from "./IconBuilder";
import ColorSquare from "../components/shared/ColorSquare";
import LikesService from "../model/service/dataStorage/extension/LikesService";
import CardWidgetLike from "../components/app/configuration/content-type/card/widget/CardWidgetLike";
import WysiwygPresenter from "../components/shared/input/wysiwyg/WysiwygPresenter";
import CompositeFieldApproval
    from "../components/app/configuration/content-type/field/presenters/CompositeFieldApproval";
import PhoneNumber from "../components/app/configuration/content-type/field/presenters/PhoneNumber";
import Label from "../views/dataStorage/presenter/Label";
import FormFieldEmoji from "../components/app/configuration/form/FormElement/formField/FormFieldEmoji";
import Emoji from "../components/app/configuration/content-type/field/presenters/Emoji";

class DataStorageHelper {
    static buildFormData(values: { [name: string]: any }, contentType: IContentType, uuids?: string[], path?: string): any {
        let output = {} as { [name: string]: any }
        const usedUuids = uuids || [] as string[]
        Object.keys(values).sort((a: string, b: string) => {
            // id and uuid must be first
            return ['id', 'uuid'].indexOf(a) > ['id', 'uuid'].indexOf(b) ? -1 : 1;
        }).forEach((name: string) => {
            const debugPath = path ? path + ' - ' + name : name;
            let value: any
            if (name === 'uuid') {
                value = values[name]
                usedUuids.push(value)
            } else if (['id'].indexOf(name) >= 0) {
                value = values[name]
            } else if (['createdAt', 'updatedAt'].indexOf(name) >= 0) {
                value = values[name]['raw']
            } else if (name.charAt(0) === "_" || ['v'].includes(name)) {
                // skip these
                return
            } else {
                const field = _.findWhere(contentType.fields, {name})
                if (!field) {
                    return
                }
                value = values.hasOwnProperty(name) ? values[name] : undefined
                switch (field.mode) {
                    case('scalar'):
                        switch (field.type) {
                            case(FIELD_TYPE.ARRAY):
                                if (typeof value !== 'object') {
                                    value = undefined;
                                }
                                break;
                            case(FIELD_TYPE.DATE_TIME):
                                if (moment.isMoment(value)) {
                                    value = value.format('YYYY-MM-DD HH:mm:ss')
                                }
                                if (value && typeof value === 'object') {
                                    value = value.raw
                                }
                                break;
                            default:
                                if (value === undefined){
                                    value = null
                                }
                                if (value !== null && typeof value === 'object') {
                                    console.error('Value is object', value, field.type)
                                    throw new Error('Value is object')
                                }
                                break;
                        }
                        break;
                    case('relation'):
                        switch (field.type) {
                            case(RELATION_FIELD_TYPE.MANY_TO_ONE):
                            case(RELATION_FIELD_TYPE.ONE_TO_ONE):
                                if (value === null || value === undefined) {
                                    value = null
                                } else if (typeof value === 'object' && value.hasOwnProperty('uuid')) {
                                    if (usedUuids.indexOf(value.uuid) >= 0) {
                                        value = value.uuid
                                    } else {
                                        // TODO resolve when use reference and when whole object
                                        const useReferenceOnly = true
                                        if (value.hasOwnProperty('_class') && !useReferenceOnly) {
                                            const itemService = selectors.services.findOneByFullClassName(store.getState(), value._class)
                                            // @ts-ignore // TODO
                                            if (itemService && typeof itemService.getContentType === 'function') {
                                                // @ts-ignore // TODO
                                                value = DataStorageHelper.buildFormData(value, itemService.getContentType(), usedUuids, debugPath)
                                            } else {
                                                value = value.uuid
                                            }
                                        } else {
                                            value = value.uuid
                                        }
                                        usedUuids.push(value.uuid)
                                    }
                                } else {
                                    // value is uuid
                                }
                                break;
                            case(RELATION_FIELD_TYPE.MANY_TO_MANY):
                            case(RELATION_FIELD_TYPE.ONE_TO_MANY):
                                if (value === null || value === undefined) {
                                    value = null
                                } else if (typeof value === 'object') {
                                    if (typeof value.forEach !== 'function') {
                                        throw new Error('Value for *toMany relation must be null or array');
                                    }
                                    let cleanValue: any = []
                                    value.forEach((item: IRestResource | number | string) => {
                                        if (typeof item === 'object') {
                                            const uuid: string = item.uuid!
                                            if (usedUuids.indexOf(uuid) >= 0) {
                                                cleanValue.push(uuid)
                                            } else {
                                                // TODO resolve when use reference and when whole object
                                                if (item.hasOwnProperty('_class')) {
                                                    const itemService = selectors.services.findOneByFullClassName(store.getState(), item._class)
                                                    // @ts-ignore // TODO
                                                    if (itemService && typeof itemService.getContentType === 'function') {
                                                        // @ts-ignore // TODO
                                                        cleanValue.push(DataStorageHelper.buildFormData(item, itemService.getContentType(), usedUuids, debugPath))
                                                    } else {
                                                        cleanValue.push(uuid)
                                                    }
                                                } else {
                                                    cleanValue.push(uuid)
                                                }
                                                usedUuids.push(uuid)
                                            }
                                        } else {
                                            cleanValue.push(item)
                                        }
                                    })
                                    value = cleanValue
                                }
                                break;
                        }
                        break;
                }
            }
            if (typeof value !== 'undefined') {
                output[name] = value
            }
        })
        return output
    }

    static buildFieldValue(field: IField, resource: IRestResource, history: History, presenter?: IPresenter) {
        if ([FIELD_MODE_SCALAR, FIELD_MODE_COMPUTED, FIELD_MODE_COMPOSITE].includes(field.mode || '')) {
            const type = field.type.replaceAll('\\\\', '\\')
            const value = resource[field.name]
            switch (type) {
                case(FIELD_TYPE.COLOR):
                    return <ColorSquare value={resource[field.name]}/>
                case(FIELD_TYPE.ICON):
                    return (value ? <>{IconBuilder(value)}</> : <></>)
                case(FIELD_TYPE.BOOLEAN):
                    return PresenterBuilder.build({type: 'tag', options: presenter?.options} as unknown as IPresenter, resource, {
                        value: field.name,
                        scheme: 'boolean',
                    })
                case (FIELD_TYPE.FLOAT):
                    let float: string | number = value && parseFloat(value)
                    if (float && typeof float === 'number' && presenter?.options?.numberPrecision){
                        float = float.toFixed(presenter?.options?.numberPrecision)
                    }
                    return <Label label={float} unit={field.unit || ''}/>
                case(FIELD_TYPE.PRICE):
                    return PresenterBuilder.build({type: 'price'} as unknown as IPresenter, resource, {
                        value: field.name,
                        unit: field.unit! || 'Kč',
                        ...presenter?.options
                    })
                case(FIELD_TYPE.HTML):
                case(FIELD_TYPE.TEXT):
                    return (<WysiwygPresenter value={value}/>);
                case(FIELD_TYPE.BASE64):
                    return (
                        resource[field.name] ?
                            <Row justify={"center"} align={'middle'} className={'w-100 border rounded p-2'}>
                                <Image fallback={Utils.imageFallback()} height={60} src={resource[field.name]}/>
                            </Row> : <Tag>není vyplněno</Tag>)
                case(FIELD_TYPE.DATE_TIME):
                    if (value && typeof value === 'object') {
                        return moment(value.raw, 'YYYY-MM-DD HH:mm:ss').format(presenter?.options!.dateFormat as string || 'LLL')
                    }
                    return '';
                case FIELD_TYPE.RATE:
                    return <Rate disabled={true}
                                 character={field.options?.rateIcon && IconBuilder(field.options.rateIcon)}
                                 count={field.options?.rateLength || 5} value={resource[field.name]}/>
                case FIELD_TYPE.SPECIALIZED_COLOR:
                    return <div style={{backgroundColor: resource[field.name], minHeight: 20, minWidth: 30}}/>
                case COMPOSITE_FIELD_TYPE.APPROVAL:
                    return <CompositeFieldApproval options={presenter?.options as any} value={value} field={field}
                                                   history={history}/>
                case FIELD_TYPE.PHONE_NUMBER:
                    return <PhoneNumber options={presenter?.options as any} value={value} field={field}
                                                   history={history}/>
                case FIELD_TYPE.EMOJI:
                    return <Emoji value={value} options={presenter?.options as any}/>
                default:
                    const scalarPresenter = {type: 'label', options: []} as unknown as IPresenter;
                    return PresenterBuilder.build(scalarPresenter, resource, {
                        label: '#' + field.name,
                        unit: field.unit || ''
                    })
            }

        } else if (field.mode === FIELD_MODE_RELATION) {
            const service = field.targetEntity && selectors.services.findOneByFullClassName(store.getState(), field.targetEntity)
            if (!service) {
                throw new Error('Service for "' + field.targetEntity + '" is missing')
            }
            if (!presenter) {
                presenter = service.getDefaultPresenter()
            }
            const value = resource[field.name]
            if ([RELATION_FIELD_TYPE.MANY_TO_MANY, RELATION_FIELD_TYPE.ONE_TO_MANY].indexOf(field.type) >= 0 && value) {
                if (field.targetEntity === LikesService.getRecordClassName()) {
                    switch (presenter.name) {
                        case 'default':
                            return (value.length || !field.options.hideCountIfEmpty) ? <span
                                className={field.options.color}>{CardWidgetLike.getIcon(true, field.options.icon)} {value.length}</span> : ''
                        case 'count':
                            return <span>{value.length}</span>
                    }
                }
                const values = value.map((item: IRestResource) => (presenter ? PresenterBuilder.build(presenter, item, presenter.options || {}) : (<>{service.getStringValue(item)}</>)))
                return (
                    <>
                        {values.map((value: any, index: number) => (
                            <span key={index}>
                                {index > 0 && (
                                    <Divider type={"vertical"}/>
                                )}
                                {value}
                            </span>
                        ))}
                    </>
                )
            } else {
                return PresenterBuilder.build(presenter, value, presenter.options)
            }
        } else {
            throw new Error('Invalid item mode: ' + field.mode)
        }
    }
}

export default DataStorageHelper;