import React from 'react'
import {APP_NAME} from 'configs/AppConfig';
import {connect, RootStateOrAny} from "react-redux";
import processLogo from '../../assets/images/proces_logo-sm.png';
import processSquareLogo from '../../assets/images/logo_sq.png';
import ISettings from "../../model/interface/ui/ISettings";
import SettingsService from "../../model/service/SettingsService";

interface IProps {
    square: boolean,
    isMobile: boolean
    login?: boolean
    settings: ISettings
    className?: string
}

class Logo extends React.Component<IProps> {

    getLogo() {
        const {settings} = this.props

        return SettingsService.getValue('ui', 'logo', settings)?.thumbnailUrl || processLogo
    }

    getLoginLogo() {
        const {settings} = this.props

        return SettingsService.getValue('ui', 'loginLogo', settings)?.thumbnailUrl || this.getLogo()
    }

    getMobileLogo() {
        const {settings} = this.props

        return SettingsService.getValue('ui', 'mobileLogo', settings)?.thumbnailUrl || processSquareLogo
    }

    render() {
        const {square, className, login} = this.props
        return (
            <div className={"logo justify-content-center " + className}>
                {square ? (
                    <>
                        <img src={this.getMobileLogo()} alt={`${APP_NAME} logo`}/>
                    </>
                ) : (
                    <>
                        <img src={login ? this.getLoginLogo() : this.getLogo()} alt={`${APP_NAME} logo`}/>
                    </>
                )}
            </div>
        )
    }
}

const mapStateToProps = ({theme}: RootStateOrAny) => {
    const {navCollapsed, navType} = theme;
    return {navCollapsed, navType}
};

export default connect(mapStateToProps)(Logo);
