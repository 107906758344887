import React from "react"
import {Form, Radio, Select, Switch} from "antd";
import {getNestedFieldOptions} from "../unit/ViewUnitProperties";
import Title from "antd/es/typography/Title";
import IContentType from "../../../../../model/interface/dataStorage/IContentType";

interface IState {

}

interface IProps {
    contentType: IContentType
}

class ViewCarouselProperties extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {}
    }

    render() {
        const {contentType} = this.props

        return (
            <>
                <Title level={3}>Vlastnosti kartotéky</Title>
                <Form.Item
                    label={"Karta pro zobrazení"}
                    name={getNestedFieldOptions('carouselCards')}
                    rules={[{required: true, message: 'Vyplňte kartu'}]}
                >
                    <Select defaultActiveFirstOption={true} mode={'multiple'}>
                        {contentType.cards.map(card => (
                            <Select.Option key={card.uuid} value={card.uuid}>
                                {card.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label={"Zobrazovat přepínač stránek"}
                    valuePropName={'checked'}
                    name={getNestedFieldOptions('carouselShowPageSwitch')}>
                    <Switch/>
                </Form.Item>
                <Form.Item
                    label={"Zobrazovat carousel stránkování"}
                    valuePropName={'checked'}
                    name={getNestedFieldOptions('carouselShowPaging')}>
                    <Switch/>
                </Form.Item>
                <Form.Item
                    label={"Nekonečné stránkování"}
                    valuePropName={'checked'}
                    name={getNestedFieldOptions('carouselInfinitePaging')}>
                    <Switch/>
                </Form.Item>
                <Form.Item
                    label={"Efekt při přechodu"}
                    name={getNestedFieldOptions('carouselEffect')}>
                    <Radio.Group buttonStyle="solid">
                        <Radio.Button value={'scrollx'}>Přejetí</Radio.Button>
                        <Radio.Button value={'fade'}>Ztmavení</Radio.Button>
                    </Radio.Group>
                </Form.Item>
            </>
        )
    }
}

export default ViewCarouselProperties