import {Rate} from 'antd';
import React, {RefObject} from "react";
import IFieldOptions from "../../../../../../model/interface/form/elementOptions/IFieldOptions";
import SignatureCanvas from 'react-signature-canvas'
import IconBuilder from "../../../../../../utils/IconBuilder";
import {SketchPicker} from "react-color";
import IconPicker from "../../../../../shared/IconPicker";

interface IProps {
    value?: string,
    onChange?: (value?: string) => void
    options: IFieldOptions
}

interface IState {
    value?: string
}

class FormFieldIcon extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            value: props.value
        }
    }

    ref: RefObject<SignatureCanvas> = React.createRef<SignatureCanvas>()

    handleChange = (value?: string) => {
        this.setState({value}, this.updateParent)
    }

    updateParent() {
        this.props.onChange && this.props.onChange(this.state.value)
    }

    render() {
        const {value} = this.props
        return (
            <div>
                <IconPicker
                    value={value}
                    onChange={(value) => this.handleChange(value)}
                />
            </div>
        )
    }
}

export default FormFieldIcon