import React from "react"
import {Form, Select} from "antd";
import {getNestedFieldOptions} from "../unit/ViewUnitProperties";
import Title from "antd/es/typography/Title";
import IContentType from "../../../../../model/interface/dataStorage/IContentType";
import {connect, RootStateOrAny} from "react-redux";
import selectors from "../../../../../redux/selectors";
import IRepositoryService from "../../../../../model/interface/IRepositoryService";
import IViewUnit from "../../../../../model/interface/dataStorage/IViewUnit";

interface IState {

}

interface IProps {
    contentTypes: IContentType[]
    findOneByFullClassName: (className: string) => IRepositoryService,
    resource: IViewUnit
}

class ViewCalendarProperties extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {}
    }

    buildPresenterOptions(contentType: IContentType) {
        const {findOneByFullClassName} = this.props
        const service = findOneByFullClassName(contentType.fullClassName)
        return service.getPresenterList()
    }

    render() {
        const {contentTypes,resource} = this.props

        return (
            <>
                <Title level={3}>Vlastnosti kalendáře</Title>
                <Form.Item
                    label={"Zobrazení"}
                >
                    {resource.contentTypes.map(contentType => {
                        const contentTypeObject = contentTypes.find(c => c.uuid === contentType)
                        return contentTypeObject && (
                            <Form.Item
                                label={contentTypeObject.label}
                                name={[...getNestedFieldOptions('calendarPresenter'), contentTypeObject.uuid]}
                            >
                                <Select
                                    className={'ml-2 w-100'}
                                    size={"small"}
                                    dropdownMatchSelectWidth={false}
                                    options={this.buildPresenterOptions(contentTypeObject)}
                                />
                            </Form.Item>
                        )
                    })}
                </Form.Item>
            </>
        )
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    return {
        findOneByFullClassName: (className: string) => selectors.services.findOneByFullClassName(state, className),
    }
}

export default connect(mapStateToProps)(ViewCalendarProperties)