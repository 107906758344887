import React from "react"
import {Col, Row} from "antd";
import IContentType from "../../../../model/interface/dataStorage/IContentType";
import {RestAggregation} from "../../../../model/interface/api/IRestServiceCollectionResponse";
import DataStorageHelper from "../../../../utils/DataStorageHelper";
import IBaseProps from "../../../../model/interface/IBaseProps";
import IAggregationOptions from "../../../../model/interface/dataStorage/view/table/IAggregationOptions";
import {humanTime} from "../../file-manager/utils/TimeDuration";
import AggregationProperties from "../../configuration/view/properties/AggregationProperties";

export interface IProps extends IBaseProps {
    contentType: IContentType,
    aggregations?: RestAggregation[],
    options?: IAggregationOptions
}

interface IState {
}

export class Aggregations extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {}
    }

    static defaultProps = {
        modal: true
    }

    render() {
        const {contentType, aggregations, history, options} = this.props

        return options && Object.keys(options).length > 0 ? <Row gutter={[8, 8]} className={'mb-2'}>
            {Object.entries(options).map(([, aggregation]) => {
                const field = contentType.fields.find(f => f.name === aggregation.field)
                const found = aggregations?.find(a => a.type === aggregation.type && a.field === aggregation.field)
                let value = found && field && DataStorageHelper.buildFieldValue(field, {[found.field]: found.value}, history)
                if (aggregation.format === AggregationProperties.FORMAT_TYPES.HUMAN_TIME) {
                    value = humanTime(found?.value, aggregation.humanTimeFormat?.interval, aggregation.humanTimeFormat?.format.replaceAll('#', ''))
                }
                return found && field && (
                    <Col className="ant-statistic">
                        <div className="ant-statistic rounded border p-3">
                            <div className="ant-statistic-title">{aggregation.title}</div>
                            <div className="ant-statistic-content font-size-md">
                                {value}
                            </div>
                        </div>
                    </Col>
                )
            })}
        </Row> : ''
    }
}

export default Aggregations