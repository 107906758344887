import {
    STORE_TOKEN,
    AUTH_TOKEN,
    SIGNOUT, MASQUERADE,
    DISABLE_MASQUERADE
} from '../constants/Auth';

export interface IAuthState {
    loading: boolean,
    message: string,
    showMessage: boolean,
    redirect: string,
    token: string | null,
    masquerade: string | null
}

const initState: IAuthState = {
    loading: false,
    message: '',
    showMessage: false,
    redirect: '',
    token: localStorage.getItem(AUTH_TOKEN),
    masquerade: localStorage.getItem(MASQUERADE)
}

const auth = (state = initState, action: { type: any; token: any; masquerade: any, redirect?: string }) => {
    switch (action.type) {
        case STORE_TOKEN:
            return {
                ...state,
                loading: false,
                redirect: action.redirect || '/',
                token: action.token
            }
        case MASQUERADE:
            return {
                ...state,
                masquerade: action.masquerade
            }
        case DISABLE_MASQUERADE:
            return {
                ...state,
                masquerade: null
            }
        case SIGNOUT: {
            return {
                ...state,
                token: null,
                redirect: '/',
                loading: false
            }
        }
        default:
            return state;
    }
}

export default auth