import _ from 'underscore'
import {RootStateOrAny} from "react-redux";
import IScript from "../../model/interface/dataStorage/IScript";

export const findAll = (store: RootStateOrAny) => store.setup.scripts

export const findOneBy = (store: RootStateOrAny, property: string, value: any): IScript => _.findWhere(findAll(store), {[property]: value})

export default {
    findAll,
    findOneBy
}