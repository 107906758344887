import React from "react";
import {Tooltip} from "antd";
import './hotkey.sass'
import {PlusOutlined} from "@ant-design/icons";
import {connect, RootStateOrAny} from "react-redux";
import {IUiState} from "../../../redux/reducers/Ui";
import {TooltipPlacement} from "antd/lib/tooltip";

type EventKey = 'Backspace' | 'Tab' | 'Enter' | 'Shift' | 'Control' | 'Alt' | 'Pause' | 'CapsLock' | 'Escape' | ''
    | 'PageUp' | 'PageDown' | 'End' | 'Home' | 'ArrowLeft' | 'ArrowUp' | 'ArrowRight' | 'ArrowDown' | 'PrintScreen'
    | 'Insert' | 'Delete' | '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | 'a' | 'b' | 'c' | 'd' | 'e'
    | 'f' | 'g' | 'h' | 'i' | 'j' | 'k' | 'l' | 'm' | 'n' | 'o' | 'p' | 'q' | 'r' | 's' | 't' | 'u' | 'v' | 'w' | 'x'
    | 'y' | 'z' | 'Meta' | 'ContextMenu' | '*' | '+' | '-' | '.' | '/' | 'F1' | 'F2' | 'F3' | 'F4' | 'F5' | 'F6' | 'F7'
    | 'F8' | 'F9' | 'F10' | 'F11' | 'F12' | 'NumLock' | 'ScrollLock' | 'AudioVolumeMute' | 'AudioVolumeDown'
    | 'AudioVolumeUp' | 'LaunchMediaPlayer' | 'LaunchApplication1' | 'LaunchApplication2' | ';' | '=' | ',' | '`' | '['
    | '\\' | ']' | '\''

interface IProps{
    keys: EventKey[],
    trigger: () => void
    coverage?: 'global' | 'focus' | string
    help?: string
    showShortcuts?: boolean
    children?: React.ReactNode,
    className?: string
    helpPlacement?: TooltipPlacement
}

interface IState {
    activeKeys: string[],
    activeElement?: Element | null
}

class Hotkey extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props)
        this.state = {
            activeKeys: []
        }
    }

    static defaultProps = {
        coverage: 'global'
    }

    rootRef = React.createRef<HTMLDivElement>()

    removeKey = (e: KeyboardEvent) => {
        this.setState(state => ({activeKeys: state.activeKeys.filter(key => e.key !== key)}))
    }

    componentDidMount() {
        const {coverage} = this.props
        document.addEventListener('keydown', this.addKey);
        document.addEventListener('keyup', this.removeKey);
        coverage === "focus" && document.addEventListener('focusin', () => {
            this.setState({
                activeElement: this.rootRef.current?.contains(document.activeElement) ? document.activeElement : null
            })
        }, true);
    }

    addKey = (e: KeyboardEvent) => {
        const {activeElement} = this.state
        const {coverage} = this.props
        !e.repeat && (coverage !== 'focus' || activeElement) && this.setState(state => ({
            activeKeys: state.activeKeys.includes(e.key) ? state.activeKeys : [...state.activeKeys, e.key]
        }), this.checkKeys)
    }

    checkKeys = () => {
        const {activeKeys} = this.state
        const {keys, trigger} = this.props
        if (keys.every(v => activeKeys.includes(v))) {
            trigger()
            this.setState({activeKeys: []})
        }
    }

    render() {
        const {children, keys, help, showShortcuts, className, helpPlacement} = this.props

        return (
            <Tooltip placement={helpPlacement} trigger={['click', 'hover']} visible={showShortcuts || undefined} title={
                <div>
                    <div>{help}</div>
                    <span className={'mr-2'}>Zkratka:</span>
                    {keys.map((key, index) => (
                        <span key={index}>
                            <button className={'keyboard-button'}>{key}</button>
                            {keys.length > 1 && index < keys.length - 1 && (
                                <PlusOutlined className={'m-1'}/>
                            )}
                        </span>

                    ))}
                </div>
            }>
                <div className={className} ref={this.rootRef}>
                    {children}
                </div>
            </Tooltip>
        )
    }
}


const mapStateToProps = (state: RootStateOrAny) => {
    const {showShortcuts} = state.ui as IUiState
    return {
        showShortcuts
    }
}

export default connect(mapStateToProps)(Hotkey)