import axios from 'axios'
// import {API_BASE_URL} from 'configs/AppConfig'
import {env} from 'configs/EnvironmentConfig'
import history from '../../history'
import {AUTH_TOKEN, MASQUERADE} from 'redux/constants/Auth'
import {notification} from 'antd';
import {detectFileResponse} from "./ETagCachedFetchInterceptor";
// import config from "react-beautiful-dnd/src/state/auto-scroller/fluid-scroller/config";

const timeoutValue = process.env.REACT_APP_FETCH_INTERCEPTOR_TIMEOUT;
const timeout = timeoutValue ? parseInt(timeoutValue, 10) : 60000;

const service = axios.create({
    baseURL: env.API_ENDPOINT_URL,
    timeout: timeout
})

// Config
const ENTRY_ROUTE = '/auth/login'
const TOKEN_PAYLOAD_KEY = 'token'
const MASQUERADE_PAYLOAD_KEY = 'masquerade'
export const PUBLIC_REQUEST_KEY = 'public-request'
//
// API Request interceptor
service.interceptors.request.use(config => {

    const token = localStorage.getItem(AUTH_TOKEN)
    const masquerade = localStorage.getItem(MASQUERADE)

    if (token) {
        config.headers[TOKEN_PAYLOAD_KEY] = token
    }
    if (masquerade) {
        config.headers[MASQUERADE_PAYLOAD_KEY] = masquerade
    }

    if (!token && !config.headers[PUBLIC_REQUEST_KEY]) {
        history.push(ENTRY_ROUTE)
        window.location.reload();
    }
    // config.headers["Access-Control-Request-Method"] = config.method
    return config
}, error => {
    // Do something with request error here
    notification.error({
        message: 'Error'
    })
    Promise.reject(error)
})

// API response interceptor
service.interceptors.response.use((response) => {
    return detectFileResponse(response);
}, (error) => {

    // localStorage.removeItem(AUTH_TOKEN)

    // if (error.response.status !== 400) {
    let notificationParam = {
        message: ''
    }

    if(error.response) {
        // Remove token and redirect
        // if (error.response.status === 403) {
        if (error.response.status === 400) {
            notificationParam.message = 'Invalid request'
        }

        if(error.response.status === 403) {
            notificationParam.message = 'Authentication Fail'
            // notificationParam.description = 'Please login again'
            history.push(ENTRY_ROUTE)
            window.location.reload();
        }

        if (error.response.status === 404) {
            notificationParam.message = 'Not Found'
        }

        if (error.response.status === 500) {
            notificationParam.message = 'Internal Server Error'
        }

        if (error.response.status === 508) {
            notificationParam.message = 'Time Out'
        }

    } else {
        notificationParam.message = 'No response'
    }


        notification.error(notificationParam)
    // }
    return Promise.reject(error);
});

export default service