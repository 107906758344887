import React from "react"
import {Col, Form, InputNumber, Row, Select, Switch} from "antd";
import {IViewSettingsProps} from "../ViewSettings";
import IViewGraphSettings from "../../../../model/interface/dataStorage/view/graph/IViewGraphSettings";
import ViewGraphService from "../../../../model/service/dataStorage/view/ViewGraphService";

export default class ViewGraphSettings extends React.Component<IViewSettingsProps, IViewGraphSettings> {
    constructor(props: IViewSettingsProps) {
        super(props);

        this.state = {
            ...props.settings,
            graphIntervalLength: props.settings?.graphIntervalLength || 12
        }
    }

    onChange = () => {
        this.props.onChange({...this.state}).then()
    }

    onChangeForm = (values: any): void => {
        this.setState(state => ({...state, ...values}), this.onChange)
    }

    render() {
        return (
            <>
                <Form onValuesChange={this.onChangeForm} initialValues={this.state}>
                    <Row gutter={12}>
                        <Col sm={12}>
                            <Form.Item
                                label={"Typ intervalu"}
                                name={'graphIntervals'}
                                rules={[{required: true, message: 'Vyplňte typ intervalu'}]}
                            >
                                <Select mode={"multiple"} defaultActiveFirstOption={true}>
                                    {ViewGraphService.buildIntervals().map(interval => (
                                        <Select.Option key={interval.value} value={interval.value}>
                                            {interval.label}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col sm={12}>
                            <Form.Item
                                label={"Filter intervalu"}
                                name={'graphIntervalRange'}
                                valuePropName={'checked'}>
                                <Switch/>
                            </Form.Item>
                        </Col>
                        <Col sm={12}>
                            <Form.Item
                                label={"Delka intervalu"}
                                name={'graphIntervalLength'}
                                rules={[{required: true, message: 'Vyplňte delku intervalu'}]}
                                help={'Tato délka také omezí výběr časového období ve filtru'}
                            >
                                <InputNumber min={1} max={50}/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </>
        );
    }
}