import React from "react";
import IRoute from "../../../../model/interface/dataStorage/IRoute";
import {Tag} from "antd";

interface IProps {
    route: IRoute
}

interface IState {
}

class RouteLabel extends React.Component<IProps, IState> {
    render() {
        const {route} = this.props
        const children = route.url.slice(5).split('/').map(part => part[0] === ':' ? <>/<Tag className={"px-1 m-0"}>{part}</Tag></> : <>/{part}</>)
        return <>{children}</>
    }
}

export default RouteLabel