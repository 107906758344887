import {Card, Typography} from 'antd';
import React from "react";
import IField, {FIELD_MODE_RELATION} from "../../../../../../../model/interface/dataStorage/IField";
import {WarningOutlined} from "@ant-design/icons";
import FieldFileOptionsEditor from "../../../../content-type/field/optionEditors/FieldFileOptionsEditor";

interface IProps {
    field: IField
}

class FormFieldWYSIWYGEditor extends React.Component<IProps> {

    render() {
        const {field} = this.props
        return field.mode === FIELD_MODE_RELATION ? (
            <Card>
                <FieldFileOptionsEditor buildFieldName={(...args) => args} options={field.options}/>
            </Card>
        ) : <Typography.Text className={'mb-3 d-block'} type={"warning"}>
           <WarningOutlined/> Neplatný režim pole, aby pole souboru správně fungovalo, musí být pole v režimu vztahu
        </Typography.Text>
    }
}

export default FormFieldWYSIWYGEditor