import React from "react"
import {Button,} from "antd";
import {IViewSetStepProps} from "./ViewConfiguration";
import View from "../../view/View";

class ViewPreview extends React.Component<IViewSetStepProps> {
    render() {
        const {resource, match, history} = this.props

        return (
            <>
                {resource && resource.units && (
                    <View preview={true} uuid={resource.uuid} resource={resource} match={match} history={history}/>
                )}

                <Button type={"primary"} onClick={() => this.props.setStep()}>Následující krok</Button>
                <Button onClick={() => this.props.setStep(true)}>Předchozí krok</Button>
            </>
        )
    }
}

export default ViewPreview