import React from "react";
import ISubmitOptions from "../../../../../../model/interface/form/elementOptions/ISubmitOptions";
import ButtonProperties from "../../../widget/optionEditor/ButtonProperties";

interface IProps {
    options: ISubmitOptions
}

class SubmitEditor extends React.Component<IProps> {

    render() {
        return (
            <ButtonProperties/>
        )
    }
}

export default SubmitEditor