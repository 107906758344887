import React from "react";
import {Divider, Spin, Typography} from "antd";
import IRestServiceFilters, {isFilterNested} from "../../../../model/interface/api/IRestServiceFilters";
import Button from "../../../shared/button/Button";


interface IState {
}

interface IProps {
    onReset?: () => void
    getFieldTitle: (name: string) => string | undefined | React.ReactNode
    total?: number
    count?: number
    filters?: IRestServiceFilters
    loading?: boolean
}

class ViewFiltersInfo extends React.Component<IProps, IState> {


    constructor(props: IProps) {
        super(props)
        this.state = {
        }
    }

    render() {
        const {filters, count, total, onReset, getFieldTitle, loading} = this.props
        const filterList = filters ? Object.values(filters)
            .filter((filter, i, a) =>
                a.findIndex(f => "field" in f && "field" in filter && f.field === filter.field) === i)
            .map(filter => {
                return isFilterNested(filter) ? filter.type : getFieldTitle(filter.field)
            }) : []

        return filterList.length > 0 ? (
            <>
                <Typography className={"font-size-base font-weight-normal ml-2"}>
                    Filtrováno {count !== undefined && total !== undefined && <><strong>{count}</strong> z <strong>{total}</strong> záznamů </>}
                    podle <strong>{filterList.join(', ')}</strong></Typography>
                <Button type={"default"} size={"small"} className={"ml-2"} onClick={onReset}>Zrušit filtry</Button>
            </>
        ) : count !== undefined && total !== undefined && <Typography className={"font-size-base font-weight-normal mr-2 ml-2"}>
            {filterList.length > 0 && <Divider type={"vertical"}/>}
            Záznamů: { loading ? <Spin size={"small"} className={"ml-2"} /> : <span className={"font-weight-bold"}>{count}</span>}
        </Typography>
    }
}

export default ViewFiltersInfo