import React from "react";
import {Form, Input} from "antd";
import settingsService from "../../../../../model/service/SettingsService";
import InputValidator from "../../../../../utils/InputValidator";
import {CheckCircleFilled, CloseCircleFilled, InfoCircleFilled} from "@ant-design/icons";

interface IProps {
    passwordProperty?: string
}

interface IState {
    validity: { [name: string]: boolean }
}

export default class NewPasswordProperties extends React.Component<IProps, IState> {

    constructor(props: Readonly<IProps> | IProps) {
        super(props);
        this.state = {
            validity: {}
        }
    }

    setValidity = (name: string, valid: boolean = true) => {
        this.setState(state => ({validity: {...state.validity, [name]: valid}}))
    }

    render() {
        const {validity} = this.state
        const passwordProperty = this.props.passwordProperty || 'password'

        const passwordMinLength = settingsService.getValue('security-password', 'min-length');
        const passwordLowerUpper = settingsService.getValue('security-password', 'lower-upper');
        const passwordNumber = settingsService.getValue('security-password', 'number');
        const passwordSpecial = settingsService.getValue('security-password', 'special');
        const validations = {
            passwordMinLength: {
                active: !!passwordMinLength,
                message: 'Minimální požadovaná délka znaku: ' + passwordMinLength,
                isValid: (value: string) => !passwordMinLength || InputValidator.validatePasswordLength(value, passwordMinLength)
            },
            passwordLowerUpper: {
                active: !!passwordLowerUpper,
                message: 'Alespoň jedno malé a velké písmeno',
                isValid: (value: string) => !passwordLowerUpper || InputValidator.validatePasswordLowerUpperRequired(value)
            },
            passwordNumber: {
                active: !!passwordNumber,
                message: 'Minimálně jedno číslo je vyžadováno',
                isValid: (value: string) => !passwordNumber || InputValidator.validatePasswordNumberRequired(value)
            },
            passwordSpecial: {
                active: !!passwordSpecial,
                message: 'Minimálně jeden speciální znak je vyžadován',
                isValid: (value: string) => !passwordSpecial || InputValidator.validatePasswordSpecialRequired(value)
            },
        }

        const activeValidations = Object.entries(validations).filter(([_, v]) => v.active)

        const status = Object.keys(validity).length === 0 ? undefined : (Object.values(validity).every(v => v) ? 'success' : 'error')

        return (
            <div>
                <Form.Item
                    name={passwordProperty}
                    label="Nové heslo"
                    hasFeedback={!!status}
                    validateStatus={status}
                    help={activeValidations.length ? <div>
                        {activeValidations.map(([name, validation]) => (
                            <div className={status && 'text-' + (validity[name] ? 'success' : 'error')}>
                                <div className={'d-inline-block mr-2'}>
                                    {!status && <InfoCircleFilled/>}
                                    {status && (validity[name] ? <CheckCircleFilled/> : <CloseCircleFilled/>)}
                                </div>
                                {validation.message}
                            </div>
                        ))}
                    </div> : undefined}
                    rules={[
                        {
                            required: true,
                            message: 'Zadejte prosím nové heslo.',
                        },
                        ...activeValidations.map(([name, validation]) => () => ({
                            validator: (_: any, value: any) => {
                                if (validation.isValid(value)) {
                                    this.setValidity(name)
                                    return Promise.resolve();
                                }
                                this.setValidity(name, false)
                                return Promise.reject(validation.message);
                            },
                        }),)
                    ]}
                >
                    <Input.Password autoComplete={"new-password"}/>
                </Form.Item>
                <Form.Item
                    name="confirm"
                    label="Nové heslo znovu"
                    dependencies={[passwordProperty]}
                    rules={[
                        {
                            required: true,
                            message: 'Potvrďte prosím své nové heslo!',
                        },
                        ({getFieldValue}) => ({
                            validator(_, value) {
                                if (!value || getFieldValue(passwordProperty) === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject('Hesla se neshodují.');
                            },
                        }),
                    ]}
                >
                    <Input.Password/>
                </Form.Item>
            </div>
        )
    }
}