import React, {RefObject} from "react";
import {FormInstance, Spin} from "antd";
import IFile from "../../../../model/interface/file/IFile";
import Simple from "./filePicker/Simple";
import Extended from "./filePicker/Extended";
import FilesService from "../../../../model/service/file/FilesService";
import {API_FILTER_TYPE} from "../../../../model/constants/ApiConstant";

export type IFilePickerWidgetType = 'simple' | 'extended'

interface IProps {
    onChange?: (files?: IFile | IFile[]) => void
    value?: (IFile | number | string)[] | number | string | IFile
    directory?: number
    canChoose?: boolean
    canUpload?: boolean
    canDownload?: boolean
    canRemove?: boolean
    multiple?: boolean,
    widget?: IFilePickerWidgetType
    disabled?: boolean,
    extensions?: string[]
    placeholder?: string
    noModal?: boolean
}

export interface IPropsFilePickerWidget {
    canChoose?: boolean
    canUpload?: boolean
    canDownload?: boolean
    canRemove?: boolean
    fileList: IFile[]
    multiple?: boolean
    disabled?: boolean
    directory?: number
    extensions?: string[]
    onRemove: (file: IFile) => void
    onChange: (files: IFile[]) => void
    placeholder?: string
}

interface IState {
    search?: string,
    directory?: number[],
    loading: boolean,
    formRef: RefObject<FormInstance>,
    value: IFile[],
    fileFoundList: IFile[],
    saving: boolean,
    visible: boolean
}

class FilePicker extends React.Component<IProps, IState> {

    static defaultProps = {
        canChoose: true,
        canUpload: true,
        canRemove: true,
        canDownload: true,
        widget: 'simple'
    };

    constructor(props: IProps) {
        super(props);
        this.state = {
            loading: false,
            directoryTree: null,
            formRef: React.createRef(),
            value: [],
            fileFoundList: [],
            saving: false,
            visible: false
        } as IState
    }

    componentDidMount() {
        this.load();
    }

    load() {
        let {value} = this.props
        value = value ? Array.isArray(value) ? value : [value] : []
        if (value[0] && (typeof value[0] === "number" || typeof value[0] === "string")) {
            this.setState({loading: true})
            FilesService.collectionList({
                filters: {
                    ids: {value: value as any, field: typeof value[0] === "number" ? 'id' : 'uuid', type: API_FILTER_TYPE.IN}
                }
            }).then(({results}) => {
                console.log(results)
                this.setState({value: results, loading: false}, this.onChange)
            })
        } else {
            this.setState({
                value: value as any
            })
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>) {
        const {value} = this.props
        if (value && prevProps.value !== value) {
            this.load()
        }
    }

    showModal = () => {
        this.setState({visible: true})
    }

    removeFile = (file: IFile) => {
        this.setState(state => {
            return {
                value: state.value.filter(listFile => listFile.id !== file.id),
            };
        }, this.onChange);
    }

    onChange() {
        this.props.onChange && this.props
            .onChange(this.props.multiple ? this.state.value : this.state.value[0])
    }

    onUpload = (files: IFile[]) => {
        this.setState({value: files}, this.onChange)
    }

    render() {
        const {loading, value} = this.state
        const {
            widget,
            extensions,
            canChoose,
            canUpload,
            canDownload,
            canRemove,
            multiple,
            directory,
            placeholder,
            noModal
        } = this.props

        const widgetProps: IPropsFilePickerWidget = {
            fileList: value,
            canChoose,
            canDownload,
            canUpload,
            canRemove,
            onChange: this.onUpload,
            onRemove: this.removeFile,
            extensions: extensions,
            multiple: multiple,
            directory: directory,
            placeholder: placeholder
        }

        return (
            <div>
                <Spin spinning={loading}>
                    {widget && {
                        simple: <Simple noModal={noModal} {...widgetProps}/>,
                        extended: <Extended {...widgetProps}/>
                    }[widget]}
                </Spin>
            </div>
        )
    }
}

export default FilePicker