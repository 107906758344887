import React from 'react'
import {Button, Dropdown, Menu} from 'antd';
import IBaseProps from "../../../../../model/interface/IBaseProps";
import ICard from "../../../../../model/interface/dataStorage/ICard";
import {connect, RootStateOrAny} from "react-redux";
import selectors from "../../../../../redux/selectors";
import IContentType from "../../../../../model/interface/dataStorage/IContentType";
import IUser from "../../../../../model/interface/security/IUser";
import {SettingOutlined} from "@ant-design/icons";
import CardModal, {CardConfigurationStep} from "./CardModal";
import ContentTypesService from "../../../../../model/service/dataStorage/ContentTypesService";
import Utils from "../../../../../utils";
import {reLoad} from "../../../../../redux/actions/Setup";
import {Link} from "react-router-dom";
import ContentTypeCards from "../ContentTypeCards";
import ContentTypeConfiguration from "../ContentTypeConfiguration";
import UsersService from "../../../../../model/service/security/UsersService";

interface IState {
    visible: boolean
    configurationStep?: CardConfigurationStep
}

interface IProps extends IBaseProps {
    user: IUser
    resource: ICard
    findContentType: (uuid: string) => IContentType
    reLoad: () => void
}

class CardEditButton extends React.Component<IProps, IState> {

    constructor(props: IProps, context: any) {
        super(props, context);
        this.state = {
            visible: false
        }
    }

    finish = (card?: ICard, changedCards: ICard[] = []) => {
        if (card) {
            const contentType = this.getContentType()
            const cards = Utils.arrayAddOrUpdateWhere(contentType.cards, {uuid: card.uuid}, card)
            return ContentTypesService.resourceUpdate(contentType.id, {
                cards: ContentTypeConfiguration.prepareCards(ContentTypeCards.updateWithChangedCards(changedCards, cards))
            }).then(() => {
                this.props.reLoad();
            })
        } else {
            return new Promise<void>(resolve => this.setState({visible: false}, resolve))
        }
    }

    render() {
        const {visible, configurationStep} = this.state
        const {user, resource} = this.props

        return (
            <div className={"noPrint"}>
                {UsersService.hasCredential(user, 'configuration') && (
                    <>
                        {visible && <CardModal card={resource} contentType={this.getContentType()}
                                               onFinish={this.finish} {...this.props} step={configurationStep}/>}
                        <Dropdown
                            trigger={["click"]}
                            overlay={(
                                <Menu>
                                    <Menu.Item key="1" onClick={() => this.setState({visible: true})}>
                                        Upravit kartu
                                    </Menu.Item>
                                    <Menu.Item key="2">
                                        <Link to={"/app/configuration/content-types/" + this.getContentType().id}>
                                            Upravit typ obsahu
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key="3"
                                               onClick={() => this.setState({
                                                   visible: true,
                                                   configurationStep: 'editor'
                                               })}>
                                        Otevřít editor
                                    </Menu.Item>
                                </Menu>
                            )}
                        >
                            <Button
                                style={{verticalAlign: "middle"}}
                                icon={<SettingOutlined/>}
                                // shape="circle"
                                size={"small"}
                            />
                        </Dropdown>
                    </>
                )}
            </div>
        )
    }

    getContentType(): IContentType {
        return this.props.findContentType(this.props.resource.contentType)
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    return {
        // findService: (contentType: IContentType) => selectors.services.findOneByContentType(state, contentType),
        findContentType: (uuid: string) => selectors.contentTypes.findOneBy(state, 'uuid', uuid),
        user: state.setup.user
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        reLoad: () => dispatch(reLoad())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CardEditButton)