import React from "react";
import {Col, Row, Spin, Typography} from "antd";
import moment, {Moment} from "moment";
import IEmployee from "../../../../../model/interface/company/IEmployee";
import IPlan from "../../../../../model/interface/company/workload/IPlan";
import {API_FILTER_TYPE} from "../../../../../model/constants/ApiConstant";
import {DATE_FORMAT_H_mm, DATE_FORMAT_YYYY_MM_DD_HH_mm_ss} from "../../../../../model/constants/DateConstant";
import PlansService from "../../../../../model/service/company/workload/PlansService";
import IEntry from "../../../../../model/interface/company/workload/IEntry";
import {MomentBuilder} from "../../../../../utils/MomentBuilder";
import EntriesService from "../../../../../model/service/company/workload/EntriesService";

interface IProps {
    employee: IEmployee,
    date: Moment
}

interface IState {
    loading: boolean,
    plans: IPlan[]
}

class CurrentPlans extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            plans: [],
            loading: false
        }
    }

    componentDidMount() {
        this.loadCurrentPlans()
    }

    componentDidUpdate(prevProps: Readonly<IProps>) {
        if (prevProps.date.unix() !== this.props.date.unix()) {
            this.loadCurrentPlans()
        }
    }

    loadCurrentPlans = () => {
        const {date, employee} = this.props
        this.setState({loading: true})

        const options = {
            filters: {
                employee: {
                    field: 'employee',
                    type: API_FILTER_TYPE.EQUAL,
                    value: employee.uuid
                },
                beginAt: {
                    field: 'beginAt',
                    type: API_FILTER_TYPE.LESSER_OR_EQUAL,
                    value: date.clone().endOf('day').format(DATE_FORMAT_YYYY_MM_DD_HH_mm_ss)
                },
                endAt: {
                    field: 'endAt',
                    type: API_FILTER_TYPE.GREATER_OR_EQUAL,
                    value: date.clone().startOf('day').format(DATE_FORMAT_YYYY_MM_DD_HH_mm_ss)
                },
                state: {
                    field: 'state',
                    type: API_FILTER_TYPE.EQUAL,
                    value: 'approved'
                }
            },
            depth: 5,
            limit: 0
        }
        return PlansService.collectionList({...options, cache: false}).then(response => {
            this.setState({
                plans: response.results,
                loading: false
            })
        })
    }

    getDayEntries = (entries: IEntry[]): IEntry[] => {
        const {date} = this.props

        return EntriesService.getInstance().filterByDate(entries, date)
    }

    getTotal = (plan: IPlan) => {
        let total = 0
        this.getDayEntries(plan.entries).forEach(entry => {
            total += (MomentBuilder.build(entry.endAt).diff(MomentBuilder.build(entry.startAt)) / (1000 * 60 * 60))
        })
        return total
    }


    render() {
        const {loading, plans} = this.state

        return <Spin size={"small"} spinning={loading}>
            <Row justify={'center'} gutter={[8, 8]}>
                {plans.map((plan, index) => (
                    <Col>
                        <Typography.Text strong={true}>
                            {plan.type?.title + ' '
                                + moment().startOf('day').hours(this.getTotal(plan) || 0).format(DATE_FORMAT_H_mm)
                                + (index < (plans.length - 1) ? ', ' : '')}
                        </Typography.Text>
                    </Col>
                ))}
            </Row>
        </Spin>
    }
}


export default CurrentPlans