import React from "react";
import {Form, Select, Switch} from "antd";
import IEntityTableOptions from "../../../../../../model/interface/form/elementOptions/IEntityTableOptions";
import IField, {RELATION_FIELD_TYPE} from "../../../../../../model/interface/dataStorage/IField";
import Utils from "../../../../../../utils";

interface IProps {
    options: IEntityTableOptions
    fields: IField[]
}

class EntityTableEditor extends React.Component<IProps, IEntityTableOptions> {

    constructor(props: Readonly<IProps> | IProps) {
        super(props);
        this.state = {
            ...props.options
        }
    }

    render() {
        let {fields} = this.props
        if (typeof fields === 'undefined') {
            fields = []
        }

        return (
            <div>
                <Form.Item name={'fieldId'} label={'Podminene pole'} rules={[{required: true}]}>
                    <Select>
                        {fields.filter(field => Utils.isTargetEntityContentType(field.targetEntity) && [
                            RELATION_FIELD_TYPE.ONE_TO_MANY,
                            RELATION_FIELD_TYPE.MANY_TO_MANY
                        ].includes(field.type))
                            .map(field => {
                                return (
                                    <Select.Option key={field.id} value={field.id as number}>
                                        {field.label} [{field.name}]
                                    </Select.Option>
                                )
                            })}
                    </Select>
                </Form.Item>
                <Form.Item name={'add'} label={'Moznost vytvorit'} valuePropName={'checked'}>
                    <Switch/>
                </Form.Item>
                <Form.Item name={'delete'} label={'Moznost mazat'} valuePropName={'checked'}>
                    <Switch/>
                </Form.Item>
            </div>
        )
    }
}

export default EntityTableEditor