import React, {RefObject} from "react"
import IRoute from "model/interface/dataStorage/IRoute";
import {Button, Collapse, Form, FormInstance, Input, message, Row, Spin, Switch} from "antd";
import RoutesService from "../../../../model/service/ui/RoutesService";
import Wysiwyg from "../../../shared/input/Wysiwyg";
import ManualsService from "../../../../model/service/ui/ManualsService";
import IManual from "../../../../model/interface/ui/IManual";
import {reLoad, update} from "../../../../redux/actions/Setup";
import {connect, RootStateOrAny} from "react-redux";
import {ISetupState} from "../../../../redux/reducers/Setup";
import Modal from "../../../shared/modal/Modal";
import {SaveOutlined} from "@ant-design/icons";
import BackgroundPicker from "../../../shared/pickers/BackgroundPicker";

interface IProps {
    route?: IRoute
    onChange: (route?: IRoute) => void
    identifier?: string
    reLoad: () => void,
    update: (changes: any) => void,
    preventReload?: boolean,
    routes: IRoute[],
    modal?: boolean
}

interface IState {
    formRef: RefObject<FormInstance>,
    loading: boolean,
    loadingManual: boolean,
    manual?: IManual
}

class RouteForm extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            formRef: React.createRef(),
            loading: false,
            loadingManual: false,
        }
    }

    componentDidMount() {
        this.loadManual();
    }

    loadManual() {
        const {route} = this.props
        if (route && route.manual) {
            this.setState({loadingManual: true})
            ManualsService.resourceRetrieve(route.manual).then(manual => {
                this.setState({manual, loadingManual: false})
            })
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>) {
        if (prevProps.route?.uuid !== this.props.route?.uuid){
            this.state.formRef.current?.resetFields()
            this.loadManual()
        }
    }

    onOk = () => {
        const {onChange, identifier, route, reLoad, update, preventReload, routes} = this.props
        const {formRef, manual} = this.state
        formRef.current?.validateFields()
            .then(values => {
                this.setState({loading: true})
                this.save({
                    ...route, ...values,
                    url: values.url + (identifier ? '/:' + identifier : ''),
                    manual: manual ? manual : null
                })
                    .then(resource => {
                        onChange(resource)
                        message.success('URL uložena. Aktualizuji osobní nastavení.').then()
                        preventReload ? update({
                            routes: [...routes.filter(r => r.uuid !== resource.uuid), resource]
                        }) : reLoad()
                    })
            })
    }

    save(route: IRoute) {
        if (route.id) {
            return RoutesService.resourceUpdate(route.id, route)
        }

        return RoutesService.collectionCreate(route)
    }

    onCancel = () => {
        this.props.onChange()
    }

    updateManual = (changes: any) => {
        this.setState(state => ({manual: {...state.manual, ...changes}}))
    }

    render() {
        const {loading, formRef, loadingManual, manual} = this.state
        const {route, identifier, modal} = this.props

        const rules = [
            {required: true, message: 'Prosím vyplňte adresu'},
            () => ({
                validator(_: any, value: string) {
                    if (value.match(/^\/?([\w-:?&]+\/?)*$/)) { //TODO better validation ?
                        return Promise.resolve();
                    }
                    return Promise.reject(new Error('Syntaxe adresy URL není platná.'));
                }
            })]

        const form = <div>
            <Form ref={formRef} initialValues={route} layout={"vertical"}>
                <Form.Item label={"URL"} name={"url"} rules={rules}>
                    <Input addonAfter={identifier ? '/:' + identifier : ''}/>
                </Form.Item>
                <Form.Item label={"Pozadí"} name={"background"}>
                    <BackgroundPicker/>
                </Form.Item>
            </Form>
            <Collapse defaultActiveKey={route?.manual ? ['1'] : []}>
                <Collapse.Panel header={route?.manual ? 'Manuál' : 'Vytvořit manuál'} key="1">
                    {loadingManual ? <Row justify={"center"}><Spin size={"large"}/></Row> : (
                        <Form initialValues={manual} layout={"vertical"} onValuesChange={this.updateManual}>
                            <Form.Item label={"Obsah"} name={"content"}>
                                <Wysiwyg mode={'full'} inlineFile={true}/>
                            </Form.Item>
                            <Form.Item name={'active'} label={'Aktivní'} valuePropName={'checked'}>
                                <Switch/>
                            </Form.Item>
                        </Form>
                    )}
                </Collapse.Panel>
            </Collapse>
            {!modal && (
                <Button icon={<SaveOutlined/>} className={'mt-3'} type="primary" loading={loading} onClick={this.onOk}>
                    Uložit
                </Button>
            )}
        </div>

        return modal ? (<Modal
                title={route ? 'Upravit cestu' : 'Vytvořit cestu'}
                visible={true}
                fullScreenOption={true}
                destroyOnClose={true}
                onOk={() => this.onOk()}
                onCancel={this.onCancel}
                closable={true}
                footer={[
                    <Button key="submit" type="primary" loading={loading} onClick={this.onOk} icon={<SaveOutlined/>}>
                        Uložit
                    </Button>
                ]}
            >
                {form}
            </Modal>
        ) : form
    }
}


const mapStateToProps = (state: RootStateOrAny) => {
    const {routes} = state.setup as ISetupState
    return {
        routes
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        reLoad: () => dispatch(reLoad()),
        update: (changes: any) => dispatch(update(changes))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RouteForm)
