import ICustomArguments from "../ICustomArguments";

export const ContentTypeScript = {
    EVENT_LOAD: 'load',
    EVENT_CREATE: 'create',
    EVENT_UPDATE: 'update',
    EVENT_PRE_DELETE: 'pre_delete',
    EVENT_POST_DELETE: 'post_delete',
}

export const ContentTypeScriptEvents = [
    {
        value: ContentTypeScript.EVENT_LOAD,
        label: 'Zobrazení',
        color: 'default'
    },
    {
        value: ContentTypeScript.EVENT_CREATE,
        label: 'Vytvoření',
        color: 'success'
    },
    {
        value: ContentTypeScript.EVENT_UPDATE,
        label: 'Změna',
        color: 'processing'
    },
    {
        value: ContentTypeScript.EVENT_PRE_DELETE,
        label: 'Před smazáním',
        color: 'error'
    },
    {
        value: ContentTypeScript.EVENT_POST_DELETE,
        label: 'Po smazání',
        color: 'error'
    }
]

export default interface IContentTypeScript {
    id?: number | null,
    uuid: string,
    name: string,
    contentType: string,
    script: string
    weight: number
    arguments: ICustomArguments
    events: string[]
}