import _ from 'underscore'
import {RootStateOrAny} from "react-redux";
import IForm from "../../model/interface/form/IForm";

export const findAll = (store: RootStateOrAny): IForm[] => store.setup.forms

export const findOneBy = (store: RootStateOrAny, property: string, value: any): IForm => _.findWhere(findAll(store), {[property]: value})!
// export const findOneBy = (store: RootStateOrAny, property: string, value: any): IForm => {
//     console.log('forms find one by', property, value, store.setup.forms)
//     const form = _.findWhere(findAll(store), {[property]: value})
//
//     return form
// }

export default {
    findAll,
    findOneBy
}