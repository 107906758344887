import fetch from 'model/auth/FetchInterceptor'
import IAction from "model/interface/dataStorage/IAction";
import IRestResource from "../../interface/api/IRestResource";
import IScript from "../../interface/dataStorage/IScript";
import Utils from 'utils';
import {Modal} from "antd";
import IActionMessage from "../../interface/dataStorage/IActionMessage";
import selectors from "../../../redux/selectors";
import store from "../../../redux/store";
import IRestServiceOptions from "../../interface/api/IRestServiceOptions";
import IRestServiceCollectionResponse from "../../interface/api/IRestServiceCollectionResponse";
import RestService from "./RestService";

// import fetch from "../../auth/ETagCachedFetchInterceptor";

export interface IActionResultProperties {
    formData: FormData | object
}

export interface IActionResult {
    action: IAction,
    resources: IRestResource[],
    properties: IActionResultProperties,
    success: boolean,
    reload?: boolean,
    redirect?: string,
    messages: IActionMessage[],
    review: { script?: IScript, response: any, success: boolean }[]
    payload: { [name: string]: any }
    fileIds?: number[]
}

export const ACTION_REJECT_REASON = {
    TERMINATED: 'action_terminated',
    CANCELLED: 'action_canceled'
}

export interface IRestActionServiceCollectionResponse extends IRestServiceCollectionResponse {
    results: Array<IAction>
}

interface IActionsService {
    collectionList(options?: IRestServiceOptions): Promise<IRestActionServiceCollectionResponse>,

    collectionCreate(data: any): Promise<IAction>,

    resourceRetrieve(id: number | string): Promise<IAction>,

    resourceUpdate(id: number | string, data: any): Promise<IAction>,

    resourceDelete(id: number | string): Promise<void>,

    // resourceRetrieve(moduleId: number, contentTypeId: number, actionId: number): Promise<IAction>
    execute(action: IAction, entity: IRestResource[] | null, payload?: { [name: string]: any }, confirm?: boolean): Promise<IActionResult>

    showConfirmModal(text: string): Promise<void>,

    isResourceAllowed(resource: IRestResource, action: IAction): boolean
}

const ActionService_COLLECTION = 'actions'

const ActionsService: IActionsService = {
    execute: (action, resources, payload?, confirm: boolean = true) => {
        if (!payload) {
            payload = {}
        }
        let confirmPromise: Promise<void>
        if (action.confirmModalEnabled && action.confirmModalText && confirm) {
            confirmPromise = ActionsService.showConfirmModal(action.confirmModalText)
        }

        const last = selectors.routes.getReferrer(store.getState())
        payload.last = last ? {
            ...selectors.routes.extractRouteParametersFromUrl(store.getState(), last),
            url: last
        } : undefined

        payload.referrer = window.location.pathname

        const run = () => {
            let filteredResources = resources ? resources.filter(resource => resource && resource.id).map(resource => resource.id) : undefined
            return fetch({
                url: '/actions/execute',
                method: 'post',
                data: Utils.convertArrayToFormData({
                    uuid: action.uuid,
                    // @ts-ignore
                    contentType: typeof action.contentType === 'object' ? action.contentType.uuid : action.contentType,
                    // resrouce: resource && resource.id ? resource.id : undefined,
                    // @ts-ignore
                    resources: filteredResources,
                    payload
                })
                // @ts-ignore
            }).then((response: IActionResult) => {
                return {...response}
            })
        }
        // @ts-ignore
        return confirmPromise ? confirmPromise.then(() => {
            return run()
        }).catch(() => Promise.reject(ACTION_REJECT_REASON.CANCELLED)) : run()
    },
    showConfirmModal: (text: string) => {
        return new Promise<void>((resolve, reject) => {
            Modal.confirm({
                title: <div dangerouslySetInnerHTML={{__html: text}}/>,
                okText: 'Potvrdit',
                cancelText: 'Zrušit',
                onOk: () => {
                    resolve()
                },
                onCancel: () => {
                    reject()
                },
                type: 'warning'
            })
        })
    },
    collectionList: function (options?) {
        return RestService.collectionList(ActionService_COLLECTION, options as unknown as IRestServiceOptions) as Promise<IRestActionServiceCollectionResponse>
    },
    collectionCreate: function (data) {
        return RestService.collectionCreate(ActionService_COLLECTION, data) as Promise<IAction>
    },
    resourceRetrieve: function (id) {
        return RestService.resourceRetrieve(ActionService_COLLECTION, id) as Promise<IAction>
    },
    resourceDelete: function (id) {
        return RestService.resourceDelete(ActionService_COLLECTION, id)
    },
    resourceUpdate: function (id, data) {
        return RestService.resourceUpdate(ActionService_COLLECTION, id, data) as Promise<IAction>
    },
    isResourceAllowed(resource: IRestResource, action: IAction): boolean {
        return resource._permissions[action.name]
    }
}


export default ActionsService