import React, {RefObject} from "react"
import {Button, Col, Divider, Drawer, Form, FormInstance, Row, Tooltip, Typography} from "antd";
import {IViewSettingsProps} from "../ViewSettings";
import IViewCalendarSettings, {
    ConditionalColor
} from "../../../../model/interface/dataStorage/view/calendar/IViewCalendarSettings";
import {connect, RootStateOrAny} from "react-redux";
import selectors from "../../../../redux/selectors";
import IContentType from "../../../../model/interface/dataStorage/IContentType";
import DragSortList from "../../../shared/list/DragsortList";
import {
    CaretDownOutlined,
    CaretUpOutlined,
    DeleteOutlined,
    EditOutlined,
    PlusOutlined,
    SaveOutlined
} from "@ant-design/icons";
import FilterTreeBuilder from "../settings/customFilters/FilterTreeBuilder";
import ColorPicker from "../../../shared/pickers/ColorPicker";
import arrayMove from "array-move";

interface IProps extends IViewSettingsProps {
    findContentTypeByUuid: (uuid: string) => IContentType
}

interface IState {
    currentIndex?: number
    currentContentType?: string
}

class ViewCalendarColorSettings extends React.Component<IProps, IState> {

    formRef: RefObject<FormInstance> = React.createRef()


    constructor(props: IProps) {
        super(props);

        this.state = {}
    }

    defineColor = (color: string | null | undefined, contentType: string) => {
        const {settings} = this.props
        this.onChange({
            calendarColors: {...settings?.calendarColors || {}, [contentType]: color || undefined}
        })
    }

    onChange = (values: { [K in keyof IViewCalendarSettings]: IViewCalendarSettings[K] }) => {
        const {settings, onChange} = this.props
        this.resetEditing()
        onChange({...settings, ...values}).then()
    }

    onEdit = (currentIndex: number, contentType: string) => {
        this.setState({currentIndex, currentContentType: contentType}, this.formRef.current?.resetFields)
    }

    onDelete = (currentIndex: number, contentType: string) => {
        const {settings} = this.props
        settings?.calendarConditionalColors?.[contentType]?.splice(currentIndex, 1)
        this.onChange({
            calendarConditionalColors: {
                ...settings?.calendarConditionalColors || {},
                [contentType]: settings?.calendarConditionalColors?.[contentType]
            }
        })
    }

    onAdd = () => {
        const {settings} = this.props
        this.formRef.current?.validateFields().then(values => {
            let {currentIndex, currentContentType} = this.state
            const colors = currentContentType ? settings?.calendarConditionalColors?.[currentContentType] || [] : []
            if (currentContentType && !colors) {
                this.onChange({
                    calendarConditionalColors: {
                        ...settings?.calendarConditionalColors || {},
                        [currentContentType]: [values]
                    }
                })
            } else if (currentContentType) {
                if (currentIndex !== undefined) {
                    colors[currentIndex] = {...values}
                } else {
                    colors.push(values)
                }
                this.onChange({
                    calendarConditionalColors: {
                        ...settings?.calendarConditionalColors || {},
                        [currentContentType]: colors
                    }
                })
            }
        })
    }

    resetEditing() {
        this.setState({currentIndex: undefined, currentContentType: undefined}, this.formRef.current?.resetFields)
    }

    onSortEnd = ({oldIndex, newIndex}: { oldIndex: number, newIndex: number }, contentType: string): void => {
        const {settings} = this.props
        this.onChange({
            calendarConditionalColors: {
                ...settings?.calendarConditionalColors || {},
                [contentType]: [...arrayMove(settings?.calendarConditionalColors?.[contentType] || [], oldIndex, newIndex)]
            }
        })
    }

    render() {
        const {view, findContentTypeByUuid, settings, history, match} = this.props
        const {currentIndex} = this.state

        return (
            <>
                {view.contentTypes.map(uuid => {
                    const contentType = findContentTypeByUuid(uuid)
                    const conditionalColors = settings?.calendarConditionalColors?.[contentType.fullClassName] || []
                    return <div>
                        <Divider type={'horizontal'}>{contentType.label}</Divider>
                        <ColorPicker value={settings?.calendarColors?.[contentType.fullClassName]}
                                     className={'mb-3 w-100'}
                                     onChange={(e) => this.defineColor(e, contentType.fullClassName)}/>
                        <Typography.Title level={4}>Podmíněné barvy</Typography.Title>
                        <DragSortList showEmpty={false} lockAxis={'y'} item={{
                            style: {zIndex: 1001},
                            render: (c: ConditionalColor, index, handle) => <Row
                                justify={"space-between"} align={"middle"} wrap={false}
                                className={'mb-2 border p-1 shadow-sm'}
                                style={{zIndex: 1001}} key={index}>
                                <Col>
                                    {handle}
                                </Col>
                                <Col flex={'1 0'}>
                                    <FilterTreeBuilder value={c.conditions} disabled={true} match={match} history={history}
                                                       contentTypeFullClassName={contentType.fullClassName}/>
                                </Col>
                                <Col flex={'1 0'}>
                                    <ColorPicker disabled={true} value={c.color}/>
                                </Col>
                                <Col>
                                    <Row align={"middle"} justify={"end"}>
                                        <Button size={"small"} type={"link"} icon={<EditOutlined/>}
                                                onClick={() => this.onEdit(index, contentType.fullClassName)}/>
                                        <Button size={"small"} type={"link"} danger icon={<DeleteOutlined/>}
                                                onClick={() => this.onDelete(index, contentType.fullClassName)}/>
                                    </Row>
                                </Col>
                            </Row>
                        }} children={conditionalColors} handle={{
                            render: () => <div className={'d-inline-block'}>
                                <div className={"d-flex flex-column pl-2 pr-2"} style={{cursor: "move"}}>
                                    <CaretUpOutlined/>
                                    <CaretDownOutlined/>
                                </div>
                            </div>
                        }} onSortEnd={(filters) => this.onSortEnd(filters, contentType.fullClassName)}/>

                        <Row justify={"center"} className={'mt-3'}>
                            <Tooltip mouseEnterDelay={0.5} title={'Přidat'}>
                                <Button shape={'circle'}
                                        onClick={() => this.onEdit(conditionalColors.length, contentType.fullClassName)}
                                        icon={<PlusOutlined/>}>
                                </Button>
                            </Tooltip>
                        </Row>
                        <Drawer placement="right"
                                title={currentIndex === conditionalColors.length ? 'Vytvořit podmíněnou barvu ' : 'Upravit podmíněnou barvu'}
                                visible={currentIndex !== undefined} getContainer={false}
                                onClose={() => this.resetEditing()}
                                destroyOnClose={true}
                                style={{position: 'absolute', zIndex: 1}}
                                contentWrapperStyle={{width: 600, maxWidth: '100%'}} width={'auto'}>
                            <Form initialValues={currentIndex !== undefined ? conditionalColors[currentIndex] : {}}
                                  layout={"vertical"} ref={this.formRef}>
                                <Form.Item name={'color'} label={'Barva'}
                                           rules={[{required: true, message: 'Pole je povinné'}]}>
                                    <ColorPicker/>
                                </Form.Item>
                                <Form.Item name={'conditions'} label={'Podminka'}>
                                    <FilterTreeBuilder contentTypeFullClassName={contentType.fullClassName}
                                                       history={history}
                                                       match={match}/>
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" onClick={() => this.onAdd()} icon={<SaveOutlined/>}>
                                        Uložit
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Drawer>
                    </div>
                })}
            </>
        );
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    return {
        findContentTypeByUuid: (uuid: string) => selectors.contentTypes.findOneBy(state, 'uuid', uuid)
    }
}

export default connect(mapStateToProps)(ViewCalendarColorSettings)