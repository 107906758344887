import RestService from "model/service/dataStorage/RestService";
import IRestServiceOptions from "../../interface/api/IRestServiceOptions";
import IRestServiceCollectionResponse from "../../interface/api/IRestServiceCollectionResponse";
import IGroup from "../../interface/security/IGroup";
import IAction from "../../interface/ui/notification-bridge/IAction";
import INotification from "../../interface/ui/INotification";

export interface IRestGroupsServiceCollectionResponse extends IRestServiceCollectionResponse {
  results: Array<IGroup>
}

interface IRestActionsService {
  collectionList(options?: IRestServiceOptions): Promise<IRestGroupsServiceCollectionResponse>,
  collectionCreate(data: any): Promise<IAction>,
  resourceRetrieve(id: number|string): Promise<IAction>,
  resourceUpdate(id: number|string, data: any): Promise<IAction>,
  resourceDelete(id: number|string): Promise<void>
  execute(action: IAction, notification: INotification): Promise<INotification>
}

const COLLECTION = 'notification-bridge-actions'

const ActionsService : IRestActionsService = {
  collectionList: function (options?) {
    return RestService.collectionList(COLLECTION, options as unknown as IRestServiceOptions) as Promise<IRestGroupsServiceCollectionResponse>
  },
  collectionCreate: function ( data) {
    return RestService.collectionCreate(COLLECTION, data) as Promise<IAction>
  },
  resourceRetrieve: function (id) {
    return RestService.resourceRetrieve(COLLECTION, id) as Promise<IAction>
  },
  resourceDelete: function (id) {
    return RestService.resourceDelete(COLLECTION, id)
  },
  resourceUpdate: function (id, data) {
    return RestService.resourceUpdate(COLLECTION, id, data) as Promise<IAction>
  },
  execute(action: IAction, notification: INotification): Promise<INotification> {
    return RestService.customRetrieve('notification/' + notification.id + '/bridge-action/' + action.id ) as Promise<INotification>
  }
}

export default ActionsService