import React from "react";
import {Button} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import store from "../../redux/store";
import selectors from "../../redux/selectors";

interface IProps {
    onReset?: () => void
    onConfirm: () => void
    confirmTop?: boolean
}

export default class FilterDropDown extends React.Component<IProps>{
    render() {
        const {children, onConfirm, onReset, confirmTop} = this.props

        if (confirmTop){
            return (
                <div className={'p-2'} style={{maxWidth: 250}}>
                    {this.renderButtons(onConfirm, onReset)}
                    <div className={'mt-2'}>
                        {children}
                    </div>
                </div>
            );
        }

        return (
            <div className={'p-2'} style={{maxWidth: 250}}>
                <div className={'mb-2'}>
                    {children}
                </div>
                {this.renderButtons(onConfirm, onReset)}
            </div>
        );
    }

    private renderButtons(onConfirm: () => void, onReset: (() => void) | undefined) {
        return <>
            <Button
                type="primary"
                onClick={onConfirm}
                icon={<SearchOutlined/>}
                size="small"
                className={'mr-2'}
            >
                {selectors.dictionary.getMessage(store.getState(), 'view.table.filter.search', 'Vyhledat')}
            </Button>
            <Button onClick={onReset} size="small">
                {selectors.dictionary.getMessage(store.getState(), 'view.table.filter.cancel', 'Reset')}
            </Button>
        </>;
    }
}