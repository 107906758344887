import IRelationType from "./IRelationType";
import IRestServiceFilters from "../api/IRestServiceFilters";

export const CONDITION_TYPE_AND = 1
export const CONDITION_TYPE_OR = 2

export default interface ICondition {
    id?: number,
    uuid: string,
    type: 'workflow_state' | 'inherit' | 'value' | 'filter',
    field: string|null,
    value?: any|null,
    relationType: IRelationType|null
    inheritRule: string|null
    states: string[]
    filter?: IRestServiceFilters
}