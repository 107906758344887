import {Cascader, Divider, Spin} from 'antd';
import React from "react";
import IFieldOptions from "../../../../../../model/interface/form/elementOptions/IFieldOptions";
import _ from "underscore";
import IRestServiceOptions from "../../../../../../model/interface/api/IRestServiceOptions";
import {connect, RootStateOrAny} from "react-redux";
import selectors from "../../../../../../redux/selectors";
import IRepositoryService from "../../../../../../model/interface/IRepositoryService";
import PresenterBuilder from "../../../../../../views/dataStorage/PresenterBuilder";
import IRestResource from "../../../../../../model/interface/api/IRestResource";
import IFormElementProps from "../../../../../../model/interface/form/IFormElementProps";
import IRestServiceOrders from "../../../../../../model/interface/api/IRestServiceOrders";
import DataStorageHelper from "../../../../../../utils/DataStorageHelper";
import Utils from "../../../../../../utils";
import IField, {RELATION_FIELD_TYPE} from "../../../../../../model/interface/dataStorage/IField";
import {CascaderOptionType} from "antd/es/cascader";
import ILabelValueChildren from "../../../../../../model/interface/util/ILabelValueChildren";

interface IProps extends IFormElementProps{
    value?: number,
    onChange?: (key?: number | number[]) => void
    options: IFieldOptions
    findServiceByClassName: (name: string) => IRepositoryService
    field: IField
}

interface IState {
    value?: number|number[],
    data: IRestResource[],
    loading: boolean
    resource?: IRestResource
    options: IFieldOptions
}

class FormFieldContentTypeCascade extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            data: [],
            loading: false,
            options: props.options
        };
    }

    isUnmounted = false;

    componentDidMount() {
        try {
            this.fetchData().then(() => this.selectActive()).then(() => {

            })
        } catch (e) {
            console.error(e)
        }
    }

    componentWillUnmount() {
        this.isUnmounted = true;
    }

    getService() {
        const {options, findServiceByClassName} = this.props

        if(this.props.field && this.props.field.targetEntity) {
            return findServiceByClassName(this.props.field.targetEntity)
        }

        // @deprecated
        return options.contentTypeFullClassName ? findServiceByClassName(options.contentTypeFullClassName) : undefined;
    }

    selectActive () {
        if (this.props.value && !this.isUnmounted) {
            if(this.props.options.multiple) {
                const value = [] as number[]
                const resources = [] as IRestResource[]
                if(Array.isArray(this.props.value)) {
                    this.props.value.forEach((rawId: any) => {
                        const id = typeof rawId === 'object' ? rawId.id : rawId
                        const resource = _.findWhere(this.state.data, {id})
                        if(resource) {
                            value.push(id)
                            resources.push(resource)
                        }
                    })
                }
                this.setState({
                    value,
                    loading: false
                }, this.updateParent);
            } else {
                const rawId = this.props.value
                // @ts-ignore
                const id = typeof rawId === 'object' ? rawId.id : rawId
                const resource = _.findWhere(this.state.data, isNaN(id) ? {uuid: id} : {id: parseInt(id, 10)})
                console.log('find resource', parseInt(id, 10) > 0, id, resource, this.state.data)
                if(resource) {
                    this.setState({
                        value: resource.id,
                        // resource: resource,
                        loading: false
                    }, this.updateParent);
                }
            }
        }
    }

    buildOrderCriteria() {
        let orderBy = {} as IRestServiceOrders
        const {options} = this.props
        if (options.contentTypeOrderField) {
            const field = _.findWhere(this.getService()?.getFields() || [], {uuid: options.contentTypeOrderField})
            if(field) {
                orderBy[field.name] = {
                    field: field.name
                }
            }
        }
        return orderBy
    }

    fetchData(params: IRestServiceOptions = {}) {
        const service = this.getService()
        const {contentTypeAdditionalRows} = this.props.options
        if (!service) {
            return new Promise(() => ([]))
        }
        this.setState({data: [], loading: true})
        return service.collectionList({limit: 0, ...params, cache: false, depth: 4, order: this.buildOrderCriteria()}) //TODO cant just load everything... there has to be a limit... (2000 employees crashes on memory)
            // TODO should limit be here??, with millions of rows it should... but then the mode would always have to be set to autocomplete...
            .then(({results}) => {
                this.setState({data: [...(contentTypeAdditionalRows || []), ...results], loading: false});
            })
    }

    getLabel(resource: IRestResource) {
        const {options} = this.props
        const service = this.getService()
        const presenter = options.contentTypePresenter ?
            service?.getPresenter(options.contentTypePresenter) : service?.getDefaultPresenter()
        return presenter ? PresenterBuilder.build(presenter, resource, presenter.options || {}) : ''
    }

    handleChange = (option: any) => {
        const multiple = this.props.options.multiple
        let value: any
        if(multiple) {
            value = option ? option.map((oneOption: any) => typeof oneOption === "object" ? oneOption.value : oneOption) : []
        } else {
            value = (typeof option === "object" && option) ? option.value : option
        }
        this.setState(() => ({
            value,
            loading: false,
        }), this.updateParent)

    };

    updateParent = () => {
        this.props.onChange && this.props.onChange(this.state.value)
    }

    buildCascadeOptions () {
        const {data} = this.state
        const fieldName = this.state.options.contentTypeCascadeField;
        if(!fieldName) {
            return []
        }
        const service = this.getService()
        const field = service?.getFields().find((field) => field.name === fieldName)
        if(!field) {
            throw new Error('Invalid field: '+fieldName);
        }
        let options: any = []
        try {
            data.forEach(resource => {
                const filterValue = resource[field.name]
                if(filterValue) {
                    let value
                    let label = <>{DataStorageHelper.buildFieldValue(field, resource, this.props.history)}</>
                    switch(field.mode) {
                        case('relation'):
                            switch(field.type) {
                                case(RELATION_FIELD_TYPE.MANY_TO_MANY):
                                case(RELATION_FIELD_TYPE.ONE_TO_MANY):
                                    // TODO
                                    break;
                                case(RELATION_FIELD_TYPE.MANY_TO_ONE):
                                case(RELATION_FIELD_TYPE.ONE_TO_ONE):
                                    value = filterValue.id
                                    break;
                            }
                            break;
                        default:
                            label = value = filterValue
                    }
                    let index = Utils.findIndex(options, {value})
                    if(index < 0) {
                        options.push({
                            value,
                            label,
                            children: []
                        } as ILabelValueChildren)
                        index = Utils.findIndex(options, {value})
                    }
                    options[index].children.push({
                        value: resource.id as number,
                        label: this.getLabel(resource)
                    } as ILabelValueChildren)
                }
            })
        } catch (e) {
            console.error(e)
        }
        if(options) {
            options.forEach((item: ILabelValueChildren) => {
                item.children = this.sortOptions(item.children || [])
            })
        }
        return this.sortOptions(options);
    }

    sortOptions(options: any) {
        options.sort((a: ILabelValueChildren, b: ILabelValueChildren) => {
            const aLabel = typeof a.label === 'object' && 'props' in a.label ? a.label.props!.children : a.label
            const bLabel = typeof b.label === 'object' && 'props' in b.label ? b.label.props!.children : b.label
            if (typeof aLabel === 'string' && typeof bLabel === 'string' && typeof aLabel.localeCompare === 'function') {
                return aLabel.localeCompare(bLabel)
            }
            return aLabel > bLabel ? 1 : -1
        })
        return options
    }

    findCascadeValue (value: any) {
        let output = [] as any
        if(value) {
            this.buildCascadeOptions().forEach((parent: any) => {
                parent.children.forEach((child: any) => {
                    if(child.value === value) {
                        output = [parent.value, child.value]
                        return
                    }
                })
                if(output) {
                    return
                }
            })
        }
        return output;
    }

    getCascadePathAsStrings(path:CascaderOptionType[]):string[] {
        let output = [] as string[]
        path.forEach((option:any) => {
            if(option.label.hasOwnProperty('props')) {
                let tmp = option.label.props.children
                while(typeof tmp === 'object') {
                    tmp = tmp.props.children
                }
                output.push(tmp)
            } else {
                output.push(option.label)
            }
        });
        return output
    }

    render() {
        const {loading, value} = this.state
        const {style, placeholder, showClear, disabled} = this.props.options
        const props = {style, placeholder, allowClear: showClear}

        return (
            <Cascader
                {...props}
                onChange={(value) => this.handleChange(value[value.length - 1])}
                disabled={disabled}
                allowClear={showClear}
                value={this.findCascadeValue(value)}
                notFoundContent={loading ? <Spin size="small"/> : null}
                options={this.buildCascadeOptions()}
                showSearch={{
                    sort: (a: CascaderOptionType[], b: CascaderOptionType[]) => {
                        return this.getCascadePathAsStrings(a).join(' ') > this.getCascadePathAsStrings(b).join(' ') ? 1 : -1
                    },
                    render: (inputValue: string, path: CascaderOptionType[]) => {
                        return (
                            <>
                                {this.getCascadePathAsStrings(path).map((path, index) => (
                                    <>
                                        {index > 0 && (<Divider type={"vertical"} />)}
                                        {path}
                                    </>
                                ))}
                            </>
                        )
                    },
                    filter: (inputValue: string, path: CascaderOptionType[]|any) => {
                        return this.getCascadePathAsStrings(path).join(' ').toLowerCase().indexOf((inputValue.toLowerCase())) > -1
                    }
                }}
                displayRender={(value) => {
                    return <>{value.map((item, index) => (<>{(index > 0 ? (<Divider type={"vertical"} />) : "")}{item}</>))}</>
                }}
            />
        )
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    return {
        findServiceByClassName: (name: string) => selectors.services.findOneByFullClassName(state, name),
    }
}
export default connect(mapStateToProps)(FormFieldContentTypeCascade)