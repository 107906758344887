import React from "react";
import {IFieldEmojiOptions} from "../optionEditors/FieldEmojiOptionsEditor";
import {Image} from "antd";
import Utils from "../../../../../../utils";


interface IProps {
    options?: IFieldEmojiOptions,
    value: string
}

interface IState {
}

class Emoji extends React.Component<IProps, IState> {

    constructor(props: Readonly<IProps> | IProps) {
        super(props);
        this.state = {}
    }

    render() {
        const {options, value} = this.props

        return  Utils.isValidHttpUrl(value) ?
            <Image preview={false} style={{height: options?.emojiSize || 25}} src={value}/> :
            <span style={{fontSize: options?.emojiSize || 25}}>{value}</span>
    }
}

export default Emoji
