import React from "react";
import {FileAddOutlined} from "@ant-design/icons";
import {Tooltip} from "antd";


interface IProps {
    onClick: () => void
}

interface IState {

}

class WysiwygInlineFileButton extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props)
        this.state = {}
    }

    render() {
        const {onClick} = this.props

        return (
            <Tooltip title={'Vložit soubor'}>
                <button onClick={(e) => {
                    e.preventDefault()
                    onClick()
                }} className={'ql-link'}>
                    <FileAddOutlined className={'d-flex justify-content-center'}/>
                </button>
            </Tooltip>

        )
    }
}

export default WysiwygInlineFileButton