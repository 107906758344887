import React, {Component} from 'react';
import {Select} from "antd";

interface IProps {
    value?: string
    onChange?: (key: string) => void
    className?: string
    placeholder?: string
}

interface IState {
    value?: string
}

const Colors = [
    'text-primary',
    'text-secondary',
    'text-success',
    'text-info',
    'text-warning',
    'text-danger',
    'text-dark',
    'text-gray',
    'text-gray-light',
    'text-gray-lighter',
    'text-gray-lightest',
    'text-white',
    'text-body',
    'text-muted',
]

class TextColorPicker extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            value: props.value
        }
    }

    onPickerChoose = (type: string) => {
        this.setState({
            value: type
        })
        if (this.props.onChange) {
            this.props.onChange(type)
        }
    }

    render() {
        const {value} = this.state
        const {className, placeholder} = this.props

        return (
            <div className="tag-color-picker">
                <Select
                    allowClear
                    className={className}
                    showSearch
                    value={value}
                    placeholder={placeholder || "Vyberte barvu"}
                    optionFilterProp="children"
                    onChange={this.onPickerChoose}
                    filterOption={(input, option) =>
                        option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {Colors.map(color => (
                        <Select.Option value={color} key={color}>
                            <span className={color}>Barva</span>
                        </Select.Option>
                    ))}
                </Select>
            </div>
        )
    }
}

export default TextColorPicker
