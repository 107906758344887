import React, {RefObject} from "react";
import {Form, FormInstance, Input, Select, Switch} from "antd";
import ICommand from "../../../model/interface/symfony-console/ICommand";
import CommandService from "../../../model/service/symfony-console/CommandService";
import {CodeOutlined, SaveOutlined} from "@ant-design/icons";
import Utils from "../../../utils";
import Button from "../../shared/button/Button";
import IInput from "../../../model/interface/symfony-console/IInput";
import Arguments from "./Arguments";
import Options from "./Options";
import Modal from "../../shared/modal/Modal";
import SavedCommandService from "../../../model/service/symfony-console/SavedCommandService";
import ISavedCommand from "../../../model/interface/symfony-console/ISavedCommand";
import IconPicker from "../../shared/IconPicker";
import ButtonTypePicker from "../../shared/button/ButtonTypePicker";
import ScrollContainer from "../../shared/scrollContainer/ScrollContainer";

interface IProps {
    modal: boolean,
    onSave?: (savedCommand: ISavedCommand) => void,
    onClose?: () => void,
    resource?: ISavedCommand,
    commands: ICommand[]
}

interface IState {
    saving: boolean
    command?: string
    optionsInput: IInput,
    argumentsInput: IInput
}

class SavedCommandForm extends React.Component<IProps, IState> {

    formRef: RefObject<FormInstance> = React.createRef()

    constructor(props: IProps, context: any) {
        super(props, context);
        this.state = {
            saving: false,
            optionsInput: {...props.resource?.options},
            argumentsInput: {...props.resource?.arguments},
            command: props.resource?.command
        }
    }

    save = () => {
        const {command, argumentsInput, optionsInput} = this.state
        const {onSave, resource} = this.props
        this.formRef.current?.validateFields().then(values => {
            this.setState({saving: true})
            const data: ISavedCommand = {
                ...resource,
                ...values,
                command,
                options: optionsInput,
                arguments: argumentsInput
            };
            (resource?.id ?
                SavedCommandService.resourceUpdate(resource.id, data) : SavedCommandService.collectionCreate(data))
                .then(result => {
                    onSave?.(result)
                    this.setState({saving: false})
                })
        })
    }

    onChangeCommand = (command?: string) => {
        this.setState({command, optionsInput: {}, argumentsInput: {}})
    }

    onChangeArguments = (input: IInput, refresh = false) => {
        this.setState({argumentsInput: input}, refresh ? this.formRef.current?.resetFields : undefined)
    }

    onChangeOptions = (input: IInput, refresh = false) => {
        this.setState({optionsInput: input}, refresh ? this.formRef.current?.resetFields : undefined)
    }

    static buildCommands(commands: ICommand[]) {
        const namespaces = CommandService.groupByNamespace(commands)
        let commandList: any[] = []
        const buildCommandSelectOption = (c: ICommand) => ({
            value: c.name,
            label: <div> {c.name}
                <div className={'ml-2 d-inline text-muted'}>{c.description}</div>
            </div>
        })
        namespaces.forEach(namespace => {
            if (!namespace.name) {
                return namespace.commands.forEach(c => commandList.push(buildCommandSelectOption(c)))
            }
            commandList.push({
                value: namespace.name,
                label: namespace.name,
                options: namespace.commands.map(c => buildCommandSelectOption(c))
            })
        })
        return commandList
    }


    getCommand = () => {
        const {command} = this.state
        const {commands} = this.props
        return commands.find(c => c.name === command)!
    }

    render() {
        const {command, optionsInput, argumentsInput, saving} = this.state

        const {modal, onClose, resource, commands} = this.props

        const form = <Form layout={'vertical'} initialValues={{label: command, ...resource}} ref={this.formRef}>
            <Form.Item name={'label'} label={'Název'} rules={[{required: true, message: 'pole je povinné'}]}>
                <Input/>
            </Form.Item>
            <Form.Item name={'command'} label={'Příkaz'} rules={[{required: true, message: 'pole je povinné'}]}>
                <Select suffixIcon={<CodeOutlined/>} showSearch={true}
                        options={SavedCommandForm.buildCommands(commands)}
                        filterOption={(inputValue, option) => Utils.stringContains(option?.value, inputValue)}
                        onChange={value => this.onChangeCommand(value?.toString())}/>
            </Form.Item>
            {command &&
                <div className={'mb-3'}>
                    <Arguments command={this.getCommand()} input={argumentsInput} onChange={this.onChangeArguments}/>
                    <Options command={this.getCommand()} input={optionsInput} onChange={this.onChangeOptions}/>
                </div>
            }
            <Form.Item name={'personal'} label={'Vlastní'}>
                <Switch/>
            </Form.Item>
            <Form.Item name={'icon'} label={'Ikona'}>
                <IconPicker/>
            </Form.Item>
            <Form.Item name={'buttonType'} label={'Styl'}>
                <ButtonTypePicker/>
            </Form.Item>
            {!modal && (
                <Button key={'save'} className={'mt-3'} onClick={this.save} loading={saving} icon={<SaveOutlined/>}
                        type={"primary"}>
                    Uložit
                </Button>
            )}
        </Form>

        return modal ? <Modal onCancel={() => onClose?.()} closable={true} bodyStyle={{height: '60vh', padding: 0}}
                              visible={true} title={'Uložit příkaz'} fullScreenOption={true} footer={[
            <Button key={'save'} loading={saving} onClick={this.save} icon={<SaveOutlined/>} type={"primary"}>
                Uložit
            </Button>
        ]}>
            <ScrollContainer visibility={"visible"} className={'p-4'}>
                {form}
            </ScrollContainer>
        </Modal> : form
    }
}

export default SavedCommandForm