import React from "react";
import {Redirect, Route, RouteComponentProps, Switch, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import AppLocale from "lang";
import {IntlProvider} from "react-intl";
import {APP_PREFIX_PATH, AUTH_PREFIX_PATH, SYMFONY_CONSOLE_PATH} from 'configs/AppConfig'
import settingsService from "../model/service/SettingsService";
import IScreen from "../model/interface/ui/IScreen";
import ISettings from "../model/interface/ui/ISettings";
import AppLayout from "./app/AppLayout";
import {storeLocation} from "../redux/actions/Setup";
import {ConfigProvider} from "antd";
import {DirectionType} from "antd/es/config-provider";
import Overview from "./app/symfony-console/Overview";
import AuthLayout from "./auth/AuthLayout";
import ThemeProvider from "../theme/ThemeProvider";
import {IAppState} from "../redux/store";
import {Mode} from "../redux/reducers/Theme";

interface IProps extends RouteComponentProps<any> {
    token: string | null
    locale: string
    direction?: DirectionType
    storeLocation: (location: string) => void,
    settings?: ISettings,
    redirect: string
    mode?: Mode
}

interface IState {
    loading: boolean,
    screenTimeout: NodeJS.Timeout | null
    screen: IScreen
    loginSettings?: ISettings
    historyChangeEvent: any
}

export class Components extends React.Component<IProps, IState> {

    constructor(props: Readonly<IProps> | IProps) {
        super(props);
        this.state = {
            loading: true,
            screenTimeout: null,
            screen: {} as IScreen,
            historyChangeEvent: undefined
        }
    }

    componentDidMount() {
        this.load()
        // this.initScreen()

        this.setState({
            historyChangeEvent: this.props.history.listen((location: any) => {
                this.props.storeLocation(location.pathname)
            })
        })
    }

    componentWillUnmount() {
        if (this.state.historyChangeEvent) {
            this.state.historyChangeEvent();
        }
    }

    // initScreen() {
    //     window.addEventListener('resize orientationchange', () => {
    //         if(this.state.screenTimeout) {
    //             clearTimeout(this.state.screenTimeout!);
    //         }
    //         this.setState({
    //             screenTimeout: setTimeout(() => this.screenWrite(), 2000)
    //         })
    //     })
    // }

    // screenWrite() {
    //     const mobileRegex1 = /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i
    //     const mobileRegex2 = /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i
    //     // @ts-ignore
    //     const browser = navigator.userAgent||navigator.vendor||window.opera
    //     // @ts-ignore
    //     const isMobile = mobileRegex1.test(browser.substr(0,4)) || mobileRegex2.test(browser.substr(0,4))
    //     this.setState({
    //         screen: {
    //             width: window.innerWidth,
    //             height: window.innerHeight,
    //             isMobile,
    //             isLandscape: window.innerWidth > window.innerHeight,
    //             isPortrait: window.innerWidth < window.innerHeight,
    //         }
    //     })
    // }

    load() {
        settingsService.getPublic().then(settings => {
            this.setState({
                loading: false,
                loginSettings: settings
            })
        })
    }

    render() {
        const {loading, loginSettings} = this.state
        const {token, direction, history, match, settings, redirect, mode, locale} = this.props;
        const currentAppLocale = AppLocale[locale] || AppLocale['cs'];
        return (
            <ThemeProvider theme={mode || 'auto'}>
                <IntlProvider
                    locale={currentAppLocale.locale}
                    messages={currentAppLocale.messages}>
                    {loading ? (
                        <div>
                            Kontroluji spojení se serverem ...
                        </div>
                    ) : (
                        <>
                            <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
                                <Switch>
                                    <Route exact path="/">
                                        <Redirect to={APP_PREFIX_PATH}/>
                                    </Route>
                                    <Route path={AUTH_PREFIX_PATH} render={props =>
                                        token ? (
                                            redirect ? (
                                                <Redirect to={redirect}/>
                                            ) : (
                                                <Redirect to={APP_PREFIX_PATH + "/"}/>
                                            )
                                        ) : (
                                            <AuthLayout {...props} settings={loginSettings || {}}
                                                        direction={direction}/>
                                        )
                                    }/>
                                    <Route path={SYMFONY_CONSOLE_PATH} render={({location}) =>
                                        token ? (
                                            <Overview/>
                                        ) : (
                                            <Redirect
                                                to={{
                                                    pathname: AUTH_PREFIX_PATH,
                                                    state: {from: location}
                                                }}
                                            />
                                        )
                                    }/>
                                    <Route
                                        render={({location}) =>
                                            token && settings ? (
                                                <AppLayout
                                                    history={history}
                                                    match={match}
                                                    settings={settings}
                                                />
                                            ) : (
                                                <Redirect
                                                    to={{
                                                        pathname: AUTH_PREFIX_PATH,
                                                        state: {from: location}
                                                    }}
                                                />
                                            )
                                        }
                                    />
                                </Switch>
                            </ConfigProvider>

                        </>
                    )}
                </IntlProvider>
            </ThemeProvider>
        )
    }
}


const mapStateToProps = (state: IAppState) => {
    const {direction, mode} = state.theme;
    const {settings, locale} = state.setup;
    const {token, redirect} = state.auth;
    return {locale, direction, token, settings, redirect, mode}
};


const mapDispatchToProps = (dispatch: any) => {
    return {
        storeLocation: (location: string) => dispatch(storeLocation(location))
    }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Components));