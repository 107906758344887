import React, {Component, RefObject} from 'react';
import {Button, FormInstance, Input, message, Select} from "antd";
import {connect, RootStateOrAny} from "react-redux";
import {ISetupState} from "../../../redux/reducers/Setup";
import IRoute from "../../../model/interface/dataStorage/IRoute";
import RouteModal from "../../app/configuration/route/RouteForm";
import {CopyOutlined, EditOutlined, PlusOutlined} from "@ant-design/icons";

interface IProps {
    value?: string | IRoute
    onChange?: (uuid?: string) => void
    className?: string,
    routes: IRoute[],
    style?: React.CSSProperties,
    disabled?: boolean
    canAdd?: boolean,
    canCopy?: boolean,
    canEdit?: boolean
    identifier?: string,
}

interface IState {
    value?: string
    formRef: RefObject<FormInstance>,
    showModal?: string | true
}

class RoutePicker extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            value: typeof props.value === "string" ? props.value : props.value?.uuid,
            formRef: React.createRef()
        }
    }

    static defaultProps = {
        canAdd: true,
        canCopy: true,
        canEdit: true
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        const {value} = this.props
        if (prevProps.value !== value) {
            this.setState({value: typeof value === "string" ? value : value?.uuid})
        }
    }

    onPickerChoose = (route: string) => {
        this.setState({value: route})
        if (this.props.onChange) {
            this.props.onChange(route)
        }
    }

    onModalChange = (route?: IRoute) => {
        if (route) {
            this.props.routes.push(route)
            this.setState({value: route.uuid})
            if (this.props.onChange && route.uuid) {
                this.props.onChange(route.uuid)
            }
        }
        this.setState({showModal: undefined})
    }

    showModal = (route?: string | true) => {
        this.setState({showModal: route})
    }

    filterIfIdentifier(identifier: string | undefined, route: IRoute) {
        return identifier ? route.url.match(new RegExp('/:' + identifier + '$', 'gi')) : true;
    }

    copyCode = () => {
        const {value} = this.state
        const {routes} = this.props
        const url = routes.find(r => r.uuid === value)?.url
        if (url) {
            navigator.clipboard.writeText(url)
                .then(() => {
                    message.success('Zkopírováno').then()
                })
        }

    }

    render() {
        const {value, showModal} = this.state
        const {className, routes, style, disabled, canAdd, identifier, canCopy, canEdit} = this.props

        return (
            <div className="route-picker" style={style}>
                {showModal && (
                    <RouteModal preventReload={true} route={routes.find(r => r.uuid === showModal)}
                                identifier={identifier} onChange={this.onModalChange} modal={true}/>
                )}
                <Input.Group compact className={'d-flex w-100'}>
                    <Select
                        allowClear
                        className={className + ' flex-grow-1 min-w-0'}
                        showSearch
                        disabled={disabled}
                        value={value}
                        placeholder="Vyberte cestu"
                        optionFilterProp="children"
                        onChange={this.onPickerChoose}
                        filterOption={(input, option) =>
                            option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {routes.filter(route => this.filterIfIdentifier(identifier, route)).map(route => (
                                <Select.Option key={route.uuid} value={route.uuid as string}>
                                    {route.url}
                                </Select.Option>
                            )
                        )}
                    </Select>
                    {canAdd && !disabled &&
                        <Button className={'flex-shrink-0'} onClick={() => this.showModal(true)} icon={<PlusOutlined/>}/>}
                    {canCopy && value && !disabled &&
                        <Button className={'flex-shrink-0'} onClick={this.copyCode} icon={<CopyOutlined/>}/>}
                    {canEdit && !disabled && value &&
                        <Button className={'flex-shrink-0'} onClick={() => this.showModal(value)}
                                icon={<EditOutlined/>}/>}
                </Input.Group>
            </div>
        )
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    const {routes} = state.setup as ISetupState

    return {
        routes
    }
}

export default connect(mapStateToProps)(RoutePicker)
