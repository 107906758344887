import React, {RefObject} from "react";
import {Form, FormInstance, Input, Modal, Row, Select, Slider, Switch, Typography} from "antd";
import ITextOptions from "../../../../../model/interface/form/elementOptions/ITextOptions";
import Wysiwyg from "../../../../shared/input/Wysiwyg";
import IWidgetOptions from "../../../../../model/interface/widget/IWidgetOptions";
import Utils from "../../../../../utils";

interface IProps {
    options: ITextOptions
    formRef: RefObject<FormInstance>
    onChange: (options: IWidgetOptions) => Promise<void>
}

export const TEXT_ELEMENT_STYLE_UNDERLINE = 'underline'
export const TEXT_ELEMENT_STYLE_STRONG = 'strong'
export const TEXT_ELEMENT_STYLE_CODE = 'code'
export const TEXT_ELEMENT_STYLE_DELETE = 'delete'
export const TEXT_ELEMENT_STYLE_MARK = 'mark'
export const TEXT_ELEMENT_STYLE_DISABLED = 'disabled'

export const TEXT_ELEMENT_STYLES = [
    TEXT_ELEMENT_STYLE_UNDERLINE,
    TEXT_ELEMENT_STYLE_STRONG,
    TEXT_ELEMENT_STYLE_CODE,
    TEXT_ELEMENT_STYLE_DELETE,
    TEXT_ELEMENT_STYLE_MARK,
    TEXT_ELEMENT_STYLE_DISABLED
]

class TextWidgetEditor extends React.Component<IProps> {

    wysiwygSwitch = (is: boolean) => {
        const {text} = this.props.options
        if (!is && Utils.stripHtml(text)) {
            Modal.confirm({
                title: 'Pozor ak vypnete wysiwyg, obsah bude smazan!',
                onOk: () => this.setWysiwyg(is),
                onCancel() {
                },
                type: 'warning'
            })
        } else {
            this.setWysiwyg(is)
        }
    }

    setWysiwyg = (value = true) => {
        const {formRef, onChange, options} = this.props
        onChange({
            wysiwyg: value,
            text: !value && Utils.stripHtml(options.text) ? '' : Utils.stripHtml(options.text)
        }).then(() => formRef.current?.resetFields())
    }

    render() {
        const {isTitle, text, wysiwyg} = this.props.options

        return (
            <div>
                <Row justify={"end"}>
                    <Form.Item>
                        <Switch checkedChildren="Wysiwyg" onChange={e => this.wysiwygSwitch(e)}
                                unCheckedChildren="Wysiwyg" checked={wysiwyg}/>
                    </Form.Item>
                </Row>
                <Form.Item name={'text'} label={'Obsah'} rules={[{required: true}]}>
                    {wysiwyg ? (<Wysiwyg mode={"medium"}/>)
                        : (<Input/>)}
                </Form.Item>
                {!wysiwyg && (
                    <div>
                        <Form.Item name={'isTitle'} label={'Nadpis'} valuePropName={'checked'}>
                            <Switch/>
                        </Form.Item>
                        {isTitle ? (
                            <Form.Item name={'titleLevel'} label={'Zvolte velikost nadpisu'}>
                                <Slider defaultValue={1} min={1} max={5} tooltipVisible/>
                            </Form.Item>
                        ) : (
                            <Form.Item name={'copyable'} label={'Moznost kopirovat'} valuePropName={'checked'}>
                                <Switch/>
                            </Form.Item>
                        )}
                        <Form.Item name={'color'} label={'Zvolte barvu textu'} initialValue={'#000000'}>
                            <Input type={'color'}/>
                        </Form.Item>
                        <Form.Item name={'style'} label={'Zvolte styl textu'}>
                            <Select mode="multiple" showSearch={false}>
                                {TEXT_ELEMENT_STYLES.map(style => {
                                    const textParams: any = {};
                                    textParams[style] = true
                                    return (
                                        <Select.Option key={style} value={style}>
                                            <Typography.Text {...textParams}>{text ? text : 'Ukazka'}</Typography.Text>
                                        </Select.Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                        <Form.Item name={'ellipsis'} label={'Orezat na jeden radek'} valuePropName={'checked'}>
                            <Switch/>
                        </Form.Item>
                    </div>
                )}
            </div>
        )
    }
}

export default TextWidgetEditor