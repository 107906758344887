import React from "react"
import {Button, Radio, Typography} from "antd";
import Text from "antd/es/typography/Text";
import {IViewStepProps} from "./ViewUnitForm";


class ViewUnitType extends React.Component<IViewStepProps> {
    selectType(type: string): void {
        this.props.onChange({
            type
        })
    }

    render() {
        const {resource, setStep} = this.props
        return (
            <>
                <Typography.Title level={3}>Vyberte typ pohledu</Typography.Title>
                <div>
                    {this.props.viewTypes.map(viewType => {
                        return (
                            <label htmlFor={"view-type-" + viewType.name} key={viewType.name}
                                   className={"d-flex align-items-center border shadow-sm p-3 mb-2"}>
                                <Radio id={"view-type-" + viewType.name} checked={resource.type === viewType.name}
                                       onChange={() => this.selectType(viewType.name)} value={viewType.name}/>
                                <div>
                                    <Text strong>{viewType.label}</Text>
                                    <br/>
                                    <Text>{viewType.description}</Text>
                                </div>
                            </label>
                        )
                    })}
                </div>

                <Button type={"primary"} disabled={!resource.type} onClick={() => setStep()}>
                    Následující krok
                </Button>
            </>
        )
    }
}

export default ViewUnitType