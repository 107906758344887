import {Card, Form, Slider, Switch, Typography} from 'antd';
import React from "react";
import IField, {
    FIELD_MODE_RELATION,
    IFieldOptions,
    RELATION_FIELD_TYPE
} from "../../../../../../../model/interface/dataStorage/IField";
import {WarningOutlined} from "@ant-design/icons";

export interface IImageEditorOptions {
    imageEditorRatio?: number
    imageEditorRound?: boolean
    imageEditorRotation?: boolean
    imageEditorGrid?: boolean
}

interface IProps {
    field: IField,
    options: IFieldOptions
}

class FormFieldImageEditorEditor extends React.Component<IProps> {

    render() {
        const {field, options} = this.props
        return field.mode === FIELD_MODE_RELATION && [RELATION_FIELD_TYPE.MANY_TO_ONE, RELATION_FIELD_TYPE.ONE_TO_ONE].includes(field.type) ? (
            <Card>
                <Form.Item name={'imageEditorRatio'} label={'Poměr stran ořezávače'} rules={[{required: true}]}
                           initialValue={50}>
                    <Slider min={1} max={100}
                            marks={{50: '1:1', [(4 / 3) * 50]: '4:3', [(16 / 9) * 50]: '16:9', [(3 / 4) * 50]: '3:4', [(9 / 16) * 50]: '9:16'}}
                            tipFormatter={value => value + '/50'}/>
                </Form.Item>
                <Form.Item name={'imageEditorRound'} label={'Kulatý tvar ořezávače'} valuePropName={'checked'}>
                    <Switch/>
                </Form.Item>
                <div className={'border' + (options.imageEditorRound ? ' rounded-circle' : '')}
                     style={{height: 40, aspectRatio: (options.imageEditorRatio || 50) + ' / 50'}}></div>
                <Form.Item name={'imageEditorGrid'} label={'Zobrazit mřížku'} valuePropName={'checked'} initialValue={true}>
                    <Switch/>
                </Form.Item>
                <Form.Item name={'imageEditorRotation'} label={'Umožnit rotaci'} valuePropName={'checked'}>
                    <Switch/>
                </Form.Item>
            </Card>
        ) : <Typography.Text className={'mb-3 d-block'} type={"warning"}>
            <WarningOutlined/> Neplatný režim pole, aby pole upravy obrazku správně fungovalo, musí být pole v režimu
            vazby N:1
        </Typography.Text>
    }
}

export default FormFieldImageEditorEditor