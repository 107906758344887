import React from "react";
import {Form, Slider, Switch} from "antd";
import IReportWidgetNotifications from "../../../../../../model/interface/report/IReportWidgetNotifications";

interface IProps {
    options: IReportWidgetNotifications
}

class NotificationsWidgetEditor extends React.Component<IProps> {

    render() {

        return (
            <div>
                <Form.Item name={'showUnread'} label={'Zobrazit počet nepřečtených'} valuePropName={'checked'} initialValue={true}>
                    <Switch/>
                </Form.Item>
                <Form.Item name={'showTotal'} label={'Zobrazit celkový počet'} valuePropName={'checked'} initialValue={true}>
                    <Switch/>
                </Form.Item>
                <Form.Item name={'showLast'} label={'Zobrazit poslední'} valuePropName={'checked'} initialValue={true}>
                    <Switch/>
                </Form.Item>
                <Form.Item name={'lastLimit'} label={'Počet posledních k zobrazení'} rules={[{required: true}]} initialValue={5}>
                    <Slider min={3} max={20}/>
                </Form.Item>
                <Form.Item name={'hideOnEmpty'} label={'Skrýt widget pokud nemáte žádné upozornění'} valuePropName={'checked'}>
                    <Switch/>
                </Form.Item>
                <Form.Item name={'showRead'} label={'Zobrazovat přečtené upozornění'} valuePropName={'checked'}>
                    <Switch/>
                </Form.Item>
                <Form.Item name={'showSenderIcon'} label={'Zobrazovat ikonu uživatele'} valuePropName={'checked'}>
                    <Switch/>
                </Form.Item>
            </div>
        )
    }
}

export default NotificationsWidgetEditor