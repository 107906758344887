const base = {
	BASE_URL: process.env.REACT_APP_BASE_URL,
	API_ENDPOINT_URL: process.env.REACT_APP_API_URL
}

const dev = {
	...base,
	debug: true
};

const prod = {
	...base,
	debug: false
};

const test = dev // TODO

const getEnv = () => {
	switch (process.env.NODE_ENV) {
		case 'production':
			return prod
		case 'test':
			return test
		case 'dev':
		case 'development':
		default:
			return dev
	}
}

export const env = getEnv()
