import IRestServiceCollectionResponse from "../../interface/api/IRestServiceCollectionResponse";
import RestService from "model/service/dataStorage/RestService";
import IRestServiceOptions from "model/interface/api/IRestServiceOptions";
import IButtonType from "../../interface/ui/IButtonType";


interface IRestRoutesServiceCollectionResponse extends IRestServiceCollectionResponse {
    results: Array<IButtonType>
}

interface IRestButtonTypesService {
    collectionList(options?: IRestServiceOptions): Promise<IRestRoutesServiceCollectionResponse>,

    collectionCreate(data: any): Promise<IButtonType>,

    resourceRetrieve(id: number | string): Promise<IButtonType>,

    resourceUpdate(id: number | string, data: any): Promise<IButtonType>,

    resourceDelete(id: number | string): Promise<void>,
}

const COLLECTION = 'ui/button-types'

const ButtonTypesService: IRestButtonTypesService = {
    collectionList: function (options?: IRestServiceOptions) {
        return RestService.collectionList(COLLECTION, options as unknown as IRestServiceOptions) as Promise<IRestRoutesServiceCollectionResponse>
    },
    collectionCreate: function (data) {
        return RestService.collectionCreate(COLLECTION, data) as Promise<IButtonType>
    },
    resourceRetrieve: function (id) {
        return RestService.resourceRetrieve(COLLECTION, id) as Promise<IButtonType>
    },
    resourceDelete: function (id) {
        return RestService.resourceDelete(COLLECTION, id)
    },
    resourceUpdate: function (id, data) {
        return RestService.resourceUpdate(COLLECTION, id, data) as Promise<IButtonType>
    }
}

export default ButtonTypesService