import React from "react";
import {Form} from "antd";
import IframeWidgetEditor from "../../../../widget/optionEditor/IframeWidgetEditor";
import ICardWidgetIframe from "../../../../../../../model/interface/dataStorage/card/ICardWidgetIframe";
import FieldPicker from "../../../../../../shared/pickers/FieldPicker";
import IContentType from "../../../../../../../model/interface/dataStorage/IContentType";

interface IProps {
    options: ICardWidgetIframe
    contentType: IContentType
}

class IframeCardWidgetEditor extends React.Component<IProps> {

    render() {
        const {options, contentType} = this.props

        return (
            <>
                <IframeWidgetEditor {...this.props} disabledUrl={!!options.field}/>
                <Form.Item name={'field'} label={'Zvolte pole ako zdroj url'}>
                    <FieldPicker serviceClassName={contentType.fullClassName} mode={"tree"} output={'name'}/>
                </Form.Item>
            </>
        )
    }
}

export default IframeCardWidgetEditor