import RestService from "model/service/dataStorage/RestService";
import IRestServiceOptions from "../../interface/api/IRestServiceOptions";
import IRestServiceCollectionResponse from "../../interface/api/IRestServiceCollectionResponse";
import ISource from "../../interface/import/ISource";
import IFormatAction from "../../interface/import/source/sourceMapping/IFormatAction";
import IRestServiceChoiceListResponse from "../../interface/api/IRestServiceChoiceListResponse";

interface IRestSourcesServiceCollectionResponse extends IRestServiceCollectionResponse {
    results: Array<ISource>
}

interface IRestSourcesMappingFormatActionServiceCollectionResponse extends IRestServiceCollectionResponse {
    results: Array<IFormatAction>
}

interface IRestSourcesService {
    collectionList(options?: IRestServiceOptions): Promise<IRestSourcesServiceCollectionResponse>,

    choiceList(presenter: 'name' | 'name-reader' | string, options?: IRestServiceOptions): Promise<IRestServiceChoiceListResponse>

    collectionCreate(data: any): Promise<ISource>,

    resourceRetrieve(id: number | string): Promise<ISource>,

    resourceUpdate(id: number | string, data: any): Promise<ISource>,

    resourceDelete(id: number | string): Promise<void>,

    listMappingFormatAction(): Promise<IRestSourcesMappingFormatActionServiceCollectionResponse>,
}

const SourcesService_COLLECTION = 'import-sources'
const SourcesService_CHOICES = 'import-sources-choices/'
const SourcesService_COLLECTION_MAPPING = 'import-sources-mapping'
const SourcesService_COLLECTION_MAPPING_FORMAT_ACTIONS = SourcesService_COLLECTION_MAPPING + '/format-actions'


const SourcesService: IRestSourcesService = {

    collectionCreate: function (data) {
        return RestService.collectionCreate(SourcesService_COLLECTION, data) as Promise<ISource>
    },
    resourceRetrieve: function (id) {
        return RestService.resourceRetrieve(SourcesService_COLLECTION, id) as Promise<ISource>
    },
    resourceDelete: function (id) {
        return RestService.resourceDelete(SourcesService_COLLECTION, id)
    },
    resourceUpdate: function (id, data) {
        return RestService.resourceUpdate(SourcesService_COLLECTION, id, data) as Promise<ISource>
    },
    collectionList: function (options?) {
        return RestService.collectionList(SourcesService_COLLECTION, options as unknown as IRestServiceOptions) as Promise<IRestSourcesServiceCollectionResponse>
    },
    listMappingFormatAction(): Promise<IRestSourcesMappingFormatActionServiceCollectionResponse> {
        return RestService.collectionList(SourcesService_COLLECTION_MAPPING_FORMAT_ACTIONS) as Promise<IRestSourcesMappingFormatActionServiceCollectionResponse>
    },
    choiceList(presenter, options?: IRestServiceOptions): Promise<IRestServiceChoiceListResponse> {
        return RestService.collectionList(SourcesService_CHOICES + presenter, options) as unknown as Promise<IRestServiceChoiceListResponse>
    }
}

export default SourcesService