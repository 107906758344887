import React, {Key} from 'react';
import PropTypes from 'prop-types'
import AvatarWithDefault from "../../app/security/AvatarWithDefault";
import {AvatarProps} from "antd/lib/avatar/avatar";


interface IProps extends AvatarProps{
    onNameClick: (info: {[name: string]: any}) => void
    suffix?: string
    name: string
    subTitle?: string
    id?: Key,
    type?: string
}

export const AvatarStatus = (props: IProps) => {
    const {name, suffix, subTitle, id, type, src, size, shape, gap, onNameClick} = props
    return (
        <div className="avatar-status d-flex align-items-center dir-ltr">
            {<AvatarWithDefault {...{src, type, size, shape, gap, name}} className={`ant-avatar-${props.type}`} title={props.name}/>}
            <div className="ml-2 mr-2">
                <div>
                    {
                        onNameClick ?
                            <div onClick={() => onNameClick({name, subTitle, src, id})}
                                 className="avatar-status-name clickable">{name}</div>
                            :
                            <div className="avatar-status-name">{name}</div>
                    }
                    <span>{suffix}</span>
                </div>
                <div className="text-muted avatar-status-subtitle">{subTitle}</div>
            </div>
        </div>
    )
}

AvatarStatus.propTypes = {
    name: PropTypes.string,
    src: PropTypes.string,
    type: PropTypes.string,
    onNameClick: PropTypes.func
}


export default AvatarStatus;
