import React, {Component, RefObject} from 'react';
import {Button, FormInstance, Input} from "antd";
import {ActionType} from "../../../model/interface/dataStorage/IAction";
import Utils from "../../../utils";
import IRestServiceChoiceListResponse from "../../../model/interface/api/IRestServiceChoiceListResponse";
import ILabelValue from "../../../model/interface/util/ILabelValue";
import IRestResource from "../../../model/interface/api/IRestResource";
import {LoadingOutlined, SearchOutlined} from "@ant-design/icons";
import ProSelect from "../input/ProSelect";
import ReactDOMServer from "react-dom/server";

interface IProps {
    value?: string | IRestResource | (string | IRestResource)[]
    onChange?: (resource?: string | string[]) => void
    className?: string
    multiple?: boolean
    style?: React.CSSProperties,
    disabled?: boolean
    output?: 'uuid' | 'name' | string,
    defaultType?: ActionType
    placeholder?: string,
    fetchChoices: () => Promise<IRestServiceChoiceListResponse> ,
    showSearch?: boolean,
    clearSearch?: boolean
    disabledWhenLoading: boolean
    selectAll?: boolean
    sort?: 'DESC' | 'ASC',
    noInputGroup?: boolean
}

interface IState {
    formRef: RefObject<FormInstance>,
    loading: boolean,
    choices?: ILabelValue[]
}

class ResourcePicker extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            formRef: React.createRef(),
            loading: false
        }
    }

    static defaultProps = {
        output: "uuid",
        disabledWhenLoading: true,
        sort: "ASC"
    }

    componentDidMount() {
        this.fetch()
    }

    onPickerChoose = (resource: string) => {
        this.updateParent(resource)
    }

    updateParent(resource?: string) {
        const {onChange, output} = this.props
        const {choices} = this.state
        if (onChange) {
            switch (output) {
                case "uuid":
                    return onChange(resource)
                case "name":
                    return onChange(choices?.find(c => c.value === resource)?.label.toString())
            }
        }
    }

    fetch = () => {
        this.setState({loading: true})
        const {fetchChoices, sort} = this.props
        const {choices} = this.state
        choices ? this.setState({loading: false}) : fetchChoices().then(response => {
            let choices = Object.entries(response.results)
                .map(([value, label]) => ({value: Utils.isUuid(value) ? value : Number(value), label}))
            if (sort) {
                choices = choices.sort((a, b) =>
                    (sort === 'ASC' ? (a.label) > (b.label) : (a.label) < (b.label)) ? 1 : -1)
            }
            this.setState({
                loading: false,
                choices
            })
        })
    }

    render() {
        const {loading, choices} = this.state
        const {className, style, disabled, placeholder, multiple, showSearch, clearSearch, disabledWhenLoading, value, noInputGroup, selectAll} = this.props

        const parsedValue = Utils.parseObjectToIdentifier(value, 'uuid')

        const select = <ProSelect
            allowSelectAll={selectAll}
            allowClear
            className={(className || '') + ' flex-grow-1 min-w-0'}
            showSearch
            autoClearSearchValue={clearSearch}
            notFoundContent={''}
            loading={showSearch ? undefined : loading}
            disabled={(disabledWhenLoading && loading) || disabled}
            dropdownMatchSelectWidth={false}
            value={loading ? undefined : parsedValue}
            mode={multiple ? "multiple" : undefined}
            placeholder={placeholder}
            onChange={this.onPickerChoose}
            filterOption={(input, option) => Utils.stringContains(typeof option?.label === 'string' ? option.label : ReactDOMServer.renderToString(option?.children), input)}
            options={choices}
        />

        return noInputGroup ? select : (
            <Input.Group style={style} compact={true} className={'d-flex w-100'}>
                {select}
                {showSearch && <Button disabled={true} icon={loading ? <LoadingOutlined/> : <SearchOutlined/>}/>}
            </Input.Group>
        )
    }
}

export default ResourcePicker
