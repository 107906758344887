import React from "react";
import {Avatar, Badge, Col, Input, Row, Tooltip, Typography} from "antd";
import IReportWidgetProps from "../../../../../model/interface/report/IReportWidgetProps";
import IReportWidgetNotifications from "../../../../../model/interface/report/IReportWidgetNotifications";
import {BellOutlined, FieldTimeOutlined, LeftOutlined, RightOutlined} from "@ant-design/icons";
import {connect} from "react-redux";
import INotification, {STATES} from "../../../../../model/interface/ui/INotification";
import {changeState} from "../../../../../redux/actions/Notifications";
import {getNotificationIcon} from "../../../message/notification/NotificationList";
import Button from "../../../../shared/button/Button";
import {MomentBuilder} from "../../../../../utils/MomentBuilder";
import UserAvatar from "../../../security/UserAvatar";
import {IAppState} from "../../../../../redux/store";
import NotificationActions from "../../../message/notification/NotificationActions";

interface IProps extends IReportWidgetProps<IReportWidgetNotifications> {
    notifications: INotification[]
}

interface IState {
    page: number
}

class NotificationsWidget extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props)
        this.state = {
            page: 1
        }
    }

    componentDidMount() {
        const {options, functions, id} = this.props
        const {hideOnEmpty} = options
        const notifications = this.getNotifications()
        const total = notifications.length
        if (hideOnEmpty && total > 0) {
            functions.onChildHidden?.(id, true)
        }
    }

    getNotifications() {
        const {options, notifications} = this.props

        return notifications.filter(n => n.state !== STATES.STATE_DELETED && (options.showRead || n.state === STATES.STATE_NEW));
    }

    switch = (next: boolean = true) => {
        let nextPage = this.state.page + (next ? 1 : -1)
        this.setState({page: nextPage > this.getNotifications().length ? 1 : Math.max(0, nextPage)})
    }


    render() {
        const {options} = this.props
        const {page} = this.state
        const {showLast, showTotal, showUnread, showSenderIcon, hideOnEmpty} = options
        const notifications = this.getNotifications()
        const notification = notifications[page - 1]
        const total = notifications.length
        if (hideOnEmpty && total === 0) {
            return <></>
        }
        let {bordered} = options
        if(typeof bordered == 'undefined') {
            bordered = true
        }

        return (<div className={bordered && 'rounded border overflow-hidden p-3 bg-theme'}>
                <Row align={"middle"} className={'w-100 mb-2'} justify={'space-between'}>
                    <Col>
                        <Typography.Title level={4} className={'m-0'}>{'Notifikace'}</Typography.Title>
                    </Col>
                    <Col>
                        <Row gutter={[24, 16]} align={"middle"}>
                            {showUnread && (
                                <Col className={'mt-2'}>
                                    <Tooltip title={'Nepřečtené'} placement={"topLeft"}>
                                        <Badge count={notifications.filter(n => n.state === STATES.STATE_NEW).length}
                                               showZero>
                                            <BellOutlined className="nav-icon mx-auto" style={{fontSize: 20}}/>
                                        </Badge>
                                    </Tooltip>
                                </Col>
                            )}
                            {showTotal && (
                                <Col className={'mt-2'}>
                                    <Tooltip title={'Celkem'} placement={"topLeft"}>
                                        <Badge
                                            count={notifications.filter(n => n.state !== STATES.STATE_DELETED).length}
                                            style={{backgroundColor: '#72849a'}} showZero>
                                            <FieldTimeOutlined className="nav-icon mx-auto" style={{fontSize: 20}}/>
                                        </Badge>
                                    </Tooltip>
                                </Col>
                            )}
                            {showLast && <Col>
                                <Row align={"middle"} wrap={false}>
                                    <Typography.Text className={"text-muted text-nowrap mr-2"}>
                                        {total === 0 ? 0 : page} z {total}
                                    </Typography.Text>
                                    <Input.Group compact={true}>
                                        <Button icon={<LeftOutlined/>} onClick={() => this.switch(false)}
                                                disabled={page === 1}
                                                size={"small"}/>
                                        <Button icon={<RightOutlined/>} onClick={() => this.switch()}
                                                disabled={page === total}
                                                size={"small"}/>
                                    </Input.Group>
                                </Row>
                            </Col>}
                        </Row>
                    </Col>
                </Row>
                {showLast && notification && (
                    <Row gutter={[6, 6]} wrap={false}>
                        <Col className={'mr-2'}>
                            {notification.sender && (showSenderIcon || showSenderIcon === undefined) ? (
                                <UserAvatar className={'m-0'} size={72} user={notification.sender}/>
                            ) : <Avatar size={72} className={`ant-avatar-alert`} icon={getNotificationIcon("alert")}/>}
                        </Col>
                        <Col>
                            <Typography.Text strong={true}>{notification.text}</Typography.Text>
                            <Typography.Text className={'d-block'} type={'secondary'}>

                                {MomentBuilder.build(notification.scheduledAt).format('LLL')}
                                {notification.sender?.employees[0] ? ' - ' + notification.sender.employees[0].fullName : ''}
                            </Typography.Text>
                            <div>{notification.message.body}</div>
                            <Row gutter={[6, 6]} className={'mt-2'}>
                                <NotificationActions size={"small"} notification={notification}/>
                            </Row>
                        </Col>
                    </Row>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state: IAppState) => {
    return {
        notifications: state.notifications.notifications
    }
}

const mapDispatchToProps =
    {
        changeState
    }

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsWidget);