import React from "react";
import ICardWidgetProps from "../../../../../../model/interface/dataStorage/card/ICardWidgetProps";
import IframeWidget from "../../../widget/IframeWidget";
import ICardWidgetIframe from "../../../../../../model/interface/dataStorage/card/ICardWidgetIframe";
import {Col, Row} from "antd";

interface IProps extends ICardWidgetProps<ICardWidgetIframe> {
}

class CardWidgetIframe extends React.Component<IProps> {
    render() {
        const {functions, options} = this.props
        const url = options.field ? functions.getValue(options.field) : options.url
        if (Array.isArray(url)){
            return <Row gutter={[12, 12]}>
                { url.map(item => (
                    <Col span={24} key={item}>
                        <IframeWidget {...this.props} options={{...options, url: item}}/>
                    </Col>
                ))}
            </Row>
        }

        return (
            <IframeWidget {...this.props} options={{...options, url}}/>
        );
    }
}


export default CardWidgetIframe

