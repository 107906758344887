import React from "react"
import LocaleProvider from "../../../i18n/LocaleProvider";
import {DatePicker} from "antd";
import {IFilterInput} from "../IFilterBase";
import {API_FILTER_TYPE} from "../../../model/constants/ApiConstant";
import {Moment} from "moment";
import moment from "moment/moment";
import {PickerMode} from 'rc-picker/lib/interface';


interface IState {

}

const {RangePicker} = DatePicker;

interface IProps extends IFilterInput {

}

class Date extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {}
    }

    render() {
        const {field, item, onChange, selected} = this.props

        const onChangeLocal = (dates: [Moment | null, Moment | null]) => {
            console.log(dates)
            onChange(dates ? ([
                ...(dates[0] ? [{
                    type: API_FILTER_TYPE.GREATER_OR_EQUAL,
                    value: formatValue(dates[0], picker, true),
                    name: field.name
                }] : [])
                ,
                ...(dates[1] ? [{
                    type: API_FILTER_TYPE.LESSER_OR_EQUAL,
                    value: formatValue(dates[1], picker, false),
                    name: field.name
                }] : [])
            ]) : [])
        }

        const picker = (((item.options && item.options.picker) || field.options.datePicker) || "date") as PickerMode
        const formatValue = (value: Moment, picker: PickerMode, start: boolean): string => {
            let v = value.clone()
            let unitOfTime: moment.unitOfTime.StartOf | undefined
            switch (picker) {
                case("date"):
                    unitOfTime = "day";
                    break;
                case 'time':
                    unitOfTime = undefined;
                    break;
                default:
                    unitOfTime = picker
            }
            if (unitOfTime) {
                if (start) {
                    v.startOf(unitOfTime).hour(0).minute(0).second(0)
                } else {
                    v.endOf(unitOfTime).hour(23).minute(59).second(59)
                }
            }
            return v.format('YYYY-MM-DD HH:mm:ss')
        }

        const defaultDates = [
            selected.find(key => key.type === API_FILTER_TYPE.GREATER_OR_EQUAL)?.value,
            selected.find(key => key.type === API_FILTER_TYPE.LESSER_OR_EQUAL)?.value
        ]

        return (
            <>
                <RangePicker
                    picker={picker}
                    locale={LocaleProvider.getPicker()}
                    format={(item.options && item.options.dateFormat) || field.options.dateFormat}
                    className={'w-100'}
                    allowEmpty={[true, true]}
                    placeholder={['Od', 'Do']}
                    value={defaultDates.map(date => date ? moment(date.toString()) : undefined) as any}
                    onChange={dates => onChangeLocal([dates?.[0] || null, dates?.[1] || null])}
                />
            </>
        )
    }
}

export default Date