import React from "react";

interface IProps {
    value: string|null
}
class ColorSquare extends React.Component<IProps> {
    render() {
        const color = this.props.value
        return (
            color ? <span style={{backgroundColor: color || '', width: 18, height: 18, display: 'block', borderRadius: 4}} className={"mr-1"} /> : <></>
        )
    }
}

export default ColorSquare