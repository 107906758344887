import React from 'react'
import {
    Button,
    Card as CardElement,
    Col,
    DatePicker,
    Divider,
    Popover,
    Row,
    Table,
    Tag,
    Tooltip,
    Typography
} from 'antd';
import {connect, RootStateOrAny} from "react-redux";

import _ from "underscore"
import IBaseProps from "../../../../model/interface/IBaseProps";
import IContentType from "../../../../model/interface/dataStorage/IContentType";
import IAction from "../../../../model/interface/dataStorage/IAction";
import DataStorageRevisionHistoryService from "../../../../model/service/dataStorage/DataStorageRevisionHistoryService";
import selectors from "../../../../redux/selectors";
import {IActionResult} from "../../../../model/service/dataStorage/ActionsService";
import {RollbackOutlined, SwapRightOutlined} from "@ant-design/icons";
import IRoute from "../../../../model/interface/dataStorage/IRoute";
import {API_FILTER_TYPE} from "../../../../model/constants/ApiConstant";
import {RouteParametersFromUrl} from "../../../../redux/selectors/Routes";
import {MomentBuilder} from "../../../../utils/MomentBuilder";
import IRevisionHistoryResource
    from "../../../../model/interface/dataStorage/revision-history/IRevisionHistoryResource";
import UserEmployeeLabel from "../../security/UserEmployeeLabel";
import {FilterValue, SorterResult, TablePaginationConfig} from "antd/lib/table/interface";
import IEmployee from "../../../../model/interface/company/IEmployee";
import AntdTableOptionsToRestOptionsService from "../../../../model/service/api/AntdTableOptionsToRestOptionsService";
import FilterDropDown from "../../../../utils/Filter/FilterDropDown";
import DataStorageHelper from "../../../../utils/DataStorageHelper";
import IField, {FIELD_MODE_RELATION, RELATION_FIELD_TYPE} from "../../../../model/interface/dataStorage/IField";
import ILabelValue from "../../../../model/interface/util/ILabelValue";
import UsersService from "../../../../model/service/security/UsersService";
import Utils from "../../../../utils";
import {Select} from 'antd/es';
import LocaleProvider from "../../../../i18n/LocaleProvider";
import {IRoutesRouterDisplay} from "../../router/RoutesRouter";

const {RangePicker} = DatePicker;

interface IState {
    loading: boolean
    data: IRevisionHistoryResource[]
    recordId: number
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null> | null,
    sorter: SorterResult<IEmployee> | SorterResult<IEmployee>[] | null,
    userChoices?: ILabelValue<string>[]
}

interface IProps extends IBaseProps, IRoutesRouterDisplay {
    findContentType: (uuid: string) => IContentType
    findRoute: (uuid: string) => IRoute
    extractRouteParametersFromUrl: (url: string) => RouteParametersFromUrl,
    standAlone?: boolean
    action: IAction
    onActionFinish?: (result?: IActionResult) => void
}

const MAX_VALUE_LENGTH = 50

class RevisionHistory extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            loading: false,
            pagination: {pageSize: 10, hideOnSinglePage: true, showSizeChanger: true},
            filters: null,
            sorter: [{field: 'createdAt', order: 'descend'}],
        } as IState;
    }

    static defaultProps = {
        standAlone: true
    }

    getContentType() {
        const {findContentType, action} = this.props
        return findContentType(action.contentType);
    }

    componentDidMount() {
        this.load().then()
    }

    load() {
        this.setState({loading: true})
        const service = new DataStorageRevisionHistoryService(this.getContentType())
        const {filters, pagination, sorter} = this.state
        const options = AntdTableOptionsToRestOptionsService.parse(pagination, filters, sorter, {'changes': API_FILTER_TYPE.LIKE})
        return service.collectionList({
            ...options,
            filters: {
                ...options.filters,
                resource: {
                    field: 'originalId',
                    value: this.getId(),
                    type: API_FILTER_TYPE.EQUAL
                }
            },
            cache: false
        }).then(({results, count}) => {
            this.setState(state => ({
                data: results, loading: false, pagination: {...state.pagination, total: count}
            }))
            UsersService.choiceList('employeeId')
                .then(({results}) => {
                    this.setState({
                        userChoices: Object.entries(results).map(([uuid, name]) => ({
                            value: uuid,
                            label: name
                        }))
                    })

                })
        })
    }

    getId(): number {
        const {extractRouteParametersFromUrl, routeParams} = this.props
        const contentType = this.getContentType()
        const routeInfo = routeParams || extractRouteParametersFromUrl(window.location.href)
        return routeInfo?.parameters.hasOwnProperty(contentType.name) ? routeInfo.parameters[contentType.name] : null
    }

    reload(): Promise<void> {
        return this.load().then(() => this.setState({loading: false}))
    }

    getField(name: string) {
        return _.findWhere(this.getContentType().fields, {name});
    }

    onChange = (pagination: any, filters: any, sorter: any) => {
        this.setState({pagination, filters, sorter}, this.load)
    }

    render() {
        const {loading, data, pagination, userChoices} = this.state
        const {standAlone, action} = this.props
        const Container = standAlone ? CardElement : 'div'

        return (
            <Container>
                {standAlone && (
                    <Row justify={"space-between"}>
                        <Typography.Title level={3}>{action.label}</Typography.Title>
                        <Button icon={<RollbackOutlined/>} onClick={() => this.onBack()}>
                            Zpět
                        </Button>
                    </Row>
                )}
                <Table loading={loading} dataSource={data} onChange={this.onChange} rowKey={row => row.uuid!}
                       pagination={pagination} columns={[
                    {
                        title: 'Datum a čas',
                        dataIndex: 'createdAt',
                        render: (_, r) => MomentBuilder.build(r.createdAt)?.format('Do MMMM YYYY, HH:mm:ss'),
                        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => {
                            return (
                                <FilterDropDown onReset={clearFilters} onConfirm={() => confirm()}>
                                    <RangePicker picker={'date'} locale={LocaleProvider.getPicker()}
                                                 className={'d-block'} value={selectedKeys as any}
                                                 allowEmpty={[true, true]} placeholder={['Od', 'Do']}
                                                 onChange={(dates) => setSelectedKeys([
                                                     ...(dates?.[0] ? [dates[0].startOf('day')] : []),
                                                     ...(dates?.[1] ? [dates[1].endOf('day')] : [])
                                                 ] as any)}/>
                                </FilterDropDown>
                            )
                        },
                        sorter: true,
                        defaultSortOrder: 'descend'
                    },
                    {
                        title: 'Autor',
                        dataIndex: 'userId',
                        render: (_, r) => r.user ? <Row align={'middle'}>
                            <UserEmployeeLabel user={r.user}/>
                            <div className={'d-inline text-muted'}>{r.user.employees[0] ? r.user.username : ''}</div>
                                </Row> : <Tag>Systém</Tag>,
                        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => {
                            return (
                                <FilterDropDown onReset={clearFilters} onConfirm={() => confirm()}>
                                    <Select className={'w-100'} value={selectedKeys} optionFilterProp="children"
                                            filterOption={(input, option) => Utils.stringContains(option?.label, input)}
                                            mode={"multiple"} loading={!userChoices} showSearch
                                            onChange={value => setSelectedKeys(value as any)}
                                            options={userChoices} dropdownMatchSelectWidth={false}/>
                                </FilterDropDown>
                            )
                        },
                        sorter: true,
                    },
                    {
                        title: 'Změny',
                        dataIndex: 'changes',
                        render: (_, r) => (
                            <div>
                                {r.changes.map((change, index) => {
                                    const field = this.getField(change)
                                    if (!field) {
                                        return undefined
                                    }
                                    return (
                                        <Row gutter={[8, 8]} wrap={false}
                                            className={'w-100 p-1' + (index + 1 !== r.changes.length ? ' border-bottom mb-1' : '')}>
                                            <Col span={8} className={'d-flex justify-content-start'}>
                                                <strong>{field.label || field.name}</strong>
                                            </Col>
                                            <Col>
                                                <SwapRightOutlined/>
                                            </Col>
                                            <Col className={'flex-grow-1'}>
                                                {this.buildValue(r, field)}
                                            </Col>
                                        </Row>
                                    )
                                })}
                            </div>
                        )
                    }
                ]}/>
            </Container>
        )
    }

    buildValue(r: IRevisionHistoryResource, field: IField) {
        const {history} = this.props
        let value = r[field.name]
        if (field.mode === FIELD_MODE_RELATION && value) {
            if (Array.isArray(value)) {
                return value.map((item, index) => {
                    return <div className={'d-inline-block'} key={index}>
                        {index > 0 && (
                            <Divider type={"vertical"}/>
                        )}
                        {typeof item === "number" ? this.notFoundRelationText(item) : DataStorageHelper.buildFieldValue({
                            ...field,
                            type: RELATION_FIELD_TYPE.ONE_TO_ONE
                        }, {[field.name]: item}, history)}
                    </div>
                })
            } else if (typeof value === "number") {
                return this.notFoundRelationText(value)
            }
            return DataStorageHelper.buildFieldValue(field, r, history)

        } else if (typeof value === 'string' && value.length > MAX_VALUE_LENGTH) {
            return <div className={'overflow-hidden'}>
                {DataStorageHelper
                    .buildFieldValue(field, {[field.name]: value.slice(0, MAX_VALUE_LENGTH)}, history)}
                <Popover trigger="click" content={DataStorageHelper.buildFieldValue(field, r, history)}
                         title={field.label || field.name}>
                    <div className={'d-inline-block text-link'}>...více</div>
                </Popover>
            </div>
        }
        return DataStorageHelper.buildFieldValue(field, r, history)
    }

    notFoundRelationText(id: number) {
        return <Tooltip title={"ID: " + id}>
            <div className={'text-muted d-inline-block'}>Obsah již není k dispozici</div>
        </Tooltip>
    }

    onBack() {
        const {history, action, findRoute} = this.props
        if (action.afterRedirect) {
            return history.push(findRoute(action.afterRedirect).url);
        }
        history.go(-1);
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    return {
        findContentType: (uuid: string) => selectors.contentTypes.findOneBy(state, 'uuid', uuid),
        findRoute: (uuid: string) => selectors.routes.findOneBy(state, 'uuid', uuid),
        extractRouteParametersFromUrl: (url: string) => selectors.routes.extractRouteParametersFromUrl(state, url)
    }
}

export default connect(mapStateToProps)(RevisionHistory)