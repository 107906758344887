import {Input, InputNumber} from 'antd';
import React from "react";
import IFieldOptions from "../../../../../../model/interface/form/elementOptions/IFieldOptions";
import PhoneNumberCountryCodePicker from "../../../../../shared/pickers/PhoneNumberCountryCodePicker";

interface IProps {
    value?: string,
    onChange?: (value?: string) => void
    options: IFieldOptions
}

interface IState {
    value?: string,
    code?: string,
    number?: string | null
}

class FormFieldPhoneNumber extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            value: props.value,
            code: this.parseValue(props.value, true) || props.options.phoneNumberDefaultCountryCode,
            number: this.parseValue(props.value)
        }
    }

    parseValue(value?: string, code: boolean = false) {
        if (value) {
            return value.split(' ')[code ? 0 : 1]
        }
        return value
    }

    handleChange = () => {
        const {phoneNumberDisabledCountryCode} = this.props.options
        const {number} = this.state
        this.setState(state => ({
                value: (phoneNumberDisabledCountryCode ? '' : state.code || '') + ' ' + state.number
            }),
            () => {
                this.props.onChange?.(number ? this.state.value : undefined)
            })
    }

    setCode(value?: string | string[]) {
        this.setState({code: value as string}, this.handleChange)
    }

    setNumber(value?: string | null) {
        this.setState({number: value}, this.handleChange)
    }

    render() {
        const {
            placeholder,
            disabled,
            phoneNumberDisabledCountryCode,
            phoneNumberDefaultCountryCode,
            phoneNumberAllowedCountryCodes
        } = this.props.options
        const {value} = this.props
        const number = this.parseValue(value)
        const code = number ? this.parseValue(value, true) || phoneNumberDefaultCountryCode : this.state.code

        return (
            <Input.Group compact className={'d-flex'}>
                {!phoneNumberDisabledCountryCode && (
                    <PhoneNumberCountryCodePicker allow={phoneNumberAllowedCountryCodes} value={code} allowClear={true}
                                                  onChange={value => this.setCode(value)}/>
                )}
                <InputNumber precision={0}
                             className={'flex-grow-1'}
                             placeholder={placeholder}
                             value={number}
                             disabled={disabled}
                             onChange={value => this.setNumber(value)}
                             maxLength={11}
                             formatter={value => {
                                 return value ? value.replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : ''
                             }}
                             parser={value => {
                                 return value ? value.replace(/\s/g, '') : ''
                             }}/>
            </Input.Group>
        )
    }
}

export default FormFieldPhoneNumber