import React, {RefObject} from "react"
import {Button, Col, Form, FormInstance, message, Modal, Row} from "antd";
import Text from "antd/es/typography/Text";
import {CloseCircleOutlined, HomeOutlined} from "@ant-design/icons";
import Title from "antd/es/typography/Title";
import Paragraph from "antd/es/typography/Paragraph";
import TextArea from "antd/es/input/TextArea";
import LogsService from "../../../model/service/log/LogsService";

interface IState {
    reportSent: boolean
    formRef: RefObject<FormInstance>,
    sendingReport: boolean
}

interface IProps {
    error: any
}

class ErrorModal extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            reportSent: false,
            formRef: React.createRef(),
            sendingReport: false
        }
    }

    report = () => {
        this.setState({sendingReport: true})
        this.state.formRef.current?.validateFields().then(values => {
            LogsService.collectionCreate({...values, type: 'error', data: this.props.error, severity: 600})
                .then(() => {
                    this.setState({reportSent: true, sendingReport: false})
                    message.success('Děkujeme, chyba byla úspěšně nahlášena').then()
                })
                .catch(() =>  message.error('Tuto chybu nebylo možné nahlásit, ' +
                        'server se potýká s problémy a v tuto chvíli nemůže zpracovat váš požadavek'))
        })
    }

    render() {
        const {reportSent, formRef, sendingReport} = this.state
        const {error} = this.props

        return (
            <>
                <Modal className={'dir-ltr'}
                       title={
                           <Title level={4} className={'m-0'}>
                               <Text type={"danger"} className={'pr-2 font-size-xl'}>
                                   <CloseCircleOutlined className={''}/>
                               </Text>
                               {'Komunikace se serverem selhala'}
                           </Title>
                       }
                       okText={'Ulozit'}
                       width={700}
                       visible={true}
                       closable={false}
                       destroyOnClose={true}
                       footer={[
                           <Row justify={"space-between"}>
                               <a href={'/app'}>
                                   <Button type="link" size={"small"} icon={<HomeOutlined/>}>
                                       Přejít na úvodní stránku
                                   </Button>
                               </a>
                               <Col>
                                   <Button disabled={reportSent} loading={sendingReport} danger onClick={this.report}>
                                       Nahlásit chybu
                                   </Button>
                               </Col>
                           </Row>

                       ]}
                >
                    <Paragraph>
                        Váš požadavek jsme bohužel nedokázali vyřídit z důvodu neplatné odpovědi serveru.
                    </Paragraph>
                    <Paragraph>
                        Zkuste svůj požadavek opakovat později nebo můžete nahlásit chybu.
                    </Paragraph>
                    <Form layout={"vertical"} ref={formRef}>
                        <Form.Item name={'subject'} label={'Popište prosím chybu, se kterou jste se setkali'}>
                            <TextArea placeholder={'...'} rows={4}/>
                        </Form.Item>
                        <Form.Item initialValue={JSON.stringify(error)} name={'protocol'} label={'Protokol chyby'}
                                   rules={[{required: true}]}>
                            <TextArea disabled rows={5}/>
                        </Form.Item>
                    </Form>
                </Modal>
            </>
        )
    }
}

export default ErrorModal