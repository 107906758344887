import React from "react"
import {Form, Select, Slider} from "antd";
import {getNestedFieldOptions} from "../unit/ViewUnitProperties";
import Title from "antd/es/typography/Title";
import IContentType from "../../../../../model/interface/dataStorage/IContentType";

interface IState {

}

interface IProps {
    contentType: IContentType
}

class ViewTimelineProperties extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {}
    }

    render() {
        const {contentType} = this.props
        const fields = contentType.fields.sort((a, b) => {
            return (a.label || a.name) > (b.label || b.name) ? 1 : -1
        })

        return (
            <>
                <Title level={3}>Vlastnosti časové osy</Title>
                <Form.Item
                    label={"Vlastnost osy"}
                    name={getNestedFieldOptions('timelineField')}
                    help={'Zde je povoleno pouze pole datum a čas'}
                    rules={[{required: true, message: 'Vyplňte vlastnost'}]}
                >
                    <Select defaultActiveFirstOption={true}>
                        {fields.filter(field => field.type.includes('DateTimeType')).map(field => (
                            <Select.Option key={field.uuid} value={field.uuid}>
                                {field.name} [{field.type}]
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    initialValue={5}
                    label={"Počet bodů"}
                    name={getNestedFieldOptions('timelineLength')}>
                    <Slider min={5} max={50}/>
                </Form.Item>
                <Form.Item
                    label={"Karta pro zobrazení bodu"}
                    name={getNestedFieldOptions('timelineCards')}
                    rules={[{required: true, message: 'Vyplňte kartu'}]}
                >
                    <Select defaultActiveFirstOption={true} mode={'multiple'}>
                        {contentType.cards.map(card => (
                            <Select.Option key={card.uuid} value={card.uuid}>
                                {card.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </>
        )
    }
}

export default ViewTimelineProperties