import React, {Suspense} from "react";
import {Redirect, Route, RouteComponentProps, Switch} from "react-router-dom";
import Loading from 'components/shared/Loading';
import {AUTH_PREFIX_PATH} from 'configs/AppConfig'
import Login from "./Login";
import PasswordReset from "./PasswordReset";
import ISettings from "../../model/interface/ui/ISettings";
import {DirectionType} from "antd/es/config-provider";
import InvalidLicense from "./InvalidLicense";

interface IProps extends RouteComponentProps<any> {
    settings: ISettings,
    direction: DirectionType
}

export const AuthViews = (viewProps: IProps) => {
    const {settings} = viewProps
    return (
        <Suspense fallback={<Loading cover="page"/>}>
            <Switch>
                {settings && !settings.licensed && (
                    <Route path={`${AUTH_PREFIX_PATH}/*`}
                           render={(props) => <InvalidLicense {...props} {...viewProps} />}/>
                )}
                <Route path={`${AUTH_PREFIX_PATH}/login`} render={(props) => <Login {...props} {...viewProps} />}/>
                <Route path={`${AUTH_PREFIX_PATH}/password-reset/:hash`}
                       render={(props) => <PasswordReset {...props} {...viewProps} />}/>
                <Redirect from={`${AUTH_PREFIX_PATH}`}
                          to={{pathname: `${AUTH_PREFIX_PATH}/login`, state: {from: viewProps.location.state?.from}}}/>
            </Switch>
        </Suspense>
    )
}

export default AuthViews;

