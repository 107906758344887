import React, {RefObject} from "react";
import {Col, FormInstance, message, Modal, Row} from "antd";
import IPlan, {PlanState} from "../../../../../model/interface/company/workload/IPlan";
import PlansService from "../../../../../model/service/company/workload/PlansService";
import ILabelValue from "../../../../../model/interface/util/ILabelValue";
import RequestForm from "./RequestForm";
import IType from "../../../../../model/interface/company/workload/IType";
import Button from "../../../../shared/button/Button";
import {CheckOutlined, SaveOutlined, SendOutlined} from "@ant-design/icons";

interface IProps {
    resource: IPlan,
    choices?: ILabelValue[]
    employee?: string,
    onSuccess?: (plan: IPlan) => void
    onCancel?: () => void
    types?: IType[]
}

interface IState {
    saving?: PlanState,
    preview?: IPlan
    valid?: boolean
}

class RequestFormModal extends React.Component<IProps, IState> {

    formRef: RefObject<FormInstance> = React.createRef()

    constructor(props: IProps) {
        super(props)
        this.state = {
            valid: false
        }
    }

    submit = (state: PlanState) => {
        const {resource, onSuccess} = this.props
        this.formRef.current?.validateFields().then((values) => {
            this.setState({saving: state})
            const promise: Promise<IPlan> = resource.id ?
                PlansService.resourceUpdate(resource.id, {
                    ...resource, ...values,
                    state: state === "new" ? state : resource.state
                }) :
                PlansService.collectionCreate({...resource, ...values, state})
            promise.then((plan) => {
                if (plan.state === 'new') {
                    message.success('Vaše žádost byla úspěšně odeslána ke schválení').then()
                } else if (plan.state === 'approved') {
                    message.success('Vaše žádost byla úspěšně zadána a schválena').then()
                } else {
                    message.success('Vaše žádost byla úspěšně uložena').then()
                }
                onSuccess?.(plan)
            }).finally(() => this.setState({saving: undefined}))
        })
    }

    onChange = (_: any) => {
        this.formRef.current?.validateFields().then(() => {
            this.setState({valid: true})
        }).catch(() => this.setState({valid: false}))
    }

    setPreview = (plan: IPlan) => {
        this.setState({preview: plan})
    }

    render() {
        const {saving, preview, valid} = this.state
        const {employee, choices, resource, onCancel, types} = this.props
        const currentPlan = preview || (resource.id ? resource : undefined)

        return (
            <Modal destroyOnClose={true} width={800} visible={!!resource} closable={true} onCancel={() => onCancel?.()}
                   footer={[
                       <Row key="footer" justify={"space-between"}>
                           <Button disabled={!valid || !currentPlan} type="primary" loading={saving === 'planned'}
                                   onClick={() => this.submit('planned')} icon={<SaveOutlined/>}>
                               {'Uložit rozpracované'}
                           </Button>
                           <Row gutter={[8, 8]}>
                               <Col>
                                   <Button disabled={!valid || !currentPlan || !currentPlan._permissions?.['ask']}
                                           type="info"
                                           loading={saving === 'new'} onClick={() => this.submit('new')}
                                           icon={<SendOutlined/>}>
                                       {'Odeslat ke schválení'}
                                   </Button>
                               </Col>
                               {currentPlan?._permissions?.['approve'] &&
                                   <Col>
                                       <Button type="special" loading={saving === 'approved'}
                                               onClick={() => this.submit('approved')}
                                               icon={<CheckOutlined/>} disabled={!valid}>
                                           {'Ihned schválit'}
                                       </Button>
                                   </Col>
                               }
                           </Row>
                       </Row>
                   ]}>
                <RequestForm
                    types={types}
                    resource={resource}
                    formRef={this.formRef}
                    choices={choices}
                    employee={employee}
                    onLoadPreview={this.setPreview}
                    onChange={this.onChange}
                />
            </Modal>
        )
    }
}

export default RequestFormModal