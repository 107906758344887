import RestService from "model/service/dataStorage/RestService";
import ICommand from "../../interface/symfony-console/ICommand";
import IRestServiceCollectionResponse from "../../interface/api/IRestServiceCollectionResponse";
import IInput from "../../interface/symfony-console/IInput";
import IResult from "../../interface/symfony-console/IResult";


interface IRestCommandService {
    run(name: string, options?: IInput, argumentInputs?: IInput): Promise<IResult>,

    list(): Promise<IRestServiceCollectionResponse<ICommand>>,

    groupByNamespace(commands: ICommand[]):{ name: string, commands: ICommand[] }[]
}

const CommandService_RUN = 'symfony-command-run'
const CommandService_LIST = 'symfony-command-list'

const CommandService: IRestCommandService = {
    run: function (name, options, argumentInputs) {
        return RestService.resourceUpdate(CommandService_RUN, name, {
            options,
            arguments: argumentInputs
        }) as unknown as Promise<IResult>
    },
    list: function () {
        return RestService.collectionList(CommandService_LIST, {cache: false}) as Promise<IRestServiceCollectionResponse<ICommand>>
    },
    groupByNamespace: (commands: ICommand[]) => {
        let namespaces: { name: string, commands: ICommand[] }[] = []
        let indexedNamespaces: string[] = []
        commands.forEach(c => {
            const index = indexedNamespaces.indexOf(c.namespace)
            if (index > -1) {
                namespaces[index].commands.push(c)
            } else {
                indexedNamespaces.push(c.namespace)
                namespaces.push({name: c.namespace, commands: [c]})
            }
        })

        return namespaces
    }
}

export default CommandService