import React from "react";
import {Button, Col, Descriptions, Modal, Row, Spin} from "antd";
import {DownloadOutlined} from "@ant-design/icons";
import IFile from "../../../../model/interface/file/IFile";
import FilesService from "../../../../model/service/file/FilesService";
import {humanFileSize} from "../../../app/file-manager/utils/FileSize";
import moment from "moment";
import FileActions from "../../../app/file-manager/FileViews/FileActions";
import {extensionToIcon} from "../../../app/file-manager/utils/Extension";
import ReactDOM from "react-dom";


interface IProps {
    fileUuid: string,
    fileName: string
}

interface IState {
    loading: boolean,
    visible: boolean,
    file?: IFile,
    downloading?: boolean
}

class WysiwygInlineFileInfo extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props)
        this.state = {
            loading: false,
            visible: false
        }
    }

    componentDidMount() {
        // DO NOT DELETE !!!
        // inside quill editor it somehow needs delay to display properly
        setTimeout(() => this.forceUpdate(), 1)
    }

    static replaceInlineFile(value: string) {
       this.findIdentifiers(value).forEach(match => {
            const uuid = match.replace('#inline-file-', '')
            const targets = document.querySelectorAll("[href='" + match + "']")
            targets.forEach(target => {
                target.firstChild?.nodeName === '#text'
                && ReactDOM.render(<WysiwygInlineFileInfo fileUuid={uuid} fileName={target.innerHTML}/>, target)
            })
        })
    }

    static rollbackUponUpdate(value: string) {
        const div = document.createElement('div');
        div.innerHTML = value;
        this.findIdentifiers(value).forEach(match => {
            const targets = div.querySelectorAll("[href='" + match + "']")
            targets.forEach(target => {
              target.innerHTML = target.textContent || ''
            })
        })
        return div.innerHTML
    }

    private static findIdentifiers(value: string) {
        return value ? Array.from(value.matchAll(/#inline-file-[0-9a-zA-Z-]+/g), m => m[0]) : [];
    }

    static buildIdentifier(file: IFile) {
        return '#inline-file-' + file.uuid
    }

    load() {
        const {fileUuid} = this.props
        this.setState({loading: true})
        FilesService.resourceRetrieve(fileUuid, {depth: 5}).then((file) => {
            this.setState({file, loading: false})
        })
    }

    open = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.stopPropagation()
        e.preventDefault()
        this.setState(state => ({visible: !state.visible}), this.load)
    }

    download = () => {
        const {file} = this.state
        this.setState({downloading: true})
        file && FileActions.download(file).then(() => {
            this.setState({downloading: false})
        })
    }

    render() {
        const {fileName} = this.props
        const {file, loading, visible, downloading} = this.state
        const Icon = () => extensionToIcon(/(?:\.([^.]+))?$/.exec(fileName)?.[1] || '', 12)
        return (
            <div className={'d-inline-block'} onClick={this.open}>
                <span style={{whiteSpace: "nowrap"}}>
                    <Icon/>{' ' + fileName}
                </span>
                <Modal title={file?.fullName || file?.name || fileName} visible={visible} onCancel={this.open} footer={[
                    <Row justify={'space-between'} key={'footer'}>
                        <Col>
                            <Button disabled={!file} onClick={this.download} type={"link"} icon={<DownloadOutlined/>}
                                    loading={downloading}>
                                Stahnout
                            </Button>
                        </Col>
                        <Col>
                            <Button key="submit" type="primary" onClick={this.open}>
                                Ok
                            </Button>
                        </Col>
                    </Row>

                ]}>
                    <Spin spinning={loading}>
                        {file && (
                            <div>
                                <Descriptions size={'middle'} title={false} bordered
                                              column={{xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1}}>
                                    <Descriptions.Item label="Typ">
                                        {file.currentVersion.extension}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Velikost">
                                        {humanFileSize(file.currentVersion.size)}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Datum nahrání">
                                        {moment(file.currentVersion.createdAt.raw).format('LLL')}
                                    </Descriptions.Item>
                                    {file.owner && (
                                        <Descriptions.Item label="Autor">
                                            {file.owner.employees[0]?.fullName || file.owner.username}
                                        </Descriptions.Item>
                                    )}
                                </Descriptions>
                            </div>
                        )}
                    </Spin>
                </Modal>
            </div>
        )
    }
}

export default WysiwygInlineFileInfo