import React, {RefObject} from "react";
import {Button, Form, FormInstance, Input, Result, Row} from "antd";
import {MailOutlined, ReloadOutlined, SendOutlined} from "@ant-design/icons";
import SignUpService from "../../model/service/security/SignUpService";

interface IProps {
}

interface IState {
    sent: boolean,
    loading: boolean,
    error: string,
    formRef: RefObject<FormInstance>,
}

class SignUpForm extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            sent: false,
            loading: false,
            error: '',
            formRef: React.createRef()
        }
    }

    setError = (error: string) => {
        this.setState({error})
    }
    newForm = () => {
        this.setError('')
    }

    onFinish = (formData: any) => {
        this.setState(_state => ({loading: true}))
        SignUpService.signup(formData).then(() => {
            this.setState({sent: true})
        }).catch((error) => {
            this.setState({error: error.response.data.message})
        }).finally(() => {
            this.setState({loading: false})
        })
    };

    render() {
        const {loading, sent, error, formRef} = this.state
        return (
            <>
                {sent && <Result className={'p-3'} status="success"
                                 title={<div className={'font-size-md'}>Váš požadavek byl úspěšně dokončen.</div>}
                                 subTitle={'Prosím zkontrolujte svou e-mailovou schránku.'}
                />
                }
                {error.length > 0 && <Result className={'p-3'} status="error"
                                             subTitle={error}
                                             extra={[
                                                 <Button onClick={this.newForm} key={'new'} type={'link'}
                                                         icon={<ReloadOutlined/>}>Zkusit znovu</Button>

                                             ]}
                />
                }
                {!sent && error.length === 0 &&
                    <Form ref={formRef} layout={'vertical'} className={"mt-3"} onFinish={this.onFinish}>
                        <Form.Item name={"name"} label={"Jméno"}
                                   rules={[
                                       {
                                           required: true,
                                           message: 'Vložte jméno',
                                       }
                                   ]}
                        >
                            <Input className={'w-100'} placeholder={"Jméno"}/>
                        </Form.Item>
                        <Form.Item name={"surname"} label={"Příjmení"}
                                   rules={[
                                       {
                                           required: true,
                                           message: 'Vložte příjmení',
                                       }
                                   ]}
                        >
                            <Input className={'w-100'} placeholder={"Příjmení"}/>
                        </Form.Item>
                        <Form.Item name={"email"} label={"E-mail"}
                                   rules={[
                                       {
                                           required: true,
                                           message: 'Vložte e-mail',
                                       },
                                       {
                                           type: 'email',
                                           message: 'Vložte e-mail ve správném tvaru'
                                       }
                                   ]}
                        >
                            <Input className={'w-100'} placeholder={"E-mail"}
                                   prefix={<MailOutlined className="text-primary"/>}/>
                        </Form.Item>
                        <Row justify={"end"}>
                            <Button icon={<SendOutlined/>} htmlType={'submit'} type={"primary"} loading={loading}>
                                Odeslat
                            </Button>
                        </Row>
                    </Form>
                }
            </>
        )
    }
}

export default SignUpForm