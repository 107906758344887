import React from "react";
import {ICompositeFieldApprovalOptions} from "../optionEditors/composite/CompositeFieldApprovalOptionsEditor";
import {Card, Col, Row, Tag, Typography} from "antd";
import PresenterBuilder from "../../../../../../views/dataStorage/PresenterBuilder";
import EmployeesService from "../../../../../../model/service/company/EmployeesService";
import ICompositeFieldData from "../../../../../../model/interface/dataStorage/field/composite/ICompositeFieldData";
import IField, {FIELD_TYPE} from "../../../../../../model/interface/dataStorage/IField";
import {History} from "history";
import DataStorageHelper from "../../../../../../utils/DataStorageHelper";


interface IProps {
    options: ICompositeFieldApprovalOptions,
    value: ICompositeFieldData,
    field: IField,
    history: History
}

interface IState {
}

class CompositeFieldApproval extends React.Component<IProps, IState> {

    constructor(props: Readonly<IProps> | IProps) {
        super(props);
        this.state = {}
    }

    render() {
        const {options, value, field, history} = this.props
        const {
            allowJustification, authorPresenter
        } = {...(field?.options || {}),...options}
        const presenter = authorPresenter ? EmployeesService.getInstance().getPresenter(authorPresenter) :
            EmployeesService.getInstance().getDefaultPresenter()

        return (
            <div>
                <Row gutter={[0, 4]}>
                    <Col>
                        {value[this.RESULT] === 1 && <Tag color={'success'}>{'Schváleno'}</Tag>}
                        {value[this.RESULT] === 2 && <Tag color={'error'}>{'Zamítnuto'}</Tag>}
                        {value[this.RESULT] === null && <Tag>{'Čeká na schválení'}</Tag>}
                    </Col>
                    <Col className={'d-flex align-items-center'}>
                        {value[this.AUTHOR] && presenter && <Typography.Text className={'text-muted mr-2'}>
                            {PresenterBuilder.build(presenter, value[this.AUTHOR], presenter.options || {})}
                        </Typography.Text>}
                    </Col>
                    <Col className={'d-flex align-items-center'}>
                        {value[this.DATE] && <Typography.Text className={'text-muted'}>
                            {DataStorageHelper.buildFieldValue({
                                ...field,
                                name: this.DATE,
                                type: FIELD_TYPE.DATE_TIME
                            }, value, history)}
                        </Typography.Text>}
                    </Col>
                </Row>
                {allowJustification && value[this.JUSTIFICATION] && (
                    <Card size={"small"} className={'mt-2 p-0 mb-0'}>
                        {value[this.JUSTIFICATION]}
                    </Card>
                )}
            </div>
        )
    }

    getFieldName = (property: string) => {
        const {field} = this.props
        return field.name + '_' + property
    }

    JUSTIFICATION = this.getFieldName('justification')
    RESULT = this.getFieldName('result')
    DATE = this.getFieldName('date')
    AUTHOR = this.getFieldName('author')
}

export default CompositeFieldApproval
