import React from "react"
import {IFilterInput} from "../IFilterBase";
import {API_FILTER_TYPE} from "../../../model/constants/ApiConstant";
import {Switch} from "antd";


interface IState {

}

interface IProps extends IFilterInput {

}

class Boolean extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {}
    }

    render() {
        const {field, item, onChange, selected} = this.props

        return (
            <Switch
                checkedChildren={item.options?.fieldOptions?.trueLabel || field.options?.trueLabel || 'Povoleno'}
                unCheckedChildren={item.options?.fieldOptions?.falseLabel || field.options?.falseLabel || 'Zakázáno'}
                checked={!!selected[0]?.value}
                onChange={(checked) => {
                    onChange([{
                        type: API_FILTER_TYPE.EQUAL,
                        value: checked,
                        name: field.name
                    }])
                }}/>
        )
    }
}

export default Boolean