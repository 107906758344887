import React, {RefObject} from "react"
import {FormInstance} from "antd";
import IBackground from "../../../model/interface/ui/IBackground";

interface IProps {
    background?: IBackground
    style?: React.CSSProperties
    className?: string
}

interface IState {

}

class Background extends React.Component<IProps, IState> {

    formRef: RefObject<FormInstance> = React.createRef()

    constructor(props: IProps) {
        super(props);
        this.state = {}
    }

    render() {
        const {background, style, children, className} = this.props

        return <div className={className} style={{
            ...style, ...(this.buildStyles(background))
        }}>
            {children}
        </div>
    }

    buildStyles(background: IBackground | undefined): React.CSSProperties {
        return background ? {
            backgroundImage: 'url("' + (background.url || background.file?.publicUrl) + '")',
            backgroundSize: background.fit,
            backgroundPositionX: background.positionX,
            backgroundPositionY: background.positionY,
            backgroundAttachment: background.scroll ? 'scroll' : 'fixed',
            backgroundRepeat: 'no-repeat',
        } : {};
    }
}


export default Background
