import IReportWidget from "../../../../../model/interface/report/IReportWidget";
import ReportWidgetType from "./ReportWidgetType";
import WidgetList, {IWidgetListItem, WidgetListManager} from "../../widget/WidgetList";
import {antIcons} from "utils/Icons/AntIcons";

class ReportWidgetListManager extends WidgetListManager<IReportWidget> {

    widgets: IWidgetListItem[] = [
        ...WidgetList.getAll(),
        {
            label: 'Pohled',
            type: ReportWidgetType.VIEW,
            icon: antIcons.FileOutlined,
            group: ReportWidgetType.GROUP_DYNAMIC
        },
        {
            label: 'Dohledání zaměstnance',
            type: ReportWidgetType.EMPLOYEE_LOOKUP,
            icon: antIcons.UserAddOutlined,
            group: ReportWidgetType.GROUP_DYNAMIC
        },
        {
            label: 'Notifikace',
            type: ReportWidgetType.NOTIFICATIONS,
            icon: antIcons.BellOutlined,
            group: ReportWidgetType.GROUP_DYNAMIC
        },
        {
            label: 'Vyhledávání podle štítku',
            type: ReportWidgetType.RESOURCE_FINDER_BY_CODE,
            icon: antIcons.ScanOutlined,
            group: ReportWidgetType.GROUP_DYNAMIC
        },
        {
            label: 'Menu',
            type: ReportWidgetType.MENU,
            icon: antIcons.MenuOutlined,
            group: ReportWidgetType.GROUP_DYNAMIC
        },
        {
            label: 'Dovolená',
            type: ReportWidgetType.VACATION,
            icon: antIcons.CoffeeOutlined,
            group: ReportWidgetType.GROUP_DYNAMIC
        },
        {
            label: 'Docházka žádosti',
            type: ReportWidgetType.WORKLOAD_REPORT,
            icon: antIcons.FileDoneOutlined,
            group: ReportWidgetType.GROUP_DYNAMIC
        },
        {
            label: 'Docházka píchačky',
            type: ReportWidgetType.WORKLOAD_ATTENDANCE,
            icon: antIcons.PlayCircleOutlined,
            group: ReportWidgetType.GROUP_DYNAMIC
        }
    ]

    getByType = (type: string) => {
        const item = this.widgets.find(widget => widget.type === type) as IWidgetListItem
        if (item) {
            const {label, type} = item
            return {
                label,
                type,
                weight: 1,
                children: [],
                id: '',
                options: {},
                parent: null,
                uuid: '',
                key: ''
            }
        }
        throw new Error(`Report widget type[${type}] does not exist!`)
    }
}


const ReportWidgetList = new ReportWidgetListManager()

export default ReportWidgetList