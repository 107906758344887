import {Tooltip} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import React from "react";
import IFilterFunctions from "./IFilterFunctions";
import IFilterBase, {IFilterBaseValue} from "./IFilterBase";
import FilterDropDown from "./FilterDropDown";
import {API_FILTER_TYPE} from "../../model/constants/ApiConstant";
import moment from "moment";
import Date from "./input/Date";

const DateFilter: IFilterBase = {
    handleSearch(_setSelectedKeys, _selectedKeys, confirm) {
        confirm();
    },

    handleReset(clearFilters, setValue) {
        setValue([])
        clearFilters();
    },

    build(item, field, state, setValue): IFilterFunctions {
        const findFrom = (selectedKeys: IFilterBaseValue[]) => {
            return selectedKeys.find(key => key.type === API_FILTER_TYPE.GREATER_OR_EQUAL)
        }
        const findTo = (selectedKeys: IFilterBaseValue[]) => {
            return selectedKeys.find(key => key.type === API_FILTER_TYPE.LESSER_OR_EQUAL)
        }

        return {
            filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => {
                return (
                    <FilterDropDown onReset={() => this.handleReset(clearFilters, setValue)}
                                    onConfirm={() => this.handleSearch(setSelectedKeys, selectedKeys, confirm, setValue)}>
                        <Date selected={selectedKeys} field={field} item={item} onChange={dates => setSelectedKeys(dates)
                        }/>
                    </FilterDropDown>
                )
            },
            filterIcon: (filtered: boolean): JSX.Element => {
                const from = findFrom(state)?.value
                const to = findTo(state)?.value
                return (
                    <Tooltip title={filtered ? "Filtrováno: " + (from || '') + ' -> ' + (to || '') : ''}>
                        <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}}/>
                    </Tooltip>
                )
            },
            onFilter: (value, record): boolean => {
                const recordDate = moment(record[field.name])

                return value.type === API_FILTER_TYPE.LESSER_OR_EQUAL ?
                    (!value.value || moment(value.value).isSameOrBefore(recordDate)) :
                    (!value.value || moment(value.value).isSameOrAfter(recordDate))
            }
        }
    }
}

export default DateFilter