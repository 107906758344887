import fetch from "../../auth/ETagCachedFetchInterceptor";

const ValidationService = {
  validateBankAccountNumber: (value: string) => {
    return fetch({
      url: '/validate/bank-account-number',
      method: 'post',
      data: {value: value}
    }).then(data => {
      // @ts-ignore // TODO
      return data.valid
    })
  }
}

export default ValidationService