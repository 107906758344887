import React from "react";
import {ionIcons, ionIconsClasses} from "./Icons/IonIcons";
import {antIcons, antIconsClasses} from "./Icons/AntIcons";

export function IconPickerList(library?: 'ant' | 'ion') {
    return [
        ...Object.entries(!library || library === "ant" ? antIcons : []).filter(icon => icon[0].includes('Outlined')).map(icon => ({
            icon: icon[1],
            value: 'ant.' + icon[0],
            label: icon[0]
        })),
        ...Object.entries(!library || library === "ion" ? ionIcons : []).filter(icon => icon[0].includes('Outline')).map(icon => ({
            icon: icon[1],
            value: 'ion.' + icon[0],
            label: icon[0]
        }))
    ].sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))
}

export function getIconLibrary(name: string): 'ant' | 'ion' | string {
    const source = name.split(".")
    return source.length > 1 ? source[0] : ''
}

export default function IconBuilder(name: string, defaultValue?: JSX.Element, props?: any) {
    if (!name) {
        return defaultValue
    }
    const library = getIconLibrary(name)
    name = library ? name.split(".")[1] : name
    switch (library) {
        case 'ion':
            const IonIcon = ionIconsClasses[name]
            return name && ionIconsClasses.hasOwnProperty(name) ?
                <IonIcon width={'1em'} height={'1em'} {...props}/> : defaultValue
        case 'ant':
        default:
            const AntIcon = antIconsClasses[name]
            return name && antIconsClasses.hasOwnProperty(name) ? <AntIcon {...props}/> : defaultValue
    }
}