import React from "react";
import ICardWidgetProps from "../../../../../../model/interface/dataStorage/card/ICardWidgetProps";
import {Typography} from "antd";
import ICardWidgetRelationField from "../../../../../../model/interface/dataStorage/card/ICardWidgetRelationField";
import View from "../../../../view/View";
import {FILTER_RELATION_FIELD_VALUE} from "./editor/RelationFieldEditor";
import {isFilterNested} from "../../../../../../model/interface/api/IRestServiceFilters";


class RelationFieldWidget extends React.Component<ICardWidgetProps<ICardWidgetRelationField>> {

    render() {
        const {value, editor, reload, options} = this.props
        const {view, filters, title, titleLevel, allowSettings, allowExport} = options

        let relationField = options.relationField

        let filterMap = Array.isArray(filters) ? Object.fromEntries(filters.map((filter, index) => ['filter' + index, filter])) : {...filters}
        if (filterMap) {
            Object.entries(filterMap).forEach(([index, filter]) => {
                if (filterMap && !isFilterNested(filter)
                    && (filter.field === relationField || filter.value === FILTER_RELATION_FIELD_VALUE)){
                    if (!value){
                        delete filterMap[index]
                        return
                    }
                    filterMap[index] = isFilterNested(filterMap[index]) ? filterMap[index] : {
                        ...filterMap[index],
                        value
                    }
                    relationField = filter.field
                }
            })
        }

        return (
            <div className={'mb-2'}>
                {editor && relationField && <Typography.Text type="warning">
                    Editor vynechává relační filtr pro zobrazení dat, všechny ostatní filtry jsou aktivní
                </Typography.Text>}
                {view ? (
                    <View
                        standalone={false}
                        uuid={view}
                        filters={{...filterMap}}
                        match={this.props.match} history={this.props.history}
                        title={title}
                        reload={reload}
                        titleLevel={titleLevel}
                        allowSettings={allowSettings}
                        allowExport={allowExport}
                        onFinishAction={this.props.functions.onFinishAction}
                    />
                ) : (
                    <Typography.Text type="danger">Chyba konfigurace #2</Typography.Text>
                )}
            </div>
        );
    }
}

export default RelationFieldWidget