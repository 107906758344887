import React, {RefObject} from "react";
import {IContentTypeStepProps} from "./ContentTypeConfiguration";
import {Checkbox, Form, FormInstance, Input, Mentions, Modal, Select, Switch, Table, Tooltip} from "antd";
import IRule from "../../../../model/interface/security/IRule";
import IAction from "../../../../model/interface/dataStorage/IAction";
import {CloudOutlined, DeleteOutlined, PlusOutlined, TeamOutlined, NodeIndexOutlined} from "@ant-design/icons";
import ICondition, {CONDITION_TYPE_AND} from "../../../../model/interface/security/ICondition";
import IField from "../../../../model/interface/dataStorage/IField";
import Utils from "../../../../utils";
import _ from "underscore"
import {connect, RootStateOrAny} from "react-redux";
import selectors from "../../../../redux/selectors";
import IContentType from "../../../../model/interface/dataStorage/IContentType";
import Button from "../../../shared/button/Button";
import IRuleConnection from "../../../../model/interface/security/IRuleConnection";
import IState from "../../../../model/interface/dataStorage/workflow/IState";
import IWorkflow from "../../../../model/interface/dataStorage/workflow/IWorkflow";
import FilterTreeBuilder from "../../view/settings/customFilters/FilterTreeBuilder";
import IRestServiceFilters from "../../../../model/interface/api/IRestServiceFilters";

interface IProps extends IContentTypeStepProps {
    findContentTypeByClassName: (className: string) => IContentType
    findWorkflowByContentType: (contentType: IContentType) => IWorkflow
}

interface IStateStepPermissions {
    rule: IRule|null,
    ruleConnection: IRuleConnection|null,
    formRef: RefObject<FormInstance>
}

class ContentTypePermissions extends React.Component<IProps, IStateStepPermissions> {
    state = {
        rule: null,
        ruleConnection: null,
        formRef: React.createRef() as RefObject<FormInstance>
    }

    componentDidMount() {
        this.load()
    }

    private load() {
        // nothing to load yet
    }

    private editRule(rule: IRule) {
        this.setState({rule})
    }

    private saveRule() {
        console.log('save rule')
        this.state.formRef.current?.validateFields().then((values: any) => {
            console.log('save rule #1', values)
            const rule= this.state.rule! as IRule
            const { rules } = this.props.resource
            if(!rule) {
                throw new Error('Rule is empty')
            }
            const uuid = rule.uuid
            const index = rule ? Utils.findIndex(rules, {uuid}) : -1
            const ruleData = {...rule , name: values.name, title: values.title}
            if(index >= 0) {
                rules[index] = {...rules[index],...ruleData}
            } else {
                rules.push(ruleData)
            }
            console.log('save rule #2', rules)
            this.props.onValuesChange({
                rules
            })
            this.setState({
                rule: null
            })
        })
    }

    private addRule() {
        console.log('add rule')
        this.setState({
            rule: {
                uuid: Utils.uuid(),
                title: '',
                name: '',
                contentType: this.props.resource.uuid,
                global: false,
                // actions: [],
                ruleConnections: [],
                conditions: [],
                groups: [],
                conditionType: CONDITION_TYPE_AND,
                weight: this.props.resource.rules.length + 1
            }
        })
    }

    private cancelRule() {
        this.setState({rule: null})
    }

    private removeRule(rule: IRule) {
        const { rules } = this.props.resource
        const index = Utils.findIndex(rules, {uuid: rule.uuid})
        if(index >= 0) {
            rules.splice(index, 1)
            this.props.onValuesChange({
                rules
            })
        }
    }

    private onRuleValuesChange(values: any) {
        const rule = this.state.rule! as IRule
        if(values['conditionTypes']) {
            Object.keys(values['conditionTypes']).forEach((uuid: string) => {
                const conditionIndex = Utils.findIndex(rule.conditions, {uuid})
                rule.conditions[conditionIndex]['type'] = values['conditionTypes'][uuid]
            })
        }
        if(values['conditionFields']) {
            Object.keys(values['conditionFields']).forEach((uuid: string) => {
                const conditionIndex = Utils.findIndex(rule.conditions, {uuid})
                const fieldUuid = values['conditionFields'][uuid]
                rule.conditions[conditionIndex]['field'] = _.findWhere(this.props.resource.fields, {uuid: fieldUuid})?.uuid || null
            })
        }
        if(values['conditionRules']) {
            Object.keys(values['conditionRules']).forEach((uuid: string) => {
                const conditionIndex = Utils.findIndex(rule.conditions, {uuid})
                const ruleUuid = values['conditionRules'][uuid]
                if(ruleUuid) {
                    const rules = this.findRules(rule.conditions[conditionIndex].field)
                    rule.conditions[conditionIndex]['inheritRule'] = _.findWhere(rules, {uuid: ruleUuid})?.uuid || null
                } else {
                    rule.conditions[conditionIndex]['inheritRule'] = null
                }

            })
        }
        if(values['conditionStates']) {
            Object.keys(values['conditionStates']).forEach((uuid: string) => {
                const conditionIndex = Utils.findIndex(rule.conditions, {uuid})
                rule.conditions[conditionIndex]['states'] = values['conditionStates'][uuid]
            })
        }
        if(values['conditionValues']) {
            Object.keys(values['conditionValues']).forEach((uuid: string) => {
                const conditionIndex = Utils.findIndex(rule.conditions, {uuid})
                rule.conditions[conditionIndex]['value'] = values['conditionValues'][uuid]
            })
        }
        if(values['conditionFilters']) {
            Object.keys(values['conditionFilters']).forEach((uuid: string) => {
                const conditionIndex = Utils.findIndex(rule.conditions, {uuid})
                rule.conditions[conditionIndex]['filter'] = values['conditionFilters'][uuid]
            })
        }
        console.log('onRuleValuesChange', values)
        this.setState({rule})
    }

    private findRules(field: string | null) {
        console.log('find rules', field)
        const { resource, findContentTypeByClassName } = this.props
        const fieldObject = field && _.findWhere(resource.fields, {uuid: field})
        if(fieldObject && fieldObject.targetEntity) {
            const contentType = findContentTypeByClassName(fieldObject.targetEntity)
            return contentType?.rules || []
        }
        return []
    }

    cancelRuleConnection() {
        this.setState({ruleConnection: null})
    }

    editRuleConnection(ruleConnection: IRuleConnection) {
        this.setState({ruleConnection})
    }

    saveRuleConnection() {
        const {resource} = this.props
        const ruleConnection = this.state.ruleConnection! as IRuleConnection
        const ruleIndex = Utils.findIndex(resource.rules, {uuid: ruleConnection.rule})
        if(ruleIndex < 0) {
            console.log('Rule with uuid '+ruleConnection.rule+' was not found', resource, ruleConnection)
            throw new Error('Rule with uuid '+ruleConnection.rule+' was not found')
        }
        const ruleConnectionIndex = Utils.findIndex(resource.rules[ruleIndex].ruleConnections, {action: ruleConnection.action})
        let rules = resource.rules
        if(!rules[ruleIndex].ruleConnections) {
            rules[ruleIndex].ruleConnections = []
        }
        rules[ruleIndex].ruleConnections[ruleConnectionIndex] = ruleConnection
        this.props.onValuesChange({rules})
        this.cancelRuleConnection()
    }

    changeRuleConnectionState(state: IState, checked: boolean) {
        const ruleConnection = this.state.ruleConnection! as IRuleConnection
        const index = ruleConnection.states.indexOf(state.uuid)
        if(checked && index < 0) {
            ruleConnection.states.push(state.uuid)
        }
        if(!checked && index >= 0) {
            ruleConnection.states.splice(index, 1)
        }
        this.setState({ruleConnection})
    }

    private static hasWorkflowExtension(contentType: IContentType): boolean {
        return contentType.extensions ? Utils.findIndex(contentType.extensions, {type: 'App\\DataStorage\\Extension\\Workflow\\State'}) >= 0 : false
    }

    private buildColumns() {
        const columns = []
        const { resource } = this.props
        const hasWorkflowExtension = ContentTypePermissions.hasWorkflowExtension(resource)
        columns.push({
            title: "",
            render: (_: any, action: IAction) => {
                return (
                    <>
                        {action.label || action.name}
                    </>
                )
            }
        })
        resource.rules.forEach((rule: IRule) => {
            columns.push({
                title: (
                    <div className={"text-center"}>
                        <div className={"mb-1"}>
                            {rule.title || rule.name}
                            {rule.global ? (
                                <Tooltip title={"Toto pravidlo nepotřebuje přiřazené uživatelské skupiny, vyhodnocení na základě podmínek"}>
                                    <CloudOutlined className={"ml-1"} />
                                </Tooltip>
                            ) : (
                                <Tooltip title={"Toto pravidlo musí být přiřazeno přes skupinu uživatelů"}>
                                    <TeamOutlined className={"ml-1"} />
                                </Tooltip>
                            )}
                        </div>
                        <div className={"mb-1"}>
                            <Button type={"primary"} size={"small"} className={"mr-0"} onClick={() => this.editRule(rule)}>
                                Podmínky ({rule.conditions.length})
                            </Button>
                        </div>
                        <div>
                            <Button
                                icon={<DeleteOutlined />}
                                type={"link"}
                                danger
                                size={"small"}
                                className={"mr-0"}
                                onClick={() => Modal.confirm({title: "Opravdu provést?", onOk: () => this.removeRule(rule)})}
                            />
                        </div>
                    </div>
                ),
                render: (_: any, action: IAction) => {
                    const ruleConnections = rule.ruleConnections || []
                    const ruleConnection = ruleConnections.find(ruleConnection => ruleConnection.action === action.uuid)
                    return (
                        <div className={"text-center"}>
                            <Checkbox
                                checked={!!ruleConnection}
                                onChange={(event) => this.onChange(rule, action, event.target.checked)}
                            />
                            {hasWorkflowExtension && (
                                <Tooltip title={"Omezení na stavy workflow"}>
                                    <Button
                                        disabled={!ruleConnection}
                                        size={"small"}
                                        type={ruleConnection && ruleConnection.states.length > 0 ? "info" : "default"}
                                        icon={<NodeIndexOutlined/>}
                                        onClick={() => this.editRuleConnection(ruleConnection!)}
                                    />
                                </Tooltip>
                            )}
                        </div>
                    )
                }
            })
        })

        columns.push({
            title: (
                <>
                    <Button icon={<PlusOutlined />} onClick={() => this.addRule()} />
                </>
            ),
            render: () => {
                return (
                    <></>
                )
            }
        })
        console.log('columns', columns)
        return columns
    }

    private onChange(rule: IRule, action: IAction, checked: boolean) {
        console.log('change', rule, action, checked)
        const { resource } = this.props
        const { rules } = resource
        const ruleIndex = Utils.findIndex(rules, {uuid: rule.uuid})
        if(ruleIndex < 0) {
            throw new Error('Rule not found')
        }
        if(!rules[ruleIndex].ruleConnections) {
            rules[ruleIndex].ruleConnections = []
        }

        const rulConnectionIndex = Utils.findIndex(rules[ruleIndex].ruleConnections, {action: action.uuid})
        if(checked) {
            if(rulConnectionIndex < 0) {
                rules[ruleIndex].ruleConnections.push({
                    action: action.uuid,
                    rule: rule.uuid,
                    states: []
                })
            }
        } else {
            if(rulConnectionIndex >= 0) {
                rules[ruleIndex].ruleConnections.splice(rulConnectionIndex, 1)
            }
        }
        this.props.onValuesChange({rules})
    }

    private buildData() {
        return this.props.resource.actions
    }

    private addCondition(rule: IRule) {
        /*
        const rules = this.props.resource.rules
        const index = Utils.findIndex(rules, {uuid: rule.uuid})
        if(index < 0) {
            throw new Error('Rule index not found')
        }
        rules[index].conditions.push({
            uuid: Utils.uuid(),
            field: null,
            relationType: null
        })
        this.props.onValuesChange({rules})
         */
        const conditions = rule.conditions
        conditions.push({
            uuid: Utils.uuid(),
            type: 'value',
            field: null,
            relationType: null,
            inheritRule: null,
            states: []
        })
        this.setState({
            rule: {
                ...rule,
                conditions
            }
        })
    }

    private removeCondition(rule: IRule, condition: ICondition) {
        // const rules = this.props.resource.rules
        // const ruleIndex = Utils.findIndex(rules, {uuid: rule.uuid})
        // if(ruleIndex < 0) {
        //     throw new Error('Rule index not found')
        // }
        const conditionIndex = Utils.findIndex(rule.conditions, {uuid: condition.uuid})
        if(conditionIndex < 0) {
            throw new Error('Condition index not found')
        }
        rule.conditions.splice(conditionIndex, 1)
        this.setState({rule})
        // this.props.onValuesChange({rules})
    }

    private onChangeRule(values: any) {
        this.setState(prevState => ({
            rule: {...prevState.rule, ...values}
        }))
    }

    private findStates(condition?: ICondition|undefined): IState[] {
        console.log('findStates #1', condition)
        if(condition) {
            const field = this.props.resource.fields.find(field => field.uuid === condition.field)
            if(field) {
                console.log('findStates #2', field)
                const targetContentType = this.props.findContentTypeByClassName(field.targetEntity!)
                if(targetContentType && ContentTypePermissions.hasWorkflowExtension(targetContentType)) {
                    console.log('findStates #3', targetContentType)
                    const workflow = this.props.findWorkflowByContentType(targetContentType)
                    if(workflow) {
                        console.log('findStates #4', workflow)
                        return workflow.activeVersion?.states!
                    }
                }
            }
        }
        return []
    }

    private buildModal(rule: IRule) {
        const {resource, history, match} = this.props
        let conditionFields = {} as { [uuid: string]: string|null }
        let conditionValues = {} as { [uuid: string]: string }
        let conditionStates = {} as { [uuid: string]: string[] }
        let conditionRules = {} as { [uuid: string]: string }
        let conditionTypes = {} as { [uuid: string]: string }
        let conditionFilters = {} as { [uuid: string]: IRestServiceFilters | undefined }
        rule.conditions.forEach((condition: ICondition) => {
            conditionFields[condition.uuid] = condition.field as string
            conditionValues[condition.uuid] = condition.value
            conditionRules[condition.uuid] = condition.inheritRule as string
            conditionTypes[condition.uuid] = condition.type
            conditionStates[condition.uuid] = condition.states
            conditionFilters[condition.uuid] = condition.filter
        })
        const formValues = {
            name: rule.name,
            title: rule.title,
            global: rule.global,
            conditionType: rule.conditionType,
            conditionFields,
            conditionFilters,
            conditionValues,
            conditionRules,
            conditionTypes,
            conditionStates
        }

        const conditionColumns = [
            {
                title: 'Typ',
                render: (_: any, condition: ICondition) => {
                    return (
                        <>
                            <Form.Item
                                className={"mb-0"}
                                name={["conditionTypes",condition.uuid]}
                            >
                                <Select
                                    options={[{value:'inherit',label: 'Pravidlo'}, {value:'value',label: 'Hodnota'}, {value:'workflow_state',label: 'Stav workflow'}, {value:'filter',label: 'Filter'}]}
                                />
                            </Form.Item>
                        </>
                    )
                }
            },
            {
                title: "Pole",
                render: (_: any, condition: ICondition) => {
                    return (
                        <>
                            <Form.Item
                                className={"mb-0"}
                                name={["conditionFields",condition.uuid]}
                            >
                                <Select disabled={condition.type === 'filter'}
                                    // value={condition.field?.id}
                                    style={{minWidth: 120}}
                                    options={this.props.resource.fields
                                        // .filter(field => condition.type === 'value' || field.type === 'relation')
                                        .filter(field => {
                                            if(condition.type === 'workflow_state') {
                                                if(field.mode === 'relation') {
                                                    const targetContentType = this.props.findContentTypeByClassName(field.targetEntity!)
                                                    if(targetContentType && ContentTypePermissions.hasWorkflowExtension(targetContentType)) {
                                                        return true
                                                    }
                                                }
                                                return false
                                            }
                                            if(condition.type === 'inherit') {
                                                if(field.mode === 'relation') {
                                                    const targetContentType = this.props.findContentTypeByClassName(field.targetEntity!)
                                                    if(targetContentType) {
                                                        return true
                                                    }
                                                }
                                                return false
                                            }
                                            return true
                                        })
                                        .map((field:IField) => {
                                            return {
                                                label: field.label || field.name,
                                                value: field.uuid as string
                                            }
                                        }
                                    )}
                                />
                            </Form.Item>
                            {/*{condition.field ? (*/}
                            {/*    <>{condition.field.label || condition.field.name}</>*/}
                            {/*) : (*/}
                            {/*    <>N/A</>*/}
                            {/*)}*/}
                        </>
                    )
                }
            },
            {
                title: 'Hodnota / Právo / Stav / Filter',
                render: (_: any, condition: ICondition) => {
                    return (
                        <>
                            {condition.type === 'workflow_state' && (
                                <>
                                    <Form.Item
                                        className={"mb-0"}
                                        name={["conditionStates",condition.uuid]}
                                    >
                                        <Select
                                            mode={"tags"}
                                            options={this.findStates(condition).map(state => ({
                                                value: state.uuid,
                                                label: state.name
                                            }))}
                                        />
                                    </Form.Item>
                                </>
                            )}
                            {condition.type === 'inherit' && (
                                <>
                                    <Form.Item
                                        className={"mb-0"}
                                        name={["conditionRules",condition.uuid]}
                                    >
                                        <Select
                                            options={this.findRules(condition.field).map(rule => ({
                                                value: rule.uuid,
                                                label: rule.title
                                            }))}
                                        />
                                    </Form.Item>
                                </>
                            )}
                            {condition.type === 'value' && (
                                <>
                                    <Form.Item
                                        className={"mb-0"}
                                        name={["conditionValues",condition.uuid]}
                                    >

                                        <Mentions
                                            placeholder="Použijte #pro vložení tokenu"
                                            prefix={'#'}
                                            style={{minWidth: 120}}
                                        >
                                            <Mentions.Option key={'user'} value={'user'}>Uživatelský profil přihlášeného uživatele</Mentions.Option>
                                            <Mentions.Option key={'employee'} value={'employee'}>Zaměstnanecký profil přihlášeného uživatele</Mentions.Option>
                                        </Mentions>
                                    </Form.Item>
                                </>
                            )}
                            {condition.type === 'filter' && (
                                <>
                                    <Form.Item
                                        className={"mb-0"}
                                        name={["conditionFilters",condition.uuid]}
                                    >
                                        <FilterTreeBuilder contentTypeFullClassName={resource.fullClassName} history={history} match={match}/>
                                    </Form.Item>
                                </>
                            )}
                        </>
                    )
                }
            },
            // {
            //     title: 'Typ relace',
            //     render: (_: any, condition: ICondition) => {
            //         return (
            //             <>
            //                 TODO relation type
            //             </>
            //         )
            //     }
            // },
            {
                title: '',
                render: (_: any, condition: ICondition) => {
                    return (
                        <>
                            <Button
                                icon={<DeleteOutlined />}
                                type={"link"}
                                danger
                                size={"small"}
                                className={"mr-0"}
                                onClick={() => Modal.confirm({title: "Opravdu provést?", onOk: () => this.removeCondition(rule, condition)})}
                            />
                        </>
                    )
                }
            }
        ]
        const conditionData = [...rule.conditions]
        return (
            <Modal visible={!!rule} title={rule ? ( rule.title || rule.name ) : 'Nové pravidlo'} width={800} onOk={() => this.saveRule()} onCancel={() => this.cancelRule()}>
                {/*A{rule.id}B*/}
                <Form ref={this.state.formRef} initialValues={formValues} onValuesChange={(values) => this.onRuleValuesChange(values)}>
                    <Form.Item
                        label={"Titulek"}
                        name={"title"}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={"Název"}
                        name={"name"}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={"Vyhodnocení podmínek"}
                        name={"conditionType"}
                    >
                        <Select
                            options={[
                                {value:1,label:"Zároveň (AND)"},
                                {value:2,label:"Alespoň jedna (OR)"},
                            ]}
                        />
                    </Form.Item>
                    <Form.Item
                        label={"Globální"}
                        name={"global"}
                        help={"Uživatelé nemusí mít skupinu s tímto oprávněním, vyhodnocení je na základě podmínek"}
                    >
                        <Switch
                            checked={formValues.global}
                            onChange={(global) => this.onChangeRule({global})}
                        />
                    </Form.Item>

                    <Table
                        rowKey={"uuid"}
                        columns={conditionColumns}
                        dataSource={conditionData}
                    />
                    <Button icon={<PlusOutlined />} onClick={() => this.addCondition(rule)}>Přidat podmínku</Button>
                </Form>
            </Modal>
        )
    }

    buildRuleConnectionModal (ruleConnection: IRuleConnection) {
        const workflow = this.props.findWorkflowByContentType(this.props.resource)
        return (
            <Modal visible={!!ruleConnection} title={'Omezení na stavy workflow'} onOk={() => this.saveRuleConnection()} onCancel={() => this.cancelRuleConnection()}>
                {workflow ? (
                    <>
                        {workflow.activeVersion!.states.map((state: IState) => (
                            <div className={"my-2"}>
                                <Switch
                                    className={"mr-2"}
                                    checked={ruleConnection.states.indexOf(state.uuid) >= 0}
                                    onChange={checked =>this.changeRuleConnectionState(state, checked)}
                                />
                                {state.name}
                            </div>
                        ))}
                    </>
                ): (
                    <>
                        Zatím není přiřazené žádné workflow
                    </>
                )}
            </Modal>
        )
    }

    render() {
        const {rule, ruleConnection} = this.state
        const ruleModal = rule ? this.buildModal(rule!) : ''
        const statesModal = ruleConnection ? this.buildRuleConnectionModal(ruleConnection!) : ''
        return (
            <>
                {ruleModal}
                {statesModal}
                <Table
                    pagination={false}
                    rowKey={"uuid"}
                    columns={this.buildColumns()}
                    dataSource={this.buildData()}
                />
            </>
        )
    }
}


const mapStateToProps = (state: RootStateOrAny) => {
    return {
        findContentTypeByClassName: (className: string) => selectors.contentTypes.findOneBy(state, 'fullClassName', className),
        findWorkflowByContentType: (contentType: IContentType) => selectors.workflow.findOneByContentType(state, contentType)
    }
}
export default connect(mapStateToProps)(ContentTypePermissions)
