import {Card, Form, Select, Switch} from 'antd';
import React from "react";
import {IFieldOptionsEditorProps} from "../FieldOptionsEditor";
import IBaseProps from "../../../../../../model/interface/IBaseProps";
import {IRestCompanyStructureAccept} from "../../../../../../model/service/company/CompanyStructureService";
import FormElementField from "../../../form/FormElement/FormElementField";
import IField from "../../../../../../model/interface/dataStorage/IField";


const types = [
    {
        value: "company",
        label: 'Společnost'
    },
    {
        value: "unit",
        label: 'Jednotka'
    },
    {
        value: "job",
        label: 'Pozice'
    },
    {
        value: "employee",
        label: 'Zaměstnanec'
    }
]

export interface IFieldStructureOptions {
    companyStructureRoot?: string | string[]
    companyStructureOnlyDirectChildren?: boolean
    companyStructureAccepts?: IRestCompanyStructureAccept
    companyStructureHideCurrent?: boolean
    companyStructureMultiple?: boolean
    companyStructureSearch?: string
    companyStructureOrder?: "ASC" | "DESC"
    companyReturnNode?: boolean
    companyStructureMode?: 'tree' | 'list'
    // companyStructureActive?: boolean
}

interface IProps extends IFieldOptionsEditorProps, IBaseProps {
    field: IField
    defaultCompanyStructureAccepts: IRestCompanyStructureAccept
}

class FieldStructureOptionsEditor extends React.Component<IProps> {

    render() {
        const {history, match, field, options, buildFieldName, defaultCompanyStructureAccepts} = this.props
        return (
            <Card>
                <Form.Item name={buildFieldName('companyStructureOnlyDirectChildren')} label={'Pouze přímé děti'}
                           valuePropName={'checked'}>
                    <Switch/>
                </Form.Item>
                <Form.Item name={buildFieldName('companyStructureAccepts')} label={'Typ'}
                           initialValue={defaultCompanyStructureAccepts}>
                    <Select>
                        {types.map(type => (
                            <Select.Option key={type.value} value={type.value}>{type.label}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item name={buildFieldName('companyStructureMode')} label={'Mod'}>
                    <Select>
                        <Select.Option key={'tree'} value={'tree'}>Strom</Select.Option>
                        <Select.Option key={'list'} value={'list'}>Seznam</Select.Option>
                    </Select>
                </Form.Item>
                {/*<Form.Item name={'companyStructureActive'} label={'Pouze aktivní'}*/}
                {/*           valuePropName={'checked'}>*/}
                {/*    <Switch/>*/}
                {/*</Form.Item>*/}
                <FormElementField
                    field={field}
                    id={"companyStructureRoot"}
                    customFieldName={buildFieldName('companyStructureRoot')}
                    label={"Omezit na strom organigramu"}
                    type={"companyStructure"}
                    preview={true}
                    match={match}
                    functions={{} as any}
                    history={history}
                    options={{
                        type: "companyStructure",
                        label: "",
                        companyStructureAccepts: options.companyStructureAccepts,
                        companyStructureMultiple: true
                    }}
                />
            </Card>
        )
    }
}

export default FieldStructureOptionsEditor