import RestService from "model/service/dataStorage/RestService";
import IType, {COMPANY_WORKLOAD_TYPE_MODE_WORKING} from "../../../interface/company/workload/IType";
import IRestServiceCollectionResponse from "../../../interface/api/IRestServiceCollectionResponse";
import IRestServiceOptions from "../../../interface/api/IRestServiceOptions";
import {API_FILTER_TYPE} from "../../../constants/ApiConstant";
import IRestServiceChoiceListResponse from "../../../interface/api/IRestServiceChoiceListResponse";

interface IRestTypesServiceCollectionResponse extends IRestServiceCollectionResponse {
    results: Array<IType>
}

interface IRestTypesService {
    collectionList(options?: IRestServiceOptions): Promise<IRestTypesServiceCollectionResponse>,
    collectionListPlanType(options?: IRestServiceOptions): Promise<IRestTypesServiceCollectionResponse>,

    collectionCreate(data: any): Promise<IType>,

    resourceRetrieve(id: number | string): Promise<IType>,

    resourceUpdate(id: number | string, data: any): Promise<IType>,

    resourceDelete(id: number | string): Promise<void>,

    choiceList(presenterName: string, options?: IRestServiceOptions): Promise<IRestServiceChoiceListResponse>

    listPlanType(types: IType[]): IType[],

    listReportEditableType(types: IType[]): IType[],
}

const TypesService_COLLECTION = 'company/workload/types'
const CHOICES = 'company/workload/types-choices/'

const TypesService: IRestTypesService = {
    collectionList: function (options?) {
        return RestService.collectionList(TypesService_COLLECTION, options as unknown as IRestServiceOptions) as Promise<IRestTypesServiceCollectionResponse>
    },
    collectionCreate: function (data) {
        return RestService.collectionCreate(TypesService_COLLECTION, data) as Promise<IType>
    },
    resourceRetrieve: function (id) {
        return RestService.resourceRetrieve(TypesService_COLLECTION, id) as Promise<IType>
    },
    resourceDelete: function (id) {
        return RestService.resourceDelete(TypesService_COLLECTION, id)
    },
    resourceUpdate: function (id, data) {
        return RestService.resourceUpdate(TypesService_COLLECTION, id, data) as Promise<IType>
    },
    collectionListPlanType(options?: IRestServiceOptions): Promise<IRestTypesServiceCollectionResponse> {
        return RestService.collectionList(TypesService_COLLECTION, {
            ...options,
            filters: {
                ...options?.filters,
                'plan_type_only': {
                    type: API_FILTER_TYPE.OR, children: {
                        0: {
                            type: API_FILTER_TYPE.EQUAL,
                            field: 'approvalRequired',
                            value: true
                        },
                        1: {
                            type: API_FILTER_TYPE.EQUAL,
                            field: 'unlimitedFund',
                            value: false
                        },
                        2: {
                            type: API_FILTER_TYPE.NOT_EQUAL,
                            field: 'mode',
                            value: COMPANY_WORKLOAD_TYPE_MODE_WORKING
                        }
                    }
                }
            }
        }) as Promise<IRestTypesServiceCollectionResponse>

    },
    choiceList(presenterName: string, options?: IRestServiceOptions): Promise<IRestServiceChoiceListResponse> {
        return RestService.collectionList(CHOICES + presenterName, options) as unknown as Promise<IRestServiceChoiceListResponse>
    },
    listPlanType(types: IType[]) {
        return types.filter(t => t.approvalRequired || !t.unlimitedFund || t.mode !== COMPANY_WORKLOAD_TYPE_MODE_WORKING)
    },
    listReportEditableType(types: IType[]) {
        return types.filter(t => t.reportInlineEdit || (t.mode === COMPANY_WORKLOAD_TYPE_MODE_WORKING && !t.approvalRequired && t.unlimitedFund))
    }
}

export default TypesService