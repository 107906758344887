import React from "react";
import {Col, Form, Input, message, Result, Row, Typography} from "antd";
import {KeyOutlined, LoadingOutlined, ReloadOutlined, RollbackOutlined} from "@ant-design/icons";
import IAdditionalAuthorizationLogin from "../../model/interface/security/IAdditionalAuthorizationLogin";
import TokenService from "../../model/service/security/TokenService";
import Button from "../shared/button/Button";

interface IProps {
    additionalAuthorization: IAdditionalAuthorizationLogin,
    onSuccess: (token: string) => void,
    onCancel: () => void
}

interface IState {
    sent: boolean
    loading: boolean
    resetting: boolean
    error: string
    additionalAuthorization: IAdditionalAuthorizationLogin
}

const CHECK_INTERVAL = 2000

class AdditionalAuthorizationForm extends React.Component<IProps, IState> {

    constructor(props: IProps, context: any) {
        super(props, context);
        this.state = {
            additionalAuthorization: props.additionalAuthorization,
            sent: false,
            loading: false,
            resetting: false,
            error: ''
        }
    }

    componentDidMount() {
        if (this.props.additionalAuthorization.external) {
            this.submit()
        }
    }


    submit = (code?: any) => {
        const {resetting} = this.state
        const {onSuccess, additionalAuthorization} = this.props
        this.setState({loading: true})
        !resetting && TokenService.additionalAuthorization(additionalAuthorization.user, code).then(response => {
            const {token, code} = response
            if (token) {
                message.success('Úspěšná autorizace!').then()
                return onSuccess(token)
            }
            if (code === 'additional_authorization_waiting') {
                return !this.state.resetting && setTimeout(this.submit, CHECK_INTERVAL)
            }
            throw new Error('Token missing!')
        }).catch((error) => {
            this.setState({error: error.response?.data?.message || error || ''})
        }).finally(() => {
            this.setState({loading: false})
        })
    }

    reset = () => {
        const {additionalAuthorization} = this.state
        this.setState({resetting: true})
        TokenService.additionalAuthorization(additionalAuthorization.user, '', true).then(response => {
            if (response.additionalAuthorization) {
                return this.setState({
                    additionalAuthorization,
                    error: '',
                    resetting: false
                }, additionalAuthorization.external ? this.submit : undefined)
            }
            throw new Error('Authorization missing!')
        }).catch((error) => {
            this.setState({error: error.response?.data?.message || error || '', resetting: false})
        })
    }

    setError = (error: string = '') => {
        this.setState({error})
    }

    render() {
        const {onCancel} = this.props
        const {loading, error, resetting, additionalAuthorization} = this.state

        return (
            <>
                {error && (additionalAuthorization.external ? (
                    <Result className={'p-0'} status="error"
                            title={<div className={'font-size-md'}>Přihlášení se nezdařilo!</div>}
                            extra={<Row gutter={[8, 8]}>
                                <Col>
                                    <Button icon={<RollbackOutlined/>} onClick={() => this.reset()}>
                                        Zkuste to znovu
                                    </Button>
                                </Col>
                            </Row>}
                    />
                ) : (
                    <Result className={'p-0'} status="error"
                            title={<div className={'font-size-md'}>Přihlášení se nezdařilo!</div>}
                            extra={<Button type={'info'} icon={<RollbackOutlined/>} onClick={() => this.setError()}>
                                Zkuste kód zadat znovu
                            </Button>}
                    />
                ))}
                {!error &&
                    <>
                        {additionalAuthorization.external ? (
                            <Result className={'p-0'} status="info"
                                    icon={resetting ? <ReloadOutlined/> : <LoadingOutlined/>}
                                    title={<div className={'font-size-md'}>{additionalAuthorization.message}</div>}
                            />
                        ) : (
                            <Form className={"mt-3"}>
                                <Typography.Text className={'font-size-md mb-3 d-block'} strong={true}>
                                    {additionalAuthorization.message}
                                </Typography.Text>
                                <Form.Item
                                    name={"code"}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Povinné',
                                        }
                                    ]}
                                >
                                    <Input.Search className={'w-100'} prefix={<KeyOutlined className="text-primary"/>}
                                                  onSearch={value => this.submit(value)} loading={loading}
                                                  enterButton={'Ověřit'}/>
                                </Form.Item>
                            </Form>
                        )}
                    </>
                }
                <Row gutter={16} className={'mt-4'} wrap={false} justify={"space-between"}>
                    <Col>
                        <Button icon={<RollbackOutlined/>} onClick={() => onCancel()}>
                            Zpět
                        </Button>
                    </Col>
                    {additionalAuthorization.canReload && (
                        <Col>
                            <Button icon={<ReloadOutlined/>} loading={resetting} type={'success'}
                                    onClick={this.reset}>
                                {additionalAuthorization.external ? 'Znovu autorizovat' : ' Nový autorizační kód'}
                            </Button>
                        </Col>
                    )}
                </Row>
            </>
        )
    }
}

export default AdditionalAuthorizationForm