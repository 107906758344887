import React from "react";
import IWidgetProps from "../../../../model/interface/widget/IWidgetProps";
import {Popover, Tooltip} from "antd";
import {connect, RootStateOrAny} from "react-redux";
import selectors from "../../../../redux/selectors";
import IRoute from "../../../../model/interface/dataStorage/IRoute";
import IWidgetPropsFunctions from "../../../../model/interface/widget/IWidgetPropsFunctions";
import Utils from "../../../../utils";
import ILinkOptions from "../../../../model/interface/widget/option/ILinkOptions";
import Button from "../../../shared/button/Button";
import {Link} from "react-router-dom";
import IconBuilder from "../../../../utils/IconBuilder";

interface IProps extends IWidgetProps<IWidgetPropsFunctions, ILinkOptions> {
    findRouteByUuid: (uuid: string) => IRoute
}

class BackButtonWidget extends React.Component<IProps> {

    render() {
        const {findRouteByUuid, options, resource} = this.props
        const {
            route,
            url,
            label,
            mode,
            type,
            icon,
            color,
            source,
            textColor,
            danger,
            size,
            tooltip,
            blank,
            popupTrigger
        } = options

        const routeObject = route ? findRouteByUuid(route) : ''
        let finalUrl = ''
        if (source === 'route' && routeObject) {
            const routeUrl = routeObject.url
            const params = routeUrl.match(/:([a-zA-Z_]+)/g)
            finalUrl = routeUrl
            if (params && params.length) {
                params.forEach(name => {
                    let id = Utils.parseObjectToIdentifier(resource?.[name.slice(1)])
                    if (id) {
                        finalUrl = finalUrl.replace(name, id)
                    }
                })
            }
        }
        if (source === 'url' && url) {
            finalUrl = url
        }
        return (
            <>
                {mode && {
                    button:
                        <Link target={blank ? '_blank' : ''} to={finalUrl}>
                            <Tooltip title={tooltip}>
                                <Button style={{background: color, color: textColor}} type={type}
                                        icon={icon && IconBuilder(icon)} danger={!!danger} size={size}>
                                    {label}
                                </Button>
                            </Tooltip>
                        </Link>,
                    text: <div>
                        <Link target={blank ? '_blank' : ''} to={finalUrl}>{label}</Link>
                    </div>,
                    popup: <Popover className={'cursor-pointer'} trigger={popupTrigger}
                                    content={<Link target={blank ? '_blank' : ''} to={finalUrl}>{finalUrl}</Link>}>
                        {label}
                    </Popover>
                }[mode]}
            </>
        );
    }
}


const mapStateToProps = (state: RootStateOrAny) => {
    return {
        findRouteByUuid: (uuid: string) => selectors.routes.findOneBy(state, 'uuid', uuid)
    }
}

export default connect(mapStateToProps)(BackButtonWidget)