import React from "react"
import _ from "underscore"
import {Button, Typography} from "antd";
import IViewEntryAction from "../../../../../model/interface/dataStorage/view/IViewEntryAction";
import {IViewStepProps} from "./ViewUnitForm";
import ViewUnitActionsType from "./ViewUnitActionsType";

interface IState {
}

class ViewUnitActions extends React.Component<IViewStepProps, IState> {
    state = {}

    onChange = (values: any) => {
        this.props.onChange(values)
    }

    render() {
        const {resource, history, match} = this.props
        const contentTypeIds = resource.contentTypes
        const contentTypes = _.filter(this.props.contentTypes, contentType => contentTypeIds.indexOf(contentType.uuid) >= 0)
        const sharedProps = {history, match}

        return (
            <>
                <Typography.Title level={3}>Akce pro entitu</Typography.Title>
                <Typography>
                    Akce dostupné pro každou položku tohoto pohledu.
                </Typography>

                {contentTypes.map(contentType => {
                    return (
                        <div key={contentType.id}>
                            <div className={"mb-3"}>
                                <Typography.Title level={4}>{contentType.label || contentType.name}</Typography.Title>
                            </div>
                            <div>
                                <ViewUnitActionsType
                                    {...sharedProps}
                                    type={'entry'}
                                    actions={contentType.actions}
                                    viewActions={resource.entryActions.filter(eA => contentType.actions.find(a => eA.action === a.uuid))}
                                    onChange={(actions: IViewEntryAction[]) => this.onChange({
                                        entryActions: [
                                            ...actions,
                                            ...resource.entryActions.filter(eA => !contentType.actions.find(a => eA.action === a.uuid))
                                        ]
                                    })}
                                />
                            </div>
                        </div>
                    )
                })}

                {contentTypes.length === 1 && (
                    <>
                        <div className={"mb-3"}>
                            <Typography.Title level={3}>Hromadné akce</Typography.Title>
                            <Typography>
                                Akce dostupné pro zvolenou sadu položek.
                            </Typography>
                        </div>
                        {contentTypes.map(contentType => {
                            return (
                                <div key={contentType.id}>
                                    <div className={"mb-3"}>
                                        <Typography.Title
                                            level={4}>{contentType.label || contentType.name}</Typography.Title>
                                    </div>
                                    <div>
                                        <ViewUnitActionsType
                                            {...sharedProps}
                                            type={'collection'}
                                            actions={contentType.actions}
                                            viewActions={resource.collectionActions.filter(eA => contentType.actions.find(a => eA.action === a.uuid))}
                                            onChange={(actions: IViewEntryAction[]) => this.onChange({
                                                collectionActions: [
                                                    ...actions,
                                                    ...resource.collectionActions.filter(eA => !contentType.actions.find(a => eA.action === a.uuid))
                                                ]
                                            })}
                                        />
                                    </div>
                                </div>
                            )
                        })}
                    </>
                )}


                <div className={"mb-3"}>
                    <Typography.Title level={3}>Akce pro obsah</Typography.Title>
                    <Typography>
                        Akce dostupné pro daný typ obsahu.
                    </Typography>
                </div>
                {contentTypes.map(contentType => {
                    return (
                        <div key={contentType.id}>
                            <div className={"mb-3"}>
                                <Typography.Title
                                    level={4}>{contentType.label || contentType.name}</Typography.Title>
                            </div>
                            <div>
                                <ViewUnitActionsType
                                    {...sharedProps}
                                    type={'schema'}
                                    actions={contentType.actions}
                                    viewActions={resource.schemaActions.filter(eA => contentType.actions.find(a => eA.action === a.uuid))}
                                    onChange={(actions: IViewEntryAction[]) => this.onChange({
                                        schemaActions: [
                                            ...actions,
                                            ...resource.schemaActions.filter(eA => !contentType.actions.find(a => eA.action === a.uuid))
                                        ]
                                    })}
                                />
                            </div>
                        </div>
                    )
                })}


                <Button type={"primary"} onClick={() => this.props.setStep()}>Následující krok</Button>
                <Button onClick={() => this.props.setStep(true)}>Předchozí krok</Button>
            </>
        )
    }
}

export default ViewUnitActions