import ILabelValue from "../../../interface/util/ILabelValue";
import IRestOptionsFilters from "../../../interface/api/IRestOptionsFilters";
import {API_FILTER_TYPE} from "../../../constants/ApiConstant";
import {IRestServiceChoiceList} from "../../../interface/api/IRestServiceChoiceListResponse";
import IUser from "../../../interface/security/IUser";
import IRestServiceOptions from "../../../interface/api/IRestServiceOptions";
import EmployeesService, {EmployeePresenterType} from "../EmployeesService";
import SettingsService from "../../SettingsService";
import PresenterBuilder from "../../../../views/dataStorage/PresenterBuilder";
import UsersService from "../../security/UsersService";

interface IService {
    get: (user: IUser, presenter?: EmployeePresenterType) => Promise<ILabelValue<string>[]>
    getByView: (user: IUser, view: 'compare' | 'calendar', defaultChoices?: ILabelValue<string>[], presenter?: EmployeePresenterType) => Promise<ILabelValue<string>[]>
}

function parseToArray(choices: IRestServiceChoiceList): ILabelValue<string>[] {
    return Object.entries(choices).map(([value, label]) => ({
        value,
        label
    })).sort((a, b) =>
        (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
}

const filters: IRestOptionsFilters = {
    obligation: {
        type: API_FILTER_TYPE.IS_NOT_NULL,
        field: 'obligation'
    },
    active: {
        type: API_FILTER_TYPE.EQUAL,
        field: 'active',
        value: true
    }
}

const params = {
    applyCustomApprovers: true
}

const PlanEmployeeChoicesService: IService = {
    get: (user, presenter = 'default') => {
        let promise: Promise<IRestServiceChoiceList>
        const currentEmployee = user.employees[0]
        if (UsersService.hasSomeCredential(user, ['vacation_request', 'vacation_read'], ['all'])) {
            promise = EmployeesService.getInstance().choiceList('default', {filters, ...params})
                .then(response => response.results)
        } else if (SettingsService.isEnabled('company', 'vacation_colleague')) {
            if (UsersService.hasSomeCredential(user, ['vacation_approve'], ['subordinates'])) {
                promise = EmployeesService.getInstance()
                    .colleaguesAndSubordinatesChoiceList(presenter, currentEmployee.uuid, {filters, ...params}, true)
                    .then(response => response.results)
            } else {
                promise = EmployeesService.getInstance()
                    .colleaguesChoiceList(presenter, currentEmployee.uuid, {filters, ...params}, true)
                    .then(response => response.results)
            }
        } else if (UsersService.hasSomeCredential(user, ['vacation_approve'], ['subordinates'])) {
            promise = EmployeesService.getInstance()
                .workloadPlanSubordinatesChoiceList(presenter, currentEmployee, {filters, ...params} as IRestServiceOptions, true).then(response => response.results)
        } else if (currentEmployee && currentEmployee.workloadPlanGranterApprovals.length) {
            promise = EmployeesService.getInstance().workloadPlanSubordinatesChoiceList('default', currentEmployee, {filters, ...params}, true)
                .then(response => response.results)
        } else if (currentEmployee && currentEmployee.workloadSubordinates.length) {
            promise = EmployeesService.getInstance().choiceList('default', {
                filters: {
                    ...filters,
                    custom: {type: API_FILTER_TYPE.IN, field: 'uuid', value: [...currentEmployee.workloadSubordinates, currentEmployee.uuid]}
                }, ...params
            })
                .then(response => response.results)
        }  else if (currentEmployee) {
            promise = Promise.resolve({[currentEmployee.uuid]: PresenterBuilder.buildFromServer(presenter, currentEmployee)})
        } else {
            promise = Promise.resolve({} as IRestServiceChoiceList)
        }

        return promise.then((choices: IRestServiceChoiceList) => {
            return parseToArray(choices)
        })
    },
    getByView: (user, view, defaultChoices = [], presenter = 'default') => {
        if (view === 'compare') {
            if (!UsersService.hasSomeCredential(user, ['vacation', 'vacation_read'])
                && UsersService.hasCredential(user, 'vacation_compare')) {
                return EmployeesService.getInstance().choiceList(presenter, {filters})
                    .then(response => {
                        return parseToArray(response.results)
                    })
            }
        }

        if (view === 'calendar') {
            return Promise.resolve(defaultChoices)
        }

        return Promise.resolve(defaultChoices)
    }
}

export default PlanEmployeeChoicesService