import React from "react";
import {Spin, Typography} from "antd";
import IReportWidgetProps from "../../../../../model/interface/report/IReportWidgetProps";
import IReportWidgetEmployeeLookup from "../../../../../model/interface/report/IReportWidgetEmployeeLookup";
import ResourcePicker from "../../../../shared/pickers/ResourcePicker";
import EmployeesService from "../../../../../model/service/company/EmployeesService";
import {API_FILTER_TYPE} from "../../../../../model/constants/ApiConstant";
import Modal from "../../../../shared/modal/Modal";
import EmployeeProfile from "../../../company/employees/profile/EmployeeProfile";
import IEmployee from "../../../../../model/interface/company/IEmployee";


interface IState {
    selected?: IEmployee,
    loading?: boolean
}

class EmployeeLookupWidget extends React.Component<IReportWidgetProps<IReportWidgetEmployeeLookup>, IState> {

    constructor(props: IReportWidgetProps<IReportWidgetEmployeeLookup>, context: any) {
        super(props, context);
        this.state = {}
    }

    onSelect = (uuid?: string) => {
        this.setState({loading: true})
        uuid ? EmployeesService.getInstance().resourceRetrieve(uuid).then(resource => {
            this.setState({loading: false, selected: resource})
        }) : this.setState({selected: undefined, loading: false})
    }

    render() {
        const {selected, loading} = this.state
        const {match, history, options} = this.props
        const {presenter, label, onlyActive} = options
        let {bordered} = options
        if(typeof bordered == 'undefined') {
            bordered = true
        }
        return (
            <div className={bordered ? "rounded border overflow-hidden bg-theme p-3" : ""}>
                {(loading || selected) &&
                    <Modal width={1000} bodyStyle={{padding: 0}} visible={true} footer={false}
                           onCancel={() => this.onSelect()} closable={false}>
                        <Spin spinning={loading}>
                            {selected ?
                                <EmployeeProfile resource={selected} match={match} history={history}
                                                 standalone={false}/> : <div className={'p-5'}/>}
                        </Spin>
                    </Modal>}
                <Typography.Title level={4}>{label}</Typography.Title>
                <ResourcePicker fetchChoices={() => EmployeesService.getInstance()
                    .choiceList(presenter || 'default', {
                        filters: onlyActive ? {
                            active: {
                                field: 'active',
                                type: API_FILTER_TYPE.EQUAL,
                                value: true
                            }
                        } : {}
                    })} onChange={uuid => this.onSelect(uuid as string | undefined)} showSearch={true} clearSearch={false}/>
            </div>
        );
    }
}

export default EmployeeLookupWidget