import fetch from 'model/auth/ApiAuth'
import Utils from "../../../utils";
import {AxiosPromise} from "axios";

interface ISignUpService {
    signup: (formData:any) => AxiosPromise<void>
}

const SignUpService: ISignUpService = {
    signup: (formData:any) => {
        return fetch({
            url: '/signup',
            method: 'post',
            data: Utils.convertArrayToFormData(formData),
            headers: {
                'public-request': 'true'
            }
        })
    },
}

export default SignUpService