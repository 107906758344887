import _ from 'underscore'
import {RootStateOrAny} from "react-redux";
import IContentType from "../../model/interface/dataStorage/IContentType";

export const findAll = (store: RootStateOrAny): IContentType[] => store.setup.contentTypes

export const findBy = (store: RootStateOrAny, property: string, value: any): IContentType[] => _.where(findAll(store), {[property]: value})

export const findOneBy = (store: RootStateOrAny, property: string, value: any): IContentType => _.findWhere(findAll(store), {[property]: value})!
export const findOneOrNullBy = (store: RootStateOrAny, property: string, value: any): IContentType | undefined => _.findWhere(findAll(store), {[property]: value})


export const findByExtensions = (store: RootStateOrAny, extensions: string[]): IContentType[] => {
    return findAll(store).filter(c => extensions.every(e => c.extensions.find(cE => cE.type === e)))
}

export default {
    findAll,
    findBy,
    findOneBy,
    findOneOrNullBy,
    findByExtensions
}