import {all, fork, put, takeEvery} from 'redux-saga/effects';
import {AUTH_TOKEN, DISABLE_MASQUERADE, MASQUERADE, SIGNOUT, STORE_TOKEN,} from '../constants/Auth';
import {load, startLoading, update} from "../actions/Setup";
import Utils from "../../utils";
import {Action} from "redux";
import {reload as notificationsReload} from "../actions/Notifications";

interface AuthTokenAction extends Action {
    token: string
}

interface AuthMasqueradeAction extends Action {
    masquerade: string
}

export function* storeToken() {
    yield takeEvery<AuthTokenAction>(STORE_TOKEN, function (payload) {
        try {
            localStorage.setItem(AUTH_TOKEN, payload.token);
        } catch (e) {
            console.error('Unable to write to local storage', e)
        }
    })
}

export function* signOut() {
    yield takeEvery(SIGNOUT, function* () {
        localStorage.clear()
        yield put(update({loaded: false}));
    });
}

export function* masquerade() {
    yield takeEvery<AuthMasqueradeAction>(MASQUERADE, function* (payload) {
        try {
            yield put(startLoading());
            localStorage.setItem(MASQUERADE, payload.masquerade);
            Utils.cleanStorage(['setup', 'user-setup', 'menu'])
            yield put(load())
            yield put(notificationsReload())
        } catch (e) {
            console.error('Unable to write to local storage', e)
        }
    })
}

export function* disableMasquerade() {
    yield takeEvery(DISABLE_MASQUERADE, function* () {
        try {
            yield put(startLoading());
            Utils.cleanStorage(['setup','user-setup', 'menu', MASQUERADE])
            yield put(load())
            yield put(notificationsReload())
        } catch (e) {
            console.error('Unable to write to local storage', e)
        }
    })
}

export default function* rootSaga() {
    yield all([
        fork(storeToken),
        fork(signOut),
        fork(masquerade),
        fork(disableMasquerade),
    ]);
}
