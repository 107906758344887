import {connect} from "react-redux";
import React from "react";
import IMessage from "../../../../../model/interface/ui/IMessage";
import UserLabel from "../../../security/UserLabel";
import UserAvatar from "../../../security/UserAvatar";
import {Col, Row, Tag} from "antd";

interface IProps {
    resource: IMessage
}

interface IState {

}

class MessageShow extends React.Component<IProps, IState> {
    state = {}

    render() {
        const {resource} = this.props
        return (
            <>
                <div className={"d-flex align-items-center mb-3"}>
                    {resource.sender && (
                        <div className={"mr-2"}>
                            <UserAvatar size={64} user={resource.sender}/>
                        </div>
                    )}
                    <div>
                        {resource.sender && (
                            <div className={"font-weight-semibold"}>
                                <UserLabel user={resource.sender} showAvatar={false}/>
                            </div>
                        )}
                        <Row className={"text-muted"} gutter={[0, 6]}>
                            <Col className={"font-weight-semibold mr-2 d-flex align-self-center"}>Příjemci:</Col>
                            {resource.recipients.map(recipient => (
                                <Col>
                                    <Tag color={'default'}>
                                        <UserLabel user={recipient} showAvatar={false}/>
                                    </Tag>
                                </Col>
                            ))}
                        </Row>
                    </div>
                </div>
                <div>
                    <div className={"font-weight-semibold font-size-lg mb-2"}>{resource.subject}</div>
                    <div>
                        <div dangerouslySetInnerHTML={{__html: resource.body}}/>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = () => {
}

export default connect(mapStateToProps)(MessageShow)