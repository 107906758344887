import React, {RefObject} from 'react'
import {Form as FormControl, FormInstance} from 'antd';
import IForm from "../../../model/interface/form/IForm";
import FormFieldType from "../configuration/form/FormElement/formField/FormFieldType";
import FormEditor from "../configuration/form/FormEditor";
import IBaseProps from "../../../model/interface/IBaseProps";
import FormElements from "./FormElements";
import {connect, RootStateOrAny} from "react-redux";
import selectors from "../../../redux/selectors";
import IContentType from "../../../model/interface/dataStorage/IContentType";
import FormEditButton from "../configuration/form/FormEditButton";
import ReportWidgetType from "../configuration/report/widget/ReportWidgetType";
import {IActionResult} from "../../../model/service/dataStorage/ActionsService";
import IRestResource from "../../../model/interface/api/IRestResource";
import FormElementType from "../configuration/form/FormElement/FormElementType";
import DataStorageHelper from "../../../utils/DataStorageHelper";

interface IState {
    formRef: RefObject<FormInstance>
    loading: boolean
}

interface IProps extends IBaseProps {
    form: IForm,
    values: { [name: string]: any }
    onSubmit?: (values: any) => void
    onValuesChange?: (values: any) => void
    findContentTypeByUuid: (uuid: string) => IContentType
    onActionStart?: (values: any) => Promise<void>
    onActionEnd?: (result?: IActionResult) => Promise<void>
    formRef?: RefObject<FormInstance>
}

class Form extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            formRef: this.props.formRef || React.createRef(),
            loading: true
        };
    }

    confirm = () => {

    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (Object.keys(prevProps.values).length !== Object.keys(this.props.values).length) {
            this.state.formRef.current?.resetFields()
        }
    }

    getContentType = (uuid?: string | null) => {
        const {form, findContentTypeByUuid} = this.props
        return findContentTypeByUuid(uuid || form.contentType)
    }

    onChange = (values: any) => {
        const {onValuesChange} = this.props
        this.forceUpdate(() => onValuesChange?.(values))
    }

    onActionFinish = (result?: IActionResult) => {
        const {onActionEnd} = this.props
        return onActionEnd?.(result).then(() => this.state.formRef.current?.resetFields()) || Promise.resolve()
    }

    onActionStart = () => {
        const {onActionStart} = this.props
        return onActionStart?.(this.state.formRef.current?.getFieldsValue()) || Promise.resolve(this.state.formRef.current?.getFieldsValue())
    }

    static buildInitialValues(form: IForm, values: IRestResource, contentType: IContentType) {
        const structure = FormEditor.widgetsToFormStructure(form.widgets, contentType);
        let initialValues = {} as { [name: string]: any }
        Object.keys(structure).forEach((key) => {
            const fieldNode = structure[key]
            if (typeof fieldNode.field !== 'undefined' && values
                && (fieldNode.options?.initialValue || values[fieldNode.field.name])
            ) {
                const value = values[fieldNode.field.name] ?
                    values[fieldNode.field.name] : fieldNode.options?.initialValue
                initialValues[fieldNode.field.name] = FormFieldType.formatToForm(fieldNode.options.type, value)
            }
            if (structure[key].type === ReportWidgetType.COMMENT && values.hasOwnProperty('thread')) {
                initialValues['thread'] = values['thread'] ? values['thread'].id : null
            }
        })
        return initialValues
    }


    render() {
        const {formRef} = this.state
        const {values, form, onSubmit, history, match} = this.props
        const initialValues = Form.buildInitialValues(form, values, this.getContentType())

        return (
            <div>
                {this.props.form &&
                    <FormEditButton resource={form} history={history} match={match}/>}
                <FormControl layout={"vertical"} ref={formRef} name={'form_' + form.id}
                             initialValues={initialValues} onValuesChange={this.onChange} onFinish={onSubmit}>
                    <FormElements onActionStart={this.onActionStart} onActionFinish={this.onActionFinish}
                                  contentType={this.getContentType()} match={match}
                                  history={history} form={form} values={values} formRef={formRef}/>
                </FormControl>
            </div>
        );
    }
}

const mapStateToProps = (state: RootStateOrAny) => {

    return {
        findContentTypeByUuid: (uuid: string) => selectors.contentTypes.findOneBy(state, 'uuid', uuid)
    }
}

export default connect(mapStateToProps)(Form)