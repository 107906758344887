import axios, {AxiosRequestConfig, AxiosResponse} from 'axios'
import {env} from 'configs/EnvironmentConfig'
import history from '../../history'
import {AUTH_TOKEN, MASQUERADE} from 'redux/constants/Auth'
import {message, notification} from 'antd';
import qs from 'qs';
import React from "react";
import ReactDOM from "react-dom";
import ErrorModal from "../../components/app/error/ErrorModal";
import Utils from "../../utils";

const timeoutValue = process.env.REACT_APP_E_TAG_CACHED_FETCH_INTERCEPTOR_TIMEOUT;
const timeout = timeoutValue ? parseInt(timeoutValue, 10) : 60000;

const service = axios.create({
    baseURL: env.API_ENDPOINT_URL,
    timeout: timeout
})

// Config
const ENTRY_ROUTE = '/auth/login'
export const NO_ACCESS_ROUTE = '/app/no-access'
const TOKEN_PAYLOAD_KEY = 'token'
const MASQUERADE_PAYLOAD_KEY = 'masquerade'
const PUBLIC_REQUEST_KEY = 'public-request'

//

interface IRequest extends AxiosRequestConfig {
    cacheKey?: string,
    cacheItem?: string
}

interface IResponse extends AxiosResponse {
    config: IRequest
}

// API Request interceptor
service.interceptors.request.use((request: IRequest) => {
    const token = localStorage.getItem(AUTH_TOKEN)
    const masquerade = localStorage.getItem(MASQUERADE)
    const cacheKey = request['cacheKey']

    if (cacheKey) {
        try {
            const cacheRaw = localStorage.getItem(cacheKey)
            const cacheItem = cacheRaw && JSON.parse(cacheRaw)
            if (cacheItem) {
                if (cacheItem.hasOwnProperty('ETag')) {
                    request.headers['If-None-Match'] = cacheItem['ETag']
                    request['cacheItem'] = cacheItem
                }
            }
        } catch (e) {
            console.error('CACHE get', e)
        }
    }

    if (token) {
        request.headers[TOKEN_PAYLOAD_KEY] = token
    }
    if (masquerade) {
        request.headers[MASQUERADE_PAYLOAD_KEY] = masquerade
    }

    if (!token && !request.headers[PUBLIC_REQUEST_KEY]) {
        history.push(ENTRY_ROUTE)
        window.location.reload();
    }
    return request
}, error => {
    notification.error({
        message: 'Error'
    })
    return Promise.reject(error)
})

service.interceptors.request.use(config => {
    // window.console.log(config);


    config.paramsSerializer = params => {
        // Qs is already included in the Axios package
        return qs.stringify(params, {
            arrayFormat: "brackets",
            encode: false
        }).replace(/#/g, '%23')
    };

    return config;
});

// API response interceptor
service.interceptors.response.use((response: IResponse) => {
    /**
     * Workaround because 304 clear all headers incl. CORS headers
     * @see https://bz.apache.org/bugzilla/show_bug.cgi?id=51223
     */
    const status = response.data.status || response.status
    if (status === 304
        || (response.status === 200 && response.data === '304 Not Modified')) {
        return response.config.cacheItem
    }
    if (status === 401) {
        // TODO
        message.info(<>Přihlášení vypršelo, prosím přihlašte se znovu</>)
        localStorage.removeItem('auth_token')
        window.location.reload()
        return Promise.reject(response)
    }

    if (response.config.cacheKey) {
        // console.log('CACHE set', response.config.cacheKey, response.data)
        try {
            localStorage.setItem(response.config.cacheKey, JSON.stringify(response.data))
        } catch (e) {
            Utils.cleanStorage()
            // localStorage.setItem(response.config.cacheKey, JSON.stringify(response.data))
        }
    }

    return detectFileResponse(response)
}, (error) => {

    let message = ''
    if (error.response) {
        // Remove token and redirect
        // if (error.response.status === 403) {
        if (error.response.status === 400) {
            message = 'Invalid request'
        }

        if (error.response.status === 403) {
            history.push(NO_ACCESS_ROUTE)

            return Promise.reject(error)
        }

        if (error.response.status === 404) {
            message = 'Not Found'
        }

        if (error.response.status === 406) {
            return Promise.reject(error.response)
        }

        if (error.response.status === 500) {
            message = 'Internal Server Error'
        }

        if (error.response.status === 508) {
            message = 'Time Out'
        }

    } else {
        message = 'No response'
    }

    if (['development', 'dev'].includes(process.env.REACT_APP_ENV as string)) {
        notification.error({message: message})
    } else if (error.config.method !== 'post' || !error.config.url.includes('/logs')) {
        const mountNode = document.body.appendChild(document.createElement("DIV"))
        ReactDOM.render(<ErrorModal error={error}/>, mountNode)
    }

    return Promise.reject(error);
});

export const detectFileResponse = (response: IResponse) => {
    let disposition = response.headers['Content-Disposition'] || response.headers['content-disposition']
    let fileName = ''
    if (response.data instanceof Blob) {
        if (disposition && disposition.indexOf('attachment') !== -1) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
                fileName = matches[1].replace(/['"]/g, '');
            }
            return {data: response.data, fileName}
        }
    }

    return response.data
}

export default service