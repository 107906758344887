import {motion} from 'framer-motion';
import React, {Component} from 'react';

interface IProps {
    collapsed?: boolean
    containerClassName?: string
    headerClassName?: string
    bodyClassName?: string
    header?: (collapsed: boolean, triggerCollapse: (collapse?: boolean) => void) => React.ReactElement
    delay?: number
}

interface IState {
    collapsed: boolean
}

class Collapse extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            collapsed: props.collapsed === undefined ? true : props.collapsed
        }
    }

    componentDidUpdate(prevProps: IProps) {
        if (prevProps.collapsed !== this.props.collapsed) {
            this.setCollapsed(this.props.collapsed)
        }
    }

    setCollapsed = (collapsed?: boolean) => {
        this.setState(state => ({collapsed: collapsed !== undefined ? collapsed : !state.collapsed}))
    }

    render() {
        const {header, children, headerClassName, containerClassName, bodyClassName, delay} = this.props
        const {collapsed} = this.state

        return <div className={containerClassName}>
            {header && (
                <div className={headerClassName}>
                    {header(collapsed, this.setCollapsed)}
                </div>
            )}
            <motion.div initial={{height: collapsed ? "0px" : "auto", display: collapsed ? "none" : "block"}}
                        transition={{duration: delay !== undefined ? delay : 0.5}}
                        className={'overflow-hidden ' + (bodyClassName || '')}
                        animate={{
                            height: collapsed ? "0px" : "auto",
                            transitionEnd: {display: collapsed ? "none" : "block"}
                        }}>
                {children}
            </motion.div>
        </div>
    }
}

export default Collapse
