import React, {RefObject} from "react";
import {Form, FormInstance, Switch} from "antd";
import IContainerOptions from "../../../../../../model/interface/form/elementOptions/IContainerOptions";
import IFormStructureNode from "../../../../../../model/interface/form/IFormStructureNode";
import ConditionEditor from "./ConditionEditor";
import WidgetContainerEditor from "../../../widget/optionEditor/ContainerEditor";
import Title from "antd/es/typography/Title";
import IField from "../../../../../../model/interface/dataStorage/IField";

interface IProps {
    options: IContainerOptions,
    formRef: RefObject<FormInstance>
    fieldNodes: IFormStructureNode[]
    fields: IField[]
}
class ContainerEditor extends React.Component<IProps> {

    render() {
        const {fields, fieldNodes, options} = this.props

        return (
            <WidgetContainerEditor {...this.props}>
                <Form.Item name={'collapsable'} label={'Možnost skrýt'} valuePropName={'checked'}>
                    <Switch/>
                </Form.Item>
                {options.collapsable && (
                    <div>
                        <Title level={4}>Collapsable conditions</Title>
                        <Form.Item name={'conditions'} noStyle={true}>
                            <ConditionEditor fields={fields} fieldNodes={fieldNodes}/>
                        </Form.Item>
                    </div>

                )}
            </WidgetContainerEditor>
        )
    }
}

export default ContainerEditor