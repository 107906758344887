import andSk from 'antd/es/locale/sk_SK';
import csMsg from "../locales/cs_CZ.json";

const SkLang = {
  antd: andSk,
  locale: 'sk-SK',
  messages: {
    ...csMsg
  },
};
export default SkLang;
