import {Form, Slider, Switch} from 'antd';
import React from "react";
import {IFieldOptionsEditorProps} from "../FieldOptionsEditor";

export interface IFieldEmojiOptions {
    emojiSize?: number,
    emojiOriginal?: boolean
}

interface IProps extends IFieldOptionsEditorProps {
    noOriginal?: boolean
}

class FieldEmojiOptionsEditor extends React.Component<IProps> {

    render() {
        const {noOriginal} = this.props
        return (
            <div>
                <Form.Item label={"Size"} name={this.props.buildFieldName('emojiSize')} initialValue={25}>
                    <Slider min={10} marks={{25: 'default'}} max={500}/>
                </Form.Item>
                {!noOriginal && (
                    <Form.Item help={'Original loads image from url, so quality is not as good when increasing size!'}
                               label={"Original"} name={this.props.buildFieldName('emojiOriginal')} initialValue={true}
                               valuePropName={'checked'}>
                        <Switch/>
                    </Form.Item>
                )}
            </div>
        )
    }
}


export default FieldEmojiOptionsEditor