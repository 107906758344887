import IUser from "../../../model/interface/security/IUser";
import React from "react";
import {AvatarSize} from "antd/lib/avatar/SizeContext";
import AvatarWithDefault from "./AvatarWithDefault";

interface IProps {
    user: IUser
    size?: AvatarSize
    title?: string,
    className?: string
}

interface IState {
    size: AvatarSize
}

class UserAvatar extends React.Component<IProps, IState> {
    state = {
        size: this.props.size || "small"
    }
    render() {
        const {user} = this.props
        const {size} = this.state
        const {name, surname, username, email, employees} = user
        return (
            <AvatarWithDefault {...this.props} src={user && (user.avatar?.thumbnailUrl || employees[0]?.photo?.thumbnailUrl)}
                               size={size} title={name && surname ? name + ' ' + surname : (employees[0]?.fullName || username || email)}/>
        )
    }
}

export default UserAvatar