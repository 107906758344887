import RestService from "model/service/dataStorage/RestService";
import IResult from "../../interface/import/source/IResult";
import ISource from "../../interface/import/ISource";

interface IRestImportService {
    import(id: string|number, payload: {route: any}): Promise<IResult>,
    reset(id: string|number): Promise<ISource>,
    retrieveDataForMapping(id: string|number): Promise<Array<any>>,
    retrievePreviewData(id: string|number): Promise<Array<any>>,
}

const ImportService_COLLECTION = 'import'
const ImportService_COLLECTION_MAPPING = 'import-mapping-data'
const ImportService_COLLECTION_PREVIEW = 'import-preview-data'
const ImportService_COLLECTION_RESET = 'import-reset'

const ImportService: IRestImportService = {
    import: function (id: number, payload: any) {
        return RestService.resourceUpdate(ImportService_COLLECTION, id, payload, {cache: false, fileUpload: true}) as Promise<IResult>
    },
    reset: function (id: number) {
        return RestService.resourceUpdate(ImportService_COLLECTION_RESET, id, {}, {cache: false}) as Promise<ISource>
    },
    retrieveDataForMapping: function (id: number | string) {
        return RestService.resourceRetrieve(ImportService_COLLECTION_MAPPING, id, {cache: true}) as Promise<Array<any>>
    },
    retrievePreviewData: function (id: number | string) {
        return RestService.resourceRetrieve(ImportService_COLLECTION_PREVIEW, id) as Promise<Array<any>>
    }
}

export default ImportService