import React from "react";
import WidgetChildren from "./WidgetChildren";
import IWidgetProps from "../../../../model/interface/widget/IWidgetProps";


class TableCellWidget extends React.Component<IWidgetProps> {
    render() {
        return (
           <div style={{minWidth: 20, minHeight: 20}} className={'h-100'}>
               <WidgetChildren {...this.props}/>
           </div>
        );
    }
}

export default TableCellWidget