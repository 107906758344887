import React, {RefObject} from "react";
import {connect, RootStateOrAny} from "react-redux";
import IBaseProps from "../../../../../model/interface/IBaseProps";
import IContentType from "../../../../../model/interface/dataStorage/IContentType";
import INotification from "../../../../../model/interface/dataStorage/INotification";
import {Divider, Empty, Form, FormInstance, Input, InputNumber, Modal, Row, Select, Table, Typography} from "antd";
import IAction from "../../../../../model/interface/dataStorage/IAction";
import Utils from "../../../../../utils";
import IField from "../../../../../model/interface/dataStorage/IField";
import selectors from "../../../../../redux/selectors";
import IRepositoryService from "../../../../../model/interface/IRepositoryService";
import RoutePicker from "../../../../shared/pickers/RoutePicker";
import ContentTypePresenterMentions from "../presenter/ContentTypePresenterMentions";
import {IRestServiceChoiceList} from "../../../../../model/interface/api/IRestServiceChoiceListResponse";
import INotificationTrigger from "../../../../../model/interface/dataStorage/INotificationTrigger";
import Button from "../../../../shared/button/Button";
import {DeleteOutlined, PlusOutlined, SaveOutlined} from "@ant-design/icons";
import ScrollContainer from "../../../../shared/scrollContainer/ScrollContainer";
import ActionPicker from "../../../../shared/pickers/ActionPicker";
import {ColumnsType} from "antd/es/table";
import LocaleText from "../../../settings/dictionary/LocaleText";

interface IProps extends IBaseProps {
    contentType: IContentType
    onFinish: (notification?: INotification) => void
    notification?: INotification
    findServiceByClassName: (className: string) => IRepositoryService
    employees: IRestServiceChoiceList
    users: IRestServiceChoiceList
}

interface ITrigger {
    uuid: string
    field: string | undefined
    type: string
    weeks: number
    days: number
    hours: number
}

interface IState {
    formRef: RefObject<FormInstance>
    notification: INotification
    triggers: ITrigger[],
    options: { label: string, options: { value: string, label: string }[], type: string }[]
    newEmail?: string
}

const WEEK_MINUTES = 40320
const DAY_MINUTES = 1440
const HOUR_MINUTES = 60

const DELAY_AFTER = 'after'
const DELAY_BEFORE = 'before'

class NotificationModal extends React.Component<IProps, IState> {


    constructor(props: IProps) {
        super(props);
        this.state = {
            formRef: React.createRef() as RefObject<FormInstance>,
            notification: this.props.notification || {
                actions: [],
                recipients: [],
                triggers: [],
                route: undefined,
                uuid: Utils.uuid(),
                title: 'Upozornění',
                text: 'Upozornění na ...'
            } as INotification,
            options: [
                {
                    label: 'Dle vazby',
                    type: 'field',
                    options: this.props.contentType.fields
                            .filter((field: IField) => (field.mode === 'relation' && field.targetEntity === 'App\\Company\\Entity\\Employee'))
                            .map((field: IField) => ({value: 'field:' + field.uuid, label: field.label || field.name}))
                        || {disabled: true, title: 'Žádné vlastnosti', value: 0}
                },
                {
                    label: 'Dle zaměstnance',
                    type: 'employee',
                    options: Object.entries(this.props.employees).map(([uuid, label]) => ({
                        value: 'employee:' + uuid,
                        label: label
                    }))
                },
                {
                    label: 'Dle uživatele',
                    type: 'user',
                    options: Object.entries(this.props.users).map(([uuid, label]) => ({
                        value: 'user:' + uuid,
                        label: label
                    }))
                },
                {
                    label: 'Email',
                    type: 'email',
                    options: this.props.notification?.recipients.filter(r => r.email).map((r) => ({
                        value: 'email:' + r.email,
                        label: r.email || ''
                    })) || []
                }
            ],
            triggers: this.props.notification ? this.buildTriggers(this.props.notification!.triggers) : []
        }
    }

    onNameChange = (email: string) => {
       this.setState({newEmail: email})
    };

    addItem = () => {
        const {newEmail} = this.state;
        newEmail && this.setState(state => ({
            options: [...state.options.filter(o => o.type !== 'email'), {
                ...(state.options.find(o => o.type === 'email') || {
                    label: 'Email',
                    options: [],
                    type: 'email'
                }),
                options: [...state.options.find(o => o.type === 'email')?.options || [], {value: 'email:' + newEmail, label: newEmail}]
            }],
            notification: {...state.notification, recipients: [...state.notification.recipients, {uuid: Utils.uuid(), type: 'email', email: newEmail}]}
        }), () => this.state.formRef.current?.resetFields(['recipients']));
    };

    buildOptions() {
        return
    }

    toggle(action: IAction) {
        let notification = this.state.notification
        const index = notification.actions.indexOf(action.uuid)
        if (index >= 0) {
            notification.actions.splice(index, 1)
        } else {
            notification.actions.push(action.uuid)
        }
        this.setState({notification})
    }

    submit() {
        this.state.formRef.current?.validateFields().then(values => {
            let notification = {
                ...this.state.notification,
                ...values,
                recipients: values.recipients.map((recipient: string) => {
                    let [type, identification] = recipient.split(':')
                    return {
                        type,
                        [type]: identification
                    }
                }),
                triggers: this.state.triggers.map(trigger => ({
                    field: trigger.field,
                    uuid: trigger.uuid,
                    delay: (trigger.type === DELAY_BEFORE ? -1 : 1) * (trigger.weeks * WEEK_MINUTES + trigger.days * DAY_MINUTES + trigger.hours * HOUR_MINUTES)
                } as INotificationTrigger))
            }
            this.props.onFinish(notification)
        })
    }

    render() {
        const {contentType} = this.props
        const {formRef, notification, options, newEmail} = this.state
        const formData = {
            ...notification,
            recipients: notification.recipients.map(recipient => {
                switch (recipient.type) {
                    case('employee'):
                        return 'employee:' + recipient.employee
                    case('field'):
                        return 'field:' + recipient.field
                    case('user'):
                        return 'user:' + recipient.user
                    case('email'):
                        return 'email:' + recipient.email
                    default:
                        return undefined
                }
            })
        }
        return (
            <Modal bodyStyle={{padding: 0}} title={'Notifikace'} visible={true} onCancel={() => this.props.onFinish()}
                   width={700} okButtonProps={{icon: <SaveOutlined/>}} okText={'Uložit'} onOk={() => this.submit()}
                   cancelButtonProps={{hidden: true}}>
                <ScrollContainer style={{maxHeight: '60vh'}} visibility={"visible"} className={'p-4'}>
                    <Form initialValues={formData} ref={formRef}>
                        <Form.Item name={"title"} label={"Titulek"} rules={[{required: true}, {pattern: /[a-z_]/}]}>
                            <ContentTypePresenterMentions contentType={contentType}/>
                        </Form.Item>
                        <Form.Item name={"text"} label={"Text"} rules={[{required: true}, {pattern: /[a-z_]/}]}>
                            <ContentTypePresenterMentions rows={3} contentType={contentType}/>
                        </Form.Item>
                        <Form.Item name={"recipients"} label={"Příjemci"}>
                            <Select
                                showSearch
                                filterOption={(input, option) =>
                                    Utils.stringContains(option?.label, input)
                                }
                                dropdownRender={menu => (
                                    <div>
                                        {menu}
                                        <Divider style={{margin: '4px 0'}}/>
                                        <Row wrap={false} justify={'space-between'} align={'middle'} style={{whiteSpace: 'nowrap'}} className={'p-2'}>
                                            <LocaleText code={'content-type.notification.recipient.add.email'} fallback={'Přidat email'}/>
                                            <Input type={'email'} className={'ml-2'} onPressEnter={this.addItem} style={{flex: 'auto'}} value={newEmail} onChange={(e) => this.onNameChange(e.target.value)}/>
                                            <a
                                                style={{
                                                    flex: 'none',
                                                    padding: '8px',
                                                    display: 'block',
                                                    cursor: 'pointer'
                                                }}
                                                onClick={this.addItem}
                                            >
                                                <PlusOutlined/>
                                            </a>
                                        </Row>
                                    </div>
                                )}
                                mode={"multiple"} options={options}/>
                        </Form.Item>
                        <Form.Item name={"route"} label={"Odkaz"}>
                            <RoutePicker canAdd={false}/>
                        </Form.Item>
                        <Form.Item name={"actions"} label={"Akce"}>
                            <ActionPicker contentTypeUuid={contentType.uuid} multiple={true}/>
                        </Form.Item>
                        <div className="d-flex align-items-center justify-content-between">
                            <Typography.Title level={3}>Spouštěče</Typography.Title>
                            <Button size={"small"} type={"info"} onClick={() => this.addTrigger()}
                                    icon={<PlusOutlined/>}/>
                        </div>
                        {this.buildFieldOptions().length > 0 ? (
                            <Table
                                size={"small"}
                                dataSource={[...this.state.triggers]}
                                columns={this.buildTriggerColumns()}
                            />
                        ) : (
                            <Empty
                                description={this.props.contentType.label + " nemá žádné datové pole pro nastavení spouštěčů"}></Empty>
                        )}
                    </Form>
                </ScrollContainer>

            </Modal>
        )
    }

    addTrigger() {
        const triggers = this.state.triggers
        triggers.push({
            uuid: Utils.uuid(),
            type: DELAY_BEFORE,
            weeks: 0,
            days: 1,
            hours: 0,
            field: undefined
        })
        this.setState({
            triggers
        })
    }

    removeTrigger(uuid: string) {
        const triggers = this.state.triggers
        const index = Utils.findIndex(triggers, {uuid})
        if (index < 0) {
            throw new Error('Cannot delete item ' + uuid + ', index was not found');
        }
        triggers.splice(index, 1)
        this.setState({triggers})
    }

    editTrigger(uuid: string, values: any) {
        const triggers = this.state.triggers
        const index = Utils.findIndex(triggers, {uuid})
        if (index < 0) {
            throw new Error('Cannot edit item ' + uuid + ', index was not found');
        }
        triggers[index] = {...triggers[index], ...values} as ITrigger
        console.log('edit trigger', uuid, values, triggers, index, {...triggers[index], ...values})
        this.setState({triggers})
    }

    buildTriggerColumns(): ColumnsType<ITrigger> {
        return [
            {
                title: 'Pole',
                dataIndex: 'field',
                key: 'field',
                render: (fieldUuid, row) => (
                    <Select options={this.buildFieldOptions()} value={fieldUuid} dropdownMatchSelectWidth={false}
                            onChange={(field: string) => this.editTrigger(row.uuid, {field})}/>)
            }, {
                title: 'Typ',
                dataIndex: 'type',
                render: (type, row) => (
                    <Select value={type} options={[
                        {label: 'Před', value: DELAY_BEFORE},
                        {label: 'Po', value: DELAY_AFTER}
                    ]} onChange={(type: string) => this.editTrigger(row.uuid, {type})}/>)
            }, {
                title: 'Týdnů',
                dataIndex: 'weeks',
                render: (value: number, row: ITrigger) => (
                    <InputNumber style={{width: 46}} value={value}
                                 onChange={(weeks: number) => this.editTrigger(row.uuid, {weeks})}/>)
            }, {
                title: 'Dní',
                dataIndex: 'days',
                render: (value: number, row: ITrigger) => (
                    <InputNumber style={{width: 46}} value={value}
                                 onChange={(days: number) => this.editTrigger(row.uuid, {days})}/>)
            }, {
                title: 'Hodin',
                dataIndex: 'hours',
                render: (value: number, row: ITrigger) => (
                    <InputNumber style={{width: 46}} value={value}
                                 onChange={(hours: number) => this.editTrigger(row.uuid, {hours})}/>)
            },
            {
                title: '',
                render: (row: ITrigger) => (
                    <Button size={"small"} type={"danger"} icon={<DeleteOutlined/>}
                            onClick={() => this.removeTrigger(row.uuid)}/>)
            }]
    }

    buildFieldOptions() {
        return this.props.contentType.fields
            .filter(field => field.type.toLowerCase().indexOf('date') >= 0)
            .map(field => ({label: field.label || field.name, value: field.uuid}))
    }

    buildTriggers(triggers: INotificationTrigger[]) {
        if (!triggers) {
            return []
        }
        return triggers.map(trigger => {
            let delay = Math.abs(trigger.delay)
            let weeks = Math.floor(delay / WEEK_MINUTES)
            delay = delay - weeks * WEEK_MINUTES
            let days = Math.floor(delay / DAY_MINUTES)
            delay = delay - days * DAY_MINUTES
            return {
                type: trigger.delay < 0 ? DELAY_BEFORE : DELAY_AFTER,
                field: trigger.field,
                uuid: trigger.uuid,
                weeks,
                days,
                hours: Math.floor(delay / HOUR_MINUTES),
            } as ITrigger
        })
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    return {
        findServiceByClassName: (className: string) => selectors.services.findOneByFullClassName(state, className)
    }
}

export default connect(mapStateToProps)(NotificationModal)