import React from "react";
import IUser from "../../../model/interface/security/IUser";
import {AvatarSize} from "antd/es/avatar/SizeContext";
import EmployeeLabel from "../company/employees/EmployeeLabel";
import UserLabel from "./UserLabel";

interface IProps {
    user: IUser
    showAvatar?: boolean
    avatarSize?: AvatarSize
}

class UserEmployeeLabel extends React.Component<IProps> {

    render() {
        const {user, avatarSize, showAvatar} = this.props
        return (
            <>
                {user.employees.length > 0 ? (
                    <EmployeeLabel employee={user.employees[0]}/>
                ) : (
                    <UserLabel avatarSize={avatarSize} user={user} showAvatar={showAvatar}/>
                )}
            </>
        )
    }
}

export default UserEmployeeLabel