import React from "react";
import {connect, RootStateOrAny} from 'react-redux'
import {QuestionCircleOutlined} from '@ant-design/icons';
import selectors from "../../../redux/selectors";
import IRoute from "../../../model/interface/dataStorage/IRoute";
import IBaseProps from "../../../model/interface/IBaseProps";
import ManualModal from "../../app/ui/ManualModal";
import Hotkey from "../../shared/hotkey/Hotkey";
import {message} from "antd";

interface IProps extends IBaseProps {
    findOneByUrl: (path: string) => IRoute | null
}

interface IState {
    modalVisible: boolean
}

class NavManual extends React.Component<IProps, IState> {

    constructor(props: Readonly<IProps> | IProps) {
        super(props);
        this.state = {
            modalVisible: false
        }
    }

    setModalVisible = () => {
        this.setState(state => ({modalVisible: !state.modalVisible}))
    }

    render() {

        const {modalVisible} = this.state
        const route = this.props.findOneByUrl(this.props.history.location.pathname)
        const manual = route ? route.manual : null
        const isManual = !!(manual && route?.manualActive)

        return (
            <>
                {manual && isManual && (
                    <ManualModal id={manual} visible={modalVisible} onFinish={this.setModalVisible}/>
                )}
                <Hotkey
                    helpPlacement={"bottom"}
                    help={isManual ? 'Zobrazit manuál' : 'Žádný manuál pro tuto stránku'}
                    trigger={isManual ? this.setModalVisible : () => message.info('Žádný manuál pro tuto stránku!').then()}
                    keys={['Alt', 'h']}>
                    <QuestionCircleOutlined
                        onClick={() => this.setModalVisible()}
                        disabled={!isManual}
                        className={'nav-icon ' + (isManual ? 'text-primary' : 'text-muted')}
                        style={{fontSize: 21}}
                    />
                </Hotkey>
            </>
        )
    }
}


const mapStateToProps = (state: RootStateOrAny) => {
    return {
        findOneByUrl: (path: string) => selectors.routes.findOneByUrlWithIdentifier(state, path)
    }
}

export default connect(mapStateToProps)(NavManual)
