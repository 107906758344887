import React from "react";
import WidgetChildren from "./WidgetChildren";
import IWidgetProps from "../../../../model/interface/widget/IWidgetProps";
import IWidgetPropsFunctions from "../../../../model/interface/widget/IWidgetPropsFunctions";
import IContainerOptions from "../../../../model/interface/widget/option/IContainerOptions";

class ContainerWidget extends React.Component<IWidgetProps<IWidgetPropsFunctions, IContainerOptions>> {
    render() {
        const {display, bordered, padding, margin, childrenMargin, background,minChildHeight} = this.props.options

        let finalDisplay = display || ''
        let childClass = 'mw-100'
        let className = 'flex-wrap'

        if (padding !== undefined) {
            className += ' p-' + padding
        }

        if (margin !== undefined){
            className += ' m-' + margin
        }

        if (childrenMargin !== undefined && !['d-block, d-flex'].includes(finalDisplay)){
            className += ' gap-' + childrenMargin
        } else {
            className += ' w-100'
        }

        if (bordered) {
            className += ' border rounded'
            if (padding === undefined) {
                className += ' p-3'
            }
        }

        if (background) {
            className += ' bg-theme'
        }

        if (minChildHeight) {
            className += ' min-height-' + minChildHeight
        }

        switch (display) {
            case 'box-6':
                childClass += ' min-w-16 w-300 mw-100'
                finalDisplay = 'd-flex'
                break
            case 'box-3':
                childClass +=  ' min-w-33 w-300 mw-100'
                finalDisplay = 'd-flex'
                break
            case 'box-2':
                childClass += ' min-w-50 w-300 mw-100'
                finalDisplay = 'd-flex'
                break
        }

        className += ' ' + finalDisplay

        return (
            <>
                <WidgetChildren {...this.props} childClassName={childClass} className={className}/>
            </>
        );
    }
}

export default ContainerWidget