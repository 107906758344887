import antdDeDE from 'antd/es/locale/de_DE';

const DeLang = {
  antd: antdDeDE,
  locale: 'de-DE',
  messages: {

  },
};
export default DeLang;
