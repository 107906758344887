import WidgetType from "../../widget/WidgetType";

class FormElementType extends WidgetType{
    static FIELD = 'field'
    static ACTION = 'action'
    static BUTTONS = 'buttons'
    static SUBMIT = 'submit'
    static COMMENT = 'comment'
    static ENTITY_TABLE = 'entityTable'
    static ENTITY_TABLE_COLUMN = 'entityTableColumn'

    static GROUP_ACTION = FormElementType.ACTION
    static GROUP_FIELD = FormElementType.FIELD
}

export default FormElementType