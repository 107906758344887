import React from 'react'
import LoginForm from "./LoginForm";
import ISettings from "../../model/interface/ui/ISettings";
import {RouteComponentProps} from "react-router-dom";

interface IProps extends RouteComponentProps<any> {
    settings: ISettings
}

interface IState {
}

class Login extends React.Component<IProps, IState> {
    render() {
        return (
            <LoginForm {...this.props}/>
        )
    }
}

export default Login
