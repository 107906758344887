import React from "react";
import {CloseOutlined, EditOutlined, PlusOutlined} from "@ant-design/icons";
import {Row, Tag, Tooltip} from "antd";
import {IContentTypeStepProps} from "./ContentTypeConfiguration";
import {ColumnProps} from "antd/es/table";
import IContentTypeScript, {
    ContentTypeScriptEvents
} from "../../../../model/interface/dataStorage/contentType/IContentTypeScript";
import DragSortingTable from "../../../shared/Table/DragSortingTable";
import ContentTypeScriptModal from "./script/ContentTypeScriptModal";
import {connect, RootStateOrAny} from "react-redux";
import IScript from "../../../../model/interface/dataStorage/IScript";
import Hotkey from "../../../shared/hotkey/Hotkey";
import Button from "components/shared/button/Button";

interface IState {
    showModal: boolean,
    script?: IContentTypeScript
}

interface IProps extends IContentTypeStepProps {
    scripts: IScript[]
}

class ContentTypeScripts extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            showModal: false
        }
    }

    showModal = (script?: IContentTypeScript) => {
        this.setState(state => ({showModal: !state.showModal, script}))
    }

    onFinish = (script?: IContentTypeScript) => {
        if (script) {
            let scripts = [...this.props.resource.scripts]
            const index = scripts.findIndex(value => value.uuid === script.uuid)
            if (index > -1) {
                scripts[index] = script
            } else {
                script.weight = scripts.length
                scripts.push(script)
            }
            this.props.onValuesChange({scripts})
        }
        this.setState({showModal: false, script: undefined})
    }

    onRemove(script: IContentTypeScript) {
        let scripts = [...this.props.resource.scripts].filter(value => value.uuid !== script.uuid)
        this.props.onValuesChange({scripts})
    }

    onSort = (data: IContentTypeScript[]) => {
        this.props.onValuesChange({scripts: data.map((row, index) => ({...row, weight: index}))})
    }


    render() {
        const presenterColumns: ColumnProps<IContentTypeScript>[] = [
            {
                title: 'ID',
                dataIndex: 'id'
            },
            {
                title: 'Script',
                dataIndex: 'script',
                render: (_, script) => (this.props.scripts.find(s => s.uuid === script.script)?.label)
            },
            {
                title: 'Udalosti',
                dataIndex: 'events',
                render: (_, script) => (
                    <Row>
                        {
                            script.events.map(event => {
                                const eventInfo = ContentTypeScriptEvents.find(e => e.value === event)
                                return eventInfo && <Tag color={eventInfo.color}>{eventInfo.label}</Tag>
                            })
                        }
                    </Row>
                )
            },
            {
                title: 'Váha',
                dataIndex: 'weight'
            },
            {
                title: <Row justify={"end"}>
                    <Hotkey help={"Přidat skript"} keys={["Alt", "p"]} trigger={() => this.showModal()}>
                        <Button type={"success"} onClick={() => this.showModal()} icon={<PlusOutlined/>}>
                            <u className={'pl-2'}>P</u>řidat
                        </Button>
                    </Hotkey>
                </Row>,
                key: 'actions',
                dataIndex: 'actions',
                render: (_, script) => (
                    <div className="text-right d-flex justify-content-end">
                        <Tooltip title={"Upravit"}>
                            <Button onClick={() => this.showModal(script)} type="link" className="mr-2"
                                    icon={<EditOutlined/>} size="small"/>
                        </Tooltip>
                        <Tooltip title={"Odstranit"}>
                            <Button onClick={() => this.onRemove(script)} danger type="link" className="mr-2"
                                    icon={<CloseOutlined/>} size="small"/>
                        </Tooltip>
                    </div>
                )
            }
        ]
        const {resource, history, match} = this.props
        const {showModal, script} = this.state
        const {scripts} = resource
        return (
            <>
                <DragSortingTable
                    onMove={this.onSort}
                    pagination={{pageSize: 10, showSizeChanger: true}}
                    columns={presenterColumns}
                    dataSource={scripts}
                    rowKey='id'
                />
                {showModal &&
                    <ContentTypeScriptModal scripts={this.props.scripts} contentType={resource} history={history} match={match}
                                            onFinish={this.onFinish}
                                            script={script}/>}
            </>
        )
    }
}

const mapStateToProps = (store: RootStateOrAny) => {
    return {
        scripts: store.setup.scripts,
    }
}

export default connect(mapStateToProps)(ContentTypeScripts)
