import React, {RefObject} from "react"
import {Col, Form, FormInstance, Input, message, Row, Spin, Tooltip, Upload} from "antd";
import {CloseOutlined, EditOutlined, RollbackOutlined, SaveOutlined, UploadOutlined} from "@ant-design/icons";
import IScript from "../../../../model/interface/dataStorage/IScript";
import ScriptsService from "../../../../model/service/dataStorage/ScriptsService";
import {connect, RootStateOrAny} from "react-redux";
import {ISetupState} from "../../../../redux/reducers/Setup";
import {Prism as SyntaxHighlighter} from "react-syntax-highlighter";
import {update} from "../../../../redux/actions/Setup";
import Button from "../../../shared/button/Button";
import Utils from "../../../../utils";
import Modal from "../../../shared/modal/Modal";
import {UploadFile} from "antd/es/upload/interface";

interface IState {
    formRef: RefObject<FormInstance>
    editMode: boolean
    modal?: boolean
    saving?: boolean
    resource?: IScript
    loading?: boolean,
    file?: UploadFile
}

interface IProps {
    onFinish?: (script?: IScript) => void
    update: (changes: any) => void
    scripts: IScript[]
    modal?: boolean
    updateSetup?: boolean
    identifier?: string | number | IScript
}

class ScriptForm extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            editMode: false,
            formRef: React.createRef()
        }
    }

    static defaultProps = {
        updateSetup: true
    }

    _isMounted = false;

    componentDidMount() {
        this._isMounted = true;
        this.load()
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (this.props.identifier !== prevProps.identifier) {
            this.load()
        }
    }

    load() {
        const {identifier} = this.props
        if (identifier) {
            this.setState({loading: true})
            return ScriptsService.resourceRetrieve(Utils.parseObjectToIdentifier(identifier)).then(resource => {
                this.setState({resource, loading: false})
            })
        }
        return this.setState({resource: undefined, loading: false})
    }

    save = () => {
        const {update, scripts, onFinish, updateSetup} = this.props
        const {resource, file} = this.state

        this.state.formRef.current?.validateFields().then((values) => {
            this.setState({saving: true})
            const data = {...values, file: file?.originFileObj, uuid: resource?.uuid || Utils.uuid()}
            const promise = resource?.id ?
                ScriptsService.resourceUpdate(resource.id, data) : ScriptsService.collectionCreate(data)
            promise.then(script => {
                message.success(`Script [${script.name}] byl úspěšně uložen.`).then()
                updateSetup && update({
                    scripts: [...scripts.filter(s => s.uuid !== script.uuid), {...script, fileContent: null}]
                })
                onFinish?.(script)
            }).finally(() => this.setState({saving: false}))
        })
    }

    changeEditMode = (value: boolean) => {
        this.setState({editMode: value})
    }

    render() {
        const {modal, onFinish, identifier} = this.props
        const {saving} = this.state

        const form = this.buildFrom()
        return modal ?
            <Modal fullScreenOption={true} visible={true} title={identifier ? 'Upravit script' : 'Nový skript'}
                   onCancel={() => onFinish?.()} width={'80vw'}
                   footer={[<Button key={'save'} loading={saving} type={"primary"}
                                    onClick={this.save}>Uložit</Button>]}>
                {form}
            </Modal> : form
    }

    setFile = (file?: UploadFile) => {
        this.setState({file})
    }

    buildFrom() {
        const {modal, onFinish} = this.props
        const {formRef, editMode, saving, resource, loading, file} = this.state

        return (
            <Spin spinning={loading}>
                {!loading && (
                    <Form ref={formRef} initialValues={{...resource}}>
                        <Form.Item
                            label={"Název"}
                            name={"label"}
                            rules={[{required: true, message: 'Vyplňte titulek'}]}
                        >
                            <Input/>
                        </Form.Item>
                        {!resource && <Form.Item
                            label={"Soubor"}
                            rules={[{required: !resource, message: 'Vyplňte soubor'}]}
                            // valuePropName="fileList" DO NOT UNCOMMENT, IT DOES NOT WORK, EVEN WHEN DOCS SAYS SO
                        >
                            <Upload
                                multiple={false}
                                onChange={info => this.setFile(info.fileList[0])}
                                fileList={file ? [file] : []}
                                beforeUpload={() => false}
                                onRemove={() => this.setFile()}
                                maxCount={1}
                            >
                                <Button>
                                    <UploadOutlined/> Select File
                                </Button>
                            </Upload>
                        </Form.Item>}
                        {resource && <Row className={'position-relative'}>
                            <Tooltip title={editMode ? 'Preview' : 'Edit'}>
                                <Button danger={editMode} icon={editMode ? <CloseOutlined/> : <EditOutlined/>}
                                        className={'position-absolute mr-2 mt-3'}
                                        onClick={() => this.changeEditMode(!editMode)}
                                        style={{top: 0, right: 0, zIndex: 1}}/>
                            </Tooltip>

                            {editMode && <Col sm={24}>
                                <Form.Item name={"fileContent"}>
                                    <Input.TextArea autoSize={true} size={"small"}/>
                                </Form.Item>
                            </Col>
                            }
                            {!editMode && <Col sm={24} className={'react-syntax-highlighter'}>
                                <SyntaxHighlighter language={'php'} showLineNumbers={true}>
                                    {resource.fileContent || ''}
                                </SyntaxHighlighter>
                            </Col>
                            }
                        </Row>}
                        {!modal && (<Row gutter={[6, 6]}>
                            <Col>
                                <Button loading={saving} type={"primary"} icon={<SaveOutlined/>} onClick={this.save}>
                                    Uložit
                                </Button>
                            </Col>
                            <Col>
                                <Button onClick={() => onFinish?.()} icon={<RollbackOutlined/>}>
                                    Zpět
                                </Button>
                            </Col>
                        </Row>)
                        }
                    </Form>
                )}
            </Spin>
        )
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    const {scripts} = state.setup as ISetupState
    return {
        scripts
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        update: (changes: any) => dispatch(update(changes))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScriptForm)
// for some reason mapStateToProps needs to be connected for reload to work