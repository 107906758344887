import React, {Component, RefObject} from 'react';
import {FormInstance, Input, Mentions, Select} from "antd";
import {ActionType} from "../../../model/interface/dataStorage/IAction";
import {Interval, INTERVAL_LIST} from "../../app/file-manager/utils/TimeDuration";


export interface IHumanTimeConfig {
    interval?: Interval,
    format?: string
}

interface IProps {
    value?: IHumanTimeConfig
    onChange?: (config?: IHumanTimeConfig) => void
    className?: string
    style?: React.CSSProperties,
    defaultType?: ActionType
}

interface IState {
    formRef: RefObject<FormInstance>,
    showModal: boolean
}

class HumanTimeConfigBuilder extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            formRef: React.createRef(),
            showModal: false
        }
    }


    onChange = (property: any, change: any) => {
        const {onChange, value} = this.props
        onChange?.({format: value?.format, interval: value?.interval, [property]: change})
    }


    render() {
        const {className, style, value} = this.props

        return (
            <div style={style} className={className + ' flex-grow-1 min-w-0'}>
                <Input.Group compact className={'d-flex w-100'}>
                    <Select
                        allowClear
                        value={value?.interval}
                        placeholder="Vyberte typ hodnoty"
                        optionFilterProp="children"
                        onChange={value => this.onChange('interval', value)}
                        showSearch={true}
                    >
                        {INTERVAL_LIST.map(interval => (
                                <Select.Option key={interval} value={interval}>
                                    {interval}
                                </Select.Option>
                            )
                        )}
                    </Select>
                    <Mentions
                        split={''}
                        value={value?.format}
                        placeholder={"Vytvořte formát pomocí # pro povolené časové intervaly"}
                        onChange={value => this.onChange('format', value)}
                        prefix={'#'}
                    >
                        {INTERVAL_LIST.map(interval => (
                            <Mentions.Option key={interval} value={interval}>
                                {interval}
                            </Mentions.Option>
                        ))}
                    </Mentions>
                </Input.Group>
            </div>
        )
    }
}

export default HumanTimeConfigBuilder
