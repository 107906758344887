import React from "react";
import {Button, Image, List, Row} from "antd";
import {DeleteOutlined, DownloadOutlined} from "@ant-design/icons";
import IFile from "../../../../../model/interface/file/IFile";
import {extensionToIcon} from "../../../../app/file-manager/utils/Extension";
import FileActions from "../../../../app/file-manager/FileViews/FileActions";
import Utils from "../../../../../utils";

interface IState {
    showAll: boolean
}

interface IProps {
    fileList: IFile[]
    onRemove?: (file: IFile) => void
    canDownload?: boolean,
    showPreview: boolean,
    loading?: boolean
}

class FileList extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            showAll: false
        } as IState
    }

    static defaultProps = {
        showPreview: false
    }

    showAll = () => {
        this.setState(state => ({showAll: !state.showAll}))
    }

    render() {
        const {showAll} = this.state
        const {fileList, onRemove, canDownload, loading, showPreview} = this.props

        const getActions = (item: IFile) => {
            const actions = []
            onRemove && actions.push(<Button size={'small'} icon={<DeleteOutlined/>} type={"link"}
                                              onClick={() => onRemove(item)}/>)
            canDownload && actions.push(<Button type={"link"} size={'small'} icon={<DownloadOutlined/>}
                                                onClick={() => FileActions.download(item)}/>)
            return actions
        }

        return fileList.length > 0 && (
            <List
                loading={loading}
                itemLayout={'horizontal'}
                loadMore={fileList.length > 5 && !showAll ? (
                    <Row justify={"center"}>
                        <Button onClick={this.showAll} className={'mt-2'} size={"small"}>Show all</Button>
                    </Row>) : ''}
                dataSource={showAll ? fileList : fileList.slice(0, 5)}
                renderItem={(item: IFile) => (
                    <List.Item
                        className={'p-1'}
                        actions={getActions(item)}>
                        <div className={'d-flex align-items-center'}>
                            {showPreview ? (
                                item.thumbnailUrl ? (
                                    <Image alt={"file preview"} height={40} width={40}
                                           src={item.thumbnailUrl} style={{background: "white", objectFit: "cover"}}
                                           fallback={Utils.imageFallback()}/>
                                ) : extensionToIcon(item.currentVersion.extension)
                            ) : extensionToIcon(item.currentVersion.extension, 14)
                            }
                            <span className={'ml-2'}>{item.fullName}</span>
                        </div>
                    </List.Item>
                )}
            />
        )
    }
}

export default FileList