import React from "react";
import {TimePicker as AntdTimePicker, TimePickerProps} from "antd";
import LocaleProvider from "../../../i18n/LocaleProvider";

type IProps = {
    confirm?: boolean
} & TimePickerProps

class TimePicker extends React.Component<IProps> {

    render() {
        const {onChange, onSelect, confirm} = this.props;

        return <AntdTimePicker locale={LocaleProvider.getPicker()} {...this.props}
                               panelRender={panel => <div className={confirm ? '' : ' ant-picker-no-ok'}>{panel}</div> }
                               onSelect={date => {
                                   onSelect?.(date)
                                   !confirm && onChange?.(date, date.toISOString())
                               }}/>
    }
}

export default TimePicker