import React from "react";
import {Mentions} from "antd";
import IContentType from "../../../../../model/interface/dataStorage/IContentType";
import {connect, RootStateOrAny} from "react-redux";
import selectors from "../../../../../redux/selectors";
import IRepositoryService from "../../../../../model/interface/IRepositoryService";
import {RELATION_FIELD_TYPE} from "../../../../../model/interface/dataStorage/IField";

interface IProps {
    onChange?: (value?: string) => void,
    value?: string,
    contentType: IContentType,
    findServiceByClassName: (name: string) => IRepositoryService
    placeHolder?: string,
    rows?: number
}

interface IState {

}

class ContentTypePresenterMentions extends React.Component<IProps, IState> {


    constructor(props: IProps, context: any) {
        super(props, context);
        this.state = {}
    }

    buildMentionOptions() {
        const output: { value: string, label: string }[] = []
        this.props.contentType.fields.forEach(field => {
            if (field.mode === "scalar") {
                output.push({value: field.name, label: field.label || field.name})
            }
            if (field.mode === 'relation') {
                if (field.type === RELATION_FIELD_TYPE.MANY_TO_ONE || field.type === RELATION_FIELD_TYPE.ONE_TO_ONE) {
                    const service = this.props.findServiceByClassName(field.targetEntity!)
                    if (!service) {
                        throw new Error('Service for field ' + field.name + ' was not found')
                    }
                    service.getPresenterList().forEach(presenter => {
                        output.push({
                            value: field.name + '|' + presenter.value,
                            label: (field.label || field.name) + ' - ' + presenter.label
                        })
                    })
                }
                if (field.type === RELATION_FIELD_TYPE.MANY_TO_MANY || field.type === RELATION_FIELD_TYPE.ONE_TO_MANY) {
                    output.push({value: field.name + '@length', label: (field.label || field.name) + ' (počet)'})
                }
            }
        })
        return output
    }

    render() {
        const {value, placeHolder, onChange, rows} = this.props

        return (
            <Mentions rows={rows}
                placeholder={placeHolder || "Použijte #pro vložení vlastnosti"}
                prefix={'#'}
                value={value}
                onChange={onChange}
            >
                {this.buildMentionOptions().map(option => (
                    <Mentions.Option key={option.value} value={option.value}>
                        {option.label}
                    </Mentions.Option>
                ))}
            </Mentions>
        )
    }
}


const mapStateToProps = (state: RootStateOrAny) => {
    return {
        findServiceByClassName: (name: string) => selectors.services.findOneByFullClassName(state, name),
    }
}
export default connect(mapStateToProps)(ContentTypePresenterMentions)