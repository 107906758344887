import axios from 'axios'
import {env} from 'configs/EnvironmentConfig'
import {AUTH_TOKEN} from 'redux/constants/Auth'
import {notification} from 'antd';

const timeoutValue = process.env.REACT_APP_API_AUTH_TIMEOUT;
const timeout = timeoutValue ? parseInt(timeoutValue, 10) : 60000;

const service = axios.create({
    baseURL: env.API_ENDPOINT_URL,
    timeout: timeout
})

// API Request interceptor
service.interceptors.request.use(config => {
    return config
}, error => {
    // Do something with request error here
    notification.error({
        message: 'Error'
    })
    return Promise.reject(error)
})

// API response interceptor
service.interceptors.response.use((response) => {
    return response.data
}, (error) => {
    localStorage.removeItem(AUTH_TOKEN)
    return Promise.reject(error);
});

export default service