import React from "react";
import {CloseOutlined, EditOutlined, PlusOutlined} from "@ant-design/icons";
import {Row, Table, Tooltip} from "antd";
import {IContentTypeStepProps} from "./ContentTypeConfiguration";
import {ColumnProps} from "antd/es/table";
import RoutePicker from "../../../shared/pickers/RoutePicker";
import IContentTypeRoute from "../../../../model/interface/dataStorage/IContentTypeRoute";
import ContentTypeRouteModal from "./contentTypeRoute/ContentTypeRouteModal";
import Hotkey from "../../../shared/hotkey/Hotkey";
import Button from "../../../shared/button/Button";

interface IState {
    showModal: boolean,
    contentTypeRoute?: IContentTypeRoute
}

class ContentTypeRoutes extends React.Component<IContentTypeStepProps, IState> {

    constructor(props: IContentTypeStepProps) {
        super(props);
        this.state = {
            showModal: false
        }
    }

    showModal = (contentTypeRoute?: IContentTypeRoute) => {
        this.setState(state => ({showModal: !state.showModal, contentTypeRoute}))
    }

    onFinish = (contentTypeRoute?: IContentTypeRoute) => {
        if (contentTypeRoute) {
            let routes = [...this.props.resource.routes]
            const index = routes.findIndex(value => value.uuid === contentTypeRoute.uuid)
            if (index > -1) {
                routes[index] = contentTypeRoute
            } else {
                routes.push(contentTypeRoute)
            }
            this.props.onValuesChange({routes})
        }
        this.setState({showModal: false, contentTypeRoute: undefined})
    }

    onRemove(contentTypeRoute: IContentTypeRoute) {
        let routes = [...this.props.resource.routes].filter(value => contentTypeRoute.uuid !== value.uuid)
        this.props.onValuesChange({routes})
    }

    render() {
        const presenterColumns: ColumnProps<IContentTypeRoute>[] = [
            {
                title: 'ID',
                dataIndex: 'id'
            },
            {
                title: 'Název',
                dataIndex: 'name'
            },
            {
                title: 'Cesta',
                dataIndex: 'route',
                render: (_, contentTypeRoute) => <RoutePicker canAdd={false} disabled={true}
                                                              value={contentTypeRoute.route}/>
            },
            {
                title: <Row justify={"end"}>
                    <Hotkey help={"Přidat cestu"} keys={["Alt", "p"]} trigger={() => this.showModal()}>
                        <Button type={"success"} onClick={() => this.showModal()} icon={<PlusOutlined/>}>
                            <u className={'pl-2'}>P</u>řidat
                        </Button>
                    </Hotkey>
                </Row>,
                key: 'actions',
                dataIndex: 'actions',
                render: (_, contentTypeRoute) => (
                    <div className="text-right d-flex justify-content-end">
                        <Tooltip title={"Upravit"}>
                            <Button onClick={() => this.showModal(contentTypeRoute)} type="link" className="mr-2"
                                    icon={<EditOutlined/>} size="small"/>
                        </Tooltip>
                        <Tooltip title={"Odstranit"}>
                            <Button onClick={() => this.onRemove(contentTypeRoute)} danger type="link" className="mr-2"
                                    icon={<CloseOutlined/>} size="small"/>
                        </Tooltip>
                    </div>
                )
            }
        ]
        const {resource} = this.props
        const {showModal, contentTypeRoute} = this.state
        const {routes} = resource
        return (
            <>
                <Table
                    pagination={{pageSize: 10, showSizeChanger: true}}
                    columns={presenterColumns}
                    dataSource={routes}
                    rowKey='id'
                />
                {showModal && <ContentTypeRouteModal onFinish={this.onFinish} contentTypeRoute={contentTypeRoute}/>}
            </>
        )
    }
}

export default ContentTypeRoutes