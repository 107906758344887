import React from "react";
import IWidgetProps from "../../../../model/interface/widget/IWidgetProps";
import IWidget from "../../../../model/interface/widget/IWidget";
import IWidgetPropsFunctions from "../../../../model/interface/widget/IWidgetPropsFunctions";
import {IAppState} from "../../../../redux/store";
import {connect} from "react-redux";
import ITableOptions from "../../../../model/interface/widget/option/ITableOptions";
import Widget from "./Widget";

interface IProps extends IWidgetProps<IWidgetPropsFunctions<IWidget<ITableOptions>>, ITableOptions> {

}

class TableWidget extends React.Component<IProps, ITableOptions> {
    render() {
        const {id, functions, options} = this.props
        const children = functions.getSortedChildren(id)

        return (
            <div>
                {children.length > 0 && (
                    <table>
                        {Array.from(Array(options.rowsNumber).keys())
                            .map(row => (
                                <tr key={row}>
                                    {Array.from(Array(options.columnsNumber).keys())
                                        .map(column => {
                                            const child = children[(row * (options.columnsNumber || 0)) + column]
                                            return child && <td className={'border'} key={column}>
                                                <Widget
                                                    {...this.props}
                                                    {...child}
                                                    id={child.id}
                                                    key={child.id}
                                                    className={'h-100'}
                                                />
                                            </td>
                                        })}
                                </tr>
                            ))}
                    </table>
                )}
            </div>
        );
    }
}


const mapStateToProps = ({theme}: IAppState) => {
    const {margin} = theme;
    return {margin}
}

export default connect(mapStateToProps)(TableWidget)