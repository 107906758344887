import React from "react";
import ISubmitOptions from "../../../../../model/interface/form/elementOptions/ISubmitOptions";
import IWidgetProps from "../../../../../model/interface/widget/IWidgetProps";
import IFormElementFunctions from "../../../../../model/interface/form/IFormElementFunctions";
import Utils from "../../../../../utils";
import IconBuilder from "../../../../../utils/IconBuilder";
import Button from "../../../../shared/button/Button";

class FormElementButtons extends React.Component<IWidgetProps<IFormElementFunctions, ISubmitOptions>> {
    renderButton = (options: ISubmitOptions) => {
        return <Button
            type={options.type || 'primary'}
            danger={Utils.toBoolean(options.danger)}
            style={{background: options.color, color: options.textColor}}
            icon={options.icon ? IconBuilder(options.icon) : undefined}
            size={options.size || "small"}
            htmlType={"submit"}
        >
            {options.label || options.text}
        </Button>

    }

    render() {
        const {options} = this.props
        return (
            <div>
                {this.renderButton(options)}
            </div>
        );
    }
}

export default FormElementButtons