import React from "react";
import {Form, Input, InputNumber} from "antd";
import IIframeOptions from "../../../../../model/interface/widget/option/IIframeOptions";

interface IProps {
    options: IIframeOptions
    disabledUrl?: boolean
}

class IframeWidgetEditor extends React.Component<IProps> {

    render() {
        const {disabledUrl} = this.props
        return (
            <>
                <Form.Item name={'url'} label={'Url'} rules={[{required: !disabledUrl, message: 'pole je povinné'}]}>
                    <Input disabled={disabledUrl} type={"url"}/>
                </Form.Item>
                <Form.Item name={'height'} label={'Výška'} initialValue={400}>
                    <InputNumber/>
                </Form.Item>
            </>
        )
    }
}

export default IframeWidgetEditor