import React from "react";
import {Button, Col, Form, Input, Result, Row, Typography} from "antd";
import {MailOutlined, ReloadOutlined, SendOutlined} from "@ant-design/icons";
import PasswordResetService from "../../model/service/security/PasswordResetService";

interface IProps {
}

interface IState {
    sent: boolean,
    loading: boolean,
    error: string
}

class PasswordResetRequest extends React.Component<IProps, IState> {

    constructor(props: IProps, context: any) {
        super(props, context);
        this.state = {
            sent: false,
            loading: false,
            error: ''
        }
    }

    submit = (values: any) => {
        this.setState(_state => ({loading: true}))
        PasswordResetService.request(values.email).then(() => {
            this.setState({sent: true})
        }).catch((error) => {
            this.setState({error: error.response.data.message})
        }).finally(() => {
            this.setState({loading: false})
        })
    }

    setSent = (sent: boolean) => {
        this.setState({sent})
    }

    setError = (error: string) => {
        this.setState({error})
    }

    render() {
        const {loading, sent, error} = this.state
        return (
            <>
                {sent && <Result className={'p-0'} status="success"
                                 title={<div className={'font-size-md'}>Požadavek na změnu hesla byl přijat!</div>}
                                 subTitle={'Zkontrolujte e-mailovou schránku.'}
                                 extra={[
                                     <Button key={'again'} type={'link'} icon={<ReloadOutlined/>}
                                             onClick={() => this.setSent(false)}>Nové odeslání</Button>
                                 ]}
                />}
                {!sent && error.length === 0 &&
                    <>
                        <Typography>
                            Pro obnovu vašeho hesla zadejte váš e-mail. Na něj vám přijde jednorázový odkaz, pomocí
                            kterého se přihlásíte.
                        </Typography>

                        <Form className={"mt-3"} onFinish={(values: any) => this.submit(values)}>
                            <Row gutter={16}>
                                <Col className={'flex-grow-1'}>
                                    <Form.Item
                                        name={"email"}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Vložte e-mail',
                                            },
                                            {
                                                type: 'email',
                                                message: 'Vložte e-mail ve správném tvaru'
                                            }
                                        ]}
                                    >
                                        <Input placeholder={"E-mail"}
                                               prefix={<MailOutlined className="text-primary"/>}/>
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Button icon={<SendOutlined/>} type={"primary"} htmlType={"submit"} block
                                            loading={loading}>Odeslat</Button>
                                </Col>
                            </Row>

                        </Form>
                    </>
                }
            </>
        )
    }
}

export default PasswordResetRequest