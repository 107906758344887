import React from "react";
import {AvatarSize} from "antd/lib/avatar/SizeContext";
import IEmployee from "../../../../../model/interface/company/IEmployee";
import AvatarWithDefault from "../../../security/AvatarWithDefault";

interface IProps {
    employee: IEmployee
    size?: AvatarSize
    title?: string,
    className?: string
}

interface IState {
    size: AvatarSize
}

class EmployeeAvatar extends React.Component<IProps, IState> {
    state = {
        size: this.props.size || "small"
    }

    render() {
        const {employee, className} = this.props
        const {size} = this.state
        const {name, surname} = employee
        return (
            <AvatarWithDefault
                {...this.props}
                src={employee && employee.photo ? employee.photo.thumbnailUrl : undefined}
                size={size}
                title={name + ' ' + surname}
                className={className}
            />
        )
    }
}

export default EmployeeAvatar