import React from "react";
import {Col, Empty, Image, Row} from "antd";
import IFile from "../../../../model/interface/file/IFile";
import Utils from "../../../../utils";
import IWidgetProps from "../../../../model/interface/widget/IWidgetProps";
import IImageWidget from "../../../../model/interface/widget/option/IImageWidget";
import IWidgetPropsFunctions from "../../../../model/interface/widget/IWidgetPropsFunctions";

class ImageWidget extends React.Component<IWidgetProps<IWidgetPropsFunctions, IImageWidget>> {

    componentDidMount() {
        const {resource, functions, id, options, editor} = this.props
        const finalFile = resource && options.field ? resource[options.field] : options.file
        if ((!finalFile || (Array.isArray(finalFile) && !finalFile.length)) && !editor) {
            functions.onChildHidden?.(id, true)
        }
    }

    render() {
        const {resource, options, editor} = this.props
        const {width, height, file, preview, field} = options
        let imageWidth = width ? Number(width) : undefined
        let imageHeight = height ? Number(height) : undefined
        const finalFile = resource && field ? resource[field] : file
        if (Array.isArray(finalFile)){
            return finalFile.map(file => {
                let url = this.getUrl(file, field, editor);
                return <Row gutter={[8, 8]}>
                    <Col>
                        {this.renderImage(url, imageWidth, imageHeight, preview, editor)}
                    </Col>
                </Row>
            })
        }
        let url = this.getUrl(finalFile, field, editor);

        return this.renderImage(url, imageWidth, imageHeight, preview, editor);
    }

    private renderImage(url: undefined | string, imageWidth: number | undefined, imageHeight: number | undefined, preview: boolean | undefined, editor: boolean | undefined) {
        return (
            <Row>
                {url ? <Image src={url} width={imageWidth} height={imageHeight} preview={Utils.toBoolean(preview)}
                              fallback={Utils.imageFallback()}/> : (editor ?
                    <Empty description={'Vyberte obrázek'}/> : '')}
            </Row>
        );
    }

    getUrl(finalFile: IFile, field: string | undefined, editor: boolean | undefined) {
        let url = finalFile?.currentVersion && ImageWidget.buildUrl(finalFile,
            finalFile?.currentVersion.width,
            finalFile?.currentVersion.height)

        if (!url && field && editor) {
            url = ' '
        }
        return url;
    }

    static buildUrl(file: IFile | null, width: any, height: any) {
        if (file) {
            const fileName = `${file.id}-${file.currentVersion.id}-${width}x${height}.${file.currentVersion.extension}`
            return process.env.REACT_APP_BASE_URL + `/thumbnails/` + fileName
        }
    }
}

export default ImageWidget