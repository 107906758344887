import React from "react";
import {Form, Switch} from "antd";
import ITabsOptions from "../../../../../model/interface/widget/option/ITabsOptions";

interface IProps {
    options: ITabsOptions
}

class TabsWidgetEditor extends React.Component<IProps> {


    render() {
        return (
            <Form.Item name={'closed'} label={'Defaultně zavřeno'}>
                <Switch/>
            </Form.Item>
        )
    }
}

export default TabsWidgetEditor