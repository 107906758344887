import React from "react";
import {CloseOutlined, EditOutlined, PlusOutlined} from "@ant-design/icons";
import {Divider, Row, Table, Tooltip} from "antd";
import {IContentTypeStepProps} from "./ContentTypeConfiguration";
import {ColumnProps} from "antd/es/table";
import INotification from "../../../../model/interface/dataStorage/INotification";
import NotificationModal from "./notification/NotificationModal";
import EmployeesService from "../../../../model/service/company/EmployeesService";
import RouteLabel from "../route/RouteLabel";
import {connect, RootStateOrAny} from "react-redux";
import IRoute from "../../../../model/interface/dataStorage/IRoute";
import {IRestServiceChoiceList} from "../../../../model/interface/api/IRestServiceChoiceListResponse";
import Hotkey from "../../../shared/hotkey/Hotkey";
import Button from "../../../shared/button/Button";
import UsersService from "../../../../model/service/security/UsersService";

interface IProps extends IContentTypeStepProps {
    routes: IRoute[]
}

interface IState {
    showModal: boolean,
    notification?: INotification
    loading: boolean
    employees: IRestServiceChoiceList,
    users: IRestServiceChoiceList
}

class ContentTypeNotifications extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            showModal: false,
            loading: true,
            employees: {},
            users: {}
        }
    }

    componentDidMount() {
        this.load()
    }

    load() {
        this.setState({loading: true}, () => {
            EmployeesService.getInstance().choiceList('default').then(response => {
                this.setState({employees: response.results})
            }).then(() => UsersService.choiceList('username').then(response => {
                this.setState({users: response.results, loading: false})
            }))
        })
    }

    showModal = (notification?: INotification) => {
        this.setState(state => ({showModal: !state.showModal, notification}))
    }

    onFinish = (notification?: INotification) => {
        if (notification) {
            let notifications = this.props.resource.notifications ? [...this.props.resource.notifications] : []
            const index = notifications.findIndex(value => value.uuid === notification.uuid)
            if (index > -1) {
                notifications[index] = notification
            } else {
                notifications.push(notification)
            }
            this.props.onValuesChange({notifications})
        }
        this.setState({showModal: false, notification: undefined})
    }

    onRemove(notification: INotification) {
        let notifications = [...this.props.resource.notifications].filter(value => value.uuid !== notification.uuid)
        this.props.onValuesChange({notifications})
    }

    render() {
        const notificationColumns: ColumnProps<INotification>[] = [
            {
                title: 'ID',
                dataIndex: 'id'
            },
            {
                title: 'Titulek',
                dataIndex: 'title'
            },
            {
                title: 'Text',
                dataIndex: 'text'
            },
            {
                title: 'Příjemci',
                render: (_, notification) => (
                    <>
                        {notification.recipients.map((recipient, index) => {
                            let label
                            switch (recipient.type) {
                                case('employee'):
                                    label = Object.entries(this.state.employees).find(([uuid]) => uuid === recipient.employee)?.[1]!
                                    break;
                                case('field'):
                                    const field = this.props.resource.fields.find(field => field.uuid === recipient.field)!
                                    label = <>{field.label || field.name}</>
                                    break;
                                case('user'):
                                    label = Object.entries(this.state.users).find(([uuid]) => uuid === recipient.user)?.[1]!
                                    break;
                                case('email'):
                                    label = <>{recipient.email}</>
                                    break;
                                default:
                                    label = <>Chyba konfigurace #4</>
                            }
                            return <span>{label} {index + 1 !== notification.recipients.length &&
                                <Divider type={"vertical"}/>}</span>
                        })}
                    </>
                )
            },
            {
                title: 'Spouštění',
                render: (_, notification) => <>
                    {notification.actions && notification.actions.map((uuid, index) => {
                        const action = this.props.resource.actions.find(action => action.uuid === uuid)!
                        return <>{index > 0 && <Divider type={"vertical"}/>}{action.label}</>
                    })}
                    {notification.triggers && notification.triggers.map((trigger, index) => {
                        const field = this.props.resource.fields.find(field => field.uuid === trigger.field)!
                        return <>{index > 0 && <Divider type={"vertical"}/>}{field.label || field.name}</>
                    })}
                </>
            },
            {
                title: 'Odkaz',
                render: (_, notification) => {
                    if (!notification.route) {
                        return <span className={"text-muted"}>N/A</span>
                    }
                    const route = this.props.routes.find(route => route.uuid === notification.route)
                    if (!route) {
                        return <span className={"text-muted"}>Error</span>
                    }
                    return <RouteLabel route={route}/>
                }
            },
            {
                title: <Row justify={"end"}>
                    <Hotkey help={"Přidat notifikaci"} keys={["Alt", "p"]} trigger={() => this.showModal()}>
                        <Button type={"success"} onClick={() => this.showModal()} icon={<PlusOutlined/>}>
                            <u className={'pl-2'}>P</u>řidat
                        </Button>
                    </Hotkey>
                </Row>,
                key: 'actions',
                dataIndex: 'actions',
                render: (_, notification) => (
                    <div className="text-right d-flex justify-content-end">
                        <Tooltip title={"Upravit"}>
                            <Button onClick={() => this.showModal(notification)} type="link" className="mr-2"
                                    icon={<EditOutlined/>} size="small"/>
                        </Tooltip>
                        <Tooltip title={"Odstranit"}>
                            <Button onClick={() => this.onRemove(notification)} danger type="link" className="mr-2"
                                    icon={<CloseOutlined/>} size="small"/>
                        </Tooltip>
                    </div>
                )
            }
        ]
        const {resource, history, match} = this.props
        const {showModal, notification, employees, users, loading} = this.state
        const {notifications} = resource
        return (
            <>
                <Table
                    pagination={{pageSize: 10, showSizeChanger: true}}
                    columns={notificationColumns}
                    dataSource={notifications}
                    rowKey='id'
                    loading={loading}
                />
                {showModal && <NotificationModal
                    contentType={resource}
                    employees={employees}
                    users={users}
                    history={history}
                    match={match}
                    onFinish={this.onFinish}
                    notification={notification}/>}
            </>
        )
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    return {
        routes: state.setup.routes
    }
}

export default connect(mapStateToProps)(ContentTypeNotifications)