import React, {RefObject} from "react";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {Alert, BackTop, Col, Form, FormInstance, Input, Row, Select, Tooltip, Typography} from "antd";
import ICommand from "../../../model/interface/symfony-console/ICommand";
import CommandService from "../../../model/service/symfony-console/CommandService";
import {CaretRightOutlined, CloseOutlined, CodeOutlined, DeleteOutlined, InfoCircleOutlined} from "@ant-design/icons";
import Utils from "../../../utils";
import Button from "../../shared/button/Button";
import IInput from "../../../model/interface/symfony-console/IInput";
import Documentation from "./Documentation";
import IResult from "../../../model/interface/symfony-console/IResult";
import Arguments from "./Arguments";
import Options from "./Options";
import {Prism as SyntaxHighlighter} from "react-syntax-highlighter";
import {a11yDark} from "react-syntax-highlighter/dist/cjs/styles/prism";
import Modal from "../../shared/modal/Modal";
import SavedCommandForm from "./SavedCommandForm";
import SavedCommands from "./SavedCommands";

interface IProps extends RouteComponentProps<any> {
}

interface IState {
    loading: boolean
    commands: ICommand[]
    command?: string
    optionsInput: IInput,
    argumentsInput: IInput
    results: IResult[],
    running?: boolean,
    helpModal?: boolean
}

class Overview extends React.Component<IProps, IState> {

    formRef: RefObject<FormInstance> = React.createRef()

    constructor(props: IProps, context: any) {
        super(props, context);
        this.state = {
            loading: true,
            commands: [],
            optionsInput: {},
            argumentsInput: {},
            results: []
        }
    }

    load() {
        CommandService.list().then(({results}) => {
            this.setState({commands: results})
        }).finally(() => {
            this.setState({loading: false})
        })
    }

    run = (command?: string, optionsInput?: IInput, argumentsInput?: IInput) => {
        if (command) {
            this.setState({running: true})
            return CommandService.run(command, optionsInput, argumentsInput).then((result) => {
                this.setState(state => ({
                    results: [{
                        ...result,
                        input: result.input.replace('"' + command + '"', command)
                    }, ...state.results], running: false
                }))
            }).catch(() => this.setState(state => ({
                    results: [{
                        success: false,
                        output: 'Nelze spustit příkaz',
                        input: command
                    }, ...state.results], running: false
                }))
            )
        }
        return Promise.resolve()
    }

    componentDidMount() {
        this.load()
    }

    onChangeCommand = (command?: string) => {
        this.setState({command, optionsInput: {}, argumentsInput: {}})
    }

    onChangeArguments = (input: IInput, refresh = false) => {
        this.setState({argumentsInput: input}, refresh ? this.formRef.current?.resetFields : undefined)
    }

    onChangeOptions = (input: IInput, refresh = false) => {
        this.setState({optionsInput: input}, refresh ? this.formRef.current?.resetFields : undefined)
    }

    getCommand = () => {
        const {commands, command} = this.state
        return commands.find(c => c.name === command)!
    }

    onCloseResult = (index: number) => {
        this.setState(state => {
            const results = [...state.results]
            results.splice(index, 1)
            return {results}
        })
    }

    onClearResults = () => {
        this.setState({results: []})
    }

    setHelpModal = () => {
        this.setState(state => ({helpModal: !state.helpModal}))
    }

    render() {
        const {
            loading,
            commands,
            command,
            optionsInput,
            argumentsInput,
            running,
            results,
            helpModal
        } = this.state

        return (
            <div className={'symfony-console content-padding'}>
                <Typography.Title>Symfony konzole</Typography.Title>
                <SavedCommands commands={commands} run={this.run} command={command}
                               argumentsInput={argumentsInput} optionsInput={optionsInput}/>

                <Form size={"small"} ref={this.formRef}
                      onFinish={() => this.run(command, optionsInput, argumentsInput)}>
                    <Row align={'middle'} wrap={false}>
                        <CodeOutlined/>
                        <Select allowClear={true} showSearch={true} className={'flex-grow-1 min-w-0'}
                                filterOption={(inputValue, option) => Utils.stringContains(option?.value, inputValue)}
                                onChange={value => this.onChangeCommand(value?.toString())}
                                placeholder={"příkaz"}
                                bordered={false}
                                autoFocus={true} options={SavedCommandForm.buildCommands(commands)}/>
                        <Tooltip title={'Dokumentace'} visible={loading ? false : undefined}>
                            <Button disabled={loading} type={"link"} icon={<InfoCircleOutlined/>}
                                    onClick={this.setHelpModal}/>
                        </Tooltip>
                    </Row>
                    {command &&
                        <Row gutter={[18, 18]}>
                            <Arguments command={this.getCommand()} input={argumentsInput}
                                       onChange={this.onChangeArguments} autoFocus={true}/>
                            <Options command={this.getCommand()}
                                     autoFocus={this.getCommand().arguments.length === 0}
                                     input={optionsInput} onChange={this.onChangeOptions}/>
                        </Row>
                    }
                    <Row justify={"space-between"} className={'mt-2'}>
                        <Col>
                            {results.length > 0 &&
                                <Button onClick={this.onClearResults} icon={<DeleteOutlined/>} type={"link"}>
                                    Smazat výsledky
                                </Button>
                            }
                        </Col>
                        <Col>
                            <Button disabled={!command} className={'ml-2'} icon={<CaretRightOutlined/>}
                                    type={'danger'} htmlType={"submit"} loading={running}>
                                Spustit
                            </Button>
                        </Col>
                    </Row>
                </Form>

                {results.map((result, index) => (
                    <div className={'react-syntax-highlighter mt-3'}>
                        <div className={'position-relative'}>
                            <SyntaxHighlighter language={'lua'} style={a11yDark}
                                               customStyle={{background: '#455560'}}>
                                {result.input}
                            </SyntaxHighlighter>
                            <Button danger={true} type={"link"} icon={<CloseOutlined/>} size={"small"}
                                    className={'position-absolute mr-2'}
                                    onClick={() => this.onCloseResult(index)}
                                    style={{
                                        top: '50%',
                                        right: 0,
                                        zIndex: 1,
                                        transform: 'translate(0%, -50%)'
                                    }}/>
                        </div>


                        <Alert message={''} type={result.success ? 'success' : 'error'} showIcon={true}
                               description={<div
                                   dangerouslySetInnerHTML={{__html: result.output.trim().replaceAll('\n', '<br/>')}}/>}/>
                    </div>
                ))}


                <Modal onCancel={this.setHelpModal} width={'80vw'} bodyStyle={{height: '60vh'}}
                       visible={helpModal} title={'Dokumentace'} fullScreenOption={true}>
                    <Documentation commands={commands}/>
                </Modal>

                <BackTop/>
            </div>
        )
    }
}

export default withRouter(Overview);