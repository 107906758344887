import React from "react";
import Utils from "../../../../../../utils";
import {connect, RootStateOrAny} from "react-redux";
import selectors from "../../../../../../redux/selectors";
import ICardWidgetProps from "../../../../../../model/interface/dataStorage/card/ICardWidgetProps";
import IconBuilder from "../../../../../../utils/IconBuilder";
import {HeartFilled, HeartOutlined} from "@ant-design/icons";
import IContentType from "../../../../../../model/interface/dataStorage/IContentType";
import IRepositoryService from "../../../../../../model/interface/IRepositoryService";
import {IFieldLikeOptions} from "../../field/optionEditors/FieldLikeOptionsEditor";
import IUser from "../../../../../../model/interface/security/IUser";
import ILike from "../../../../../../model/interface/dataStorage/extension/ILike";
import {ISetupState} from "../../../../../../redux/reducers/Setup";
import {Button, Card, Dropdown, Input, List, Tooltip} from "antd";
import UsersService from "../../../../../../model/service/security/UsersService";
import {API_FILTER_TYPE} from "../../../../../../model/constants/ApiConstant";
import EmployeeAvatar from "../../../../company/employees/partials/EmployeeAvatar";
import moment from "moment";
import ScrollContainer from "../../../../../shared/scrollContainer/ScrollContainer";
import UserAvatar from "../../../../security/UserAvatar";
import LikesService from "../../../../../../model/service/dataStorage/extension/LikesService";

interface IProps extends ICardWidgetProps<IFieldLikeOptions> {
    findService: (contentType: IContentType) => IRepositoryService,
    user: IUser
}

interface IState {
    saving: boolean,
    loading: boolean,
    users: IUser[]

}

class CardWidgetLike extends React.Component<IProps, IState> {

    constructor(props: Readonly<IProps> | IProps) {
        super(props);
        this.state = {
            saving: false,
            loading: false,
            users: []
        }
    }

    onLike = (liked?: ILike) => {
        const {functions, recordId, value, user, id} = this.props
        const contentType = functions.getContentType()

        if (contentType && recordId) {
            this.setState({saving: true, users: []})
            const newLike = {uuid: Utils.uuid(), user: user.uuid, createdAt: {raw: moment().format()}};
            const likes = liked ? value.filter((l: ILike) => l.user !== user.uuid) :
                [newLike, ...value]
            functions.updateValue(id, likes)
            if (liked){
                return LikesService.relationDelete(liked.uuid, contentType.fullClassName, recordId)
                    .then(() => this.setState({saving: false}))
            }
            return LikesService.relationCreate(newLike, contentType.fullClassName, recordId)
                .then(() => this.setState({saving: false}))
        }
    }

    onUserList = () => {
        const {value} = this.props
        if (this.state.users.length > 0 || value.length === 0) {
            return
        }
        this.setState({loading: true})
        UsersService.collectionList({
            filters: {
                0: {
                    field: 'uuid',
                    value: value.map((l: ILike) => l.user),
                    type: API_FILTER_TYPE.IN
                },
            },
            depth: 5,
            limit: 0 //todo ?
        }).then(({results}) => this.setState({users: results, loading: false}))
    }

    static getIcon(liked: boolean, icon?: string) {
        return liked ? (
            icon ? IconBuilder(icon
                .replace('Outlined', 'Filled')
                .replace('Outline', '')) : <HeartFilled/>
        ) : (
            icon ? IconBuilder(icon) : <HeartOutlined/>
        );
    }

    render() {
        const {options, user, value, functions} = this.props
        const field = functions.getContentType().fields.find(f => f.name === 'likes')
        const {users, loading} = this.state
        const {
            icon,
            text,
            color,
            textTooltip,
            showUsers,
            showCount,
            hideCountIfEmpty
        } = {...options, ...(field?.options || {})}
        const liked = value?.find((l: ILike) => l.user === user.uuid)

        return (
            <div>
                <div className={'d-inline-block'}>
                    <Input.Group compact={true}>
                        <Tooltip title={textTooltip && text}>
                            <Button icon={
                                CardWidgetLike.getIcon(!!liked, icon)
                            } size={"small"} className={'cursor-pointer ' + color + (liked ? ' font-weight-bold' : '')}
                                    onClick={() => this.onLike(liked)}>
                                {!textTooltip && text ? (
                                    <span className={'mr-1'}>{text}</span>
                                ) : ''}
                            </Button>
                        </Tooltip>
                        {(value?.length || !hideCountIfEmpty) && showCount ? (
                            <Dropdown
                                disabled={!showUsers}
                                onVisibleChange={visible => visible && this.onUserList()}
                                overlay={
                                    <Card>
                                        <ScrollContainer style={{maxHeight: '300px'}}>
                                            <List loading={loading}
                                                  className={'m-0'}
                                                  itemLayout="horizontal"
                                                  dataSource={users}
                                                  renderItem={item => (
                                                      <List.Item className={'justify-content-start p-2  '}>
                                                          {item.employees[0] ? (
                                                              <EmployeeAvatar employee={item.employees[0]}/>
                                                          ) : <UserAvatar user={item}/>}
                                                          <div className={'ml-1'} style={{lineHeight: 1}}>
                                                              <span>{item.employees[0]?.fullName || item.username || item.email}</span>
                                                              <div>
                                                                  <span
                                                                      className={'text-muted font-size-xs'}>{moment(value.find((v: ILike) => v.user === item.uuid)?.createdAt.raw).fromNow() || ''}</span>
                                                              </div>
                                                          </div>
                                                      </List.Item>
                                                  )}></List>
                                        </ScrollContainer>
                                    </Card>
                                }>
                                <Button size={"small"}>
                                    <span className={'text-muted'}>{value.length}</span>
                                </Button>
                            </Dropdown>
                        ) : ''}
                    </Input.Group>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    const {user} = state.setup as ISetupState

    return {
        findService: (contentType: IContentType) => selectors.services.findOneByContentType(state, contentType),
        user
    }
}

export default connect(mapStateToProps)(CardWidgetLike)
