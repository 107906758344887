import {Button, Divider, Empty, Form, FormInstance, message, Modal, Row, Select, Tag, Tooltip, Tree} from 'antd';
import {NodeDragEventParams} from "rc-tree/lib/contextTypes";
import {EventDataNode, Key} from "rc-tree/lib/interface";
import React, {RefObject} from "react";
import {DataNode} from 'antd/lib/tree';
import {DeleteOutlined, EditOutlined, PlusOutlined, SwapOutlined} from "@ant-design/icons";
import Utils from "../../../../../utils";
import IField from "../../../../../model/interface/dataStorage/IField";
import FormElementField from "../../../configuration/form/FormElement/FormElementField";
import {connect, RootStateOrAny} from "react-redux";
import selectors from "../../../../../redux/selectors";
import IContentType from "../../../../../model/interface/dataStorage/IContentType";
import IBaseProps from "../../../../../model/interface/IBaseProps";
import IRepositoryService from "../../../../../model/interface/IRepositoryService";
import {
    API_FILTER_TYPE_COLLECTION,
    API_FILTER_TYPE_LIST,
    API_FILTER_TYPE_LOGICAL_OPERATORS,
    API_FILTER_TYPE_MULTIPLE,
    API_FILTER_TYPE_VALUELESS,
    API_TOKEN_LIST
} from "../../../../../model/constants/ApiConstant";
import IRestServiceFilter from "../../../../../model/interface/api/IRestServiceFilter";
import IRestServiceFilters from "../../../../../model/interface/api/IRestServiceFilters";
import {ReorderTwoOutline} from "react-ionicons";
import {ISetupState} from "../../../../../redux/reducers/Setup";
import IUser from "../../../../../model/interface/security/IUser";
import IRestServiceFilterNested from "../../../../../model/interface/api/IRestServiceFilterNested";
import FormFieldType from "../../../configuration/form/FormElement/formField/FormFieldType";
import FieldEditor from "../../../configuration/form/FormElement/optionEditor/FieldEditor";
import FieldTypesService from "../../../../../model/service/dataStorage/FieldTypesService";
import FieldPicker, {FieldPickerMode} from "../../../../shared/pickers/FieldPicker";
import FieldsService from "../../../../../model/service/dataStorage/FieldsService";
import FiltersService from "../../../../../model/service/dataStorage/FIltersService";
import FieldDefaultValuePicker, {CustomToken} from "../../../../shared/pickers/FieldDefaultValuePicker";

type FilterDataNode = { filter: IRestServiceFilter | IRestServiceFilterNested, children?: FilterDataNode[] } & DataNode

interface IProps extends IBaseProps {
    value?: IRestServiceFilters,
    contentTypeFullClassName: string
    findContentTypeByClassName: (name: string) => IContentType
    findContentTypeByUuid: (uuid: string) => IContentType
    findServiceByClassName: (className: string) => IRepositoryService
    onChange?: (filters: IRestServiceFilters) => void
    user: IUser
    prefix?: string,
    store: RootStateOrAny
    addNewLabel?: string
    fieldPickerMode?: FieldPickerMode
    customTokens?: CustomToken[]
    disabled?: boolean
}

interface IState {
    localData: FilterDataNode[],
    expendedKeys: string[],
    formRef: RefObject<FormInstance>,
    field?: IField,
    value?: any,
    parentKey?: string,
    showModal?: boolean
    type?: string
    currentFilter?: FilterDataNode
}


class FilterTreeBuilder extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            localData: [],
            expendedKeys: [],
            formRef: React.createRef(),
        }
    }

    static defaultProps = {
        addNewLabel: 'Přidat nový filtr',
        fieldPickerMode: 'tree' as FieldPickerMode
    }

    componentDidMount() {
        const {value} = this.props
        this.setState({localData: this.parseFiltersToTree({...value})})
    }

    componentDidUpdate(prevProps: IProps) {
        if (this.props.value !== prevProps.value){
            this.componentDidMount()
        }
    }

    parseFiltersToTree(filters: IRestServiceFilters) {
        let dataNodes: FilterDataNode[] = []
        Object.entries(filters).forEach(([, filter]) => {
            let node = this.buildNode(filter)
            if ("children" in filter && filter.children) {
                node.children = this.parseFiltersToTree(filter.children)
            }
            dataNodes.push(node)
        })
        return dataNodes
    }

    reset() {
        this.setState({
            type: undefined,
            field: undefined,
            currentFilter: undefined
        }, this.state.formRef.current?.resetFields)
    }

    parseTreeToFilters(dataNodes?: FilterDataNode[]) {
        const data = dataNodes || this.state.localData
        let filters: IRestServiceFilters = {}
        data.forEach((node, index) => {
            const key = (this.props.prefix || 'custom-filter-') + index
            filters = {...filters, [key]: {...node.filter}}
            if (node.children) {
                filters = {...filters, [key]: {...filters[key], children: this.parseTreeToFilters(node.children)}}
            }
        })

        return filters
    }

    loop = (data: FilterDataNode[],
            key: string | number,
            callback: (item: FilterDataNode, index: number, arr: FilterDataNode[]) => void | true): void | true => {
        for (let i = 0; i < data.length; i++) {
            if (data[i].key === key) {
                return callback(data[i], i, data)
            }
            const children = data[i].children
            if (children) {
                const result = this.loop(children, key, callback)
                if (result) {
                    return result
                }
            }
        }
    }

    add = (key?: string) => {
        this.setState({parentKey: key, showModal: true}, this.reset)
    }

    edit = (key: string) => {
        const data = [...this.state.localData]
        this.loop(data, key, (item) => {
            const fieldName = "field" in item.filter ? item.filter.field : '';
            const field = this.getFieldFromRelationChain(fieldName)
            const value = field && FormFieldType
                .formatToForm(FieldEditor.detectType(field), "value" in item.filter && item.filter.value)
            this.setState({
                currentFilter: {...item, filter: {...item.filter, field: fieldName, value}},
                showModal: true,
                value,
                type: item.filter.type,
                field
            }, this.state.formRef.current?.resetFields)
        })
    }

    remove = (key: string) => {
        const data = [...this.state.localData]
        this.loop(data, key, (item, index, arr) => {
            arr.splice(index, 1)
        })
        this.setState({localData: data}, this.onChange)
    }

    onSave = () => {
        const {parentKey, formRef, currentFilter, value} = this.state
        formRef.current?.validateFields().then(values => {
            const data = [...this.state.localData]
            const field = values.field && this.getFieldFromRelationChain(values.field)
            values['value'] = field && FormFieldType.formatFromForm(FieldEditor.detectType(field), value)
            if (API_FILTER_TYPE_VALUELESS.includes(values['type'])) {
                delete values['value']
            }
            if (parentKey) {
                this.loop(data, parentKey, (item) => {
                    item.children = [...item.children || [], this.buildNode(values)]
                })
                this.setState({localData: data})
            } else {
                if (currentFilter) {
                    this.loop(data, currentFilter.key, (item, index, arr) => {
                        arr[index] = {...item, ...this.buildNode(values)}
                    })
                    this.setState({localData: data})
                } else {
                    this.setState(state => ({localData: [...state.localData, this.buildNode(values)]}))
                }
            }
            this.setState({showModal: false},
                () => {
                    this.onChange()
                    this.reset()
                }
            )
        })
    }

    onChange() {
        this.props.onChange && this.props.onChange(this.parseTreeToFilters())
    }

    onCancel = () => {
        this.setState({showModal: false}, this.reset)
    }


    getTokens = (field: IField) => {
        const {customTokens} = this.props
        return [...API_TOKEN_LIST, ...(customTokens?.filter(c => c.isValid ? c.isValid(field) : true) || [])]
    }

    buildNode(values: IRestServiceFilter | IRestServiceFilterNested): FilterDataNode {
        const {history, match, disabled} = this.props

        let value: any, label, field: IField | undefined
        if (!API_FILTER_TYPE_LOGICAL_OPERATORS.includes(values.type) && !FiltersService.isNestedFilter(values)) {
            field = this.getFieldFromRelationChain(values.field)
            label = field ? (field.label || field.name) : ''
            value = values.value
        }
        label = (label || '') + ` [${values.type}]`

        const key = Utils.uuid()
        this.setState(state => ({expendedKeys: [...state.expendedKeys, key]}))
        const valueToken = field && (this.getTokens(field).find(c => c.value === value))

        return {
            title: (
                <div className={'d-inline-flex align-items-center border rounded px-2 py-1'}>
                    <text className={'mr-2'}>{label}</text>
                    {field && value && (valueToken ?
                            <Tag>{valueToken.label}</Tag> :
                            <Form className={'d-inline-block'} initialValues={{
                                value: field && value && FormFieldType
                                    .formatToForm(FieldEditor.detectType(field), value)
                            }}>
                                <FormElementField
                                    className={'mb-0'}
                                    field={field}
                                    functions={{
                                        ...{
                                            getNode: () => ({field}),
                                            getContentType: () => field?.contentTypeId && this.getContentType(field.contentTypeId),
                                        } as any
                                    }}
                                    type={'field'}
                                    label={''}
                                    match={match}
                                    history={history}
                                    id={'dede'}
                                    options={{disabled: true}}
                                    noLabel={true}
                                    preview={true}
                                    customFieldName={'value'}
                                />
                            </Form>
                    )}
                    {!disabled && (
                        <Row className={'ml-1 d-inline-flex'} gutter={[12, 12]}>
                            {API_FILTER_TYPE_LOGICAL_OPERATORS.includes(values.type) && (
                                <Tooltip title={'Přidat podpoložku filtru'}>
                                    <Button onClick={() => this.add(key)} className={'px-1 py-0'} type={"link"}
                                            style={{height: 'auto'}} size={"small"}>
                                        <PlusOutlined/>
                                    </Button>
                                </Tooltip>
                            )}
                            <Tooltip title={'Upravit filtr'}>
                                <Button onClick={() => this.edit(key)} className={'px-1 py-0'} type={"link"}
                                        style={{height: 'auto'}} size={"small"}>
                                    <EditOutlined/>
                                </Button>
                            </Tooltip>
                            <Tooltip title={'Smazat filtr'}>
                                <Button onClick={() => this.remove(key)} danger className={'px-1 py-0'} type={"link"}
                                        style={{height: 'auto'}} size={"small"}>
                                    <DeleteOutlined/>
                                </Button>
                            </Tooltip>
                        </Row>
                    )}
                </div>
            ),
            filter: values, key
        };
    }

    getFieldFromRelationChain(field: string) {
        const {store} = this.props
        return FieldsService.getFieldFromRelationChain(field, this.getContentType(), store);
    }

    getContentType(identifier?: string) {
        const {contentTypeFullClassName, findContentTypeByClassName, findContentTypeByUuid} = this.props
        if (identifier) {
            return Utils.isUuid(identifier) ? findContentTypeByUuid(identifier) : findContentTypeByClassName(identifier)
        }

        return findContentTypeByClassName(contentTypeFullClassName)
    }

    onDrop = (info: NodeDragEventParams & {
        dragNode: EventDataNode
        dragNodesKeys: Key[]
        dropPosition: number
        dropToGap: boolean
    }) => {
        const dropKey = info.node.key
        const dragKey = info.dragNode.key
        const dropPos = info.node.pos.split('-')
        const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1])

        const data = [...this.state.localData]
        let dragObj: any;
        this.loop(data, dragKey, (item, index, arr) => {
            arr.splice(index, 1)
            dragObj = item
        })

        if (!info.dropToGap || ((info.node.children || []).length > 0 && info.node.expanded && dropPosition === 1)) {
            const stop = this.loop(data, dropKey, item => {
                if (!API_FILTER_TYPE_LOGICAL_OPERATORS.includes(item.filter.type)) {
                    message.warn('Nelze připojit potomka k typu filtru, který není AND nebo OR').then()
                    return true
                }
                item.children = item.children || []
                item.children.unshift(dragObj)
            })
            if (stop) {
                return
            }
        } else {
            this.loop(data, dropKey, (item, index, arr) => {
                if (dropPosition === -1) {
                    arr.splice(index, 0, dragObj);
                } else {
                    arr.splice(index + 1, 0, dragObj)
                }
            })
        }

        this.setState({localData: data}, this.onChange)
    }

    setValue = (value: any) => {
        this.setState(state => ({value: state.value === value ? this.state.formRef.current?.getFieldValue('value') : value}))
    }

    updateState = (value: any) => {
        this.setState({...value})
    }

    onFieldChange = (field?: IField) => {
        return this.setState({field: undefined, type: undefined, value: undefined},
            () => {
                this.setState({
                    field: field
                }, () => this.state.formRef.current?.setFieldsValue({value: undefined, type: undefined}))
            })
    }

    render() {
        const {localData, expendedKeys, formRef, field, showModal, type, currentFilter} = this.state
        const {history, match, addNewLabel, fieldPickerMode, customTokens, disabled} = this.props
        const contentType = this.getContentType()

        const fieldDisabled = !!type && API_FILTER_TYPE_LOGICAL_OPERATORS.includes(type)

        return (
            <div>
                <Tree
                    disabled={disabled}
                    className="draggable-tree"
                    draggable={!disabled}
                    expandedKeys={expendedKeys}
                    defaultExpandAll={true}
                    showIcon={true}
                    switcherIcon={<></>}
                    icon={!disabled && <span style={{cursor: "move"}}><ReorderTwoOutline/></span>}
                    blockNode
                    onDrop={this.onDrop}
                    treeData={[...localData]}
                />
                {!disabled && (!localData.length ?
                    <Empty className={'m-0'} description={''} image={Empty.PRESENTED_IMAGE_SIMPLE}>
                        <Button icon={<PlusOutlined/>} size={"small"} type={"link"} onClick={() => this.add()}>
                            {addNewLabel}
                        </Button>
                    </Empty> : <Button icon={<PlusOutlined/>} size={"small"} type={"link"} onClick={() => this.add()}>
                        {addNewLabel}
                    </Button>)
                }

                <Modal visible={showModal} onOk={this.onSave} title={'Filter'} onCancel={this.onCancel}>
                    <Form initialValues={{...currentFilter?.filter}}
                          ref={formRef} onValuesChange={this.updateState}>
                        <Form.Item label={"Typ filtru (nested)"} name={"type"} rules={[{required: !field}]}>
                            <Select disabled={!!field} allowClear={true}>
                                {API_FILTER_TYPE_LIST
                                    .filter(t => API_FILTER_TYPE_LOGICAL_OPERATORS.includes(t.value))
                                    .map(filterType =>
                                        <Select.Option key={filterType.value} value={filterType.value}>
                                            {filterType.label}
                                        </Select.Option>
                                    )}
                            </Select>
                        </Form.Item>
                        <Divider type={'horizontal'}><SwapOutlined rotate={90}/></Divider>
                        <div>
                            <Form.Item name={'field'} label={'Pole'} rules={[{required: !fieldDisabled}]}>
                                <FieldPicker serviceClassName={contentType.fullClassName} mode={fieldPickerMode}
                                             output={'name'}
                                             disabled={!!type && API_FILTER_TYPE_LOGICAL_OPERATORS.includes(type)}
                                             onChange={(value, field) => this.onFieldChange(field as IField | undefined)}/>
                            </Form.Item>
                            {field && (
                                <div>
                                    <Form.Item label={"Typ filtru"} name={"type"} rules={[{required: true}]}>
                                        <Select>
                                            {API_FILTER_TYPE_LIST
                                                .filter(t =>
                                                        !API_FILTER_TYPE_LOGICAL_OPERATORS.includes(t.value)
                                                        && (
                                                            FieldTypesService.isCollection(field) ? API_FILTER_TYPE_COLLECTION.includes(t.value) :
                                                                !API_FILTER_TYPE_COLLECTION.includes(t.value) || API_FILTER_TYPE_VALUELESS.includes(t.value)
                                                        )
                                                )
                                                .map(filterType =>
                                                    <Select.Option key={filterType.value} value={filterType.value}>
                                                        {filterType.label}
                                                    </Select.Option>
                                                )}
                                        </Select>
                                    </Form.Item>
                                    {type && !API_FILTER_TYPE_VALUELESS.includes(type) && (
                                        <Form.Item className={'d-block'} label={'Očekávaná hodnota'} name={'value'}>
                                            <FieldDefaultValuePicker
                                                customTokens={customTokens}
                                                multiple={API_FILTER_TYPE_MULTIPLE.includes(type) || undefined}
                                                onChange={this.setValue} field={field} history={history} match={match}/>
                                        </Form.Item>
                                    )}
                                </div>
                            )}
                        </div>
                    </Form>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    const {user} = state.setup as ISetupState
    return {
        user,
        findContentTypeByClassName: (name: string) => selectors.contentTypes.findOneBy(state, 'fullClassName', name),
        findContentTypeByUuid: (name: string) => selectors.contentTypes.findOneBy(state, 'uuid', name),
        findServiceByClassName: (className: string) => selectors.services.findOneByFullClassName(state, className),
        store: state
    }
}

export default connect(mapStateToProps)(FilterTreeBuilder)
