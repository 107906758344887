import React from "react";
import {connect, RootStateOrAny} from "react-redux";
import IUser from "../../../model/interface/security/IUser";
import Detail from "./users/Detail";
import IBaseProps from "../../../model/interface/IBaseProps";
import {Avatar, Spin} from "antd";
import UsersService from "../../../model/service/security/UsersService";
import {UserOutlined} from "@ant-design/icons";
import UserAvatar from "./UserAvatar";
import {AvatarSize} from "antd/es/avatar/SizeContext";

interface IProps {
    user: IUser
    showAvatar?: boolean
    avatarSize?: AvatarSize
}

interface IState {
    showAvatar: boolean
}

class User extends React.Component<IProps, IState> {
    state = {
        showAvatar: typeof this.props.showAvatar === 'undefined' ? true : this.props.showAvatar
    }
    render() {
        const { user, avatarSize } = this.props
        const {showAvatar} = this.state
        return (
            <>
                {showAvatar && (<UserAvatar user={user} size={avatarSize} />)}
                {user.username}
            </>
        )
    }
}

export default User