import {Tooltip} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import React from "react";
import IFilterFunctions from "./IFilterFunctions";
import IFilterBase from "./IFilterBase";
import FilterDropDown from "./FilterDropDown";
import Search from "./input/Search";

const SearchFilter: IFilterBase = {
    handleSearch(_setSelectedKeys, _selectedKeys, confirm) {
        confirm();
    },

    handleReset(clearFilters, setValue) {
        setValue([])
        clearFilters()
    },

    build(item, field, state, setValue): IFilterFunctions {
        let searchInput: any

        return {
            filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => {
                return (
                    <FilterDropDown onReset={() => this.handleReset(clearFilters, setValue)}
                                    onConfirm={() => this.handleSearch(setSelectedKeys, selectedKeys, confirm, setValue)}>
                        <Search field={field} item={item} inputRef={node => {searchInput = node}} onChange={setSelectedKeys} selected={selectedKeys}/>
                    </FilterDropDown>
                )
            },
            filterIcon: (filtered) => {
                return (
                    <Tooltip title={filtered && state[0] ? "Filtrováno: " + state[0].value : ''}>
                        <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}}/>
                    </Tooltip>
                )
            },
            onFilter: (value, record): boolean => {
                return !value.search ? true : record[field.name]
                    .toString()
                    .toLowerCase()
                    .includes(value.search.toLowerCase());
            },
            onFilterDropdownVisibleChange: (visible) => {
                if (visible && searchInput) {
                    setTimeout(() => searchInput.select());
                }
            }
        }
    }
}

export default SearchFilter