import RestService from "model/service/dataStorage/RestService";
import IRestServiceOptions from "../../../interface/api/IRestServiceOptions";
import IViewSettings from "../../../interface/dataStorage/view/IViewSettings";
import IRestServiceCollectionResponse from "../../../interface/api/IRestServiceCollectionResponse";

interface IRestViewsServiceCollectionResponse extends IRestServiceCollectionResponse {
    results: Array<IViewSettings>
}

interface IRestViewsService {
    collectionList(viewId: number, options?: IRestServiceOptions): Promise<IRestViewsServiceCollectionResponse>,

    collectionCreate(viewId: number, data: any): Promise<IViewSettings>,

    resourceRetrieve(viewId: number, id: number | string): Promise<IViewSettings>,

    resourceUpdate(viewId: number, id: number | string, data: IViewSettings): Promise<IViewSettings>,

    resourceDelete(viewId: number, id: number | string): Promise<void>,
}

const ViewsSettingsService_COLLECTION = 'settings'
const ViewsService_COLLECTION = 'views'

const getCollectionName = (id: number) => {
    return ViewsService_COLLECTION + '/' + id + '/' + ViewsSettingsService_COLLECTION
}

const ViewsSettingsService: IRestViewsService = {
    collectionList: function (viewId: number, options?) {
        return RestService.collectionList(getCollectionName(viewId), options as unknown as IRestServiceOptions) as Promise<IRestViewsServiceCollectionResponse>
    },
    collectionCreate: function (viewId: number, data) {
        return RestService.collectionCreate(getCollectionName(viewId), data) as Promise<IViewSettings>
    },
    resourceRetrieve: function (viewId: number, id) {
        return RestService.resourceRetrieve(getCollectionName(viewId), id) as Promise<IViewSettings>
    },
    resourceDelete: function (viewId: number, id) {
        return RestService.resourceDelete(getCollectionName(viewId), id)
    },
    resourceUpdate: function (viewId: number, id, data) {
        return RestService.resourceUpdate(getCollectionName(viewId), id, data) as Promise<IViewSettings>
    }
}

export default ViewsSettingsService