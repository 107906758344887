import React, {lazy, Suspense} from "react";
import {Route, Switch} from "react-router-dom";
import Loading from 'components/shared/Loading';
import {APP_PREFIX_PATH} from 'configs/AppConfig'

import IBaseProps from "model/interface/IBaseProps";
import IRoute from "model/interface/dataStorage/IRoute";
import {connect, RootStateOrAny} from "react-redux";

const RoutesRouter = lazy(() => import(`components/app/router/RoutesRouter`))

interface IState {
}

interface IProps extends IBaseProps {
    routesIndex: { [x:string]: IRoute }
}

export class AppRouter extends React.Component<IProps, IState> {

    state = {
    }

    render() {
        const routesIndex = this.props.routesIndex
        return (
            <Suspense fallback={<Loading cover="content"/>}>
                <Switch>
                    <Route path={`${APP_PREFIX_PATH}/file-manager`} component={lazy(() => import(`components/app/file-manager`))}/>
                    <Route path={`${APP_PREFIX_PATH}/security`} component={lazy(() => import(`components/app/security`))}/>
                    <Route path={`${APP_PREFIX_PATH}/generated-documents`} component={lazy(() => import(`components/app/generated-document`))}/>
                    <Route path={`${APP_PREFIX_PATH}/import`} component={lazy(() => import(`components/app/import`))}/>
                    <Route path={`${APP_PREFIX_PATH}/symfony-console`} component={lazy(() => import(`components/app/symfony-console/Overview`))}/>
                    <Route path={`${APP_PREFIX_PATH}/company`} component={lazy(() => import(`components/app/company`))}/>
                    <Route path={`${APP_PREFIX_PATH}/configuration`} component={lazy(() => import(`components/app/configuration`))}/>
                    <Route path={`${APP_PREFIX_PATH}/settings`} component={lazy(() => import(`components/app/settings/Overview`))}/>
                    <Route path={`${APP_PREFIX_PATH}/personal-calendar`} component={lazy(() => import(`components/app/personal-calendar/PersonalCalendar`))}/>
                    <Route path={`${APP_PREFIX_PATH}/dictionary-settings`} component={lazy(() => import(`components/app/settings/dictionary/Overview`))}/>
                    <Route path={`${APP_PREFIX_PATH}/no-access`} component={lazy(() => import(`components/app/error/Error403`))}/>

                    {/*/!* TEST start *!/*/}
                    {/*<Route path={`${APP_PREFIX_PATH}/tmp`} component={lazy(() => import(`views/app-views/tmp`))}/>*/}
                    {/*/!* TEST start *!/*/}

                    {Object.entries(routesIndex).map(([path, route]) => {
                        return (
                            <Route exact={true} key={route.id} path={path} render={props => <RoutesRouter {...props} path={this.props.history.location.pathname}/>}/>
                        )
                    })}

                    <Route path={`${APP_PREFIX_PATH}/`} exact component={lazy(() => import(`components/app/home/Home`))}/>
                    <Route path={`${APP_PREFIX_PATH}/`} component={lazy(() => import(`components/app/error/Error404`))}/>
                    {/*<Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/dashboards`}/>*/}
                </Switch>
            </Suspense>
        )
    }
}

const mapStateToProps = ({ setup }: RootStateOrAny ) => {
    const  { routesIndex } = setup
    return { routesIndex }
}

export default connect(mapStateToProps)(React.memo(AppRouter));
