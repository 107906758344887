import React from "react";
import {Col, Form, Input, List, Row, Select, Typography} from "antd";
import ICommand from "../../../model/interface/symfony-console/ICommand";
import {CloseOutlined, DeleteOutlined, PlusOutlined, UnorderedListOutlined} from "@ant-design/icons";
import Utils from "../../../utils";
import Button from "../../shared/button/Button";
import IInput from "../../../model/interface/symfony-console/IInput";

interface IProps {
    command: ICommand,
    input: IInput
    onChange: (argumentsInput: IInput, refresh?: boolean) => void,
    autoFocus?: boolean
}

interface IState {

}

class Arguments extends React.Component<IProps, IState> {

    componentDidMount() {
        this.presetArguments();
    }

    presetArguments() {
        const {onChange, command, input} = this.props
        let presetInput: IInput = {...input}
        !input && command.arguments.forEach(a => {
            if (a.required) {
                presetInput[a.name] = a.isArray ? [''] : ''
            }
        })
        onChange(presetInput)
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (prevProps.command.name !== this.props.command.name) {
            this.presetArguments()
        }
    }

    onAdd = (name: string) => {
        const {onChange, input} = this.props
        const argument = this.get(name)

        onChange({...input, [name]: argument?.isArray ? [''] : ''})
    }

    onDelete = (argument: string) => {
        const {onChange, input} = this.props
        const result = {...input}
        delete result[argument];
        onChange(result)
    }

    onChangeValue = (name: string, value: string, index: number, remove: boolean = false) => {
        const argument = this.get(name)
        const {onChange, input} = this.props

        let inputValue = input[name]
        if (argument?.isArray) {
            inputValue = Array.isArray(inputValue) ? [...inputValue] : []
            inputValue[index] = value
            if (remove) {
                inputValue.splice(index, 1)
            }
        } else {
            inputValue = value
        }

        return onChange({...input, [name]: inputValue}, remove)
    }

    get = (name: string) => {
        const {command} = this.props
        return command.arguments.find(o => o.name === name)
    }

    buildOptions() {
        const {command} = this.props
        return command.arguments?.map(argument => {
            return {
                argument: argument,
                value: argument.name,
                label: <div>
                    {argument.name}
                    <div className={'ml-2 d-inline text-muted'}>{argument.description}</div>
                </div>
            }
        })
    }


    render() {
        const {input, autoFocus} = this.props
        const argumentsList = this.buildOptions()

        return !!argumentsList.length && (
            <Col flex={'auto'}>
                <Row align={'middle'} wrap={false}>
                    <UnorderedListOutlined/>
                    <Select value={null as any} autoFocus={autoFocus} allowClear={true} showSearch={true} className={'w-100'}
                            filterOption={(inputValue, option) => Utils.stringContains(option?.value, inputValue)}
                            onSelect={value => this.onAdd(value as any)} options={argumentsList}
                            bordered={false} placeholder={"argumenty"}/>

                </Row>
                {!!Object.keys(input).length && (
                    <List size={"small"}>
                        {Object.entries(input)
                            .map(([name, value]) => {
                                    const argument = this.get(name)
                                    return argument && (
                                        <List.Item key={name} className={'d-flex justify-content-between'}>
                                            <div>
                                                {(Array.isArray(value) ? value : [value]).map((inputValue, index) => (
                                                    <Row wrap={false} align={"middle"}>
                                                        <Typography.Text strong={true}>{name}:</Typography.Text>
                                                        <Form.Item name={name + index} className={'ml-2 m-0'}
                                                                   noStyle={index > 0} initialValue={inputValue}
                                                                   rules={[{
                                                                       required: true,
                                                                       message: 'hodnota je povinná'
                                                                   }]}>
                                                            <Input
                                                                onChange={e => this.onChangeValue(name, e.target.value, index)}/>
                                                        </Form.Item>
                                                        {argument.isArray && index > 0 && (
                                                            <Button icon={<CloseOutlined/>} danger={true} size={"small"}
                                                                    type={"link"}
                                                                    onClick={() => this.onChangeValue(name, '', index, true)}/>
                                                        )}
                                                    </Row>
                                                ))}
                                                {argument.isArray && Array.isArray(value) && (
                                                    <Button icon={<PlusOutlined/>} size={"small"} className={'mt-2'}
                                                            type={"primary"}
                                                            onClick={() => this.onChangeValue(name, '', value.length)}/>
                                                )}
                                            </div>
                                            {!argument.required && (
                                                <Button icon={<DeleteOutlined/>} danger={true} size={"small"}
                                                        type={"link"}
                                                        onClick={() => this.onDelete(name)}/>
                                            )}
                                        </List.Item>
                                    )
                                }
                            )}
                    </List>
                )}
            </Col>
        )
    }
}

export default Arguments;