import React from "react"
import {Checkbox, Divider, Row, Switch, Typography} from "antd";
import {CaretDownOutlined, CaretUpOutlined, MoreOutlined} from "@ant-design/icons";
import Utils from "utils/index"
import {IViewSettingsProps} from "../ViewSettings";
import {connect, RootStateOrAny} from "react-redux";
import selectors from "../../../../redux/selectors";
import IContentType from "../../../../model/interface/dataStorage/IContentType";
import DragSortList from "../../../shared/list/DragsortList";
import Button from "../../../shared/button/Button";
import {SortEnd} from "react-sortable-hoc";
import Collapse from "../../../shared/display/collapse/Collapse";
import IViewCalendarFilterSettings
    from "../../../../model/interface/dataStorage/view/calendar/IViewCalendarFilterSettings";
import arrayMove from "array-move";

interface IProps extends IViewSettingsProps {
    filters: IViewCalendarFilterSettings[] | undefined
    findContentTypeByUuid: (uuid: string) => IContentType
    contentTypeUuid: string
}

class ViewCalendarFilterSettings extends React.Component<IProps, { calendarFilters: IViewCalendarFilterSettings[] }> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            calendarFilters: props.settings && props.filters
                ? [
                    ...props.filters.filter(item => props.view.items.find(tableItem => tableItem.field === item.field && tableItem.enabled && !!this.getField(item.field))),
                    ...props.view.items
                        .filter(item => !props.filters?.find(tableItem => tableItem.field === item.field) && item.enabled && !!this.getField(item.field))
                        .map(item => ({
                            field: item.field,
                            enabled: item.enabled
                        }))
                ]
                : props.view.items.filter(item => item.enabled && !!this.getField(item.field)).map(item => ({
                    field: item.field,
                    enabled: item.enabled
                }))
        }
    }

    onSortEnd = ({oldIndex, newIndex}: SortEnd): void => {
        const {settings, onChange, contentTypeUuid} = this.props
        let calendarFilters = this.getFilters()
        calendarFilters = arrayMove(calendarFilters, oldIndex, newIndex)
        this.setState({calendarFilters});
        onChange({calendarFilters: {...settings?.calendarFilters, [contentTypeUuid]: calendarFilters}}).then()
    }

    getFilters() {
        return this.state.calendarFilters || [];
    }

    onChange = (item: IViewCalendarFilterSettings, values: any) => {
        const {settings, onChange, contentTypeUuid} = this.props
        let filters = this.getFilters()
        const index = Utils.findIndex(filters, {field: item.field})
        filters[index] = {...filters[index], ...values}
        this.setState({calendarFilters: filters})
        onChange({calendarFilters: {...settings?.calendarFilters, [contentTypeUuid]: filters}}).then()
    }

    getField = (value: string, property: 'uuid' | 'name' = 'uuid') => {
        const {findContentTypeByUuid, contentTypeUuid} = this.props
        const contentType = findContentTypeByUuid(contentTypeUuid)
        return contentType.fields.find(f => f[property] === value)
    }

    getViewItem = (field: string) => {
        const {view} = this.props
        return view.items.find(viewItem => viewItem.field === field)
    }

    render() {
        const {calendarFilters} = this.state

        return (
            <>
                {this.buildItemList(calendarFilters || [])}
            </>
        );
    }

    buildItemList(children: IViewCalendarFilterSettings[]) {
        return <DragSortList item={{
            render: (value: IViewCalendarFilterSettings, _, handle) => {
                const {field, enabled, defaultVisible} = value
                const viewItem = this.getViewItem(field)
                const fieldObject = this.getField(field)
                if (!field || !fieldObject) {
                    throw new Error('missing field on view item')
                }
                const {name, label} = fieldObject
                const title = viewItem?.options?.label || label
                return (
                    <Collapse header={(_, trigger) =>
                        <Row justify={'space-between'}>
                            <Row align={"middle"}>
                                {handle}
                                <Divider type={"vertical"}/>
                                <Checkbox className={'mr-2'} checked={enabled}
                                          onChange={(event) =>
                                              this.onChange(value, {enabled: event.target.checked})}/>
                                {title &&
                                    <Typography.Text strong className={'mr-2'}>{title}</Typography.Text>}({name})
                            </Row>
                            <Row align={"middle"}>
                                Vždy zobrazit: <Switch className={'ml-2'} defaultChecked={defaultVisible}
                                                       onChange={defaultVisible => this.onChange(value, {defaultVisible})}/>
                            </Row>
                        </Row>}>
                    </Collapse>
                )
            },
            className: 'border p-1 px-2 mb-2 shadow-sm',
            style: {zIndex: 1001}
        }} children={children} lockAxis={"y"} handle={{
            render: () => <div className={'d-inline-block'}>
                <div className={"d-flex flex-column px-1"} style={{cursor: "move"}}>
                    <CaretUpOutlined/>
                    <CaretDownOutlined/>
                </div>
            </div>
        }} onSortEnd={end => this.onSortEnd(end)}/>;
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    return {
        findContentTypeByUuid: (uuid: string) => selectors.contentTypes.findOneBy(state, 'uuid', uuid)
    }
}

export default connect(mapStateToProps)(ViewCalendarFilterSettings)