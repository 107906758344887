import RestService from "model/service/dataStorage/RestService";
import IRestServiceOptions from "../../interface/api/IRestServiceOptions";
import IRestServiceCollectionResponse from "../../interface/api/IRestServiceCollectionResponse";
import fetch from "../../auth/ETagCachedFetchInterceptor";
import IPushNotificationSubscription from "../../interface/push-notifications/IPushNotificationSubscription";
import {message} from "antd";
import store from "../../../redux/store";

export interface IRestPushNotificationsServiceCollectionResponse extends IRestServiceCollectionResponse {
    results: Array<IPushNotificationSubscription>
}

interface IRestPushNotificationsService {
    subscribe(key: string): Promise<PushSubscription|null>

    askPermission(): Promise<void>

    register(): Promise<void | ServiceWorkerRegistration> | undefined

    getPermission(): NotificationPermission

    sendTest(id?: number | string): Promise<void>

    collectionList(options?: IRestServiceOptions): Promise<IRestPushNotificationsServiceCollectionResponse>

    collectionCreate(data: IPushNotificationSubscription): Promise<IPushNotificationSubscription>

    resourceRetrieve(id: number | string): Promise<IPushNotificationSubscription>

    resourceUpdate(id: number | string, data: any): Promise<IPushNotificationSubscription>

    resourceDelete(id: number | string): Promise<void>

    showDialog: () => Promise<string>
}

const PushNotificationsService_COLLECTION = 'push-notification-subscriptions'
const PushNotificationsService_TEST = 'send-test'

const PushNotificationsService: IRestPushNotificationsService = {
    subscribe: (key: string) => {
        return navigator.serviceWorker.register(`/serviceWorker.js`)
            .then(function(registration) {
                return new Promise<PushSubscription>(resolve => {
                    const subscription = registration.pushManager.getSubscription();
                    subscription.then(pushSubscription => {
                        if(pushSubscription) {
                            resolve(pushSubscription)
                        }
                        const subscribeOptions = {
                            userVisibleOnly: true,
                            applicationServerKey: key
                        };
                        resolve(registration.pushManager.subscribe(subscribeOptions))
                    })
                })
            })
            .then(function(pushSubscription) {
                return pushSubscription;
            })
    },
    showDialog: () => {
        return new Promise<NotificationPermission>((resolve, reject) => {
            const permissionResult = Notification.requestPermission(result => {
                resolve(result);
            });

            if (permissionResult) {
                permissionResult.then(resolve, reject);
            }
        }).then(permissionResult => {
            return permissionResult
        });
    },
    askPermission: () => {
        return PushNotificationsService.showDialog().then((permission: string) => {
            if(permission === 'granted') {
                const state = store.getState()
                if(!state.setup.pushNotificationPublicKey) {
                    throw new Error('Push notification public key is not set')
                }
                return PushNotificationsService.subscribe(state.setup.pushNotificationPublicKey).then(subscription => {
                    if(!subscription) {
                        throw new Error('Subscription is empty')
                    }
                    PushNotificationsService.collectionList({
                        cache:false,
                        filters: {0: {
                            type: 'equal',
                            field: 'endPoint',
                            value: subscription.endpoint
                        }}
                    }).then(response => {
                        if(response.count === 0) {
                            const subscriptionJSON = subscription.toJSON()
                            const authToken = subscriptionJSON.keys?.['auth']
                            const p256dh =subscriptionJSON.keys?.['p256dh']
                            if (!state.setup.user.id || !authToken || !p256dh){
                                throw new Error('Unable to obtain subscription data!');
                            } else {
                                return PushNotificationsService.collectionCreate({
                                    user: state.setup.user.id, endPoint: subscription.endpoint, authToken, p256dh
                                }).then(() => {
                                    message.success('Úspěšně přihlášen k odběru oznámení').then()
                                }).catch((e) => {
                                    console.error(e)
                                })
                            }
                        }
                    })
                })
            }
        })
    },
    register: () => {
        if ('serviceWorker' in navigator) {
            return navigator.serviceWorker.register(`/serviceWorker.js`)
                .then(registration => {
                    return registration
                })
                .catch(err => console.log(err))
        } else {
            console.error('Service worker not supported!');
        }
    },
    getPermission: () => Notification.permission,
    sendTest(id): Promise<void> {
        return fetch({
            url: '/' + PushNotificationsService_COLLECTION + '/' + id +  '/' + PushNotificationsService_TEST,
            method: 'get'
        }).then(response => {
            // @ts-ignore // TODO
            return response.success;
        })
    },
    collectionList: (options?)  => {
        return RestService.collectionList(PushNotificationsService_COLLECTION, options as IRestServiceOptions) as Promise<IRestPushNotificationsServiceCollectionResponse>
    },
    collectionCreate: (data) => {
        return RestService.collectionCreate(PushNotificationsService_COLLECTION, data) as Promise<IPushNotificationSubscription>
    },
    resourceRetrieve: (id) => {
        return RestService.resourceRetrieve(PushNotificationsService_COLLECTION, id) as Promise<IPushNotificationSubscription>
    },
    resourceDelete: (id) => {
        return RestService.resourceDelete(PushNotificationsService_COLLECTION, id)
    },
    resourceUpdate: (id, data) => {
        return RestService.resourceUpdate(PushNotificationsService_COLLECTION, id, data) as Promise<IPushNotificationSubscription>
    }
}

export default PushNotificationsService
