import React from "react";
import {Button, Form, Input, Popover, Radio, Row, Select, Switch} from "antd";
import RoutePicker from "../../../../shared/pickers/RoutePicker";
import ILinkOptions from "../../../../../model/interface/widget/option/ILinkOptions";
import {LinkOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";
import {API_BASE_URL} from "../../../../../configs/AppConfig";
import ButtonProperties from "./ButtonProperties";

interface IProps {
    options: ILinkOptions
}

class BackButtonWidgetEditor extends React.Component<IProps> {

    render() {
        const {options} = this.props

        return (
            <div>
                <Form.Item name={'source'} label={'Zdroj'} rules={[{required: true}]} initialValue={'url'}>
                    <Radio.Group>
                        <Radio value={'url'}>Url</Radio>
                        <Radio value={'route'}>Cesta</Radio>
                    </Radio.Group>
                </Form.Item>
                {options.source && {
                    route: <Form.Item name={'route'} label={'Zvolte cestu'} rules={[{required: true}]}>
                        <RoutePicker/>
                    </Form.Item>,
                    url: <Form.Item name={'url'} rules={[{required: true}]} label={'Zvolte cestu'}>
                        <Input/>
                    </Form.Item>

                }[options.source]}
                <Form.Item name={'mode'} label={'Format'} rules={[{required: true}]}>
                    <Select>
                        <Select.Option value={'button'}>
                            <Row justify={"space-between"}>
                                Tlačítko
                                <Button icon={<LinkOutlined/>} size={"small"}/>
                            </Row>
                        </Select.Option>
                        <Select.Option value={'text'}>
                            <Row justify={"space-between"}>
                                Text
                                <Link to={''}>text</Link>
                            </Row>
                        </Select.Option>
                        <Select.Option value={'popup'}>
                            <Popover trigger={''} content={<Link to={''}>{API_BASE_URL}</Link>}>
                                <Row justify={"space-between"}>
                                    Popup
                                </Row>
                            </Popover>
                        </Select.Option>
                    </Select>
                </Form.Item>
                {options.mode && {
                    button: <ButtonProperties/>,
                    text: <div>
                        <Form.Item name={'label'} label={'Text'}>
                            <Input/>
                        </Form.Item>
                    </div>,
                    popup: <div>
                        <Form.Item name={'label'} label={'Text'}>
                            <Input/>
                        </Form.Item>
                        <Form.Item name={'popupTrigger'} label={'Zobrazit popup při'} initialValue={'hover'}>
                            <Radio.Group>
                                <Radio value={'hover'}>najetí</Radio>
                                <Radio value={'click'}>kliknutí</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                }[options.mode]}
                <Form.Item name={'blank'} label={'Otevřít na nové kartě'} valuePropName={'checked'}>
                    <Switch/>
                </Form.Item>
            </div>
        )
    }
}

export default BackButtonWidgetEditor