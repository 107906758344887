import React from "react";
import {Form} from "antd";
import CommentList from "../../../comment/CommentList";
import IWidgetProps from "../../../../../model/interface/widget/IWidgetProps";
import IFormElementFunctions from "../../../../../model/interface/form/IFormElementFunctions";
import Utils from "../../../../../utils";
import ICommentOptions from "../../../../../model/interface/form/elementOptions/ICommentOptions";

class FormElementComment extends React.Component<IWidgetProps<IFormElementFunctions, ICommentOptions>> {
    render() {
        const {options} = this.props
        const {label, wysiwyg, attachments, replyAble, editable, wysiwygPackage} = options

        return (
            <div>
                <Form.Item label={label} name={'thread'} trigger={'onThreadCreated'} valuePropName={'thread'}>
                    <CommentList
                        wysiwyg={Utils.toBoolean(wysiwyg)}
                        attachments={Utils.toBoolean(attachments)}
                        replyAble={Utils.toBoolean(replyAble)}
                        editable={Utils.toBoolean(editable)}
                        wysiwygPackage={wysiwygPackage}
                        limit={options.limit}
                    />
                </Form.Item>

            </div>
        );
    }
}

export default FormElementComment