import React from "react";
import {Form, Slider} from "antd";
import ITableOptions from "../../../../../model/interface/widget/option/ITableOptions";

interface IProps {
    options: ITableOptions
}

class TableWidgetEditor extends React.Component<IProps> {

    render() {
        return (
            <>
                <Form.Item name={'columnsNumber'} label={'počet sloupců'}>
                    <Slider min={2} max={30} tooltipVisible/>
                </Form.Item>
                <Form.Item name={'rowsNumber'} label={'počet řádků'}>
                    <Slider min={2} max={30} tooltipVisible/>
                </Form.Item>
            </>

        )
    }
}

export default TableWidgetEditor