import React from "react";
import {Form, Input} from "antd";
import ITabOptions from "../../../../../model/interface/form/elementOptions/ITabOptions";

interface IProps {
    options: ITabOptions
}

class TabWidgetEditor extends React.Component<IProps> {


    render() {
        return (
            <Form.Item name={'label'} label={'Zvolte nazev'} rules={[{required: true}]}>
                <Input/>
            </Form.Item>
        )
    }
}

export default TabWidgetEditor