import {Component} from "react";
import IFile from "model/interface/file/IFile"
import {Avatar} from "antd";

interface IProps {
    avatar: IFile,
    label: string,
    unit: string
}

interface IState {}

export class AvatarIcon extends Component <IProps, IState> {
    state: IState = {}

    render () {
        const { avatar, label, unit } = this.props
        return (
            <>
                <Avatar src={avatar.publicUrl} style={{verticalAlign: 'middle'}} />
                { label } { unit }
            </>
        )
    }
}

export default AvatarIcon