import React from "react"
import {Col, Form, Row, Select, Switch} from "antd";
import IViewTableSettings from "../../../../model/interface/dataStorage/view/table/IViewTableSettings";
import {IViewSettingsProps} from "../ViewSettings";

interface IProps extends IViewSettingsProps {
}

class ViewTableSettings extends React.Component<IProps, IViewTableSettings> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            tablePageSize: 20,
            tableHeaderSticky: true,
            ...props.settings
        }
    }

    onChangeForm = (values: any): void => {
        this.setState(state => ({...state, ...values}), () => this.props.onChange({...values}))
    }

    render() {
        return (
            <>
                <Form onValuesChange={this.onChangeForm} initialValues={this.state}>
                    <Row gutter={12}>
                        <Col sm={12}>
                            <Form.Item name={'tableSortSave'} label={'Průběžně ukládat řazení'}
                                       valuePropName={"checked"}>
                                <Switch/>
                            </Form.Item>
                        </Col>
                        <Col sm={12}>
                            <Form.Item name={'tableFilterSave'} label={'Průběžně ukládat filter'}
                                       valuePropName={"checked"}>
                                <Switch/>
                            </Form.Item>
                        </Col>
                        <Col sm={12}>
                            <Form.Item name={'tablePageSave'} label={'Průběžně ukládat číslo stránky'}
                                       valuePropName={"checked"}>
                                <Switch/>
                            </Form.Item>
                        </Col>
                        <Col sm={12}>
                            <Form.Item name={'tablePageSize'} label={'Počet záznamů na stránku'}>
                                <Select size={"small"}>
                                    {[10, 20, 50, 100].map(size => (
                                        <Select.Option value={size} key={size}>{size}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col sm={12}>
                            <Form.Item name={'tableHeaderSticky'} label={'Ukotvit záhlaví tabulky'}
                                       valuePropName={"checked"}>
                                <Switch/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </>
        )
    }
}


export default ViewTableSettings