import React, {FC} from 'react';
import {Button, Col, Row, Slider} from "antd";
import {ZoomInOutlined, ZoomOutOutlined} from "@ant-design/icons";
import cn from "classnames";

interface IProps {
    zoom: number;
    onZoom?: (value: number) => void;
    className?: string;
    disabled?: unknown;
    min?: number,
    max: number,
    step?: number
    buttonStep?: number
}

export const Zoom: FC<IProps> = ({className, onZoom, zoom, min = 1, max, step = 0.01, buttonStep = 0.1}) => {
    const onZoomIn = () => {
        if (onZoom) {
            onZoom(Math.min(max, zoom + buttonStep));
        }
    };

    const onZoomOut = () => {
        if (onZoom) {
            onZoom(Math.max(min, zoom - buttonStep));
        }
    };

    return (
        <Row className={cn('min-w-300', className)} gutter={[6, 6]}>
            <Col>
                <Button size={"small"} onClick={onZoomOut} icon={<ZoomOutOutlined/>}/>
            </Col>
            <Col flex={'1 1'}>
                <Slider defaultValue={zoom} min={min} max={max} step={step} value={zoom} onChange={onZoom}/>
            </Col>
            <Col>
                <Button size={"small"} onClick={onZoomIn} icon={<ZoomInOutlined/>}/>
            </Col>
        </Row>
    );
};