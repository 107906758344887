import React from "react"
import {Button,} from "antd";
import ViewUnit from "components/app/view/ViewUnit";
import {IViewStepProps} from "./ViewUnitForm";

class ViewUnitPreview extends React.Component<IViewStepProps> {
    render() {
        const {resource, match, history, view} = this.props

        return (
            <>
                {resource && resource.type && resource.contentTypes && (
                    <ViewUnit view={view} preview={true} uuid={resource.uuid} resource={resource} match={match}
                              history={history}/>
                )}

                <Button type={"primary"} onClick={() => this.props.setStep()}>Následující krok</Button>
                <Button onClick={() => this.props.setStep(true)}>Předchozí krok</Button>
            </>
        )
    }
}

export default ViewUnitPreview