import React from "react";
import {Form, Select, Switch} from "antd";
import {connect, RootStateOrAny} from "react-redux";
import {ISetupState} from "../../../../../../redux/reducers/Setup";
import Utils from "../../../../../../utils";
import IMenu from "../../../../../../model/interface/ui/IMenu";
import IReportWidgetMenu from "../../../../../../model/interface/report/IReportWidgetMenu";

interface IProps {
    options: IReportWidgetMenu
    menu: IMenu[]
}

class MenuWidgetEditor extends React.Component<IProps> {

    render() {
        const {menu} = this.props

        return (
            <div>
                <Form.Item label={'Menu'} name={'menu'} rules={[{required: true, message: 'Menu je vyžadováno'}]}>
                    <Select showSearch filterOption={(input, option) =>
                        Utils.stringContains(option?.props.children.join(), input)
                    }>
                        {menu.map(group => (
                            <Select.Option value={group.uuid || ''} key={group.uuid}>
                                {group.title}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item label={'Typ zobrazení'} name={'layout'} rules={[{required: true, message: 'Typ zobrazení'}]}>
                    <Select showSearch filterOption={(input, option) =>
                        Utils.stringContains(option?.props.children.join(), input)
                    }>
                        {[
                            {label: 'Pod sebou', value: 'column'},
                            {label: 'Vedle sebe', value: 'row'}
                        ].map(({value, label}) => (
                            <Select.Option value={value}>{label}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item name={'bordered'} label={'Ohraničení'} valuePropName={'checked'}>
                    <Switch/>
                </Form.Item>
            </div>
        )
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    const {menu} = state.setup as ISetupState

    return {
        menu
    }
}

export default connect(mapStateToProps)(MenuWidgetEditor)
