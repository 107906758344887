import React from "react";
import {ExportOutlined} from "@ant-design/icons";
import {Button, message, Spin} from "antd";
import {IContentTypeStepProps} from "./ContentTypeConfiguration";
import ContentTypesService from "../../../../model/service/dataStorage/ContentTypesService";
import MaintenanceImport from "./maintenace/MaintenaceImport";
import IContentType from "../../../../model/interface/dataStorage/IContentType";

interface IState {
    exporting: boolean
    loadingImport: boolean
}

interface IProps extends IContentTypeStepProps {
    setupReLoad: () => void
    setupLoaded: boolean
}

class ContentTypeMaintenance extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            exporting: false,
            loadingImport: false
        }
    }

    exportToZip = () => {
        const {resource} = this.props
        this.setState({exporting: true})
        ContentTypesService.exportToZip(resource.uuid).then(()=> {
            this.setState({exporting: false})
        }).catch(() => this.setState({exporting: false}))
    }

    onImport = (contentType: IContentType) => {
        message.success(
            <div>
                <div>{`Typ obsahu [${contentType.name}] byl úspěšně importovan. Aktualizuji osobní nastavení.`}</div>
                <div><Spin spinning={true}/></div>
            </div>
        ).then()
        this.props.setupReLoad();
        const promise = new Promise(resolve => {
            if (this.props.setupLoaded) {
                resolve(contentType);
            }
        })
        promise.then(() => {
            message.success('Aktualizace dokončena').then();
            this.props.history.push('/app/configuration/content-types/' + contentType.id)
        })
    }

    render() {
        const {exporting} = this.state
        const {resource, history, match} = this.props

        return (
            <>
                <Button disabled={!resource.id} type={"primary"} loading={exporting} icon={<ExportOutlined/>}
                        onClick={this.exportToZip}>
                    Exportovat
                </Button>
                <div className={'mt-3'}>
                    <MaintenanceImport onImport={this.onImport} resource={resource} history={history} match={match}/>
                </div>
            </>
        )
    }
}

export default ContentTypeMaintenance