import React from "react";
import {Slider} from "antd";
import IFieldOptions from "../../../../../../model/interface/form/elementOptions/IFieldOptions";
import Utils from "../../../../../../utils";

class FormFieldSlider {
    public static render = (options: IFieldOptions, tooltipFormat: string = "") => {
        const props = {
            style: options.style,
            placeholder: options.placeholder,
            min: options.sliderMin ? Number(options.sliderMin): options.sliderMin,
            max: options.sliderMax ? Number(options.sliderMax): options.sliderMax,
            tooltipVisible: Utils.toBoolean(options.sliderTooltipVisible),
            disabled: options.disabled,
            value: options.value

        };
        return (
            <Slider {...props} tipFormatter={value => value + tooltipFormat} />
        )
    }
}

export default FormFieldSlider