import {Tooltip} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import React from "react";
import IFilterFunctions from "./IFilterFunctions";
import IFilterBase, {IFilterBaseValue} from "./IFilterBase";
import {API_FILTER_TYPE} from "../../model/constants/ApiConstant";
import FilterDropDown from "./FilterDropDown";
import Range from "./input/Range";

const RangeFilter: IFilterBase = {
    handleSearch(setSelectedKeys, selectedKeys, confirm, setValue) {
        const finalKeys = selectedKeys.filter(key => key.value)
        setValue(finalKeys)
        setSelectedKeys(finalKeys)
        confirm();
    },

    handleReset(clearFilters, setValue) {
        clearFilters();
        setValue([])
    },

    build(item, field, state, setValue): IFilterFunctions {
        let inputMin: any
        const findMin = (selectedKeys: IFilterBaseValue[]) => {
            return selectedKeys.find(key => key.type === API_FILTER_TYPE.GREATER_OR_EQUAL)
        }
        const findMax = (selectedKeys: IFilterBaseValue[]) => {
            return selectedKeys.find(key => key.type === API_FILTER_TYPE.LESSER_OR_EQUAL)
        }

        return {
            filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => {
                return (
                    <FilterDropDown onReset={() => this.handleReset(clearFilters, setValue)}
                                    onConfirm={() => this.handleSearch(setSelectedKeys, selectedKeys, confirm, setValue)}>
                       <Range selected={selectedKeys} inputRef={node => {inputMin = node}} field={field} item={item} onChange={setSelectedKeys} onPressEnter={() => this.handleSearch(setSelectedKeys, selectedKeys, confirm, setValue)}/>
                    </FilterDropDown>
                )
            },
           filterIcon: (filtered: boolean): JSX.Element => {
                const minValue = findMin(state)?.value as number
                const maxValue = findMax(state)?.value as number
                return (
                    <Tooltip title={filtered ? "Filtrováno: " + (minValue || '') + ' - ' + (maxValue || '') : ''}>
                        <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}}/>
                    </Tooltip>
                )
            },
            onFilter: (value, record): boolean => {
                const recordValue = parseInt(record[field.name].toString(), 10)

                return value.type === API_FILTER_TYPE.LESSER_OR_EQUAL ?
                    (!value.value || parseInt(value.value.toString(), 10) >= recordValue) :
                    (!value.value || parseInt(value.value.toString(), 10) >= recordValue)
            },
            onFilterDropdownVisibleChange: (visible: boolean) => {
                if (visible && inputMin) {
                    setTimeout(() => inputMin.select());
                }
            }
        }
    }
}

export default RangeFilter