import {Input, InputNumber, Row, Select} from 'antd';
import React from "react";
import IFieldOptions from "../../../../../../model/interface/form/elementOptions/IFieldOptions";
import Utils from "../../../../../../utils";

interface IProps {
    value?: string,
    onChange?: (value?: string) => void
    options: IFieldOptions
}

interface IState {
    value?: string
    currency?: string
    amount?: null | string
}

const CURRENCIES = [
    {
        name: 'CZK',
        icon: 'Kč'
    }, {
        name: 'EUR',
        icon: '€'
    }
]

class FormFieldCurrency extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            value: props.value,
            currency: this.parseValue(props.value, true) || "CZK",
            amount: this.parseValue(props.value)
        }
    }

    parseValue(value?: string, currency: boolean = false) {
        if (value) {
            console.log("value parse",value)
            return value.split(' ')[currency ? 1 : 0]
        }
        return value
    }

    handleChange = () => {
        const {amount, currency} = this.state
        this.setState(state => ({value: state.amount + ' ' + state.currency}),
            () => {
                this.props.onChange && this.props.onChange(amount && currency ? this.state.value : undefined)
            })
    }

    setCurrency = (value: string) => {
        this.setState({currency: value}, this.handleChange)
    }

    setAmount(value: string | null) {
        this.setState({amount: value}, this.handleChange)
    }

    render() {
        const {placeholder, disabled} = this.props.options
        const {value} = this.props
        const amount = this.parseValue(value)
        const currency = this.parseValue(value, true) || this.state.currency || 'CZK'

        return (
            <Input.Group compact>
                <InputNumber precision={2}
                             onChange={value => this.setAmount(value)}
                             inputMode={"decimal"}
                             placeholder={placeholder}
                             value={amount}
                             disabled={disabled}
                             formatter={value => {
                                 return value ? Number(value).toLocaleString() : ''
                             }}
                             parser={value => {
                                 const replace = `[^\\d\\${Utils.decimalSeparator()}]`;
                                 const regExp = new RegExp(replace, "g");
                                 return value ?
                                     value.replace(regExp, '').replace(',', '.') : ''
                             }}
                             style={{width: '80%'}}/>
                <Select style={{width: '20%'}} value={currency} disabled={disabled} onChange={this.setCurrency}>
                    {CURRENCIES.map(currency => (
                        <Select.Option value={currency.name}>
                            <Row justify={"space-between"}>
                                <div>{currency.icon}</div>
                                <div>[{currency.name}]</div>
                            </Row>
                        </Select.Option>
                    ))}
                </Select>
            </Input.Group>
        )
    }
}

export default FormFieldCurrency