import React, {Component, RefObject} from 'react';
import {Button, FormInstance, Input, Row, Select} from "antd";
import {EditOutlined, PlusOutlined} from "@ant-design/icons";
import IBackground from "../../../model/interface/ui/IBackground";
import BackgroundForm from "../../app/ui/BackgroundForm";
import BackgroundsService from "../../../model/service/ui/BackgroundsService";
import Background from "../../app/ui/Background";

interface IProps {
    value?: string | IBackground
    onChange?: (uuid?: string) => void
    className?: string,
    style?: React.CSSProperties,
    disabled?: boolean
    canAdd?: boolean,
    canEdit?: boolean
    identifier?: string,
}

interface IState {
    formRef: RefObject<FormInstance>,
    showModal?: string | true
    backgrounds?: IBackground[],
}

class BackgroundPicker extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            formRef: React.createRef()
        }
    }

    static defaultProps = {
        canAdd: true,
        canEdit: true
    }

    componentDidMount() {
        BackgroundsService.collectionList().then(({results}) => {
            this.setState({backgrounds: results})
        })
    }

    onPickerChoose = (route: string) => {
        if (this.props.onChange) {
            this.props.onChange(route)
        }
    }

    onModalChange = (background?: IBackground) => {
        if (background) {
            this.setState(state => ({backgrounds: [...(state.backgrounds || []), background]}))
            if (this.props.onChange && background.uuid) {
                this.props.onChange(background.uuid)
            }
        }
        this.setState({showModal: undefined})
    }

    showModal = (background?: string | true) => {
        this.setState({showModal: background})
    }

    render() {
        const {showModal, backgrounds} = this.state
        const {className, style, disabled, canAdd, value, canEdit} = this.props
        const parseValue = typeof value === "string" ? value : value?.uuid

        return (
            <div className="route-picker" style={style}>
                {showModal && (
                    <BackgroundForm background={backgrounds?.find(r => r.uuid === showModal)}
                                    onChange={this.onModalChange} modal={true}/>
                )}
                <Input.Group compact className={'d-flex w-100'}>
                    <Select
                        allowClear
                        className={className + ' flex-grow-1 min-w-0'}
                        showSearch
                        disabled={disabled || !backgrounds}
                        value={!backgrounds ? undefined : parseValue}
                        loading={!backgrounds}
                        placeholder="Vyberte pozadi"
                        optionFilterProp="children"
                        onChange={this.onPickerChoose}
                        filterOption={(input, option) =>
                            option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {backgrounds?.map(b => (
                                <Select.Option key={b.uuid} value={b.uuid}>
                                    <Row className={'h-100'} justify={"space-between"}>
                                        {b.url || b.file?.fullName}
                                        <Background background={b} style={{flexGrow: 1, height: '100%'}}/>
                                    </Row>
                                </Select.Option>
                            )
                        )}
                    </Select>
                    {canAdd && !disabled &&
                        <Button className={'flex-shrink-0'} onClick={() => this.showModal(true)}
                                icon={<PlusOutlined/>}/>}
                    {canEdit && !disabled && value &&
                        <Button className={'flex-shrink-0'} onClick={() => this.showModal(parseValue)}
                                icon={<EditOutlined/>}/>}
                </Input.Group>
            </div>
        )
    }
}

export default BackgroundPicker
