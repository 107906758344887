import {Form, Input, Switch} from 'antd';
import React from "react";
import {IFieldOptionsEditorProps} from "../FieldOptionsEditor";
import IconPicker from "../../../../../shared/IconPicker";
import TextColorPicker from "../../../../../shared/textColorPicker/TextColorPicker";

export interface IFieldLikeOptions {
    icon?: string,
    text?: string,
    color?: string,
    textTooltip?: boolean
    showUsers?: boolean
    showCount?: boolean,
    hideCountIfEmpty?: boolean
}

interface IProps extends IFieldOptionsEditorProps {

}

class FieldLikeOptionsEditor extends React.Component<IProps> {

    render() {
        const {buildFieldName} = this.props
        return (
            <div>
                <Form.Item name={buildFieldName('icon')} label={'Zvolte iconu'} initialValue={'ant.LikeOutlined'}>
                    <IconPicker/>
                </Form.Item>
                <Form.Item name={buildFieldName('text')} label={'Zvolte popisek'} initialValue={'líbí se mi'}>
                    <Input/>
                </Form.Item>
                <Form.Item name={buildFieldName('color')} label={'Zvolte barvu'} initialValue={'text-primary'}>
                    <TextColorPicker/>
                </Form.Item>
                <Form.Item name={buildFieldName('textTooltip')} label={'Popisek jen jako nápověda'} valuePropName={'checked'}>
                    <Switch/>
                </Form.Item>
                <Form.Item name={buildFieldName('showUsers')} label={'zobrazit uživatele'} valuePropName={'checked'} initialValue={true}>
                    <Switch/>
                </Form.Item>
                <Form.Item name={buildFieldName('showCount')} label={'Zobrazit počet'} valuePropName={'checked'} initialValue={true}>
                    <Switch/>
                </Form.Item>
                <Form.Item name={buildFieldName('hideCountIfEmpty')} label={'Skrýt počet, pokud je prázdný'} valuePropName={'checked'} initialValue={true}>
                    <Switch/>
                </Form.Item>
            </div>
        )
    }
}


export default FieldLikeOptionsEditor