import React from "react";
import {IFieldOptionsEditorProps} from "../FieldOptionsEditor";
import FieldWysiwygOptionsEditor, {IFieldWYSIWYGOptions} from "./FieldWysiwygOptionsEditor";

export interface IFieldTextOptions extends IFieldWYSIWYGOptions {

}

interface IProps extends IFieldOptionsEditorProps {

}

class FieldCompanyOptionEditor extends React.Component<IProps> {


    render() {
        return (
            <div>
                <FieldWysiwygOptionsEditor {...this.props}/>
            </div>
        )
    }
}

export default FieldCompanyOptionEditor