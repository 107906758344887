import React from "react"
import {Button, Modal, Tabs} from "antd";
import IViewSettingsStructure from "model/interface/dataStorage/view/settings/IViewSettingsStructure";
import SortSettings from "./settings/SortSettings";
import ViewTableSettings from "./table/ViewTableSettings";
import ViewCalendarSettings from "./calendar/ViewCalendarSettings";
import {
    VIEW_TYPE_CALENDAR,
    VIEW_TYPE_CAROUSEL,
    VIEW_TYPE_GRAPH,
    VIEW_TYPE_PIVOT_TABLE,
    VIEW_TYPE_TABLE
} from "../../../model/service/dataStorage/ViewTypesService";
import ViewGraphSettings from "./graph/ViewGraphSettings";
import CustomFiltersSettings from "./settings/CustomFiltersSettings";
import IBaseProps from "../../../model/interface/IBaseProps";
import IViewUnit from "../../../model/interface/dataStorage/IViewUnit";
import {RollbackOutlined, SaveOutlined} from "@ant-design/icons";
import ViewPivotTableSettings from "./pivot/ViewPivotTableSettings";
import ViewTableItemsSettings from "./table/ViewTableItemsSettings";
import ViewCalendarColorSettings from "./calendar/ViewCalendarColorSettings";

export interface IViewSettingsProps extends IBaseProps {
    view: IViewUnit,
    onChange: (values: IViewSettingsStructure | null) => Promise<void>
    onReset?: () => Promise<void>
    settings?: IViewSettingsStructure,
    autoSave?: boolean
}

interface IProps extends IViewSettingsProps {
    modal?: boolean
    visible: boolean
    onClose?: () => void
    viewType?: string
}

interface IState {
    saving: boolean
    loading: boolean
    enabled: boolean,
    settings?: IViewSettingsStructure,
    altered: boolean,
    resetting: boolean
}

export class ViewSettings extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            settings: props.settings,
            saving: false,
            loading: false,
            enabled: true,
            altered: false,
            resetting: false
        }
    }

    static defaultProps = {
        modal: true
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (JSON.stringify(this.props.settings) !== JSON.stringify(prevProps.settings)) {
            this.setState({settings: this.props.settings})
        }
    }

    getSettings() {
        return this.state.settings || this.props.settings
    }

    onChange = (values: any): Promise<void> => {
        return new Promise(resolve => {
            const {modal} = this.props
            this.setState(() => ({
                settings: {...this.getSettings(), ...values},
                altered: true
            }), () => {
                if (!modal) {
                    this.updateParent()
                }
                resolve()
            })
        })
    }

    onFinish() {
        this.setState({saving: true})
        this.updateParent();
    }

    updateParent() {
        this.props.onChange(this.state.settings || null).then(() => {
            this.setState({saving: false})
        })
    }

    onReset = () => {
        this.setState({resetting: true})
        if (this.props.onReset) {
            this.props.onReset().then(() => this.setState({resetting: false}))
        }
    }

    render() {
        const {view, modal, match, history, visible, onReset, viewType, onClose} = this.props
        const {saving, resetting} = this.state
        const settings = this.getSettings()
        const settingsProps = {view, settings, onChange: this.onChange, match, history}
        const editor = viewType ?
            {
                [VIEW_TYPE_TABLE]: <ViewTableSettings {...settingsProps}/>,
                [VIEW_TYPE_CALENDAR]: <ViewCalendarSettings {...settingsProps}/>,
                [VIEW_TYPE_GRAPH]: <ViewGraphSettings {...settingsProps}/>,
                [VIEW_TYPE_PIVOT_TABLE]: <ViewPivotTableSettings {...settingsProps}/>,
            }[view.type] : (
                <div>
                    <Tabs>
                        {
                            {
                                [VIEW_TYPE_TABLE]:
                                    <Tabs.TabPane key={"items"} tab={"Položky"}>
                                        <ViewTableItemsSettings {...settingsProps}/>
                                    </Tabs.TabPane>,
                                [VIEW_TYPE_PIVOT_TABLE]:
                                    <Tabs.TabPane key={"items"} tab={"Pivot"}>
                                        <ViewPivotTableSettings {...settingsProps}/>
                                        <ViewTableItemsSettings {...settingsProps}/>
                                    </Tabs.TabPane>,
                            }[view.type]
                        }
                        {[VIEW_TYPE_TABLE, VIEW_TYPE_PIVOT_TABLE, VIEW_TYPE_CAROUSEL].includes(view.type) &&
                            <Tabs.TabPane key={"sortSettings"} tab={"Řazení"}>
                                <SortSettings {...settingsProps}/>
                            </Tabs.TabPane>
                        }

                        {[VIEW_TYPE_TABLE, VIEW_TYPE_PIVOT_TABLE, VIEW_TYPE_CALENDAR, VIEW_TYPE_GRAPH].includes(view.type) &&
                            <Tabs.TabPane key={"settings"} tab={"Volby"}>
                                {
                                    {
                                        [VIEW_TYPE_TABLE]:
                                            <ViewTableSettings {...settingsProps}/>,
                                        [VIEW_TYPE_PIVOT_TABLE]:
                                            <ViewTableSettings {...settingsProps}/>,
                                        [VIEW_TYPE_CALENDAR]:
                                            <ViewCalendarSettings {...settingsProps}/>,
                                        [VIEW_TYPE_GRAPH]:
                                            <ViewGraphSettings {...settingsProps}/>
                                    }[view.type]
                                }
                            </Tabs.TabPane>
                        }

                        {[VIEW_TYPE_CALENDAR].includes(view.type) &&
                            <Tabs.TabPane key={"colors"} tab={"Barvy"}>
                                <ViewCalendarColorSettings {...settingsProps}/>
                            </Tabs.TabPane>
                        }

                        <Tabs.TabPane key={"filters"} tab={"Filtry"}>
                            <CustomFiltersSettings {...settingsProps}/>
                        </Tabs.TabPane>
                    </Tabs>
                </div>
            )

        return modal ? (
            <Modal
                title={onReset ? 'Osobní nastavení pohledu' : 'Výchozí nastavení pohledu'}
                visible={visible}
                bodyStyle={{maxHeight: '60vh', overflowY: "auto"}}
                className={'overflow-hidden'}
                closable={false}
                width={800}
                destroyOnClose={true}
                footer={[
                    <div key={'footer'}>
                        <Button
                            key="reset"
                            icon={<RollbackOutlined/>}
                            danger
                            type="primary"
                            className={"float-left"}
                            loading={resetting}
                            disabled={!onReset || saving}
                            onClick={this.onReset}
                        >
                            Obnovit výchozí
                        </Button>
                        <Button
                            key={'close'}
                            type="default"
                            disabled={saving || resetting}
                            onClick={() => onClose?.()}
                        >
                            Zrušit
                        </Button>
                        <Button
                            key="submit"
                            type="primary"
                            icon={<SaveOutlined/>}
                            loading={saving || resetting}
                            onClick={() => this.onFinish()}
                        >
                            Uložit
                        </Button>
                    </div>
                ]}
            >
                {editor}
            </Modal>
        ) : (editor)
    }
}

export default ViewSettings