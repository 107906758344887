import RestService from "model/service/dataStorage/RestService";
import IRestServiceOptions from "../../interface/api/IRestServiceOptions";
import IRestServiceCollectionResponse from "../../interface/api/IRestServiceCollectionResponse";
import IContactType from "../../interface/company/IContactType";
import IRepositoryService from "../../interface/IRepositoryService";
import IField from "../../interface/dataStorage/IField";
import IContact from "../../interface/company/IContact";
import IPresenter from "../../interface/dataStorage/IPresenter";
import IRestServiceChoiceListResponse from "../../interface/api/IRestServiceChoiceListResponse";
import IconBuilder from "../../../utils/IconBuilder";
import React from "react";

interface IRestContactTypesServiceCollectionResponse extends IRestServiceCollectionResponse {
    results: Array<IContactType>
}

const presenters: IPresenter[] = [
    {
        type: 'label',
        name: 'default',
        label: 'Výchozí',
        options: {
            label: "#name"
        }
    },
    {
        type: 'label',
        name: 'icon',
        label: 'Ikona',
        options: {
            label: "#icon"
        }
    },
    {
        type: 'callback',
        name: 'withIcon',
        label: 'S ikonou',
        options: {
            callback: (object?: IContactType) => {
                return object ? <span className={'text-nowrap'}>{IconBuilder(object.icon)}{' ' + object.name}</span> : ''
            }
        }
    }
]

interface IRestContactTypesService extends IRepositoryService {
    collectionList(options?: IRestServiceOptions): Promise<IRestContactTypesServiceCollectionResponse>,

    collectionCreate(data: any): Promise<IContactType>,

    resourceRetrieve(id: number | string): Promise<IContactType>,

    resourceUpdate(id: number | string, data: any): Promise<IContactType>,

    resourceDelete(id: number | string): Promise<void>,
}

const ContactTypesService_COLLECTION = 'company/contact-types'
const CHOICES = 'company/contact-types-choices/'

class ContactTypesService implements IRestContactTypesService {
    private static instance?: ContactTypesService

    public static getInstance(): ContactTypesService {
        if (!ContactTypesService.instance) {
            ContactTypesService.instance = new ContactTypesService()
        }
        return ContactTypesService.instance
    }

    getFields(): IField[] {
        return [
            {
                uuid: '',
                name: 'Name',
                label: 'Název',
                mode: "scalar",
                type: 'string',
                targetEntity: null,
                options: [],
                weight: 1,
                contentTypeId: null,
                contentTypeName: false,
                locked: true,
                arguments: {}
            },
            {
                uuid: '',
                name: 'icon',
                label: 'Ikona',
                mode: "scalar",
                type: 'string',
                targetEntity: null,
                options: [],
                weight: 1,
                contentTypeId: null,
                contentTypeName: false,
                locked: true,
                arguments: {}
            }
        ];
    }

    getRecordClassName() {
        return 'App\\Company\\Entity\\ContactType'
    }

    getTitle(): string {
        return "Typ Kontaktu";
    }

    getStringValue(contact: IContact): string {
        return contact.value
    }

    getPresenter(name: string): IPresenter | null {
        return presenters.find(value => value.name === name) || null
    }

    getDefaultPresenter(): IPresenter {
        return presenters[0]
    }

    getPresenterList() {
        return presenters.map(presenter => {
            return {value: presenter.name, label: presenter.label}
        })
    }

    choiceList(presenterName: string, options?: IRestServiceOptions): Promise<IRestServiceChoiceListResponse> {
        return RestService.collectionList(CHOICES + presenterName, options) as unknown as Promise<IRestServiceChoiceListResponse>
    }

    collectionList(options?: IRestServiceOptions) {
        return RestService.collectionList(ContactTypesService_COLLECTION, options as unknown as IRestServiceOptions) as Promise<IRestContactTypesServiceCollectionResponse>
    }
    collectionCreate(data: any) {
        return RestService.collectionCreate(ContactTypesService_COLLECTION, data) as Promise<IContactType>
    }
    resourceRetrieve(id: number | string) {
        return RestService.resourceRetrieve(ContactTypesService_COLLECTION, id) as Promise<IContactType>
    }
    resourceDelete(id: number | string) {
        return RestService.resourceDelete(ContactTypesService_COLLECTION, id)
    }
    resourceUpdate(id: number | string, data: any) {
        return RestService.resourceUpdate(ContactTypesService_COLLECTION, id, data) as Promise<IContactType>
    }
}

export default ContactTypesService