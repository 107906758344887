import React from 'react'
import {Button, Dropdown, Menu} from 'antd';
import {connect, RootStateOrAny} from "react-redux";
import {SettingOutlined} from "@ant-design/icons";
import IBaseProps from "../../../../model/interface/IBaseProps";
import IUser from "../../../../model/interface/security/IUser";
import IForm from "../../../../model/interface/form/IForm";
import IContentType from "../../../../model/interface/dataStorage/IContentType";
import ContentTypesService from "../../../../model/service/dataStorage/ContentTypesService";
import Utils from "../../../../utils";
import FormConfiguration, {FormConfigurationStep} from "./FormConfiguration";
import selectors from "../../../../redux/selectors";
import {reLoad} from "../../../../redux/actions/Setup";
import {Link} from "react-router-dom";
import ContentTypeForms from "../content-type/ContentTypeForms";
import ContentTypeConfiguration from "../content-type/ContentTypeConfiguration";
import UsersService from "../../../../model/service/security/UsersService";

interface IState {
    visible: boolean,
    configurationStep?: FormConfigurationStep
}

interface IProps extends IBaseProps {
    user: IUser
    resource: IForm
    findContentType: (uuid: string) => IContentType
    reLoad: () => void
    findForm: (value: any) => IForm
}

class FormEditButton extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props)
        this.state = {
            visible: false
        }
    }

    finish(form?: IForm, changedForms: IForm[] = []) {
        const {findForm, reLoad} = this.props
        if (form) {
            const contentType = this.getContentType()
            const forms = Utils.arrayAddOrUpdateWhere(contentType.forms.map(f => findForm(f)), {uuid: form.uuid}, form)
            return ContentTypesService.resourceUpdate(contentType.id, {
                forms: ContentTypeForms.updateWithChangedForms(changedForms, ContentTypeConfiguration.prepareForms(forms))
            }).then(() => {
                reLoad()
            })
        } else {
            return new Promise<void>(resolve => this.setState({visible: false}, resolve))
        }
    }

    cancel() {
        this.setState({visible: false})
    }

    render() {
        const {visible, configurationStep} = this.state
        const {user, resource, match, history} = this.props

        return (
            <>
                {UsersService.hasCredential(user, 'configuration') && (
                    <>
                        {visible &&
                            <FormConfiguration contentType={this.getContentType()} history={history}
                                               match={match} update={(form, forms) => this.finish(form, forms)}
                                               form={resource} cancel={() => this.cancel()} step={configurationStep}/>}
                        <Dropdown
                            trigger={["click"]}
                            overlay={(
                                <Menu>
                                    <Menu.Item key="1" onClick={() => this.setState({visible: true})}>
                                        Upravit formulář
                                    </Menu.Item>
                                    <Menu.Item key="2">
                                        <Link to={"/app/configuration/content-types/" + this.getContentType().id}>
                                            Upravit typ obsahu
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key="3"
                                               onClick={() => this.setState({visible: true, configurationStep: 'editor'})}>
                                        Otevřít editor
                                    </Menu.Item>
                                </Menu>
                            )}
                        >
                            <Button
                                className={'mb-3'}
                                style={{verticalAlign: "middle"}}
                                icon={<SettingOutlined/>}
                                // shape="circle"
                                size={"small"}
                            />
                        </Dropdown>

                    </>
                )}
            </>
        )
    }

    getContentType(): IContentType {
        return this.props.findContentType(this.props.resource.contentType)
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    return {
        // findService: (contentType: IContentType) => selectors.services.findOneByContentType(state, contentType),
        findContentType: (uuid: string) => selectors.contentTypes.findOneBy(state, 'uuid', uuid),
        findForm: (uuid: string) => selectors.forms.findOneBy(state, 'uuid', uuid),
        user: state.setup.user
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        reLoad: () => dispatch(reLoad())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormEditButton)