import {Tooltip} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import React from "react";
import IFilterBase from "../IFilterBase";
import IFilterFunctions from "../IFilterFunctions";
import FilterDropDown from "../FilterDropDown";
import Approval from "../input/composite/Approval";


const CompositeApprovalFilter: IFilterBase = {
    handleSearch(_setSelectedKeys, _selectedKeys, confirm) {
        confirm();
    },

    handleReset(clearFilters, setValue) {
        clearFilters();
        setValue([])
    },

    build(item, field, state, setValue): IFilterFunctions {
        return {
            filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => {
                return (
                    <FilterDropDown onReset={() => this.handleReset(clearFilters, setValue)}
                                    onConfirm={() => this.handleSearch(setSelectedKeys, selectedKeys, confirm, setValue)}>
                        <Approval field={field} item={item} onChange={setSelectedKeys} selected={selectedKeys}/>
                    </FilterDropDown>
                )
            },
            filterIcon: (filtered: boolean): JSX.Element => {
                return (
                    <Tooltip title={filtered ? "Filtrováno: " + (state[0]?.value ? 'Povoleno' : 'Zakázáno') : ''}>
                        <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}}/>
                    </Tooltip>
                )
            },
            onFilter: (value, record): boolean => {
                return record[field.name] === value
            }
        }
    }
}

export default CompositeApprovalFilter