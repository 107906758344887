import IRestServiceFilters from "../../interface/api/IRestServiceFilters";
import IRestServiceFilterNested from "../../interface/api/IRestServiceFilterNested";

const FiltersService = {
    isNestedFilter: (object: any): object is IRestServiceFilterNested => {
        return 'children' in object;
    },
    makeFixed: (filters: IRestServiceFilters) => {
        Object.keys(filters).forEach(key => {
            const filter = filters[key]
            if (FiltersService.isNestedFilter(filter)) {
                filter.children = FiltersService.makeFixed(filter.children)
            } else {
                filter.fixed = true
            }
        })
        return filters
    }
}

export default FiltersService