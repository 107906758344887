import {Form, Input} from 'antd';
import React from "react";
import {IFieldOptionsEditorProps} from "../FieldOptionsEditor";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";

export interface IFieldBooleanOptions {
    trueLabel?: string,
    falseLabel?: string,
    nullLabel?: string
}

interface IProps extends IFieldOptionsEditorProps {

}

interface IState {
}

class FieldBooleanOptionsEditor extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {}
    }

    render() {
        const {buildFieldName} = this.props
        return (
            <div>
                <Form.Item name={buildFieldName('trueLabel')} initialValue={'Povoleno'} label={'Název pro kladnou logickou hodnotu'}>
                    <Input prefix={<CheckOutlined/>}/>
                </Form.Item>
                <Form.Item name={buildFieldName('falseLabel')} initialValue={'Zakázano'} label={'Název pro zápornou logickou hodnotu'}>
                    <Input prefix={<CloseOutlined/>}/>
                </Form.Item>
                <Form.Item name={buildFieldName('nullLabel')} initialValue={'není vyplněno'}
                           label={'Název pro prázdnou logickou hodnotu'}>
                    <Input/>
                </Form.Item>
            </div>
        )
    }
}


export default FieldBooleanOptionsEditor