import React from "react";
import {Breadcrumb, Checkbox, Popover, Row, Tooltip, Typography} from "antd";
import IRestResource from "../../../../../../model/interface/api/IRestResource";
import {PlusCircleFilled, MinusCircleFilled, EyeOutlined, QuestionCircleFilled} from "@ant-design/icons";
import Button from "../../../../../shared/button/Button";


export type ContentTypeChange = {
    path: string[]
    state: 'add' | 'update' | 'delete',
    detail: IRestResource
    properties: { [name: string]: string }
}

interface IState {

}

interface IProps {
    change: ContentTypeChange
    onChoose: (change: string[]) => void
    checked: boolean
}

class MaintenanceImportChange extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {}
    }

    onChoose = () => {
        const {onChoose, change} = this.props
        onChoose(change.path)
    }

    render() {
        const {change, checked} = this.props
        const propertiesKeys = Object.entries(change.properties).map(([key]) => key)

        return (
            <Row className={'px-2 py-1 border'} align={'middle'} justify={'space-between'}>
                <Row align={"middle"} className={'m-0'}>
                    <Tooltip title={'Povolit změnu'}>
                        <Checkbox onChange={this.onChoose} checked={checked}/>
                    </Tooltip>
                    <Tooltip title={{
                        delete: 'Vymazání',
                        add: 'Přidání',
                        update: 'Změna'
                    }[change.state]} className={'ml-2 d-flex'}>
                        {
                            {
                                delete: <MinusCircleFilled className={'text-danger font-size-md'}/>,
                                add: <PlusCircleFilled className={'text-success font-size-md'}/>,
                                update: <QuestionCircleFilled className={'text-primary font-size-md'}/>
                            }[change.state]
                        }
                    </Tooltip>
                    <Breadcrumb className={'font-weight-bold ml-2'}>
                        {change.path.map(item => (
                            <Breadcrumb.Item>{item + ''}</Breadcrumb.Item>
                        ))}
                        {change.path.length < 1 && (
                            <Breadcrumb.Item>{'contentType'}</Breadcrumb.Item>
                        )}
                    </Breadcrumb>
                </Row>
                <Popover title={'Podrobnosti změny'} content={
                    <div>
                        {Object.entries(change.detail).map(([key, value]) => (
                            <Row justify={"space-between"}
                                 className={'p-1' + (propertiesKeys.includes(key) ? ' bg-danger' : '')}>
                                <Typography.Text className={'mr-2'} strong>{key}:</Typography.Text>
                                <Typography.Text strong>
                                    {(propertiesKeys.includes(key) ? (change.properties[key] || 'null') + ' -> ' : '') + value}
                                </Typography.Text>
                            </Row>
                        ))}
                    </div>
                }>
                    <Button type={'link'} size={"small"} icon={<EyeOutlined/>}/>
                </Popover>
            </Row>
        )
    }
}

export default MaintenanceImportChange