import RestService from "model/service/dataStorage/RestService";
import IField from "../../../interface/dataStorage/IField";
import IRestServiceCollectionResponse from "../../../interface/api/IRestServiceCollectionResponse";
import IRepositoryService from "../../../interface/IRepositoryService";
import IRestServiceOptions from "../../../interface/api/IRestServiceOptions";
import IRestServiceChoiceListResponse from "../../../interface/api/IRestServiceChoiceListResponse";
import IPresenter from "../../../interface/dataStorage/IPresenter";
import ITimeTracker from "../../../interface/extension/ITimeTracker";
import MasterRepositoryService from "../MasterRepositoryService";

interface IRestTimeTrackerServiceCollectionResponse extends IRestServiceCollectionResponse {
    results: Array<ITimeTracker>
}

interface IRestTimeTrackerService extends IRepositoryService {
    collectionList(options?: IRestServiceOptions): Promise<IRestTimeTrackerServiceCollectionResponse>,

    collectionCreate(data: any): Promise<ITimeTracker>,

    resourceRetrieve(id: number | string): Promise<ITimeTracker>,

    resourceUpdate(id: number | string, data: any): Promise<ITimeTracker>,

    resourceDelete(id: number | string): Promise<void>
}

const LikesService_COLLECTION = 'extensions/time-tracker'

const presenters = [
    {
        type: 'label',
        name: 'default',
        label: 'Výchozí'
    } as IPresenter,
    {
        type: 'label',
        name: 'count',
        label: 'Pouze počet'
    } as IPresenter
]

class TimeTrackerService extends MasterRepositoryService implements IRestTimeTrackerService {
    private static instance?: TimeTrackerService

    public static getInstance(): TimeTrackerService {
        if (!TimeTrackerService.instance) {
            TimeTrackerService.instance = new TimeTrackerService()
        }
        return TimeTrackerService.instance
    }

    getFields(): IField[] {
        return this.getBaseFields();
    }

    getMasterClassName(): string {
        return this.getRecordClassName()
    }
    getBaseFields(): IField[] {
        return  [{
            uuid: '',
            name: 'name',
            label: 'Název',
            mode: "scalar",
            type: 'string',
            targetEntity: null,
            options: [],
            weight: 1,
            contentTypeId: null,
            contentTypeName: false,
            locked: true,
            arguments: {}
        }]
    }

    getRecordClassName() {
        return 'App\\DataStorage\\Entity\\Extension\\TimeTracker'
    }

    getTitle(): string {
        return "Líbí se mi";
    }

    collectionList(options?: IRestServiceOptions) {
        return RestService.collectionList(LikesService_COLLECTION, options) as Promise<IRestTimeTrackerServiceCollectionResponse>
    }

    collectionCreate(data: any) {
        return RestService.collectionCreate(LikesService_COLLECTION, data) as Promise<ITimeTracker>
    }

    resourceRetrieve(id: number | string) {
        return RestService.resourceRetrieve(LikesService_COLLECTION, id) as Promise<ITimeTracker>
    }

    resourceDelete(id: number | string) {
        return RestService.resourceDelete(LikesService_COLLECTION, id)
    }

    resourceUpdate(id: number | string, data: any) {
        return RestService.resourceUpdate(LikesService_COLLECTION, id, data) as Promise<ITimeTracker>
    }

    getStringValue(like: ITimeTracker): string {
        return ': (' + like.createdAt + ')' //TODO ???
    }

    getPresenter(name: string): IPresenter | null {
        return presenters.find(presenter => presenter.name === name) || null
    }

    getDefaultPresenter(): IPresenter {
        return this.getPresenter('default') as IPresenter
    }

    getPresenterList() {
        return presenters.map(presenter => {
            return {value: presenter.name, label: presenter.label}
        })
    }

    choiceList(presenterName: string, options?: IRestServiceOptions): Promise<IRestServiceChoiceListResponse> {
        return new Promise<IRestServiceChoiceListResponse>(resolve => resolve); //TODO ???
    }
}

export default TimeTrackerService