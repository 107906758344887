import React from "react"
import ICompositeFieldData from "../../../../model/interface/dataStorage/field/composite/ICompositeFieldData";
import FormCompositeFieldApproval
    from "../../../../components/app/configuration/form/FormElement/formField/FormCompositeFieldApproval";
import {IFilterInput} from "../../IFilterBase";
import {API_FILTER_TYPE} from "../../../../model/constants/ApiConstant";


interface IState {

}

interface IProps extends IFilterInput {

}

class Approval extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {}
    }

    render() {
        const {field, onChange, selected} = this.props

        return (
            <FormCompositeFieldApproval options={{
                ...field.options,
                allowChange: true,
                requireJustification: false,
                allowJustification: false
            }} value={selected[0]?.value} field={field} onChange={(data: ICompositeFieldData) => {
                onChange([{
                    type: data[field.name + "_result"] ? API_FILTER_TYPE.EQUAL : API_FILTER_TYPE.IS_NULL,
                    value: data[field.name + "_result"],
                    name: field.name
                }])
            }}/>
        )
    }
}

export default Approval