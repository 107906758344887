import RestService from "model/service/dataStorage/RestService";
import IRestServiceCollectionResponse from "../../../interface/api/IRestServiceCollectionResponse";
import IRestServiceOptions from "../../../interface/api/IRestServiceOptions";
import IPattern from "../../../interface/file/generated-document/IPattern";


interface IRestPatternsServiceCollectionResponse extends IRestServiceCollectionResponse {
    results: Array<IPattern>
}

interface IRestPatternsService {
    collectionList(options?: IRestServiceOptions): Promise<IRestPatternsServiceCollectionResponse>,

    collectionCreate(data: any): Promise<IPattern>,

    resourceRetrieve(id: number | string): Promise<IPattern>,

    resourceUpdate(id: number | string, data: any, options?: IRestServiceOptions): Promise<IPattern>,

    resourceDelete(id: number | string): Promise<void>,

    resourceDownload(id: number | string, fileName?: string): Promise<any>
}

const PatternsService_COLLECTION = 'generated-document-patterns'
const PatternsService_DOWNLOAD = 'generated-document-patterns-download'

const PatternsService: IRestPatternsService = {
    collectionList: function (options?) {
        return RestService.collectionList(PatternsService_COLLECTION, options as unknown as IRestServiceOptions) as Promise<IRestPatternsServiceCollectionResponse>
    },
    collectionCreate: function (data) {
        return RestService.collectionCreate(PatternsService_COLLECTION, data, {fileUpload: true}) as Promise<IPattern>
    },
    resourceRetrieve: function (id) {
        return RestService.resourceRetrieve(PatternsService_COLLECTION, id) as Promise<IPattern>
    },
    resourceDelete: function (id) {
        return RestService.resourceDelete(PatternsService_COLLECTION, id)
    },
    resourceUpdate: function (id, data, options) {
        return RestService.resourceUpdate(PatternsService_COLLECTION, id, data, options) as Promise<IPattern>
    },
    resourceDownload(id, fileName): Promise<any> {
        return RestService.resourceDownload('/' + PatternsService_DOWNLOAD + '/' + id, true, fileName)
    }
}

export default PatternsService