import React from "react"
import {Badge, Card, Checkbox, Col, Form, List, Modal, Popover, Row, Timeline, Tooltip, Typography} from "antd";
import IRestResource from "model/interface/api/IRestResource";
import IRepositoryService from "model/interface/IRepositoryService";
import IViewSettingsStructure from "model/interface/dataStorage/view/settings/IViewSettingsStructure";
import {connect, RootStateOrAny} from "react-redux";
import selectors from "../../../redux/selectors";
import moment, {Moment} from "moment";
import IContentType from "../../../model/interface/dataStorage/IContentType";
import Text from "antd/es/typography/Text";
import {API_FILTER_TYPE} from "../../../model/constants/ApiConstant";
import {DATE_FORMAT_YYYY_MM_DD, DATE_FORMAT_YYYY_MM_DD_HH_mm_ss} from "../../../model/constants/DateConstant";
import ViewAction from "./ViewAction";
import {IActionResult} from "../../../model/service/dataStorage/ActionsService";
import ViewCustomFilters, {DefaultCustomFilters} from "./ViewCustomFilters";
import {IBaseViewProps} from "./ViewUnit";
import Calendar, {CalendarMode, IEvent} from "components/shared/Calendar/Calendar";
import ViewPersonalEditButton from "./ViewPersonalEditButton";
import ViewEditButton from "./ViewEditButton";
import Utils from "../../../utils";
import {MomentBuilder} from "../../../utils/MomentBuilder";
import ActionButton from "../action/ActionButton";
import IAction, {ACTION_RESERVED_NAMES} from "../../../model/interface/dataStorage/IAction";
import IPresenter from "../../../model/interface/dataStorage/IPresenter";
import PresenterBuilder from "../../../views/dataStorage/PresenterBuilder";
import _ from "underscore";
import IRestServiceOptions from "../../../model/interface/api/IRestServiceOptions";
import {RefreshOutline} from "react-ionicons";
import ViewTitle from "./ViewTitle";
import ViewSchemeActions from "./ViewSchemeActions";
import {IFilterBaseValue} from "../../../utils/Filter/IFilterBase";
import FilterBuilder from "../../../utils/FilterBuilder";
import IRestServiceFilters, {isFilterNested} from "../../../model/interface/api/IRestServiceFilters";
import IField from "../../../model/interface/dataStorage/IField";
import IViewItem from "../../../model/interface/dataStorage/view/IViewItem";
import Collapse from "../../shared/display/collapse/Collapse";
import {FilterOutlined} from "@ant-design/icons";
import Button from "../../shared/button/Button";
import FilterEvaluationService from "../../../model/service/api/FilterEvaluationService";

const COLORS = [
    '#ff0000',
    '#407294',
    '#ff80ed',
    '#0000ff',
    '#ffff00',
    '#990000',
    '#daa520',
    '#065535',
    '#6897bb',
    '#f08080',
    '#660066',
    '#40e0d0',
    '#101010',
]

interface IState {
    results: { [uuid: string]: Array<IRestResource & IEvent> },
    total: number,
    loading: boolean,
    customizationDraft: IViewSettingsStructure | null,
    customization: IViewSettingsStructure,
    filters: { [contentType: string]: IRestServiceFilters | undefined }
    activeContentTypes: string[],
    month: Moment,
    minDateShown: Moment
    maxDateShown: Moment
    customFilters?: DefaultCustomFilters,
    renderHiddenActionButton?: JSX.Element,
    renderCreateActionModal?: boolean,
    selectedStart?: Moment,
    selectedEnd?: Moment,
    mode?: CalendarMode,
    showFilters?: boolean
}

interface IProps extends IBaseViewProps {
    findOneByFullClassName: (fullClassName: string) => IRepositoryService
    findOneByContentType: (contentType: IContentType) => IRepositoryService,
    findContentTypeByUuid: (uuid: string) => IContentType,
    extractRouteParametersFromUrl: (url: string) => null | { id: number, parameters: { [name: string]: any } }
}

class ViewCalendar extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            results: {} as { [id: number]: Array<IRestResource & IEvent> },
            filters: {},
            loading: false,
            total: 0,
            minDateShown: moment(),
            maxDateShown: moment(),
            month: moment(),
            customization: {
                items: null
            },
            customizationDraft: null,
            activeContentTypes: [] as number[],
            ...this.presetState(),
            customFilters: ViewCustomFilters.getDefault(props.settings.customFilters)
        }
    }

    componentDidMount() {
        this.load()
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (prevProps.reload !== this.props.reload && this.props.reload) {
            this.refresh()
        }
    }

    load = () => {
        if (this.state.activeContentTypes.length === 0) {
            const contentType = this.props.viewUnit.contentTypes[0]
            this.setState({activeContentTypes: [contentType]}, this.loadAllActive)
        } else {
            this.loadAllActive()
        }
    }

    loadResults(contentType: IContentType): Promise<void> {
        const {findOneByContentType} = this.props
        const {minDateShown, maxDateShown, customFilters, filters} = this.state
        this.setState({loading: true})
        let params: IRestServiceOptions = {
            filters: {
                0: {
                    field: 'startAt',
                    type: API_FILTER_TYPE.GREATER,
                    value: minDateShown.format(DATE_FORMAT_YYYY_MM_DD_HH_mm_ss)
                },
                1: {
                    field: 'endAt',
                    type: API_FILTER_TYPE.LESSER,
                    value: maxDateShown.format(DATE_FORMAT_YYYY_MM_DD_HH_mm_ss)
                },
                ...(customFilters?.[contentType.fullClassName] || {}),
                ...(filters?.[contentType.fullClassName] || {})
            },
            order: {
                0: {
                    field: 'startAt'
                }
            }
        }

        return findOneByContentType(contentType).collectionList(params).then(({count, results}: any) => {
            this.setState(prevState => ({
                results: {...prevState.results, [contentType.uuid]: results},
                total: count,
                loading: false
            }))
        })
    }

    presetState() {
        const {settings, state} = this.props
        let previousState: any | IState = {}
        if (settings.calendarSaveMonth && state.calendarMonth) {
            previousState.month = moment(state.calendarMonth)
        }
        if (settings.calendarSaveMode && state.calendarMode) {
            previousState.mode = state.calendarMode
        }
        if (settings.calendarSelectedContentTypes && state.calendarSelectedContentTypes) {
            previousState.activeContentTypes = state.calendarSelectedContentTypes
        }
        if (settings.calendarSaveFilters && state.calendarFilters) {
            previousState.filters = state.calendarFilters
            if (Object.keys(previousState.filters).length) {
                previousState.showFilters = true
            }
        }
        return previousState
    }

    saveState() {
        const {settings, saveState, state} = this.props
        const {activeContentTypes, month, mode, filters} = this.state
        let newState = {...state}
        if (settings.calendarSaveMonth) {
            newState.calendarMonth = month.format(DATE_FORMAT_YYYY_MM_DD)
        }
        if (settings.calendarSaveMode && mode) {
            newState.calendarMode = mode
        }
        if (settings.calendarSaveFilters && filters) {
            newState.calendarFilters = filters
        }
        if (settings.calendarSelectedContentTypes) {
            newState.calendarSelectedContentTypes = activeContentTypes
        }
        saveState({...newState}).then()
    }

    doAction = (result?: IActionResult) => {
        result && this.refresh()
        return new Promise<void>(resolve => resolve()) //TODO
    }

    onFinish = (result?: IActionResult): Promise<void> => {
        return Promise.resolve().then(() => {
            this.cancelSelection()
            if (this.props.onFinishAction) {
                return this.props.onFinishAction(result)
            } else {
                return this.refresh()
            }
        })
    }

    getPayload() {
        let payload = {} as any;
        payload.route = this.props.extractRouteParametersFromUrl(this.props.history.location.pathname)
        return payload;
    }

    dateCellRender = (event: IEvent) => {
        const {viewUnit, match, history} = this.props
        return <Popover trigger={["click", "hover"]} zIndex={1000} placement={"right"} content={
            <Row onClick={e => e.stopPropagation()}>
                <Col span={24}>
                    <Timeline>
                        <Timeline.Item color="green">{event.startAt.format('lll')}</Timeline.Item>
                        <Timeline.Item className={'pb-0'} color="red">
                            {event.endAt?.format('lll')}
                        </Timeline.Item>
                    </Timeline>
                </Col>
                <Col span={24}>
                    <Row gutter={[6, 6]}>
                        {viewUnit.entryActions
                            .filter(eA => event.contentType && this.getContentType(event.contentType).actions.find(a => eA.action === a.uuid))
                            .filter(viewAction => {
                                const action = _.findWhere(this.findActionContentType(viewAction.action).actions, {uuid: viewAction.action})
                                return action && event._permissions[action.name]
                            })
                            .map((action) => (
                                <Col>
                                    <ViewAction
                                        history={history}
                                        match={match}
                                        resources={[event]}
                                        key={action.uuid}
                                        action={action}
                                        payload={this.getPayload()}
                                        onFinish={this.onFinish}
                                    />
                                </Col>
                            ))}
                    </Row>

                </Col>
            </Row>
        } title={this.getLabel(event)}>
            <Text ellipsis={true} className={'font-size-sm h-100 width-100 d-block cursor-pointer'}
                  onClick={e => e?.stopPropagation()}>
                {this.getLabel(event)}
            </Text>
        </Popover>
    }

    getPresenter = (resource: IRestResource) => {
        const {findOneByContentType, viewUnit} = this.props;
        let presenter: null | IPresenter
        if (resource.contentType) {
            const service = findOneByContentType(this.getContentType(resource.contentType))
            presenter = service.getPresenter(viewUnit.options?.calendarPresenter?.[resource.contentType] || '')
            if (!presenter) {
                presenter = service.getDefaultPresenter()
            }
            presenter.options = {...presenter.options}
            return PresenterBuilder.build(presenter, resource, presenter.options) || resource.id + ''
        }
        return '(bez názvu)'
    }

    getLabel(event: IEvent) {
        return this.getPresenter(event)
    }

    showFilters = () => {
        this.setState(state => ({showFilters: !state.showFilters}))
    }

    buildEvents() {
        const events: IEvent[] = []
        this.state.activeContentTypes.forEach(uuid => {
            this.state.results[uuid]?.forEach(event => {
                let color = this.getSettingsColor(this.props.findContentTypeByUuid(uuid), event)
                color = color ? color : COLORS[this.props.viewUnit.contentTypes
                    .findIndex(contentType => contentType === uuid) % COLORS.length]
                events.push(this.createEvent(event, color, uuid))
            })
        })
        return events
    }

    createEvent(event: IRestResource & IEvent, color: string, contentType: string): IEvent {
        return {
            ...event,
            startAt: MomentBuilder.build(event.startAt),
            endAt: MomentBuilder.build(event.endAt),
            color,
            key: event.uuid || Utils.uuid(),
            resource: event,
            contentType
        };
    }

    isBetween(date: moment.Moment, value: IRestResource) {
        return date.isBetween(moment(value.startAt.raw), moment(value.endAt.raw), 'day', '[]');
    }

    updateResults = (contentType: IContentType) => {
        this.setState(prevState => {
            const exists = this.state.activeContentTypes.includes(contentType.uuid)
            return exists ? {activeContentTypes: prevState.activeContentTypes.filter(type => type !== contentType.uuid)} :
                {activeContentTypes: [...prevState.activeContentTypes, contentType.uuid]}
        }, () => {
            this.loadResults(contentType).then()
            this.saveState()
        })
    }

    selectAll = () => {
        this.setState({
            activeContentTypes: !this.isCheckedAll() ? this.props.viewUnit.contentTypes.map(type => type) : []
        })
        this.loadAll();
    }

    loadAll() {
        this.props.viewUnit.contentTypes.forEach(contentType => {
            this.loadResults(this.getContentType(contentType)).then()
        })
    }

    loadActive() {
        this.state.activeContentTypes.forEach(contentTypeId => {
            this.loadResults(this.getContentType(contentTypeId)).then()
        })
    }

    getContentType(uuid: string) {
        return this.props.findContentTypeByUuid(uuid)
    }

    refresh = () => {
        this.setState({results: {}}, this.loadActive)
    }

    isCheckedAll() {
        return this.state.activeContentTypes.length === this.props.viewUnit.contentTypes.length;
    }

    onSelect = (start: Moment, end: Moment) => {
        const {viewUnit} = this.props
        if (viewUnit.contentTypes.length > 1) {
            this.setState({renderCreateActionModal: true, selectedStart: start, selectedEnd: end})
        } else {
            this.setState({
                renderHiddenActionButton: this.getActionButton(start, end, this.getContentType(viewUnit.contentTypes[0]), true)
            })
        }
    }

    getActionButton(start: Moment, end: Moment, contentType: IContentType, trigger = false) {
        const actions = contentType.actions
        const action = this.getCreateAction(actions);

        return action && this.props.permissions[contentType.fullClassName] && this.props.permissions[contentType.fullClassName][action.name] ?
            <ActionButton
                options={{
                    label: '',
                    icon: 'antd.PlusOutlined',
                    type: 'info'
                }}
                onClick={(execute) => {
                    this.setState({loading: true})
                    return execute()
                }}
                key={action.uuid} action={action}
                payload={{
                    data: {
                        startAt: start.format(DATE_FORMAT_YYYY_MM_DD_HH_mm_ss),
                        endAt: end.format(DATE_FORMAT_YYYY_MM_DD_HH_mm_ss)
                    }
                }}
                onFinish={this.onFinish}
                triggerClickRender={trigger}
            /> : <></>
    }

    getCreateAction(actions: IAction[]) {
        return actions.find(a => [ACTION_RESERVED_NAMES.CALENDAR_CREATE].includes(a.name)) || actions.find(a => ['create'].includes(a.name));
    }

    onChange = (date: Moment, min: Moment, max: Moment, mode: CalendarMode) => {
        const {maxDateShown, minDateShown} = this.state
        if (maxDateShown < max || minDateShown > min) {
            this.setState({results: {}, minDateShown: min, maxDateShown: max}, () =>
                this.loadAllActive()
            )
        }
        this.setState({month: date, mode}, this.saveState)
    }

    loadAllActive() {
        this.state.activeContentTypes.forEach(uuid => {
            this.loadResults(this.props.findContentTypeByUuid(uuid)).then()
        });
    }

    getSettingsColor(contentType: IContentType, resource?: IRestResource) {
        const {settings} = this.props
        const conditionalColors = settings.calendarConditionalColors?.[contentType.fullClassName]
        if (conditionalColors && resource){
            let color = ''
            const found = conditionalColors.some(function (c) {
                    color = c.color
                    return !c.conditions || Object.keys(c.conditions).length === 0 || FilterEvaluationService.evaluate(c.conditions, resource, contentType)
                })
            return found ? color : null
        }
        return settings.calendarColors?.[contentType.fullClassName]
    }

    onCustomFilterChange = (filters?: DefaultCustomFilters) => {
        this.setState({customFilters: filters}, this.refresh)
    }

    onFilterChange = (filters: IFilterBaseValue[], contentType: string) => {
        const output: IRestServiceFilters = {}
        filters.forEach((filter, index) => {
            output[filter.name + index] = {
                field: filter.name,
                type: filter.type,
                value: filter.value,
                label: filter.label
            }
        })
        this.setState(state => ({
            filters: {
                ...state.filters, [contentType]: {...state.filters[contentType], ...output}
            }
        }), () => {
            this.saveState()
            this.refresh()
        })
    }

    findActionContentType(uuid: string) {
        return this.getContentType(
            this.props.viewUnit.contentTypes
                .find(c => this.getContentType(c).actions.find(a => a.uuid === uuid)) || '')
    }

    cancelSelection = () => {
        this.setState({
            renderCreateActionModal: false,
            selectedStart: undefined,
            selectedEnd: undefined,
            renderHiddenActionButton: undefined
        })
    }

    clearFilters = () => {
        this.setState({filters: {}}, () => {
            this.refresh()
        })
    }

    render() {
        const {viewUnit, settings, history, match, permissions, insideTab, standalone} = this.props
        const {
            loading,
            activeContentTypes,
            month,
            mode,
            renderHiddenActionButton,
            renderCreateActionModal,
            selectedStart,
            selectedEnd,
            filters,
            showFilters
        } = this.state;

        return (
            <>
                <Row justify={"space-between"}>
                    <ViewTitle hideTitle={insideTab || !standalone} viewUnit={viewUnit}>
                        <Row className={"noPrint"} gutter={[4, 6]}>
                            {activeContentTypes.map(contentType => {
                                const contentTypeObject = this.getContentType(contentType)
                                return <ViewSchemeActions onFinish={this.onFinish} contentType={contentTypeObject}
                                                          permissions={permissions} viewUnit={viewUnit} match={match}
                                                          history={history}/>
                            })}
                            <Col>
                                <Tooltip title={"Znovu načíst data"}>
                                    <Button
                                        onClick={() => this.refresh()}
                                        style={{verticalAlign: "middle"}}
                                        icon={<RefreshOutline/>}
                                        size={"small"}
                                    />
                                </Tooltip>
                            </Col>
                            <ViewPersonalEditButton {...this.props}/>
                            <ViewEditButton {...this.props}/>
                            <Col>
                                <Button type={Object.keys(filters).length ? 'info' : undefined} size={"small"}
                                        onClick={this.showFilters}
                                        icon={<FilterOutlined/>}/>
                            </Col>
                            {Object.keys(filters).length > 0 && <Col>
                                <Button type={"link"} size={"small"} onClick={this.clearFilters}>Zrušit filtry</Button>
                            </Col>}
                        </Row>
                    </ViewTitle>
                </Row>
                <Row>
                    {settings.customFilters && (
                        <Col>
                            <ViewCustomFilters filters={settings.customFilters}
                                               onChange={this.onCustomFilterChange}/>
                        </Col>
                    )}
                </Row>

                {viewUnit.contentTypes.map((contentType, index) => {
                    const contentTypeObject = this.getContentType(contentType)
                    const filterItems = settings.calendarFilters?.[contentTypeObject.uuid]
                    let items: IViewItem[] = []
                    if (filterItems){
                        filterItems?.forEach(filter => {
                            const viewItem = viewUnit.items.find(i => i.field === filter.field && contentTypeObject.fields.some(f => f.uuid === i.field))
                            if (viewItem){
                                items.push(viewItem)
                            }
                        })
                    } else {
                        items = viewUnit.items.filter(i => contentTypeObject.fields.some(f => f.uuid === i.field))
                    }
                    const defaultItems = showFilters ? false : items.filter(i => filterItems?.some(f => f.field === i.field && f.defaultVisible && f.enabled))
                    items = defaultItems || items
                    return items.length > 0 ? <Collapse collapsed={!showFilters && !defaultItems}>
                        <div className={'my-2'}>{viewUnit.contentTypes.length < 2 ?
                            this.renderFilterCard(defaultItems || items, contentTypeObject, filters) :
                            <Card key={index} size={"small"}
                                  title={contentTypeObject.label || contentTypeObject.name}>
                                {this.renderFilterCard(defaultItems || items, contentTypeObject, filters)}
                            </Card>}
                        </div>
                    </Collapse> : <></>
                })}

                <Row gutter={20}>
                    {viewUnit.contentTypes.length > 1 && (
                        <Col xs={24} xxl={4}>
                            <List
                                header={(
                                    <Typography.Title className={'m-0'} level={4}>
                                        <Row justify={"space-between"}>
                                            <Text>Typy obsahu</Text>
                                            <Checkbox checked={this.isCheckedAll()} onChange={this.selectAll}
                                                      className={'ml-2'}/>
                                        </Row>
                                    </Typography.Title>
                                )}>
                                {viewUnit.contentTypes.map((contentType, index) => {
                                    const contentTypeObject = this.getContentType(contentType)
                                    const color = this.getSettingsColor(contentTypeObject)
                                    return (
                                        <List.Item key={index} onClick={() => this.updateResults(contentTypeObject)}
                                                   className={"cursor-pointer"}>
                                            <Badge color={color ? color : COLORS[index]}
                                                   text={contentTypeObject.label || contentTypeObject.name}/>
                                            <Checkbox checked={activeContentTypes.includes(contentType)}/>
                                        </List.Item>
                                    )
                                })}
                            </List>
                        </Col>
                    )}
                    <Col xxl={viewUnit.contentTypes.length > 1 ? 20 : 24} xs={24}>
                        <Calendar
                            date={month}
                            defaultMode={mode}
                            onSelect={this.onSelect}
                            customHeaderItems={[]}
                            eventRender={this.dateCellRender}
                            onChange={this.onChange}
                            loading={loading}
                            events={this.buildEvents()}
                            highlightWeekend={settings.calendarHighlightWeekend}
                            highlightHolidays={settings.calendarHighlightHolidays}
                        />
                    </Col>
                </Row>
                <div hidden={true}>
                    {renderHiddenActionButton}
                </div>
                {renderCreateActionModal && selectedStart && selectedEnd && (
                    <Modal visible={true} title={'Vyberte, ve které agendě chcete záznam vytvořit'}
                           cancelText={'zrušit'}
                           onCancel={this.cancelSelection}
                           okButtonProps={{hidden: true}}>
                        <Row>
                            {viewUnit.contentTypes.map(contentType => {
                                const contentTypeObject = this.getContentType(contentType)
                                const button = this.getActionButton(selectedStart, selectedEnd, contentTypeObject)
                                return !!button && (
                                    <Col className={'border-bottom w-100 py-2'} key={contentType}>
                                        <Row justify={"space-between"} className={'w-100'} align={'middle'}>
                                            <Typography.Text strong>
                                                {contentTypeObject.label || contentTypeObject.name}
                                            </Typography.Text>
                                            {button}
                                        </Row>
                                    </Col>
                                )
                            })}
                        </Row>
                    </Modal>
                )}
            </>
        )
    }

    renderFilterCard(items: IViewItem[], contentTypeObject: IContentType, filters: DefaultCustomFilters) {
        return <>
            {items.map(item => {
                const field = contentTypeObject.fields.find(f => f.uuid === item.field)
                return field && <Form.Item label={item.label || field.label} key={item.uuid}>
                    {FilterBuilder.buildInput(item, field, this.getFilter(filters, contentTypeObject, field), (filters) => this.onFilterChange(filters, contentTypeObject.fullClassName))}
                </Form.Item>
            })}
        </>;
    }

    getFilter(filters: DefaultCustomFilters, contentTypeObject: IContentType, field: IField) {
        let match: IFilterBaseValue[] = []
        Object.values(filters[contentTypeObject.fullClassName] || {}).forEach(f => {
            if (!isFilterNested(f) && f.field === field.name) {
                match.push({...f, name: f.field})
            }
        })
        return match
    }
}


const
    mapStateToProps = (state: RootStateOrAny) => {
        return {
            findOneByContentType: (contentType: IContentType) => selectors.services.findOneByContentType(state, contentType),
            findOneByFullClassName: (fullClassName: string) => selectors.services.findOneByFullClassName(state, fullClassName),
            findContentTypeByUuid: (uuid: string) => selectors.contentTypes.findOneBy(state, 'uuid', uuid),
            extractRouteParametersFromUrl: (url: string) => selectors.routes.extractRouteParametersFromUrl(state, url)
        }
    }

export default connect(mapStateToProps)

(
    ViewCalendar
)