import FormElementType from "./FormElementType";
import WidgetList, {IWidgetListItem, WidgetListManager} from "../../widget/WidgetList";
import WidgetType from "../../widget/WidgetType";
import IFormStructureNode from "../../../../../model/interface/form/IFormStructureNode";
import {antIcons} from "utils/Icons/AntIcons";
import {ThunderboltOutlined} from "@ant-design/icons";
import React from "react";


class FormElementListManager extends WidgetListManager<IFormStructureNode> {

    widgets: IWidgetListItem[] = [
        {
            type: FormElementType.SUBMIT,
            group: WidgetType.GROUP_STATIC,
            label: "Tlačítko",
            icon: antIcons.SendOutlined
        },
        {
            type: FormElementType.BUTTONS,
            group: WidgetType.GROUP_STATIC,
            label: 'Tlačítka',
            icon: antIcons.AppstoreAddOutlined
        },
        {
            type: FormElementType.COMMENT,
            group: FormElementType.GROUP_FIELD,
            label: 'Komentáře',
            icon: antIcons.CommentOutlined
        },
        {
            type: FormElementType.ENTITY_TABLE,
            group: FormElementType.GROUP_FIELD,
            label: 'Tabulka entit',
            icon: antIcons.TableOutlined,
            hidden: true,
            add: true,
        },
        {
            type: FormElementType.ENTITY_TABLE_COLUMN,
            group: FormElementType.GROUP_FIELD,
            label: 'Tabulka entit sloupec',
            icon: antIcons.InsertRowRightOutlined,
            hidden: true
        },
        {
            type: FormElementType.FIELD,
            group: FormElementType.GROUP_FIELD,
            label: 'Pole',
            icon: antIcons.TagOutlined,
            hidden: true
        },
        {
            type: FormElementType.ACTION,
            label: 'Akce',
            group: FormElementType.GROUP_ACTION,
            icon: <ThunderboltOutlined/>,
            hidden: true
        },
        ...WidgetList.getAll(),
    ]

    getGrouped = () => {
        let groups = {
            ...WidgetList.getGrouped(),
            [FormElementType.GROUP_FIELD]: {label: 'Vlastnosti', widgets: []},
            [FormElementType.GROUP_ACTION]: {label: 'Akce', widgets: []}
        } as { [group: string]: { label: string, widgets: IWidgetListItem[] } }
       return this.mapWidgetsToGroups(groups)
    }

    getByType = (type: string): IFormStructureNode => {
        const itemDefault = {type, label: ''}
        const item = this.widgets.find(widget => widget.type === type) as IWidgetListItem
        if (item || [FormElementType.GROUP_ACTION, FormElementType.GROUP_FIELD].includes(type)) {
            const {label, type} = (item || itemDefault)
            return {
                label,
                type,
                weight: 1,
                key: '',
                children: [],
                id: '',
                options: {},
                parent: null
            }
        }
        throw new Error(`Report widget type[${type}] does not exist!`)
    }
}

const FormElementList = new FormElementListManager()
export default FormElementList
