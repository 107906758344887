import React from "react";
import {Select} from "antd";
import IFieldOptions from "../../../../../../model/interface/form/elementOptions/IFieldOptions";

class FormFieldSelect {
    public static render = (options: IFieldOptions) => {
        const props = {
            style: options.style,
            placeholder: options.placeholder,
            allowClear: options.showClear,
            disabled: options.disabled,
            value: options.value
        };
        return (
            <Select {...props} mode={options.multiple ? 'multiple' : undefined}>
                {options.selectOptions?.map(option => (
                    <Select.Option key={option.value} value={option.value}>{option.title}</Select.Option>
                ))}
            </Select>
        )
    }
}

export default FormFieldSelect