import React, {RefObject} from "react"
import {Col, Divider, Form, FormInstance, Input, message, Radio, Row} from "antd";
import {DeleteOutlined, SaveOutlined} from "@ant-design/icons";
import Modal from "../../shared/modal/Modal";
import ColorPicker from "../pickers/ColorPicker";
import IButtonType from "../../../model/interface/ui/IButtonType";
import ButtonTypesService from "../../../model/service/ui/ButtonTypesService";
import {connect} from "react-redux";
import {IAppState} from "../../../redux/store";
import {update} from "../../../redux/actions/Setup";
import {ISetupState} from "../../../redux/reducers/Setup";
import Button from "./Button";

interface IProps {
    buttonType?: IButtonType
    buttonTypes: IButtonType[]
    onChange: (buttonType?: IButtonType) => void
    modal?: boolean
    update: (changes: { [K in keyof ISetupState]?: ISetupState[K] }) => void,
}

interface IState {
    loading: boolean
    deleting: boolean
    values: { [K in keyof IButtonType]?: IButtonType[K] }
}

class ButtonTypeForm extends React.Component<IProps, IState> {

    formRef: RefObject<FormInstance> = React.createRef()

    constructor(props: IProps) {
        super(props);
        this.state = {
            loading: false,
            deleting: false,
            values: props.buttonType || {}
        }
    }

    onOk = () => {
        const {onChange, buttonType} = this.props
        this.formRef.current?.validateFields()
            .then(values => {
                this.setState({loading: true})
                this.save({
                    ...buttonType, ...values
                })
                    .then(resource => {
                        onChange(resource)
                        message.success('Typ uložen').then()
                    })
            })
    }

    remove = async () => {
        const {buttonType} = this.props
        if (!buttonType) {
            return
        }
        this.setState({deleting: true})
        const {update, buttonTypes} = this.props
        await ButtonTypesService.resourceDelete(buttonType.uuid);
        this.setState({deleting: false})
        update({buttonTypes: [...buttonTypes.filter(c => c.uuid !== buttonType.uuid)]})
        return buttonType
    }

    async save(buttonType: IButtonType) {
        const {update, buttonTypes} = this.props
        let promise: Promise<IButtonType>
        if (buttonType.id) {
            promise = ButtonTypesService.resourceUpdate(buttonType.id, buttonType)
        } else {
            promise = ButtonTypesService.collectionCreate(buttonType)
        }
        const type = await promise;
        update({buttonTypes: [...buttonTypes.filter(c => c.uuid !== type.uuid), type]})
        return type
    }

    onCancel = () => {
        this.props.onChange()
    }

    onValuesChange = (values: any) => {
        this.setState({values})
    }


    render() {
        const {values} = this.state
        const {buttonType, modal} = this.props

        const form = <div>
            <Form style={{position: "relative", zIndex: 100}} ref={this.formRef} initialValues={{...buttonType}}
                  layout={"horizontal"}
                  onValuesChange={(_, values) => this.onValuesChange(values)}>
                <Form.Item label={"Název"} name={"label"} rules={[{required: true, message: 'pole je povinné'}]}>
                    <Input/>
                </Form.Item>
                <Form.Item name={'shape'} label={'Tvar'} initialValue={undefined}>
                    <Radio.Group>
                        <Radio value={undefined}>standart</Radio>
                        <Radio value={'round'}>ovál</Radio>
                        <Radio value={'circle'}>kruh</Radio>
                    </Radio.Group>
                </Form.Item>
                <Row align={'middle'}>
                    <Col flex={'1 0'}>
                        <Form.Item label={"Barva"} name={"color"}
                                   rules={[{required: true, message: 'pole je povinné'}]}>
                            <ColorPicker/>
                        </Form.Item>
                        <Form.Item name={'textColor'} label={'Barva textu'}>
                            <ColorPicker/>
                        </Form.Item>
                    </Col>
                    <Col className={'pl-5'}>
                        <Button isCustomDark={() => false} previewType={{...buttonType, ...values} as IButtonType}>
                            {values.shape !== 'circle' ? 'Ukazka' : '+'}
                        </Button>
                    </Col>
                </Row>
                <Divider>Tmavý režim</Divider>
                <Row align={'middle'}>
                    <Col flex={'1 0'}>
                        <Form.Item label={"Barva"} name={"colorDark"}>
                            <ColorPicker/>
                        </Form.Item>
                        <Form.Item name={'textColorDark'} label={'Barva textu'}>
                            <ColorPicker/>
                        </Form.Item>
                    </Col>
                    <Col className={'pl-5'}>
                        <Button isCustomDark={() => true}
                                previewType={{...buttonType, ...values} as IButtonType}>
                            {values.shape !== 'circle' ? 'Ukazka' : '+'}
                        </Button>
                    </Col>
                </Row>
            </Form>
            {!modal && (
                <div className={'mt-3'}>
                    {this.renderFooter()}
                </div>
            )}
        </div>

        return modal ? (<Modal
                width={700}
                title={buttonType ? 'Upravit typ tlačítka' : 'Vytvořit typ tlačítka'}
                visible={true}
                fullScreenOption={true}
                destroyOnClose={true}
                onOk={() => this.onOk()}
                onCancel={this.onCancel}
                closable={true}
                footer={[
                    this.renderFooter()

                ]}
            >
                {form}
            </Modal>
        ) : form
    }

    renderFooter() {
        const {buttonType} = this.props
        const {loading, deleting} = this.state
        return <Row className={'w-100'} justify={"space-between"}>
            {buttonType ? <Button danger loading={deleting} onClick={this.remove} icon={<DeleteOutlined/>}>
                Smazat
            </Button> : <div></div>}
            <Button type="primary" loading={loading} onClick={this.onOk} icon={<SaveOutlined/>}>
                Uložit
            </Button>
        </Row>;
    }
}

const mapStateToProps = (state: IAppState) => {
    return {
        buttonTypes: state.setup.buttonTypes,
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        update: (changes: any) => dispatch(update(changes))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ButtonTypeForm)
