import React from "react";
import ICardWidgetProps from "../../../../../../model/interface/dataStorage/card/ICardWidgetProps";
import ICardWidgetContainer from "../../../../../../model/interface/dataStorage/card/ICardWidgetContainer";
import {motion} from "framer-motion";
import MainContainerWidget from "../../../widget/ContainerWidget";
import ConditionEditor from "../../../form/FormElement/optionEditor/ConditionEditor";

interface IState{
    conditionMet: boolean
}

class ContainerWidget extends React.Component<ICardWidgetProps<ICardWidgetContainer>, IState> {

    constructor(props: ICardWidgetProps<ICardWidgetContainer>) {
        super(props);
        this.state = {
            conditionMet: false
        }
    }

    componentDidMount() {
        const state = ContainerWidget.getDerivedStateFromProps(this.props)
        this.setState({conditionMet: !!state?.conditionMet})
    }

    static getDerivedStateFromProps(props: ICardWidgetProps<ICardWidgetContainer>) {
        const {functions, preview, options} = props
        if (preview && options.conditions) {
            return {conditionMet: ConditionEditor.evaluateConditions(options.conditions, functions.getValue)}
        }
        return null
    }

    render() {
        const {preview} = this.props
        const {conditionMet} = this.state

        return (
            <motion.div className={!preview ? '' : "overflow-hidden"}
                        initial={{height: conditionMet ? "0px" : "auto", display: conditionMet ? "none" : "block"}}
                        transition={{duration: 0.5}}
                        animate={{
                            height:  conditionMet ? "0px" : "auto",
                            transitionEnd: {
                                display: conditionMet ? "none" : "block",
                            }
                        }}
            >
                <MainContainerWidget {...this.props}></MainContainerWidget>
            </motion.div>
        );
    }
}

export default ContainerWidget