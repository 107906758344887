import React from "react";
import FormElementField from "./FormElement/FormElementField";
import FormElementAction from "./FormElement/FormElementAction";
import IFormElementProps from "../../../../model/interface/form/IFormElementProps";
import FormElementButtons from "./FormElement/FormElementButtons";
import FormElementType from "./FormElement/FormElementType";
import FormElementSubmit from "./FormElement/FormElementSubmit";
import Widget from "../widget/Widget";
import FormElementContainer from "./FormElement/FormElementContainer";
import FormElementComment from "./FormElement/FormElementComment";
import IWidgetProps from "../../../../model/interface/widget/IWidgetProps";
import IFormElementFunctions from "../../../../model/interface/form/IFormElementFunctions";
import FormElementEntityTableWrapper from "./FormElement/FormElementEntityTableWrapper";

class FormElement extends React.Component<IFormElementProps> {

    static getWidget(type: string, props: IWidgetProps<IFormElementFunctions, any>) {
        switch (type) {
            case(FormElementType.FIELD):
                return <FormElementField {...props} />
            case(FormElementType.ACTION):
                return <FormElementAction {...props} />
            case(FormElementType.BUTTONS):
                return <FormElementButtons {...props} />
            case(FormElementType.SUBMIT):
                return <FormElementSubmit {...props} />
            case(FormElementType.CONTAINER):
                return <FormElementContainer {...props} />
            case(FormElementType.COMMENT):
                return <FormElementComment {...props} />
            case(FormElementType.ENTITY_TABLE):
                return <FormElementEntityTableWrapper {...props} />
            default:
                return Widget.getWidget(type, props)
        }
    }

    render(): JSX.Element {
        return Widget.renderWidget({...this.props, customWidget: FormElement}, FormElement.getWidget)
    }
}

export default FormElement