import React from "react";
import IWidgetProps from "../../../../model/interface/widget/IWidgetProps";
import WidgetChildren from "./WidgetChildren";


class TabWidget extends React.Component<IWidgetProps> {
    render() {
        return (
           <>
               <WidgetChildren {...this.props}/>
           </>
        );
    }
}

export default TabWidget