import ICard from "../../interface/dataStorage/ICard";
import FilterEvaluationService from "../api/FilterEvaluationService";
import IRestResource from "../../interface/api/IRestResource";
import IContentType from "../../interface/dataStorage/IContentType";
import Utils from "../../../utils";
import ICardWidget from "../../interface/dataStorage/card/ICardWidget";

// interface IRestCardsServiceCollectionResponse extends IRestServiceCollectionResponse {
//     results: Array<ICard>
// }

interface IRestCardsService {
    // collectionList(options?: IRestServiceOptions): Promise<IRestCardsServiceCollectionResponse>,
    //
    // collectionCreate(data: any, options?: IRestServiceOptions): Promise<ICard>,
    //
    // resourceRetrieve(id: number | string, options?: IRestServiceOptions): Promise<ICard>,
    //
    // resourceUpdate(id: number | string, data: any, options?: IRestServiceOptions): Promise<ICard>,
    //
    // resourceDelete(id: number | string): Promise<void>,

    findAvailableCard(cards: ICard[], resource: IRestResource, findContentType: (uuid: string) => IContentType): ICard | null,

    clone(card: ICard, routePriority?: number): ICard
}

//const CardsService_COLLECTION = 'cards'


const CardsService: IRestCardsService = {
    // collectionList: function (options?) {
    //     return RestService.collectionList(CardsService_COLLECTION, options as unknown as IRestServiceOptions) as Promise<IRestCardsServiceCollectionResponse>
    // },
    // collectionCreate: function (data, options?: IRestServiceOptions) {
    //     return RestService.collectionCreate(CardsService_COLLECTION, data, options) as Promise<ICard>
    // },
    // resourceRetrieve: function (id, options?: IRestServiceOptions) {
    //     return RestService.resourceRetrieve(CardsService_COLLECTION, id, options) as Promise<ICard>
    // },
    // resourceDelete: function (id) {
    //     return RestService.resourceDelete(CardsService_COLLECTION, id)
    // },
    // resourceUpdate: function (id, data, options?: IRestServiceOptions) {
    //     return RestService.resourceUpdate(CardsService_COLLECTION, id, data, options) as Promise<ICard>
    // },
    findAvailableCard(cards, resource, findContentType): ICard | null {
        let card: ICard | null = null
        const found = cards.sort((a, b) => (a.routePriority || 0) - (b.routePriority || 0))
            .some(function (c) {
                card = c
                return !c.conditions || Object.keys(c.conditions).length === 0 || FilterEvaluationService.evaluate(c.conditions, resource, findContentType(c.contentType))
            })
        return found ? card : null
    },
    clone(card, routePriority): ICard {
        const cloneWidgets = (card: ICard): ICardWidget[] => {
            let uuidMap: { old: string, new: string }[] = []
            uuidMap = card.widgets.map(w => ({old: w.uuid, new: Utils.uuid()}))
            return card.widgets.map(w => ({
                ...w,
                id: '',
                uuid: uuidMap.find(u => u.old === w.uuid)?.new || '',
                parent: uuidMap.find(u => u.old === w.parent)?.new || null
            }))
        }

        return {
            ...card,
            uuid: Utils.uuid(),
            name: card.name + " (copy)",
            widgets: cloneWidgets(card),
            id: undefined,
            routePriority
        }
    }
}

export default CardsService