import {Key} from "react";

export type ITreeNode<T = any> = {
    children: (ITreeNode & T)[],
    parent: number | string | null,
    id: number | string,
    disabled?: boolean
    key: string | number
    [name: string]: any
}

export type INode = {
    children: (number | string)[],
    parent: number | string | null,
    disabled?: boolean
    id: number | string,
    key: string | number
    weight?: number
    [name: string]: any
}


type Structure<T> = {
    [index in (number | string)]: T
}

export interface ITreeStructure<T> extends Structure<T> {
}

class TreeStructure {
    static build<T extends INode>(nodeList: ITreeStructure<T>) {
        let tree: (T & ITreeNode)[] = []

        Object.keys(nodeList).forEach(key => {
            let node = nodeList[key]
            !node.parent && nodeList[node.id] && tree.push(this.buildNode(node.id, nodeList))
        })

        return tree
    }

    private static buildNode<T extends INode>(id: number | string, nodeList: ITreeStructure<T>) {
        const node = {...nodeList[id], key: id}
        let children: ITreeNode[] = []
        Object.entries(nodeList).forEach(([, node]) => {
            if (node.parent === id) {
                children.push(this.buildNode(node.id, nodeList))
            }
        })
        if (!children.length) {
            return {...node, children: []}
        }

        return {...node, children: children}
    }

    static buildJson<T extends INode>(nodeList: ITreeStructure<T>, replacer?: (string | number)[] | null | undefined, space?: number) {
        return JSON.stringify(this.build(nodeList), replacer, space)
    }

    static destruct<T extends ITreeNode>(tree: T) {
        let structure: ITreeStructure<T & INode> = {}
        return this.destructNode(structure, tree)
    }

    private static destructNode<T extends ITreeNode>(structure: ITreeStructure<T & INode>, node: T, parent?: ITreeNode) {

        structure[node.id] = {
            ...node,
            parent: parent ? parent.id : null,
            children: []
        }
        if (parent) {
            structure[parent.id].children.push(node.id)
        }
        if (node.children) {
            node.children.forEach((child: any) => {
                structure = this.destructNode(structure, child, node)
            })
        }
        return structure
    }

    static sortChildren<T extends INode>(id: number | string, nodeList: ITreeStructure<T>, sortProperty: string, desc: boolean = false) {
        const node = this.getNode(id, nodeList)
        let childrenNodes: T[] = []
        node.children.forEach((childId) => {
            nodeList[childId] && childrenNodes.push(nodeList[childId])
        })

        return childrenNodes.sort((a, b) =>
            (a[sortProperty] > b[sortProperty]) ? (desc ? -1 : 1) :
                ((b[sortProperty] > a[sortProperty]) ? (desc ? 1 : -1) : 0))
    }

    static isParent<T extends INode>(parent: Key, child: Key, nodeList: ITreeStructure<T>): boolean {
        const node = this.getNode(child, nodeList)
        return !!node.parent && (node.parent === parent || this.isParent(parent, node.parent, nodeList))
    }

    static updatePositionProperty<T extends INode>(id: number | string, list: ITreeStructure<T>, property: string) {
        const node = this.getNode(id, list)
        if (node.parent) {
            const parent = this.getNode(node.parent, list)
            parent.children.forEach((child, index) => {
                list[child] = {...this.getNode(child, list), [property]: index}
            })
        }

        return list
    }

    private static getNode<T extends INode>(id: number | string, nodeList: ITreeStructure<T>) {
        if (!nodeList[id]) {
            throw new Error('Tree structure received an invalid id index!')
        }
        return nodeList[id]
    }
}

export default TreeStructure




