import RestService from "model/service/dataStorage/RestService";
import IRestServiceOptions from "../../interface/api/IRestServiceOptions";
import IRestServiceCollectionResponse from "../../interface/api/IRestServiceCollectionResponse";
import IReport from "../../interface/dataStorage/IReport";

interface IRestReportsServiceCollectionResponse extends IRestServiceCollectionResponse {
    results: Array<IReport>
}

interface IRestReportsService {
    collectionList(options?: IRestServiceOptions): Promise<IRestReportsServiceCollectionResponse>,
    collectionCreate(data: any): Promise<IReport>,
    resourceRetrieve(id: number|string): Promise<IReport>,
    resourceUpdate(id: number|string, data: any): Promise<IReport>,
    resourceDelete(id: number|string): Promise<void>,
}

const ReportsService_COLLECTION = 'reports'

const ReportsService: IRestReportsService = {
    collectionList: function (options?) {
        return RestService.collectionList(ReportsService_COLLECTION, options as IRestServiceOptions) as Promise<IRestReportsServiceCollectionResponse>
    },
    collectionCreate: function ( data) {
        return RestService.collectionCreate(ReportsService_COLLECTION, data) as Promise<IReport>
    },
    resourceRetrieve: function (id) {
        return RestService.resourceRetrieve(ReportsService_COLLECTION, id) as Promise<IReport>
    },
    resourceDelete: function (id) {
        return RestService.resourceDelete(ReportsService_COLLECTION, id)
    },
    resourceUpdate: function (id, data) {
        return RestService.resourceUpdate(ReportsService_COLLECTION, id, data) as Promise<IReport>
    }
}

export default ReportsService