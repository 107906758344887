import React from "react";
import {Form, Input, Switch} from "antd";
import IReportWidgetEmployeeLookup from "../../../../../../model/interface/report/IReportWidgetEmployeeLookup";
import PresenterPicker from "../../../../../shared/pickers/PresenterPicker";
import EmployeesService from "../../../../../../model/service/company/EmployeesService";

interface IProps {
    options: IReportWidgetEmployeeLookup
}

class EmployeeLookupWidgetEditor extends React.Component<IProps> {

    render() {
        return (
            <div>
                <Form.Item name={'label'} label={'Titulek'} rules={[{required: true}]} initialValue={'Najít zaměstnance'}>
                    <Input/>
                </Form.Item>
                <Form.Item name={'presenter'} label={'Vyhledávat podle'} rules={[{required: true}]}>
                    <PresenterPicker serviceClassName={EmployeesService.getInstance().getRecordClassName()}/>
                </Form.Item>
                <Form.Item name={'onlyActive'} label={'Pouze aktivní'} valuePropName={'checked'} initialValue={true}>
                    <Switch/>
                </Form.Item>
            </div>
        )
    }
}

export default EmployeeLookupWidgetEditor