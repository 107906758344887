import RestService from "model/service/dataStorage/RestService";
import IRestServiceOptions from "../../interface/api/IRestServiceOptions";
import IDirectory from "../../interface/file/IDirectory";
import IRestServiceCollectionResponse from "../../interface/api/IRestServiceCollectionResponse";

interface IRestDirectoriesServiceCollectionResponse extends IRestServiceCollectionResponse {
    results: Array<IDirectory>
}

interface IRestDirectoriesServiceCollectionInnerResponse extends IRestServiceCollectionResponse {
    results: Array<any>
}

interface IRestDirectoriesServiceCollectionTreeResponse extends IRestServiceCollectionResponse {
    tree: IDirectory
}

interface IRestDirectoriesService {
    collectionList(options?: IRestServiceOptions): Promise<IRestDirectoriesServiceCollectionResponse>,
    collectionCreate(data: any): Promise<IDirectory>,
    resourceRetrieve(id: number|string): Promise<IDirectory>,
    resourceUpdate(id: number|string, data: any): Promise<IDirectory>,
    resourceDelete(id: number|string): Promise<void>,
    collectionListTree(options?: IRestServiceOptions): Promise<IRestDirectoriesServiceCollectionTreeResponse>
    collectionListInner(id:number|string, parts:any, options?: IRestServiceOptions): Promise<IRestDirectoriesServiceCollectionInnerResponse>,
}

const DirectoriesService_COLLECTION = 'directories'
const DirectoriesService_COLLECTION_TREE = 'directory-tree'

const DirectoriesService: IRestDirectoriesService = {
    collectionList: function (options?) {
        return RestService.collectionList(DirectoriesService_COLLECTION, options as unknown as IRestServiceOptions) as Promise<IRestDirectoriesServiceCollectionResponse>
    },
    collectionCreate: function ( data) {
        return RestService.collectionCreate(DirectoriesService_COLLECTION, data) as Promise<IDirectory>
    },
    resourceRetrieve: function (id) {
        return RestService.resourceRetrieve(DirectoriesService_COLLECTION, id) as Promise<IDirectory>
    },
    resourceDelete: function (id) {
        return RestService.resourceDelete(DirectoriesService_COLLECTION, id)
    },
    resourceUpdate: function (id, data) {
        return RestService.resourceUpdate(DirectoriesService_COLLECTION, id, data) as Promise<IDirectory>
    },
    collectionListTree: function (options) {
        return RestService.collectionList(DirectoriesService_COLLECTION_TREE, options) as Promise<IRestDirectoriesServiceCollectionTreeResponse>
    },
    collectionListInner: function (id, parts, options) {
        return RestService.collectionListInner(DirectoriesService_COLLECTION, id, parts, options) as Promise<IRestDirectoriesServiceCollectionInnerResponse>
    }
}

export default DirectoriesService