import RestService from "model/service/dataStorage/RestService";
import IFreeDay from "../../../interface/company/workload/IFreeDay";
import IRestServiceCollectionResponse from "../../../interface/api/IRestServiceCollectionResponse";
import IRestServiceOptions from "../../../interface/api/IRestServiceOptions";
import {MomentBuilder} from "../../../../utils/MomentBuilder";
import {Moment} from "moment";

interface IRestFreeDaysServiceCollectionResponse extends IRestServiceCollectionResponse {
    results: Array<IFreeDay>
}

interface IRestFreeDaysService {
    collectionList(year?: number, options?: IRestServiceOptions): Promise<IRestFreeDaysServiceCollectionResponse>,

    indexByMonthDay(freeDays: IFreeDay[]): { [month: string]: { [day: number]: IFreeDay } }

    isFreeDay(date: Moment, freeDays: IFreeDay[]): boolean

    getFreeDay(date: Moment, freeDays: IFreeDay[]): IFreeDay | undefined
}

const FreeDaysService_COLLECTION = 'company/workload/free-days/'

const FreeDaysService: IRestFreeDaysService = {
    collectionList: function (year = 0, options?) {
        return RestService.collectionList(FreeDaysService_COLLECTION + year, options as unknown as IRestServiceOptions) as Promise<IRestFreeDaysServiceCollectionResponse>
    },
    indexByMonthDay(freeDays: IFreeDay[]) {
        let indexed: any = {}
        freeDays.forEach(freeDay => {
            const date = MomentBuilder.build(freeDay.date)
            const month = date.month() + 1
            indexed[month] = {...indexed[month]}
            indexed[month][date.date()] = freeDay
        })
        return indexed
    },
    isFreeDay(date, freeDays) {
        return freeDays.some(freeDay => MomentBuilder.build(freeDay.date).isSame(date, 'date'))
    },
    getFreeDay(date, freeDays): IFreeDay | undefined {
        return freeDays.find(freeDay => MomentBuilder.build(freeDay.date).isSame(date, 'date'))
    }
}

export default FreeDaysService