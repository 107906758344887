import React, {CSSProperties, ReactNode} from "react";
import Utils from "../../../utils";
import {Mode} from "../../../redux/reducers/Theme";
import {IAppState} from "../../../redux/store";
import {connect} from "react-redux";
import {isOsDark} from "../../../theme/ThemeProvider";

interface IState {
    visible: boolean
}

interface IProps {
    visibility?: 'visible' | 'auto' | 'hidden'
    design?: 'minimalist' | 'classic'
    style?: CSSProperties
    orientation?: 'vertical' | 'horizontal'
    className?: string
    wrapperClassName?: string,
    wrapperStyle?: CSSProperties
    shadowCoverColor?: string
    themeMode?: Mode
    children: (ReactNode | Element)[] | ReactNode | Element
}

class ScrollContainer extends React.Component<IProps, IState> {

    static defaultProps : Omit<IProps, 'children'> = {
        className: '',
        design: 'minimalist',
        orientation: 'vertical',
        visibility: "auto"
    }

    constructor(props: IProps) {
        super(props);
        this.state = {
            visible: props.visibility === 'visible'
        }
    }

    setVisible = (visible: boolean = true) => {
        const {visibility} = this.props
        visibility === 'auto' && this.setState({visible})
    }

    render() {
        const {
            children,
            visibility,
            design,
            style,
            orientation,
            className,
            wrapperClassName,
            wrapperStyle,
            themeMode,
            shadowCoverColor
        } = this.props

        let finalTheme = themeMode === 'auto' && isOsDark() ? 'dark' : themeMode
        const shadowColor = shadowCoverColor ? Utils.rgbaToHexNoA(Utils.hexaToRgba(shadowCoverColor!)) :
            Utils.rgbaToHexNoA(Utils.hexaToRgba(finalTheme === 'dark' ? '#283142ff' : '#ffffffff'))

        return (
            <div className={"scroll-container-wrapper " + wrapperClassName} style={wrapperStyle}
                 id='scroll-container-wrapper-id'>
                <div className={'scroll-container ' + (visibility) + ' ' + (design) + ' ' + (orientation) + ' ' + className}
                     id='scroll-container-id'
                     onMouseEnter={() => this.setVisible()}
                     onMouseLeave={() => this.setVisible(false)}
                     style={{
                         ...style,
                         background: orientation === 'vertical' ? `linear-gradient(${shadowColor} 99%, transparent),radial-gradient(farthest-side at top, rgba(0, 0, 0, .2), transparent),linear-gradient(transparent, ${shadowColor} 1%) bottom,radial-gradient(farthest-side at bottom, rgba(0, 0, 0, .2), transparent) bottom` :
                             `linear-gradient(${shadowColor} 99%, transparent) left,radial-gradient(farthest-side at left, rgba(0, 0, 0, .2), transparent) left,linear-gradient(transparent, ${shadowColor} 1%) right,radial-gradient(farthest-side at right, rgba(0, 0, 0, .2), transparent) right`
                     }}>
                    {children}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: IAppState) => {
    const {mode} = state.theme;
    return {themeMode: mode}
};


export default connect(mapStateToProps)(ScrollContainer)