import React, {Component} from 'react';
import {IconPickerList} from "utils/IconBuilder";
import {Divider, Select} from "antd";

interface IProps {
    value?: string
    onChange?: (key: string) => void
    className?: string
    library?: 'ant' | 'ion'
    disabled?: boolean
}

interface IState {
    value?: string
}

class IconPicker extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            value: props.value
        }
    }

    onPickerChoose = (icon: string) => {
        this.setState({
            value: icon
        })
        if (this.props.onChange) {
            this.props.onChange(icon)
        }
    }

    render() {
        const {value} = this.state
        const {className, library, disabled} = this.props

        return (
            <div className="icon-picker">
                <Select
                    allowClear
                    className={className}
                    showSearch
                    disabled={disabled}
                    value={value}
                    placeholder="Vyberte iconu"
                    optionFilterProp="children"
                    onChange={this.onPickerChoose}
                    filterOption={(input, option) =>
                        option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {IconPickerList(library).map(icon => {
                            return (
                                <Select.Option key={icon.value} value={icon.value}>
                                    {icon.icon} <Divider type={"vertical"}/> {icon.label}
                                </Select.Option>
                            )
                        }
                    )}
                </Select>
            </div>
        )
    }
}

export default IconPicker;
