// export * from './Common';
// export * from './Auth';
// import * from './Theme';
// export * from './Setup';
// export * from './Notifications';

import Theme from './Theme';
import Auth from './Auth';
import Setup from './Setup';
import Notifications from './Notifications';

export default {
    theme: Theme,
    auth: Auth,
    setup: Setup,
    notifications: Notifications
}