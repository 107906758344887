import React, {RefObject} from "react";
import {Form, FormInstance, InputNumber, Switch} from "antd";
import TypesService from "../../../../../../model/service/company/workload/TypesService";
import IReportWidgetVacation from "../../../../../../model/interface/report/IReportWidgetVacation";
import {API_FILTER_TYPE} from "../../../../../../model/constants/ApiConstant";
import ResourcePicker from "../../../../../shared/pickers/ResourcePicker";
import {COMPANY_WORKLOAD_TYPE_MODE_WORKING} from "../../../../../../model/interface/company/workload/IType";

interface IProps {
    options: IReportWidgetVacation
    formRef?: RefObject<FormInstance>
}

interface IState {

}

class VacationWidgetEditor extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props)
        this.state = {}
    }

    render() {
        return (
            <div>
                <Form.Item name={'vacationTypes'} label={'Type nepřítomnosti'}
                           rules={[{required: true, message: 'Povinné pole'}]}>
                    <ResourcePicker multiple={true} fetchChoices={() => TypesService.choiceList('default', {
                        limit: 0,
                        filters: {
                            unlimitedFund: {
                                field: 'unlimitedFund',
                                type: API_FILTER_TYPE.NOT_EQUAL,
                                value: true
                            },
                            2: {
                                type: API_FILTER_TYPE.NOT_EQUAL,
                                field: 'mode',
                                value: COMPANY_WORKLOAD_TYPE_MODE_WORKING
                            }
                        }
                    })}/>
                </Form.Item>
                <Form.Item name={'vacationUpcomingInterval'}
                           label={'Zadejte, kolik dní před dovolenou se začnou zobrazovat informace o nadcházející dovolené'}
                           rules={[{required: true}]}
                           initialValue={7}>
                    <InputNumber min={1} max={365}/>
                </Form.Item>
                <Form.Item name={'vacationExpireInterval'}
                           label={'Zadejte, kolik dní před vypršením nároku na dovolenou se zobrazí upozornění'}
                           rules={[{required: true}]}
                           initialValue={14}>
                    <InputNumber min={1} max={365}/>
                </Form.Item>
                <Form.Item name={'bordered'} label={'Ohraničení'} valuePropName={'checked'}>
                    <Switch/>
                </Form.Item>
            </div>
        )
    }
}

export default VacationWidgetEditor