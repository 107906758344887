import React from "react"
import {connect} from "react-redux";
import selectors from "redux/selectors";
import {IAppState} from "../../../../redux/store";

interface IState {

}

interface IProps {
    getText: (code: string, fallBack?: string) => string
    code: string
    fallback?: string

}

class LocaleText extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {

        }
    }

    render() {
        const {getText, code, fallback} = this.props

        return <span>{getText(code, fallback) || code}</span>
    }
}


const mapStateToProps = (state: IAppState) => {

    return {
        getText: (code: string, fallback?: string) => selectors.dictionary.getMessage(state, code, fallback)
    }
}

export default connect(mapStateToProps)(LocaleText)
