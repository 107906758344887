import {FilterValue, SorterResult, TablePaginationConfig} from "antd/lib/table/interface";
import IRestServiceOptions from "../../interface/api/IRestServiceOptions";
import IRestServiceOrders from "../../interface/api/IRestServiceOrders";
import IRestServiceFilters from "../../interface/api/IRestServiceFilters";
import {API_FILTER_TYPE} from "../../constants/ApiConstant";
import moment from "moment";
import {DATE_FORMAT_YYYY_MM_DD_HH_mm_ss} from "../../constants/DateConstant";
import {Key} from "react";


const AntdTableOptionsToRestOptionsService = {
    parse: (pagination: TablePaginationConfig,
            filters: Record<string, FilterValue | Key | boolean | null> | null,
            sorter?: SorterResult<any> | SorterResult<any>[] | null,
            filterTypes?: { [field: string]: string },
            customFieldNames?: { [field: string]: string }): IRestServiceOptions => {

        return {
            limit: pagination.pageSize,
            filters: AntdTableOptionsToRestOptionsService.parseFilters(filters, filterTypes, customFieldNames),
            page: pagination.current,
            order: AntdTableOptionsToRestOptionsService.parseOrder(sorter, customFieldNames)
        }
    },
    parseFilters: function (filters: Record<string, FilterValue | Key | boolean | null> | null,
                            filterTypes?: { [field: string]: string },
                            customFieldNames?: { [field: string]: string }) {
        let apiFilters: IRestServiceFilters = {}
        filters && Object.entries(filters).forEach(([field, value], index) => {
            if (value !== undefined && value !== null) {
                if (Array.isArray(value) && value.length === 2 && moment.isMoment(value[0]) && moment.isMoment(value[1])){
                    apiFilters[field + 'from' + index] = {
                        field: customFieldNames?.[field] || field,
                        value: value[0].format(DATE_FORMAT_YYYY_MM_DD_HH_mm_ss),
                        type: API_FILTER_TYPE.GREATER_OR_EQUAL
                    }
                    apiFilters[field + 'to' + index] = {
                        field: customFieldNames?.[field] || field,
                        value: value[1].format(DATE_FORMAT_YYYY_MM_DD_HH_mm_ss),
                        type: API_FILTER_TYPE.LESSER_OR_EQUAL
                    }
                    return
                }

                if (Array.isArray(value) && value.length === 1 && typeof value[0] === "boolean"){
                    apiFilters[field + index] = {
                        field: customFieldNames?.[field] || field,
                        value: value[0],
                        type: API_FILTER_TYPE.EQUAL
                    }
                    return
                }

                if (typeof value === "boolean"){
                    apiFilters[field + index] = {
                        field: customFieldNames?.[field] || field,
                        value: value,
                        type: API_FILTER_TYPE.EQUAL
                    }
                    return
                }
                apiFilters[field + index] = {
                    field: customFieldNames?.[field] || field,
                    value: value,
                    type: filterTypes?.[field] || (Array.isArray(value) ? API_FILTER_TYPE.IN : API_FILTER_TYPE.EQUAL)
                }
            }
        })

        return apiFilters;
    },
    parseOrder: function (sorter?: SorterResult<any> | SorterResult<any>[] | null,
                          customFieldNames?: { [field: string]: string }) {
        let order: IRestServiceOrders = {}
        sorter && (Array.isArray(sorter) ? sorter : [sorter]).forEach((sort, index) => {
            const field = sort.field as string
            if (sort.order) {
                order[field + index] = {
                    field: customFieldNames?.[field] || field,
                    direction: {'ascend': 'ASC', 'descend': 'DESC'}[sort.order] as 'ASC' | 'DESC'
                }
            }
        })

        return order
    },
    getDefaultOrder: function (key: string, sorter?: SorterResult<any> | SorterResult<any>[] | null) {
        return Array.isArray(sorter) ? sorter.find(s => s.columnKey === key)?.order :
            (sorter?.columnKey === key ? sorter.order : undefined)
    }
}

export default AntdTableOptionsToRestOptionsService