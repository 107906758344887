import ILabelValue from "../../../interface/util/ILabelValue";
import {IViewGraphSettingsInterval} from "../../../interface/dataStorage/view/graph/IViewGraphSettings";
import {COLORS} from "../../../constants/ChartConstant";

const ViewGraphService = {
    TYPE_BAR: 'bar',
    TYPE_LINE: 'line',
    TYPE_PIE: 'pie',
    TYPE_DONUT: 'donut',
    COLOR_SCHEME_RED_GREEN: 'redGreen',
    COLOR_SCHEME_SPECTRUM: 'spectrum',
    INTERVAL_DAY: 'day' as IViewGraphSettingsInterval,
    INTERVAL_WEEK: 'week' as IViewGraphSettingsInterval,
    INTERVAL_MONTH: 'month' as IViewGraphSettingsInterval,
    INTERVAL_QUARTER: 'quarter' as IViewGraphSettingsInterval,
    INTERVAL_YEAR: 'year' as IViewGraphSettingsInterval,
    X_AXIS_INTERVAL: 'interval',
    X_AXIS_FIELD: 'field',
    buildColors: (colorScheme: string) => {
        switch (colorScheme) {
            case(ViewGraphService.COLOR_SCHEME_RED_GREEN):
                return [
                    '#2d962d',
                    '#d23737',
                ]
            default:
                return COLORS
        }
    },
    buildIntervals: (): ILabelValue<IViewGraphSettingsInterval>[] => {
        return [
            {
                value: ViewGraphService.INTERVAL_DAY,
                label: 'Den'
            },
            {
                value: ViewGraphService.INTERVAL_WEEK,
                label: 'Týden'
            },
            {
                value: ViewGraphService.INTERVAL_MONTH,
                label: 'Měsíc'
            },
            {
                value: ViewGraphService.INTERVAL_QUARTER,
                label: 'Kvartál'
            },
            {
                value: ViewGraphService.INTERVAL_YEAR,
                label: 'Rok'
            },
        ]
    },
    intervalFormats: () => {
        return {
            [ViewGraphService.INTERVAL_DAY]: {delta: ViewGraphService.INTERVAL_DAY, format: 'LL'},
            [ViewGraphService.INTERVAL_WEEK]: {
                delta: ViewGraphService.INTERVAL_WEEK,
                format: 'W. YYYY'
            },
            [ViewGraphService.INTERVAL_MONTH]: {
                delta: ViewGraphService.INTERVAL_MONTH,
                format: 'MMMM YYYY'
            },
            [ViewGraphService.INTERVAL_QUARTER]: {
                delta: ViewGraphService.INTERVAL_QUARTER,
                format: 'YYYY/Q'
            },
            [ViewGraphService.INTERVAL_YEAR]: {delta: ViewGraphService.INTERVAL_YEAR, format: 'YYYY'}
        } as { [interval: string]: { delta: IViewGraphSettingsInterval, format: string } }
    }
}

export default ViewGraphService