import RestService from "model/service/dataStorage/RestService";
import IRestServiceOptions from "../../interface/api/IRestServiceOptions";
import IWorkflow from "../../interface/dataStorage/workflow/IWorkflow";
import IRestServiceCollectionResponse from "../../interface/api/IRestServiceCollectionResponse";
import IVersion from "../../interface/dataStorage/workflow/IVersion";
import IRestResource from "../../interface/api/IRestResource";
import IContentType from "../../interface/dataStorage/IContentType";
import _ from "underscore";
import ITransition from "../../interface/dataStorage/workflow/ITransition";
import IState from "../../interface/dataStorage/workflow/IState";

interface IRestWorkflowsServiceCollectionResponse extends IRestServiceCollectionResponse {
    results: Array<IWorkflow>
}

interface IRestWorkflowsService {
    collectionList(options?: IRestServiceOptions): Promise<IRestWorkflowsServiceCollectionResponse>,

    collectionCreate(data: any): Promise<IWorkflow>,

    resourceRetrieve(id: number | string): Promise<IWorkflow>,

    resourceUpdate(id: number | string, data: any): Promise<IWorkflow>,

    resourceDelete(id: number | string): Promise<void>,

    getOutgoingTransitions: (version: IVersion, resource: IRestResource, contentType: IContentType) => (ITransition & { state: IState })[]
}

const WorkflowsService_COLLECTION = 'workflows'

const WorkflowsService: IRestWorkflowsService = {
    collectionList: function (options?) {
        return RestService.collectionList(WorkflowsService_COLLECTION, options as unknown as IRestServiceOptions) as Promise<IRestWorkflowsServiceCollectionResponse>
    },
    collectionCreate: function (data) {
        return RestService.collectionCreate(WorkflowsService_COLLECTION, data) as Promise<IWorkflow>
    },
    resourceRetrieve: function (id) {
        return RestService.resourceRetrieve(WorkflowsService_COLLECTION, id) as Promise<IWorkflow>
    },
    resourceDelete: function (id) {
        return RestService.resourceDelete(WorkflowsService_COLLECTION, id)
    },
    resourceUpdate: function (id, data) {
        return RestService.resourceUpdate(WorkflowsService_COLLECTION, id, data) as Promise<IWorkflow>
    },
    getOutgoingTransitions: (version: IVersion, resource: IRestResource, contentType: IContentType) => {
        const currentState = _.findWhere(version!.states, {id: resource.state.id})
        return version.transitions
            .filter(
                transition => {
                    const action = _.findWhere(contentType.actions, {uuid: transition.action})
                    return transition.startState === currentState?.uuid
                        && ((action && resource._permissions[action.name]) || !transition.action)
                }
            ).map(transition => {
                const uuid = typeof transition.endState === 'object' ? transition.endState!.uuid : transition.endState as string
                const state = _.findWhere(version.states, {uuid})
                if (!state) {
                    throw new Error('State not found!')
                }

                return {...transition, state}
            })
    }

}

export default WorkflowsService