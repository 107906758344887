import React from "react";
import {Button, Input} from "antd";
import {FolderOpenOutlined} from "@ant-design/icons";
import IFile from "../../../../../model/interface/file/IFile";
import FileUpload from "../../../../app/file-manager/FileUpload";
import {IPropsFilePickerWidget} from "../FilePicker";
import PickerModal from "./PickerModal";
import FileList from "./FileList";

interface IState {
    search?: string,
    directory?: number[],
    loading: boolean,
    saving: boolean,
    visible: boolean
}

interface IProps extends IPropsFilePickerWidget {
    noModal?: boolean
}

class Simple extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            loading: false,
            saving: false,
            visible: false
        } as IState
    }

    removeFile = (file: IFile) => {
        this.props.onRemove(file)
    }

    onUpload = (files: IFile[]) => {
        this.props.onChange(this.props.multiple ? [...this.props.fileList, ...files] : files)
        this.setState({visible: false})
    }

    onChoose = (files: IFile[]) => {
        this.props.onChange(files)
        this.setState({visible: false})
    }

    showModal = () => {
        this.setState({visible: true})
    }

    render() {
        const {loading, visible} = this.state
        const {
            canChoose,
            canUpload,
            multiple,
            fileList,
            directory,
            canRemove,
            canDownload,
            extensions,
            placeholder,
            noModal
        } = this.props

        return (
            <div>
                {visible && <PickerModal extensions={extensions} multiple={multiple} forceDirectory={directory}
                                         onFinish={this.onChoose}
                                         fileList={fileList}/>}
                <Input.Group compact={true} className={'w-100'}>
                    {canUpload &&
                        <FileUpload mode={noModal ? 'direct' : 'button'} placeholder={placeholder}
                                    accept={extensions?.join(',')} multiple={multiple}
                                    onUpload={this.onUpload} directory={directory}/>}
                    {canChoose && (
                        <Button icon={<FolderOpenOutlined/>} onClick={this.showModal}>
                            Vybrat soubor
                        </Button>
                    )}
                </Input.Group>
                <FileList onRemove={canRemove ? this.removeFile : undefined} loading={loading}
                          canDownload={canDownload} fileList={fileList}/>
            </div>
        )
    }
}

export default Simple