import RestService from "model/service/dataStorage/RestService";
import IRestServiceOptions from "../../interface/api/IRestServiceOptions";
import IRestServiceCollectionResponse from "../../interface/api/IRestServiceCollectionResponse";
import IForm from "../../interface/form/IForm";
import IRestResource from "../../interface/api/IRestResource";
import IContentType from "../../interface/dataStorage/IContentType";
import FilterEvaluationService from "../api/FilterEvaluationService";
import Utils from "../../../utils";
import IWidget from "../../interface/form/IWidget";
import FieldEditor from "../../../components/app/configuration/form/FormElement/optionEditor/FieldEditor";

interface IRestFormsServiceCollectionResponse extends IRestServiceCollectionResponse {
    results: Array<IForm>
}

interface IRestFormsService {
    collectionList(options?: IRestServiceOptions): Promise<IRestFormsServiceCollectionResponse>,

    collectionCreate(data: any): Promise<IForm>,

    resourceRetrieve(id: number | string): Promise<IForm>,

    resourceUpdate(id: number | string, data: any): Promise<IForm>,

    resourceDelete(id: number | string): Promise<void>,

    findSuitable(forms: IForm[], resource: IRestResource, findContentType: (uuid: string) => IContentType): IForm | null,

    clone(form: IForm, priority?: number): IForm

    buildBasicForm(contentType: IContentType): IForm
}

const FormsService_COLLECTION = 'forms'

const FormsService: IRestFormsService = {
    collectionList: function (options?) {
        return RestService.collectionList(FormsService_COLLECTION, options as unknown as IRestServiceOptions) as Promise<IRestFormsServiceCollectionResponse>
    },
    collectionCreate: function (data) {
        return RestService.collectionCreate(FormsService_COLLECTION, data) as Promise<IForm>
    },
    resourceRetrieve: function (id) {
        return RestService.resourceRetrieve(FormsService_COLLECTION, id) as Promise<IForm>
    },
    resourceDelete: function (id) {
        return RestService.resourceDelete(FormsService_COLLECTION, id)
    },
    resourceUpdate: function (id, data) {
        return RestService.resourceUpdate(FormsService_COLLECTION, id, data) as Promise<IForm>
    },
    findSuitable(forms, resource, findContentType): IForm | null {
        let form: IForm | null = null
        const found = forms.sort((a, b) => (a.priority || 0) - (b.priority || 0))
            .some(function (f) {
                form = f
                return !f.conditions || Object.keys(f.conditions).length === 0 || FilterEvaluationService.evaluate(f.conditions, resource, findContentType(f.contentType))
            })
        return found ? form : null
    },
    clone(form: IForm, priority): IForm {
        const uuid = Utils.uuid()
        const cloneWidgets = (form: IForm): IWidget[] => {
            let uuidMap: { old: string, new: string }[] = []
            uuidMap = form.widgets.map(w => ({old: w.uuid, new: Utils.uuid()}))
            return form.widgets.map(w => ({
                ...w,
                id: null,
                uuid: uuidMap.find(u => u.old === w.uuid)?.new || '',
                parent: uuidMap.find(u => u.old === w.parent)?.new || null
            }))
        }

        return {
            ...form,
            uuid: uuid,
            actions: [],
            name: form.name + "_copy",
            label: form.label + " (copy)",
            widgets: cloneWidgets(form),
            id: undefined,
            priority
        }
    },
    buildBasicForm(contentType: IContentType): IForm {
        const uuids = [Utils.uuid(), Utils.uuid(), Utils.uuid(), Utils.uuid(), Utils.uuid(), Utils.uuid()]
        const form: IForm = {
            name: contentType.name + '_form',
            label: contentType.label || contentType.name,
            contentType: contentType.uuid,
            widgets: [
                {
                    uuid: uuids[0],
                    id: null,
                    label: 'Kontejner',
                    type: 'container',
                    options: {},
                    parent: null,
                    weight: 1,
                    children: [uuids[1]]
                },
                {
                    uuid: uuids[1],
                    id: null,
                    label: 'Sloupce',
                    type: 'columns',
                    options: {},
                    parent: uuids[0],
                    weight: 1,
                    children: [uuids[2], uuids[3], uuids[4]]
                },
                {
                    uuid: uuids[2],
                    id: null,
                    label: 'Sloupec',
                    type: 'column',
                    options: {width: 24},
                    parent: uuids[1],
                    weight: 1,
                    children: [uuids[5]]
                },
                {
                    uuid: uuids[3],
                    id: null,
                    label: 'Sloupec',
                    type: 'column',
                    options: {width: 24},
                    parent: uuids[1],
                    weight: 2,
                    children: []
                },
                {
                    uuid: uuids[4],
                    id: null,
                    label: 'Sloupec',
                    type: 'column',
                    options: {width: 24},
                    parent: uuids[1],
                    weight: 3,
                    children: []
                },
                {
                    uuid: uuids[5],
                    id: null,
                    label: 'Text',
                    type: 'text',
                    options: {
                        wysiwyg: true,
                        text: '<h1>' + contentType.label + '</h1>'
                    },
                    parent: uuids[2],
                    weight: 1,
                    children: []
                },
                {
                    uuid: Utils.uuid(),
                    id: null,
                    label: "Tlačítko",
                    type: "submit",
                    weight: 1,
                    children: [],
                    options: {text: "Uložit"},
                    parent: uuids[4]
                }
            ]
        }
        let i = 1
        contentType.fields.forEach(field => {
            form.widgets.push({
                uuid: Utils.uuid(),
                id: null,
                label: field.label || field.name,
                type: "field",
                field: field.uuid,
                weight: i,
                children: [],
                parent: uuids[3],
                options: {
                    "sliderMin": 1,
                    "sliderMax": 100,
                    "datePicker": "date",
                    "requiredText": "Tato položka je povinná",
                    "autocompleteCollection": "",
                    "autocompleteField": "",
                    "fileWidget": "simple",
                    "autocompleteMode": "like",
                    "autocompleteMin": 2,
                    "wysiwygPackage": "basic",
                    "initialValue": "",
                    "showClear": false,
                    "required": false,
                    "sliderTooltipVisible": false,
                    "label": field.label || field.name,
                    "type": FieldEditor.detectType(field),
                    "fileCanChoose": false,
                    "fileCanDownload": false,
                    "fileCanUpload": true,
                    "fileCanRemove": false,
                    "fileMultiple": false,
                    "fileDirectory": "",
                    "placeholder": ""
                }
            })
            i++
        })
        return form
    }
}

export default FormsService