import React from "react";
import {Input, InputProps} from "antd";
import IFieldOptions from "../../../../../../model/interface/form/elementOptions/IFieldOptions";

class FormFieldText {
    public static render = (options: IFieldOptions & InputProps) => {
        const props = {
            style: options.style,
            placeholder: options.placeholder,
            allowClear: options.showClear,
            disabled: options.disabled,
            addonBefore: options.addonBefore,
            addonAfter: options.addonAfter,
            prefix: options.prefix,
            suffix: options.suffix,
            value: options.value
        }

        return (
            <Input {...props}/>
        )
    }
}

export default FormFieldText