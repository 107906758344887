import React, {RefObject} from 'react'
import {FormInstance} from 'antd';
import {IPropsReportStep} from "./ReportConfiguration";
import IReportWidget from "../../../../model/interface/report/IReportWidget";
import ReportWidgetType from "./widget/ReportWidgetType";
import ReportWidget from "./widget/ReportWidget";
import ReportWidgetList from "./widget/ReportWidgetList";
import IWidgetOptions from "../../../../model/interface/widget/IWidgetOptions";
import Widget from "../widget/Widget";
import IReportWidgetView from "../../../../model/interface/report/IReportWidgetView";
import ViewWidgetEditor from "./widget/optionEditor/ViewWidgetEditor";
import ReportWidgetGallery from "./widget/ReportWidgetGallery";
import Utils from "../../../../utils";
import EmployeeLookupWidgetEditor from "./widget/optionEditor/EmployeeLookupWidgetEditor";
import IReportWidgetEmployeeLookup from "../../../../model/interface/report/IReportWidgetEmployeeLookup";
import NotificationsWidgetEditor from "./widget/optionEditor/NotificationsWidgetEditor";
import ResourceFinderByCodeWidgetEditor from "./widget/optionEditor/ResourceFinderByCodeWidgetEditor";
import Editor, {IEditorState} from "../editor/Editor";
import ICardWidget from "../../../../model/interface/dataStorage/card/ICardWidget";
import IWidgetPropsFunctions from "../../../../model/interface/widget/IWidgetPropsFunctions";
import VacationWidgetEditor from "./widget/optionEditor/VacationWidgetEditor";
import MenuWidgetEditor from "./widget/optionEditor/MenuWidgetEditor";
import IReportWidgetVacation from "../../../../model/interface/report/IReportWidgetVacation";
import WorkloadReportWidgetEditor from "./widget/optionEditor/WorkloadReportWidgetEditor";
import WorkloadAttendanceWidgetEditor from "./widget/optionEditor/WorkloadAttendanceWidgetEditor";

interface IState extends IEditorState<IReportWidget> {

}

interface IProps extends IPropsReportStep {
    save: () => void
}

class ReportEditor extends Editor<IProps, IState, ICardWidget> {

    widgetList = ReportWidgetList

    constructor(props: IProps) {
        super(props);
        const containerUuid = Utils.uuid();
        this.state = {
            ...this.state,
            current: props.resource.widgets.find(w => !w.parent)?.uuid || containerUuid,
            structure: props.resource.widgets.length > 0 ? ReportEditor.widgetsToStructure(this.props.resource.widgets
                    .filter(widget => this.props.resource.thread || widget.type !== ReportWidgetType.COMMENT))
                : {
                    [containerUuid]: {
                        ...this.widgetList.getByType(ReportWidgetType.CONTAINER),
                        id: containerUuid,
                        label: 'Container'
                    }
                }
        }
    }

    static widgetsToStructure(widgets: IReportWidget[]) {
        let structure = {} as { [id: string]: IReportWidget }
        widgets.forEach(widget => {
            structure[widget.uuid] = {
                ...widget,
                id: widget.uuid,
                children: widgets.filter(value => value.parent === widget.uuid).map(wd => wd.uuid),
                parent: widget.parent
            }
        })
        return structure;
    }

    getOptionEditor(node: IReportWidget, formRef: RefObject<FormInstance>) {
        switch (node.type) {
            case ReportWidgetType.VIEW:
                return <ViewWidgetEditor options={node.options as IReportWidgetView}/>
            case ReportWidgetType.EMPLOYEE_LOOKUP:
                return <EmployeeLookupWidgetEditor options={node.options as IReportWidgetEmployeeLookup}/>
            case ReportWidgetType.NOTIFICATIONS:
                return <NotificationsWidgetEditor options={node.options}/>
            case ReportWidgetType.VACATION:
                return <VacationWidgetEditor options={node.options as IReportWidgetVacation}/>
            case ReportWidgetType.RESOURCE_FINDER_BY_CODE:
                return <ResourceFinderByCodeWidgetEditor options={node.options}/>
            case ReportWidgetType.MENU:
                return <MenuWidgetEditor options={node.options}/>
            case ReportWidgetType.WORKLOAD_REPORT:
                return <WorkloadReportWidgetEditor options={node.options}/>
            case ReportWidgetType.WORKLOAD_ATTENDANCE:
                return <WorkloadAttendanceWidgetEditor options={node.options}/>
            default:
                return Widget.getOptionEditor(node.type, node.options, formRef, this.onOptionsChange)
        }
    }

    getGallery = (group?: string) => {
        const {current} = this.state
        return <ReportWidgetGallery report={this.props.resource} exists={this.typeExists}
                                    node={this.getCurrentNode()} list={ReportWidgetList} customGroup={group}
                                    onSelect={(type) => this.appendNodeExecute(current, type)}/>
    }

    typeExists = (type: string) => {
        return !!Object.entries(this.state.structure).find(value => value[1].type === type)
    }

    appendNodeExecute(id: string, type: string) {
        this.validate().then(() => {
            const widget = {...ReportWidgetList.getByType(type), parent: id, id: Utils.uuid(), type}
            this.saveNode(widget).then()
        })
    }

    saveNode(node: IReportWidget, values?: IWidgetOptions) {
        return super.saveNode(node, values).then(this.confirm)
    }

    confirm = () => {
        const {onChange, setValid} = this.props
        this.formRefOptions.current?.validateFields()
            .then(() => setValid(true)).catch(() => setValid(false))
        let resource = {...this.props.resource}
        resource.widgets = this.mapWidgets()
        onChange(resource)
    }

    mapWidgets() {
        let widgets: IReportWidget[] = []
        Object.entries(this.state.structure).forEach(([, widget]) => {
            widgets.push({
                ...widget,
                children: undefined as any, // can`t send empty array, will delete all attached widgets
                id: null as any,
                uuid: widget.id
            })
        })
        return widgets;
    }

    renderPreview = () => {
        const {structure, current} = this.state
        return <div>
            {
                Object.entries(structure).map(([, widget]) => {
                        if (widget && !widget.parent) {
                            let functions: IWidgetPropsFunctions = {
                                getNode: (id: string) => structure[id],
                                setCurrent: (id: string) => this.setCurrent(id),
                                getSortedChildren: (id: string) => Editor.getSortedChildren(id, structure),
                            }
                            return (
                                <ReportWidget
                                    {...widget}
                                    current={current}
                                    key={widget.uuid}
                                    editor={true}
                                    functions={functions}
                                    match={this.props.match}
                                    history={this.props.history}
                                />
                            )
                        }
                        return null
                    }
                )
            }
        </div>
    }
}

export default ReportEditor