import {useZxing} from "react-zxing";
import React, {useEffect, useState} from "react";
import {Alert, Button, Col, message, Result, Row, Select, Tooltip} from "antd";
import {Result as ReaderResult} from "@zxing/library";
import {CopyOutlined, ReloadOutlined} from "@ant-design/icons";

interface IProps {
    onResult?: (result: ReaderResult) => void,
    timeBetweenDecodingAttempts?: number
}

const ZXING_CAMERA_DEVICE_ID = 'zxing-camera-device-id'

export const ZxingReader = ({onResult}: IProps) => {
    const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
    const [permission, setPermission] = useState<PermissionState>();
    const [device, setDevice] = useState<MediaDeviceInfo | undefined>();
    const [result, setResult] = useState<string | undefined>("");
    const {ref} = useZxing({
        paused: !device,
        deviceId: device?.deviceId,
        timeBetweenDecodingAttempts: 300,
        onResult(readerResult) {
            if (!result) {
                setResult(readerResult.getText())
                onResult?.(readerResult as any)
            }
        },
    })

    const setPermissionState = () => {
        navigator.permissions.query({name: 'camera'}).then(r => setPermission(r.state))
            .catch(r => console.log(r))
    }

    useEffect(() => {
        setPermissionState();
        if (permission === 'prompt') {
            navigator.mediaDevices.getUserMedia({video: true}).catch()
                .finally(() => setPermissionState())
        }

        if (device && device.deviceId !== localStorage.getItem(ZXING_CAMERA_DEVICE_ID)){
            localStorage.setItem(ZXING_CAMERA_DEVICE_ID, device.deviceId)
        }

        navigator.mediaDevices.enumerateDevices().then(devices => {
            const videoDevices = devices.filter(d => d.kind === 'videoinput' && d.label)
            setDevices(videoDevices)
            if (!device && videoDevices && videoDevices.length > 0) {
                const previous = videoDevices.find(d => d.deviceId === localStorage.getItem(ZXING_CAMERA_DEVICE_ID));
                setDevice(previous || videoDevices?.[0])
            }
        })
    }, [permission, device])

    const copyCode = (text: string) => {
        navigator.clipboard.writeText(text).then(() => {
            message.success('Zkopírováno').then()
        })
    }


    return <Row className={'zxing-reader w-100 h-100 position-relative'} align={"middle"} justify={'center'}>
        {permission && {
            prompt: <Result status={"info"} subTitle={'Povolte prosím přístup aplikace ke kameře'}/>,
            denied: <Result status={"warning"}
                            subTitle={'Dříve jste odepřeli přístup ke kameře. \n Resetujte prosím oprávnění ke kameře ve vašem prohlížeči'}/>,
            granted: <div className={'overlay w-100 h-100' + (result ? ' success' : '')}>
                <video className={'d-block w-100 h-100'} ref={ref}></video>
                <div className={'focus-border'}/>
                <div className={'focus top left'}/>
                <div className={'focus top right'}/>
                <div className={'focus bottom left'}/>
                <div className={'focus bottom right'}/>
                {result && <Alert className={'result-alert'} closable={true} onClose={() => setResult(undefined)}
                    message={result} type="success" showIcon description={<Row gutter={[8, 8]}>
                        <Col>
                            <Tooltip title={'Zkopírovat'}>
                                <Button size={"small"} className={'ml-1'} type={"dashed"}
                                        onClick={() => copyCode(result)} icon={<CopyOutlined/>}/>
                            </Tooltip>
                        </Col>
                        <Col>
                            <Button size={"small"} className={'ml-1'}
                                    onClick={() => setResult(undefined)} icon={<ReloadOutlined/>}>
                                Naskenovat znovu
                            </Button>
                        </Col>
                    </Row>}
                />}
            </div>
        }[permission]}
        {devices && devices.length > 1 &&
            <Select className={'mt-2 w-100'} placeholder={'Zvolte si kameru'} value={device?.deviceId}
                    onChange={id => setDevice(devices?.find(d => d.deviceId === id))}>
                {devices.map(device =>
                    <Select.Option value={device.deviceId} key={device.deviceId}>
                        {device.label}
                    </Select.Option>)}
            </Select>}
    </Row>
};