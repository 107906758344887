import {Form, Slider} from 'antd';
import React from "react";
import {IFieldOptionsEditorProps} from "../FieldOptionsEditor";
import IconPicker from "../../../../../shared/IconPicker";

export interface IFieldRatingOptions {
    rateIcon?: string
    rateLength?: number
}

interface IProps extends IFieldOptionsEditorProps {

}

class FieldRateOptionsEditor extends React.Component<IProps> {

    render() {
        const {buildFieldName} = this.props

        return (
            <div>
                <Form.Item name={buildFieldName('rateLength')} label={'Zvolte počet bodů'} rules={[{required: true}]} initialValue={5}>
                    <Slider min={1} max={10}/>
                </Form.Item>
                <Form.Item name={buildFieldName('rateIcon')} label={'Zvolte iconu'}>
                    <IconPicker/>
                </Form.Item>
            </div>
        )
    }
}


export default FieldRateOptionsEditor