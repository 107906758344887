import React from "react"
import {Form, Switch} from "antd";
import {IViewSettingsProps} from "../ViewSettings";
import {connect, RootStateOrAny} from "react-redux";
import selectors from "../../../../redux/selectors";
import IContentType from "../../../../model/interface/dataStorage/IContentType";
import GroupItemsSettings from "./GroupItemsSettings";
import AggregationItemsSettings from "./AggregationItemsSettings";
import IViewPivotTableGroupItemSettings
    from "../../../../model/interface/dataStorage/view/pivotTable/IViewPivotTableGroupItemSettings";
import IViewPivotTableAggregationItemSettings
    from "../../../../model/interface/dataStorage/view/pivotTable/IViewPivotTableAggregationItemSettings";

interface IProps extends IViewSettingsProps {
    findContentTypeByUuid: (uuid: string) => IContentType
}

class ViewPivotTableSettings extends React.Component<IProps> {
    constructor(props: IProps) {
        super(props);

        this.state = {}
    }

    onGroupChange = (items: IViewPivotTableGroupItemSettings[]) => {
        this.props.onChange({pivotTableGroupItems: items}).then()
    }

    onAggregationChange = (items: IViewPivotTableAggregationItemSettings[]) => {
        this.props.onChange({pivotTableAggregationItems: items}).then()
    }

    onChangeForm = (values: any): void => {
        this.setState(state => ({...state, ...values}), () => this.props.onChange({...values}))
    }

    getField = (value: string, property: 'uuid' | 'name' = 'uuid') => {
        const contentType = this.getContentType();
        const field = contentType.fields.find(f => f[property] === value);
        if (!field) {
            throw new Error(`Field with identifier [${property}: ${value}] does not exist`)
        }
        return field
    }

    getContentType() {
        const {findContentTypeByUuid, view} = this.props
        return findContentTypeByUuid(view.contentTypes[0]);
    }

    render() {
        const {view, settings} = this.props
        const {pivotTableAggregationItems, pivotTableGroupItems} = settings || {}

        return (
            <>
                <Form onValuesChange={this.onChangeForm} initialValues={settings}>
                    <Form.Item label={'Zobrazit počet záznamů seskupení'} name={'pivotTableShowCount'}
                               valuePropName={'checked'}>
                        <Switch/>
                    </Form.Item>
                </Form>

                <Form layout={'vertical'}>
                    <Form.Item label={'Seskupit podle'}>
                        <GroupItemsSettings onChange={this.onGroupChange} items={pivotTableGroupItems || []}
                                            viewUnit={view}/>
                    </Form.Item>
                    <Form.Item label={'Výsledky'}>
                        <AggregationItemsSettings onChange={this.onAggregationChange}
                                                  items={pivotTableAggregationItems || []} viewUnit={view}/>
                    </Form.Item>
                </Form>
            </>
        );
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    return {
        findContentTypeByUuid: (uuid: string) => selectors.contentTypes.findOneBy(state, 'uuid', uuid)
    }
}

export default connect(mapStateToProps)(ViewPivotTableSettings)