import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import {SETUP_LOAD, SETUP_RELOAD,} from '../constants/Setup';
import {endLoading, save, startLoading} from "../actions/Setup";

import SetupService from 'model/service/SetupService';
import IRestServiceSetupResponse from "../../model/interface/api/IRestServiceSetupResponse";
import Utils from "../../utils";
import {loadTheme} from "../actions/Theme";

export function* setupLoad() {
  	yield takeEvery(SETUP_LOAD, function* () {
		yield put(startLoading());
		const response: IRestServiceSetupResponse = yield call(SetupService.load)
		yield put(save(response));
		yield response.user.theme && put(loadTheme(response.user.theme));
		yield put(endLoading());
	});
}
export function* setupReload() {
  	yield takeEvery(SETUP_RELOAD, function* () {
		yield put(startLoading());
		localStorage.removeItem('setup')
		localStorage.removeItem('user-setup')
		Utils.cleanStorage()
		const response: IRestServiceSetupResponse = yield call(() => SetupService.load(false))
		yield put(save(response));
		yield put(endLoading());
	});
}

export default function* rootSaga() {
  yield all([
		fork(setupLoad),
		fork(setupReload)
  ]);
}
