import _ from 'underscore'
import {RootStateOrAny} from "react-redux";
import IWorkflow from "../../model/interface/dataStorage/workflow/IWorkflow";
import IContentType from "../../model/interface/dataStorage/IContentType";
import Utils from "../../utils";

export const findAll = (store: RootStateOrAny) => store.setup.workflows

export const findOneBy = (store: RootStateOrAny, property: string, value: any): IWorkflow => _.findWhere(findAll(store), {[property]: value})

export const findOneByContentType = (store: RootStateOrAny, contentType: IContentType): IWorkflow => {
    const workflows = findAll(store)
    const index = Utils.findIndex(workflows, {contentType: contentType.uuid})
    if(index < 0) {
        throw new Error('Workflow not found by content type: ' + contentType.name)
    }
    return workflows[index]
}

export default {
    findAll,
    findOneBy,
    findOneByContentType
}