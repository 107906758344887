import {Component} from "react";

interface IProps {
    label: string | JSX.Element | number,
    unit: string
}

interface IState {
}

export class Label extends Component <IProps, IState> {
    state: IState = {}

    render() {
        const {label, unit} = this.props
        return (
            <>
                {label && (
                    <>{label} {unit}</>
                )}
            </>
        )
    }
}

export default Label