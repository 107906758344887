import RestService from "model/service/dataStorage/RestService";
import IRestServiceOptions from "../../interface/api/IRestServiceOptions";
import IRestServiceCollectionResponse from "../../interface/api/IRestServiceCollectionResponse";
import IFieldType from "../../interface/dataStorage/IFieldType";
import IField, {RELATION_FIELD_TYPE} from "../../interface/dataStorage/IField";

interface IRestFieldTypesServiceCollectionResponse extends IRestServiceCollectionResponse {
    results: Array<IFieldType>
}

interface IRestFieldTypesService {
    collectionList(options?: IRestServiceOptions): Promise<IRestFieldTypesServiceCollectionResponse>,

    isCollection(field: IField): boolean
}

const FieldTypesService_COLLECTION = 'field-types'

const FieldTypesService: IRestFieldTypesService = {
    collectionList: function (options?) {
        return RestService.collectionList(FieldTypesService_COLLECTION, options as unknown as IRestServiceOptions) as Promise<IRestFieldTypesServiceCollectionResponse>
    },
    isCollection(field: IField): boolean {
        return [RELATION_FIELD_TYPE.MANY_TO_MANY, RELATION_FIELD_TYPE.ONE_TO_MANY].includes(field.type)
    }
}

export default FieldTypesService