import React from "react";
import {Button, Card, Col, Divider, Dropdown, List, Row, Tag, Tooltip, Typography} from "antd";
import _ from "underscore";
import IRestResource from "model/interface/api/IRestResource";
import IRepositoryService from "model/interface/IRepositoryService";
import IViewItem from "model/interface/dataStorage/view/IViewItem";
import IField, {
    FIELD_MODE_COMPOSITE,
    FIELD_MODE_COMPUTED,
    FIELD_MODE_SCALAR,
    FIELD_TYPE,
    RELATION_FIELD_TYPE
} from "model/interface/dataStorage/IField";
import FilterBuilder from "utils/FilterBuilder";
import {connect, RootStateOrAny} from "react-redux";
import selectors from "../../../redux/selectors";
import IContentType from "../../../model/interface/dataStorage/IContentType";
import IRestServiceOptions, {RestServiceOptions} from "../../../model/interface/api/IRestServiceOptions";
import IRestServiceFilters from "../../../model/interface/api/IRestServiceFilters";
import {TablePaginationConfig} from "antd/es";
import {FilterValue, SorterResult} from "antd/es/table/interface";
import IRestServiceOrders from "../../../model/interface/api/IRestServiceOrders";
import {ColumnsType, ColumnType} from "antd/lib/table/interface";
import {IFilterBaseValue} from "../../../utils/Filter/IFilterBase";
import {ISetupState} from "../../../redux/reducers/Setup";
import IUser from "../../../model/interface/security/IUser";
import DataStorageHelper from "../../../utils/DataStorageHelper";
import {IActionResult} from "../../../model/service/dataStorage/ActionsService";
import ViewCustomFilters, {DefaultCustomFilters} from "./ViewCustomFilters";
import {RefreshOutline} from "react-ionicons";
import IPresenter from "../../../model/interface/dataStorage/IPresenter";
import ViewUnit, {IBaseViewProps} from "./ViewUnit";
import IViewUnit from "../../../model/interface/dataStorage/IViewUnit";
import Table from "../../shared/Table/Table";
import ViewTableSettings from "./table/ViewTableSettings";
import ViewTableExportButton from "./table/ViewTableExportButton";
import FiltersService from "../../../model/service/dataStorage/FIltersService";
import {ActionButtonExecuteType} from "../action/ActionButton";
import IViewAction from "../../../model/interface/dataStorage/view/IViewAction";
import ViewEditButton from "./ViewEditButton";
import ViewPersonalEditButton from "./ViewPersonalEditButton";
import IRestServiceAggregations from "../../../model/interface/api/IRestServiceAggregations";
import {RestAggregation} from "../../../model/interface/api/IRestServiceCollectionResponse";
import IViewSettingsStructure from "../../../model/interface/dataStorage/view/settings/IViewSettingsStructure";
import {CaretLeftOutlined, SettingOutlined} from "@ant-design/icons";
import IViewPivotTableAggregationItemSettings
    from "../../../model/interface/dataStorage/view/pivotTable/IViewPivotTableAggregationItemSettings";
import IViewPivotTableGroupItemSettings
    from "../../../model/interface/dataStorage/view/pivotTable/IViewPivotTableGroupItemSettings";
import IViewTableItemSettings from "../../../model/interface/dataStorage/view/table/IViewTableItemSettings";
import Configuration from "./pivot/Configuration";
import IRestServiceGroupByList from "../../../model/interface/api/IRestServiceGroupByList";
import {generateUniqueID} from "web-vitals/dist/modules/lib/generateUniqueID";
import {
    API_AGGREGATION_GROUP_COUNT,
    API_AGGREGATION_TYPE,
    API_AGGREGATION_TYPE_LIST
} from "../../../model/constants/ApiConstant";
import ScrollContainer from "../../shared/scrollContainer/ScrollContainer";
import PresenterBuilder from "../../../views/dataStorage/PresenterBuilder";
import ViewTitle from "./ViewTitle";
import ViewSchemeActions from "./ViewSchemeActions";
import SortSettings from "./settings/SortSettings";
import ViewTableItemsSettings from "./table/ViewTableItemsSettings";

interface IState {
    results: Array<IRestResource>,
    aggregations?: RestAggregation[]
    page: number,
    count: number,
    totalCount: number,
    pageSize: number,
    loading: boolean,
    filters: IRestServiceFilters,
    customFilters?: IRestServiceFilters,
    order: IRestServiceOrders
    pivotSettingsOpen?: boolean
}

interface IProps extends IBaseViewProps {
    findServiceByContentType: (contentType: IContentType) => IRepositoryService,
    findServiceByClassName: (name: string) => IRepositoryService,
    findContentType: (uuid: string) => IContentType,
    extractRouteParametersFromUrl: (url: string) => null | { id: number, parameters: { [name: string]: any } },
    user: IUser,
    allowSettings?: boolean,
    allowExport?: boolean,
    title?: string,
    titleLevel?: 1 | 2 | 3 | 4 | 5,
}

type Column = IViewItem & (IViewPivotTableAggregationItemSettings & IViewPivotTableGroupItemSettings)
    & IViewTableItemSettings
    & { fieldObject: IField, isGroup: boolean, index: number }

class ViewPivotTable extends React.Component<IProps, IState> {

    constructor(props: Readonly<IProps> | IProps) {
        super(props);
        this.state = {
            results: [],
            filters: {},
            page: 1,
            count: 0,
            totalCount: 0,
            pageSize: 20,
            loading: false,
            order: SortSettings.buildSortSettings(props.settings, props.viewUnit, this.getContentType()),
            customFilters: ViewCustomFilters.getDefault(props.settings.customFilters)[this.getContentType().fullClassName],
            ...this.presetState(),
            pivotSettingsOpen: true
        }
    }

    presetState() {
        const {settings, state, viewUnit} = this.props
        let previousState: any | IState = {}
        if (settings.tablePageSave && state.tablePage) {
            previousState.page = state.tablePage
        }
        if (settings.tableFilterSave && state.tableFilter) {
            previousState.filters = Object.fromEntries(
                state.tableFilter.filter(filter => viewUnit.items.find(viewItem => {
                    const field = _.findWhere(this.getService().getFields(), {uuid: viewItem.field});
                    return "field" in filter && field?.name === filter.field
                }))
                    .map((filter, index) => [index + '-previousFilter', filter]))
        }
        if (settings.tableSortSave && state.tableSort) {
            previousState.order = {
                ...Object.fromEntries(
                    state.tableSort.map((sort, index) => [index + '-previousSort', sort])),
                ...SortSettings.buildSortSettings(settings, viewUnit, this.getContentType())
            }
        }
        if (settings.tablePageSize) {
            previousState.pageSize = settings.tablePageSize
        }
        return previousState
    }

    componentDidMount() {
        this.load().then()
    }

    componentWillUnmount() {
        window.onbeforeunload = null
    }

    componentDidUpdate(prevProps: Readonly<IProps>) {
        const {reload, settings} = this.props
        if (prevProps.reload !== reload && reload) {
            this.reload().then()
        }
        if (JSON.stringify(settings) !== JSON.stringify(prevProps.settings)) {
            this.setState(state => ({
                ...state, ...this.presetState(),
                page: settings.tablePageSize !== prevProps.settings.tablePageSize ? 1 : state.page
            }), settings.tablePageSize !== prevProps.settings.tablePageSize ? this.reload : undefined)
        }
    }

    load(ignoreCache?: boolean): Promise<void> {
        const contentType = this.getContentType()
        const {findServiceByContentType} = this.props
        const service = findServiceByContentType(contentType)

        const options = this.buildOptions(ignoreCache);
        this.setState({loading: true})
        return service.collectionList(options).then((result) => {
            return new Promise(resolve => {
                this.setState({
                    results: result.results,
                    count: result.count,
                    totalCount: result.totalCount,
                    loading: false,
                    aggregations: result.aggregations
                }, resolve)
            })
        })
    }

    buildOptions(ignoreCache?: boolean) {
        const contentType = this.getContentType()
        const {findServiceByContentType, viewUnit} = this.props
        const service = findServiceByContentType(contentType)
        const {page, pageSize, filters, customFilters, order} = this.state
        let fixedFilters = FiltersService.makeFixed({...this.props.filters, ...customFilters})

        const options: IRestServiceOptions = {
            page,
            limit: pageSize,
            filters: {...filters, ...fixedFilters},
            view: viewUnit.view,
            aggregations: this.getAggregations(service),
            groupBy: this.getGroupByList(),
            format: RestServiceOptions.FORMAT.PIVOT
        }

        if (order) {
            options.order = order
        }
        if (ignoreCache) {
            options.cache = false
        }
        return options;
    }

    getAggregations(service: IRepositoryService) {
        const {viewUnit, settings} = this.props
        let aggregations: IRestServiceAggregations = {}
        if (viewUnit.options?.aggregations) {
            Object.entries(viewUnit.options.aggregations).forEach(([uuid, item]) => {
                const field = _.findWhere(service.getFields(), {name: item.field});
                if (field) {
                    aggregations[uuid] = {
                        field: field.name,
                        type: item.type
                    }
                }
            })
        }

        const aggregationItems = this.buildAggregationItems(viewUnit, settings)

        aggregationItems.forEach(item => {
            if (item.type) {
                aggregations[item.fieldObject.name] = {field: item.fieldObject.name, type: item.type, groupBy: true}
            }
        })

        return aggregations;
    }

    getGroupByList() {
        const {viewUnit, settings} = this.props
        const groupItems = this.buildGroupItems(viewUnit, settings)

        let groupByList: IRestServiceGroupByList = {}
        groupItems.forEach(item => {
            groupByList[item.fieldObject.name] = {field: item.fieldObject.name}
        })
        return groupByList;
    }

    getFilters() {
        const {filters, customFilters} = this.state
        return {...filters, ...this.props.filters, ...customFilters};
    }

    reload() {
        return this.load(true);
    }

    onFinish = (result?: IActionResult): Promise<void> => {
        if (this.props.onFinishAction) {
            return this.props.onFinishAction(result)
        } else {
            return this.reload()
        }
    }

    getPayload() {
        let payload = {} as any;
        payload.route = this.props.extractRouteParametersFromUrl(this.props.history.location.pathname)
        return payload;
    }

    buildColumnStringValue(item: IViewItem, row: IRestResource) {
        const field: IField = this.findField(item)
        if ([FIELD_MODE_SCALAR, FIELD_MODE_COMPOSITE, FIELD_MODE_COMPUTED].includes(field.mode || '')) {
            return {
                value: row[field.name],
                label: row[field.name]
            }
        } else if (field.mode === 'relation') {
            const targetEntity = field.targetEntity
            if (!targetEntity) {
                throw new Error('Content type is not set')
            }
            let service: IRepositoryService = this.props.findServiceByClassName(targetEntity)
            if (!service) {
                throw new Error('Service for "' + field.targetEntity + '" is missing')
            }
            const relatedEntity = row[field.name] as IRestResource
            return {
                value: row[field.name]?.id || null,
                label: relatedEntity ? service.getStringValue(relatedEntity) : ''
            }
        } else {
            throw new Error('Invalid item mode: ' + field.mode)
        }
    }

    buildColumnValue(field: IField, item: IViewItem, row: IRestResource) {
        return this.buildValueByPresenter(field, item, row);
    }

    buildValueByPresenter(field: IField, item: IViewItem, row: IRestResource) {
        let presenter = this.getPresenter(field, item);
        return DataStorageHelper.buildFieldValue(field, row, this.props.history, presenter)
    }

    getPresenter(field: IField, item: IViewItem) {
        let presenter: null | IPresenter = null
        if (field.mode === 'relation') {
            const service = this.props.findServiceByClassName(field.targetEntity!)
            if (item.presenter) {
                presenter = service.getPresenter(item.presenter)
            }
            if (!presenter) {
                presenter = service.getDefaultPresenter()
            }
        }
        if (!presenter) {
            presenter = {
                type: 'label',
                name: 'default',
                label: 'Výchozí',
                options: {
                    label: "#" + field.name
                }
            } as IPresenter
        }
        presenter.options = {...item.options, ...presenter.options}
        return presenter
    }

    clearFilters() {
        this.setState({filters: {}}, () => {
            this.saveState()
            this.load().then()
        })
    }

    getContentType() {
        return this.props.findContentType(this.props.viewUnit.contentTypes[0])
    }

    buildColumns() {
        const {viewUnit, settings} = this.props
        const {pivotSettingsOpen} = this.state

        let tableColumns: ColumnsType<IRestResource> = []
        let groupItems = this.buildGroupItems(viewUnit, settings)
        let aggregationItems = this.buildAggregationItems(viewUnit, settings);

        tableColumns.push({
            className: 'p-0 text-center text-muted',
            width: 20,
            render: (_, row) => ({
                props: {style: {backgroundColor: this.getRowColor(groupItems, row)}}, children: ''
            }),
            title: <Button size={"small"} type={"link"} onClick={this.triggerPivotSettingsOpen}
                           icon={pivotSettingsOpen ? <CaretLeftOutlined/> : <SettingOutlined/>}/>
        })

        groupItems.forEach(item => {
            const field = item.fieldObject
            if (field && item.enabled) {
                let column = this.buildColumn({...item}, field);
                tableColumns.push(column)
            }
        })
        aggregationItems.forEach(item => {
            const field = item.fieldObject
            if (field && item.enabled) {
                let column = this.buildColumn(item, field);
                tableColumns.push(column)
            }
        })
        if (settings.pivotTableShowCount) {
            tableColumns.push({
                width: 20,
                title: 'Počet záznamů',
                render: (_, row) => ({
                    props: {style: {backgroundColor: this.getRowColor(groupItems, row)}},
                    children: row[API_AGGREGATION_GROUP_COUNT]
                })
            })
        }

        return tableColumns
    }

    buildColumn(item: Column, field: IField): ColumnType<IRestResource> {
        const {viewUnit, settings} = this.props
        const {filters, results} = this.state
        const contentType = this.getContentType()

        const defaultSort = this.getDefaultSort(viewUnit, contentType, field)
        const {noLabel, noLineBreak} = item.options || {}

        const title = noLabel ? '' : (item.label || item.options?.label || field.label || field.name)
        return {
            title: item.isGroup ? title : <div>
                <div>{title}</div>
                <div><Typography.Text disabled={true} className={'mr-2'}>
                    ({API_AGGREGATION_TYPE_LIST.find(t => item.type === t.value)?.label})
                </Typography.Text></div>
            </div>,
            key: item.isGroup ? field.name : field.name + item.index,
            className: item.columnColor + (noLineBreak ? ' text-nowrap' : '')
                + (item.isGroup && item.index === (this.buildGroupItems(viewUnit, settings).length - 1) ? ' border-right' : ''),
            dataIndex: item.isGroup ? field.name : field.name + item.index,
            sorter: item.sortable && !ViewTableItemsSettings.isSortableDisabled(field) ? defaultSort ? {multiple: defaultSort.priority} : true : false,
            defaultSortOrder: defaultSort && (defaultSort.direction === 'ASC' ? 'ascend' : 'descend'),
            render: (value: any, row: IRestResource) => {
                let parsedRow = {...row}
                return this.buildTableCell(item, parsedRow);
            },
            ...FilterBuilder.build(
                item,
                field,
                Object.entries(filters).filter(filter =>
                    "field" in filter[1] && filter[1].field.indexOf(field.name) === 0
                    && filter[1].value !== undefined
                ).map(filter => filter[1]) as IFilterBaseValue[],
                (value): void => {
                    value.length.toString()
                }, //TODO
                this.state.loading ? [] : results.map(row => this.buildColumnStringValue(item, row)),
                undefined,
                filters
            ),
            ...(Object.entries(filters).length === 0 ? {filteredValue: []} : {}),
        }
    }


    buildGroupItems(viewUnit: IViewUnit, settings: IViewSettingsStructure) {
        let items: Column[] = []
        if (settings.pivotTableGroupItems?.length) {
            settings.pivotTableGroupItems.forEach((item, index) => {
                let viewItems = viewUnit.items.filter(viewItem => viewItem.field === item.field)
                viewItems.forEach(viewItem => {
                    const fieldObject = viewItem && this.findField(viewItem)
                    const tableItem = settings.tableItems?.find(t => t.fieldId === viewItem.field)
                    if (viewItem && fieldObject) {
                        items.push({...viewItem, ...tableItem, ...item, fieldObject, isGroup: true, index})
                    }
                })
            })
        }

        return items.filter(item => item.enabled)
    }

    buildAggregationItems(viewUnit: IViewUnit, settings: IViewSettingsStructure) {
        let items: Column[] = []
        if (settings.pivotTableAggregationItems?.length) {
            settings.pivotTableAggregationItems.forEach((item, index) => {
                let viewItems = viewUnit.items.filter(viewItem => viewItem.field === item.field)
                viewItems.forEach(viewItem => {
                    const tableItem = settings.tableItems?.find(t => t.fieldId === viewItem.field)
                    const fieldObject = viewItem && this.findField(viewItem);
                    if (viewItem && fieldObject) {
                        items.push({...viewItem, ...tableItem, ...item, fieldObject, isGroup: false, index})
                    }
                })
            })
        }

        return items.filter(item => item.enabled)
    }

    findField(item: IViewItem) {
        let service: IRepositoryService = this.getService()
        return _.findWhere(service.getFields(), {uuid: item.field})!
    }

    getService() {
        const {findServiceByContentType} = this.props
        return findServiceByContentType(this.getContentType());
    }

    buildTableCell(item: Column, row: IRestResource) {
        const {viewUnit, settings} = this.props
        const field = item.fieldObject
        const {minWidth, maxWidth, noLineBreak} = item.options || {}

        let children: any = this.buildColumnValue(field, item, row)
        if (!item.isGroup) {
            const indexedFieldName = field.name + item.index
            if (item.type === API_AGGREGATION_TYPE.DATE_INTERVAL) {
                children = <div>
                    {this.buildColumnValue(field, {
                        ...item,
                        options: {...item.options, dateFormat: item.dateFormat}
                    }, {[field.name]: row[indexedFieldName]?.[0]})}
                    {' - '}
                    {this.buildColumnValue(field, {
                        ...item,
                        options: {...item.options, dateFormat: item.dateFormat}
                    }, {[field.name]: row[indexedFieldName]?.[1]})}
                </div>
            } else if (item.type === API_AGGREGATION_TYPE.LIST) {
                children = <Dropdown {...(row[indexedFieldName]?.length < 2 && {visible: false})}
                                     overlay={<Card size={"small"}>
                                         <ScrollContainer style={{maxHeight: '40vh'}}>
                                             <List>
                                                 {row[indexedFieldName]?.map((i: IRestResource, index: number) =>
                                                     <List.Item className={'p-2'} key={index}>
                                                         {this.buildListValue(field, item, i)}
                                                     </List.Item>)}
                                             </List>
                                         </ScrollContainer>
                                     </Card>}>
                    <div>
                        <div className={'d-inline-block mr-2'}>
                            {this.buildListValue(field, item, row[indexedFieldName]?.[0])}
                        </div>
                        {row[indexedFieldName]?.length > 1 &&
                            <Tag>+{row[indexedFieldName]?.length - 1}</Tag>}
                    </div>
                </Dropdown>
            } else {
                children = this.buildColumnValue(field, item, {[field.name]: row[indexedFieldName]})
            }
        }
        let style: React.CSSProperties = {}
        let className: string = ''
        switch (field.type) {
            case FIELD_TYPE.SPECIALIZED_COLOR:
                style.backgroundColor = row[field.name]
                children = null
        }
        if (minWidth) {
            style.minWidth = minWidth + "px"
        }
        if (maxWidth) {
            style.maxWidth = maxWidth + "px"
        }
        if (noLineBreak) {
            style.whiteSpace = 'nowrap'
            style.overflow = 'auto'
            className = className + ' minimalist-scroll-bar'
        }
        const items = this.buildGroupItems(viewUnit, settings)
        style.backgroundColor = this.getRowColor(items, row)
        return {
            props: {
                style: style,
                className: className,
            },
            children
        }
    }

    buildListValue(field: IField, item: Column, row: IRestResource) {
        return [RELATION_FIELD_TYPE.MANY_TO_MANY, RELATION_FIELD_TYPE.ONE_TO_MANY].includes(field.type) ?
            PresenterBuilder.build(this.getPresenter(field, item), row, this.getPresenter(field, item).options) :
            this.buildColumnValue(field, item, {[field.name]: row});
    }

    getRowColor(items: Column[], row: IRestResource) {
        let color = ''
        items.forEach(i => {
            if (!color && items.length > 1 && row[i.fieldObject.name]) {
                color = `rgba(204, 204, 204, ${i.index / items.length}`
            }
        })
        return color;
    }

    getDefaultSort(view: IViewUnit, contentType: IContentType, field: IField) {
        const {order} = this.state
        const {viewUnit} = this.props
        if (Object.keys(order).length > 0) {
            const index = Object.entries(order)
                .findIndex(order => Object.entries(ViewUnit.getItemSortBy(viewUnit, field))
                    .find(([, item]) => order[1].field === item.field))

            return index > -1 ? {priority: index + 1, ...Object.entries(order)[index][1]} : undefined
        }

        return undefined
    }

    onChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<any> | SorterResult<any>[]
    ) => {
        this.setState(_state => {
            return {
                pageSize: pagination.pageSize || this.state.pageSize,
                page: pagination.current || this.state.page,
                order: this.formatOrder(sorter),
                filters: this.formatFilters(filters as any)
            }
        }, () => {
            this.saveState()
            this.load().then()
        })
    }

    saveState() {
        const {settings, saveState, state} = this.props
        const {page, order, filters} = this.state
        let newState = {...state}
        if (settings.tablePageSave) {
            newState.tablePage = page
        }
        if (settings.tableSortSave) {
            newState.tableSort = Object.entries(order).map(orderItem => orderItem[1])
        }
        if (settings.tableFilterSave) {
            newState.tableFilter = Object.entries(filters).map(filterItem => filterItem[1])
        }
        saveState({...newState}).then()
    }

    formatOrder(sorter: SorterResult<any> | SorterResult<any>[]) {
        const {viewUnit} = this.props
        if (Object.keys(sorter).length === 0) {
            return {}
        }
        let orders: IRestServiceOrders = {};
        (Array.isArray(sorter) ? sorter : [sorter])
            .forEach((sort: SorterResult<any>) => {
                const field = _.findWhere(this.getService().getFields(), {name: sort.field as string})
                if (field) {
                    orders = {...orders, ...ViewUnit.getItemSortBy(viewUnit, field, sort.order === 'ascend' ? 'ASC' : 'DESC')}
                }
            })
        return orders;
    }

    formatFilters = (filters: Record<string, IFilterBaseValue[] | null>) => {
        const output: IRestServiceFilters = {}
        Object.keys(filters).forEach(name => {
            const filterList = filters[name]
            if (filters.hasOwnProperty(name) && filterList) {
                filterList.forEach((filterItem, index) => {
                    output[name + index] = {
                        field: filterItem.name || name,
                        type: filterItem.type,
                        value: filterItem.value,
                        label: filterItem.label
                    }
                })
            }
        })
        return output
    }

    onCustomFilterChange = (filters?: DefaultCustomFilters) => {
        this.setState({customFilters: filters?.[this.getContentType().fullClassName]}, this.load)
    }

    triggerPivotSettingsOpen = () => {
        this.setState(state => ({pivotSettingsOpen: !state.pivotSettingsOpen}))
    }

    executeAction = (executeAction: ActionButtonExecuteType, viewAction: IViewAction, resources?: IRestResource[]) => {
        const {extractRouteParametersFromUrl, history} = this.props
        this.setState({loading: true})
        return executeAction({
            route: extractRouteParametersFromUrl(history.location.pathname)
        }, undefined, resources)
            .catch(() => this.setState({loading: false}))
    }

    onChangeSettings = (settings: IViewSettingsStructure | null) => {
        const {updateSettings} = this.props
        this.setState({loading: true})
        return updateSettings(settings).then(() => this.load(true))
    }

    render() {
        const {allowExport, settings, viewUnit, history, match, insideTab, standalone, permissions} = this.props
        const {loading, page, pageSize, count, totalCount, pivotSettingsOpen, filters} = this.state;
        const pagination = count > pageSize || viewUnit.options.allowPageSizeChanger ? {
            current: page,
            total: count,
            pageSize,
            showSizeChanger: viewUnit.options.allowPageSizeChanger
        } : false

        const tableColumns = this.buildColumns()
        const filterList = Object.entries(filters)
            .filter(([filterName, filter], i, a) =>
                a.findIndex(([, f]) => "field" in f && "field" in filter && f.field === filter.field) === i)
            .map(([, filter]) => {
                const column = tableColumns.find(column => "field" in filter && column.key === filter.field)
                return column?.title
            })

        const results = this.buildGroupItems(viewUnit, settings).length
        || this.buildAggregationItems(viewUnit, settings).length ? this.state.results : []

        return (
            <div>
                <ViewTitle hideTitle={insideTab || !standalone} viewUnit={viewUnit}>
                    <Row className={"noPrint"} gutter={[4, 6]}>
                        <ViewSchemeActions onFinish={this.onFinish} contentType={this.getContentType()}
                                           onClick={this.executeAction}
                                           permissions={permissions} viewUnit={viewUnit} match={match}
                                           history={history}/>
                        <Col>
                            <Tooltip title={"Znovu načíst data"}>
                                <Button
                                    onClick={() => this.reload()}
                                    style={{verticalAlign: "middle"}}
                                    icon={<RefreshOutline/>}
                                    size={"small"}
                                />
                            </Tooltip>
                        </Col>
                        <ViewPersonalEditButton {...this.props} />
                        {(typeof allowExport === 'undefined' ? true : allowExport) && (
                            <Col>
                                <ViewTableExportButton viewUnit={viewUnit} totalCount={count} settings={settings}
                                                       options={this.buildOptions()}/>
                            </Col>
                        )}
                        <ViewEditButton {...this.props} />
                        <Col
                            className={"d-flex align-content-center align-self-center align-items-center  text-nowrap"}>
                            {filterList.length > 0 ? (
                                <>
                                    <Typography className={"font-size-base font-weight-normal text-muted"}>
                                        filtrováno <strong>{count}</strong> z <strong>{totalCount}</strong> záznamů
                                        podle <strong>{filterList.join(', ')}</strong></Typography>
                                    <Button type={"link"} onClick={() => this.clearFilters()}>Zrušit filtry</Button>
                                </>
                            ) : (
                                <Typography className={"font-size-base font-weight-normal text-muted mr-2"}>
                                    {filterList.length > 0 && <Divider type={"vertical"}/>}
                                    záznamů: {count}
                                </Typography>
                            )}
                        </Col>
                    </Row>
                </ViewTitle>

                {settings.customFilters && (
                    <div className={'mb-2'}>
                        <ViewCustomFilters filters={settings.customFilters} onChange={this.onCustomFilterChange}/>
                    </div>
                )}

                <Row wrap={false} gutter={8} className={'mt-3'}>
                    <Col>
                        <Configuration open={pivotSettingsOpen} viewUnit={viewUnit} settings={settings}
                                       onChange={this.onChangeSettings} match={match} history={history}/>
                    </Col>
                    <Col flex={"auto"}>
                        <Table className={'border rounded overflow-hidden'}
                               loading={loading}
                               rowClassName={'bg-theme'}
                               pagination={pagination}
                               columns={tableColumns}
                               dataSource={results}
                               rowKey={() => generateUniqueID()}
                               onChange={this.onChange}
                        />
                    </Col>
                </Row>
            </div>
        )
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    const {user} = state.setup as ISetupState
    return {
        findServiceByContentType: (contentType: IContentType) => selectors.services.findOneByContentType(state, contentType),
        findServiceByClassName: (name: string) => selectors.services.findOneByFullClassName(state, name),
        findContentType: (uuid: string) => selectors.contentTypes.findOneBy(state, 'uuid', uuid),
        extractRouteParametersFromUrl: (url: string) => selectors.routes.extractRouteParametersFromUrl(state, url),
        user
    }
}

export default connect(mapStateToProps)(ViewPivotTable)