import {Button, Card, Form, Input, Row, Switch} from 'antd';
import {CaretDownOutlined, CaretUpOutlined, PlusOutlined} from '@ant-design/icons';
import React from "react";
import IFormFieldSelectOption from "../../../../../../../model/interface/form/formField/IFormFieldSelectOption";
import Title from "antd/es/typography/Title";
import DragSortList from "../../../../../../shared/list/DragsortList";
import arrayMove from "array-move";


export interface ISelectEditorOptions {
    multiple?: boolean
}


interface IProps {
    multiple: boolean
    options?: IFormFieldSelectOption[]
}

interface IState {
    options: IFormFieldSelectOption[]
}

class FormFieldSelectOptionEditor extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            options: props.options ? [...props.options] : [],
        }
    }

    handleClose = (removeOption: IFormFieldSelectOption) => {
        const options = this.state.options.filter(option => option.value !== removeOption.value);
        this.setState({options});
    };

    confirm = () => {
        let {options} = this.state;
        options = [...options, {value: '', title: ''}]
        this.setState({options})
    }

    onSortEnd = ({oldIndex, newIndex}: { oldIndex: number, newIndex: number }): void => {
        const {options} = this.state
        const sortList = arrayMove(options, oldIndex, newIndex)
        this.setState({options: sortList});
    }

    render() {
        const {options} = this.state

        return (
            <Card>
                <Form.Item name={'multiple'} label={'Více položek'} valuePropName={'checked'}>
                    <Switch/>
                </Form.Item>

                <Title level={4}>Select Options</Title>

                <DragSortList lockAxis={"y"} item={{
                    style: {zIndex: 1001},
                    render: (item: IFormFieldSelectOption, index, handle) =>
                        <Row justify={"space-between"} align={"middle"}
                             className={'mb-2 border p-1 shadow-sm'} key={item.value}>
                            <div>
                                {handle}
                            </div>
                            <div>
                                <Form.Item name={['selectOptions', index, 'title']} label={'Nazev'}
                                           rules={[{'required': true}]}>
                                    <Input/>
                                </Form.Item>
                                <Form.Item name={['selectOptions', index, 'value']} label={'Hodnota'}
                                           rules={[{'required': true}]}>
                                    <Input/>
                                </Form.Item>
                            </div>
                        </Row>
                }} children={options} onSortEnd={(sort) =>
                    this.onSortEnd(sort)}
                              handle={{
                                  render: () => <div className={'d-inline-block'}>
                                      <div className={"d-flex flex-column pl-2 pr-2"} style={{cursor: "move"}}>
                                          <CaretUpOutlined/>
                                          <CaretDownOutlined/>
                                      </div>
                                  </div>
                              }}/>

                <Button onClick={this.confirm} icon={<PlusOutlined/>}>
                    Add
                </Button>
            </Card>
        );
    }
}

export default FormFieldSelectOptionEditor