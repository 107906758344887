import IActionScript from "./IActionScript";

export type ActionType =
    'form'
    | 'script'
    | 'script_form'
    | 'show'
    | 'delete'
    | 'workflow_state'
    | 'import'
    | 'generate_document'
    | 'cron'
    | 'table_form'
    | 'revision_history'
    | 'relation_from'
    | 'create_comment'
    | '';

export const ACTION_RESERVED_NAMES = {
    CALENDAR_SHOW: '#calendar-show',
    CALENDAR_CREATE: '#calendar-create'
}
export default interface IAction {
    id?: number,
    uuid: string,
    name: string,
    label: string,
    type: ActionType
    form?: string | null,
    forms: string[],
    actionScripts: IActionScript[],
    notifications: string[],
    locked: boolean,
    route?: string,
    contentType: string
    afterRedirect?: string,
    confirmModalText?: string,
    confirmModalEnabled?: boolean,
    modal?: boolean
    importSource?: string,
    generatedDocumentPattern?: string
    relationAction?: string
}

export const ActionTypes = {
    FORM: 'form' as ActionType,
    SCRIPT: 'script' as ActionType,
    SCRIPT_FORM: 'script_form' as ActionType,
    SHOW: 'show' as ActionType,
    DELETE: 'delete' as ActionType,
    WORKFLOW_STATE: 'workflow_state' as ActionType,
    IMPORT: 'import' as ActionType,
    GENERATE_DOCUMENT: 'generate_document' as ActionType,
    CRON: 'cron' as ActionType,
    TABLE_FORM: 'table_form' as ActionType,
    REVISION_HISTORY: 'revision_history' as ActionType,
    RELATION_ACTION: 'relation_action' as ActionType,
    CREATE_COMMENT: 'create_comment' as ActionType
}

export const ActionTypesList = [
    {
        value: ActionTypes.FORM,
        label: 'Formulář'
    },
    {
        value: ActionTypes.RELATION_ACTION,
        label: 'Vztahová akce'
    },
    {
        value: ActionTypes.DELETE,
        label: 'Smazat'
    },
    {
        value: ActionTypes.SHOW,
        label: 'Zobrazit'
    },
    {
        value: ActionTypes.SCRIPT,
        label: 'Skript'
    },
    {
        value: ActionTypes.SCRIPT_FORM,
        label: 'Samostatný formulář'
    },
    {
        value: ActionTypes.IMPORT,
        label: 'Import'
    },
    {
        value: ActionTypes.CRON,
        label: 'Cron'
    },
    {
        value: ActionTypes.TABLE_FORM,
        label: 'Tabulkový formulář'
    },
    {
        value: ActionTypes.WORKFLOW_STATE,
        label: 'Změna stavu workflow'
    },
    {
        value: ActionTypes.GENERATE_DOCUMENT,
        label: 'Generování dokumentu'
    },
    {
        value: ActionTypes.REVISION_HISTORY,
        label: 'Historie revizí'
    },
    {
        value: ActionTypes.CREATE_COMMENT,
        label: 'Vytvoření komentáře'
    }
]