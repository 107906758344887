import React from "react";
import {Button, Form, FormInstance, Input, Result, Select, Spin} from "antd";
import Wysiwyg from "../../shared/input/Wysiwyg";
import IMessage from "../../../model/interface/ui/IMessage";
import IUser from "../../../model/interface/security/IUser";
import Utils from "../../../utils";
import {connect, RootStateOrAny} from "react-redux";
import {MessageOutlined} from "@ant-design/icons";
import UserLabel from "../security/UserLabel";
import MessagesService from "../../../model/service/ui/MessagesService";
import UsersService from "../../../model/service/security/UsersService";

interface IProps {
    formRef?: React.Ref<FormInstance>
    showSubmit?: boolean
    recipients?: IUser[],
    lockRecipients?: boolean
    sender: IUser
    currentUser: IUser
    onAfterSend: () => void
}

interface IState {
    loading: boolean
    lockRecipients: boolean
    recipientFilter: string
    message: IMessage
    result: null|boolean
    users: IUser[]
}

interface IMessageValues {
    body?: string
    subject?: string
}

class MessageForm extends React.Component<IProps, IState> {
    state = {
        loading: false,
        lockRecipients: typeof this.props.lockRecipients === 'undefined' ? false : this.props.lockRecipients,
        recipientFilter: '',
        result: null,
        users: [] as IUser[],
        message: {
            uuid: Utils.uuid(),
            recipients: this.props.recipients || [],
            sender: this.props.sender,
            subject: '',
            sendAt: null,
            body: '',
            state: 0
        }
    }

    componentDidMount() {
        this.load();
    }

    load () {
        if(!this.props.lockRecipients) {
            this.setState({loading: true}, () => {
                UsersService.collectionList().then(response => {
                    this.setState({users: response.results, loading: false})
                })
            })
        }
    }

    onChange(values: IMessageValues) {

    }

    reset() {
        this.setState({
            result: null,
            message: {
                uuid: Utils.uuid(),
                recipients: this.props.recipients || [],
                sender: this.props.sender || this.props.currentUser,
                subject: '',
                body: '',
                sendAt: null,
                state: 0
            }
        })
    }

    backToForm() {
        this.setState({result: null})
    }

    send(values: IMessageValues) {
        const data = {
            ...this.state.message,
            ...values,
            recipients: this.state.message.recipients.map(recipient => recipient.id),
            sender: this.state.message.sender ? this.state.message.sender.id : null
        }
        console.log('send', values, data, this.props.sender)
        this.setState({loading: true}, () => {
            MessagesService.collectionCreate(data).then(() => {
                if(this.props.onAfterSend) {
                    this.props.onAfterSend()
                } else {
                    this.setState({result:true, loading: false})
                }
            }).catch(() => {
                this.setState({result:false, loading: false})
            })
        })
    }

    buildRecipientsOptions () {
        console.log('build recipients options', this.state.users
            .filter(user => user.username.includes(this.state.recipientFilter))
            .map((user: IUser) => ({
                label: (<UserLabel user={user} />),
                value: user.uuid!
            })))
        return this.state.users
            .filter(user => user.username.includes(this.state.recipientFilter))
            .map((user: IUser) => ({
                label: (<UserLabel user={user} />),
                value: user.uuid!
            }))
    }

    onRecipientsFilter (input: string, uuid: string) {
        const user = this.state.users.find(user => user.uuid === uuid)
        return (user && user.username.includes(input)) as boolean
    }

    onRecipientsChange (values: any) {
        this.setState(prevState => {
            console.log('onRecipientsChange', this.state.message)
            return {
                message: {
                    ...prevState.message,
                    recipients: values.map((uuid: string) => this.state.users.find(user => user.uuid === uuid))
                }
            }
        })
    }

    render() {
        const {showSubmit, formRef} = this.props
        const {loading, message, lockRecipients, result} = this.state
        return (
            <Spin spinning={loading}>
                {result !== null ? (
                    <>
                        {result ? (
                            <Result
                                status="success"
                                title="Zpráva byla úspěšně odeslána"
                                subTitle="Příjemce zprávu obdrží dle jejich nastavených preferencí"
                                extra={[
                                    <Button type="primary" onClick={() => this.reset()}>
                                        Poslat novou zprávu
                                    </Button>
                                ]}
                            />
                        ) : (
                            <Result
                                status="error"
                                title="Zpráva se nepodařilo odeslat"
                                subTitle="Při odesílání zprávy došlo k  chybě, prosím zkuste to znovu"
                                extra={[
                                    <Button type="primary" onClick={() => this.backToForm()}>
                                        Zpět
                                    </Button>
                                ]}
                            />
                        )}
                    </>
                ) : (
                    <Form ref={formRef} onFinish={(values) => this.send(values)} initialValues={{...message}}>
                        {lockRecipients ? (
                            <div className={"mb-3 d-flex"}>
                                <div className={"font-weight-semibold mr-1"}>Příjemci:</div>
                                <div>
                                    {message.recipients.map(user => (
                                        <div className={"mb-1"}>
                                            <UserLabel user={user} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <Form.Item
                                name={"recipients"}
                                label={"Příjemci"}
                                rules={[{required: true,message: "Pole je povinné"}]}
                            >
                                <Select
                                    options={this.buildRecipientsOptions()}
                                    mode={"multiple"}
                                    filterOption={(input, option) => this.onRecipientsFilter(input, option!.value)}
                                    onChange={(values) => this.onRecipientsChange(values)}
                                />
                            </Form.Item>
                        )}

                        <Form.Item
                            name={"subject"}
                            label={"Předmět"}
                            rules={[{required: true,message: "Pole je povinné"}]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            name={"body"}
                            label={"Zpráva"}
                            rules={[{required: true,message: "Pole je povinné"}]}
                        >
                            <Wysiwyg onChange={(body) => this.onChange({body})} />
                        </Form.Item>

                        {showSubmit && (
                            <Button htmlType={"submit"} icon={<MessageOutlined />}>Odeslat</Button>
                        )}
                    </Form>
                )}
            </Spin>
        )
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    return {
        currentUser: state.setup.user
    }
}

export default connect(mapStateToProps)(MessageForm)