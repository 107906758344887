import {Button, Image, Modal, Row, Tooltip, Typography} from 'antd';
import React, {RefObject} from "react";
import IFieldOptions from "../../../../../../model/interface/form/elementOptions/IFieldOptions";
import SignatureCanvas from 'react-signature-canvas'
import {CloseOutlined, EditOutlined} from "@ant-design/icons";
import Utils from "../../../../../../utils";

interface IProps {
    value?: string,
    onChange?: (value?: string) => void
    options: IFieldOptions
}

interface IState {
    value?: string
    writing?: boolean,
    showModal?: boolean
}

class FormFieldSignature extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            value: props.value
        }
    }

    ref: RefObject<SignatureCanvas> = React.createRef<SignatureCanvas>()

    handleChange = (clear: boolean = false) => {
        clear && this.ref.current?.clear()
        const signature = this.ref.current?.getTrimmedCanvas().toDataURL('image/png')
        this.setState({
                value: clear ? undefined : this.ref.current?.isEmpty() ? undefined : signature,
                writing: false
            }, clear ? this.updateParent : undefined
        )
    }

    onBegin = () => {
        this.setState({writing: true})
    }

    showModal = () => {
        this.setState(state => ({showModal: !state.showModal}))
    }

    submit = () => {
        this.setState({showModal: false})
        this.updateParent()
    }

    updateParent() {
        this.props.onChange && this.props.onChange(this.state.value)
    }

    render() {
        const {placeholder, disabled} = this.props.options
        const {value} = this.props
        const {writing, showModal} = this.state
        const stateValue = this.state.value
        disabled && this.ref.current?.off()

        return (
            <div>
                <Row justify={"center"} align={'middle'} className={'position-relative border rounded p-3'}>
                    {value ?
                        <div>
                            <Image preview={false} src={value} fallback={Utils.imageFallback()} height={100}/>
                            {!disabled && (
                                <div className={'position-absolute'} style={{top: 0, right: 0}}>
                                    <Tooltip title={'Vymazat podpis'}>
                                        <Button size={"small"} type={'link'} icon={<CloseOutlined/>} danger
                                                onClick={() => this.handleChange(true)}/>
                                    </Tooltip>
                                </div>
                            )}
                        </div>
                        :
                        <Button disabled={disabled} type={"dashed"} onClick={this.showModal}
                                icon={<EditOutlined/>}>{placeholder || 'Podepsat'}</Button>
                    }
                </Row>
                <Modal visible={showModal} title={'Podpis'} width={800}
                       onCancel={this.showModal} footer={
                    <Row justify={"end"} gutter={12}>
                        <Button size={"small"} disabled={!stateValue} onClick={this.submit}>Potvrdit</Button>
                    </Row>
                }>
                    <div className={'position-relative border rounded'} style={{height: '50vh'}}>
                        <SignatureCanvas onBegin={() => this.onBegin()} minWidth={1}
                                         onEnd={() => this.handleChange()} ref={this.ref}
                                         canvasProps={{
                                             className: 'position-absolute w-100 h-100', style: {zIndex: 2}
                                         }}/>
                        {!stateValue && !writing &&
                            <Row className={'position-absolute w-100 h-100'} justify={'center'} align={'middle'}
                                 style={{top: 0, left: 0}}>
                                <Typography.Text
                                    type={"secondary"}>{'Podepište se zde, prosím'}</Typography.Text>
                            </Row>
                        }
                        {stateValue &&
                            <div className={'position-absolute'} style={{top: 0, right: 0, zIndex: 3}}>
                                <Tooltip title={'Vymazat podpis'}>
                                    <Button size={"small"} type={'link'} icon={<CloseOutlined/>} danger
                                            onClick={() => this.handleChange(true)}/>
                                </Tooltip>
                            </div>
                        }
                    </div>
                </Modal>
            </div>
        )
    }
}

export default FormFieldSignature