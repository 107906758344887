import {Avatar} from "antd";
import {UserOutlined} from "@ant-design/icons";
import React from "react";
import {AvatarSize} from "antd/lib/avatar/SizeContext";
import {AvatarProps} from "antd/lib/avatar/avatar";

interface IProps extends AvatarProps{
    src: string|undefined|React.ReactNode
    className?: string
    size?: AvatarSize
    title?: string
}

interface IState {
    size: AvatarSize
}

class AvatarWithDefault extends React.Component<IProps, IState> {
    state = {
        size: this.props.size || "small"
    }
    guessFontSize (): number {
        const size = this.props.size
        // @ts-ignore
        if(isNaN(size)) {
            // @ts-ignore
            return size
        }
        // @ts-ignore
        return parseInt(size, 10)  * .5
    }
    render() {
        const {size} = this.state
        const {src, className, title} = this.props
        const color = '#' + ('000000' + (parseInt(parseInt(title!, 36).toExponential().slice(2,-5), 10) & 0xFFFFFF).toString(16).toUpperCase()).slice(-6)

        return (
            <>
                { src ? (
                    <Avatar {...this.props} style={{ backgroundColor: 'transparent'}} className={className || 'mr-2'} src={src} size={size} />
                ) : (
                    <>
                        {title ? (
                            <Avatar {...this.props} style={{ backgroundColor: color, fontSize: this.guessFontSize()}} className={className || 'mr-2'} size={size}>{title.split(' ').map(part => part.length ? part[0] : '').join('').toUpperCase()}</Avatar>
                        ) : (
                            <Avatar {...this.props} style={{ backgroundColor: '#333'}} className={className || 'mr-2'} icon={<UserOutlined />} size={size} />
                        )}
                    </>
                )}
            </>
        )
    }
}

export default AvatarWithDefault