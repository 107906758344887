import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE, DIR_LTR } from 'model/constants/ThemeConstant';
import { env } from './EnvironmentConfig'

export const APP_NAME = 'ProCES';
export const API_BASE_URL = env.API_ENDPOINT_URL
export const APP_PREFIX_PATH = '/app';
export const AUTH_PREFIX_PATH = '/auth';
export const SYMFONY_CONSOLE_PATH = '/symfony-console';

export const THEME_CONFIG = {
	navCollapsed: false,
	// sideNavTheme: SIDE_NAV_LIGHT,
	locale: 'cs',
	// navType: NAV_TYPE_SIDE,
	// topNavColor: '#3e82f7',
	// headerNavColor: '',
	mobileNav: false,
	// currentTheme: 'light',
	// direction: DIR_LTR
	margin: 'lg',
	fontSize: 'lg',
	tableSize: 'lg',
	// sideNavTheme: SIDE_NAV_LIGHT,
	// locale: 'cs',
	navType: NAV_TYPE_SIDE,
	mode: 'auto'
	// topNavColor: '#3e82f7',
	// headerNavColor: '',
	// mobileNav: false,
	// currentTheme: 'light',
	// direction: DIR_LTR
};
