import IType from "./workload/IType";
import ILabelValue from "../util/ILabelValue";

export type ShiftType = "daily"|"weekly"|"work_day"|"monthly"|"yearly"| "on_holiday" | "odd_weekly" | "even_weekly" | string

export const SHIFT_TYPE = {
    WORK_DAY: 'work_day',
    DAILY: 'daily',
    WEEKLY: 'weekly',
    EVEN_WEEKLY: 'even_weekly',
    ODD_WEEKLY: 'odd_weekly',
    MONTHLY: 'monthly',
    YEARLY: 'yearly',
    ON_HOLIDAY: 'on_holiday'
}

export const SHIFT_TYPES: ILabelValue<string, string>[] = [
    {
        label: 'Pracovní den',
        value: SHIFT_TYPE.WORK_DAY
    },
    {
        label: 'Ve svátek',
        value: SHIFT_TYPE.ON_HOLIDAY
    },
    {
        label: 'Každý den',
        value: SHIFT_TYPE.DAILY
    },
    {
        label: 'Týdně',
        value: SHIFT_TYPE.WEEKLY
    },
    {
        label: 'V sudém týdnu',
        value: SHIFT_TYPE.EVEN_WEEKLY
    },
    {
        label: 'V lichém týdnu',
        value: SHIFT_TYPE.ODD_WEEKLY
    },
    {
        label: 'Měsíčně',
        value: SHIFT_TYPE.MONTHLY
    },
    {
        label: 'Ročně',
        value: SHIFT_TYPE.YEARLY
    }
]

export default interface IShift {
    id?: number,
    uuid: string,
    startAtTime: string,
    endAtTime: string,
    type: ShiftType,
    value: number| undefined,
    startAtTimeHours: number,
    startAtTimeMinutes: number,
    endAtTimeHours: number,
    endAtTimeMinutes: number,
    totalHours: number
    entryType: IType|string|null
    exceptional: boolean
}