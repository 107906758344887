import React from "react";
import IEmployee from "../../../../model/interface/company/IEmployee";
import {Skeleton} from "antd";
import AvatarStatus from "../../../shared/AvatarStatus";
import {AvatarSize} from "antd/lib/avatar/SizeContext";

interface IProps {
    employee: IEmployee | null,
    loading?: boolean
    mode?: "default" | "simple"
    size?: AvatarSize
}

class EmployeeLabel extends React.Component<IProps> {
    render() {
        const {employee, size, loading} = this.props
        if (loading) {
            return <Skeleton.Button active={true} className={'min-w-150'}/>
        }
        if (!employee) {
            return (<span className={"text-muted"}>Neplatný zaměnstnanec</span>)
        }
        switch (this.props.mode) {
            case('simple'):
                // @ts-ignore
                return (<AvatarStatus onNameClick={() => {
                }} src={employee.photo ? employee.photo.thumbnailUrl : null}
                                      name={employee.name + ' ' + employee.surname} size={size}/>)
            default:
                // @ts-ignore
                return (<AvatarStatus onNameClick={() => {
                }} src={employee.photo ? employee.photo.thumbnailUrl : null}
                                      name={employee.name + ' ' + employee.surname}
                                      subTitle={employee.email || undefined}
                                      size={size}/>)
        }

    }
}

export default EmployeeLabel