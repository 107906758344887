import IRestServiceFilters, {isFilterNested} from "../../interface/api/IRestServiceFilters";
import IRestResource from "../../interface/api/IRestResource";
import {API_FILTER_TYPE} from "../../constants/ApiConstant";
import IRestServiceFilter from "../../interface/api/IRestServiceFilter";
import Utils from "../../../utils";
import FormFieldType from "../../../components/app/configuration/form/FormElement/formField/FormFieldType";
import IContentType from "../../interface/dataStorage/IContentType";
import FieldEditor from "../../../components/app/configuration/form/FormElement/optionEditor/FieldEditor";


const FilterEvaluationService = {
    evaluateByType: function (filter: IRestServiceFilter, resource: IRestResource, contentType: IContentType): boolean {
        const field = contentType.fields.find(f => f.name === filter.field)!
        let value = FormFieldType.formatToForm(FieldEditor.detectType(field), resource[field.name])
        const filterValue = FormFieldType.formatToForm(FieldEditor.detectType(field), filter.value)
        const identifier = Utils.isUuid(Array.isArray(filterValue) ? filterValue[0] : filterValue) ? 'uuid' : 'id'
        value = Utils.parseObjectToIdentifier(value, identifier)

        switch (filter.type) {
            case API_FILTER_TYPE.EQUAL:
                return filterValue === value
            case API_FILTER_TYPE.NOT_EQUAL:
                return filterValue !== value
            case API_FILTER_TYPE.LESSER:
                return !!filterValue && filterValue > value
            case API_FILTER_TYPE.LESSER_OR_EQUAL:
                return !!filterValue && filterValue >= value
            case API_FILTER_TYPE.GREATER:
                return !!filterValue && filterValue < value
            case API_FILTER_TYPE.GREATER_OR_EQUAL:
                return !!filterValue && filterValue <= value
            case API_FILTER_TYPE.CONTAINS:
                return Array.isArray(value) && Array.isArray(filterValue) &&
                    filterValue.every(item => value.includes(item))
            case API_FILTER_TYPE.CONTAINS_NOT:
                return Array.isArray(value) && Array.isArray(filterValue) &&
                    !filterValue.every(item => value.includes(item))
            case API_FILTER_TYPE.IN:
                return Array.isArray(filterValue) && filterValue.includes(value as never)
            case API_FILTER_TYPE.NOT_IN:
                return Array.isArray(filterValue) && !filterValue.includes(value as never)
            case API_FILTER_TYPE.LIKE:
                return Utils.stringContains(value, filterValue)
            case API_FILTER_TYPE.LIKE_BEGINNING:
                return Utils.stringStartsWith(value, filterValue) && resource.value
            case API_FILTER_TYPE.IS_NOT_NULL:
                return !!filterValue
            case API_FILTER_TYPE.IS_NULL:
                return !filterValue
            default:
                return false

        }
    }, evaluate: function (filters: IRestServiceFilters, resource: IRestResource, contentType: IContentType, or: boolean = false) {
        let isTrue = false;
        Object.entries(filters).forEach(([, filter]) => {
            if (isFilterNested(filter)) {
                isTrue = this.evaluate(filter.children, resource, contentType,  filter.type === API_FILTER_TYPE.OR)
            } else {
                isTrue = this.evaluateByType(filter, resource, contentType)
            }
            if (or && isTrue) {
                return true
            }
        })
        return isTrue
    }
}

export default FilterEvaluationService