import React, {RefObject} from "react";
import {Button, Carousel, Col, Empty, Input, Row, Spin, Tooltip, Typography} from "antd";
import IRestResource from "model/interface/api/IRestResource";
import IRepositoryService from "model/interface/IRepositoryService";
import {connect, RootStateOrAny} from "react-redux";
import selectors from "../../../redux/selectors";
import IContentType from "../../../model/interface/dataStorage/IContentType";
import IRestServiceOptions from "../../../model/interface/api/IRestServiceOptions";
import {LeftOutlined, RightOutlined} from "@ant-design/icons";
import {ISetupState} from "../../../redux/reducers/Setup";
import IUser from "../../../model/interface/security/IUser";
import {IActionResult} from "../../../model/service/dataStorage/ActionsService";
import IRestServiceFilters from "../../../model/interface/api/IRestServiceFilters";
import ViewCustomFilters, {DefaultCustomFilters} from "./ViewCustomFilters";
import {IBaseViewProps} from "./ViewUnit";
import FiltersService from "../../../model/service/dataStorage/FIltersService";
import {ActionType} from "../../../model/interface/dataStorage/IAction";
import ViewEditButton from "./ViewEditButton";
import {RefreshOutline} from "react-ionicons";
import ViewTitle from "./ViewTitle";
import ViewSchemeActions from "./ViewSchemeActions";
import {CarouselRef} from "antd/lib/carousel";
import Card from "../configuration/content-type/card/Card";
import SortSettings from "./settings/SortSettings";
import ViewPersonalEditButton from "./ViewPersonalEditButton";

interface IState {
    results: { [key: number]: IRestResource },
    loading: boolean,
    count: number,
    totalCount: number,
    page: number,
    pageLoaded: number,
    customFilters?: IRestServiceFilters
}

interface IProps extends IBaseViewProps {
    findServiceByContentType: (contentType: IContentType) => IRepositoryService,
    findContentType: (uuid: string) => IContentType,
    user: IUser
}

class ViewCarousel extends React.Component<IProps, IState> {

    carouselRef: RefObject<CarouselRef> = React.createRef()

    constructor(props: Readonly<IProps> | IProps) {
        super(props);
        this.state = {
            results: {},
            loading: false,
            count: 0,
            totalCount: 0,
            page: 1,
            pageLoaded: 1,
            customFilters: ViewCustomFilters.getDefault(props.settings.customFilters)[this.getContentType().fullClassName]
        }
    }

    componentDidMount() {
        this.load().then()
    }

    componentDidUpdate(prevProps: Readonly<IProps>) {
        if (prevProps.reload !== this.props.reload && this.props.reload) {
            this.load().then()
        }
    }

    load = async (force = false): Promise<void> => {
        const {page, customFilters} = this.state
        const {findServiceByContentType, filters, settings, viewUnit} = this.props
        const contentType = this.getContentType()
        const service = findServiceByContentType(contentType)
        if (this.state.results[page] && !force) {
            return new Promise(resolve => {
                this.setState({pageLoaded: page}, resolve)
            })
        }
        this.setState({
            loading: true
        })
        let fixedFilters = FiltersService.makeFixed({...filters, ...customFilters})
        const forcedPage = force ? 1 : page
        const options: IRestServiceOptions = {
            limit: 1,
            page: forcedPage,
            order: {'help': {field: 'id'}},
            filters: {
                ...fixedFilters,
            },
            view: this.props.viewUnit.view,
            cache: !force
        }
        if (settings.sort) {
            options.order = SortSettings.buildSortSettings(settings, viewUnit, this.getContentType())
        }
        const {results, count, totalCount} = await service.collectionList(options);
        return await new Promise<void>(resolve => {
            this.setState(state => ({
                results: {...(force ? {} : state.results), [forcedPage]: results[0]},
                totalCount,
                count,
                page: forcedPage,
                pageLoaded: forcedPage,
                loading: false
            }), () => {
                this.carouselRef.current?.goTo(this.state.page - 1)
                resolve()
            });
        });
    }

    doAction = (result?: IActionResult) => {
        if (result) {
            return new Promise<void>(() => {
                if (this.props.onFinishAction) {
                    return this.props.onFinishAction(result)
                } else {
                    return this.load(true)
                }
            })
        }
        return Promise.resolve()
    }

    onCardActionFinished = (result?: IActionResult): Promise<void> => {
        const reloadTypes: ActionType[] = ['delete', 'import']
        return reloadTypes.includes(result?.action.type || '') ? this.load(true) : Promise.resolve()
    }

    getContentType() {
        return this.props.findContentType(this.props.viewUnit.contentTypes[0])
    }

    getViewOptions() {
        return this.props.viewUnit.options
    }

    getCards() {
        return this.getContentType().cards.filter(card => this.getViewOptions().carouselCards?.includes(card.uuid))
    }

    changePage = (page: number) => {
        this.setState({page: page + 1}, () => {
            this.load().then()
        })
    }

    onCustomFilterChange = (filters?: DefaultCustomFilters) => {
        this.setState({customFilters: filters?.[this.getContentType().fullClassName]},
            () => this.load(true))
    }

    render() {
        const {viewUnit, match, history, settings, permissions, standalone, insideTab} = this.props
        const {results, loading, count, page, pageLoaded} = this.state;
        const cards = this.getCards()
        const {
            carouselShowPageSwitch,
            carouselEffect,
            carouselShowPaging,
            carouselInfinitePaging
        } = this.getViewOptions()

        return (
            <div className={'position-relative'}>
                <ViewTitle hideTitle={insideTab || !standalone} viewUnit={viewUnit}>
                    <Row className={"noPrint"} gutter={[4, 6]}>
                        <ViewSchemeActions onFinish={this.doAction} contentType={this.getContentType()}
                                           permissions={permissions} viewUnit={viewUnit} match={match}
                                           history={history}/>
                        <Col
                            className={"d-flex align-content-center align-self-center align-items-center  text-nowrap"}>
                            <Row align={"middle"} gutter={[4, 6]}>
                                <Col>
                                    <Tooltip title={"Znovu načíst data"}>
                                        <Button
                                            onClick={() => this.load(true)}
                                            style={{verticalAlign: "middle"}}
                                            icon={<RefreshOutline/>}
                                            size={"small"}
                                        />
                                    </Tooltip>
                                </Col>
                                <ViewPersonalEditButton {...this.props} />
                                <ViewEditButton {...this.props}/>
                                <Col>
                                    {carouselShowPageSwitch && (
                                        <Input.Group compact={true}>
                                            <Button icon={<LeftOutlined/>} onClick={this.carouselRef.current?.prev}
                                                    disabled={page === 1 && !carouselInfinitePaging} size={"small"}/>
                                            <Button icon={<RightOutlined/>}
                                                    onClick={this.carouselRef.current?.next}
                                                    disabled={count === page && !carouselInfinitePaging} size={"small"}/>
                                        </Input.Group>
                                    )}
                                </Col>
                                {Object.keys(results).length > 0 && <Col>
                                    <Typography className={"font-size-base font-weight-normal text-muted"}>
                                        {page} z {count}
                                    </Typography>
                                </Col>}
                            </Row>
                        </Col>
                    </Row>
                </ViewTitle>
                {settings.customFilters && (
                    <ViewCustomFilters filters={settings.customFilters} onChange={this.onCustomFilterChange}/>
                )}
                <div className={'mt-3'}>
                    <Carousel className={'dark-dots'} ref={this.carouselRef}
                              beforeChange={(_, next) => this.changePage(next)}
                              effect={carouselEffect} dots={carouselShowPaging}>
                        {Object.keys(results).length === 0 && (loading ? (
                            <Spin className={'w-100'}/>
                        ) : (<Empty description={'Žádná data'}/>))}
                        {Array.from(Array(Math.min(count, 100)).keys()).map(key => (
                            <div key={key}>
                                {cards.length && (
                                    <Spin spinning={loading}>
                                        <div className={standalone ? '' : 'mb-3'}>
                                            <Card
                                                standAlone={standalone}
                                                card={cards[0]}
                                                cards={cards}
                                                resource={results[pageLoaded]}
                                                match={match}
                                                history={history}
                                                onActionFinish={this.onCardActionFinished}
                                            />
                                        </div>

                                    </Spin>
                                )}
                            </div>
                        ))}
                    </Carousel>
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state: RootStateOrAny) => {
    const {user} = state.setup as ISetupState
    return {
        findServiceByContentType: (contentType: IContentType) => selectors.services.findOneByContentType(state, contentType),
        findContentType: (uuid: string) => selectors.contentTypes.findOneBy(state, 'uuid', uuid),
        user
    }
}

export default connect(mapStateToProps)(ViewCarousel)