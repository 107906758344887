import React from "react"
import {Col, message, Popconfirm, Row} from "antd";
import IType, {COMPANY_WORKLOAD_TYPE_MODE_WORKING} from "../../../../../model/interface/company/workload/IType";
import IObligation from "../../../../../model/interface/company/IObligation";
import EntriesService from "../../../../../model/service/company/workload/EntriesService";
import IClaim from "../../../../../model/interface/company/workload/IClaim";
import TypesService from "../../../../../model/service/company/workload/TypesService";
import {humanShiftHoursInDays} from "../../../file-manager/utils/TimeDuration";
import {IEntryState} from "../../../../../model/interface/company/workload/IEntry";
import ShiftsService from "../../../../../model/service/company/workload/ShiftsService";
import Button from "../../../../shared/button/Button";
import {DeleteOutlined, FilePdfOutlined, FileSyncOutlined} from "@ant-design/icons";
import IReport from "../../../../../model/interface/company/workload/IReport";
import ReportsService from "../../../../../model/service/company/workload/ReportsService";
import moment from "moment";
import IFreeDay from "../../../../../model/interface/company/workload/IFreeDay";
import {ITimeSheetRow} from "../Month";
import IEmployee from "../../../../../model/interface/company/IEmployee";
import LocaleText from "../../../settings/dictionary/LocaleText";

interface IProps {
    data: ITimeSheetRow[]
    obligation: IObligation,
    freeDays: IFreeDay[]
    types: IType[]
    claims: IClaim[]
    report: IReport
    onChange: (report: IReport) => void
}

interface IState {
    filling?: boolean
    downloading?: boolean
    deleting?: boolean
}

class Summary extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {}
    }

    fillWithDefault() {
        const {report, onChange} = this.props
        this.setState({filling: true})
        return ReportsService.resourceUpdate(report.uuid, {}, "fill_default")
            .then(resource => {
                message.success('Úspěšně doplněno').then()
                this.setState({filling: false}, () => onChange(resource))
            })
    }

    deleteNew() {
        const {report, onChange} = this.props
        this.setState({deleting: true})
        return ReportsService.resourceUpdate(report.uuid, {}, "delete_all_new")
            .then(resource => {
                message.success('Úspěšně doplněno').then()
                this.setState({deleting: false}, () => onChange(resource))
            })
    }


    download(report: IReport) {
        this.setState({downloading: true})
        return ReportsService.download(report?.id).finally(() => this.setState({downloading: false}))
    }

    render() {
        const {filling, downloading, deleting} = this.state
        const {obligation, data, types, claims, report, freeDays} = this.props
        const workFond = report.workFond
        const workFondTillToday = Summary.getWorkFondTillToday(data, obligation, freeDays, report.employee)
        let workTotal = Summary.getWorkTotal(data)
        const allTillToday = Summary.getTillTodayTotal(data)
        workTotal = Math.abs(workTotal - Math.round(workTotal)) > 0.001 ? workTotal : Math.round(workTotal)
        return (
            <div>
                <Row justify={"space-between"}>
                    {"Fond tento měsíc:"}
                    <div>
                        {Summary.hoursToString(workFond)}
                        {this.showDaysInfo(obligation, workFond)}
                    </div>
                </Row>
                <Row justify={"space-between"}>
                    {"Pracovní fond k dnešnímu dni:"}
                    <div>
                        {Summary.hoursToString(workFondTillToday)}
                        {this.showDaysInfo(obligation, workFondTillToday)}
                    </div>
                </Row>
                <Row justify={"space-between"}>
                    {"Odpracovaná doba:"}
                    <div>
                        {Summary.hoursToString(workTotal)}
                        {this.showDaysInfo(obligation, workTotal)}
                    </div>
                </Row>

                {this.getPlanSummary(types, claims, data, obligation)}

                <Row justify={"space-between"}>
                    {"K dnešnímu dni chybí:"}
                    <div>
                        {Summary.hoursToString(Math.max(workFondTillToday - allTillToday, 0))}
                        {this.showDaysInfo(obligation, Math.max(workFondTillToday - allTillToday, 0))}
                    </div>
                </Row>
                <Row justify={'start'} gutter={[6, 6]} className={'mt-3'}>
                    <Col>
                        <Button icon={<FileSyncOutlined/>} type={'special'}
                                disabled={!report || !report._permissions?.['edit']}
                                onClick={() => this.fillWithDefault()} loading={filling}>
                            Doplnit dle úvazku
                        </Button>
                    </Col>
                    <Col>
                        <Button icon={<FilePdfOutlined className={'text-danger'}/>} loading={downloading}
                                disabled={!report}
                                onClick={() => report && this.download(report)}>
                            Stáhnout výkaz
                        </Button>
                    </Col>
                    {['new', 'returned'].includes(report.state) && (
                        <Col>
                            <Popconfirm title={'Opravdu chcete smazat všechny záznamy?'}
                                        onConfirm={() => report && this.deleteNew()}
                                        okText={<LocaleText code={'general.yes'} fallback={'Ano'}/>}
                                        cancelText={<LocaleText code={'general.no'} fallback={'Ne'}/>}>
                                <Button danger={true} icon={<DeleteOutlined/>}
                                        loading={deleting}
                                        disabled={!report}>
                                    <span>
                                        <LocaleText code={'company.workload.report.month.delete-all'} fallback={'Smazat záznamy'}/>
                                    </span>
                                </Button>
                            </Popconfirm>
                        </Col>
                    )}
                </Row>
            </div>
        )
    }

    showDaysInfo(obligation: IObligation, workFond: number) {
        return obligation.dayWorkHours ? (
            <div className={'text-muted pl-2 d-inline'}>
                {workFond ? humanShiftHoursInDays(workFond, obligation.dayWorkHours) : ''}
            </div>
        ) : '';
    }

    getPlanSummary(types: IType[], claims: IClaim[], data: ITimeSheetRow[], obligation: IObligation) {
        return TypesService.listPlanType(types)
            .map((type: IType) => {
                const index = claims.findIndex(c => c.type.uuid === type.uuid)
                const claim = index < 0 ? null : claims[index]
                const approved = claim ? Math.round(10 * claim.amountApproved) / 10 : 0;
                const available = claim ? Math.round(10 * claim.amountTotal) / 10 : 0
                const total = Summary.getTypeTotal(type, data, 'approved')

                return claim ? (
                    <div>
                        <Row justify={"space-between"}>
                            {claim.type.title + ':'}
                            <div>
                                {Summary.hoursToString(total)}
                                {this.showDaysInfo(obligation, total)}
                            </div>
                        </Row>
                        <Row justify={"space-between"} className={'text-muted'}>
                            {'Zůstatky'}
                            <div>
                                {obligation.dayWorkHours ? humanShiftHoursInDays(available - approved, obligation.dayWorkHours) : Summary.hoursToString(available - approved)}
                                {' z '}
                                {obligation.dayWorkHours ? humanShiftHoursInDays(available, obligation.dayWorkHours) : Summary.hoursToString(available)}
                            </div>
                        </Row>
                    </div>
                ) : ''
            });
    }

    static getWorkTotal(data: ITimeSheetRow[]) {
        return data.reduce((daySum, a) => daySum + Summary.getWorkDayTotal(a), 0)
    }

    static getWorkDayTotal(a: ITimeSheetRow) {
        return a.entries.filter(e => e.type.mode === COMPANY_WORKLOAD_TYPE_MODE_WORKING)
            .reduce((entrySum, e) => entrySum + EntriesService.getInstance().countHours(e), 0)
    }

    static getDayTotal(a: ITimeSheetRow) {
        return a.entries.reduce((entrySum, e) => entrySum + EntriesService.getInstance().countHours(e), 0)
    }

    static getWorkTillToday(data: ITimeSheetRow[]) {
        return data
            .reduce((entrySum, e) => entrySum + (moment().isSameOrAfter(e.date) ? Summary.getWorkDayTotal(e) : 0), 0)
    }

    static getTillTodayTotal(data: ITimeSheetRow[]) {
        return data.reduce((entrySum, e) => entrySum + (moment().isSameOrAfter(e.date) ? Summary.getDayTotal(e) : 0), 0)
    }

    static getTotal(data: ITimeSheetRow[]) {
        return data.reduce((entrySum, e) => entrySum + Summary.getDayTotal(e), 0)
    }

    static getWorkFondTillToday(data: ITimeSheetRow[], obligation: IObligation, freeDays: IFreeDay[], employee?: IEmployee) {
        return data.reduce((daySum, a) => daySum + (a.date.isSameOrBefore(moment(), 'date') ?
            Summary.getWorkDayFond(obligation, a, freeDays, employee) : 0), 0)
    }

    static getWorkDayFond(obligation: IObligation, a: ITimeSheetRow, freeDays: IFreeDay[], employee?: IEmployee) {
        return obligation?.shifts.filter(s => ShiftsService.isShiftActive(s, a.date, employee, freeDays))
            .reduce((shiftSum, s) => s.totalHours + shiftSum, 0)
    }

    static getTypeTotal(type: IType, data: ITimeSheetRow[], state?: IEntryState) {
        return data.reduce((daySum, a) => daySum + Summary.getTypeDayTotal(type, a, state), 0)
    }

    static getTypeDayTotal(type: IType, a: ITimeSheetRow, state?: IEntryState) {
        return a.entries.filter(e => e.type.uuid === type.uuid && (!state || state === e.state))
            .reduce((entrySum, e) => entrySum + EntriesService.getInstance().countHours(e), 0);
    }

    getOvertime(data: ITimeSheetRow[], obligation: IObligation, freeDays: IFreeDay[]) {
        return data.reduce((daySum, a) =>
            daySum + Math.max(Summary.getWorkDayTotal(a) - Summary.getWorkDayFond(obligation, a, freeDays), 0), 0)
    }

    static hoursToString(number: number) {
        const hours = Math.floor(number)
        const minutes = '' + Math.round((number - hours) * 60)
        return hours.toString().padStart(2, "0") + ':' + minutes.padStart(2, "0")
    }
}

export default Summary
