import { combineReducers } from 'redux';
import Auth from './Auth';
import Theme from './Theme';
import Setup from './Setup';
import Ui from './Ui';
import Notifications from './Notifications';

const reducers = combineReducers({
    theme: Theme,
    auth: Auth,
    setup: Setup,
    ui: Ui,
    notifications: Notifications
});

export default reducers;