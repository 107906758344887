import React from "react";
import {IActionResult} from "../../../model/service/dataStorage/ActionsService";
import IContentType from "../../../model/interface/dataStorage/IContentType";
import IBaseProps from "../../../model/interface/IBaseProps";
import ViewUnitsService from "../../../model/service/dataStorage/ViewUnitsService";
import IViewUnit from "../../../model/interface/dataStorage/IViewUnit";
import IViewPermissions from "../../../model/interface/dataStorage/view/IViewPermissions";
import {Col, Dropdown, Menu, Row} from "antd";
import {LoadingOutlined, ThunderboltOutlined} from "@ant-design/icons";
import ViewAction from "./ViewAction";
import IViewAction from "../../../model/interface/dataStorage/view/IViewAction";
import {ActionButtonExecuteType} from "../action/ActionButton";
import IconBuilder from "../../../utils/IconBuilder";
import Button from "../../shared/button/Button";

interface IState {
    loading?: string
}

interface IProps extends IBaseProps {
    onFinish: (result?: IActionResult) => Promise<void>,
    onClick?: (executeAction: ActionButtonExecuteType, viewAction: IViewAction) => Promise<void>
    contentType: IContentType
    permissions: IViewPermissions
    viewUnit: IViewUnit
}

class ViewSchemeActions extends React.Component<IProps, IState> {


    constructor(props: IProps, context: any) {
        super(props, context);
        this.state = {}
    }

    onClick = (action: IViewAction, onClick: () => Promise<void>) => {
        this.setState({loading: action.uuid})
        onClick().then(() => this.setState({loading: undefined}))
    }

    render() {
        const {loading} = this.state
        const {viewUnit, permissions, contentType, onClick, onFinish, history, match} = this.props
        const allowedSchemeActions = ViewUnitsService.getAllowedSchemeActions(viewUnit, permissions, contentType)

        return allowedSchemeActions.length > 0 && (
            <Col>
                {allowedSchemeActions.length > 2 ? (
                    <Dropdown trigger={["click"]} {...(loading ? {visible: true} : undefined)} overlay={<Menu>{allowedSchemeActions.map(action => {
                        return (
                            <Menu.Item key={action.uuid} disabled={loading === action.uuid}>
                                <ViewAction
                                    className={'d-inline-block'}
                                    history={history}
                                    match={match}
                                    onClick={onClick && (executeAction => onClick(executeAction, action))}
                                    onFinish={onFinish}
                                    action={action}
                                    customRender={(onClick) => {
                                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                        return <a onClick={() => this.onClick(action, onClick)}>
                                            <div className={'d-inline-block mr-2'}>
                                                {loading === action.uuid ?
                                                    <LoadingOutlined/> : IconBuilder(action.icon)}
                                            </div>
                                            {action.label}
                                        </a>
                                    }}
                                />
                            </Menu.Item>
                        )
                    })}</Menu>}>
                        <Button size={"small"} icon={<ThunderboltOutlined/>}>Akce</Button>
                    </Dropdown>
                ) : (
                    <Row gutter={[6, 6]}>
                        {allowedSchemeActions.map(action => {
                            return (
                                <Col key={action.uuid}>
                                    <ViewAction
                                        history={history}
                                        match={match}
                                        onClick={onClick && (executeAction => onClick(executeAction, action))}
                                        onFinish={onFinish}
                                        action={action}
                                    />
                                </Col>
                            )
                        })}
                    </Row>
                )}
            </Col>
        )
    }
}

export default ViewSchemeActions