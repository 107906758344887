import _ from 'underscore'
import {RootStateOrAny} from "react-redux";
import IView from "model/interface/dataStorage/IView";

export const findAll = (store: RootStateOrAny) => store.setup.views

export const findBy = (store: RootStateOrAny, property: string, value: any): IView[] => _.where(findAll(store), {[property]: value})

export const findOneBy = (store: RootStateOrAny, property: string, value: any): IView => _.findWhere(findAll(store), {[property]: value})

export const findOneById = (store: RootStateOrAny, id: number): IView => findOneBy(store, 'id', id)

export default {
    findAll,
    findBy,
    findOneBy,
    findOneById
}