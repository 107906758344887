import React from "react";
import {Button, Col, Comment, Row, Spin, Tooltip} from "antd";
import {API_FILTER_TYPE} from "../../../model/constants/ApiConstant";
import CommentsService from "../../../model/service/comment/CommentsService";
import IComment from "../../../model/interface/comment/IComment";
import CommentForm from "./CommentForm";
import IUser from "../../../model/interface/security/IUser";
import {
    DeleteOutlined,
    DownOutlined,
    EditOutlined,
    EyeInvisibleOutlined,
    EyeOutlined,
    MessageOutlined
} from "@ant-design/icons";
import {connect, RootStateOrAny} from "react-redux";
import {ISetupState} from "../../../redux/reducers/Setup";
import {IWYSIWYGEditorOptions} from "../configuration/form/FormElement/formField/editor/FormFieldWYSIWYGEditor";
import IRestServiceOptions from "../../../model/interface/api/IRestServiceOptions";
import FileList from "../../shared/pickers/file/filePicker/FileList";
import UserAvatar from "../security/UserAvatar";
import IThread from "../../../model/interface/comment/IThread";
import {MomentBuilder} from "../../../utils/MomentBuilder";

interface IProps extends IWYSIWYGEditorOptions {
    replyAble?: boolean,
    wysiwyg?: boolean,
    attachments?: boolean,
    editable?: boolean
    thread?: number | string | IThread,
    parent?: IComment
    onThreadCreated?: (id: number | string) => void,
    showForm?: boolean,
    user: IUser
    onChildrenChange?: (child: IComment) => void
    onRootsTotalChange?: (total: number) => void
    limit?: number,
    readonly?: boolean
    deletable?: boolean
    forceReload?: number
    splitByBorder?: boolean
    renderCommentText?: (text: string) => string
}

interface IState {
    loading: boolean,
    showReplies: number[]
    showForms: (number | undefined)[]
    comments: IComment[],
    thread?: number | string,
    editing: number | null,
    deleting: number | null,
    page: number,
    limit: number,
    total: number
}

class CommentList extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            loading: false,
            comments: [],
            showReplies: [],
            showForms: [],
            thread: typeof props.thread === "object" ? props.thread?.id : props.thread,
            editing: null,
            deleting: null,
            page: 1,
            limit: props.limit ? props.limit : 10,
            total: 0
        }
    }

    _isMounted = false;

    static defaultProps = {
        deletable: true,
    }

    componentDidMount() {
        this._isMounted = true;
        const {parent} = this.props
        this.load(parent)
    }

    componentDidUpdate(prevProps: Readonly<IProps>) {
        if (prevProps.thread !== this.props.thread && this.state.thread !== this.props.thread) {
            this.setState({
                thread: typeof this.props.thread === "object" ? this.props.thread?.id : this.props.thread,
                comments: []
            }, this.load)
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    load = (parent?: IComment) => {
        const {thread} = this.state
        if (parent) {
            parent.children?.length && this.loadChildren(parent.id)
        } else if (thread) {
            this.loadRoots(thread);
        }
    }

    setTotal = (total: number) => {
        const {onRootsTotalChange} = this.props
        this.setState({total}, () => onRootsTotalChange?.(this.state.total))
    }

    onSave = (thread: number | string, newComment: IComment, newThread?: boolean) => {
        const {total} = this.state
        const {onThreadCreated, onChildrenChange} = this.props

        if (newThread) {
            this.setState({thread}, () => {
                onThreadCreated?.(thread)
                onChildrenChange?.(newComment)
                this.load()
            })
        } else {
            let comments = [...this.state.comments]
            const index = comments.findIndex(comment => newComment.id === comment.id)
            if (index > -1) {
                comments[index] = newComment
                this.setState({editing: null})
            } else {
                comments.unshift(newComment)
                this.setTotal(total + 1)
            }
            this.setState({comments}, () => onChildrenChange?.(newComment))
        }
    }

    onChildrenChange = (child: IComment) => {
        let comments = [...this.state.comments]
        const index = comments.findIndex(comment => comment.id === child.parent?.id)
        comments[index].children = comments[index].children.findIndex(comment => comment.id === child.id) > -1 ?
            comments[index].children.filter(comment => comment.id !== child.id) : [...comments[index].children, child]
        this.setState({comments})
    }

    remove(comment: IComment) {
        this.setState({deleting: comment.id})
        CommentsService.resourceDelete(comment.id).then(() => {
            this.setState(state => ({
                comments: state.comments.filter(stateComment => stateComment.id !== comment.id),
                deleting: null
            }), () => {
                this.props.onChildrenChange?.(comment)
                this.setTotal(this.state.total - 1)
            })
        })
    }

    loadChildren(id: number) {
        this.setState({loading: true})
        CommentsService.collectionList({
            ...this.getParams(),
            filters: {
                'parent': {
                    field: 'parent',
                    type: API_FILTER_TYPE.EQUAL,
                    value: id.toString()
                }
            },
            depth: 6
        }).then(({results, count}) => {
            this.setComments(results, count);
        })
    }

    loadRoots(thread: number | string) {
        this.setState({loading: true})
        CommentsService.collectionList({
            filters: {
                'thread': {
                    field: 'thread',
                    type: API_FILTER_TYPE.EQUAL,
                    value: thread.toString()
                },
                'parent': {
                    field: 'parent',
                    type: API_FILTER_TYPE.IS_NULL
                }
            },
            depth: 6,
            ...this.getParams()
        }).then(({results, count}) => {
            this.setComments(results, count)
        })
    }

    setComments(results: Array<IComment>, total: number) {
        const {comments, limit, page} = this.state
        if (comments.length < limit * page) {
            this._isMounted && this.setState({comments: [...comments, ...results], loading: false}, () => this.setTotal(total))
        } else {
            this._isMounted && this.setState({comments: results, loading: false}, () => this.setTotal(total))
        }
    }

    loadMore = () => {
        const {parent} = this.props
        this.setState(state => ({page: state.page + 1}), () => this.load(parent))
    }

    getParams(): IRestServiceOptions {
        return {
            order: {0: {field: 'createdAt', direction: 'DESC'}},
            limit: this.state.limit,
            page: this.state.page
        } as IRestServiceOptions
    }


    showReplies(id: number, show: boolean = true) {
        (!this.state.showReplies.includes(id) || !show) && this.setState(state => {
            return {showReplies: show ? [...state.showReplies, id] : state.showReplies.filter(key => key !== id)}
        })
    }

    showForms(id: number, show: boolean = true) {
        (!this.state.showForms.includes(id) || !show) && this.setState(state => {
            show && this.showReplies(id)
            return {showForms: show ? [...state.showForms, id] : state.showForms.filter(key => key !== id)}
        })
    }

    setEditing(id: number) {
        this.setState({editing: id})
    }

    getActions(comment: IComment) {
        const {replyAble, editable, user, readonly, deletable} = this.props
        const {showReplies, editing, showForms, deleting} = this.state
        return readonly ? [] : [
            ...(replyAble ? [(
                <Button type={"link"} icon={<MessageOutlined/>} size={"small"}
                        onClick={() => this.showForms(comment.id, !showForms.includes(comment.id))}>
                    Odpovědět
                </Button>)] : []),
            ...(replyAble && comment.children && comment.children.length > 0 ? [(
                <Button icon={showReplies.includes(comment.id) ? <EyeInvisibleOutlined/> : <EyeOutlined/>}
                        type={"link"} size={"small"} onClick={() =>
                    this.showReplies(comment.id, !showReplies.includes(comment.id))}>
                    {showReplies.includes(comment.id) ?
                        `Skrýt odpovědi (${comment.children.length})` :
                        `Odpovědi (${comment.children.length})`}
                </Button>
            )] : []),
        ...(editable && (comment.user.id === user.id) ? [(
                <Tooltip title={'upravit komentář'}>
                    <Button icon={<EditOutlined/>} disabled={editing === comment.id} type={"link"} size={"small"}
                            onClick={() => this.setEditing(comment.id)}>
                    </Button>
                </Tooltip>
            )] : []),
        ...((comment.user.id === user.id && deletable) ? [(
                <Tooltip title={'smazat komentář'}>
                    <Button danger loading={deleting === comment.id} icon={<DeleteOutlined/>} type={"link"}
                            size={"small"} onClick={() => this.remove(comment)}>
                    </Button>
                </Tooltip>
            )]: []),
        ];
    }

    renderDatetime(comment: IComment) {
        return (
            <Tooltip title={MomentBuilder.build(comment.createdAt).format('YYYY-MM-DD HH:mm:ss')}>
                <span>{MomentBuilder.build(comment.createdAt).fromNow()}</span>
            </Tooltip>
        )
    }

    getAuthorName(user: IUser) {
        return user?.employees[0]?.fullName || user.username; //TODO Presenter ???
    }

    renderContent(comment: IComment) {
        const {editing} = this.state
        const {editable, renderCommentText} = this.props

        return (
            <div>
                {editable && editing === comment.id ? (
                    <CommentForm comment={comment} {...this.getCommentFromProps()}/>
                ) : (
                    <Row className={'overflow-hidden'}>
                        <p dangerouslySetInnerHTML={{__html: renderCommentText?.(comment.text) || comment.text}}/> {/*TODO ESCAPE SAFELY*/}
                        <Col span={24}>
                            <FileList canDownload={true} fileList={comment.attachments}/>
                        </Col>
                    </Row>
                )}
            </div>
        )
    }

    getCommentFromProps() {
        const {wysiwyg, attachments, user, wysiwygPackage} = this.props
        const {thread} = this.state
        return {wysiwyg, isAttachments: attachments, thread, onSave: this.onSave, user, wysiwygPackage}
    }

    render() {
        const {loading, comments, showReplies, thread, showForms, total, limit, page} = this.state
        const {parent, showForm, user, readonly, splitByBorder} = this.props

        return (
            <div>
                {(parent ? showForm !== false && !readonly && (
                    <CommentForm {...this.getCommentFromProps()} parent={parent.id}/>) :
                    showForm !== false && !readonly && (<CommentForm {...this.getCommentFromProps()}/>))}
                {comments.length > 0 && comments.map((comment, i) =>
                    <Comment key={comment.id}
                             className={splitByBorder && i > 0 ? 'border-top' : ''}
                             content={this.renderContent(comment)}
                             avatar={<UserAvatar user={comment.user} size={42}/>}
                             author={this.getAuthorName(comment.user)}
                             datetime={this.renderDatetime(comment)}
                             actions={this.getActions(comment)}
                    >
                        {showReplies.includes(comment.id) && (
                            <CommentList
                                {...this.props}
                                user={user}
                                parent={comment}
                                thread={thread}
                                showForm={showForms.includes(comment.id)}
                                onChildrenChange={this.onChildrenChange}
                            />
                        )}
                    </Comment>
                )}
                <Row hidden={!loading} style={{zIndex: 10}} justify={"center"} align={"middle"}>
                    <Spin/>
                </Row>
                {total > limit * page && total > comments.length && !loading && (
                    <Row justify={"center"}>
                        <Button type={"link"} icon={<DownOutlined/>} onClick={this.loadMore}>
                            Načíst další ({comments.length + '/' + total})
                        </Button>
                    </Row>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    const {user} = state.setup as ISetupState

    return {
        user
    }
}

export default connect(mapStateToProps)(CommentList)