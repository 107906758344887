import React from "react";
import {Col, Input, Row, Spin, Typography} from "antd";
import IReportWidgetProps from "../../../../../model/interface/report/IReportWidgetProps";
import {LeftOutlined, RightOutlined} from "@ant-design/icons";
import IRestServiceFilters from "../../../../../model/interface/api/IRestServiceFilters";
import ReportsService from "../../../../../model/service/company/workload/ReportsService";
import IReport from "../../../../../model/interface/company/workload/IReport";
import IReportWidgetWorkloadReport from "../../../../../model/interface/report/IReportWidgetWorkloadReport";
import Button from "../../../../shared/button/Button";
import Actions from "../../../company/workload/report/Actions";
import {IAppState} from "../../../../../redux/store";
import {connect} from "react-redux";
import IUser from "../../../../../model/interface/security/IUser";
import EmployeeAvatar from "../../../company/employees/partials/EmployeeAvatar";
import {MomentBuilder} from "../../../../../utils/MomentBuilder";
import Summary from "../../../company/workload/month/Summary";
import {Link} from "react-router-dom";

interface IProps extends IReportWidgetProps<IReportWidgetWorkloadReport> {
    user: IUser
}

interface IState {
    report?: IReport,
    loadingData: boolean,
    page: number
    total: number
}

class WorkloadReportWidget extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props)
        this.state = {
            loadingData: false,
            page: 1,
            total: 0
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>) {
        if (prevProps.reload !== this.props.reload) {
            this.loadData().then()
        }
    }

    componentDidMount() {
        this.loadData().then()
    }

    loadData = () => {
        this.setState({loadingData: true})
        const {options, functions, id} = this.props
        const {page} = this.state
        const optionFilters: IRestServiceFilters = {}

        if (options.workloadReportHideOnEmpty) {
            functions.onChildHidden?.(id, true)
        }

        optionFilters.state = {
            field: 'state',
            type: 'equal',
            value: 'waiting'
        }

        return ReportsService.collectionList({
            filters: {...optionFilters},
            page,
            limit: 1,
            order: {date: {field: 'month', direction: 'ASC'}}
        })
            .then(response => {
                this.setState({
                    total: response.count,
                    report: response.results[0],
                    loadingData: false
                }, () => {
                    const {total} = this.state
                    if (options.workloadReportHideOnEmpty) {
                        functions.onChildHidden?.(id, !total)
                    }
                })
            })
    }

    switch = (next: boolean = true) => {
        let nextPage = this.state.page + (next ? 1 : -1)
        this.setState(state => ({page: nextPage > state.total ? 1 : Math.max(0, nextPage)}), this.loadData)
    }

    render() {
        const {loadingData, total, page, report} = this.state
        const {options, history, match} = this.props
        let {border} = options
        if(typeof border == 'undefined') {
            border = true
        }
        if (!total && options.workloadReportHideOnEmpty) {
            return ''
        }

        return <div className={border && 'rounded border overflow-hidden bg-theme'}>
            <Spin spinning={loadingData} wrapperClassName={'p-3'}>
                <Row align={"middle"} gutter={[4, 6]} className={'w-100 mb-2'} justify={'space-between'}>
                    <Col>
                        <Typography.Title level={4} className={'m-0'}>{'Docházka žádosti'}</Typography.Title>
                    </Col>
                    <Col>
                        <Row align={"middle"} wrap={false}>
                            <Typography.Text className={"text-muted text-nowrap mr-2"}>
                                {total === 0 ? 0 : page} z {total}
                            </Typography.Text>
                            <Input.Group compact={true}>
                                <Button icon={<LeftOutlined/>} onClick={() => this.switch(false)} disabled={page === 1}
                                        size={"small"}/>
                                <Button icon={<RightOutlined/>} onClick={() => this.switch()} disabled={page === total}
                                        size={"small"}/>
                            </Input.Group>
                        </Row>
                    </Col>
                </Row>
                {report && (
                    <Row gutter={[6, 6]}>
                        <Col>
                            <EmployeeAvatar size={72} employee={report.employee}/>
                        </Col>
                        <Col>
                            <Typography.Text strong={true}>{report.employee.fullName}</Typography.Text>
                            <div>
                                {MomentBuilder.build(report.month).startOf('month').format('D. M.')}
                                {' - '}
                                {MomentBuilder.build(report.month).endOf('month').format('D. M.')}
                                {MomentBuilder.build(report.month).format(' YYYY')}
                            </div>
                            <div>práce: {Summary.hoursToString(report.workHours)}</div>
                            <div>volno: {Summary.hoursToString(report.offHours)}</div>
                            <div>zbýva: {Summary.hoursToString(report.workFond - report.writtenHours)}</div>
                            <div className={'mt-2'}>
                                <Actions askChangeStateConfirmation={!!options.workloadReportApprovalConfirmation}
                                         canDelete={false} canReturn={false} report={report} match={match}
                                         history={history}
                                         onChange={this.loadData}/>
                            </div>
                        </Col>
                    </Row>
                )}
            </Spin>
            <Link className={'d-block bg-gray-lighter w-100 text-center border-top p-2 text-reset'}
                  to={'/app/company/workload/history'}>
                Zobrazit žádosti</Link>
        </div>
    }
}

const mapStateToProps = (state: IAppState) => {
    return {
        user: state.setup.user
    }
}

export default connect(mapStateToProps)(WorkloadReportWidget)
