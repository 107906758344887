import {Button, Col, Row} from "antd";
import {Link} from "react-router-dom";
import {ArrowLeftOutlined} from "@ant-design/icons";
import React, {Component} from "react";
import View from "./View";

export class Error404 extends Component {
    render() {
        return (
            <Row align="middle">
                <Col xs={24} sm={24} md={8}>
                    <h1 className="font-weight-bold mb-4 display-4">Page not found</h1>
                    <p className="font-size-md mb-4">We've noticed you've lost your way. No worries, we'll help you find the correct path.</p>
                    <Link to='/app'>
                        <Button type="primary" icon={<ArrowLeftOutlined />}>Go back</Button>
                    </Link>
                </Col>
                <Col xs={24} sm={24} md={{ span: 14, offset: 2 }}>
                    <img className="img-fluid mt-md-0 mt-4" src="/img/others/img-20.png" alt="" />
                </Col>
            </Row>
        )
    }
}


export default Error404