import React, {FC, useEffect, useState} from "react";
import Cropper from "react-easy-crop";
import {Area, Point} from "react-easy-crop/types";
import {Zoom} from "./Zoom";
import {Col, Row, Tooltip} from "antd";
import Button from "../../button/Button";
import {RotateRightOutlined} from "@ant-design/icons";
import {debounce} from "underscore";

interface IProps {
    onChange?: (image?: Blob) => void
    src: string
    height?: number
    maxZoom?: number
    zoomSpeed?: number
    aspect?: number
    round?: boolean
    mime: string
    allowRotation?: boolean
    showGrid?: boolean
}

const ImageEditor: FC<IProps> = ({
                                     onChange,
                                     src,
                                     height = 500,
                                     maxZoom = 5,
                                     zoomSpeed = 0.2,
                                     aspect = 1,
                                     round,
                                     mime,
                                     allowRotation,
                                     showGrid = true
                                 }) => {
    const [crop, setCrop] = useState<Point>({x: 0, y: 0});
    const [zoom, setZoom] = useState(1);
    const [rotation, setRotation] = useState(0);

    const save = (_: Area, croppedAreaPixels: Area) => {
        getCroppedImg(croppedAreaPixels).then(image => {
            if (image) {
                onChange?.(image)
            }
        })
    }

    const onCropComplete = React.useMemo(
        () => debounce(save, 500),
        [src, rotation, zoom]
    )

    useEffect(() => {
        return () => {
            onCropComplete.cancel();
        }
    }, [])

    const createImage = () =>
        new Promise<HTMLImageElement | undefined>((resolve, reject) => {
            let image = new Image()
            if (src) {
                image.addEventListener('load', () => resolve(image))
                image.addEventListener('error', () => reject())
                image.setAttribute('crossOrigin', 'anonymous')
                image.src = src
            } else {
                resolve(undefined)
            }
        })


    const getRadianAngle = (degreeValue: number) => {
        return (degreeValue * Math.PI) / 180
    }

    const rotateSize = (width: number, height: number, rotation: number) => {
        const rotRad = getRadianAngle(rotation)

        return {
            width:
                Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
            height:
                Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
        }
    }

    const getCroppedImg = async (crop: Area, flip = {horizontal: false, vertical: false}) => {
        const image = await createImage()
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')

        if (!ctx || !image) {
            return undefined
        }

        const rotRad = getRadianAngle(rotation)

        // calculate bounding box of the rotated image
        const {width: bBoxWidth, height: bBoxHeight} = rotateSize(image.width, image.height, rotation)

        // set canvas size to match the bounding box
        canvas.width = bBoxWidth
        canvas.height = bBoxHeight

        ctx.translate(bBoxWidth / 2, bBoxHeight / 2)
        ctx.rotate(rotRad)
        ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
        ctx.translate(-image.width / 2, -image.height / 2)

        ctx.drawImage(image, 0, 0)

        const croppedCanvas = document.createElement('canvas')
        const croppedCtx = croppedCanvas.getContext('2d')

        if (!croppedCtx) {
            return undefined
        }

        croppedCanvas.width = crop.width
        croppedCanvas.height = crop.height

        croppedCtx.drawImage(
            canvas,
            crop.x,
            crop.y,
            crop.width,
            crop.height,
            0,
            0,
            crop.width,
            crop.height
        )

        return new Promise<Blob | undefined>(resolve => {
            croppedCanvas.toBlob(file => {
                resolve(file ? file : undefined)
            }, mime)
        })
    }

    return (
        <div style={{height}} className={'position-relative'}>
            <div className="crop-container">
                <Cropper
                    image={src}
                    crop={crop}
                    zoom={zoom}
                    zoomSpeed={zoomSpeed}
                    showGrid={showGrid}
                    maxZoom={maxZoom}
                    cropShape={round ? 'round' : 'rect'}
                    aspect={aspect}
                    rotation={rotation}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                    classes={{containerClassName: 'bg-white'}}
                    //style={{cropAreaStyle: {boxShadow: '0 0 0 9999em rgba(200, 200, 200, 0.6) '}}}
                />
            </div>
            <Row justify={'center'} className="position-absolute w-100" style={{bottom: 0}} gutter={[10, 10]}>
                <Col>
                    <Zoom zoom={zoom} max={maxZoom} onZoom={setZoom}/>
                </Col>
                {allowRotation && (
                    <Col>
                        <Tooltip title={'otočit'}>
                            <Button size={"small"} icon={<RotateRightOutlined/>}
                                    onClick={() => setRotation(rotation + 90)}/>
                        </Tooltip>
                    </Col>
                )}
            </Row>
        </div>
    );
};

export default ImageEditor;