import React, {RefObject} from "react";
import {Button, Form, FormInstance, Input, Modal} from "antd";
import Text from "antd/es/typography/Text";
import {StarFilled} from "@ant-design/icons";
import Title from "antd/es/typography/Title";
import IBookmark from "../../../model/interface/ui/IBookmark";
import IconPicker from "../../shared/IconPicker";
import BookmarksService from "../../../model/service/ui/BookmarksService";
import TextColorPicker from "../../shared/textColorPicker/TextColorPicker";
import LocaleText from "../settings/dictionary/LocaleText";
import {IAppState} from "../../../redux/store";
import selectors from "../../../redux/selectors";
import {connect} from "react-redux";

interface IState {
    loading: boolean
}

interface IProps {
    bookmark: IBookmark
    onFinish: (bookmark?: IBookmark) => void
    getText: (code: string, fallBack?: string) => string
}

class BookmarkModal extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            loading: false
        }
    }

    formRef: RefObject<FormInstance> = React.createRef()

    finish = (close = false) => {
        const {onFinish, bookmark} = this.props
        !close ? this.formRef.current?.validateFields().then(values => {
            this.setState({loading: true})
            let promises: Promise<any>[] = []
            if (bookmark.id) {
                promises.push(BookmarksService.resourceUpdate(bookmark.id, {...bookmark, ...values}))
            } else {
                promises.push(BookmarksService.collectionCreate({...bookmark, ...values}))
            }
            Promise.all(promises).then((resource) => {
                this.setState({loading: false})
                onFinish(resource[0])
            })
        }) : onFinish()
    }

    render() {
        const {bookmark, getText} = this.props
        const {loading} = this.state

        return (
            <>
                <Modal className={'dir-ltr'}
                       title={
                           <Title level={4} className={'m-0'}>
                               <Text className={'pr-2 font-size-xl text-warning'}>
                                   <StarFilled/>
                               </Text>
                               {'Záložka ' + (bookmark.title || '')}
                           </Title>
                       }
                       width={600}
                       visible={true}
                       onCancel={() => this.finish(true)}
                       destroyOnClose={true}
                       footer={[
                           <Button loading={loading} onClick={() => this.finish()}>
                               <LocaleText code={'general.save'} fallback={"Uložit"}/>
                           </Button>
                       ]}
                >
                    <Form ref={this.formRef} initialValues={{...bookmark, icon: bookmark.icon || 'ant.BookOutlined'}}>
                        <Form.Item name={'url'} label={<LocaleText code={'bookmark.url'} fallback={"Odkaz"}/>} required={true}>
                            <Input/>
                        </Form.Item>
                        <Form.Item name={'title'} label={<LocaleText code={'bookmark.title'} fallback={'Titulek'}/>}>
                            <Input/>
                        </Form.Item>
                        <Form.Item name={'icon'} label={<LocaleText code={'bookmark.icon'} fallback={'Ikona'}/>} required={true}>
                            <IconPicker library={'ant'}/>
                        </Form.Item>
                        <Form.Item name={'tooltip'} label={<LocaleText code={'bookmark.hint'} fallback={'Nápověda'}/>}>
                            <Input/>
                        </Form.Item>
                        <Form.Item label={<LocaleText code={'bookmark.style'} fallback={"Styl"}/>} name={"style"}>
                            <TextColorPicker placeholder={getText('bookmark.style.help', 'Zvolte barvu icony')}/>
                        </Form.Item>
                    </Form>
                </Modal>
            </>
        )
    }
}

const mapStateToProps = (state: IAppState) => {
    return {
        getText: (code: string, fallback?: string) => selectors.dictionary.getMessage(state, code, fallback)
    }
}

export default connect(mapStateToProps,)(BookmarkModal)