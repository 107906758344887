import React from "react";
import {Tabs} from "antd";
import IWidgetProps from "../../../../model/interface/widget/IWidgetProps";
import IWidget from "../../../../model/interface/widget/IWidget";
import Widget from "./Widget";
import IWidgetPropsFunctions from "../../../../model/interface/widget/IWidgetPropsFunctions";
import ITabsOptions from "../../../../model/interface/widget/option/ITabsOptions";

interface IState {
    activeTab?: string
    closed: boolean
}

class TabsWidget extends React.Component<IWidgetProps<IWidgetPropsFunctions<IWidget<ITabsOptions>>, ITabsOptions>, IState>  {
    state = {
        activeTab: undefined,
        closed: this.props.options.closed || false
    }

    componentDidMount() {
        const activeTab = window.localStorage.getItem('tabs/' + this.props.id)
        if(activeTab) {
            this.setState(_state => ({activeTab}))
        }
    }

    onChange(activeTab: string) {
        try {
            window.localStorage.setItem('tabs/' + this.props.id, activeTab)
        } catch(e) {
            console.error('Unable to write to local storage', e)
        }
        this.setState({activeTab, closed: false})
    }

    render() {
        const { id, functions, options } = this.props
        const {activeTab, closed} = this.state
        const tabPosition = ((options && options.tabPosition) || 'top')
        const children = functions.getSortedChildren(id)

        return (
            <>
                {children.length > 0 && (
                    <Tabs tabPosition={tabPosition} activeKey={closed ? 'dummy_value' : (activeTab || children[0].id + "")} onChange={(key: string) => this.onChange(key)}>
                        {children.map((child) => {
                            return (
                                <Tabs.TabPane key={child.id + ""} tab={(
                                    <>
                                        {child.options ? (
                                            <>{child.options.label}</>
                                        ) : (
                                            <>
                                                Tab { child.id }
                                            </>
                                        )}
                                    </>
                                )}>
                                    {!closed && (
                                        <Widget
                                            {...this.props}
                                            {...child}
                                            id={child.id}
                                            key={child.id}
                                        />
                                    )}
                                </Tabs.TabPane>
                            )
                        })}
                    </Tabs>
                )}
            </>
        );
    }
}

export default TabsWidget