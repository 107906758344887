import {Form, Switch} from 'antd';
import React from "react";
import {IFieldOptionsEditorProps} from "../FieldOptionsEditor";
import PhoneNumberCountryCodePicker, {COUNTRY_CODES} from "../../../../../shared/pickers/PhoneNumberCountryCodePicker";

export interface IFieldPhoneNumberOptions {
    phoneNumberLink?: boolean
    phoneNumberAllowedCountryCodes?: string[],
    phoneNumberDisabledCountryCode?: boolean,
    phoneNumberDefaultCountryCode?: string
}

interface IProps extends IFieldOptionsEditorProps {

}

class FieldPhoneNumberOptionsEditor extends React.Component<IProps> {

    render() {
        const {options, buildFieldName} = this.props

        return (
            <div>
                <Form.Item name={buildFieldName('phoneNumberLink')} label={'Zobrazit jako odkaz'} valuePropName={'checked'}>
                    <Switch/>
                </Form.Item>
                <Form.Item name={buildFieldName('phoneNumberDisabledCountryCode')} label={'Zakázat kód země'} valuePropName={'checked'}>
                    <Switch/>
                </Form.Item>
                {!options.phoneNumberDisabledCountryCode && (
                    <>
                        <Form.Item name={buildFieldName('phoneNumberAllowedCountryCodes')} label={'Povolit kódy zemí'}>
                            <PhoneNumberCountryCodePicker multiple={true} allowClear={true}/>
                        </Form.Item>
                        <Form.Item name={buildFieldName('phoneNumberDefaultCountryCode')} label={'Výchozí kód země'} initialValue={COUNTRY_CODES.CZ}>
                            <PhoneNumberCountryCodePicker allowClear={true}/>
                        </Form.Item>
                    </>
                )}
            </div>
        )
    }
}


export default FieldPhoneNumberOptionsEditor