import {Card, Form, Select, Switch} from 'antd';
import React from "react";
import {IFieldOptionsEditorProps} from "../FieldOptionsEditor";
import Wysiwyg from "../../../../../shared/input/Wysiwyg";

export interface IFieldWYSIWYGOptions {
    wysiwygPackage?: 'basic' | 'medium' | 'full',
    wysiwygInlineFile?: boolean
}

interface IProps extends IFieldOptionsEditorProps {

}

class FieldCompanyOptionEditor extends React.Component<IProps> {

    render() {
        const {buildFieldName} = this.props
        return (
            <Card title={'Wysiwyg'}>
                <Form.Item name={buildFieldName('wysiwygPackage')} label={'Zvolte wysiwyg balicek'}
                           rules={[{required: true}]} initialValue={'basic'}>
                    <Select>
                        {Wysiwyg.MODE_OPTIONS.map(type => (
                            <Select.Option key={type.code} value={type.code}>{type.label}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item name={buildFieldName('wysiwygInlineFile')} label={'Vkládání souboru'}
                           valuePropName={'checked'}>
                    <Switch/>
                </Form.Item>
            </Card>
        )
    }
}

export default FieldCompanyOptionEditor