import RestService from "model/service/dataStorage/RestService";
import IRestServiceOptions from "../../interface/api/IRestServiceOptions";
import IRestServiceCollectionResponse from "../../interface/api/IRestServiceCollectionResponse";
import IGroup from "../../interface/security/IGroup";
import IRestServiceChoiceListResponse from "../../interface/api/IRestServiceChoiceListResponse";
import IRestChoiceService from "../../interface/api/IRestChoiceService";

export interface IRestGroupsServiceCollectionResponse extends IRestServiceCollectionResponse {
  results: Array<IGroup>
}

interface IRestGroupsService extends IRestChoiceService{
  collectionList(options?: IRestServiceOptions): Promise<IRestGroupsServiceCollectionResponse>,
  collectionCreate(data: any): Promise<IGroup>,
  resourceRetrieve(id: number|string): Promise<IGroup>,
  resourceUpdate(id: number|string, data: any): Promise<IGroup>,
  resourceDelete(id: number|string): Promise<void>,
}

const GroupsService_COLLECTION = 'groups'
const GroupsService_CHOICES = 'groups-choices/'

const GroupsService: IRestGroupsService = {
  collectionList: function (options?) {
    return RestService.collectionList(GroupsService_COLLECTION, options as unknown as IRestServiceOptions) as Promise<IRestGroupsServiceCollectionResponse>
  },
  collectionCreate: function ( data) {
    return RestService.collectionCreate(GroupsService_COLLECTION, data) as Promise<IGroup>
  },
  resourceRetrieve: function (id) {
    return RestService.resourceRetrieve(GroupsService_COLLECTION, id) as Promise<IGroup>
  },
  resourceDelete: function (id) {
    return RestService.resourceDelete(GroupsService_COLLECTION, id)
  },
  resourceUpdate: function (id, data) {
    return RestService.resourceUpdate(GroupsService_COLLECTION, id, data) as Promise<IGroup>
  },
  choiceList(presenterName: string, options?: IRestServiceOptions): Promise<IRestServiceChoiceListResponse> {
    return RestService.collectionList(GroupsService_CHOICES + presenterName, options) as unknown as Promise<IRestServiceChoiceListResponse>
  }
}

export default GroupsService