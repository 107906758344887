import React from "react";
import {Button, Col, Empty, Row, Spin, Timeline, Tooltip, Typography} from "antd";
import IRestResource from "model/interface/api/IRestResource";
import IRepositoryService from "model/interface/IRepositoryService";
import {connect, RootStateOrAny} from "react-redux";
import selectors from "../../../redux/selectors";
import IContentType from "../../../model/interface/dataStorage/IContentType";
import IRestServiceOptions from "../../../model/interface/api/IRestServiceOptions";
import {DownloadOutlined, VerticalAlignTopOutlined} from "@ant-design/icons";
import {ISetupState} from "../../../redux/reducers/Setup";
import IUser from "../../../model/interface/security/IUser";
import Card from "../configuration/content-type/card/Card";
import {IActionResult} from "../../../model/service/dataStorage/ActionsService";
import IRestServiceFilters from "../../../model/interface/api/IRestServiceFilters";
import ViewCustomFilters, {DefaultCustomFilters} from "./ViewCustomFilters";
import {IBaseViewProps} from "./ViewUnit";
import FiltersService from "../../../model/service/dataStorage/FIltersService";
import IViewOptions from "../../../model/interface/dataStorage/view/IViewOptions";
import {ActionType} from "../../../model/interface/dataStorage/IAction";
import ViewEditButton from "./ViewEditButton";
import {RefreshOutline} from "react-ionicons";
import ViewTitle from "./ViewTitle";
import ViewSchemeActions from "./ViewSchemeActions";
import IScreen from "../../../model/interface/ui/IScreen";
import Utils from "../../../utils";

interface IState {
    results: Array<IRestResource>,
    loading: boolean,
    count: number,
    totalCount: number,
    page: number
    customFilters?: IRestServiceFilters
    screen: IScreen
}

interface IProps extends IBaseViewProps {
    findServiceByContentType: (contentType: IContentType) => IRepositoryService,
    findContentType: (uuid: string) => IContentType,
    user: IUser
}

class ViewTimeLine extends React.Component<IProps, IState> {

    constructor(props: Readonly<IProps> | IProps) {
        super(props);
        this.state = {
            results: [],
            loading: false,
            count: 0,
            totalCount: 0,
            page: 1,
            customFilters: ViewCustomFilters.getDefault(props.settings.customFilters)[this.getContentType().fullClassName],
            screen: Utils.getScreenInfo(),
        }
    }

    componentDidMount() {
        this.load(false).then()
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (prevProps.reload !== this.props.reload && this.props.reload) {
            this.load().then()
        }
    }

    load = (append = true, force = false): Promise<void> => {
        const {page, results, customFilters, totalCount} = this.state
        const {findServiceByContentType, filters} = this.props
        const contentType = this.getContentType()
        const service = findServiceByContentType(contentType)
        const {timelineField, timelineLength} = this.getViewOptions()
        if (totalCount && totalCount <= results.length && !force) {
            return Promise.resolve()
        }
        this.setState({
            loading: true
        })
        const orderField = this.getContentType().fields.find(field => field.uuid === timelineField)?.name
        let fixedFilters = FiltersService.makeFixed({...filters, ...customFilters})
        const options: IRestServiceOptions = {
            limit: timelineLength,
            page: force ? 1 : page,
            order: orderField ? {'main': {field: orderField, direction: "DESC"}, 'help': {field: 'id'}} : {},
            filters: {
                ...fixedFilters,
            },
            view: this.props.viewUnit.view,
            cache: !force
        }
        return service.collectionList(options).then(({results, count, totalCount}) => {
            return new Promise<void>(resolve => {
                this.setState(state => ({
                    results: append ? [...state.results, ...results] : results,
                    totalCount,
                    count,
                    loading: false
                }), () => resolve())
            })
        })
    }

    doAction = (result?: IActionResult) => {
        if (result) {
            return new Promise<void>(() => {
                if (this.props.onFinishAction) {
                    return this.props.onFinishAction(result)
                } else {
                    return this.load(false, true)
                }
            })
        }
        return Promise.resolve()
    }

    onCardActionFinished = (result?: IActionResult): Promise<void> => {
        const reloadTypes: ActionType[] = ['delete', 'import']
        return reloadTypes.includes(result?.action.type || '') ? this.load(false, true) : Promise.resolve()
    }

    getContentType() {
        return this.props.findContentType(this.props.viewUnit.contentTypes[0])
    }

    getViewOptions() {
        return this.props.viewUnit.options as IViewOptions
    }

    getCards() {
        return this.getContentType().cards.filter(card => this.getViewOptions().timelineCards?.includes(card.uuid) || this.getViewOptions().timelineCard === card.uuid)
    }

    incrementPage(reset: boolean = false) {
        this.setState(state => ({page: reset ? 1 : state.page + 1}), this.load)
    }

    onCustomFilterChange = (filters?: DefaultCustomFilters) => {
        this.setState({customFilters: filters?.[this.getContentType().fullClassName]},
            () => this.load(false, true))
    }

    render() {
        const {viewUnit, match, history, settings, permissions, standalone, insideTab} = this.props
        const {results, loading, totalCount, page, screen} = this.state;
        const cards = this.getCards()
        const {timelineLength} = this.getViewOptions()
        const isMobile = screen.isMobile
        return (
            <div className={'position-relative'}>
                <ViewTitle hideTitle={insideTab || !standalone} viewUnit={viewUnit}>
                    <Row className={"noPrint"} gutter={[4, 6]} align={"middle"}>
                        <ViewSchemeActions onFinish={this.doAction} contentType={this.getContentType()}
                                           permissions={permissions} viewUnit={viewUnit} match={match}
                                           history={history}/>
                        <Col>
                            <Tooltip title={"Znovu načíst data"}>
                                <Button
                                    onClick={() => this.load(false, true)}
                                    style={{verticalAlign: "middle"}}
                                    icon={<RefreshOutline/>}
                                    size={"small"}
                                />
                            </Tooltip>
                        </Col>
                        <ViewEditButton {...this.props}/>
                        <Col>
                            <Typography className={"font-size-base font-weight-normal text-muted mr-2"}>
                                načteno {Math.min(results.length, page * timelineLength)} záznamů z {totalCount}
                            </Typography>
                        </Col>
                    </Row>
                </ViewTitle>
                {settings.customFilters && (
                    <ViewCustomFilters filters={settings.customFilters} onChange={this.onCustomFilterChange}/>
                )}
                <div className={'mt-3'}>
                    <Spin spinning={loading && page === 1}>
                        {isMobile ? (
                            <div>
                                {results.slice(0, page * timelineLength).map(event => (
                                    <div className={'pb-2'} key={event.id}>
                                        {cards.length && (
                                            <Card card={cards[0]} cards={cards} resource={event} match={match}
                                                  history={history} onActionFinish={this.onCardActionFinished}/>
                                        )}
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <Timeline className={'pl-2'}
                                      pending={(totalCount > page * timelineLength || (loading)) && results.length > 0 && (
                                          <Button loading={page > 1 && loading} onClick={() => this.incrementPage()}
                                                  icon={<DownloadOutlined/>}>
                                              Načíst další
                                          </Button>)}>
                                {results.slice(0, page * timelineLength).map(event => (
                                    <Timeline.Item className={'pb-2'} key={event.id}>
                                        {cards.length && (
                                            <Card card={cards[0]} cards={cards} resource={event} match={match}
                                                  history={history} onActionFinish={this.onCardActionFinished}/>
                                        )}
                                    </Timeline.Item>
                                ))}
                            </Timeline>
                        )}
                        {page > 1 && (
                            <Button onClick={() => this.incrementPage(true)} icon={<VerticalAlignTopOutlined/>}>
                                Skrýt
                            </Button>
                        )}
                        {results.length === 0 && !loading && (
                            <Empty description={'Žádná data'}/>
                        )}
                    </Spin>
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state: RootStateOrAny) => {
    const {user} = state.setup as ISetupState
    return {
        findServiceByContentType: (contentType: IContentType) => selectors.services.findOneByContentType(state, contentType),
        findContentType: (uuid: string) => selectors.contentTypes.findOneBy(state, 'uuid', uuid),
        user
    }
}

export default connect(mapStateToProps)(ViewTimeLine)