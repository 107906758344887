import React from "react"
import {Form, InputNumber, Row, Select} from "antd";
import {getNestedFieldOptions} from "../unit/ViewUnitProperties";
import {BarChartOutlined, LineChartOutlined, PieChartOutlined} from "@ant-design/icons";
import Title from "antd/es/typography/Title";
import {FIELD_TYPE} from "../../../../../model/interface/dataStorage/IField";
import IViewGraphOptions from "../../../../../model/interface/dataStorage/view/graph/IViewGraphOptions";
import PresenterPicker from "../../../../shared/pickers/PresenterPicker";
import ViewGraphService from "../../../../../model/service/dataStorage/view/ViewGraphService";
import {API_AGGREGATION_TYPE_LIST} from "../../../../../model/constants/ApiConstant";
import FieldPicker from "../../../../shared/pickers/FieldPicker";
import IContentType from "../../../../../model/interface/dataStorage/IContentType";

interface IState {

}

interface IProps {
    contentType: IContentType,
    values: IViewGraphOptions
}

class ViewGraphProperties extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {}
    }

    buildColorSchemeOptions = () => {
        return [
            {value: ViewGraphService.COLOR_SCHEME_RED_GREEN, label: 'Červená / zelená'},
            {value: ViewGraphService.COLOR_SCHEME_SPECTRUM, label: 'Paleta'}
        ]
    }

    render() {
        const {values, contentType} = this.props
        const {graphXAxis, graphGroup, graphType} = values

        const groupField = graphGroup && contentType.fields.find(f => f.uuid === graphGroup)

        const types = [
            {
                value: ViewGraphService.TYPE_BAR,
                label: 'Sloupcový graf',
                icon: <BarChartOutlined style={{fontSize: '1.5em'}}/>
            },
            {
                value: ViewGraphService.TYPE_LINE,
                label: 'čárový graf',
                icon: <LineChartOutlined style={{fontSize: '1.5em'}}/>
            },
            {
                value: ViewGraphService.TYPE_PIE,
                label: 'Koláčový graf',
                icon: <PieChartOutlined style={{fontSize: '1.5em'}}/>
            },
            {
                value: ViewGraphService.TYPE_DONUT,
                label: 'Donat graf',
                icon: <PieChartOutlined style={{fontSize: '1.5em'}}/>
            }
        ]

        return (
            <>
                <Title level={3}>Vlastnosti grafu</Title>
                <Form.Item label={"Typ"} name={getNestedFieldOptions('graphType')}
                           rules={[{required: true, message: 'Vyplňte typ grafu'}]}>
                    <Select defaultActiveFirstOption>
                        {types.map(type => (
                            <Select.Option key={type.value} value={type.value}>
                                <Row justify={"space-between"} align={"middle"}>
                                    {type.label} {type.icon}
                                </Row>
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item label={"Barevné schema"} name={getNestedFieldOptions('colorScheme')}
                           rules={[{required: true, message: 'Tato položka je povinná'}]}>
                    <Select options={this.buildColorSchemeOptions()}/>
                </Form.Item>
                <Form.Item label={"Zdroj osy x"} name={getNestedFieldOptions('graphXAxis')}
                           rules={[{required: true, message: 'Vyberte zdroj osy x'}]}>
                    <Select>
                        <Select.Option value={'interval'}>
                            Interval / Čas
                        </Select.Option>
                        <Select.Option value={'field'}>
                            Vlastnost
                        </Select.Option>
                    </Select>
                </Form.Item>
                {graphXAxis && {
                    interval: (
                        <div>
                            <Form.Item
                                label={"Typ intervalu"}
                                name={getNestedFieldOptions('graphXAxisInterval')}
                                rules={[{required: true, message: 'Vyplňte typ intervalu'}]}
                            >
                                <Select defaultActiveFirstOption={true}>
                                    {ViewGraphService.buildIntervals().map(type => (
                                        <Select.Option key={type.value} value={type.value}>
                                            {type.label}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label={"Vlasnost intrvalu"}
                                name={getNestedFieldOptions('graphXAxisField')}
                                help={'Zde je povoleno pouze pole datum a čas'}
                                rules={[{required: true, message: 'Vyplňte vlastnost'}]}
                            >
                                <FieldPicker serviceClassName={contentType.fullClassName} output={'uuid'}
                                             allowTypes={[FIELD_TYPE.DATE_TIME]}/>
                            </Form.Item>
                            <Form.Item
                                label={"Delka intervalu"}
                                help={'Pokud ponecháte prázdné, použije se minimum a maximum dat. ' +
                                    'Mějte na paměti, že to může mít za následek nepřehlednost grafu'}
                                name={getNestedFieldOptions('graphXAxisIntervalLength')}
                            >
                                <InputNumber min={1}/>
                            </Form.Item>
                        </div>
                    ),
                    field: (
                        <Form.Item
                            label={"Vlasnost"}
                            name={getNestedFieldOptions('graphXAxisField')}
                            rules={[{required: true, message: 'Vyplňte vlastnost'}]}
                        >
                            <FieldPicker serviceClassName={contentType.fullClassName} output={'uuid'}/>
                        </Form.Item>
                    )
                }[graphXAxis]}
                {[ViewGraphService.TYPE_BAR, ViewGraphService.TYPE_LINE].includes(graphType) && (
                    <div>
                        <Form.Item
                            label={"Proměnná pro rozlišení řad"}
                            name={getNestedFieldOptions('graphGroup')}
                        >
                            <FieldPicker serviceClassName={contentType.fullClassName} output={'uuid'}/>
                        </Form.Item>
                        {groupField && groupField.targetEntity && (
                            <Form.Item
                                label={"Zobrazení"}
                                name={getNestedFieldOptions('graphGroupPresenter')}
                            >
                                <PresenterPicker serviceClassName={groupField.targetEntity}/>
                            </Form.Item>
                        )}
                    </div>
                )}
                {[ViewGraphService.TYPE_BAR, ViewGraphService.TYPE_LINE].includes(graphType) && (
                    <Form.Item
                        label={"Typ agregace"}
                        name={getNestedFieldOptions('graphAggregation')}
                        rules={[{required: true, message: 'Vyplňte typ agregace'}]}
                    >
                        <Select defaultActiveFirstOption={true}>
                            {
                                API_AGGREGATION_TYPE_LIST.map(type => (
                                    <Select.Option key={type.value} value={type.value}>
                                        {type.label}
                                    </Select.Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                )}
            </>
        )
    }
}

export default ViewGraphProperties