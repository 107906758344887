import React, {RefObject} from 'react'
import {Divider, Row} from 'antd';


interface IState {
    sideWidth: number
}

interface IProps {
    id: string,
    saveWidth?: boolean
    wrapperStyle?: React.CSSProperties
    wrapperClassName?: string
    sliderStyle?: React.CSSProperties
    sliderClassName?: string
    panelStyle?: React.CSSProperties
    panelClassName?: string
    contentStyle?: React.CSSProperties
    contentClassName?: string
    panel: JSX.Element | string
    defaultWidth?: number,
    position?: 'left' | 'right'
}

const SIDE_WIDTH = '_editor_side_panel_width'

export default class ResizableSidePanel extends React.Component<IProps, IState> {

    sidePanel: RefObject<HTMLDivElement> = React.createRef()

    constructor(props: IProps) {
        super(props)
        this.state = {
            sideWidth: (props.saveWidth && Number(localStorage.getItem(SIDE_WIDTH + props.id)))
                || (props.defaultWidth || 30)
        }
    }

    static defaultProps = {
        saveWidth: true,
        position: 'left'
    }

    componentWillUnmount() {
        this.stopSidePanelResizing()
    }

    resize = (ev: MouseEvent) => {
        const {position} = this.props
        const parentWidth = this.sidePanel.current?.parentElement?.clientWidth || 0
        const sliderWidth = this.sidePanel.current?.nextElementSibling?.clientWidth || 0
        let width = ev.clientX - this.sidePanel.current!.getBoundingClientRect().left
        if (position === 'right') {
            width = this.sidePanel.current!.getBoundingClientRect().right - ev.clientX
        }
        this.setState({sideWidth: Math.min((width / parentWidth) * 100, 100 - ((sliderWidth / parentWidth) * 100))})
    }

    startResizing = () => {
        window.addEventListener("mousemove", this.resize);
        window.addEventListener("mouseup", this.stopSidePanelResizing);
    }

    stopSidePanelResizing = () => {
        const {saveWidth, id} = this.props
        saveWidth && localStorage.setItem(SIDE_WIDTH + id, this.state.sideWidth.toString())

        window.removeEventListener("mousemove", this.resize);
        window.removeEventListener("mouseup", this.stopSidePanelResizing);
    }

    render() {
        const {sideWidth} = this.state
        const {
            panel,
            children,
            panelClassName,
            panelStyle,
            contentClassName,
            contentStyle,
            sliderClassName,
            sliderStyle,
            wrapperStyle,
            wrapperClassName,
            position
        } = this.props

        return (
            <Row wrap={false}
                 className={'w-100 h-100 ' + (wrapperClassName || '') + (position === 'right' ? ' flex-row-reverse' : '')}
                 style={{...wrapperStyle}}>
                <div className={'flex-shrink-0 ' + (panelClassName || '')} ref={this.sidePanel}
                     style={{width: sideWidth + '%', ...panelStyle}}>
                    {panel}
                </div>
                <div onMouseDown={this.startResizing} style={{cursor: 'col-resize'}} className={'flex-shrink-0'}>
                    <Divider className={'h-100' + (sliderClassName || '')} type={'vertical'}
                             style={{ borderWidth: 5, ...sliderStyle}}/>
                </div>
                <div className={'flex-grow-1 min-w-0 w-10 ' + (contentClassName || '')} style={{...contentStyle}}>
                    {children}
                </div>
            </Row>
        )
    }
}