import React from "react"
import ViewTypesService from "model/service/dataStorage/ViewTypesService"
import {Button, Col, message, Row, Spin, Steps} from "antd";
import IContentType from "model/interface/dataStorage/IContentType";
import IBaseProps from "model/interface/IBaseProps";
import IViewType from "model/interface/dataStorage/view/IViewType";
import {connect, RootStateOrAny} from "react-redux";
import {ISetupState} from "../../../../redux/reducers/Setup";
import IView from "../../../../model/interface/dataStorage/IView";
import {IViewSetStepProps} from "./ViewConfiguration";
import ViewUnits from "./ViewUnits";
import ViewProperties from "./ViewProperties";
import ViewSummary from "./ViewSummary";
import ViewPreview from "./ViewPreview";
import ViewsService from "../../../../model/service/dataStorage/ViewsService";
import ViewFilters from "./ViewFilters";
import {SaveOutlined} from "@ant-design/icons";
import {update} from "../../../../redux/actions/Setup";

interface IProps extends IBaseProps {
    resource: IView,
    contentTypes: IContentType[],
    afterSave?: (view: IView) => Promise<void>,
    updateSetup?: boolean,
    update: (changes: any) => void,
    views: IView[]
}

interface IState {
    loading: boolean,
    resource: IView,
    viewTypes: IViewType[],
    step: number
}

class ViewForm extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props)
        this.state = {
            loading: false,
            viewTypes: [],
            resource: this.props.resource || {
                id: 0,
                uuid: "",
                name: "",
                label: "",
                description: "",
                allowSettings: null,
                units: []
            },
            step: 0
        }
    }

    load(): void {
        this.setState({loading: true}, () => {
            ViewTypesService.collectionList().then(viewTypes => {
                this.setState({
                    viewTypes,
                    loading: false
                })
            })
        })
    }

    setStep = (back: boolean = false, step?: number) => {
        this.setState(state => ({
            step: step !== undefined ? step : state.step + (back ? -1 : 1)
        }))
    }

    onChange(values: any): void {
        this.setState({
            resource: {
                ...this.state.resource,
                ...values
            }
        })
        this.forceUpdate()
    }

    componentDidMount() {
        this.load()
    }

    save = () => {
        const {afterSave, update, updateSetup, views} = this.props
        return new Promise<IView>(resolve => {
            this.setState({loading: true}, () => {
                const {
                    id,
                    uuid,
                    name,
                    label,
                    description,
                    route,
                    contentType,
                    units,
                    defaultUnit,
                    filters
                } = this.state.resource
                const data = {
                    uuid,
                    label,
                    name,
                    description,
                    route,
                    units,
                    contentType,
                    defaultUnit,
                    filters
                }
                const promise = id ? ViewsService.resourceUpdate(id, data) : ViewsService.collectionCreate(data)
                promise.then(view => resolve(view))
            })
        }).then((resource) => {
            message.success(`Pohled [${resource.label}] byl úspěšně uložen.`).then()
            updateSetup && update({
                views: [...views.filter(v => v.uuid !== resource.uuid), resource]
            })
            this.setState({loading: false})
            return afterSave?.(resource)
        }).catch(() => this.setState({loading: false}))
    }

    render() {
        const {contentTypes} = this.props
        const {step, loading, resource, viewTypes} = this.state
        const {match, history} = this.props

        const stepProps: IViewSetStepProps = {
            setStep: this.setStep,
            onChange: (values) => this.onChange(values),
            resource: resource,
            contentTypes: contentTypes,
            history: history,
            match: match,
            viewTypes: viewTypes,
        }

        const steps = [
            {title: "Vlastnosti", node: <ViewProperties {...stepProps}/>},
            {title: "Sekce pohledu", node: <ViewUnits {...stepProps}/>},
            {title: "Výchozí filtry", node: <ViewFilters {...stepProps}/>},
            {title: "Výchozí zobrazení", node: <ViewPreview {...stepProps}/>},
            {title: "Souhrn", node: <ViewSummary {...stepProps} save={this.save}/>}
        ]

        return (
            <>
                {loading ? (
                    <Spin spinning={true}/>
                ) : (
                    <Row gutter={16}>
                        <Col sm={6}>
                            <Steps current={step} direction={"vertical"}
                                   onChange={steps.length - 1 === step ? selectedStep =>
                                       this.setStep(false, selectedStep) : undefined}>
                                {steps.map(s => (
                                    <Steps.Step key={s.title} title={s.title}/>
                                ))}
                            </Steps>
                            {!!resource.id && (
                                <Button icon={<SaveOutlined/>} type={"primary"} onClick={this.save}>Uložit</Button>
                            )}
                        </Col>
                        <Col sm={18}>
                            {steps[step].node}
                        </Col>
                    </Row>
                )}
            </>
        )
    }
}


const mapStateToProps = (state: RootStateOrAny) => {
    const {contentTypes, views} = state.setup as ISetupState

    return {
        contentTypes, views
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        update: (changes: any) => dispatch(update(changes))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ViewForm)
