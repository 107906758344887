import enLang from './entries/en_US';
import csLang from './entries/cs_CZ';
import skLang from './entries/sk_SK';
import frLang from './entries/fr_FR';
import esLang from './entries/es_ES';
import deLang from './entries/de_DE';


const AppLocale: {[code: string]: any} = {
    cs: csLang,
    en: enLang,
    sk: skLang,
    fr: frLang,
    es: esLang,
    de: deLang
};

export default AppLocale;