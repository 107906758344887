import React from "react";
import CardWidgetType from "./CardWidgetType";
import Widget from "../../../widget/Widget";
import ICardWidgetProps from "../../../../../../model/interface/dataStorage/card/ICardWidgetProps";
import ScalarFieldWidget from "./ScalarFieldWidget";
import ICardWidgetScalarField from "../../../../../../model/interface/dataStorage/card/ICardWidgetScalarField";
import RelationFieldWidget from "./RelationFieldWidget";
import ICardWidgetRelationField from "../../../../../../model/interface/dataStorage/card/ICardWidgetRelationField";
import CommentCardWidget from "./editor/CommentCardWidget";
import CardWidgetAction from "./CardWidgetAction";
import IActionOptions from "../../../../../../model/interface/widget/option/IActionOptions";
import ICardWidgetContainer from "../../../../../../model/interface/dataStorage/card/ICardWidgetContainer";
import ContainerWidget from "./ContainerWidget";
import CardWidgetGeneratedDocuments from "./CardWidgetGeneratedDocuments";
import CardWidgetLike from "./CardWidgetLike";
import CardWidgetPrint from "./CardWidgetPrint";
import ICardWidgetWorkflowTransactionConditions
    from "../../../../../../model/interface/dataStorage/card/ICardWidgetWorkflowTransactionConditions";
import CardWidgetWorkflowTransactionConditions from "./CardWidgetWorkflowTransactionConditions";
import {connect} from "react-redux";
import {IAppState} from "../../../../../../redux/store";
import IUser from "../../../../../../model/interface/security/IUser";
import ICardWidgetIframe from "../../../../../../model/interface/dataStorage/card/ICardWidgetIframe";
import CardWidgetIframe from "./CardWidgetIframe";

class ICardWidgetLikeOptions {
}


interface ICardWidgetPrintOptions {
}

interface IProps extends ICardWidgetProps{
    user: IUser
}

class CardWidget extends React.Component<IProps> {

    static getWidget(type: string, props: ICardWidgetProps) {
        switch (type) {
            case(CardWidgetType.SCALAR_FIELD):
                return <ScalarFieldWidget {...props as ICardWidgetProps<ICardWidgetScalarField>}/>
            case(CardWidgetType.RELATION_FIELD):
                return <RelationFieldWidget {...props as ICardWidgetProps<ICardWidgetRelationField>}/>
            case(CardWidgetType.COMMENT):
                return <CommentCardWidget {...props as ICardWidgetProps<ICardWidgetRelationField>}/>
            case(CardWidgetType.ACTION):
                return <CardWidgetAction {...props as ICardWidgetProps<IActionOptions>}/>
            case(CardWidgetType.CONTAINER):
                return <ContainerWidget {...props as ICardWidgetProps<ICardWidgetContainer>}/>
            case(CardWidgetType.GENERATED_DOCUMENTS):
                return <CardWidgetGeneratedDocuments {...props as ICardWidgetProps}/>
            case(CardWidgetType.LIKE):
                return <CardWidgetLike {...props as ICardWidgetProps<ICardWidgetLikeOptions>}/>
            case(CardWidgetType.PRINT):
                return <CardWidgetPrint {...props as ICardWidgetProps<ICardWidgetPrintOptions>}/>
            case(CardWidgetType.WORKFLOW_TRANSACTION_CONDITIONS):
                return <CardWidgetWorkflowTransactionConditions {...props as ICardWidgetProps<ICardWidgetWorkflowTransactionConditions>}/>
            case(CardWidgetType.IFRAME):
                return <CardWidgetIframe {...props as ICardWidgetProps<ICardWidgetIframe>}/>
            default:
                return Widget.getWidget(type, props)
        }
    }

    render(): JSX.Element {
        const {user, functions, id, editor} = this.props
        const node =  functions.getNode(id)

        if (!editor && node.groups?.length && !node.groups.some(g => user.groups.find(group => group.uuid === g))){
            return <></>
        }
        return Widget.renderWidget({...this.props, customWidget: CardWidget}, CardWidget.getWidget)
    }
}


const mapStateToProps = (state: IAppState) => {
    return {
       user: state.setup.user
    }
}

export default connect(mapStateToProps)(CardWidget)