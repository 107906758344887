import React, {RefObject} from "react";
import {Button, Form, FormInstance, Input, Modal, notification} from "antd";
import IDirectory from "../../../../model/interface/file/IDirectory";
import DirectoriesService from "../../../../model/service/file/DirectoriesService";
import DirectoryPicker from "../../../shared/pickers/file/DirectoryPicker";

interface DirectoryEdit {
    formRef: RefObject<FormInstance>,
    saving: boolean
}

interface IPropsDirectoryEdit {
    onUpdate: (directory?: IDirectory) => void,
    directory?: IDirectory | null,
    parent?: IDirectory | null
}

class DirectoryEdit extends React.Component<IPropsDirectoryEdit, DirectoryEdit> {

    constructor(props: IPropsDirectoryEdit) {
        super(props);
        this.state = {
            formRef: React.createRef(),
            saving: false
        } as DirectoryEdit
    }

    save = () => {
        this.setState({saving: true})
        const {directory} = this.props
        this.state.formRef.current?.validateFields()
            .then(values => {
                if (directory) {
                    if (directory.parent === values.parent) {
                        this.setState({saving: false})
                        notification.success({message: `Directory successfully moved!`})
                    } else {
                        DirectoriesService.resourceUpdate(directory.uuid, values).then(updatedDirectory => {
                            this.setState({saving: false})
                            if (values['parent'] !== updatedDirectory.parent) {
                                notification.warning({message: `Directory cannot be move to its child`})
                            } else {
                                notification.success({message: `Directory successfully moved!`})
                            }
                            this.props.onUpdate(updatedDirectory)
                        })
                    }
                } else {
                    DirectoriesService.collectionCreate(values).then(updatedDirectory => {
                        this.setState({saving: false})
                        notification.success({message: `Directory successfully created!`})
                        this.props.onUpdate(updatedDirectory)
                    })
                }

            }).catch((error) => {
            console.log(error)
            this.setState({saving: false})
        })
    }

    onCancel = () => {
        this.props.onUpdate()
    }

    render() {
        const {saving} = this.state
        const {directory, parent} = this.props
        const data = {parent: (parent?.id ? parent?.id : ''), ...directory}

        return (
            <>
                <Modal
                    title={directory ? 'Uprav slozku' : 'Vytvor slozku'}
                    okText={'uloz'}
                    visible={true}
                    onCancel={this.onCancel}
                    destroyOnClose={true}
                    footer={[
                        <Button key="back" onClick={this.onCancel}>
                            Return
                        </Button>,
                        <Button key="submit" type="primary" loading={saving} onClick={this.save}>
                            Submit
                        </Button>,
                    ]}
                >
                    <Form ref={this.state.formRef} initialValues={data}>
                        <Form.Item name={'name'} label={"Nazev"}
                                   rules={[{required: true, message: 'Vyplňte název'}]}>
                            <Input disabled={!!directory} autoFocus/>
                        </Form.Item>
                        <Form.Item label={"Parent"} name={"parent"} className={'mb-0'}>
                            <DirectoryPicker add={false} defaultRoot={true}/>
                        </Form.Item>
                        <Form.Item hidden name={'uuid'}>
                            <Input hidden/>
                        </Form.Item>
                    </Form>
                </Modal>
            </>
        )
    }
}

export default DirectoryEdit