import WidgetType from "../../../widget/WidgetType";


class CardWidgetType extends WidgetType {
    static SCALAR_FIELD = 'scalar-field'
    static RELATION_FIELD = 'relation-field'
    static GENERATED_DOCUMENTS = 'generated-documents'
    static LIKE = 'like'
    static PRINT = 'print'

    static GROUP_RELATION = 'relation'
    static GROUP_SCALAR = 'scalar'
    static GROUP_ACTION = CardWidgetType.ACTION
}

export default CardWidgetType