import React, {RefObject} from "react";
import {Button, Form, FormInstance, Modal, Select, Tag} from "antd";
import IBaseProps from "../../../../../model/interface/IBaseProps";
import IContentType from "../../../../../model/interface/dataStorage/IContentType";
import Utils from "../../../../../utils";
import IContentTypeScript, {
    ContentTypeScriptEvents
} from "../../../../../model/interface/dataStorage/contentType/IContentTypeScript";
import ArgumentEditor from "../../../ArgumentEditor";
import ICustomArguments from "../../../../../model/interface/dataStorage/ICustomArguments";
import IScript from "../../../../../model/interface/dataStorage/IScript";
import ScriptPicker from "../../../../shared/pickers/ScriptPicker";

interface IState {
    saving: boolean
    script: IContentTypeScript
    refForm: RefObject<FormInstance>
}

interface IProps extends IBaseProps {
    onFinish?: (script?: IContentTypeScript) => void
    script?: IContentTypeScript,
    contentType: IContentType
    scripts: IScript[],
}

class ContentTypeScriptModal extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            saving: false,
            script: {
                ...props.script ? props.script : {
                    name: '',
                    script: '',
                    uuid: Utils.uuid(),
                    arguments: {},
                    weight: 1,
                    events: []
                },
                contentType: props.contentType.uuid
            },
            refForm: React.createRef()
        }
    }

    onCancel = () => {
        this.state.script && JSON.stringify(this.props.script) !== JSON.stringify(this.state.script) ? Modal.confirm({
            title: 'Pozor, pokud budete pokračovat, všechny změny budou ztraceny!',
            onOk: () => {
                Modal.destroyAll();
                this.updateParent()
            },
            onCancel() {
            },
            type: 'warning'
        }) : this.updateParent()
    }

    onFinish = () => {
        this.setState({saving: true}, () => {
            const {script} = this.state
            if (script) {
                this.updateParent(script);
            }
        })
    }

    updateParent(script?: IContentTypeScript) {
        this.props.onFinish && this.props.onFinish(script)
    }

    onArgumentsUpdate(customArguments: ICustomArguments) {
        this.setState({
            script: {
                ...this.state.script,
                arguments: customArguments
            }
        })
    }

    onChange = (values: any) => {
        this.setState(state => ({
            script: {...state.script, ...values}
        }))
    }

    render() {
        const {saving, script, refForm} = this.state

        return (
            <Modal
                title={script.id ? `Uprava scriptu` : 'Nový script'}
                visible={true}
                bodyStyle={{maxHeight: '60vh', overflow: "auto"}}
                onCancel={this.onCancel}
                width={800}
                style={{overflow: "hidden"}}
                destroyOnClose={true}
                footer={[
                    <Button key="back" onClick={this.onCancel}>
                        Return
                    </Button>,
                    <Button key="submit" type="primary" loading={saving}
                            onClick={() => this.onFinish()}>
                        Submit
                    </Button>
                ]}
            >
                <Form ref={refForm} onValuesChange={this.onChange} initialValues={{...script}}>
                    <Form.Item
                        name={"script"}
                        label={"Skript"}
                        rules={[
                            {
                                required: true,
                                message: 'Vyberte script.'
                            },
                        ]}
                    >
                        <ScriptPicker/>
                    </Form.Item>
                    <Form.Item
                        name={"events"}
                        label={"Udalosti"}
                        rules={[
                            {
                                required: true,
                                message: 'Vyberte udalosti.'
                            },
                        ]}
                    >
                        <Select mode={"multiple"} tagRender={(props) => {
                            const eventInfo = ContentTypeScriptEvents.find(e => e.value === props.value)
                            return eventInfo ? (
                                <Tag onClose={props.onClose} closable={props.closable} color={eventInfo.color}>
                                    {props.label}
                                </Tag>
                            ) : <></>
                        }}>
                            {ContentTypeScriptEvents.map(event => (
                                <Select.Option value={event.value} key={event.value}>
                                    {event.label}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <ArgumentEditor
                        arguments={script.arguments || {}}
                        onChange={(customArguments: ICustomArguments) => this.onArgumentsUpdate(customArguments)}
                    />
                </Form>
            </Modal>
        )
    }
}


export default ContentTypeScriptModal