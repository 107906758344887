import IDocument from "model/interface/file/generated-document/IDocument";
import RestService from "model/service/dataStorage/RestService";
import IRestServiceCollectionResponse from "../../../interface/api/IRestServiceCollectionResponse";
import IRestServiceOptions from "../../../interface/api/IRestServiceOptions";

interface IRestDocumentsServiceCollectionResponse extends IRestServiceCollectionResponse {
    results: Array<IDocument>
}

interface IRestDocumentsService {
    NAME: string
    collectionList(options?: IRestServiceOptions): Promise<IRestDocumentsServiceCollectionResponse>,

    collectionCreate(data: any): Promise<IDocument>,

    resourceRetrieve(id: number | string): Promise<IDocument>,

    resourceUpdate(id: number | string, data: any, options?: IRestServiceOptions): Promise<IDocument>,

    resourceDelete(id: number | string): Promise<void>,

    resourceDownload(id: number | string, fileName?: string): Promise<any>,

    fileTokens(id: number | string): Promise<string[]>

    generate(patternId: number | string, resourceId: number|string, test?: boolean): Promise<{ blob: any, fileName: string }>
}

const DocumentsService_COLLECTION = 'generated-documents'
const DocumentsService_DOWNLOAD = 'generated-document-download'
const DocumentsService_TOKENS = 'generated-document-tokens'
const DocumentsService_GENERATE = 'generated-document-generate'


const GeneratedDocumentsService: IRestDocumentsService = {
    collectionList: function (options?) {
        return RestService.collectionList(DocumentsService_COLLECTION, options as unknown as IRestServiceOptions) as Promise<IRestDocumentsServiceCollectionResponse>
    },
    collectionCreate: function (data) {
        return RestService.collectionCreate(DocumentsService_COLLECTION, data, {fileUpload: true}) as Promise<IDocument>
    },
    resourceRetrieve: function (id) {
        return RestService.resourceRetrieve(DocumentsService_COLLECTION, id) as Promise<IDocument>
    },
    resourceDelete: function (id) {
        return RestService.resourceDelete(DocumentsService_COLLECTION, id)
    },
    resourceUpdate: function (id, data, options) {
        return RestService.resourceUpdate(DocumentsService_COLLECTION, id, data, options) as Promise<IDocument>
    },
    resourceDownload(id, fileName): Promise<any> {
        return RestService.resourceDownload('/' + DocumentsService_DOWNLOAD + '/' + id, true, fileName)
    },
    fileTokens(id: number | string): Promise<string[]> {
        return RestService.resourceRetrieve(DocumentsService_TOKENS, id) as Promise<string[]>
    },
    generate(patternId: number | string, resourceId: number | string, test): Promise<any> {
       return RestService.resourceDownload('/' + DocumentsService_GENERATE + '/' + patternId + '/' + resourceId + (test ? '?test' : ''), true)
    },
    NAME: 'App\\DataStorage\\Extension\\GeneratedDocument'
}

export default GeneratedDocumentsService