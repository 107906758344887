import React from "react";
import IFieldOptions from "../../../../../../model/interface/form/elementOptions/IFieldOptions";
import FileImageEditorUpload from "../../../../file-manager/FileImageEditorUpload";

class FormFieldImageEditor {

    public static render = (options: IFieldOptions) => {
        const {
            imageEditorRatio,
            imageEditorRound,
            imageEditorRotation,
            imageEditorGrid,
            placeholder,
            disabled
        } = options

        return (
            <FileImageEditorUpload disabled={disabled} allowRotation={imageEditorRotation} roundCropper={imageEditorRound}
                                   showCropperGrid={imageEditorGrid}
                                   aspectRatio={(imageEditorRatio || 50) / 50} placeholder={placeholder}/>
        )
    }
}

export default FormFieldImageEditor