import store from "redux/store";
import IField from "../../interface/dataStorage/IField";
import selectors from "../../../redux/selectors";
import IForm from "../../interface/form/IForm";
import ICard from "../../interface/dataStorage/ICard";

abstract class MasterRepositoryService {
    abstract getMasterClassName(): string

    abstract getBaseFields(): IField[]

    getSlaveContentType() {
        return selectors.contentTypes.findOneOrNullBy(store.getState(), 'masterClass', this.getMasterClassName())
    }

    getSlaveContentTypeForm(name?: string): IForm | undefined {
        const contentType = this.getSlaveContentType()
        if (name && contentType) {
            return selectors.forms.findAll(store.getState())
                    .filter(f => (contentType.forms as any).includes(f.uuid) && f.name === name)[0]
                || this.getSlaveContentTypeForm()
        }
        return contentType?.forms[0] && selectors.forms.findOneBy(store.getState(), 'uuid', contentType?.forms[0])
    }

    getSlaveContentTypeCard(name?: string): ICard | undefined {
        const contentType = this.getSlaveContentType()
        if (name) {
            return contentType?.cards.find(c => c.name === name) || this.getSlaveContentTypeCard()
        }
        return contentType?.cards[0]
    }

    getVirtualFields(): IField[] {
        const contentType = this.getSlaveContentType()
        return (contentType ? contentType.fields : []);
    }

    getFields(): IField[] {
        return [...this.getVirtualFields(), ...this.getBaseFields().filter(f => !this.getVirtualFields().find(vf => vf.name === f.name))]
    }
}

export default MasterRepositoryService