import React from "react";
import {Modal as ModalAntd, ModalProps, Row} from "antd";
import {ArrowsAltOutlined, ShrinkOutlined} from "@ant-design/icons";
import Hotkey from "../hotkey/Hotkey";

interface IProps extends ModalProps {
    fullScreenOption?: boolean,
    fullScreen?: boolean
}

interface IState {
    isFullScreen: boolean
}

class Modal extends React.Component<IProps, IState> {


    constructor(props: IProps, context: any) {
        super(props, context);
        this.state = {
            isFullScreen: !!props.fullScreen
        }
    }

    setFullScreen = () => {
        this.setState(state => ({isFullScreen: !state.isFullScreen}))
    }

    render() {
        const {fullScreenOption, closable, className, children} = this.props
        const {isFullScreen} = this.state

        return (
            <ModalAntd {...this.props} className={(className || '') + (isFullScreen ? ' full-screen' : '')}>
                {children}
                <div style={{
                    marginRight: closable !== false ? '56px' : '0',
                    top: 0,
                    right: 0,
                    zIndex: 1,
                    height: 56
                }} onClick={this.setFullScreen} className={'position-absolute ant-btn-link cursor-pointer px-2'}>
                    {fullScreenOption &&
                        <Hotkey keys={['Alt', 'c']} trigger={this.setFullScreen} className={'h-100'}
                                help={isFullScreen ? 'Vypnúť celú obrazovku' : 'Prepnúť na celú obrazovku'}>
                            <Row align={'middle'} justify={'center'} className={'h-100 w-100'}>
                                {isFullScreen ? <ShrinkOutlined/> : <ArrowsAltOutlined/>}
                            </Row>
                        </Hotkey>
                    }
                </div>
            </ModalAntd>
        )
    }
}

export default Modal