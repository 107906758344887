import React from "react"
import {Table, Tooltip} from "antd";
import _ from "underscore";
import {LinkOutlined} from "@ant-design/icons";
import IField from "../../../../../model/interface/dataStorage/IField";
import {ISourcePreviewProps} from "../SourcePreview";
import {ColumnsType} from "antd/es/table";

interface IStateSourceMapping {

}

class SourcePreviewTable extends React.Component<ISourcePreviewProps, IStateSourceMapping> {

    constructor(props: ISourcePreviewProps) {
        super(props);
        this.state = {}
    }

    getFields(className: string): IField[] {
        return this.props.findServiceByClassName(className).getFields()
    }

    getField = (className: string, name?: string) => {
        return this.getFields(className).find(field => field.name === name)
    }

    buildColumns = () => {
        const columns: ColumnsType<any> = []
        const {type, mapping} = this.props.resource
        const {data} = this.props
        if (data.length > 0) {
            Object.entries(data[0]).forEach(([column]) => {
                const field = this.getField(type, column.split('.')[0]);
                const currentMapping = _.findWhere(mapping, {field: column})
                field && columns.push({
                    title: (
                        field.label
                    ),
                    ellipsis: true,
                    width: 140,
                    render: (title) => title[column]
                })
            })
        }
        return columns
    }

    render() {
        const {data} = this.props

        return (
            <div className={'w-100  overflow-auto'}>
                <Table
                    rowKey={'index'}
                    columns={this.buildColumns()}
                    dataSource={data}
                />
            </div>
        )
    }
}

export default SourcePreviewTable