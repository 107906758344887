import React from "react";
import IFieldOptions from "../../../../../../model/interface/form/elementOptions/IFieldOptions";
import Wysiwyg from "../../../../../shared/input/Wysiwyg";

class FormFieldWYSIWYG {

    public static render = (options: IFieldOptions) => {
        return (
            <Wysiwyg mode={options.wysiwygPackage} inlineFile={options.wysiwygInlineFile} disabled={options.disabled}
                     placeholder={options.placeholder} value={options.value}/>
        )
    }
}

export default FormFieldWYSIWYG