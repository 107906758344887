import RestService from "model/service/dataStorage/RestService";
import IRestServiceOptions from "../../interface/api/IRestServiceOptions";
import IRestServiceCollectionResponse from "../../interface/api/IRestServiceCollectionResponse";
import IRepositoryService from "../../interface/IRepositoryService";
import IField, {FIELD_MODE_RELATION, RELATION_FIELD_TYPE} from "../../interface/dataStorage/IField";
import IPresenter from "../../interface/dataStorage/IPresenter";
import IRestServiceChoiceListResponse from "../../interface/api/IRestServiceChoiceListResponse";
import IContact from "../../interface/company/IContact";
import React from "react";
import IconBuilder from "../../../utils/IconBuilder";
import ContactTypesService from "./ContactTypesService";

interface IRestUnitsServiceCollectionResponse extends IRestServiceCollectionResponse {
    results: Array<IContact>
}

interface IRestContactsService extends IRepositoryService {
    collectionList(options?: IRestServiceOptions): Promise<IRestUnitsServiceCollectionResponse>,

    collectionCreate(data: any): Promise<IContact>,

    resourceRetrieve(id: number | string): Promise<IContact>,

    resourceUpdate(id: number | string, data: any): Promise<IContact>,

    resourceDelete(id: number | string): Promise<void>,
}

const COLLECTION = 'company/contacts'
const CHOICES = 'company/contacts-choices/'

const presenters: IPresenter[] = [
    {
        type: 'label',
        name: 'default',
        label: 'Výchozí',
        options: {
            label: "#value"
        }
    },
    {
        type: 'callback',
        name: 'withIcon',
        label: 'S ikonou',
        options: {
            callback: (object?: IContact) => {
                return object ?
                    <span className={'text-nowrap'}>{IconBuilder(object?.type.icon)}{' ' + object.value}</span> : ''
            }
        }
    }
]

class ContactsService implements IRestContactsService {
    private static instance?: ContactsService

    public static getInstance(): ContactsService {
        if (!ContactsService.instance) {
            ContactsService.instance = new ContactsService()
        }
        return ContactsService.instance
    }

    getFields(): IField[] {
        return [
            {
                uuid: '',
                name: 'value',
                label: 'Hodnota',
                mode: "scalar",
                type: 'string',
                targetEntity: null,
                options: [],
                weight: 1,
                contentTypeId: null,
                contentTypeName: false,
                locked: true,
                arguments: {}
            },
            {
                uuid: '',
                name: 'type',
                label: 'Typ',
                mode: FIELD_MODE_RELATION,
                type: RELATION_FIELD_TYPE.MANY_TO_ONE,
                targetEntity: ContactTypesService.getInstance().getRecordClassName(),
                options: [],
                weight: 1,
                contentTypeId: null,
                contentTypeName: false,
                locked: true,
                arguments: {}
            }
        ];
    }

    getRecordClassName() {
        return 'App\\Company\\Entity\\Contact'
    }

    getTitle(): string {
        return "Kontakt";
    }

    collectionList(options?: IRestServiceOptions): Promise<IRestUnitsServiceCollectionResponse> {
        return RestService.collectionList(COLLECTION, options as unknown as IRestServiceOptions) as Promise<IRestUnitsServiceCollectionResponse>
    }

    collectionCreate(data: any) {
        return RestService.collectionCreate(COLLECTION, data) as Promise<IContact>
    }

    resourceRetrieve(id: number | string, options?: IRestServiceOptions) {
        return RestService.resourceRetrieve(COLLECTION, id) as Promise<IContact>
    }

    resourceDelete(id: number | string) {
        return RestService.resourceDelete(COLLECTION, id)
    }

    resourceUpdate(id: number | string, data: any) {
        return RestService.resourceUpdate(COLLECTION, id, data) as Promise<IContact>
    }

    getStringValue(contact: IContact): string {
        return contact.value
    }

    getPresenter(name: string): IPresenter | null {
        return presenters.find(value => value.name === name) || null
    }

    getDefaultPresenter(): IPresenter {
        return presenters[0]
    }

    getPresenterList() {
        return presenters.map(presenter => {
            return {value: presenter.name, label: presenter.label}
        })
    }

    choiceList(presenterName: string, options?: IRestServiceOptions): Promise<IRestServiceChoiceListResponse> {
        return RestService.collectionList(CHOICES + presenterName, options) as unknown as Promise<IRestServiceChoiceListResponse>
    }
}

export default ContactsService