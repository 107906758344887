import RestService from "model/service/dataStorage/RestService";
import IRestServiceOptions from "../../interface/api/IRestServiceOptions";
import IRestServiceCollectionResponse from "../../interface/api/IRestServiceCollectionResponse";
import IComment from "../../interface/comment/IComment";

interface IRestCommentsServiceCollectionResponse extends IRestServiceCollectionResponse {
  results: Array<IComment>
}

interface IRestCommentsService {
  collectionList(options?: IRestServiceOptions): Promise<IRestCommentsServiceCollectionResponse>,
  collectionCreate(data: any, options?: IRestServiceOptions): Promise<IComment>,
  resourceRetrieve(id: number|string): Promise<IComment>,
  resourceUpdate(id: number|string, data: any): Promise<IComment>,
  resourceDelete(id: number|string): Promise<void>,
}

const CommentsService_COLLECTION = 'comments'

const CommentsService: IRestCommentsService = {
  collectionList: function (options?) {
    return RestService.collectionList(CommentsService_COLLECTION, options as unknown as IRestServiceOptions) as Promise<IRestCommentsServiceCollectionResponse>
  },
  collectionCreate: function ( data, options) {
    return RestService.collectionCreate(CommentsService_COLLECTION, data, options) as Promise<IComment>
  },
  resourceRetrieve: function (id) {
    return RestService.resourceRetrieve(CommentsService_COLLECTION, id) as Promise<IComment>
  },
  resourceDelete: function (id) {
    return RestService.resourceDelete(CommentsService_COLLECTION, id)
  },
  resourceUpdate: function (id, data) {
    return RestService.resourceUpdate(CommentsService_COLLECTION, id, data) as Promise<IComment>
  }
}

export default CommentsService