import React from "react";
import {Col, Form, Input, List, Row, Select, Typography} from "antd";
import ICommand from "../../../model/interface/symfony-console/ICommand";
import {CloseOutlined, DeleteOutlined, PlusOutlined, UnorderedListOutlined} from "@ant-design/icons";
import Utils from "../../../utils";
import Button from "../../shared/button/Button";
import IInput from "../../../model/interface/symfony-console/IInput";
import IOption from "../../../model/interface/symfony-console/IOption";

interface IProps {
    command: ICommand,
    input: IInput
    onChange: (argumentsInput: IInput, refresh?: boolean) => void,
    autoFocus?: boolean
}

interface IState {

}

class Options extends React.Component<IProps, IState> {

    onAdd = (code: string) => {
        const {onChange, input} = this.props
        const option = this.get(code)

        onChange({...input, [code]: option?.isArray ? [''] : true})
    }

    onDelete = (argument: string) => {
        const {onChange, input} = this.props
        const result = {...input}
        delete result[argument];
        onChange(result)
    }

    onChangeValue = (code: string, value: string, index: number, remove: boolean = false) => {
        const option = this.get(code)
        const {onChange, input} = this.props

        let inputValue = input[code]
        if (option?.isArray) {
            inputValue = Array.isArray(inputValue) ? [...inputValue] : []
            inputValue[index] = value
            if (remove) {
                inputValue.splice(index, 1)
            }
        } else {
            inputValue = value
        }

        return onChange({...input, [code]: inputValue}, remove)
    }

    get = (code: string) => {
        const {command} = this.props
        return command.options.find(o => o.code === code)
    }

    buildOptions(): any {
        const {command} = this.props

        const options = [
            ...command.options.filter(o => !o.global)
                .map(o => ({value: o.code, label: this.buildOptionLabel(o)})), {
                value: 'global', label: 'global', options: command.options.filter(o => o.global)
                    .map(o => ({value: o.code, label: this.buildOptionLabel(o)}))
            }]

        return options.length ? options : undefined
    }

    buildOptionLabel = (option: IOption) => {
        return <div>
            {option.code}
            <div className={'ml-2 d-inline text-muted'}>{option.description}</div>
        </div>
    }

    render() {
        const {input, autoFocus} = this.props
        const optionsList = this.buildOptions()

        return !!optionsList.length && (
            <Col flex={'auto'}>
                <Row align={'middle'} wrap={false}>
                    <UnorderedListOutlined/>
                    <Select value={null as any} placeholder={"možnosti"} allowClear={true} showSearch={true}
                            filterOption={(inputValue, option) => Utils.stringContains(option?.value, inputValue)}
                            onSelect={value => this.onAdd(value as any)} options={optionsList} className={'w-100'}
                            bordered={false} autoFocus={autoFocus}/>
                </Row>
                {!!Object.keys(input).length && (
                    <List size={"small"}>
                        {Object.entries(input).map(([name, value]) => {
                            const option = this.get(name)
                            return option &&
                                <List.Item key={name} className={'d-flex justify-content-between'}>
                                    {(Array.isArray(value) ? value : [value]).map((value, index) => (
                                        <Row wrap={false} align={"middle"}>
                                            <Typography.Text strong={true}>{name}:</Typography.Text>
                                            <Form.Item name={name + index} className={'m-0 ml-2'}
                                                       noStyle={index > 0} colon={option.acceptValue}
                                                       rules={[{
                                                           required: option.required,
                                                           message: 'hodnota je povinná'
                                                       }]}>
                                                {option.acceptValue && (
                                                    <Input
                                                        onChange={e => this.onChangeValue(name, e.target.value, index)}/>
                                                )}
                                            </Form.Item>
                                            {option.isArray && index > 0 && (
                                                <Button icon={<CloseOutlined/>} danger={true} size={"small"}
                                                        type={"link"}
                                                        onClick={() => this.onChangeValue(name, '', index, true)}/>
                                            )}
                                        </Row>
                                    ))}
                                    {option.isArray && Array.isArray(value) && (
                                        <Button icon={<PlusOutlined/>} className={'mt-2'} size={"small"}
                                                type={"primary"}
                                                onClick={() => this.onChangeValue(name, '', value.length, true)}/>
                                    )}

                                    <Button icon={<DeleteOutlined/>} danger={true} size={"small"}
                                            type={"link"}
                                            onClick={() => this.onDelete(name)}/>
                                </List.Item>
                        })}
                    </List>
                )}
            </Col>
        )
    }
}

export default Options;