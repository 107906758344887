import fetch from 'model/auth/ApiAuth'
import Utils from "../../../utils";
import {AxiosPromise} from "axios";

interface IPasswordResetService {
  request: (email:string) => AxiosPromise<void>
  reset: (password:string,token:string) => AxiosPromise<void>
  check: (token:string) => AxiosPromise<void>
}

const PasswordResetService: IPasswordResetService = {
  request: (username) => {
    return fetch({
      url: '/password-reset-request',
      method: 'post',
      data: Utils.convertArrayToFormData({
        username
      }),
      headers: {
        "Content-Type": "multipart/form-data",
        'public-request': 'true'
      }
    })
  },

  reset: (password,token) => {
    return fetch({
      url: '/password-reset',
      method: 'post',
      data: Utils.convertArrayToFormData({
        password,
        token
      }),
      headers: {
        "Content-Type": "multipart/form-data",
        'public-request': 'true'
      }
    })
  },
  check: (token) => {
    return fetch({
      url: '/password-reset-token-check',
      method: 'post',
      data: Utils.convertArrayToFormData({
        token
      }),
      headers: {
        "Content-Type": "multipart/form-data",
        'public-request': 'true'
      }
    })
  }
}

export default PasswordResetService