import IPlan from "../../../../../model/interface/company/workload/IPlan";
import IEntry from "../../../../../model/interface/company/workload/IEntry";
import {Tag} from "antd";
import React from "react";
import IReport from "../../../../../model/interface/company/workload/IReport";

interface IProps {
    resource?: IPlan | IEntry | IReport
    waiting?: boolean
}

class PlanOrEntryOrReportState extends React.Component<IProps> {
    render() {
        const {resource, waiting} = this.props
        return (
            <>
                {resource ? {
                    'new': waiting ? <Tag className="text-capitalize" color={'yellow'}>Čeká na schválení</Tag> : <Tag className="text-capitalize" color={'blue'}>Nový</Tag>,
                    'waiting': <Tag className="text-capitalize" color={'yellow'}>Čeká na schválení</Tag>,
                    'planned': <Tag className="text-capitalize" color={'blue'}>Rozvrženo</Tag>,
                    'approved': <Tag className="text-capitalize"
                                     color={'cyan'}>{'type' in resource ? (resource.type?.approvalRequired ? 'Schváleno' : 'Zadáno') : 'Schváleno'}</Tag>,
                    'rejected': <Tag className="text-capitalize" color={'red'}>Zamítnuto</Tag>,
                    'returned': <Tag className="text-capitalize" color={'orange'}>Vráceno</Tag>,
                    'spent': <Tag className="text-capitalize">Vyčerpáno</Tag>,
                    'archived': <Tag className="text-capitalize" color={'purple'}>Archivované</Tag>,
                }[resource.state] : <Tag className="text-capitalize">Nový</Tag>}
            </>
        )
    }
}

export default PlanOrEntryOrReportState