import {RootStateOrAny} from "react-redux";
import IRepositoryService from "../../model/interface/IRepositoryService";
import DataStorageService from "../../model/service/dataStorage/DataStorageService";
import {findAll, findOneBy} from "./ContentTypes";
import RestService from "../../model/service/dataStorage/RestService";
import FilesService from "../../model/service/file/FilesService";
import IContentType from "../../model/interface/dataStorage/IContentType";
import WorkflowStatesService from "../../model/service/file/WorkflowStatesService";
import UsersService from "../../model/service/security/UsersService";
import ThreadsService from "../../model/service/comment/ThreadsService";
import EmployeesService from "../../model/service/company/EmployeesService";
import CompaniesService from "../../model/service/company/CompaniesService";
import UnitsService from "../../model/service/company/UnitsService";
import JobsService from "../../model/service/company/JobsService";
import LikesService from "../../model/service/dataStorage/extension/LikesService";
import TimeTrackerService from "../../model/service/dataStorage/extension/TimeTrackerService";
import ContactsService from "../../model/service/company/ContactsService";
import ContactTypesService from "../../model/service/company/ContactTypesService";

const services = {
    RestService,
    // ModulesService,
    FilesService,
    WorkflowStatesService,
    UsersService,
    ThreadsService,
    EmployeesService,
    UnitsService,
    CompaniesService,
    JobsService,
    LikesService,
    TimeTrackerService,
    ContactsService,
    ContactTypesService
}

interface IServicesCache {
    [key: string]: IRepositoryService
}

export interface IRepositoryServiceList {
    [className: string]: IRepositoryService
}

let servicesCache: IServicesCache = {}

export const collectionList = (store: RootStateOrAny): IRepositoryServiceList => {
    let list = {} as IRepositoryServiceList
    findAll(store).forEach((contentType: IContentType) => {
        list[contentType.fullClassName] = findOneByFullClassName(store, contentType.fullClassName)
    })
    list[UsersService.getRecordClassName()] = services.UsersService
    list[FilesService.getRecordClassName()] = services.FilesService
    list[WorkflowStatesService.getRecordClassName()] = services.WorkflowStatesService
    list[ThreadsService.getRecordClassName()] = services.ThreadsService
    list[EmployeesService.getInstance().getRecordClassName()] = services.EmployeesService.getInstance()
    list[JobsService.getInstance().getRecordClassName()] = services.JobsService.getInstance()
    list[UnitsService.getInstance().getRecordClassName()] = services.UnitsService.getInstance()
    list[CompaniesService.getInstance().getRecordClassName()] = services.CompaniesService.getInstance()
    list[LikesService.getRecordClassName()] = services.LikesService
    list[TimeTrackerService.getInstance().getRecordClassName()] = services.TimeTrackerService.getInstance()
    list[ContactsService.getInstance().getRecordClassName()] = services.ContactsService.getInstance()
    list[ContactTypesService.getInstance().getRecordClassName()] = services.ContactTypesService.getInstance()
    return list
}

export const findOneByFullClassName = (store: RootStateOrAny, entity: string): IRepositoryService => {
    if (entity.startsWith(DataStorageService.VIRTUAL_ENTITY)) {
        if (servicesCache.hasOwnProperty(entity)) {
            return servicesCache[entity]
        }
        const contentType = findOneBy(store, 'fullClassName', entity)
        servicesCache[entity] = new DataStorageService(contentType)
        return servicesCache[entity]
    }
    const service = collectionList(store)[entity]
    if (service) {
        return service
    }
    throw new Error("Unable to get service from target entity " + entity)
}

export const findOneOrNullByFullClassName = (store: RootStateOrAny, entity: string): IRepositoryService | null => {
    try {
        return findOneByFullClassName(store, entity)
    } catch (e) {
        return null
    }
}


export const isVirtual = (entity: string): boolean => {
    return entity.startsWith(DataStorageService.VIRTUAL_ENTITY);

}
export const findOneByContentType = (store: RootStateOrAny, contentType: IContentType): IRepositoryService => {
    return findOneByFullClassName(store, contentType.fullClassName)
}

const selectors = {
    collectionList,
    findOneByFullClassName,
    findOneByContentType,
    isVirtual,
    findOneOrNullByFullClassName
}

export default selectors