import RestService from "model/service/dataStorage/RestService";
import IRestServiceOptions from "../../interface/api/IRestServiceOptions";
import IRestServiceCollectionResponse from "../../interface/api/IRestServiceCollectionResponse";
import IScript from "../../interface/dataStorage/IScript";

interface IRestScriptsServiceCollectionResponse extends IRestServiceCollectionResponse {
    results: Array<IScript>
}

interface IRestScriptsService {
    collectionList(options?: IRestServiceOptions): Promise<IRestScriptsServiceCollectionResponse>,
    collectionCreate(data: any): Promise<IScript>,
    resourceRetrieve(id: number|string): Promise<IScript>,
    resourceUpdate(id: number|string, data: any): Promise<IScript>,
    resourceDelete(id: number|string): Promise<void>,
}

const ScriptsService_COLLECTION = 'scripts'

const ScriptsService: IRestScriptsService = {
    collectionList: function (options?) {
        return RestService.collectionList(ScriptsService_COLLECTION, options as unknown as IRestServiceOptions) as Promise<IRestScriptsServiceCollectionResponse>
    },
    collectionCreate: function ( data) {
        return RestService.collectionCreate(ScriptsService_COLLECTION, data, {fileUpload: true} as IRestServiceOptions) as Promise<IScript>
    },
    resourceRetrieve: function (id) {
        return RestService.resourceRetrieve(ScriptsService_COLLECTION, id) as Promise<IScript>
    },
    resourceDelete: function (id) {
        return RestService.resourceDelete(ScriptsService_COLLECTION, id)
    },
    resourceUpdate: function (id, data) {
        return RestService.resourceUpdate(ScriptsService_COLLECTION, id, data, {fileUpload: true}) as Promise<IScript>
    }
}

export default ScriptsService