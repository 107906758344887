import React from "react";
import {List, Tabs} from "antd";
import ICommand from "../../../model/interface/symfony-console/ICommand";
import {CodeOutlined} from "@ant-design/icons";
import CommandService from "../../../model/service/symfony-console/CommandService";
import ScrollContainer from "../../shared/scrollContainer/ScrollContainer";

interface IProps {
    commands: ICommand[]
}

interface IState {
}

class Documentation extends React.Component<IProps, IState> {


    render() {
        const {commands} = this.props
        const namespaces = CommandService.groupByNamespace(commands)

        return <div style={{overflow: "auto"}} className={'position-relative h-100'}>
            <Tabs className={'h-100 content-h-100'}
                defaultActiveKey={'base'}
                tabPosition={'left'}
            >
                {namespaces.map(namespace =>
                    <Tabs.TabPane tab={namespace.name || 'base'} key={namespace.name || 'base'} className={'h-100'}>
                        <ScrollContainer visibility={"visible"}>
                            <List>
                                {namespace.commands.map(command => {
                                    return (
                                        <List.Item key={command.name}>
                                            <List.Item.Meta
                                                avatar={<CodeOutlined/>}
                                                title={<div>{command.name}
                                                    <div className={'text-muted ml-2 mb-3 d-inline'}>
                                                        {command.description}
                                                    </div>
                                                </div>}
                                                description={<div
                                                    dangerouslySetInnerHTML={{__html: command.help}}/>}
                                            />
                                        </List.Item>
                                    )
                                })}
                            </List>
                        </ScrollContainer>
                    </Tabs.TabPane>
                )}

            </Tabs>
        </div>
    }
}

export default Documentation

