import {UI_TOGGLE_SHOW_SHORTCUTS} from "../constants/Ui";

export type IUiState = {
    showShortcuts: boolean
}

type IAction = {
    type: string,
    [x: string]: any
}

const initState: IUiState = {
    showShortcuts: false
}

const ui = (state = initState, action: IAction) => {
    switch (action.type) {
        case UI_TOGGLE_SHOW_SHORTCUTS: {
            return {
                ...state,
                showShortcuts: !state.showShortcuts
            }
        }
        default:
            return state;
    }
}

export default ui