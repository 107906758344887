import React, {Key, RefObject} from 'react'
import {
    Col,
    Collapse,
    Drawer,
    Form,
    FormInstance,
    Input,
    message,
    Popconfirm,
    Row,
    Tooltip,
    Tree,
    Typography
} from 'antd';
import {CodeOutlined, CopyOutlined, DeleteOutlined} from "@ant-design/icons";
import _ from "underscore"
import IWidget from "../../../../model/interface/widget/IWidget";
import TreeStructure, {ITreeNode, ITreeStructure} from "../../../../utils/TreeStructure";
import ResizableSidePanel from "../../../shared/layout/ResizableSidePanel";
import {NodeDragEventParams} from "rc-tree/lib/contextTypes";
import {ReorderTwoOutline} from "react-ionicons";
import {EventDataNode} from 'antd/lib/tree';
import Button from "../../../shared/button/Button";
import WidgetList, {IWidgetList, WidgetListManager} from "../widget/WidgetList";
import FormElementType from "../form/FormElement/FormElementType";
import Utils from "../../../../utils";
import Widget from "../widget/Widget";
import IFormOptions from "../../../../model/interface/form/IFormOptions";
import WidgetGallery from "../widget/WidgetGallery";
import IWidgetPropsFunctions from "../../../../model/interface/widget/IWidgetPropsFunctions";
import IBaseProps from "../../../../model/interface/IBaseProps";
import IWidgetOptions from "../../../../model/interface/widget/IWidgetOptions";
import WidgetType from "../widget/WidgetType";
import ScrollContainer from "../../../shared/scrollContainer/ScrollContainer";
import LocaleText from "../../settings/dictionary/LocaleText";

export interface IEditorState<E extends IWidget> {
    code?: boolean,
    structure: ITreeStructure<E>
    current: string
    expandedKeys: Key[]
}

export interface IEditorProps extends IBaseProps {

}

abstract class Editor<T extends IEditorProps, S extends IEditorState<E>, E extends IWidget> extends React.Component<T, S> {

    formRefCode: RefObject<FormInstance> = React.createRef()
    formRefOptions: RefObject<FormInstance> = React.createRef()
    widgetList: IWidgetList<E> = new WidgetListManager<E>()

    protected constructor(props: Readonly<T> | T) {
        super(props);
        const containerUuid = Utils.uuid()
        this.state = {
            ...this.state,
            expandedKeys: [],
            structure: {
                [containerUuid]: {...WidgetList.getByType(FormElementType.CONTAINER), id: containerUuid} as E
            },
            current: containerUuid
        }
    }

    componentDidMount() {
        this.confirm()
    }

    removeNodeById(id: string) {
        const {structure} = this.state
        let newStructure = {...structure}
        if (newStructure[id]) {
            newStructure = this.removeChildren(id, newStructure)
            const parent = newStructure[id].parent
            if (parent && newStructure[parent]) {
                newStructure[parent].children = _.without(newStructure[parent].children, id)
            }
            delete newStructure[id]
            this.setState({structure: newStructure, current: parent || Object.values(structure)[0].id}, this.confirm)
        }
    }

    removeChildren(id: string | number, structure: ITreeStructure<E>) {
        for (const child of Editor.getSortedChildren(id, structure)) {
            structure = this.removeChildren(child.id, structure)
            delete structure[child.id]
        }
        return structure
    }

    static getSortedChildren(id: string | number, structure: ITreeStructure<any>) {
        return TreeStructure.sortChildren(id, structure, 'weight')
    }

    applyCode(values: any) {
        let code = JSON.parse(values["code"])
        if (code) {
            this.formatStructureFromTree(code[0])
        }
        message.success('Formulář aktualizován').then()
    }

    setCode = () => {
        this.setState(state => ({code: !state.code}))
    }

    setExpanded = (expandedKeys: Key[], node: Key, collapsed: boolean) => {
        this.setState({expandedKeys: expandedKeys.filter(k => !collapsed || !TreeStructure.isParent(node, k, this.state.structure))})
    }

    formatJsonTree() {
        const {structure} = this.state
        return TreeStructure.buildJson(structure, null, 2)
    }

    formatStructureFromTree(tree: (E & ITreeNode)) {
        this.setState({structure: TreeStructure.destruct(tree)})
    }

    copyCode = () => {
        navigator.clipboard.writeText(this.formRefCode.current?.getFieldValue('code'))
            .then(() => {
                message.success('Zkopírováno').then()
            })
    }

    onDrop = (info: NodeDragEventParams & {
        dragNode: EventDataNode
        dragNodesKeys: Key[]
        dropPosition: number
        dropToGap: boolean
    }) => {
        const {structure} = this.state
        const dropKey = info.node.key
        const dragKey = info.dragNode.key
        const dropPos = info.node.pos.split('-')
        const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1])

        const data = [...TreeStructure.build(structure)]
        let dragObj: any;
        this.loop(data, dragKey, (item, index, arr) => {
            arr.splice(index, 1)
            dragObj = item
        })

        if (!info.dropToGap || ((info.node.children || []).length > 0 && info.node.expanded && dropPosition === 1)) {
            const stop = this.loop(data, dropKey, item => {
                const add = this.widgetList.getItemByType(item.type).add
                if (add !== true || (Array.isArray(add) && !add.includes(dragObj.type))) {
                    message.error('Nelze připojit tuto podřízenou položku k tomuto rodiči').then()
                    return true
                }
                item.children = item.children || []
                item.children.unshift(dragObj)
            })
            if (stop) {
                return
            }
        } else {
            this.loop(data, dropKey, (item, index, arr) => {
                if (dropPosition === -1) {
                    arr.splice(index, 0, dragObj);
                } else {
                    arr.splice(index + 1, 0, dragObj)
                }
            })
        }

        this.setState({
            structure: TreeStructure.updatePositionProperty(dragKey, TreeStructure.destruct(data[0]), 'weight')
        }, this.confirm)
    }

    loop = (data: (Omit<IWidget, 'children'> & ITreeNode)[], key: string | number,
            callback: (item: Omit<IWidget, 'children'> & ITreeNode, index: number, arr: ITreeNode[]) => void | true): void | true => {

        for (const i in data) {
            if (data[i].key === key) {
                return callback(data[i], Number(i), data)
            }
            if (data[i].children) {
                const result = this.loop(data[i].children, key, callback)
                if (result) {
                    return result
                }
            }
        }
    }

    getPathToNode = (id: string) => {
        const {structure} = this.state
        let parent = structure[id]?.parent
        let path = []
        while (parent){
            path.unshift(parent)
            parent = structure[parent]?.parent
        }
        return path
    }

    setCurrent = (current: string) => {
        this.validate().then(() => {
            this.setState(state => ({
                current,
                expandedKeys: Array.from(new Set([...state.expandedKeys, ...this.getPathToNode(current)]))
            }), this.formRefOptions.current?.resetFields)
        })
    }

    validate = () => {
        return this.formRefOptions.current?.validateFields().catch(() => {
            message.warn('Neplatné vlastnosti, prosím opravte').then()
            return Promise.reject()
        }) || Promise.resolve()
    }

    getCurrentNode() {
        const {structure, current} = this.state
        return structure[current];
    }

    onOptionsChange = (values?: IFormOptions) => {
        const node = this.getCurrentNode()
        if (values) {
            return this.saveNode({...node}, values)
        }
        return Promise.resolve()
    }

    saveNode(node: E, values?: IWidgetOptions) {
        const id = node.id
        let newStructure = {...this.state.structure}
        node.options = {...node.options, ...(values || this.getInitialOptions(node))}
        let isNew = false
        if (!newStructure[id] && node.parent) {
            node.weight = newStructure[node.parent].children.push(id)
            isNew = true
        }
        newStructure = {...newStructure, [id]: node}
        if (node.type === WidgetType.TABLE) {
            Array.from(Array(node.options.rowsNumber * node.options.columnsNumber).keys()).forEach(index => {
                if (newStructure[id].children.length < (index + 1)) {
                    let cell = {...this.widgetList.getByType(WidgetType.TABLE_CELL), parent: id, id: Utils.uuid()}
                    node.weight = newStructure[cell.parent].children.push(cell.id)
                    newStructure = {...newStructure, [cell.id]: cell}
                }
            })
        }
        return new Promise<void>(r => this.setState({structure: newStructure, current: node.id},
            () => {
                r()
                isNew && this.formRefOptions.current?.resetFields()
            }))
    }

    confirm = () => {
    }

    getGallery = (group?: string) => {
        const {current} = this.state

        return <WidgetGallery node={this.getCurrentNode()} list={this.widgetList} onSelect={(type: string) =>
            this.appendNodeExecute(current, type)} customGroup={group}/>
    }

    appendNodeExecute(id: string, type: string) {
        this.validate().then(() => {
            let node = {...this.widgetList.getByType(type), parent: id, id: Utils.uuid()}
            this.saveNode(node).then()
        })
    }

    getOptionEditor(node: IWidget, formRef: RefObject<FormInstance>) {
        return Widget.getOptionEditor(node.type, node.options, formRef, this.onOptionsChange)
    }

    getExtraConfiguration(node: IWidget): void | JSX.Element {

    }

    renderPreview = () => {
        const {structure, current} = this.state
        const {history, match} = this.props

        return <div className={'w-100'}>
            {Object.entries(structure).flatMap(([key, node]) => {
                if (node && !node.parent) {
                    let functions: IWidgetPropsFunctions = {
                        getNode: (id: string) => structure[id],
                        setCurrent: (id: string) => this.setCurrent(id),
                        getSortedChildren: (id: string) =>
                            Editor.getSortedChildren(id, structure)
                    }
                    return (
                        <Widget
                            {...node}
                            editor={true}
                            current={current}
                            id={key}
                            children={node.children}
                            key={key}
                            functions={functions}
                            history={history}
                            match={match}
                        />
                    )
                }
                return []
            })}
        </div>
    }

    getInitialOptions(node: E): IWidgetOptions {
        return {rowsNumber: 2, columnsNumber: 2, ...node.options}
    }

    render() {
        const {code, structure, current, expandedKeys} = this.state

        const node = this.getCurrentNode()

        const treeData = TreeStructure.build(Object.fromEntries(Object.entries(structure).map(([key, node]) =>
            [key, {
                ...node,
                title: <Row className={'d-inline-flex'} align={'middle'} gutter={[6, 6]}>
                    <Col>{this.widgetList.getItemByType(node.type).icon}</Col>
                    <Col>{node.label}</Col>
                </Row>
            }]
        )))

        const editor = node && this.getOptionEditor(node, this.formRefOptions)
        const nodeType = this.widgetList.getItemByType(node.type)

        return (
            <div className={'position-relative h-100'}>
                <ResizableSidePanel id={'editor'} contentClassName={'widget-editor'}
                                    wrapperClassName={'h-100'} panel={node ?
                    <Form style={{padding: '3px'}} className={'h-100'} initialValues={this.getInitialOptions(node)}
                          ref={this.formRefOptions}
                          onValuesChange={(_, values) => this.onOptionsChange(values)}>
                        <Row className={'h-100 flex-column'}>
                            <Col>
                                <Row justify={"space-between"} className={'mb-2'}>
                                    <Col>
                                        <Typography.Title level={3}>
                                            {nodeType.icon && (
                                                <div className={'mr-2 d-inline'}>{nodeType.icon}</div>
                                            )}
                                            {node.label}
                                        </Typography.Title>
                                    </Col>
                                    <Col>
                                        <Row gutter={[6, 6]}>
                                            <Col>
                                                <Tooltip title={'Editor kódu'}>
                                                    <Button icon={<CodeOutlined/>} type={'special'} size={"small"}
                                                            onClick={() => this.setCode()}/>
                                                </Tooltip>
                                            </Col>
                                            <Col>
                                                <Popconfirm title={'Opravdu chcete smazat tento widget a jeho potomky?'}
                                                                onConfirm={() => this.removeNodeById(node.id)} okText={<LocaleText code={'general.yes'} fallback={'Ano'}/>}
                                                            cancelText={<LocaleText code={'general.no'} fallback={'Ne'}/>}>
                                                    <Button disabled={!node.parent} size={"small"} icon={<DeleteOutlined/>}
                                                            type={'danger'}/>
                                                </Popconfirm>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col flex={'1 1'}>
                                <ScrollContainer visibility={"visible"} className={'px-1'}>
                                    <Collapse defaultActiveKey={['properties']}>
                                        <Collapse.Panel key={'children'} header={'Prvky'}>
                                            <Tree
                                                className="draggable-tree"
                                                draggable={true}
                                                selectedKeys={[current]}
                                                autoExpandParent={true}
                                                showIcon={true}
                                                defaultExpandedKeys={[current]}
                                                expandedKeys={expandedKeys}
                                                onSelect={(_, {node}) => this.setCurrent(node.key.toString())}
                                                icon={<span
                                                    style={{cursor: "move"}}><ReorderTwoOutline/></span>}
                                                blockNode={true}
                                                onExpand={(keys, {node}) => this.setExpanded(keys, node.key, node.expanded)}
                                                showLine={false}
                                                onDrop={this.onDrop}
                                                treeData={treeData}
                                            />
                                        </Collapse.Panel>
                                        {editor && (
                                            <Collapse.Panel key={'properties'} header={'Vlastnosti'}>
                                                {editor}
                                            </Collapse.Panel>
                                        )}
                                        {this.widgetList.getItemByType(node.type).add &&
                                            <Collapse.Panel key={'gallery'} header={'Přidat prvek'}>
                                                {this.getGallery()}
                                            </Collapse.Panel>
                                        }
                                        {this.getExtraConfiguration(node)}
                                    </Collapse>
                                </ScrollContainer>
                            </Col>
                        </Row>
                    </Form>
                    : 'no content'
                } position={'right'}>
                    <ScrollContainer className={'p-1'} wrapperClassName={'px-1'} visibility={"visible"}>
                        {this.renderPreview()}
                    </ScrollContainer>
                </ResizableSidePanel>
                <Drawer
                    placement="left"
                    width={'50%'}
                    closable={true}
                    onClose={this.setCode}
                    visible={code}
                    getContainer={false}
                    style={{position: 'absolute'}}
                >
                    <Form onFinish={(values) => this.applyCode(values)} layout={"vertical"}
                          initialValues={{code: this.formatJsonTree()}}>
                        <Form.Item name={"code"} label={'Kód'} shouldUpdate={true}>
                            <Input.TextArea rows={10}/>
                        </Form.Item>
                        <Button htmlType={"submit"} type={"primary"}>Aktualizovat</Button>
                        <Button className={'ml-1'} type={"dashed"}
                                onClick={this.copyCode} icon={<CopyOutlined/>}/>
                    </Form>
                </Drawer>
            </div>
        )
    }
}

export default Editor