import React from "react";
import IFormElementProps from "../../../../../../model/interface/form/IFormElementProps";
import IField from "../../../../../../model/interface/dataStorage/IField";
import {
    ICompositeFieldApprovalOptions
} from "../../../content-type/field/optionEditors/composite/CompositeFieldApprovalOptionsEditor";
import {Card, Input, Select, Switch, Tooltip} from "antd";
import ICompositeFieldData from "../../../../../../model/interface/dataStorage/field/composite/ICompositeFieldData";
import FormFieldType from "./FormFieldType";
import moment from "moment";
import {connect, RootStateOrAny} from "react-redux";
import IUser from "../../../../../../model/interface/security/IUser";


interface IProps extends IFormElementProps {
    value?: ICompositeFieldData,
    onChange?: (value: ICompositeFieldData) => void
    options: ICompositeFieldApprovalOptions
    field: IField,
    user: IUser,
}

interface IState {
}


class FormCompositeFieldApproval extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {}
    }

    onChange = (property: string, change: any) => {
        const {onChange, value, user} = this.props
        let dependentData = {}
        if (property === this.RESULT) {
            dependentData = {
                [this.DATE]: FormFieldType.formatFromForm(FormFieldType.FIELD_DATE, moment()),
                [this.AUTHOR]: user.employees[0]?.uuid || null
            }
        }
        onChange?.({...value, [property]: change, ...dependentData})
    }

    render() {
        const {options, value, resource} = this.props
        const {allowChange, allowJustification, requireJustification, allowDenial, action} = options
        const changeDisabled = (!allowChange && value?.[this.RESULT]) || (action && !resource?._permissions[action])
        const disabled = (requireJustification && !value?.[this.JUSTIFICATION]?.trim()) || changeDisabled

        const content = (
            <div>
                {allowJustification && (
                    <Input.TextArea disabled={changeDisabled} onChange={value => this.onChange(this.JUSTIFICATION, value.target.value)}
                                    value={value?.[this.JUSTIFICATION]} placeholder={'Zdůvodnění'}
                                    className={'mb-3'}/>
                )}
                <Tooltip title={changeDisabled ? 'Nelze změnit' : disabled ? 'Nejprve vyplňte zdůvodnění' : false}>
                    <div className={'d-inline-block w-100'}>
                        {allowDenial ? (
                                <Select className={'w-100'}
                                        value={value?.[this.RESULT]}
                                        onChange={value => this.onChange(this.RESULT, value)}
                                        disabled={disabled}>
                                    <Select.Option key={1} value={1}>Schváleno</Select.Option>
                                    <Select.Option key={2} value={2}>Zamítnuto</Select.Option>
                                    <Select.Option value={null as any}>Čeká na schválení</Select.Option>
                                </Select>) :
                            <Switch onChange={value => this.onChange(this.RESULT, value ? 1 : 2)}
                                    disabled={disabled} checked={value?.[this.RESULT] === 1} checkedChildren="Schváleno"
                                    unCheckedChildren="Schvaluji"/>}
                    </div>
                </Tooltip>
            </div>
        )
        return (
            allowJustification ? <Card size={"small"} bodyStyle={{width: '100%'}}>
                {content}
            </Card> : content
        )
    }

    getFieldName = (property: string) => {
        const {field} = this.props
        return field.name + '_' + property
    }

    JUSTIFICATION = this.getFieldName('justification')
    RESULT = this.getFieldName('result')
    DATE = this.getFieldName('date')
    AUTHOR = this.getFieldName('author')
}

const mapStateToProps = (state: RootStateOrAny) => {
    const {user} = state.setup
    return {
        user
    }
}
export default connect(mapStateToProps)(FormCompositeFieldApproval)