import React from "react";
import IWidgetProps from "../../../../model/interface/widget/IWidgetProps";
import Widget from "./Widget";

interface IProps extends IWidgetProps {
    className?: string,
    childClassName?: string

}

interface IState {
    hiddenChildren: string[]
}

class WidgetChildren extends React.Component<IProps, IState> {

    constructor(props: IProps, context: any) {
        super(props, context);
        this.state = {
            hiddenChildren: []
        }
    }

    onChildHidden = (id: string, hidden: boolean) => {
        const {hiddenChildren} = this.state
        const newHidden =  !hiddenChildren.includes(id) && hidden ?
            [...hiddenChildren, id] : hiddenChildren.filter(c => c !== id)
        this.setState({
            hiddenChildren: newHidden
        })
    }

    render() {
        const {id, functions, customWidget, className, childClassName} = this.props
        const {hiddenChildren} = this.state
        const children = functions.getSortedChildren(id)
        const CustomWidget = customWidget

        return (
            <div className={className}>
                {children && children.map(child =>
                    <div key={child.id} className={childClassName + (hiddenChildren.includes(child.id) ? ' d-none' : '')}>
                        {CustomWidget ? (
                            <CustomWidget {...this.props}
                                          {...child}
                                          className={''}
                                          functions={{...functions, onChildHidden: this.onChildHidden}}
                                          id={child.id}
                                          key={child.id}/>
                        ) : (
                            <Widget
                                {...this.props}
                                {...child}
                                className={''}
                                id={child.id}
                                key={child.id}
                            />)}
                    </div>
                )}
            </div>
        );
    }
}

export default WidgetChildren