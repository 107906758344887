import {Form, Switch} from 'antd';
import React from "react";
import {IFieldOptionsEditorProps} from "../FieldOptionsEditor";

export interface IFieldCodeOptions {
    allowCameraScan?: boolean,
    autoCloseScanOnResult?: boolean
}

interface IProps extends IFieldOptionsEditorProps {

}

interface IState {
}

class FieldCodeOptionsEditor extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {}
    }

    static getDefaultOptions(): IFieldCodeOptions {
        return {allowCameraScan: true, autoCloseScanOnResult: true}
    }

    render() {
        const {buildFieldName} = this.props
        return (
            <div>
                <Form.Item name={buildFieldName('allowCameraScan')} initialValue={true} label={'Skenování pomoci kamery'} valuePropName={'checked'}>
                    <Switch/>
                </Form.Item>
                <Form.Item name={buildFieldName('autoCloseScanOnResult')} initialValue={true} label={'Automatické ukončení skenování po nalezeném kódu'}
                           valuePropName={'checked'}>
                    <Switch/>
                </Form.Item>
            </div>
        )
    }
}


export default FieldCodeOptionsEditor