import React, {RefObject} from "react";
import {Drawer, Form, FormInstance, Input, InputNumber, Modal, Select, Table} from "antd";
import {DeleteOutlined, EditOutlined, PlusOutlined} from "@ant-design/icons";
import ICustomArguments from "../../model/interface/dataStorage/ICustomArguments";
import Button from "../shared/button/Button";
import LocaleText from "./settings/dictionary/LocaleText";
import {IAppState} from "../../redux/store";
import selectors from "../../redux/selectors";
import {connect} from "react-redux";

interface IProps {
    arguments: ICustomArguments,
    onChange: (customArguments: ICustomArguments) => void
    getText: (code: string, fallBack?: string) => string
}
interface IArgument {
    name: string,
    type: string,
    value: boolean|number|string
}
interface IState {
    refForm: RefObject<FormInstance>,
    argument?: IArgument,
    type: string
}
const newArgument = {
    name: '',
    type: 'string',
    value: ''
} as IArgument

class ArgumentEditor extends React.Component<IProps, IState> {
    state = {
        refForm: React.createRef() as RefObject<FormInstance>,
        argument: undefined,
        type: newArgument.type
    }
    add() {
        this.setState({
            argument: {...newArgument},
            type: newArgument.type
        })
        setTimeout(() => this.state.refForm.current?.resetFields(), 1)
    }
    cancel() {
        this.setState({argument: undefined})
    }
    edit(name: string) {
        const value = this.props.arguments[name]
        const type = typeof value
        this.setState({
            argument: {
                name,
                type,
                value,
            },
            type
        })
        setTimeout(() => this.state.refForm.current?.resetFields(), 1)
    }
    delete(name: string) {
        const customArguments = {...this.props.arguments}
        delete customArguments[name]
        this.props.onChange(customArguments)
    }
    onChangeBoolean(value: any) {
        this.setState({argument:{
            ...this.state.argument! as IArgument,
            value
        }})
    }
    update(values: any) {
        console.log('update', values)
        let customArguments = {...this.props.arguments}
        const name = values.name
        let value
        switch(this.state.type) {
            case('number'): value = parseInt(values.numberValue, 10); break;
            case('boolean'): value = !!values.booleanValue; break;
            case('string'):
            default:
                value = values.stringValue
        }
        customArguments[name] = value
        this.props.onChange(customArguments)
        this.setState({argument: undefined})
    }

    buildTypeOptions = () => {
        return [
            {
                label: 'Text',
                value: 'string'
            },
            {
                label: 'Číslo',
                value: 'number'
            },
            {
                label: 'Logická hodnota',
                value: 'boolean'
            }
        ]
    }

    onValuesChange(values: any) {
        if(values.type) {
            this.setState({type: values.type})
        }
    }

    render() {
        const {getText} = this.props
        const customArguments = this.props.arguments
        const {type, refForm} = this.state
        const argument = this.state.argument! as IArgument
        const initialValues = argument ? {
            name: argument.name,
            type: argument.type,
            booleanValue: argument.type === 'boolean' ? argument.value : true,
            stringValue: argument.type === 'string' ? argument.value : '',
            numberValue: argument.type === 'number' ? argument.value : 0,
        } : {}
        return (
            <div>
                <Drawer
                    title={<LocaleText code={'argument.editor.title'} fallback={'Správa argumentů'}/>}
                    placement="right"
                    closable={false}
                    onClose={() =>this.cancel()}
                    visible={typeof argument !== 'undefined'}
                    getContainer={false}
                    style={{ position: 'absolute' }}
                >
                    <Form
                        ref={refForm}
                        initialValues={initialValues}
                        onFinish={(values) => this.update(values)}
                        onValuesChange={(values) => this.onValuesChange(values)}
                    >
                        <Form.Item
                            label={"Název"}
                            name={"name"}
                            rules={[{required: true, message: <LocaleText code={'form.item.required'}/>}]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={"Type"}
                            name={"type"}
                            rules={[{required: true, message: <LocaleText code={'form.item.required'}/>}]}
                        >
                            <Select options={this.buildTypeOptions()} />
                        </Form.Item>
                        {type === 'string' && (
                            <Form.Item
                                label={"Hodnota"}
                                name={"stringValue"}
                                rules={[{required: true, message: <LocaleText code={'form.item.required'}/>}]}
                            >
                                <Input />
                            </Form.Item>
                        )}
                        {type === 'boolean' && (
                            <Form.Item
                                label={"Hodnota"}
                                name={"booleanValue"}
                            >
                                <Input type={"checkbox"} checked={!!initialValues.booleanValue} onChange={event => this.onChangeBoolean(event.target.value)} />
                            </Form.Item>
                        )}
                        {type === 'number' && (
                            <Form.Item
                                label={<LocaleText code={'general.value'} fallback={'"Hodnota"'}/>}
                                name={"numberValue"}
                                rules={[{required: true, message: <LocaleText code={'form.item.required'}/>}]}
                            >
                                <InputNumber />
                            </Form.Item>
                        )}
                        <Button onClick={() => this.state.refForm.current?.submit()} htmlType={"button"} type={"primary"}>
                            <LocaleText code={'general.save'}/>
                        </Button>
                    </Form>
                </Drawer>
                <Table
                    pagination={false}
                    columns={[
                        {
                            dataIndex: 'name',
                            title: <LocaleText code={'general.name'}/>
                        },
                        {
                            dataIndex: 'value',
                            title: <LocaleText code={'general.value'}/>
                        },
                        {
                            title: (
                                <>
                                    Akce
                                    <Button
                                        className={"ml-1"}
                                        type={"primary"}
                                        size={"small"}
                                        icon={<PlusOutlined/>}
                                        onClick={() => this.add()}
                                    />
                                </>
                            ),
                            render: (_value: any, argument: any) => {
                                return (
                                    <>
                                        <Button
                                            type={"primary"}
                                            size={"small"}
                                            icon={<EditOutlined/>}
                                            onClick={() => this.edit(argument.name)}
                                        />
                                        <Button
                                            type={"primary"}
                                            danger
                                            size={"small"}
                                            icon={<DeleteOutlined/>}
                                            onClick={() => Modal.confirm({
                                                content: getText('argument.editor.remove', 'Opravdu odebrat?'),
                                                onOk: () => this.delete(argument.name)
                                            })}
                                        />
                                    </>
                                )
                            }
                        }
                    ]}
                    dataSource={Object.keys({...customArguments}).map(name => ({name, value: JSON.stringify(customArguments[name])}))}
                />
            </div>
        );
    }
}

const mapStateToProps = (state: IAppState) => {

    return {
        getText: (code: string, fallback?: string) => selectors.dictionary.getMessage(state, code, fallback)
    }
}

export default connect(mapStateToProps)(ArgumentEditor)