import IEmployee from "../IEmployee";

export const COMPANY_WORKLOAD_FUND_PERIOD_YEAR = 1
export const COMPANY_WORKLOAD_FUND_PERIOD_MONTH = 2

export default interface IFund {
    id?: number,
    uuid: string,
    type: string,
    obligation: string,
    amount: number,
    period: number
    extends?: string
    affectedByAmountChange: IEmployee[]
    forceClaimChangeToIndividual: boolean
}