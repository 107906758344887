import fetch from 'model/auth/ApiAuth'
import Utils from 'utils';
import {PUBLIC_REQUEST_KEY} from "../../auth/FetchInterceptor";
import ITokenResponse from "../../interface/security/ITokenResponse";

const tokenService = {
    retrieve: (username: string, password: string): Promise<ITokenResponse> => {
        // @ts-ignore
        return fetch({
            url: '/token',
            method: 'post',
            data: Utils.convertArrayToFormData({
                username,
                password
            }),
            headers: {
                "Content-Type": "multipart/form-data",
                [PUBLIC_REQUEST_KEY]: 'true'
            }
        })
    },
    additionalAuthorization: (user: string, code?: string, reset?: true): Promise<ITokenResponse> => {
        // @ts-ignore
        return fetch({
            url: '/token-additional',
            method: 'post',
            data: Utils.convertArrayToFormData({
                user,
                code,
                reset
            }),
            headers: {
                "Content-Type": "multipart/form-data",
                [PUBLIC_REQUEST_KEY]: 'true'
            }
        })
    },
    google: (token: string): Promise<ITokenResponse> => {
        // @ts-ignore
        return fetch({
            url: '/token',
            method: 'post',
            data: Utils.convertArrayToFormData({
                token,
                authority: 'google'
            }),
            headers: {
                "Content-Type": "multipart/form-data",
                [PUBLIC_REQUEST_KEY]: 'true'
            }
        })
    },
    facebook: (token: string): Promise<ITokenResponse> => {
        // @ts-ignore
        return fetch({
            url: '/token',
            method: 'post',
            data: Utils.convertArrayToFormData({
                token,
                authority: 'facebook'
            }),
            headers: {
                "Content-Type": "multipart/form-data",
                [PUBLIC_REQUEST_KEY]: 'true'
            }
        })
    },
    ldap: (username: string, password: string): Promise<ITokenResponse> => {
        // @ts-ignore
        return fetch({
            url: '/token',
            method: 'post',
            data: Utils.convertArrayToFormData({
                username,
                password,
                authority: 'ldap'
            }),
            headers: {
                "Content-Type": "multipart/form-data",
                [PUBLIC_REQUEST_KEY]: 'true'
            }
        })
    }
}

export default tokenService