import React from "react";
import {connect, RootStateOrAny} from "react-redux";
import {ISetupState} from "../../../redux/reducers/Setup";
import IUser from "../../../model/interface/security/IUser";
import {IBaseViewProps} from "./ViewUnit";
import {Badge, Button, Col, Tooltip} from "antd";
import {FilterOutline} from "react-ionicons";
import {CheckCircleFilled} from "@ant-design/icons";

interface IState {

}

interface IProps extends IBaseViewProps {
    user: IUser
}


class ViewPersonalEditButton extends React.Component<IProps, IState> {

    constructor(props: Readonly<IProps> | IProps) {
        super(props);
        this.state = {}
    }

    render() {
        const {
            viewUnit,
            showSettings,
            allowSettings,
            settings
        } = this.props

        return allowSettings ? (
            <Col>
                <Badge count={settings.personal ? <Tooltip title={"Moje nastavení jsou aktivní"}>
                    <CheckCircleFilled className={'text-success'} style={{top: 4, right: 4}}/>
                </Tooltip> : ''}>
                    <Tooltip title={"Přizpůsobit moje nastavení"}>
                        <Button
                            onClick={showSettings}
                            style={{verticalAlign: "middle"}}
                            icon={<FilterOutline/>}
                            disabled={!viewUnit.id}
                            size={"small"}
                        />
                    </Tooltip>
                </Badge>
            </Col>
        ) : <></>
    }
}


const mapStateToProps = (state: RootStateOrAny) => {
    const {user} = state.setup as ISetupState
    return {
        user
    }
}

export default connect(mapStateToProps)(ViewPersonalEditButton)