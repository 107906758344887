import WidgetList, {IWidgetListItem, WidgetListManager} from "../../../widget/WidgetList";
import CardWidgetType from "./CardWidgetType";
import {antIcons} from "../../../../../../utils/Icons/AntIcons";
import ICardWidget from "../../../../../../model/interface/dataStorage/card/ICardWidget";


class CardWidgetListManager extends WidgetListManager<ICardWidget>{

    widgets: IWidgetListItem[] = [
        ...WidgetList.getAll(),
        {
            label: 'Vazba',
            type: CardWidgetType.RELATION_FIELD,
            icon: antIcons.PartitionOutlined,
            group: CardWidgetType.GROUP_RELATION,
            hidden: true
        }, {
            label: 'Vazba',
            type: CardWidgetType.SCALAR_FIELD,
            icon: antIcons.DatabaseOutlined,
            group: CardWidgetType.GROUP_SCALAR,
            hidden: true
        }, {
            label: 'Akce',
            type: CardWidgetType.ACTION,
            icon: antIcons.ThunderboltOutlined,
            group: CardWidgetType.ACTION,
            hidden: true
        },{
            label: 'Dokumenty',
            type: CardWidgetType.GENERATED_DOCUMENTS,
            icon: antIcons.FileTextOutlined,
            group: CardWidgetType.GROUP_RELATION,
            hidden: true
        },
        {
            label: 'Líbí se mi',
            type: CardWidgetType.LIKE,
            icon: antIcons.LikeOutlined,
            group: CardWidgetType.GROUP_RELATION
        },
        {
            label: 'Workflow podmínky',
            type: CardWidgetType.WORKFLOW_TRANSACTION_CONDITIONS,
            icon: antIcons.BranchesOutlined,
            group: CardWidgetType.GROUP_RELATION
        },
        {
            label: 'Schválení',
            type: CardWidgetType.SCALAR_FIELD,
            icon: antIcons.AppstoreAddOutlined,
            group: CardWidgetType.GROUP_SCALAR
        }
    ]

    getGrouped =  () => {
        let groups = {
            ...WidgetList.getGrouped(),
            [CardWidgetType.GROUP_SCALAR]: {label: 'Scalárani a vztahová pole [N:1]', widgets: []},
            [CardWidgetType.GROUP_RELATION]: {label: 'Vztahová pole [1:N]', widgets: []},
            [CardWidgetType.GROUP_ACTION]: {label: 'Akce', widgets: []}
        } as { [group: string]: { label: string, widgets: IWidgetListItem[] } }
        return this.mapWidgetsToGroups(groups);
    }
}



const CardWidgetList = new CardWidgetListManager()

export default CardWidgetList
