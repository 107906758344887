import React from 'react'
import {Progress, Typography} from 'antd';
import IClaim from "../../../../../model/interface/company/workload/IClaim";
import {MomentBuilder} from "../../../../../utils/MomentBuilder";
import IObligation from "../../../../../model/interface/company/IObligation";
import {humanShiftHoursInDays} from "../../../file-manager/utils/TimeDuration";

const {Text} = Typography;

interface IProps {
    claim: IClaim,
    obligation: IObligation

    showValidity?: boolean
}

interface IState {

}

export class Status extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props)
        this.state = {} as IState
    }


    getDays(obligation: IObligation, total: number) {
        return obligation.dayWorkHours && <> ({humanShiftHoursInDays(total, obligation.dayWorkHours)})</>;
    }

    render() {
        const {claim, showValidity, obligation} = this.props
        const planned = claim ? claim.amountPlanned + claim.amountTaken : 0
        return <>
            {claim ?
                <>
                    {showValidity &&
                        <Text strong>{
                            MomentBuilder.build(claim.validFrom).format('LL')} -
                            {MomentBuilder.build(claim.validUntil).format('LL')}
                        </Text>}
                    <Progress showInfo={false} percent={100 * (claim.amountSpent + planned) / claim.amountTotal}
                              success={{percent: (100 * claim.amountSpent / claim.amountTotal)}} size="small"/>

                    Plánováno:{' '}<Text strong>{planned}h</Text> {this.getDays(obligation, planned)},
                    Čerpáno:{' '}<Text strong>{claim.amountSpent}h</Text> {this.getDays(obligation, claim.amountSpent)},
                    Zůstatek:{' '}<Text strong>{claim.amountFree}h</Text> {this.getDays(obligation, claim.amountFree)},
                    Nárok:{' '}<Text strong>{claim.amountTotal}h</Text> {this.getDays(obligation, claim.amountTotal)}
                </>
                : (
                    <span className={"text-muted"}>Není k dispozici</span>
                )}
        </>
    }
}

export default Status