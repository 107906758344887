import React from 'react'
import {Alert} from "antd";
import ISettings from "../../model/interface/ui/ISettings";
import {RouteComponentProps} from "react-router-dom";

interface IProps extends RouteComponentProps<any> {
    settings: ISettings
}

interface IState {
}

class Login extends React.Component<IProps, IState> {
    render() {
        return (
            <Alert className={'mt-4'} message="Vaše licence vypršela." description={'Funkce aplikace jsou omezeny.'} type="error" showIcon/>
        )
    }
}

export default Login
