import RestService from "model/service/dataStorage/RestService";
import IRestServiceOptions from "../../interface/api/IRestServiceOptions";
import IRestServiceCollectionResponse from "../../interface/api/IRestServiceCollectionResponse";
import IEmployeePickerFilter from "../../interface/company/IEmployeePickerFilter";

interface IRestEmployeePickerFiltersServiceCollectionResponse extends IRestServiceCollectionResponse {
    results: Array<IEmployeePickerFilter>
}

interface IRestEmployeePickerFiltersService {
    collectionList(options?: IRestServiceOptions): Promise<IRestEmployeePickerFiltersServiceCollectionResponse>,

    collectionCreate(data: any): Promise<IEmployeePickerFilter>,

    resourceRetrieve(id: number | string): Promise<IEmployeePickerFilter>,

    resourceUpdate(id: number | string, data: any): Promise<IEmployeePickerFilter>,

    resourceDelete(id: number | string): Promise<void>
}

const COLLECTION = 'company/employee-picker-filters'

const EmployeePickerFiltersService: IRestEmployeePickerFiltersService = {
    collectionList: function (options?) {
        return RestService.collectionList(COLLECTION, options as unknown as IRestServiceOptions) as Promise<IRestEmployeePickerFiltersServiceCollectionResponse>
    },
    collectionCreate: function (data) {
        return RestService.collectionCreate(COLLECTION, data) as Promise<IEmployeePickerFilter>
    },
    resourceRetrieve: function (id) {
        return RestService.resourceRetrieve(COLLECTION, id) as Promise<IEmployeePickerFilter>
    },
    resourceDelete: function (id) {
        return RestService.resourceDelete(COLLECTION, id)
    },
    resourceUpdate: function (id, data) {
        return RestService.resourceUpdate(COLLECTION, id, data) as Promise<IEmployeePickerFilter>
    }
}

export default EmployeePickerFiltersService