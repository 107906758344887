import * as IonIcon from "react-ionicons";
import React from "react";

export const ionIcons: { [name: string]: JSX.Element } = {
    AccessibilityOutline: <IonIcon.AccessibilityOutline width={'1em'} height={'1em'}/>,
    AccessibilitySharp: <IonIcon.AccessibilitySharp width={'1em'} height={'1em'}/>,
    Accessibility: <IonIcon.Accessibility width={'1em'} height={'1em'}/>,
    AddCircleOutline: <IonIcon.AddCircleOutline width={'1em'} height={'1em'}/>,
    AddCircleSharp: <IonIcon.AddCircleSharp width={'1em'} height={'1em'}/>,
    AddCircle: <IonIcon.AddCircle width={'1em'} height={'1em'}/>,
    AddOutline: <IonIcon.AddOutline width={'1em'} height={'1em'}/>,
    AddSharp: <IonIcon.AddSharp width={'1em'} height={'1em'}/>,
    Add: <IonIcon.Add width={'1em'} height={'1em'}/>,
    AirplaneOutline: <IonIcon.AirplaneOutline width={'1em'} height={'1em'}/>,
    AirplaneSharp: <IonIcon.AirplaneSharp width={'1em'} height={'1em'}/>,
    Airplane: <IonIcon.Airplane width={'1em'} height={'1em'}/>,
    AlarmOutline: <IonIcon.AlarmOutline width={'1em'} height={'1em'}/>,
    AlarmSharp: <IonIcon.AlarmSharp width={'1em'} height={'1em'}/>,
    Alarm: <IonIcon.Alarm width={'1em'} height={'1em'}/>,
    AlbumsOutline: <IonIcon.AlbumsOutline width={'1em'} height={'1em'}/>,
    AlbumsSharp: <IonIcon.AlbumsSharp width={'1em'} height={'1em'}/>,
    Albums: <IonIcon.Albums width={'1em'} height={'1em'}/>,
    AlertCircleOutline: <IonIcon.AlertCircleOutline width={'1em'} height={'1em'}/>,
    AlertCircleSharp: <IonIcon.AlertCircleSharp width={'1em'} height={'1em'}/>,
    AlertCircle: <IonIcon.AlertCircle width={'1em'} height={'1em'}/>,
    AlertOutline: <IonIcon.AlertOutline width={'1em'} height={'1em'}/>,
    AlertSharp: <IonIcon.AlertSharp width={'1em'} height={'1em'}/>,
    Alert: <IonIcon.Alert width={'1em'} height={'1em'}/>,
    AmericanFootballOutline: <IonIcon.AmericanFootballOutline width={'1em'} height={'1em'}/>,
    AmericanFootballSharp: <IonIcon.AmericanFootballSharp width={'1em'} height={'1em'}/>,
    AmericanFootball: <IonIcon.AmericanFootball width={'1em'} height={'1em'}/>,
    AnalyticsOutline: <IonIcon.AnalyticsOutline width={'1em'} height={'1em'}/>,
    AnalyticsSharp: <IonIcon.AnalyticsSharp width={'1em'} height={'1em'}/>,
    Analytics: <IonIcon.Analytics width={'1em'} height={'1em'}/>,
    ApertureOutline: <IonIcon.ApertureOutline width={'1em'} height={'1em'}/>,
    ApertureSharp: <IonIcon.ApertureSharp width={'1em'} height={'1em'}/>,
    Aperture: <IonIcon.Aperture width={'1em'} height={'1em'}/>,
    AppsOutline: <IonIcon.AppsOutline width={'1em'} height={'1em'}/>,
    AppsSharp: <IonIcon.AppsSharp width={'1em'} height={'1em'}/>,
    Apps: <IonIcon.Apps width={'1em'} height={'1em'}/>,
    ArchiveOutline: <IonIcon.ArchiveOutline width={'1em'} height={'1em'}/>,
    ArchiveSharp: <IonIcon.ArchiveSharp width={'1em'} height={'1em'}/>,
    Archive: <IonIcon.Archive width={'1em'} height={'1em'}/>,
    ArrowBackCircleOutline: <IonIcon.ArrowBackCircleOutline width={'1em'} height={'1em'}/>,
    ArrowBackCircleSharp: <IonIcon.ArrowBackCircleSharp width={'1em'} height={'1em'}/>,
    ArrowBackCircle: <IonIcon.ArrowBackCircle width={'1em'} height={'1em'}/>,
    ArrowBackOutline: <IonIcon.ArrowBackOutline width={'1em'} height={'1em'}/>,
    ArrowBackSharp: <IonIcon.ArrowBackSharp width={'1em'} height={'1em'}/>,
    ArrowBack: <IonIcon.ArrowBack width={'1em'} height={'1em'}/>,
    ArrowDownCircleOutline: <IonIcon.ArrowDownCircleOutline width={'1em'} height={'1em'}/>,
    ArrowDownCircleSharp: <IonIcon.ArrowDownCircleSharp width={'1em'} height={'1em'}/>,
    ArrowDownCircle: <IonIcon.ArrowDownCircle width={'1em'} height={'1em'}/>,
    ArrowDownOutline: <IonIcon.ArrowDownOutline width={'1em'} height={'1em'}/>,
    ArrowDownSharp: <IonIcon.ArrowDownSharp width={'1em'} height={'1em'}/>,
    ArrowDown: <IonIcon.ArrowDown width={'1em'} height={'1em'}/>,
    ArrowForwardCircleOutline: <IonIcon.ArrowForwardCircleOutline width={'1em'} height={'1em'}/>,
    ArrowForwardCircleSharp: <IonIcon.ArrowForwardCircleSharp width={'1em'} height={'1em'}/>,
    ArrowForwardCircle: <IonIcon.ArrowForwardCircle width={'1em'} height={'1em'}/>,
    ArrowForwardOutline: <IonIcon.ArrowForwardOutline width={'1em'} height={'1em'}/>,
    ArrowForwardSharp: <IonIcon.ArrowForwardSharp width={'1em'} height={'1em'}/>,
    ArrowForward: <IonIcon.ArrowForward width={'1em'} height={'1em'}/>,
    ArrowRedoCircleOutline: <IonIcon.ArrowRedoCircleOutline width={'1em'} height={'1em'}/>,
    ArrowRedoCircleSharp: <IonIcon.ArrowRedoCircleSharp width={'1em'} height={'1em'}/>,
    ArrowRedoCircle: <IonIcon.ArrowRedoCircle width={'1em'} height={'1em'}/>,
    ArrowRedoOutline: <IonIcon.ArrowRedoOutline width={'1em'} height={'1em'}/>,
    ArrowRedoSharp: <IonIcon.ArrowRedoSharp width={'1em'} height={'1em'}/>,
    ArrowRedo: <IonIcon.ArrowRedo width={'1em'} height={'1em'}/>,
    ArrowUndoCircleOutline: <IonIcon.ArrowUndoCircleOutline width={'1em'} height={'1em'}/>,
    ArrowUndoCircleSharp: <IonIcon.ArrowUndoCircleSharp width={'1em'} height={'1em'}/>,
    ArrowUndoCircle: <IonIcon.ArrowUndoCircle width={'1em'} height={'1em'}/>,
    ArrowUndoOutline: <IonIcon.ArrowUndoOutline width={'1em'} height={'1em'}/>,
    ArrowUndoSharp: <IonIcon.ArrowUndoSharp width={'1em'} height={'1em'}/>,
    ArrowUndo: <IonIcon.ArrowUndo width={'1em'} height={'1em'}/>,
    ArrowUpCircleOutline: <IonIcon.ArrowUpCircleOutline width={'1em'} height={'1em'}/>,
    ArrowUpCircleSharp: <IonIcon.ArrowUpCircleSharp width={'1em'} height={'1em'}/>,
    ArrowUpCircle: <IonIcon.ArrowUpCircle width={'1em'} height={'1em'}/>,
    ArrowUpOutline: <IonIcon.ArrowUpOutline width={'1em'} height={'1em'}/>,
    ArrowUpSharp: <IonIcon.ArrowUpSharp width={'1em'} height={'1em'}/>,
    ArrowUp: <IonIcon.ArrowUp width={'1em'} height={'1em'}/>,
    AtCircleOutline: <IonIcon.AtCircleOutline width={'1em'} height={'1em'}/>,
    AtCircleSharp: <IonIcon.AtCircleSharp width={'1em'} height={'1em'}/>,
    AtCircle: <IonIcon.AtCircle width={'1em'} height={'1em'}/>,
    AtOutline: <IonIcon.AtOutline width={'1em'} height={'1em'}/>,
    AtSharp: <IonIcon.AtSharp width={'1em'} height={'1em'}/>,
    At: <IonIcon.At width={'1em'} height={'1em'}/>,
    AttachOutline: <IonIcon.AttachOutline width={'1em'} height={'1em'}/>,
    AttachSharp: <IonIcon.AttachSharp width={'1em'} height={'1em'}/>,
    Attach: <IonIcon.Attach width={'1em'} height={'1em'}/>,
    BackspaceOutline: <IonIcon.BackspaceOutline width={'1em'} height={'1em'}/>,
    BackspaceSharp: <IonIcon.BackspaceSharp width={'1em'} height={'1em'}/>,
    Backspace: <IonIcon.Backspace width={'1em'} height={'1em'}/>,
    BagAddOutline: <IonIcon.BagAddOutline width={'1em'} height={'1em'}/>,
    BagAddSharp: <IonIcon.BagAddSharp width={'1em'} height={'1em'}/>,
    BagAdd: <IonIcon.BagAdd width={'1em'} height={'1em'}/>,
    BagCheckOutline: <IonIcon.BagCheckOutline width={'1em'} height={'1em'}/>,
    BagCheckSharp: <IonIcon.BagCheckSharp width={'1em'} height={'1em'}/>,
    BagCheck: <IonIcon.BagCheck width={'1em'} height={'1em'}/>,
    BagHandleOutline: <IonIcon.BagHandleOutline width={'1em'} height={'1em'}/>,
    BagHandleSharp: <IonIcon.BagHandleSharp width={'1em'} height={'1em'}/>,
    BagHandle: <IonIcon.BagHandle width={'1em'} height={'1em'}/>,
    BagOutline: <IonIcon.BagOutline width={'1em'} height={'1em'}/>,
    BagRemoveOutline: <IonIcon.BagRemoveOutline width={'1em'} height={'1em'}/>,
    BagRemoveSharp: <IonIcon.BagRemoveSharp width={'1em'} height={'1em'}/>,
    BagRemove: <IonIcon.BagRemove width={'1em'} height={'1em'}/>,
    BagSharp: <IonIcon.BagSharp width={'1em'} height={'1em'}/>,
    Bag: <IonIcon.Bag width={'1em'} height={'1em'}/>,
    BanOutline: <IonIcon.BanOutline width={'1em'} height={'1em'}/>,
    BanSharp: <IonIcon.BanSharp width={'1em'} height={'1em'}/>,
    Ban: <IonIcon.Ban width={'1em'} height={'1em'}/>,
    BandageOutline: <IonIcon.BandageOutline width={'1em'} height={'1em'}/>,
    BandageSharp: <IonIcon.BandageSharp width={'1em'} height={'1em'}/>,
    Bandage: <IonIcon.Bandage width={'1em'} height={'1em'}/>,
    BarChartOutline: <IonIcon.BarChartOutline width={'1em'} height={'1em'}/>,
    BarChartSharp: <IonIcon.BarChartSharp width={'1em'} height={'1em'}/>,
    BarChart: <IonIcon.BarChart width={'1em'} height={'1em'}/>,
    BarbellOutline: <IonIcon.BarbellOutline width={'1em'} height={'1em'}/>,
    BarbellSharp: <IonIcon.BarbellSharp width={'1em'} height={'1em'}/>,
    Barbell: <IonIcon.Barbell width={'1em'} height={'1em'}/>,
    BarcodeOutline: <IonIcon.BarcodeOutline width={'1em'} height={'1em'}/>,
    BarcodeSharp: <IonIcon.BarcodeSharp width={'1em'} height={'1em'}/>,
    Barcode: <IonIcon.Barcode width={'1em'} height={'1em'}/>,
    BaseballOutline: <IonIcon.BaseballOutline width={'1em'} height={'1em'}/>,
    BaseballSharp: <IonIcon.BaseballSharp width={'1em'} height={'1em'}/>,
    Baseball: <IonIcon.Baseball width={'1em'} height={'1em'}/>,
    BasketOutline: <IonIcon.BasketOutline width={'1em'} height={'1em'}/>,
    BasketSharp: <IonIcon.BasketSharp width={'1em'} height={'1em'}/>,
    Basket: <IonIcon.Basket width={'1em'} height={'1em'}/>,
    BasketballOutline: <IonIcon.BasketballOutline width={'1em'} height={'1em'}/>,
    BasketballSharp: <IonIcon.BasketballSharp width={'1em'} height={'1em'}/>,
    Basketball: <IonIcon.Basketball width={'1em'} height={'1em'}/>,
    BatteryChargingOutline: <IonIcon.BatteryChargingOutline width={'1em'} height={'1em'}/>,
    BatteryChargingSharp: <IonIcon.BatteryChargingSharp width={'1em'} height={'1em'}/>,
    BatteryCharging: <IonIcon.BatteryCharging width={'1em'} height={'1em'}/>,
    BatteryDeadOutline: <IonIcon.BatteryDeadOutline width={'1em'} height={'1em'}/>,
    BatteryDeadSharp: <IonIcon.BatteryDeadSharp width={'1em'} height={'1em'}/>,
    BatteryDead: <IonIcon.BatteryDead width={'1em'} height={'1em'}/>,
    BatteryFullOutline: <IonIcon.BatteryFullOutline width={'1em'} height={'1em'}/>,
    BatteryFullSharp: <IonIcon.BatteryFullSharp width={'1em'} height={'1em'}/>,
    BatteryFull: <IonIcon.BatteryFull width={'1em'} height={'1em'}/>,
    BatteryHalfOutline: <IonIcon.BatteryHalfOutline width={'1em'} height={'1em'}/>,
    BatteryHalfSharp: <IonIcon.BatteryHalfSharp width={'1em'} height={'1em'}/>,
    BatteryHalf: <IonIcon.BatteryHalf width={'1em'} height={'1em'}/>,
    BeakerOutline: <IonIcon.BeakerOutline width={'1em'} height={'1em'}/>,
    BeakerSharp: <IonIcon.BeakerSharp width={'1em'} height={'1em'}/>,
    Beaker: <IonIcon.Beaker width={'1em'} height={'1em'}/>,
    BedOutline: <IonIcon.BedOutline width={'1em'} height={'1em'}/>,
    BedSharp: <IonIcon.BedSharp width={'1em'} height={'1em'}/>,
    Bed: <IonIcon.Bed width={'1em'} height={'1em'}/>,
    BeerOutline: <IonIcon.BeerOutline width={'1em'} height={'1em'}/>,
    BeerSharp: <IonIcon.BeerSharp width={'1em'} height={'1em'}/>,
    Beer: <IonIcon.Beer width={'1em'} height={'1em'}/>,
    BicycleOutline: <IonIcon.BicycleOutline width={'1em'} height={'1em'}/>,
    BicycleSharp: <IonIcon.BicycleSharp width={'1em'} height={'1em'}/>,
    Bicycle: <IonIcon.Bicycle width={'1em'} height={'1em'}/>,
    BluetoothOutline: <IonIcon.BluetoothOutline width={'1em'} height={'1em'}/>,
    BluetoothSharp: <IonIcon.BluetoothSharp width={'1em'} height={'1em'}/>,
    Bluetooth: <IonIcon.Bluetooth width={'1em'} height={'1em'}/>,
    BoatOutline: <IonIcon.BoatOutline width={'1em'} height={'1em'}/>,
    BoatSharp: <IonIcon.BoatSharp width={'1em'} height={'1em'}/>,
    Boat: <IonIcon.Boat width={'1em'} height={'1em'}/>,
    BodyOutline: <IonIcon.BodyOutline width={'1em'} height={'1em'}/>,
    BodySharp: <IonIcon.BodySharp width={'1em'} height={'1em'}/>,
    Body: <IonIcon.Body width={'1em'} height={'1em'}/>,
    BonfireOutline: <IonIcon.BonfireOutline width={'1em'} height={'1em'}/>,
    BonfireSharp: <IonIcon.BonfireSharp width={'1em'} height={'1em'}/>,
    Bonfire: <IonIcon.Bonfire width={'1em'} height={'1em'}/>,
    BookOutline: <IonIcon.BookOutline width={'1em'} height={'1em'}/>,
    BookSharp: <IonIcon.BookSharp width={'1em'} height={'1em'}/>,
    Book: <IonIcon.Book width={'1em'} height={'1em'}/>,
    BookmarkOutline: <IonIcon.BookmarkOutline width={'1em'} height={'1em'}/>,
    BookmarkSharp: <IonIcon.BookmarkSharp width={'1em'} height={'1em'}/>,
    Bookmark: <IonIcon.Bookmark width={'1em'} height={'1em'}/>,
    BookmarksOutline: <IonIcon.BookmarksOutline width={'1em'} height={'1em'}/>,
    BookmarksSharp: <IonIcon.BookmarksSharp width={'1em'} height={'1em'}/>,
    Bookmarks: <IonIcon.Bookmarks width={'1em'} height={'1em'}/>,
    BriefcaseOutline: <IonIcon.BriefcaseOutline width={'1em'} height={'1em'}/>,
    BriefcaseSharp: <IonIcon.BriefcaseSharp width={'1em'} height={'1em'}/>,
    Briefcase: <IonIcon.Briefcase width={'1em'} height={'1em'}/>,
    BrowsersOutline: <IonIcon.BrowsersOutline width={'1em'} height={'1em'}/>,
    BrowsersSharp: <IonIcon.BrowsersSharp width={'1em'} height={'1em'}/>,
    Browsers: <IonIcon.Browsers width={'1em'} height={'1em'}/>,
    BrushOutline: <IonIcon.BrushOutline width={'1em'} height={'1em'}/>,
    BrushSharp: <IonIcon.BrushSharp width={'1em'} height={'1em'}/>,
    Brush: <IonIcon.Brush width={'1em'} height={'1em'}/>,
    BugOutline: <IonIcon.BugOutline width={'1em'} height={'1em'}/>,
    BugSharp: <IonIcon.BugSharp width={'1em'} height={'1em'}/>,
    Bug: <IonIcon.Bug width={'1em'} height={'1em'}/>,
    BuildOutline: <IonIcon.BuildOutline width={'1em'} height={'1em'}/>,
    BuildSharp: <IonIcon.BuildSharp width={'1em'} height={'1em'}/>,
    Build: <IonIcon.Build width={'1em'} height={'1em'}/>,
    BulbOutline: <IonIcon.BulbOutline width={'1em'} height={'1em'}/>,
    BulbSharp: <IonIcon.BulbSharp width={'1em'} height={'1em'}/>,
    Bulb: <IonIcon.Bulb width={'1em'} height={'1em'}/>,
    BusOutline: <IonIcon.BusOutline width={'1em'} height={'1em'}/>,
    BusSharp: <IonIcon.BusSharp width={'1em'} height={'1em'}/>,
    Bus: <IonIcon.Bus width={'1em'} height={'1em'}/>,
    BusinessOutline: <IonIcon.BusinessOutline width={'1em'} height={'1em'}/>,
    BusinessSharp: <IonIcon.BusinessSharp width={'1em'} height={'1em'}/>,
    Business: <IonIcon.Business width={'1em'} height={'1em'}/>,
    CafeOutline: <IonIcon.CafeOutline width={'1em'} height={'1em'}/>,
    CafeSharp: <IonIcon.CafeSharp width={'1em'} height={'1em'}/>,
    Cafe: <IonIcon.Cafe width={'1em'} height={'1em'}/>,
    CalculatorOutline: <IonIcon.CalculatorOutline width={'1em'} height={'1em'}/>,
    CalculatorSharp: <IonIcon.CalculatorSharp width={'1em'} height={'1em'}/>,
    Calculator: <IonIcon.Calculator width={'1em'} height={'1em'}/>,
    CalendarClearOutline: <IonIcon.CalendarClearOutline width={'1em'} height={'1em'}/>,
    CalendarClearSharp: <IonIcon.CalendarClearSharp width={'1em'} height={'1em'}/>,
    CalendarClear: <IonIcon.CalendarClear width={'1em'} height={'1em'}/>,
    CalendarOutline: <IonIcon.CalendarOutline width={'1em'} height={'1em'}/>,
    CalendarSharp: <IonIcon.CalendarSharp width={'1em'} height={'1em'}/>,
    Calendar: <IonIcon.Calendar width={'1em'} height={'1em'}/>,
    CallOutline: <IonIcon.CallOutline width={'1em'} height={'1em'}/>,
    CallSharp: <IonIcon.CallSharp width={'1em'} height={'1em'}/>,
    Call: <IonIcon.Call width={'1em'} height={'1em'}/>,
    CameraOutline: <IonIcon.CameraOutline width={'1em'} height={'1em'}/>,
    CameraReverseOutline: <IonIcon.CameraReverseOutline width={'1em'} height={'1em'}/>,
    CameraReverseSharp: <IonIcon.CameraReverseSharp width={'1em'} height={'1em'}/>,
    CameraReverse: <IonIcon.CameraReverse width={'1em'} height={'1em'}/>,
    CameraSharp: <IonIcon.CameraSharp width={'1em'} height={'1em'}/>,
    Camera: <IonIcon.Camera width={'1em'} height={'1em'}/>,
    CarOutline: <IonIcon.CarOutline width={'1em'} height={'1em'}/>,
    CarSharp: <IonIcon.CarSharp width={'1em'} height={'1em'}/>,
    CarSportOutline: <IonIcon.CarSportOutline width={'1em'} height={'1em'}/>,
    CarSportSharp: <IonIcon.CarSportSharp width={'1em'} height={'1em'}/>,
    CarSport: <IonIcon.CarSport width={'1em'} height={'1em'}/>,
    Car: <IonIcon.Car width={'1em'} height={'1em'}/>,
    CardOutline: <IonIcon.CardOutline width={'1em'} height={'1em'}/>,
    CardSharp: <IonIcon.CardSharp width={'1em'} height={'1em'}/>,
    Card: <IonIcon.Card width={'1em'} height={'1em'}/>,
    CaretBackCircleOutline: <IonIcon.CaretBackCircleOutline width={'1em'} height={'1em'}/>,
    CaretBackCircleSharp: <IonIcon.CaretBackCircleSharp width={'1em'} height={'1em'}/>,
    CaretBackCircle: <IonIcon.CaretBackCircle width={'1em'} height={'1em'}/>,
    CaretBackOutline: <IonIcon.CaretBackOutline width={'1em'} height={'1em'}/>,
    CaretBackSharp: <IonIcon.CaretBackSharp width={'1em'} height={'1em'}/>,
    CaretBack: <IonIcon.CaretBack width={'1em'} height={'1em'}/>,
    CaretDownCircleOutline: <IonIcon.CaretDownCircleOutline width={'1em'} height={'1em'}/>,
    CaretDownCircleSharp: <IonIcon.CaretDownCircleSharp width={'1em'} height={'1em'}/>,
    CaretDownCircle: <IonIcon.CaretDownCircle width={'1em'} height={'1em'}/>,
    CaretDownOutline: <IonIcon.CaretDownOutline width={'1em'} height={'1em'}/>,
    CaretDownSharp: <IonIcon.CaretDownSharp width={'1em'} height={'1em'}/>,
    CaretDown: <IonIcon.CaretDown width={'1em'} height={'1em'}/>,
    CaretForwardCircleOutline: <IonIcon.CaretForwardCircleOutline width={'1em'} height={'1em'}/>,
    CaretForwardCircleSharp: <IonIcon.CaretForwardCircleSharp width={'1em'} height={'1em'}/>,
    CaretForwardCircle: <IonIcon.CaretForwardCircle width={'1em'} height={'1em'}/>,
    CaretForwardOutline: <IonIcon.CaretForwardOutline width={'1em'} height={'1em'}/>,
    CaretForwardSharp: <IonIcon.CaretForwardSharp width={'1em'} height={'1em'}/>,
    CaretForward: <IonIcon.CaretForward width={'1em'} height={'1em'}/>,
    CaretUpCircleOutline: <IonIcon.CaretUpCircleOutline width={'1em'} height={'1em'}/>,
    CaretUpCircleSharp: <IonIcon.CaretUpCircleSharp width={'1em'} height={'1em'}/>,
    CaretUpCircle: <IonIcon.CaretUpCircle width={'1em'} height={'1em'}/>,
    CaretUpOutline: <IonIcon.CaretUpOutline width={'1em'} height={'1em'}/>,
    CaretUpSharp: <IonIcon.CaretUpSharp width={'1em'} height={'1em'}/>,
    CaretUp: <IonIcon.CaretUp width={'1em'} height={'1em'}/>,
    CartOutline: <IonIcon.CartOutline width={'1em'} height={'1em'}/>,
    CartSharp: <IonIcon.CartSharp width={'1em'} height={'1em'}/>,
    Cart: <IonIcon.Cart width={'1em'} height={'1em'}/>,
    CashOutline: <IonIcon.CashOutline width={'1em'} height={'1em'}/>,
    CashSharp: <IonIcon.CashSharp width={'1em'} height={'1em'}/>,
    Cash: <IonIcon.Cash width={'1em'} height={'1em'}/>,
    CellularOutline: <IonIcon.CellularOutline width={'1em'} height={'1em'}/>,
    CellularSharp: <IonIcon.CellularSharp width={'1em'} height={'1em'}/>,
    Cellular: <IonIcon.Cellular width={'1em'} height={'1em'}/>,
    ChatboxEllipsesOutline: <IonIcon.ChatboxEllipsesOutline width={'1em'} height={'1em'}/>,
    ChatboxEllipsesSharp: <IonIcon.ChatboxEllipsesSharp width={'1em'} height={'1em'}/>,
    ChatboxEllipses: <IonIcon.ChatboxEllipses width={'1em'} height={'1em'}/>,
    ChatboxOutline: <IonIcon.ChatboxOutline width={'1em'} height={'1em'}/>,
    ChatboxSharp: <IonIcon.ChatboxSharp width={'1em'} height={'1em'}/>,
    Chatbox: <IonIcon.Chatbox width={'1em'} height={'1em'}/>,
    ChatbubbleEllipsesOutline: <IonIcon.ChatbubbleEllipsesOutline width={'1em'} height={'1em'}/>,
    ChatbubbleEllipsesSharp: <IonIcon.ChatbubbleEllipsesSharp width={'1em'} height={'1em'}/>,
    ChatbubbleEllipses: <IonIcon.ChatbubbleEllipses width={'1em'} height={'1em'}/>,
    ChatbubbleOutline: <IonIcon.ChatbubbleOutline width={'1em'} height={'1em'}/>,
    ChatbubbleSharp: <IonIcon.ChatbubbleSharp width={'1em'} height={'1em'}/>,
    Chatbubble: <IonIcon.Chatbubble width={'1em'} height={'1em'}/>,
    ChatbubblesOutline: <IonIcon.ChatbubblesOutline width={'1em'} height={'1em'}/>,
    ChatbubblesSharp: <IonIcon.ChatbubblesSharp width={'1em'} height={'1em'}/>,
    Chatbubbles: <IonIcon.Chatbubbles width={'1em'} height={'1em'}/>,
    CheckboxOutline: <IonIcon.CheckboxOutline width={'1em'} height={'1em'}/>,
    CheckboxSharp: <IonIcon.CheckboxSharp width={'1em'} height={'1em'}/>,
    Checkbox: <IonIcon.Checkbox width={'1em'} height={'1em'}/>,
    CheckmarkCircleOutline: <IonIcon.CheckmarkCircleOutline width={'1em'} height={'1em'}/>,
    CheckmarkCircleSharp: <IonIcon.CheckmarkCircleSharp width={'1em'} height={'1em'}/>,
    CheckmarkCircle: <IonIcon.CheckmarkCircle width={'1em'} height={'1em'}/>,
    CheckmarkDoneCircleOutline: <IonIcon.CheckmarkDoneCircleOutline width={'1em'} height={'1em'}/>,
    CheckmarkDoneCircleSharp: <IonIcon.CheckmarkDoneCircleSharp width={'1em'} height={'1em'}/>,
    CheckmarkDoneCircle: <IonIcon.CheckmarkDoneCircle width={'1em'} height={'1em'}/>,
    CheckmarkDoneOutline: <IonIcon.CheckmarkDoneOutline width={'1em'} height={'1em'}/>,
    CheckmarkDoneSharp: <IonIcon.CheckmarkDoneSharp width={'1em'} height={'1em'}/>,
    CheckmarkDone: <IonIcon.CheckmarkDone width={'1em'} height={'1em'}/>,
    CheckmarkOutline: <IonIcon.CheckmarkOutline width={'1em'} height={'1em'}/>,
    CheckmarkSharp: <IonIcon.CheckmarkSharp width={'1em'} height={'1em'}/>,
    Checkmark: <IonIcon.Checkmark width={'1em'} height={'1em'}/>,
    ChevronBackCircleOutline: <IonIcon.ChevronBackCircleOutline width={'1em'} height={'1em'}/>,
    ChevronBackCircleSharp: <IonIcon.ChevronBackCircleSharp width={'1em'} height={'1em'}/>,
    ChevronBackCircle: <IonIcon.ChevronBackCircle width={'1em'} height={'1em'}/>,
    ChevronBackOutline: <IonIcon.ChevronBackOutline width={'1em'} height={'1em'}/>,
    ChevronBackSharp: <IonIcon.ChevronBackSharp width={'1em'} height={'1em'}/>,
    ChevronBack: <IonIcon.ChevronBack width={'1em'} height={'1em'}/>,
    ChevronDownCircleOutline: <IonIcon.ChevronDownCircleOutline width={'1em'} height={'1em'}/>,
    ChevronDownCircleSharp: <IonIcon.ChevronDownCircleSharp width={'1em'} height={'1em'}/>,
    ChevronDownCircle: <IonIcon.ChevronDownCircle width={'1em'} height={'1em'}/>,
    ChevronDownOutline: <IonIcon.ChevronDownOutline width={'1em'} height={'1em'}/>,
    ChevronDownSharp: <IonIcon.ChevronDownSharp width={'1em'} height={'1em'}/>,
    ChevronDown: <IonIcon.ChevronDown width={'1em'} height={'1em'}/>,
    ChevronForwardCircleOutline: <IonIcon.ChevronForwardCircleOutline width={'1em'} height={'1em'}/>,
    ChevronForwardCircleSharp: <IonIcon.ChevronForwardCircleSharp width={'1em'} height={'1em'}/>,
    ChevronForwardCircle: <IonIcon.ChevronForwardCircle width={'1em'} height={'1em'}/>,
    ChevronForwardOutline: <IonIcon.ChevronForwardOutline width={'1em'} height={'1em'}/>,
    ChevronForwardSharp: <IonIcon.ChevronForwardSharp width={'1em'} height={'1em'}/>,
    ChevronForward: <IonIcon.ChevronForward width={'1em'} height={'1em'}/>,
    ChevronUpCircleOutline: <IonIcon.ChevronUpCircleOutline width={'1em'} height={'1em'}/>,
    ChevronUpCircleSharp: <IonIcon.ChevronUpCircleSharp width={'1em'} height={'1em'}/>,
    ChevronUpCircle: <IonIcon.ChevronUpCircle width={'1em'} height={'1em'}/>,
    ChevronUpOutline: <IonIcon.ChevronUpOutline width={'1em'} height={'1em'}/>,
    ChevronUpSharp: <IonIcon.ChevronUpSharp width={'1em'} height={'1em'}/>,
    ChevronUp: <IonIcon.ChevronUp width={'1em'} height={'1em'}/>,
    ClipboardOutline: <IonIcon.ClipboardOutline width={'1em'} height={'1em'}/>,
    ClipboardSharp: <IonIcon.ClipboardSharp width={'1em'} height={'1em'}/>,
    Clipboard: <IonIcon.Clipboard width={'1em'} height={'1em'}/>,
    CloseCircleOutline: <IonIcon.CloseCircleOutline width={'1em'} height={'1em'}/>,
    CloseCircleSharp: <IonIcon.CloseCircleSharp width={'1em'} height={'1em'}/>,
    CloseCircle: <IonIcon.CloseCircle width={'1em'} height={'1em'}/>,
    CloseOutline: <IonIcon.CloseOutline width={'1em'} height={'1em'}/>,
    CloseSharp: <IonIcon.CloseSharp width={'1em'} height={'1em'}/>,
    Close: <IonIcon.Close width={'1em'} height={'1em'}/>,
    CloudCircleOutline: <IonIcon.CloudCircleOutline width={'1em'} height={'1em'}/>,
    CloudCircleSharp: <IonIcon.CloudCircleSharp width={'1em'} height={'1em'}/>,
    CloudCircle: <IonIcon.CloudCircle width={'1em'} height={'1em'}/>,
    CloudDoneOutline: <IonIcon.CloudDoneOutline width={'1em'} height={'1em'}/>,
    CloudDoneSharp: <IonIcon.CloudDoneSharp width={'1em'} height={'1em'}/>,
    CloudDone: <IonIcon.CloudDone width={'1em'} height={'1em'}/>,
    CloudDownloadOutline: <IonIcon.CloudDownloadOutline width={'1em'} height={'1em'}/>,
    CloudDownloadSharp: <IonIcon.CloudDownloadSharp width={'1em'} height={'1em'}/>,
    CloudDownload: <IonIcon.CloudDownload width={'1em'} height={'1em'}/>,
    CloudOfflineOutline: <IonIcon.CloudOfflineOutline width={'1em'} height={'1em'}/>,
    CloudOfflineSharp: <IonIcon.CloudOfflineSharp width={'1em'} height={'1em'}/>,
    CloudOffline: <IonIcon.CloudOffline width={'1em'} height={'1em'}/>,
    CloudOutline: <IonIcon.CloudOutline width={'1em'} height={'1em'}/>,
    CloudSharp: <IonIcon.CloudSharp width={'1em'} height={'1em'}/>,
    CloudUploadOutline: <IonIcon.CloudUploadOutline width={'1em'} height={'1em'}/>,
    CloudUploadSharp: <IonIcon.CloudUploadSharp width={'1em'} height={'1em'}/>,
    CloudUpload: <IonIcon.CloudUpload width={'1em'} height={'1em'}/>,
    Cloud: <IonIcon.Cloud width={'1em'} height={'1em'}/>,
    CloudyNightOutline: <IonIcon.CloudyNightOutline width={'1em'} height={'1em'}/>,
    CloudyNightSharp: <IonIcon.CloudyNightSharp width={'1em'} height={'1em'}/>,
    CloudyNight: <IonIcon.CloudyNight width={'1em'} height={'1em'}/>,
    CloudyOutline: <IonIcon.CloudyOutline width={'1em'} height={'1em'}/>,
    CloudySharp: <IonIcon.CloudySharp width={'1em'} height={'1em'}/>,
    Cloudy: <IonIcon.Cloudy width={'1em'} height={'1em'}/>,
    CodeDownloadOutline: <IonIcon.CodeDownloadOutline width={'1em'} height={'1em'}/>,
    CodeDownloadSharp: <IonIcon.CodeDownloadSharp width={'1em'} height={'1em'}/>,
    CodeDownload: <IonIcon.CodeDownload width={'1em'} height={'1em'}/>,
    CodeOutline: <IonIcon.CodeOutline width={'1em'} height={'1em'}/>,
    CodeSharp: <IonIcon.CodeSharp width={'1em'} height={'1em'}/>,
    CodeSlashOutline: <IonIcon.CodeSlashOutline width={'1em'} height={'1em'}/>,
    CodeSlashSharp: <IonIcon.CodeSlashSharp width={'1em'} height={'1em'}/>,
    CodeSlash: <IonIcon.CodeSlash width={'1em'} height={'1em'}/>,
    CodeWorkingOutline: <IonIcon.CodeWorkingOutline width={'1em'} height={'1em'}/>,
    CodeWorkingSharp: <IonIcon.CodeWorkingSharp width={'1em'} height={'1em'}/>,
    CodeWorking: <IonIcon.CodeWorking width={'1em'} height={'1em'}/>,
    Code: <IonIcon.Code width={'1em'} height={'1em'}/>,
    CogOutline: <IonIcon.CogOutline width={'1em'} height={'1em'}/>,
    CogSharp: <IonIcon.CogSharp width={'1em'} height={'1em'}/>,
    Cog: <IonIcon.Cog width={'1em'} height={'1em'}/>,
    ColorFillOutline: <IonIcon.ColorFillOutline width={'1em'} height={'1em'}/>,
    ColorFillSharp: <IonIcon.ColorFillSharp width={'1em'} height={'1em'}/>,
    ColorFill: <IonIcon.ColorFill width={'1em'} height={'1em'}/>,
    ColorFilterOutline: <IonIcon.ColorFilterOutline width={'1em'} height={'1em'}/>,
    ColorFilterSharp: <IonIcon.ColorFilterSharp width={'1em'} height={'1em'}/>,
    ColorFilter: <IonIcon.ColorFilter width={'1em'} height={'1em'}/>,
    ColorPaletteOutline: <IonIcon.ColorPaletteOutline width={'1em'} height={'1em'}/>,
    ColorPaletteSharp: <IonIcon.ColorPaletteSharp width={'1em'} height={'1em'}/>,
    ColorPalette: <IonIcon.ColorPalette width={'1em'} height={'1em'}/>,
    ColorWandOutline: <IonIcon.ColorWandOutline width={'1em'} height={'1em'}/>,
    ColorWandSharp: <IonIcon.ColorWandSharp width={'1em'} height={'1em'}/>,
    ColorWand: <IonIcon.ColorWand width={'1em'} height={'1em'}/>,
    CompassOutline: <IonIcon.CompassOutline width={'1em'} height={'1em'}/>,
    CompassSharp: <IonIcon.CompassSharp width={'1em'} height={'1em'}/>,
    Compass: <IonIcon.Compass width={'1em'} height={'1em'}/>,
    ConstructOutline: <IonIcon.ConstructOutline width={'1em'} height={'1em'}/>,
    ConstructSharp: <IonIcon.ConstructSharp width={'1em'} height={'1em'}/>,
    Construct: <IonIcon.Construct width={'1em'} height={'1em'}/>,
    ContractOutline: <IonIcon.ContractOutline width={'1em'} height={'1em'}/>,
    ContractSharp: <IonIcon.ContractSharp width={'1em'} height={'1em'}/>,
    Contract: <IonIcon.Contract width={'1em'} height={'1em'}/>,
    ContrastOutline: <IonIcon.ContrastOutline width={'1em'} height={'1em'}/>,
    ContrastSharp: <IonIcon.ContrastSharp width={'1em'} height={'1em'}/>,
    Contrast: <IonIcon.Contrast width={'1em'} height={'1em'}/>,
    CopyOutline: <IonIcon.CopyOutline width={'1em'} height={'1em'}/>,
    CopySharp: <IonIcon.CopySharp width={'1em'} height={'1em'}/>,
    Copy: <IonIcon.Copy width={'1em'} height={'1em'}/>,
    CreateOutline: <IonIcon.CreateOutline width={'1em'} height={'1em'}/>,
    CreateSharp: <IonIcon.CreateSharp width={'1em'} height={'1em'}/>,
    Create: <IonIcon.Create width={'1em'} height={'1em'}/>,
    CropOutline: <IonIcon.CropOutline width={'1em'} height={'1em'}/>,
    CropSharp: <IonIcon.CropSharp width={'1em'} height={'1em'}/>,
    Crop: <IonIcon.Crop width={'1em'} height={'1em'}/>,
    CubeOutline: <IonIcon.CubeOutline width={'1em'} height={'1em'}/>,
    CubeSharp: <IonIcon.CubeSharp width={'1em'} height={'1em'}/>,
    Cube: <IonIcon.Cube width={'1em'} height={'1em'}/>,
    CutOutline: <IonIcon.CutOutline width={'1em'} height={'1em'}/>,
    CutSharp: <IonIcon.CutSharp width={'1em'} height={'1em'}/>,
    Cut: <IonIcon.Cut width={'1em'} height={'1em'}/>,
    DesktopOutline: <IonIcon.DesktopOutline width={'1em'} height={'1em'}/>,
    DesktopSharp: <IonIcon.DesktopSharp width={'1em'} height={'1em'}/>,
    Desktop: <IonIcon.Desktop width={'1em'} height={'1em'}/>,
    DiceOutline: <IonIcon.DiceOutline width={'1em'} height={'1em'}/>,
    DiceSharp: <IonIcon.DiceSharp width={'1em'} height={'1em'}/>,
    Dice: <IonIcon.Dice width={'1em'} height={'1em'}/>,
    DiscOutline: <IonIcon.DiscOutline width={'1em'} height={'1em'}/>,
    DiscSharp: <IonIcon.DiscSharp width={'1em'} height={'1em'}/>,
    Disc: <IonIcon.Disc width={'1em'} height={'1em'}/>,
    DocumentAttachOutline: <IonIcon.DocumentAttachOutline width={'1em'} height={'1em'}/>,
    DocumentAttachSharp: <IonIcon.DocumentAttachSharp width={'1em'} height={'1em'}/>,
    DocumentAttach: <IonIcon.DocumentAttach width={'1em'} height={'1em'}/>,
    DocumentLockOutline: <IonIcon.DocumentLockOutline width={'1em'} height={'1em'}/>,
    DocumentLockSharp: <IonIcon.DocumentLockSharp width={'1em'} height={'1em'}/>,
    DocumentLock: <IonIcon.DocumentLock width={'1em'} height={'1em'}/>,
    DocumentOutline: <IonIcon.DocumentOutline width={'1em'} height={'1em'}/>,
    DocumentSharp: <IonIcon.DocumentSharp width={'1em'} height={'1em'}/>,
    DocumentTextOutline: <IonIcon.DocumentTextOutline width={'1em'} height={'1em'}/>,
    DocumentTextSharp: <IonIcon.DocumentTextSharp width={'1em'} height={'1em'}/>,
    DocumentText: <IonIcon.DocumentText width={'1em'} height={'1em'}/>,
    Document: <IonIcon.Document width={'1em'} height={'1em'}/>,
    DocumentsOutline: <IonIcon.DocumentsOutline width={'1em'} height={'1em'}/>,
    DocumentsSharp: <IonIcon.DocumentsSharp width={'1em'} height={'1em'}/>,
    Documents: <IonIcon.Documents width={'1em'} height={'1em'}/>,
    DownloadOutline: <IonIcon.DownloadOutline width={'1em'} height={'1em'}/>,
    DownloadSharp: <IonIcon.DownloadSharp width={'1em'} height={'1em'}/>,
    Download: <IonIcon.Download width={'1em'} height={'1em'}/>,
    DuplicateOutline: <IonIcon.DuplicateOutline width={'1em'} height={'1em'}/>,
    DuplicateSharp: <IonIcon.DuplicateSharp width={'1em'} height={'1em'}/>,
    Duplicate: <IonIcon.Duplicate width={'1em'} height={'1em'}/>,
    EarOutline: <IonIcon.EarOutline width={'1em'} height={'1em'}/>,
    EarSharp: <IonIcon.EarSharp width={'1em'} height={'1em'}/>,
    Ear: <IonIcon.Ear width={'1em'} height={'1em'}/>,
    EarthOutline: <IonIcon.EarthOutline width={'1em'} height={'1em'}/>,
    EarthSharp: <IonIcon.EarthSharp width={'1em'} height={'1em'}/>,
    Earth: <IonIcon.Earth width={'1em'} height={'1em'}/>,
    EaselOutline: <IonIcon.EaselOutline width={'1em'} height={'1em'}/>,
    EaselSharp: <IonIcon.EaselSharp width={'1em'} height={'1em'}/>,
    Easel: <IonIcon.Easel width={'1em'} height={'1em'}/>,
    EggOutline: <IonIcon.EggOutline width={'1em'} height={'1em'}/>,
    EggSharp: <IonIcon.EggSharp width={'1em'} height={'1em'}/>,
    Egg: <IonIcon.Egg width={'1em'} height={'1em'}/>,
    EllipseOutline: <IonIcon.EllipseOutline width={'1em'} height={'1em'}/>,
    EllipseSharp: <IonIcon.EllipseSharp width={'1em'} height={'1em'}/>,
    Ellipse: <IonIcon.Ellipse width={'1em'} height={'1em'}/>,
    EllipsisHorizontalCircleOutline: <IonIcon.EllipsisHorizontalCircleOutline width={'1em'} height={'1em'}/>,
    EllipsisHorizontalCircleSharp: <IonIcon.EllipsisHorizontalCircleSharp width={'1em'} height={'1em'}/>,
    EllipsisHorizontalCircle: <IonIcon.EllipsisHorizontalCircle width={'1em'} height={'1em'}/>,
    EllipsisHorizontalOutline: <IonIcon.EllipsisHorizontalOutline width={'1em'} height={'1em'}/>,
    EllipsisHorizontalSharp: <IonIcon.EllipsisHorizontalSharp width={'1em'} height={'1em'}/>,
    EllipsisHorizontal: <IonIcon.EllipsisHorizontal width={'1em'} height={'1em'}/>,
    EllipsisVerticalCircleOutline: <IonIcon.EllipsisVerticalCircleOutline width={'1em'} height={'1em'}/>,
    EllipsisVerticalCircleSharp: <IonIcon.EllipsisVerticalCircleSharp width={'1em'} height={'1em'}/>,
    EllipsisVerticalCircle: <IonIcon.EllipsisVerticalCircle width={'1em'} height={'1em'}/>,
    EllipsisVerticalOutline: <IonIcon.EllipsisVerticalOutline width={'1em'} height={'1em'}/>,
    EllipsisVerticalSharp: <IonIcon.EllipsisVerticalSharp width={'1em'} height={'1em'}/>,
    EllipsisVertical: <IonIcon.EllipsisVertical width={'1em'} height={'1em'}/>,
    EnterOutline: <IonIcon.EnterOutline width={'1em'} height={'1em'}/>,
    EnterSharp: <IonIcon.EnterSharp width={'1em'} height={'1em'}/>,
    Enter: <IonIcon.Enter width={'1em'} height={'1em'}/>,
    ExitOutline: <IonIcon.ExitOutline width={'1em'} height={'1em'}/>,
    ExitSharp: <IonIcon.ExitSharp width={'1em'} height={'1em'}/>,
    Exit: <IonIcon.Exit width={'1em'} height={'1em'}/>,
    ExpandOutline: <IonIcon.ExpandOutline width={'1em'} height={'1em'}/>,
    ExpandSharp: <IonIcon.ExpandSharp width={'1em'} height={'1em'}/>,
    Expand: <IonIcon.Expand width={'1em'} height={'1em'}/>,
    ExtensionPuzzleOutline: <IonIcon.ExtensionPuzzleOutline width={'1em'} height={'1em'}/>,
    ExtensionPuzzleSharp: <IonIcon.ExtensionPuzzleSharp width={'1em'} height={'1em'}/>,
    ExtensionPuzzle: <IonIcon.ExtensionPuzzle width={'1em'} height={'1em'}/>,
    EyeOffOutline: <IonIcon.EyeOffOutline width={'1em'} height={'1em'}/>,
    EyeOffSharp: <IonIcon.EyeOffSharp width={'1em'} height={'1em'}/>,
    EyeOff: <IonIcon.EyeOff width={'1em'} height={'1em'}/>,
    EyeOutline: <IonIcon.EyeOutline width={'1em'} height={'1em'}/>,
    EyeSharp: <IonIcon.EyeSharp width={'1em'} height={'1em'}/>,
    Eye: <IonIcon.Eye width={'1em'} height={'1em'}/>,
    EyedropOutline: <IonIcon.EyedropOutline width={'1em'} height={'1em'}/>,
    EyedropSharp: <IonIcon.EyedropSharp width={'1em'} height={'1em'}/>,
    Eyedrop: <IonIcon.Eyedrop width={'1em'} height={'1em'}/>,
    FastFoodOutline: <IonIcon.FastFoodOutline width={'1em'} height={'1em'}/>,
    FastFoodSharp: <IonIcon.FastFoodSharp width={'1em'} height={'1em'}/>,
    FastFood: <IonIcon.FastFood width={'1em'} height={'1em'}/>,
    FemaleOutline: <IonIcon.FemaleOutline width={'1em'} height={'1em'}/>,
    FemaleSharp: <IonIcon.FemaleSharp width={'1em'} height={'1em'}/>,
    Female: <IonIcon.Female width={'1em'} height={'1em'}/>,
    FileTrayFullOutline: <IonIcon.FileTrayFullOutline width={'1em'} height={'1em'}/>,
    FileTrayFullSharp: <IonIcon.FileTrayFullSharp width={'1em'} height={'1em'}/>,
    FileTrayFull: <IonIcon.FileTrayFull width={'1em'} height={'1em'}/>,
    FileTrayOutline: <IonIcon.FileTrayOutline width={'1em'} height={'1em'}/>,
    FileTraySharp: <IonIcon.FileTraySharp width={'1em'} height={'1em'}/>,
    FileTrayStackedOutline: <IonIcon.FileTrayStackedOutline width={'1em'} height={'1em'}/>,
    FileTrayStackedSharp: <IonIcon.FileTrayStackedSharp width={'1em'} height={'1em'}/>,
    FileTrayStacked: <IonIcon.FileTrayStacked width={'1em'} height={'1em'}/>,
    FileTray: <IonIcon.FileTray width={'1em'} height={'1em'}/>,
    FilmOutline: <IonIcon.FilmOutline width={'1em'} height={'1em'}/>,
    FilmSharp: <IonIcon.FilmSharp width={'1em'} height={'1em'}/>,
    Film: <IonIcon.Film width={'1em'} height={'1em'}/>,
    FilterCircleOutline: <IonIcon.FilterCircleOutline width={'1em'} height={'1em'}/>,
    FilterCircleSharp: <IonIcon.FilterCircleSharp width={'1em'} height={'1em'}/>,
    FilterCircle: <IonIcon.FilterCircle width={'1em'} height={'1em'}/>,
    FilterOutline: <IonIcon.FilterOutline width={'1em'} height={'1em'}/>,
    FilterSharp: <IonIcon.FilterSharp width={'1em'} height={'1em'}/>,
    Filter: <IonIcon.Filter width={'1em'} height={'1em'}/>,
    FingerPrintOutline: <IonIcon.FingerPrintOutline width={'1em'} height={'1em'}/>,
    FingerPrintSharp: <IonIcon.FingerPrintSharp width={'1em'} height={'1em'}/>,
    FingerPrint: <IonIcon.FingerPrint width={'1em'} height={'1em'}/>,
    FishOutline: <IonIcon.FishOutline width={'1em'} height={'1em'}/>,
    FishSharp: <IonIcon.FishSharp width={'1em'} height={'1em'}/>,
    Fish: <IonIcon.Fish width={'1em'} height={'1em'}/>,
    FitnessOutline: <IonIcon.FitnessOutline width={'1em'} height={'1em'}/>,
    FitnessSharp: <IonIcon.FitnessSharp width={'1em'} height={'1em'}/>,
    Fitness: <IonIcon.Fitness width={'1em'} height={'1em'}/>,
    FlagOutline: <IonIcon.FlagOutline width={'1em'} height={'1em'}/>,
    FlagSharp: <IonIcon.FlagSharp width={'1em'} height={'1em'}/>,
    Flag: <IonIcon.Flag width={'1em'} height={'1em'}/>,
    FlameOutline: <IonIcon.FlameOutline width={'1em'} height={'1em'}/>,
    FlameSharp: <IonIcon.FlameSharp width={'1em'} height={'1em'}/>,
    Flame: <IonIcon.Flame width={'1em'} height={'1em'}/>,
    FlashOffOutline: <IonIcon.FlashOffOutline width={'1em'} height={'1em'}/>,
    FlashOffSharp: <IonIcon.FlashOffSharp width={'1em'} height={'1em'}/>,
    FlashOff: <IonIcon.FlashOff width={'1em'} height={'1em'}/>,
    FlashOutline: <IonIcon.FlashOutline width={'1em'} height={'1em'}/>,
    FlashSharp: <IonIcon.FlashSharp width={'1em'} height={'1em'}/>,
    Flash: <IonIcon.Flash width={'1em'} height={'1em'}/>,
    FlashlightOutline: <IonIcon.FlashlightOutline width={'1em'} height={'1em'}/>,
    FlashlightSharp: <IonIcon.FlashlightSharp width={'1em'} height={'1em'}/>,
    Flashlight: <IonIcon.Flashlight width={'1em'} height={'1em'}/>,
    FlaskOutline: <IonIcon.FlaskOutline width={'1em'} height={'1em'}/>,
    FlaskSharp: <IonIcon.FlaskSharp width={'1em'} height={'1em'}/>,
    Flask: <IonIcon.Flask width={'1em'} height={'1em'}/>,
    FlowerOutline: <IonIcon.FlowerOutline width={'1em'} height={'1em'}/>,
    FlowerSharp: <IonIcon.FlowerSharp width={'1em'} height={'1em'}/>,
    Flower: <IonIcon.Flower width={'1em'} height={'1em'}/>,
    FolderOpenOutline: <IonIcon.FolderOpenOutline width={'1em'} height={'1em'}/>,
    FolderOpenSharp: <IonIcon.FolderOpenSharp width={'1em'} height={'1em'}/>,
    FolderOpen: <IonIcon.FolderOpen width={'1em'} height={'1em'}/>,
    FolderOutline: <IonIcon.FolderOutline width={'1em'} height={'1em'}/>,
    FolderSharp: <IonIcon.FolderSharp width={'1em'} height={'1em'}/>,
    Folder: <IonIcon.Folder width={'1em'} height={'1em'}/>,
    FootballOutline: <IonIcon.FootballOutline width={'1em'} height={'1em'}/>,
    FootballSharp: <IonIcon.FootballSharp width={'1em'} height={'1em'}/>,
    Football: <IonIcon.Football width={'1em'} height={'1em'}/>,
    FunnelOutline: <IonIcon.FunnelOutline width={'1em'} height={'1em'}/>,
    FunnelSharp: <IonIcon.FunnelSharp width={'1em'} height={'1em'}/>,
    Funnel: <IonIcon.Funnel width={'1em'} height={'1em'}/>,
    GameControllerOutline: <IonIcon.GameControllerOutline width={'1em'} height={'1em'}/>,
    GameControllerSharp: <IonIcon.GameControllerSharp width={'1em'} height={'1em'}/>,
    GameController: <IonIcon.GameController width={'1em'} height={'1em'}/>,
    GiftOutline: <IonIcon.GiftOutline width={'1em'} height={'1em'}/>,
    GiftSharp: <IonIcon.GiftSharp width={'1em'} height={'1em'}/>,
    Gift: <IonIcon.Gift width={'1em'} height={'1em'}/>,
    GitBranchOutline: <IonIcon.GitBranchOutline width={'1em'} height={'1em'}/>,
    GitBranchSharp: <IonIcon.GitBranchSharp width={'1em'} height={'1em'}/>,
    GitBranch: <IonIcon.GitBranch width={'1em'} height={'1em'}/>,
    GitCommitOutline: <IonIcon.GitCommitOutline width={'1em'} height={'1em'}/>,
    GitCommitSharp: <IonIcon.GitCommitSharp width={'1em'} height={'1em'}/>,
    GitCommit: <IonIcon.GitCommit width={'1em'} height={'1em'}/>,
    GitCompareOutline: <IonIcon.GitCompareOutline width={'1em'} height={'1em'}/>,
    GitCompareSharp: <IonIcon.GitCompareSharp width={'1em'} height={'1em'}/>,
    GitCompare: <IonIcon.GitCompare width={'1em'} height={'1em'}/>,
    GitMergeOutline: <IonIcon.GitMergeOutline width={'1em'} height={'1em'}/>,
    GitMergeSharp: <IonIcon.GitMergeSharp width={'1em'} height={'1em'}/>,
    GitMerge: <IonIcon.GitMerge width={'1em'} height={'1em'}/>,
    GitNetworkOutline: <IonIcon.GitNetworkOutline width={'1em'} height={'1em'}/>,
    GitNetworkSharp: <IonIcon.GitNetworkSharp width={'1em'} height={'1em'}/>,
    GitNetwork: <IonIcon.GitNetwork width={'1em'} height={'1em'}/>,
    GitPullRequestOutline: <IonIcon.GitPullRequestOutline width={'1em'} height={'1em'}/>,
    GitPullRequestSharp: <IonIcon.GitPullRequestSharp width={'1em'} height={'1em'}/>,
    GitPullRequest: <IonIcon.GitPullRequest width={'1em'} height={'1em'}/>,
    GlassesOutline: <IonIcon.GlassesOutline width={'1em'} height={'1em'}/>,
    GlassesSharp: <IonIcon.GlassesSharp width={'1em'} height={'1em'}/>,
    Glasses: <IonIcon.Glasses width={'1em'} height={'1em'}/>,
    GlobeOutline: <IonIcon.GlobeOutline width={'1em'} height={'1em'}/>,
    GlobeSharp: <IonIcon.GlobeSharp width={'1em'} height={'1em'}/>,
    Globe: <IonIcon.Globe width={'1em'} height={'1em'}/>,
    GolfOutline: <IonIcon.GolfOutline width={'1em'} height={'1em'}/>,
    GolfSharp: <IonIcon.GolfSharp width={'1em'} height={'1em'}/>,
    Golf: <IonIcon.Golf width={'1em'} height={'1em'}/>,
    GridOutline: <IonIcon.GridOutline width={'1em'} height={'1em'}/>,
    GridSharp: <IonIcon.GridSharp width={'1em'} height={'1em'}/>,
    Grid: <IonIcon.Grid width={'1em'} height={'1em'}/>,
    HammerOutline: <IonIcon.HammerOutline width={'1em'} height={'1em'}/>,
    HammerSharp: <IonIcon.HammerSharp width={'1em'} height={'1em'}/>,
    Hammer: <IonIcon.Hammer width={'1em'} height={'1em'}/>,
    HandLeftOutline: <IonIcon.HandLeftOutline width={'1em'} height={'1em'}/>,
    HandLeftSharp: <IonIcon.HandLeftSharp width={'1em'} height={'1em'}/>,
    HandLeft: <IonIcon.HandLeft width={'1em'} height={'1em'}/>,
    HandRightOutline: <IonIcon.HandRightOutline width={'1em'} height={'1em'}/>,
    HandRightSharp: <IonIcon.HandRightSharp width={'1em'} height={'1em'}/>,
    HandRight: <IonIcon.HandRight width={'1em'} height={'1em'}/>,
    HappyOutline: <IonIcon.HappyOutline width={'1em'} height={'1em'}/>,
    HappySharp: <IonIcon.HappySharp width={'1em'} height={'1em'}/>,
    Happy: <IonIcon.Happy width={'1em'} height={'1em'}/>,
    HardwareChipOutline: <IonIcon.HardwareChipOutline width={'1em'} height={'1em'}/>,
    HardwareChipSharp: <IonIcon.HardwareChipSharp width={'1em'} height={'1em'}/>,
    HardwareChip: <IonIcon.HardwareChip width={'1em'} height={'1em'}/>,
    HeadsetOutline: <IonIcon.HeadsetOutline width={'1em'} height={'1em'}/>,
    HeadsetSharp: <IonIcon.HeadsetSharp width={'1em'} height={'1em'}/>,
    Headset: <IonIcon.Headset width={'1em'} height={'1em'}/>,
    HeartCircleOutline: <IonIcon.HeartCircleOutline width={'1em'} height={'1em'}/>,
    HeartCircleSharp: <IonIcon.HeartCircleSharp width={'1em'} height={'1em'}/>,
    HeartCircle: <IonIcon.HeartCircle width={'1em'} height={'1em'}/>,
    HeartDislikeCircleOutline: <IonIcon.HeartDislikeCircleOutline width={'1em'} height={'1em'}/>,
    HeartDislikeCircleSharp: <IonIcon.HeartDislikeCircleSharp width={'1em'} height={'1em'}/>,
    HeartDislikeCircle: <IonIcon.HeartDislikeCircle width={'1em'} height={'1em'}/>,
    HeartDislikeOutline: <IonIcon.HeartDislikeOutline width={'1em'} height={'1em'}/>,
    HeartDislikeSharp: <IonIcon.HeartDislikeSharp width={'1em'} height={'1em'}/>,
    HeartDislike: <IonIcon.HeartDislike width={'1em'} height={'1em'}/>,
    HeartHalfOutline: <IonIcon.HeartHalfOutline width={'1em'} height={'1em'}/>,
    HeartHalfSharp: <IonIcon.HeartHalfSharp width={'1em'} height={'1em'}/>,
    HeartHalf: <IonIcon.HeartHalf width={'1em'} height={'1em'}/>,
    HeartOutline: <IonIcon.HeartOutline width={'1em'} height={'1em'}/>,
    HeartSharp: <IonIcon.HeartSharp width={'1em'} height={'1em'}/>,
    Heart: <IonIcon.Heart width={'1em'} height={'1em'}/>,
    HelpBuoyOutline: <IonIcon.HelpBuoyOutline width={'1em'} height={'1em'}/>,
    HelpBuoySharp: <IonIcon.HelpBuoySharp width={'1em'} height={'1em'}/>,
    HelpBuoy: <IonIcon.HelpBuoy width={'1em'} height={'1em'}/>,
    HelpCircleOutline: <IonIcon.HelpCircleOutline width={'1em'} height={'1em'}/>,
    HelpCircleSharp: <IonIcon.HelpCircleSharp width={'1em'} height={'1em'}/>,
    HelpCircle: <IonIcon.HelpCircle width={'1em'} height={'1em'}/>,
    HelpOutline: <IonIcon.HelpOutline width={'1em'} height={'1em'}/>,
    HelpSharp: <IonIcon.HelpSharp width={'1em'} height={'1em'}/>,
    Help: <IonIcon.Help width={'1em'} height={'1em'}/>,
    HomeOutline: <IonIcon.HomeOutline width={'1em'} height={'1em'}/>,
    HomeSharp: <IonIcon.HomeSharp width={'1em'} height={'1em'}/>,
    Home: <IonIcon.Home width={'1em'} height={'1em'}/>,
    HourglassOutline: <IonIcon.HourglassOutline width={'1em'} height={'1em'}/>,
    HourglassSharp: <IonIcon.HourglassSharp width={'1em'} height={'1em'}/>,
    Hourglass: <IonIcon.Hourglass width={'1em'} height={'1em'}/>,
    IceCreamOutline: <IonIcon.IceCreamOutline width={'1em'} height={'1em'}/>,
    IceCreamSharp: <IonIcon.IceCreamSharp width={'1em'} height={'1em'}/>,
    IceCream: <IonIcon.IceCream width={'1em'} height={'1em'}/>,
    ImageOutline: <IonIcon.ImageOutline width={'1em'} height={'1em'}/>,
    ImageSharp: <IonIcon.ImageSharp width={'1em'} height={'1em'}/>,
    Image: <IonIcon.Image width={'1em'} height={'1em'}/>,
    ImagesOutline: <IonIcon.ImagesOutline width={'1em'} height={'1em'}/>,
    ImagesSharp: <IonIcon.ImagesSharp width={'1em'} height={'1em'}/>,
    Images: <IonIcon.Images width={'1em'} height={'1em'}/>,
    InfiniteOutline: <IonIcon.InfiniteOutline width={'1em'} height={'1em'}/>,
    InfiniteSharp: <IonIcon.InfiniteSharp width={'1em'} height={'1em'}/>,
    Infinite: <IonIcon.Infinite width={'1em'} height={'1em'}/>,
    InformationCircleOutline: <IonIcon.InformationCircleOutline width={'1em'} height={'1em'}/>,
    InformationCircleSharp: <IonIcon.InformationCircleSharp width={'1em'} height={'1em'}/>,
    InformationCircle: <IonIcon.InformationCircle width={'1em'} height={'1em'}/>,
    InformationOutline: <IonIcon.InformationOutline width={'1em'} height={'1em'}/>,
    InformationSharp: <IonIcon.InformationSharp width={'1em'} height={'1em'}/>,
    Information: <IonIcon.Information width={'1em'} height={'1em'}/>,
    InvertModeOutline: <IonIcon.InvertModeOutline width={'1em'} height={'1em'}/>,
    InvertModeSharp: <IonIcon.InvertModeSharp width={'1em'} height={'1em'}/>,
    InvertMode: <IonIcon.InvertMode width={'1em'} height={'1em'}/>,
    JournalOutline: <IonIcon.JournalOutline width={'1em'} height={'1em'}/>,
    JournalSharp: <IonIcon.JournalSharp width={'1em'} height={'1em'}/>,
    Journal: <IonIcon.Journal width={'1em'} height={'1em'}/>,
    KeyOutline: <IonIcon.KeyOutline width={'1em'} height={'1em'}/>,
    KeySharp: <IonIcon.KeySharp width={'1em'} height={'1em'}/>,
    Key: <IonIcon.Key width={'1em'} height={'1em'}/>,
    KeypadOutline: <IonIcon.KeypadOutline width={'1em'} height={'1em'}/>,
    KeypadSharp: <IonIcon.KeypadSharp width={'1em'} height={'1em'}/>,
    Keypad: <IonIcon.Keypad width={'1em'} height={'1em'}/>,
    LanguageOutline: <IonIcon.LanguageOutline width={'1em'} height={'1em'}/>,
    LanguageSharp: <IonIcon.LanguageSharp width={'1em'} height={'1em'}/>,
    Language: <IonIcon.Language width={'1em'} height={'1em'}/>,
    LaptopOutline: <IonIcon.LaptopOutline width={'1em'} height={'1em'}/>,
    LaptopSharp: <IonIcon.LaptopSharp width={'1em'} height={'1em'}/>,
    Laptop: <IonIcon.Laptop width={'1em'} height={'1em'}/>,
    LayersOutline: <IonIcon.LayersOutline width={'1em'} height={'1em'}/>,
    LayersSharp: <IonIcon.LayersSharp width={'1em'} height={'1em'}/>,
    Layers: <IonIcon.Layers width={'1em'} height={'1em'}/>,
    LeafOutline: <IonIcon.LeafOutline width={'1em'} height={'1em'}/>,
    LeafSharp: <IonIcon.LeafSharp width={'1em'} height={'1em'}/>,
    Leaf: <IonIcon.Leaf width={'1em'} height={'1em'}/>,
    LibraryOutline: <IonIcon.LibraryOutline width={'1em'} height={'1em'}/>,
    LibrarySharp: <IonIcon.LibrarySharp width={'1em'} height={'1em'}/>,
    Library: <IonIcon.Library width={'1em'} height={'1em'}/>,
    LinkOutline: <IonIcon.LinkOutline width={'1em'} height={'1em'}/>,
    LinkSharp: <IonIcon.LinkSharp width={'1em'} height={'1em'}/>,
    Link: <IonIcon.Link width={'1em'} height={'1em'}/>,
    ListCircleOutline: <IonIcon.ListCircleOutline width={'1em'} height={'1em'}/>,
    ListCircleSharp: <IonIcon.ListCircleSharp width={'1em'} height={'1em'}/>,
    ListCircle: <IonIcon.ListCircle width={'1em'} height={'1em'}/>,
    ListOutline: <IonIcon.ListOutline width={'1em'} height={'1em'}/>,
    ListSharp: <IonIcon.ListSharp width={'1em'} height={'1em'}/>,
    List: <IonIcon.List width={'1em'} height={'1em'}/>,
    LocateOutline: <IonIcon.LocateOutline width={'1em'} height={'1em'}/>,
    LocateSharp: <IonIcon.LocateSharp width={'1em'} height={'1em'}/>,
    Locate: <IonIcon.Locate width={'1em'} height={'1em'}/>,
    LocationOutline: <IonIcon.LocationOutline width={'1em'} height={'1em'}/>,
    LocationSharp: <IonIcon.LocationSharp width={'1em'} height={'1em'}/>,
    Location: <IonIcon.Location width={'1em'} height={'1em'}/>,
    LockClosedOutline: <IonIcon.LockClosedOutline width={'1em'} height={'1em'}/>,
    LockClosedSharp: <IonIcon.LockClosedSharp width={'1em'} height={'1em'}/>,
    LockClosed: <IonIcon.LockClosed width={'1em'} height={'1em'}/>,
    LockOpenOutline: <IonIcon.LockOpenOutline width={'1em'} height={'1em'}/>,
    LockOpenSharp: <IonIcon.LockOpenSharp width={'1em'} height={'1em'}/>,
    LockOpen: <IonIcon.LockOpen width={'1em'} height={'1em'}/>,
    LogInOutline: <IonIcon.LogInOutline width={'1em'} height={'1em'}/>,
    LogInSharp: <IonIcon.LogInSharp width={'1em'} height={'1em'}/>,
    LogIn: <IonIcon.LogIn width={'1em'} height={'1em'}/>,
    LogOutOutline: <IonIcon.LogOutOutline width={'1em'} height={'1em'}/>,
    LogOutSharp: <IonIcon.LogOutSharp width={'1em'} height={'1em'}/>,
    LogOut: <IonIcon.LogOut width={'1em'} height={'1em'}/>,
    LogoAlipay: <IonIcon.LogoAlipay width={'1em'} height={'1em'}/>,
    LogoAmazon: <IonIcon.LogoAmazon width={'1em'} height={'1em'}/>,
    LogoAmplify: <IonIcon.LogoAmplify width={'1em'} height={'1em'}/>,
    LogoAndroid: <IonIcon.LogoAndroid width={'1em'} height={'1em'}/>,
    LogoAngular: <IonIcon.LogoAngular width={'1em'} height={'1em'}/>,
    LogoAppleAppstore: <IonIcon.LogoAppleAppstore width={'1em'} height={'1em'}/>,
    LogoApple: <IonIcon.LogoApple width={'1em'} height={'1em'}/>,
    LogoBehance: <IonIcon.LogoBehance width={'1em'} height={'1em'}/>,
    LogoBitbucket: <IonIcon.LogoBitbucket width={'1em'} height={'1em'}/>,
    LogoBitcoin: <IonIcon.LogoBitcoin width={'1em'} height={'1em'}/>,
    LogoBuffer: <IonIcon.LogoBuffer width={'1em'} height={'1em'}/>,
    LogoCapacitor: <IonIcon.LogoCapacitor width={'1em'} height={'1em'}/>,
    LogoChrome: <IonIcon.LogoChrome width={'1em'} height={'1em'}/>,
    LogoClosedCaptioning: <IonIcon.LogoClosedCaptioning width={'1em'} height={'1em'}/>,
    LogoCodepen: <IonIcon.LogoCodepen width={'1em'} height={'1em'}/>,
    LogoCss3: <IonIcon.LogoCss3 width={'1em'} height={'1em'}/>,
    LogoDesignernews: <IonIcon.LogoDesignernews width={'1em'} height={'1em'}/>,
    LogoDeviantart: <IonIcon.LogoDeviantart width={'1em'} height={'1em'}/>,
    LogoDiscord: <IonIcon.LogoDiscord width={'1em'} height={'1em'}/>,
    LogoDocker: <IonIcon.LogoDocker width={'1em'} height={'1em'}/>,
    LogoDribbble: <IonIcon.LogoDribbble width={'1em'} height={'1em'}/>,
    LogoDropbox: <IonIcon.LogoDropbox width={'1em'} height={'1em'}/>,
    LogoEdge: <IonIcon.LogoEdge width={'1em'} height={'1em'}/>,
    LogoElectron: <IonIcon.LogoElectron width={'1em'} height={'1em'}/>,
    LogoEuro: <IonIcon.LogoEuro width={'1em'} height={'1em'}/>,
    LogoFacebook: <IonIcon.LogoFacebook width={'1em'} height={'1em'}/>,
    LogoFigma: <IonIcon.LogoFigma width={'1em'} height={'1em'}/>,
    LogoFirebase: <IonIcon.LogoFirebase width={'1em'} height={'1em'}/>,
    LogoFirefox: <IonIcon.LogoFirefox width={'1em'} height={'1em'}/>,
    LogoFlickr: <IonIcon.LogoFlickr width={'1em'} height={'1em'}/>,
    LogoFoursquare: <IonIcon.LogoFoursquare width={'1em'} height={'1em'}/>,
    LogoGithub: <IonIcon.LogoGithub width={'1em'} height={'1em'}/>,
    LogoGitlab: <IonIcon.LogoGitlab width={'1em'} height={'1em'}/>,
    LogoGooglePlaystore: <IonIcon.LogoGooglePlaystore width={'1em'} height={'1em'}/>,
    LogoGoogle: <IonIcon.LogoGoogle width={'1em'} height={'1em'}/>,
    LogoHackernews: <IonIcon.LogoHackernews width={'1em'} height={'1em'}/>,
    LogoHtml5: <IonIcon.LogoHtml5 width={'1em'} height={'1em'}/>,
    LogoInstagram: <IonIcon.LogoInstagram width={'1em'} height={'1em'}/>,
    LogoIonic: <IonIcon.LogoIonic width={'1em'} height={'1em'}/>,
    LogoIonitron: <IonIcon.LogoIonitron width={'1em'} height={'1em'}/>,
    LogoJavascript: <IonIcon.LogoJavascript width={'1em'} height={'1em'}/>,
    LogoLaravel: <IonIcon.LogoLaravel width={'1em'} height={'1em'}/>,
    LogoLinkedin: <IonIcon.LogoLinkedin width={'1em'} height={'1em'}/>,
    LogoMarkdown: <IonIcon.LogoMarkdown width={'1em'} height={'1em'}/>,
    LogoMastodon: <IonIcon.LogoMastodon width={'1em'} height={'1em'}/>,
    LogoMedium: <IonIcon.LogoMedium width={'1em'} height={'1em'}/>,
    LogoNoSmoking: <IonIcon.LogoNoSmoking width={'1em'} height={'1em'}/>,
    LogoNodejs: <IonIcon.LogoNodejs width={'1em'} height={'1em'}/>,
    LogoNpm: <IonIcon.LogoNpm width={'1em'} height={'1em'}/>,
    LogoOctocat: <IonIcon.LogoOctocat width={'1em'} height={'1em'}/>,
    LogoPaypal: <IonIcon.LogoPaypal width={'1em'} height={'1em'}/>,
    LogoPinterest: <IonIcon.LogoPinterest width={'1em'} height={'1em'}/>,
    LogoPlaystation: <IonIcon.LogoPlaystation width={'1em'} height={'1em'}/>,
    LogoPwa: <IonIcon.LogoPwa width={'1em'} height={'1em'}/>,
    LogoPython: <IonIcon.LogoPython width={'1em'} height={'1em'}/>,
    LogoReact: <IonIcon.LogoReact width={'1em'} height={'1em'}/>,
    LogoReddit: <IonIcon.LogoReddit width={'1em'} height={'1em'}/>,
    LogoRss: <IonIcon.LogoRss width={'1em'} height={'1em'}/>,
    LogoSass: <IonIcon.LogoSass width={'1em'} height={'1em'}/>,
    LogoSkype: <IonIcon.LogoSkype width={'1em'} height={'1em'}/>,
    LogoSlack: <IonIcon.LogoSlack width={'1em'} height={'1em'}/>,
    LogoSnapchat: <IonIcon.LogoSnapchat width={'1em'} height={'1em'}/>,
    LogoSoundcloud: <IonIcon.LogoSoundcloud width={'1em'} height={'1em'}/>,
    LogoStackoverflow: <IonIcon.LogoStackoverflow width={'1em'} height={'1em'}/>,
    LogoSteam: <IonIcon.LogoSteam width={'1em'} height={'1em'}/>,
    LogoStencil: <IonIcon.LogoStencil width={'1em'} height={'1em'}/>,
    LogoTableau: <IonIcon.LogoTableau width={'1em'} height={'1em'}/>,
    LogoTiktok: <IonIcon.LogoTiktok width={'1em'} height={'1em'}/>,
    LogoTumblr: <IonIcon.LogoTumblr width={'1em'} height={'1em'}/>,
    LogoTux: <IonIcon.LogoTux width={'1em'} height={'1em'}/>,
    LogoTwitch: <IonIcon.LogoTwitch width={'1em'} height={'1em'}/>,
    LogoTwitter: <IonIcon.LogoTwitter width={'1em'} height={'1em'}/>,
    LogoUsd: <IonIcon.LogoUsd width={'1em'} height={'1em'}/>,
    LogoVenmo: <IonIcon.LogoVenmo width={'1em'} height={'1em'}/>,
    LogoVercel: <IonIcon.LogoVercel width={'1em'} height={'1em'}/>,
    LogoVimeo: <IonIcon.LogoVimeo width={'1em'} height={'1em'}/>,
    LogoVk: <IonIcon.LogoVk width={'1em'} height={'1em'}/>,
    LogoVue: <IonIcon.LogoVue width={'1em'} height={'1em'}/>,
    LogoWebComponent: <IonIcon.LogoWebComponent width={'1em'} height={'1em'}/>,
    LogoWechat: <IonIcon.LogoWechat width={'1em'} height={'1em'}/>,
    LogoWhatsapp: <IonIcon.LogoWhatsapp width={'1em'} height={'1em'}/>,
    LogoWindows: <IonIcon.LogoWindows width={'1em'} height={'1em'}/>,
    LogoWordpress: <IonIcon.LogoWordpress width={'1em'} height={'1em'}/>,
    LogoXbox: <IonIcon.LogoXbox width={'1em'} height={'1em'}/>,
    LogoXing: <IonIcon.LogoXing width={'1em'} height={'1em'}/>,
    LogoYahoo: <IonIcon.LogoYahoo width={'1em'} height={'1em'}/>,
    LogoYen: <IonIcon.LogoYen width={'1em'} height={'1em'}/>,
    LogoYoutube: <IonIcon.LogoYoutube width={'1em'} height={'1em'}/>,
    MagnetOutline: <IonIcon.MagnetOutline width={'1em'} height={'1em'}/>,
    MagnetSharp: <IonIcon.MagnetSharp width={'1em'} height={'1em'}/>,
    Magnet: <IonIcon.Magnet width={'1em'} height={'1em'}/>,
    MailOpenOutline: <IonIcon.MailOpenOutline width={'1em'} height={'1em'}/>,
    MailOpenSharp: <IonIcon.MailOpenSharp width={'1em'} height={'1em'}/>,
    MailOpen: <IonIcon.MailOpen width={'1em'} height={'1em'}/>,
    MailOutline: <IonIcon.MailOutline width={'1em'} height={'1em'}/>,
    MailSharp: <IonIcon.MailSharp width={'1em'} height={'1em'}/>,
    MailUnreadOutline: <IonIcon.MailUnreadOutline width={'1em'} height={'1em'}/>,
    MailUnreadSharp: <IonIcon.MailUnreadSharp width={'1em'} height={'1em'}/>,
    MailUnread: <IonIcon.MailUnread width={'1em'} height={'1em'}/>,
    Mail: <IonIcon.Mail width={'1em'} height={'1em'}/>,
    MaleFemaleOutline: <IonIcon.MaleFemaleOutline width={'1em'} height={'1em'}/>,
    MaleFemaleSharp: <IonIcon.MaleFemaleSharp width={'1em'} height={'1em'}/>,
    MaleFemale: <IonIcon.MaleFemale width={'1em'} height={'1em'}/>,
    MaleOutline: <IonIcon.MaleOutline width={'1em'} height={'1em'}/>,
    MaleSharp: <IonIcon.MaleSharp width={'1em'} height={'1em'}/>,
    Male: <IonIcon.Male width={'1em'} height={'1em'}/>,
    ManOutline: <IonIcon.ManOutline width={'1em'} height={'1em'}/>,
    ManSharp: <IonIcon.ManSharp width={'1em'} height={'1em'}/>,
    Man: <IonIcon.Man width={'1em'} height={'1em'}/>,
    MapOutline: <IonIcon.MapOutline width={'1em'} height={'1em'}/>,
    MapSharp: <IonIcon.MapSharp width={'1em'} height={'1em'}/>,
    Map: <IonIcon.Map width={'1em'} height={'1em'}/>,
    MedalOutline: <IonIcon.MedalOutline width={'1em'} height={'1em'}/>,
    MedalSharp: <IonIcon.MedalSharp width={'1em'} height={'1em'}/>,
    Medal: <IonIcon.Medal width={'1em'} height={'1em'}/>,
    MedicalOutline: <IonIcon.MedicalOutline width={'1em'} height={'1em'}/>,
    MedicalSharp: <IonIcon.MedicalSharp width={'1em'} height={'1em'}/>,
    Medical: <IonIcon.Medical width={'1em'} height={'1em'}/>,
    MedkitOutline: <IonIcon.MedkitOutline width={'1em'} height={'1em'}/>,
    MedkitSharp: <IonIcon.MedkitSharp width={'1em'} height={'1em'}/>,
    Medkit: <IonIcon.Medkit width={'1em'} height={'1em'}/>,
    MegaphoneOutline: <IonIcon.MegaphoneOutline width={'1em'} height={'1em'}/>,
    MegaphoneSharp: <IonIcon.MegaphoneSharp width={'1em'} height={'1em'}/>,
    Megaphone: <IonIcon.Megaphone width={'1em'} height={'1em'}/>,
    MenuOutline: <IonIcon.MenuOutline width={'1em'} height={'1em'}/>,
    MenuSharp: <IonIcon.MenuSharp width={'1em'} height={'1em'}/>,
    Menu: <IonIcon.Menu width={'1em'} height={'1em'}/>,
    MicCircleOutline: <IonIcon.MicCircleOutline width={'1em'} height={'1em'}/>,
    MicCircleSharp: <IonIcon.MicCircleSharp width={'1em'} height={'1em'}/>,
    MicCircle: <IonIcon.MicCircle width={'1em'} height={'1em'}/>,
    MicOffCircleOutline: <IonIcon.MicOffCircleOutline width={'1em'} height={'1em'}/>,
    MicOffCircleSharp: <IonIcon.MicOffCircleSharp width={'1em'} height={'1em'}/>,
    MicOffCircle: <IonIcon.MicOffCircle width={'1em'} height={'1em'}/>,
    MicOffOutline: <IonIcon.MicOffOutline width={'1em'} height={'1em'}/>,
    MicOffSharp: <IonIcon.MicOffSharp width={'1em'} height={'1em'}/>,
    MicOff: <IonIcon.MicOff width={'1em'} height={'1em'}/>,
    MicOutline: <IonIcon.MicOutline width={'1em'} height={'1em'}/>,
    MicSharp: <IonIcon.MicSharp width={'1em'} height={'1em'}/>,
    Mic: <IonIcon.Mic width={'1em'} height={'1em'}/>,
    MoonOutline: <IonIcon.MoonOutline width={'1em'} height={'1em'}/>,
    MoonSharp: <IonIcon.MoonSharp width={'1em'} height={'1em'}/>,
    Moon: <IonIcon.Moon width={'1em'} height={'1em'}/>,
    MoveOutline: <IonIcon.MoveOutline width={'1em'} height={'1em'}/>,
    MoveSharp: <IonIcon.MoveSharp width={'1em'} height={'1em'}/>,
    Move: <IonIcon.Move width={'1em'} height={'1em'}/>,
    MusicalNoteOutline: <IonIcon.MusicalNoteOutline width={'1em'} height={'1em'}/>,
    MusicalNoteSharp: <IonIcon.MusicalNoteSharp width={'1em'} height={'1em'}/>,
    MusicalNote: <IonIcon.MusicalNote width={'1em'} height={'1em'}/>,
    MusicalNotesOutline: <IonIcon.MusicalNotesOutline width={'1em'} height={'1em'}/>,
    MusicalNotesSharp: <IonIcon.MusicalNotesSharp width={'1em'} height={'1em'}/>,
    MusicalNotes: <IonIcon.MusicalNotes width={'1em'} height={'1em'}/>,
    NavigateCircleOutline: <IonIcon.NavigateCircleOutline width={'1em'} height={'1em'}/>,
    NavigateCircleSharp: <IonIcon.NavigateCircleSharp width={'1em'} height={'1em'}/>,
    NavigateCircle: <IonIcon.NavigateCircle width={'1em'} height={'1em'}/>,
    NavigateOutline: <IonIcon.NavigateOutline width={'1em'} height={'1em'}/>,
    NavigateSharp: <IonIcon.NavigateSharp width={'1em'} height={'1em'}/>,
    Navigate: <IonIcon.Navigate width={'1em'} height={'1em'}/>,
    NewspaperOutline: <IonIcon.NewspaperOutline width={'1em'} height={'1em'}/>,
    NewspaperSharp: <IonIcon.NewspaperSharp width={'1em'} height={'1em'}/>,
    Newspaper: <IonIcon.Newspaper width={'1em'} height={'1em'}/>,
    NotificationsCircleOutline: <IonIcon.NotificationsCircleOutline width={'1em'} height={'1em'}/>,
    NotificationsCircleSharp: <IonIcon.NotificationsCircleSharp width={'1em'} height={'1em'}/>,
    NotificationsCircle: <IonIcon.NotificationsCircle width={'1em'} height={'1em'}/>,
    NotificationsOffCircleOutline: <IonIcon.NotificationsOffCircleOutline width={'1em'} height={'1em'}/>,
    NotificationsOffCircleSharp: <IonIcon.NotificationsOffCircleSharp width={'1em'} height={'1em'}/>,
    NotificationsOffCircle: <IonIcon.NotificationsOffCircle width={'1em'} height={'1em'}/>,
    NotificationsOffOutline: <IonIcon.NotificationsOffOutline width={'1em'} height={'1em'}/>,
    NotificationsOffSharp: <IonIcon.NotificationsOffSharp width={'1em'} height={'1em'}/>,
    NotificationsOff: <IonIcon.NotificationsOff width={'1em'} height={'1em'}/>,
    NotificationsOutline: <IonIcon.NotificationsOutline width={'1em'} height={'1em'}/>,
    NotificationsSharp: <IonIcon.NotificationsSharp width={'1em'} height={'1em'}/>,
    Notifications: <IonIcon.Notifications width={'1em'} height={'1em'}/>,
    NuclearOutline: <IonIcon.NuclearOutline width={'1em'} height={'1em'}/>,
    NuclearSharp: <IonIcon.NuclearSharp width={'1em'} height={'1em'}/>,
    Nuclear: <IonIcon.Nuclear width={'1em'} height={'1em'}/>,
    NutritionOutline: <IonIcon.NutritionOutline width={'1em'} height={'1em'}/>,
    NutritionSharp: <IonIcon.NutritionSharp width={'1em'} height={'1em'}/>,
    Nutrition: <IonIcon.Nutrition width={'1em'} height={'1em'}/>,
    OpenOutline: <IonIcon.OpenOutline width={'1em'} height={'1em'}/>,
    OpenSharp: <IonIcon.OpenSharp width={'1em'} height={'1em'}/>,
    Open: <IonIcon.Open width={'1em'} height={'1em'}/>,
    OptionsOutline: <IonIcon.OptionsOutline width={'1em'} height={'1em'}/>,
    OptionsSharp: <IonIcon.OptionsSharp width={'1em'} height={'1em'}/>,
    Options: <IonIcon.Options width={'1em'} height={'1em'}/>,
    PaperPlaneOutline: <IonIcon.PaperPlaneOutline width={'1em'} height={'1em'}/>,
    PaperPlaneSharp: <IonIcon.PaperPlaneSharp width={'1em'} height={'1em'}/>,
    PaperPlane: <IonIcon.PaperPlane width={'1em'} height={'1em'}/>,
    PartlySunnyOutline: <IonIcon.PartlySunnyOutline width={'1em'} height={'1em'}/>,
    PartlySunnySharp: <IonIcon.PartlySunnySharp width={'1em'} height={'1em'}/>,
    PartlySunny: <IonIcon.PartlySunny width={'1em'} height={'1em'}/>,
    PauseCircleOutline: <IonIcon.PauseCircleOutline width={'1em'} height={'1em'}/>,
    PauseCircleSharp: <IonIcon.PauseCircleSharp width={'1em'} height={'1em'}/>,
    PauseCircle: <IonIcon.PauseCircle width={'1em'} height={'1em'}/>,
    PauseOutline: <IonIcon.PauseOutline width={'1em'} height={'1em'}/>,
    PauseSharp: <IonIcon.PauseSharp width={'1em'} height={'1em'}/>,
    Pause: <IonIcon.Pause width={'1em'} height={'1em'}/>,
    PawOutline: <IonIcon.PawOutline width={'1em'} height={'1em'}/>,
    PawSharp: <IonIcon.PawSharp width={'1em'} height={'1em'}/>,
    Paw: <IonIcon.Paw width={'1em'} height={'1em'}/>,
    PencilOutline: <IonIcon.PencilOutline width={'1em'} height={'1em'}/>,
    PencilSharp: <IonIcon.PencilSharp width={'1em'} height={'1em'}/>,
    Pencil: <IonIcon.Pencil width={'1em'} height={'1em'}/>,
    PeopleCircleOutline: <IonIcon.PeopleCircleOutline width={'1em'} height={'1em'}/>,
    PeopleCircleSharp: <IonIcon.PeopleCircleSharp width={'1em'} height={'1em'}/>,
    PeopleCircle: <IonIcon.PeopleCircle width={'1em'} height={'1em'}/>,
    PeopleOutline: <IonIcon.PeopleOutline width={'1em'} height={'1em'}/>,
    PeopleSharp: <IonIcon.PeopleSharp width={'1em'} height={'1em'}/>,
    People: <IonIcon.People width={'1em'} height={'1em'}/>,
    PersonAddOutline: <IonIcon.PersonAddOutline width={'1em'} height={'1em'}/>,
    PersonAddSharp: <IonIcon.PersonAddSharp width={'1em'} height={'1em'}/>,
    PersonAdd: <IonIcon.PersonAdd width={'1em'} height={'1em'}/>,
    PersonCircleOutline: <IonIcon.PersonCircleOutline width={'1em'} height={'1em'}/>,
    PersonCircleSharp: <IonIcon.PersonCircleSharp width={'1em'} height={'1em'}/>,
    PersonCircle: <IonIcon.PersonCircle width={'1em'} height={'1em'}/>,
    PersonOutline: <IonIcon.PersonOutline width={'1em'} height={'1em'}/>,
    PersonRemoveOutline: <IonIcon.PersonRemoveOutline width={'1em'} height={'1em'}/>,
    PersonRemoveSharp: <IonIcon.PersonRemoveSharp width={'1em'} height={'1em'}/>,
    PersonRemove: <IonIcon.PersonRemove width={'1em'} height={'1em'}/>,
    PersonSharp: <IonIcon.PersonSharp width={'1em'} height={'1em'}/>,
    Person: <IonIcon.Person width={'1em'} height={'1em'}/>,
    PhoneLandscapeOutline: <IonIcon.PhoneLandscapeOutline width={'1em'} height={'1em'}/>,
    PhoneLandscapeSharp: <IonIcon.PhoneLandscapeSharp width={'1em'} height={'1em'}/>,
    PhoneLandscape: <IonIcon.PhoneLandscape width={'1em'} height={'1em'}/>,
    PhonePortraitOutline: <IonIcon.PhonePortraitOutline width={'1em'} height={'1em'}/>,
    PhonePortraitSharp: <IonIcon.PhonePortraitSharp width={'1em'} height={'1em'}/>,
    PhonePortrait: <IonIcon.PhonePortrait width={'1em'} height={'1em'}/>,
    PieChartOutline: <IonIcon.PieChartOutline width={'1em'} height={'1em'}/>,
    PieChartSharp: <IonIcon.PieChartSharp width={'1em'} height={'1em'}/>,
    PieChart: <IonIcon.PieChart width={'1em'} height={'1em'}/>,
    PinOutline: <IonIcon.PinOutline width={'1em'} height={'1em'}/>,
    PinSharp: <IonIcon.PinSharp width={'1em'} height={'1em'}/>,
    Pin: <IonIcon.Pin width={'1em'} height={'1em'}/>,
    PintOutline: <IonIcon.PintOutline width={'1em'} height={'1em'}/>,
    PintSharp: <IonIcon.PintSharp width={'1em'} height={'1em'}/>,
    Pint: <IonIcon.Pint width={'1em'} height={'1em'}/>,
    PizzaOutline: <IonIcon.PizzaOutline width={'1em'} height={'1em'}/>,
    PizzaSharp: <IonIcon.PizzaSharp width={'1em'} height={'1em'}/>,
    Pizza: <IonIcon.Pizza width={'1em'} height={'1em'}/>,
    PlanetOutline: <IonIcon.PlanetOutline width={'1em'} height={'1em'}/>,
    PlanetSharp: <IonIcon.PlanetSharp width={'1em'} height={'1em'}/>,
    Planet: <IonIcon.Planet width={'1em'} height={'1em'}/>,
    PlayBackCircleOutline: <IonIcon.PlayBackCircleOutline width={'1em'} height={'1em'}/>,
    PlayBackCircleSharp: <IonIcon.PlayBackCircleSharp width={'1em'} height={'1em'}/>,
    PlayBackCircle: <IonIcon.PlayBackCircle width={'1em'} height={'1em'}/>,
    PlayBackOutline: <IonIcon.PlayBackOutline width={'1em'} height={'1em'}/>,
    PlayBackSharp: <IonIcon.PlayBackSharp width={'1em'} height={'1em'}/>,
    PlayBack: <IonIcon.PlayBack width={'1em'} height={'1em'}/>,
    PlayCircleOutline: <IonIcon.PlayCircleOutline width={'1em'} height={'1em'}/>,
    PlayCircleSharp: <IonIcon.PlayCircleSharp width={'1em'} height={'1em'}/>,
    PlayCircle: <IonIcon.PlayCircle width={'1em'} height={'1em'}/>,
    PlayForwardCircleOutline: <IonIcon.PlayForwardCircleOutline width={'1em'} height={'1em'}/>,
    PlayForwardCircleSharp: <IonIcon.PlayForwardCircleSharp width={'1em'} height={'1em'}/>,
    PlayForwardCircle: <IonIcon.PlayForwardCircle width={'1em'} height={'1em'}/>,
    PlayForwardOutline: <IonIcon.PlayForwardOutline width={'1em'} height={'1em'}/>,
    PlayForwardSharp: <IonIcon.PlayForwardSharp width={'1em'} height={'1em'}/>,
    PlayForward: <IonIcon.PlayForward width={'1em'} height={'1em'}/>,
    PlayOutline: <IonIcon.PlayOutline width={'1em'} height={'1em'}/>,
    PlaySharp: <IonIcon.PlaySharp width={'1em'} height={'1em'}/>,
    PlaySkipBackCircleOutline: <IonIcon.PlaySkipBackCircleOutline width={'1em'} height={'1em'}/>,
    PlaySkipBackCircleSharp: <IonIcon.PlaySkipBackCircleSharp width={'1em'} height={'1em'}/>,
    PlaySkipBackCircle: <IonIcon.PlaySkipBackCircle width={'1em'} height={'1em'}/>,
    PlaySkipBackOutline: <IonIcon.PlaySkipBackOutline width={'1em'} height={'1em'}/>,
    PlaySkipBackSharp: <IonIcon.PlaySkipBackSharp width={'1em'} height={'1em'}/>,
    PlaySkipBack: <IonIcon.PlaySkipBack width={'1em'} height={'1em'}/>,
    PlaySkipForwardCircleOutline: <IonIcon.PlaySkipForwardCircleOutline width={'1em'} height={'1em'}/>,
    PlaySkipForwardCircleSharp: <IonIcon.PlaySkipForwardCircleSharp width={'1em'} height={'1em'}/>,
    PlaySkipForwardCircle: <IonIcon.PlaySkipForwardCircle width={'1em'} height={'1em'}/>,
    PlaySkipForwardOutline: <IonIcon.PlaySkipForwardOutline width={'1em'} height={'1em'}/>,
    PlaySkipForwardSharp: <IonIcon.PlaySkipForwardSharp width={'1em'} height={'1em'}/>,
    PlaySkipForward: <IonIcon.PlaySkipForward width={'1em'} height={'1em'}/>,
    Play: <IonIcon.Play width={'1em'} height={'1em'}/>,
    PodiumOutline: <IonIcon.PodiumOutline width={'1em'} height={'1em'}/>,
    PodiumSharp: <IonIcon.PodiumSharp width={'1em'} height={'1em'}/>,
    Podium: <IonIcon.Podium width={'1em'} height={'1em'}/>,
    PowerOutline: <IonIcon.PowerOutline width={'1em'} height={'1em'}/>,
    PowerSharp: <IonIcon.PowerSharp width={'1em'} height={'1em'}/>,
    Power: <IonIcon.Power width={'1em'} height={'1em'}/>,
    PricetagOutline: <IonIcon.PricetagOutline width={'1em'} height={'1em'}/>,
    PricetagSharp: <IonIcon.PricetagSharp width={'1em'} height={'1em'}/>,
    Pricetag: <IonIcon.Pricetag width={'1em'} height={'1em'}/>,
    PricetagsOutline: <IonIcon.PricetagsOutline width={'1em'} height={'1em'}/>,
    PricetagsSharp: <IonIcon.PricetagsSharp width={'1em'} height={'1em'}/>,
    Pricetags: <IonIcon.Pricetags width={'1em'} height={'1em'}/>,
    PrintOutline: <IonIcon.PrintOutline width={'1em'} height={'1em'}/>,
    PrintSharp: <IonIcon.PrintSharp width={'1em'} height={'1em'}/>,
    Print: <IonIcon.Print width={'1em'} height={'1em'}/>,
    PulseOutline: <IonIcon.PulseOutline width={'1em'} height={'1em'}/>,
    PulseSharp: <IonIcon.PulseSharp width={'1em'} height={'1em'}/>,
    Pulse: <IonIcon.Pulse width={'1em'} height={'1em'}/>,
    PushOutline: <IonIcon.PushOutline width={'1em'} height={'1em'}/>,
    PushSharp: <IonIcon.PushSharp width={'1em'} height={'1em'}/>,
    Push: <IonIcon.Push width={'1em'} height={'1em'}/>,
    QrCodeOutline: <IonIcon.QrCodeOutline width={'1em'} height={'1em'}/>,
    QrCodeSharp: <IonIcon.QrCodeSharp width={'1em'} height={'1em'}/>,
    QrCode: <IonIcon.QrCode width={'1em'} height={'1em'}/>,
    RadioButtonOffOutline: <IonIcon.RadioButtonOffOutline width={'1em'} height={'1em'}/>,
    RadioButtonOffSharp: <IonIcon.RadioButtonOffSharp width={'1em'} height={'1em'}/>,
    RadioButtonOff: <IonIcon.RadioButtonOff width={'1em'} height={'1em'}/>,
    RadioButtonOnOutline: <IonIcon.RadioButtonOnOutline width={'1em'} height={'1em'}/>,
    RadioButtonOnSharp: <IonIcon.RadioButtonOnSharp width={'1em'} height={'1em'}/>,
    RadioButtonOn: <IonIcon.RadioButtonOn width={'1em'} height={'1em'}/>,
    RadioOutline: <IonIcon.RadioOutline width={'1em'} height={'1em'}/>,
    RadioSharp: <IonIcon.RadioSharp width={'1em'} height={'1em'}/>,
    Radio: <IonIcon.Radio width={'1em'} height={'1em'}/>,
    RainyOutline: <IonIcon.RainyOutline width={'1em'} height={'1em'}/>,
    RainySharp: <IonIcon.RainySharp width={'1em'} height={'1em'}/>,
    Rainy: <IonIcon.Rainy width={'1em'} height={'1em'}/>,
    ReaderOutline: <IonIcon.ReaderOutline width={'1em'} height={'1em'}/>,
    ReaderSharp: <IonIcon.ReaderSharp width={'1em'} height={'1em'}/>,
    Reader: <IonIcon.Reader width={'1em'} height={'1em'}/>,
    ReceiptOutline: <IonIcon.ReceiptOutline width={'1em'} height={'1em'}/>,
    ReceiptSharp: <IonIcon.ReceiptSharp width={'1em'} height={'1em'}/>,
    Receipt: <IonIcon.Receipt width={'1em'} height={'1em'}/>,
    RecordingOutline: <IonIcon.RecordingOutline width={'1em'} height={'1em'}/>,
    RecordingSharp: <IonIcon.RecordingSharp width={'1em'} height={'1em'}/>,
    Recording: <IonIcon.Recording width={'1em'} height={'1em'}/>,
    RefreshCircleOutline: <IonIcon.RefreshCircleOutline width={'1em'} height={'1em'}/>,
    RefreshCircleSharp: <IonIcon.RefreshCircleSharp width={'1em'} height={'1em'}/>,
    RefreshCircle: <IonIcon.RefreshCircle width={'1em'} height={'1em'}/>,
    RefreshOutline: <IonIcon.RefreshOutline width={'1em'} height={'1em'}/>,
    RefreshSharp: <IonIcon.RefreshSharp width={'1em'} height={'1em'}/>,
    Refresh: <IonIcon.Refresh width={'1em'} height={'1em'}/>,
    ReloadCircleOutline: <IonIcon.ReloadCircleOutline width={'1em'} height={'1em'}/>,
    ReloadCircleSharp: <IonIcon.ReloadCircleSharp width={'1em'} height={'1em'}/>,
    ReloadCircle: <IonIcon.ReloadCircle width={'1em'} height={'1em'}/>,
    ReloadOutline: <IonIcon.ReloadOutline width={'1em'} height={'1em'}/>,
    ReloadSharp: <IonIcon.ReloadSharp width={'1em'} height={'1em'}/>,
    Reload: <IonIcon.Reload width={'1em'} height={'1em'}/>,
    RemoveCircleOutline: <IonIcon.RemoveCircleOutline width={'1em'} height={'1em'}/>,
    RemoveCircleSharp: <IonIcon.RemoveCircleSharp width={'1em'} height={'1em'}/>,
    RemoveCircle: <IonIcon.RemoveCircle width={'1em'} height={'1em'}/>,
    RemoveOutline: <IonIcon.RemoveOutline width={'1em'} height={'1em'}/>,
    RemoveSharp: <IonIcon.RemoveSharp width={'1em'} height={'1em'}/>,
    Remove: <IonIcon.Remove width={'1em'} height={'1em'}/>,
    ReorderFourOutline: <IonIcon.ReorderFourOutline width={'1em'} height={'1em'}/>,
    ReorderFourSharp: <IonIcon.ReorderFourSharp width={'1em'} height={'1em'}/>,
    ReorderFour: <IonIcon.ReorderFour width={'1em'} height={'1em'}/>,
    ReorderThreeOutline: <IonIcon.ReorderThreeOutline width={'1em'} height={'1em'}/>,
    ReorderThreeSharp: <IonIcon.ReorderThreeSharp width={'1em'} height={'1em'}/>,
    ReorderThree: <IonIcon.ReorderThree width={'1em'} height={'1em'}/>,
    ReorderTwoOutline: <IonIcon.ReorderTwoOutline width={'1em'} height={'1em'}/>,
    ReorderTwoSharp: <IonIcon.ReorderTwoSharp width={'1em'} height={'1em'}/>,
    ReorderTwo: <IonIcon.ReorderTwo width={'1em'} height={'1em'}/>,
    RepeatOutline: <IonIcon.RepeatOutline width={'1em'} height={'1em'}/>,
    RepeatSharp: <IonIcon.RepeatSharp width={'1em'} height={'1em'}/>,
    Repeat: <IonIcon.Repeat width={'1em'} height={'1em'}/>,
    ResizeOutline: <IonIcon.ResizeOutline width={'1em'} height={'1em'}/>,
    ResizeSharp: <IonIcon.ResizeSharp width={'1em'} height={'1em'}/>,
    Resize: <IonIcon.Resize width={'1em'} height={'1em'}/>,
    RestaurantOutline: <IonIcon.RestaurantOutline width={'1em'} height={'1em'}/>,
    RestaurantSharp: <IonIcon.RestaurantSharp width={'1em'} height={'1em'}/>,
    Restaurant: <IonIcon.Restaurant width={'1em'} height={'1em'}/>,
    ReturnDownBackOutline: <IonIcon.ReturnDownBackOutline width={'1em'} height={'1em'}/>,
    ReturnDownBackSharp: <IonIcon.ReturnDownBackSharp width={'1em'} height={'1em'}/>,
    ReturnDownBack: <IonIcon.ReturnDownBack width={'1em'} height={'1em'}/>,
    ReturnDownForwardOutline: <IonIcon.ReturnDownForwardOutline width={'1em'} height={'1em'}/>,
    ReturnDownForwardSharp: <IonIcon.ReturnDownForwardSharp width={'1em'} height={'1em'}/>,
    ReturnDownForward: <IonIcon.ReturnDownForward width={'1em'} height={'1em'}/>,
    ReturnUpBackOutline: <IonIcon.ReturnUpBackOutline width={'1em'} height={'1em'}/>,
    ReturnUpBackSharp: <IonIcon.ReturnUpBackSharp width={'1em'} height={'1em'}/>,
    ReturnUpBack: <IonIcon.ReturnUpBack width={'1em'} height={'1em'}/>,
    ReturnUpForwardOutline: <IonIcon.ReturnUpForwardOutline width={'1em'} height={'1em'}/>,
    ReturnUpForwardSharp: <IonIcon.ReturnUpForwardSharp width={'1em'} height={'1em'}/>,
    ReturnUpForward: <IonIcon.ReturnUpForward width={'1em'} height={'1em'}/>,
    RibbonOutline: <IonIcon.RibbonOutline width={'1em'} height={'1em'}/>,
    RibbonSharp: <IonIcon.RibbonSharp width={'1em'} height={'1em'}/>,
    Ribbon: <IonIcon.Ribbon width={'1em'} height={'1em'}/>,
    RocketOutline: <IonIcon.RocketOutline width={'1em'} height={'1em'}/>,
    RocketSharp: <IonIcon.RocketSharp width={'1em'} height={'1em'}/>,
    Rocket: <IonIcon.Rocket width={'1em'} height={'1em'}/>,
    RoseOutline: <IonIcon.RoseOutline width={'1em'} height={'1em'}/>,
    RoseSharp: <IonIcon.RoseSharp width={'1em'} height={'1em'}/>,
    Rose: <IonIcon.Rose width={'1em'} height={'1em'}/>,
    SadOutline: <IonIcon.SadOutline width={'1em'} height={'1em'}/>,
    SadSharp: <IonIcon.SadSharp width={'1em'} height={'1em'}/>,
    Sad: <IonIcon.Sad width={'1em'} height={'1em'}/>,
    SaveOutline: <IonIcon.SaveOutline width={'1em'} height={'1em'}/>,
    SaveSharp: <IonIcon.SaveSharp width={'1em'} height={'1em'}/>,
    Save: <IonIcon.Save width={'1em'} height={'1em'}/>,
    ScanCircleOutline: <IonIcon.ScanCircleOutline width={'1em'} height={'1em'}/>,
    ScanCircleSharp: <IonIcon.ScanCircleSharp width={'1em'} height={'1em'}/>,
    ScanCircle: <IonIcon.ScanCircle width={'1em'} height={'1em'}/>,
    ScanOutline: <IonIcon.ScanOutline width={'1em'} height={'1em'}/>,
    ScanSharp: <IonIcon.ScanSharp width={'1em'} height={'1em'}/>,
    Scan: <IonIcon.Scan width={'1em'} height={'1em'}/>,
    SchoolOutline: <IonIcon.SchoolOutline width={'1em'} height={'1em'}/>,
    SchoolSharp: <IonIcon.SchoolSharp width={'1em'} height={'1em'}/>,
    School: <IonIcon.School width={'1em'} height={'1em'}/>,
    SearchCircleOutline: <IonIcon.SearchCircleOutline width={'1em'} height={'1em'}/>,
    SearchCircleSharp: <IonIcon.SearchCircleSharp width={'1em'} height={'1em'}/>,
    SearchCircle: <IonIcon.SearchCircle width={'1em'} height={'1em'}/>,
    SearchOutline: <IonIcon.SearchOutline width={'1em'} height={'1em'}/>,
    SearchSharp: <IonIcon.SearchSharp width={'1em'} height={'1em'}/>,
    Search: <IonIcon.Search width={'1em'} height={'1em'}/>,
    SendOutline: <IonIcon.SendOutline width={'1em'} height={'1em'}/>,
    SendSharp: <IonIcon.SendSharp width={'1em'} height={'1em'}/>,
    Send: <IonIcon.Send width={'1em'} height={'1em'}/>,
    ServerOutline: <IonIcon.ServerOutline width={'1em'} height={'1em'}/>,
    ServerSharp: <IonIcon.ServerSharp width={'1em'} height={'1em'}/>,
    Server: <IonIcon.Server width={'1em'} height={'1em'}/>,
    SettingsOutline: <IonIcon.SettingsOutline width={'1em'} height={'1em'}/>,
    SettingsSharp: <IonIcon.SettingsSharp width={'1em'} height={'1em'}/>,
    Settings: <IonIcon.Settings width={'1em'} height={'1em'}/>,
    ShapesOutline: <IonIcon.ShapesOutline width={'1em'} height={'1em'}/>,
    ShapesSharp: <IonIcon.ShapesSharp width={'1em'} height={'1em'}/>,
    Shapes: <IonIcon.Shapes width={'1em'} height={'1em'}/>,
    ShareOutline: <IonIcon.ShareOutline width={'1em'} height={'1em'}/>,
    ShareSharp: <IonIcon.ShareSharp width={'1em'} height={'1em'}/>,
    ShareSocialOutline: <IonIcon.ShareSocialOutline width={'1em'} height={'1em'}/>,
    ShareSocialSharp: <IonIcon.ShareSocialSharp width={'1em'} height={'1em'}/>,
    ShareSocial: <IonIcon.ShareSocial width={'1em'} height={'1em'}/>,
    Share: <IonIcon.Share width={'1em'} height={'1em'}/>,
    ShieldCheckmarkOutline: <IonIcon.ShieldCheckmarkOutline width={'1em'} height={'1em'}/>,
    ShieldCheckmarkSharp: <IonIcon.ShieldCheckmarkSharp width={'1em'} height={'1em'}/>,
    ShieldCheckmark: <IonIcon.ShieldCheckmark width={'1em'} height={'1em'}/>,
    ShieldOutline: <IonIcon.ShieldOutline width={'1em'} height={'1em'}/>,
    ShieldSharp: <IonIcon.ShieldSharp width={'1em'} height={'1em'}/>,
    Shield: <IonIcon.Shield width={'1em'} height={'1em'}/>,
    ShirtOutline: <IonIcon.ShirtOutline width={'1em'} height={'1em'}/>,
    ShirtSharp: <IonIcon.ShirtSharp width={'1em'} height={'1em'}/>,
    Shirt: <IonIcon.Shirt width={'1em'} height={'1em'}/>,
    ShuffleOutline: <IonIcon.ShuffleOutline width={'1em'} height={'1em'}/>,
    ShuffleSharp: <IonIcon.ShuffleSharp width={'1em'} height={'1em'}/>,
    Shuffle: <IonIcon.Shuffle width={'1em'} height={'1em'}/>,
    SkullOutline: <IonIcon.SkullOutline width={'1em'} height={'1em'}/>,
    SkullSharp: <IonIcon.SkullSharp width={'1em'} height={'1em'}/>,
    Skull: <IonIcon.Skull width={'1em'} height={'1em'}/>,
    SnowOutline: <IonIcon.SnowOutline width={'1em'} height={'1em'}/>,
    SnowSharp: <IonIcon.SnowSharp width={'1em'} height={'1em'}/>,
    Snow: <IonIcon.Snow width={'1em'} height={'1em'}/>,
    SpeedometerOutline: <IonIcon.SpeedometerOutline width={'1em'} height={'1em'}/>,
    SpeedometerSharp: <IonIcon.SpeedometerSharp width={'1em'} height={'1em'}/>,
    Speedometer: <IonIcon.Speedometer width={'1em'} height={'1em'}/>,
    SquareOutline: <IonIcon.SquareOutline width={'1em'} height={'1em'}/>,
    SquareSharp: <IonIcon.SquareSharp width={'1em'} height={'1em'}/>,
    Square: <IonIcon.Square width={'1em'} height={'1em'}/>,
    StarHalfOutline: <IonIcon.StarHalfOutline width={'1em'} height={'1em'}/>,
    StarHalfSharp: <IonIcon.StarHalfSharp width={'1em'} height={'1em'}/>,
    StarHalf: <IonIcon.StarHalf width={'1em'} height={'1em'}/>,
    StarOutline: <IonIcon.StarOutline width={'1em'} height={'1em'}/>,
    StarSharp: <IonIcon.StarSharp width={'1em'} height={'1em'}/>,
    Star: <IonIcon.Star width={'1em'} height={'1em'}/>,
    StatsChartOutline: <IonIcon.StatsChartOutline width={'1em'} height={'1em'}/>,
    StatsChartSharp: <IonIcon.StatsChartSharp width={'1em'} height={'1em'}/>,
    StatsChart: <IonIcon.StatsChart width={'1em'} height={'1em'}/>,
    StopCircleOutline: <IonIcon.StopCircleOutline width={'1em'} height={'1em'}/>,
    StopCircleSharp: <IonIcon.StopCircleSharp width={'1em'} height={'1em'}/>,
    StopCircle: <IonIcon.StopCircle width={'1em'} height={'1em'}/>,
    StopOutline: <IonIcon.StopOutline width={'1em'} height={'1em'}/>,
    StopSharp: <IonIcon.StopSharp width={'1em'} height={'1em'}/>,
    Stop: <IonIcon.Stop width={'1em'} height={'1em'}/>,
    StopwatchOutline: <IonIcon.StopwatchOutline width={'1em'} height={'1em'}/>,
    StopwatchSharp: <IonIcon.StopwatchSharp width={'1em'} height={'1em'}/>,
    Stopwatch: <IonIcon.Stopwatch width={'1em'} height={'1em'}/>,
    StorefrontOutline: <IonIcon.StorefrontOutline width={'1em'} height={'1em'}/>,
    StorefrontSharp: <IonIcon.StorefrontSharp width={'1em'} height={'1em'}/>,
    Storefront: <IonIcon.Storefront width={'1em'} height={'1em'}/>,
    SubwayOutline: <IonIcon.SubwayOutline width={'1em'} height={'1em'}/>,
    SubwaySharp: <IonIcon.SubwaySharp width={'1em'} height={'1em'}/>,
    Subway: <IonIcon.Subway width={'1em'} height={'1em'}/>,
    SunnyOutline: <IonIcon.SunnyOutline width={'1em'} height={'1em'}/>,
    SunnySharp: <IonIcon.SunnySharp width={'1em'} height={'1em'}/>,
    Sunny: <IonIcon.Sunny width={'1em'} height={'1em'}/>,
    SwapHorizontalOutline: <IonIcon.SwapHorizontalOutline width={'1em'} height={'1em'}/>,
    SwapHorizontalSharp: <IonIcon.SwapHorizontalSharp width={'1em'} height={'1em'}/>,
    SwapHorizontal: <IonIcon.SwapHorizontal width={'1em'} height={'1em'}/>,
    SwapVerticalOutline: <IonIcon.SwapVerticalOutline width={'1em'} height={'1em'}/>,
    SwapVerticalSharp: <IonIcon.SwapVerticalSharp width={'1em'} height={'1em'}/>,
    SwapVertical: <IonIcon.SwapVertical width={'1em'} height={'1em'}/>,
    SyncCircleOutline: <IonIcon.SyncCircleOutline width={'1em'} height={'1em'}/>,
    SyncCircleSharp: <IonIcon.SyncCircleSharp width={'1em'} height={'1em'}/>,
    SyncCircle: <IonIcon.SyncCircle width={'1em'} height={'1em'}/>,
    SyncOutline: <IonIcon.SyncOutline width={'1em'} height={'1em'}/>,
    SyncSharp: <IonIcon.SyncSharp width={'1em'} height={'1em'}/>,
    Sync: <IonIcon.Sync width={'1em'} height={'1em'}/>,
    TabletLandscapeOutline: <IonIcon.TabletLandscapeOutline width={'1em'} height={'1em'}/>,
    TabletLandscapeSharp: <IonIcon.TabletLandscapeSharp width={'1em'} height={'1em'}/>,
    TabletLandscape: <IonIcon.TabletLandscape width={'1em'} height={'1em'}/>,
    TabletPortraitOutline: <IonIcon.TabletPortraitOutline width={'1em'} height={'1em'}/>,
    TabletPortraitSharp: <IonIcon.TabletPortraitSharp width={'1em'} height={'1em'}/>,
    TabletPortrait: <IonIcon.TabletPortrait width={'1em'} height={'1em'}/>,
    TelescopeOutline: <IonIcon.TelescopeOutline width={'1em'} height={'1em'}/>,
    TelescopeSharp: <IonIcon.TelescopeSharp width={'1em'} height={'1em'}/>,
    Telescope: <IonIcon.Telescope width={'1em'} height={'1em'}/>,
    TennisballOutline: <IonIcon.TennisballOutline width={'1em'} height={'1em'}/>,
    TennisballSharp: <IonIcon.TennisballSharp width={'1em'} height={'1em'}/>,
    Tennisball: <IonIcon.Tennisball width={'1em'} height={'1em'}/>,
    TerminalOutline: <IonIcon.TerminalOutline width={'1em'} height={'1em'}/>,
    TerminalSharp: <IonIcon.TerminalSharp width={'1em'} height={'1em'}/>,
    Terminal: <IonIcon.Terminal width={'1em'} height={'1em'}/>,
    TextOutline: <IonIcon.TextOutline width={'1em'} height={'1em'}/>,
    TextSharp: <IonIcon.TextSharp width={'1em'} height={'1em'}/>,
    Text: <IonIcon.Text width={'1em'} height={'1em'}/>,
    ThermometerOutline: <IonIcon.ThermometerOutline width={'1em'} height={'1em'}/>,
    ThermometerSharp: <IonIcon.ThermometerSharp width={'1em'} height={'1em'}/>,
    Thermometer: <IonIcon.Thermometer width={'1em'} height={'1em'}/>,
    ThumbsDownOutline: <IonIcon.ThumbsDownOutline width={'1em'} height={'1em'}/>,
    ThumbsDownSharp: <IonIcon.ThumbsDownSharp width={'1em'} height={'1em'}/>,
    ThumbsDown: <IonIcon.ThumbsDown width={'1em'} height={'1em'}/>,
    ThumbsUpOutline: <IonIcon.ThumbsUpOutline width={'1em'} height={'1em'}/>,
    ThumbsUpSharp: <IonIcon.ThumbsUpSharp width={'1em'} height={'1em'}/>,
    ThumbsUp: <IonIcon.ThumbsUp width={'1em'} height={'1em'}/>,
    ThunderstormOutline: <IonIcon.ThunderstormOutline width={'1em'} height={'1em'}/>,
    ThunderstormSharp: <IonIcon.ThunderstormSharp width={'1em'} height={'1em'}/>,
    Thunderstorm: <IonIcon.Thunderstorm width={'1em'} height={'1em'}/>,
    TicketOutline: <IonIcon.TicketOutline width={'1em'} height={'1em'}/>,
    TicketSharp: <IonIcon.TicketSharp width={'1em'} height={'1em'}/>,
    Ticket: <IonIcon.Ticket width={'1em'} height={'1em'}/>,
    TimeOutline: <IonIcon.TimeOutline width={'1em'} height={'1em'}/>,
    TimeSharp: <IonIcon.TimeSharp width={'1em'} height={'1em'}/>,
    Time: <IonIcon.Time width={'1em'} height={'1em'}/>,
    TimerOutline: <IonIcon.TimerOutline width={'1em'} height={'1em'}/>,
    TimerSharp: <IonIcon.TimerSharp width={'1em'} height={'1em'}/>,
    Timer: <IonIcon.Timer width={'1em'} height={'1em'}/>,
    TodayOutline: <IonIcon.TodayOutline width={'1em'} height={'1em'}/>,
    TodaySharp: <IonIcon.TodaySharp width={'1em'} height={'1em'}/>,
    Today: <IonIcon.Today width={'1em'} height={'1em'}/>,
    ToggleOutline: <IonIcon.ToggleOutline width={'1em'} height={'1em'}/>,
    ToggleSharp: <IonIcon.ToggleSharp width={'1em'} height={'1em'}/>,
    Toggle: <IonIcon.Toggle width={'1em'} height={'1em'}/>,
    TrailSignOutline: <IonIcon.TrailSignOutline width={'1em'} height={'1em'}/>,
    TrailSignSharp: <IonIcon.TrailSignSharp width={'1em'} height={'1em'}/>,
    TrailSign: <IonIcon.TrailSign width={'1em'} height={'1em'}/>,
    TrainOutline: <IonIcon.TrainOutline width={'1em'} height={'1em'}/>,
    TrainSharp: <IonIcon.TrainSharp width={'1em'} height={'1em'}/>,
    Train: <IonIcon.Train width={'1em'} height={'1em'}/>,
    TransgenderOutline: <IonIcon.TransgenderOutline width={'1em'} height={'1em'}/>,
    TransgenderSharp: <IonIcon.TransgenderSharp width={'1em'} height={'1em'}/>,
    Transgender: <IonIcon.Transgender width={'1em'} height={'1em'}/>,
    TrashBinOutline: <IonIcon.TrashBinOutline width={'1em'} height={'1em'}/>,
    TrashBinSharp: <IonIcon.TrashBinSharp width={'1em'} height={'1em'}/>,
    TrashBin: <IonIcon.TrashBin width={'1em'} height={'1em'}/>,
    TrashOutline: <IonIcon.TrashOutline width={'1em'} height={'1em'}/>,
    TrashSharp: <IonIcon.TrashSharp width={'1em'} height={'1em'}/>,
    Trash: <IonIcon.Trash width={'1em'} height={'1em'}/>,
    TrendingDownOutline: <IonIcon.TrendingDownOutline width={'1em'} height={'1em'}/>,
    TrendingDownSharp: <IonIcon.TrendingDownSharp width={'1em'} height={'1em'}/>,
    TrendingDown: <IonIcon.TrendingDown width={'1em'} height={'1em'}/>,
    TrendingUpOutline: <IonIcon.TrendingUpOutline width={'1em'} height={'1em'}/>,
    TrendingUpSharp: <IonIcon.TrendingUpSharp width={'1em'} height={'1em'}/>,
    TrendingUp: <IonIcon.TrendingUp width={'1em'} height={'1em'}/>,
    TriangleOutline: <IonIcon.TriangleOutline width={'1em'} height={'1em'}/>,
    TriangleSharp: <IonIcon.TriangleSharp width={'1em'} height={'1em'}/>,
    Triangle: <IonIcon.Triangle width={'1em'} height={'1em'}/>,
    TrophyOutline: <IonIcon.TrophyOutline width={'1em'} height={'1em'}/>,
    TrophySharp: <IonIcon.TrophySharp width={'1em'} height={'1em'}/>,
    Trophy: <IonIcon.Trophy width={'1em'} height={'1em'}/>,
    TvOutline: <IonIcon.TvOutline width={'1em'} height={'1em'}/>,
    TvSharp: <IonIcon.TvSharp width={'1em'} height={'1em'}/>,
    Tv: <IonIcon.Tv width={'1em'} height={'1em'}/>,
    UmbrellaOutline: <IonIcon.UmbrellaOutline width={'1em'} height={'1em'}/>,
    UmbrellaSharp: <IonIcon.UmbrellaSharp width={'1em'} height={'1em'}/>,
    Umbrella: <IonIcon.Umbrella width={'1em'} height={'1em'}/>,
    UnlinkOutline: <IonIcon.UnlinkOutline width={'1em'} height={'1em'}/>,
    UnlinkSharp: <IonIcon.UnlinkSharp width={'1em'} height={'1em'}/>,
    Unlink: <IonIcon.Unlink width={'1em'} height={'1em'}/>,
    VideocamOffOutline: <IonIcon.VideocamOffOutline width={'1em'} height={'1em'}/>,
    VideocamOffSharp: <IonIcon.VideocamOffSharp width={'1em'} height={'1em'}/>,
    VideocamOff: <IonIcon.VideocamOff width={'1em'} height={'1em'}/>,
    VideocamOutline: <IonIcon.VideocamOutline width={'1em'} height={'1em'}/>,
    VideocamSharp: <IonIcon.VideocamSharp width={'1em'} height={'1em'}/>,
    Videocam: <IonIcon.Videocam width={'1em'} height={'1em'}/>,
    VolumeHighOutline: <IonIcon.VolumeHighOutline width={'1em'} height={'1em'}/>,
    VolumeHighSharp: <IonIcon.VolumeHighSharp width={'1em'} height={'1em'}/>,
    VolumeHigh: <IonIcon.VolumeHigh width={'1em'} height={'1em'}/>,
    VolumeLowOutline: <IonIcon.VolumeLowOutline width={'1em'} height={'1em'}/>,
    VolumeLowSharp: <IonIcon.VolumeLowSharp width={'1em'} height={'1em'}/>,
    VolumeLow: <IonIcon.VolumeLow width={'1em'} height={'1em'}/>,
    VolumeMediumOutline: <IonIcon.VolumeMediumOutline width={'1em'} height={'1em'}/>,
    VolumeMediumSharp: <IonIcon.VolumeMediumSharp width={'1em'} height={'1em'}/>,
    VolumeMedium: <IonIcon.VolumeMedium width={'1em'} height={'1em'}/>,
    VolumeMuteOutline: <IonIcon.VolumeMuteOutline width={'1em'} height={'1em'}/>,
    VolumeMuteSharp: <IonIcon.VolumeMuteSharp width={'1em'} height={'1em'}/>,
    VolumeMute: <IonIcon.VolumeMute width={'1em'} height={'1em'}/>,
    VolumeOffOutline: <IonIcon.VolumeOffOutline width={'1em'} height={'1em'}/>,
    VolumeOffSharp: <IonIcon.VolumeOffSharp width={'1em'} height={'1em'}/>,
    VolumeOff: <IonIcon.VolumeOff width={'1em'} height={'1em'}/>,
    WalkOutline: <IonIcon.WalkOutline width={'1em'} height={'1em'}/>,
    WalkSharp: <IonIcon.WalkSharp width={'1em'} height={'1em'}/>,
    Walk: <IonIcon.Walk width={'1em'} height={'1em'}/>,
    WalletOutline: <IonIcon.WalletOutline width={'1em'} height={'1em'}/>,
    WalletSharp: <IonIcon.WalletSharp width={'1em'} height={'1em'}/>,
    Wallet: <IonIcon.Wallet width={'1em'} height={'1em'}/>,
    WarningOutline: <IonIcon.WarningOutline width={'1em'} height={'1em'}/>,
    WarningSharp: <IonIcon.WarningSharp width={'1em'} height={'1em'}/>,
    Warning: <IonIcon.Warning width={'1em'} height={'1em'}/>,
    WatchOutline: <IonIcon.WatchOutline width={'1em'} height={'1em'}/>,
    WatchSharp: <IonIcon.WatchSharp width={'1em'} height={'1em'}/>,
    Watch: <IonIcon.Watch width={'1em'} height={'1em'}/>,
    WaterOutline: <IonIcon.WaterOutline width={'1em'} height={'1em'}/>,
    WaterSharp: <IonIcon.WaterSharp width={'1em'} height={'1em'}/>,
    Water: <IonIcon.Water width={'1em'} height={'1em'}/>,
    WifiOutline: <IonIcon.WifiOutline width={'1em'} height={'1em'}/>,
    WifiSharp: <IonIcon.WifiSharp width={'1em'} height={'1em'}/>,
    Wifi: <IonIcon.Wifi width={'1em'} height={'1em'}/>,
    WineOutline: <IonIcon.WineOutline width={'1em'} height={'1em'}/>,
    WineSharp: <IonIcon.WineSharp width={'1em'} height={'1em'}/>,
    Wine: <IonIcon.Wine width={'1em'} height={'1em'}/>,
    WomanOutline: <IonIcon.WomanOutline width={'1em'} height={'1em'}/>,
    WomanSharp: <IonIcon.WomanSharp width={'1em'} height={'1em'}/>,
    Woman: <IonIcon.Woman width={'1em'} height={'1em'}/>
}

export const ionIconsClasses: { [name: string]: any } = {
    AccessibilityOutline: IonIcon.AccessibilityOutline,
    AccessibilitySharp: IonIcon.AccessibilitySharp,
    Accessibility: IonIcon.Accessibility,
    AddCircleOutline: IonIcon.AddCircleOutline,
    AddCircleSharp: IonIcon.AddCircleSharp,
    AddCircle: IonIcon.AddCircle,
    AddOutline: IonIcon.AddOutline,
    AddSharp: IonIcon.AddSharp,
    Add: IonIcon.Add,
    AirplaneOutline: IonIcon.AirplaneOutline,
    AirplaneSharp: IonIcon.AirplaneSharp,
    Airplane: IonIcon.Airplane,
    AlarmOutline: IonIcon.AlarmOutline,
    AlarmSharp: IonIcon.AlarmSharp,
    Alarm: IonIcon.Alarm,
    AlbumsOutline: IonIcon.AlbumsOutline,
    AlbumsSharp: IonIcon.AlbumsSharp,
    Albums: IonIcon.Albums,
    AlertCircleOutline: IonIcon.AlertCircleOutline,
    AlertCircleSharp: IonIcon.AlertCircleSharp,
    AlertCircle: IonIcon.AlertCircle,
    AlertOutline: IonIcon.AlertOutline,
    AlertSharp: IonIcon.AlertSharp,
    Alert: IonIcon.Alert,
    AmericanFootballOutline: IonIcon.AmericanFootballOutline,
    AmericanFootballSharp: IonIcon.AmericanFootballSharp,
    AmericanFootball: IonIcon.AmericanFootball,
    AnalyticsOutline: IonIcon.AnalyticsOutline,
    AnalyticsSharp: IonIcon.AnalyticsSharp,
    Analytics: IonIcon.Analytics,
    ApertureOutline: IonIcon.ApertureOutline,
    ApertureSharp: IonIcon.ApertureSharp,
    Aperture: IonIcon.Aperture,
    AppsOutline: IonIcon.AppsOutline,
    AppsSharp: IonIcon.AppsSharp,
    Apps: IonIcon.Apps,
    ArchiveOutline: IonIcon.ArchiveOutline,
    ArchiveSharp: IonIcon.ArchiveSharp,
    Archive: IonIcon.Archive,
    ArrowBackCircleOutline: IonIcon.ArrowBackCircleOutline,
    ArrowBackCircleSharp: IonIcon.ArrowBackCircleSharp,
    ArrowBackCircle: IonIcon.ArrowBackCircle,
    ArrowBackOutline: IonIcon.ArrowBackOutline,
    ArrowBackSharp: IonIcon.ArrowBackSharp,
    ArrowBack: IonIcon.ArrowBack,
    ArrowDownCircleOutline: IonIcon.ArrowDownCircleOutline,
    ArrowDownCircleSharp: IonIcon.ArrowDownCircleSharp,
    ArrowDownCircle: IonIcon.ArrowDownCircle,
    ArrowDownOutline: IonIcon.ArrowDownOutline,
    ArrowDownSharp: IonIcon.ArrowDownSharp,
    ArrowDown: IonIcon.ArrowDown,
    ArrowForwardCircleOutline: IonIcon.ArrowForwardCircleOutline,
    ArrowForwardCircleSharp: IonIcon.ArrowForwardCircleSharp,
    ArrowForwardCircle: IonIcon.ArrowForwardCircle,
    ArrowForwardOutline: IonIcon.ArrowForwardOutline,
    ArrowForwardSharp: IonIcon.ArrowForwardSharp,
    ArrowForward: IonIcon.ArrowForward,
    ArrowRedoCircleOutline: IonIcon.ArrowRedoCircleOutline,
    ArrowRedoCircleSharp: IonIcon.ArrowRedoCircleSharp,
    ArrowRedoCircle: IonIcon.ArrowRedoCircle,
    ArrowRedoOutline: IonIcon.ArrowRedoOutline,
    ArrowRedoSharp: IonIcon.ArrowRedoSharp,
    ArrowRedo: IonIcon.ArrowRedo,
    ArrowUndoCircleOutline: IonIcon.ArrowUndoCircleOutline,
    ArrowUndoCircleSharp: IonIcon.ArrowUndoCircleSharp,
    ArrowUndoCircle: IonIcon.ArrowUndoCircle,
    ArrowUndoOutline: IonIcon.ArrowUndoOutline,
    ArrowUndoSharp: IonIcon.ArrowUndoSharp,
    ArrowUndo: IonIcon.ArrowUndo,
    ArrowUpCircleOutline: IonIcon.ArrowUpCircleOutline,
    ArrowUpCircleSharp: IonIcon.ArrowUpCircleSharp,
    ArrowUpCircle: IonIcon.ArrowUpCircle,
    ArrowUpOutline: IonIcon.ArrowUpOutline,
    ArrowUpSharp: IonIcon.ArrowUpSharp,
    ArrowUp: IonIcon.ArrowUp,
    AtCircleOutline: IonIcon.AtCircleOutline,
    AtCircleSharp: IonIcon.AtCircleSharp,
    AtCircle: IonIcon.AtCircle,
    AtOutline: IonIcon.AtOutline,
    AtSharp: IonIcon.AtSharp,
    At: IonIcon.At,
    AttachOutline: IonIcon.AttachOutline,
    AttachSharp: IonIcon.AttachSharp,
    Attach: IonIcon.Attach,
    BackspaceOutline: IonIcon.BackspaceOutline,
    BackspaceSharp: IonIcon.BackspaceSharp,
    Backspace: IonIcon.Backspace,
    BagAddOutline: IonIcon.BagAddOutline,
    BagAddSharp: IonIcon.BagAddSharp,
    BagAdd: IonIcon.BagAdd,
    BagCheckOutline: IonIcon.BagCheckOutline,
    BagCheckSharp: IonIcon.BagCheckSharp,
    BagCheck: IonIcon.BagCheck,
    BagHandleOutline: IonIcon.BagHandleOutline,
    BagHandleSharp: IonIcon.BagHandleSharp,
    BagHandle: IonIcon.BagHandle,
    BagOutline: IonIcon.BagOutline,
    BagRemoveOutline: IonIcon.BagRemoveOutline,
    BagRemoveSharp: IonIcon.BagRemoveSharp,
    BagRemove: IonIcon.BagRemove,
    BagSharp: IonIcon.BagSharp,
    Bag: IonIcon.Bag,
    BanOutline: IonIcon.BanOutline,
    BanSharp: IonIcon.BanSharp,
    Ban: IonIcon.Ban,
    BandageOutline: IonIcon.BandageOutline,
    BandageSharp: IonIcon.BandageSharp,
    Bandage: IonIcon.Bandage,
    BarChartOutline: IonIcon.BarChartOutline,
    BarChartSharp: IonIcon.BarChartSharp,
    BarChart: IonIcon.BarChart,
    BarbellOutline: IonIcon.BarbellOutline,
    BarbellSharp: IonIcon.BarbellSharp,
    Barbell: IonIcon.Barbell,
    BarcodeOutline: IonIcon.BarcodeOutline,
    BarcodeSharp: IonIcon.BarcodeSharp,
    Barcode: IonIcon.Barcode,
    BaseballOutline: IonIcon.BaseballOutline,
    BaseballSharp: IonIcon.BaseballSharp,
    Baseball: IonIcon.Baseball,
    BasketOutline: IonIcon.BasketOutline,
    BasketSharp: IonIcon.BasketSharp,
    Basket: IonIcon.Basket,
    BasketballOutline: IonIcon.BasketballOutline,
    BasketballSharp: IonIcon.BasketballSharp,
    Basketball: IonIcon.Basketball,
    BatteryChargingOutline: IonIcon.BatteryChargingOutline,
    BatteryChargingSharp: IonIcon.BatteryChargingSharp,
    BatteryCharging: IonIcon.BatteryCharging,
    BatteryDeadOutline: IonIcon.BatteryDeadOutline,
    BatteryDeadSharp: IonIcon.BatteryDeadSharp,
    BatteryDead: IonIcon.BatteryDead,
    BatteryFullOutline: IonIcon.BatteryFullOutline,
    BatteryFullSharp: IonIcon.BatteryFullSharp,
    BatteryFull: IonIcon.BatteryFull,
    BatteryHalfOutline: IonIcon.BatteryHalfOutline,
    BatteryHalfSharp: IonIcon.BatteryHalfSharp,
    BatteryHalf: IonIcon.BatteryHalf,
    BeakerOutline: IonIcon.BeakerOutline,
    BeakerSharp: IonIcon.BeakerSharp,
    Beaker: IonIcon.Beaker,
    BedOutline: IonIcon.BedOutline,
    BedSharp: IonIcon.BedSharp,
    Bed: IonIcon.Bed,
    BeerOutline: IonIcon.BeerOutline,
    BeerSharp: IonIcon.BeerSharp,
    Beer: IonIcon.Beer,
    BicycleOutline: IonIcon.BicycleOutline,
    BicycleSharp: IonIcon.BicycleSharp,
    Bicycle: IonIcon.Bicycle,
    BluetoothOutline: IonIcon.BluetoothOutline,
    BluetoothSharp: IonIcon.BluetoothSharp,
    Bluetooth: IonIcon.Bluetooth,
    BoatOutline: IonIcon.BoatOutline,
    BoatSharp: IonIcon.BoatSharp,
    Boat: IonIcon.Boat,
    BodyOutline: IonIcon.BodyOutline,
    BodySharp: IonIcon.BodySharp,
    Body: IonIcon.Body,
    BonfireOutline: IonIcon.BonfireOutline,
    BonfireSharp: IonIcon.BonfireSharp,
    Bonfire: IonIcon.Bonfire,
    BookOutline: IonIcon.BookOutline,
    BookSharp: IonIcon.BookSharp,
    Book: IonIcon.Book,
    BookmarkOutline: IonIcon.BookmarkOutline,
    BookmarkSharp: IonIcon.BookmarkSharp,
    Bookmark: IonIcon.Bookmark,
    BookmarksOutline: IonIcon.BookmarksOutline,
    BookmarksSharp: IonIcon.BookmarksSharp,
    Bookmarks: IonIcon.Bookmarks,
    BriefcaseOutline: IonIcon.BriefcaseOutline,
    BriefcaseSharp: IonIcon.BriefcaseSharp,
    Briefcase: IonIcon.Briefcase,
    BrowsersOutline: IonIcon.BrowsersOutline,
    BrowsersSharp: IonIcon.BrowsersSharp,
    Browsers: IonIcon.Browsers,
    BrushOutline: IonIcon.BrushOutline,
    BrushSharp: IonIcon.BrushSharp,
    Brush: IonIcon.Brush,
    BugOutline: IonIcon.BugOutline,
    BugSharp: IonIcon.BugSharp,
    Bug: IonIcon.Bug,
    BuildOutline: IonIcon.BuildOutline,
    BuildSharp: IonIcon.BuildSharp,
    Build: IonIcon.Build,
    BulbOutline: IonIcon.BulbOutline,
    BulbSharp: IonIcon.BulbSharp,
    Bulb: IonIcon.Bulb,
    BusOutline: IonIcon.BusOutline,
    BusSharp: IonIcon.BusSharp,
    Bus: IonIcon.Bus,
    BusinessOutline: IonIcon.BusinessOutline,
    BusinessSharp: IonIcon.BusinessSharp,
    Business: IonIcon.Business,
    CafeOutline: IonIcon.CafeOutline,
    CafeSharp: IonIcon.CafeSharp,
    Cafe: IonIcon.Cafe,
    CalculatorOutline: IonIcon.CalculatorOutline,
    CalculatorSharp: IonIcon.CalculatorSharp,
    Calculator: IonIcon.Calculator,
    CalendarClearOutline: IonIcon.CalendarClearOutline,
    CalendarClearSharp: IonIcon.CalendarClearSharp,
    CalendarClear: IonIcon.CalendarClear,
    CalendarOutline: IonIcon.CalendarOutline,
    CalendarSharp: IonIcon.CalendarSharp,
    Calendar: IonIcon.Calendar,
    CallOutline: IonIcon.CallOutline,
    CallSharp: IonIcon.CallSharp,
    Call: IonIcon.Call,
    CameraOutline: IonIcon.CameraOutline,
    CameraReverseOutline: IonIcon.CameraReverseOutline,
    CameraReverseSharp: IonIcon.CameraReverseSharp,
    CameraReverse: IonIcon.CameraReverse,
    CameraSharp: IonIcon.CameraSharp,
    Camera: IonIcon.Camera,
    CarOutline: IonIcon.CarOutline,
    CarSharp: IonIcon.CarSharp,
    CarSportOutline: IonIcon.CarSportOutline,
    CarSportSharp: IonIcon.CarSportSharp,
    CarSport: IonIcon.CarSport,
    Car: IonIcon.Car,
    CardOutline: IonIcon.CardOutline,
    CardSharp: IonIcon.CardSharp,
    Card: IonIcon.Card,
    CaretBackCircleOutline: IonIcon.CaretBackCircleOutline,
    CaretBackCircleSharp: IonIcon.CaretBackCircleSharp,
    CaretBackCircle: IonIcon.CaretBackCircle,
    CaretBackOutline: IonIcon.CaretBackOutline,
    CaretBackSharp: IonIcon.CaretBackSharp,
    CaretBack: IonIcon.CaretBack,
    CaretDownCircleOutline: IonIcon.CaretDownCircleOutline,
    CaretDownCircleSharp: IonIcon.CaretDownCircleSharp,
    CaretDownCircle: IonIcon.CaretDownCircle,
    CaretDownOutline: IonIcon.CaretDownOutline,
    CaretDownSharp: IonIcon.CaretDownSharp,
    CaretDown: IonIcon.CaretDown,
    CaretForwardCircleOutline: IonIcon.CaretForwardCircleOutline,
    CaretForwardCircleSharp: IonIcon.CaretForwardCircleSharp,
    CaretForwardCircle: IonIcon.CaretForwardCircle,
    CaretForwardOutline: IonIcon.CaretForwardOutline,
    CaretForwardSharp: IonIcon.CaretForwardSharp,
    CaretForward: IonIcon.CaretForward,
    CaretUpCircleOutline: IonIcon.CaretUpCircleOutline,
    CaretUpCircleSharp: IonIcon.CaretUpCircleSharp,
    CaretUpCircle: IonIcon.CaretUpCircle,
    CaretUpOutline: IonIcon.CaretUpOutline,
    CaretUpSharp: IonIcon.CaretUpSharp,
    CaretUp: IonIcon.CaretUp,
    CartOutline: IonIcon.CartOutline,
    CartSharp: IonIcon.CartSharp,
    Cart: IonIcon.Cart,
    CashOutline: IonIcon.CashOutline,
    CashSharp: IonIcon.CashSharp,
    Cash: IonIcon.Cash,
    CellularOutline: IonIcon.CellularOutline,
    CellularSharp: IonIcon.CellularSharp,
    Cellular: IonIcon.Cellular,
    ChatboxEllipsesOutline: IonIcon.ChatboxEllipsesOutline,
    ChatboxEllipsesSharp: IonIcon.ChatboxEllipsesSharp,
    ChatboxEllipses: IonIcon.ChatboxEllipses,
    ChatboxOutline: IonIcon.ChatboxOutline,
    ChatboxSharp: IonIcon.ChatboxSharp,
    Chatbox: IonIcon.Chatbox,
    ChatbubbleEllipsesOutline: IonIcon.ChatbubbleEllipsesOutline,
    ChatbubbleEllipsesSharp: IonIcon.ChatbubbleEllipsesSharp,
    ChatbubbleEllipses: IonIcon.ChatbubbleEllipses,
    ChatbubbleOutline: IonIcon.ChatbubbleOutline,
    ChatbubbleSharp: IonIcon.ChatbubbleSharp,
    Chatbubble: IonIcon.Chatbubble,
    ChatbubblesOutline: IonIcon.ChatbubblesOutline,
    ChatbubblesSharp: IonIcon.ChatbubblesSharp,
    Chatbubbles: IonIcon.Chatbubbles,
    CheckboxOutline: IonIcon.CheckboxOutline,
    CheckboxSharp: IonIcon.CheckboxSharp,
    Checkbox: IonIcon.Checkbox,
    CheckmarkCircleOutline: IonIcon.CheckmarkCircleOutline,
    CheckmarkCircleSharp: IonIcon.CheckmarkCircleSharp,
    CheckmarkCircle: IonIcon.CheckmarkCircle,
    CheckmarkDoneCircleOutline: IonIcon.CheckmarkDoneCircleOutline,
    CheckmarkDoneCircleSharp: IonIcon.CheckmarkDoneCircleSharp,
    CheckmarkDoneCircle: IonIcon.CheckmarkDoneCircle,
    CheckmarkDoneOutline: IonIcon.CheckmarkDoneOutline,
    CheckmarkDoneSharp: IonIcon.CheckmarkDoneSharp,
    CheckmarkDone: IonIcon.CheckmarkDone,
    CheckmarkOutline: IonIcon.CheckmarkOutline,
    CheckmarkSharp: IonIcon.CheckmarkSharp,
    Checkmark: IonIcon.Checkmark,
    ChevronBackCircleOutline: IonIcon.ChevronBackCircleOutline,
    ChevronBackCircleSharp: IonIcon.ChevronBackCircleSharp,
    ChevronBackCircle: IonIcon.ChevronBackCircle,
    ChevronBackOutline: IonIcon.ChevronBackOutline,
    ChevronBackSharp: IonIcon.ChevronBackSharp,
    ChevronBack: IonIcon.ChevronBack,
    ChevronDownCircleOutline: IonIcon.ChevronDownCircleOutline,
    ChevronDownCircleSharp: IonIcon.ChevronDownCircleSharp,
    ChevronDownCircle: IonIcon.ChevronDownCircle,
    ChevronDownOutline: IonIcon.ChevronDownOutline,
    ChevronDownSharp: IonIcon.ChevronDownSharp,
    ChevronDown: IonIcon.ChevronDown,
    ChevronForwardCircleOutline: IonIcon.ChevronForwardCircleOutline,
    ChevronForwardCircleSharp: IonIcon.ChevronForwardCircleSharp,
    ChevronForwardCircle: IonIcon.ChevronForwardCircle,
    ChevronForwardOutline: IonIcon.ChevronForwardOutline,
    ChevronForwardSharp: IonIcon.ChevronForwardSharp,
    ChevronForward: IonIcon.ChevronForward,
    ChevronUpCircleOutline: IonIcon.ChevronUpCircleOutline,
    ChevronUpCircleSharp: IonIcon.ChevronUpCircleSharp,
    ChevronUpCircle: IonIcon.ChevronUpCircle,
    ChevronUpOutline: IonIcon.ChevronUpOutline,
    ChevronUpSharp: IonIcon.ChevronUpSharp,
    ChevronUp: IonIcon.ChevronUp,
    ClipboardOutline: IonIcon.ClipboardOutline,
    ClipboardSharp: IonIcon.ClipboardSharp,
    Clipboard: IonIcon.Clipboard,
    CloseCircleOutline: IonIcon.CloseCircleOutline,
    CloseCircleSharp: IonIcon.CloseCircleSharp,
    CloseCircle: IonIcon.CloseCircle,
    CloseOutline: IonIcon.CloseOutline,
    CloseSharp: IonIcon.CloseSharp,
    Close: IonIcon.Close,
    CloudCircleOutline: IonIcon.CloudCircleOutline,
    CloudCircleSharp: IonIcon.CloudCircleSharp,
    CloudCircle: IonIcon.CloudCircle,
    CloudDoneOutline: IonIcon.CloudDoneOutline,
    CloudDoneSharp: IonIcon.CloudDoneSharp,
    CloudDone: IonIcon.CloudDone,
    CloudDownloadOutline: IonIcon.CloudDownloadOutline,
    CloudDownloadSharp: IonIcon.CloudDownloadSharp,
    CloudDownload: IonIcon.CloudDownload,
    CloudOfflineOutline: IonIcon.CloudOfflineOutline,
    CloudOfflineSharp: IonIcon.CloudOfflineSharp,
    CloudOffline: IonIcon.CloudOffline,
    CloudOutline: IonIcon.CloudOutline,
    CloudSharp: IonIcon.CloudSharp,
    CloudUploadOutline: IonIcon.CloudUploadOutline,
    CloudUploadSharp: IonIcon.CloudUploadSharp,
    CloudUpload: IonIcon.CloudUpload,
    Cloud: IonIcon.Cloud,
    CloudyNightOutline: IonIcon.CloudyNightOutline,
    CloudyNightSharp: IonIcon.CloudyNightSharp,
    CloudyNight: IonIcon.CloudyNight,
    CloudyOutline: IonIcon.CloudyOutline,
    CloudySharp: IonIcon.CloudySharp,
    Cloudy: IonIcon.Cloudy,
    CodeDownloadOutline: IonIcon.CodeDownloadOutline,
    CodeDownloadSharp: IonIcon.CodeDownloadSharp,
    CodeDownload: IonIcon.CodeDownload,
    CodeOutline: IonIcon.CodeOutline,
    CodeSharp: IonIcon.CodeSharp,
    CodeSlashOutline: IonIcon.CodeSlashOutline,
    CodeSlashSharp: IonIcon.CodeSlashSharp,
    CodeSlash: IonIcon.CodeSlash,
    CodeWorkingOutline: IonIcon.CodeWorkingOutline,
    CodeWorkingSharp: IonIcon.CodeWorkingSharp,
    CodeWorking: IonIcon.CodeWorking,
    Code: IonIcon.Code,
    CogOutline: IonIcon.CogOutline,
    CogSharp: IonIcon.CogSharp,
    Cog: IonIcon.Cog,
    ColorFillOutline: IonIcon.ColorFillOutline,
    ColorFillSharp: IonIcon.ColorFillSharp,
    ColorFill: IonIcon.ColorFill,
    ColorFilterOutline: IonIcon.ColorFilterOutline,
    ColorFilterSharp: IonIcon.ColorFilterSharp,
    ColorFilter: IonIcon.ColorFilter,
    ColorPaletteOutline: IonIcon.ColorPaletteOutline,
    ColorPaletteSharp: IonIcon.ColorPaletteSharp,
    ColorPalette: IonIcon.ColorPalette,
    ColorWandOutline: IonIcon.ColorWandOutline,
    ColorWandSharp: IonIcon.ColorWandSharp,
    ColorWand: IonIcon.ColorWand,
    CompassOutline: IonIcon.CompassOutline,
    CompassSharp: IonIcon.CompassSharp,
    Compass: IonIcon.Compass,
    ConstructOutline: IonIcon.ConstructOutline,
    ConstructSharp: IonIcon.ConstructSharp,
    Construct: IonIcon.Construct,
    ContractOutline: IonIcon.ContractOutline,
    ContractSharp: IonIcon.ContractSharp,
    Contract: IonIcon.Contract,
    ContrastOutline: IonIcon.ContrastOutline,
    ContrastSharp: IonIcon.ContrastSharp,
    Contrast: IonIcon.Contrast,
    CopyOutline: IonIcon.CopyOutline,
    CopySharp: IonIcon.CopySharp,
    Copy: IonIcon.Copy,
    CreateOutline: IonIcon.CreateOutline,
    CreateSharp: IonIcon.CreateSharp,
    Create: IonIcon.Create,
    CropOutline: IonIcon.CropOutline,
    CropSharp: IonIcon.CropSharp,
    Crop: IonIcon.Crop,
    CubeOutline: IonIcon.CubeOutline,
    CubeSharp: IonIcon.CubeSharp,
    Cube: IonIcon.Cube,
    CutOutline: IonIcon.CutOutline,
    CutSharp: IonIcon.CutSharp,
    Cut: IonIcon.Cut,
    DesktopOutline: IonIcon.DesktopOutline,
    DesktopSharp: IonIcon.DesktopSharp,
    Desktop: IonIcon.Desktop,
    DiceOutline: IonIcon.DiceOutline,
    DiceSharp: IonIcon.DiceSharp,
    Dice: IonIcon.Dice,
    DiscOutline: IonIcon.DiscOutline,
    DiscSharp: IonIcon.DiscSharp,
    Disc: IonIcon.Disc,
    DocumentAttachOutline: IonIcon.DocumentAttachOutline,
    DocumentAttachSharp: IonIcon.DocumentAttachSharp,
    DocumentAttach: IonIcon.DocumentAttach,
    DocumentLockOutline: IonIcon.DocumentLockOutline,
    DocumentLockSharp: IonIcon.DocumentLockSharp,
    DocumentLock: IonIcon.DocumentLock,
    DocumentOutline: IonIcon.DocumentOutline,
    DocumentSharp: IonIcon.DocumentSharp,
    DocumentTextOutline: IonIcon.DocumentTextOutline,
    DocumentTextSharp: IonIcon.DocumentTextSharp,
    DocumentText: IonIcon.DocumentText,
    Document: IonIcon.Document,
    DocumentsOutline: IonIcon.DocumentsOutline,
    DocumentsSharp: IonIcon.DocumentsSharp,
    Documents: IonIcon.Documents,
    DownloadOutline: IonIcon.DownloadOutline,
    DownloadSharp: IonIcon.DownloadSharp,
    Download: IonIcon.Download,
    DuplicateOutline: IonIcon.DuplicateOutline,
    DuplicateSharp: IonIcon.DuplicateSharp,
    Duplicate: IonIcon.Duplicate,
    EarOutline: IonIcon.EarOutline,
    EarSharp: IonIcon.EarSharp,
    Ear: IonIcon.Ear,
    EarthOutline: IonIcon.EarthOutline,
    EarthSharp: IonIcon.EarthSharp,
    Earth: IonIcon.Earth,
    EaselOutline: IonIcon.EaselOutline,
    EaselSharp: IonIcon.EaselSharp,
    Easel: IonIcon.Easel,
    EggOutline: IonIcon.EggOutline,
    EggSharp: IonIcon.EggSharp,
    Egg: IonIcon.Egg,
    EllipseOutline: IonIcon.EllipseOutline,
    EllipseSharp: IonIcon.EllipseSharp,
    Ellipse: IonIcon.Ellipse,
    EllipsisHorizontalCircleOutline: IonIcon.EllipsisHorizontalCircleOutline,
    EllipsisHorizontalCircleSharp: IonIcon.EllipsisHorizontalCircleSharp,
    EllipsisHorizontalCircle: IonIcon.EllipsisHorizontalCircle,
    EllipsisHorizontalOutline: IonIcon.EllipsisHorizontalOutline,
    EllipsisHorizontalSharp: IonIcon.EllipsisHorizontalSharp,
    EllipsisHorizontal: IonIcon.EllipsisHorizontal,
    EllipsisVerticalCircleOutline: IonIcon.EllipsisVerticalCircleOutline,
    EllipsisVerticalCircleSharp: IonIcon.EllipsisVerticalCircleSharp,
    EllipsisVerticalCircle: IonIcon.EllipsisVerticalCircle,
    EllipsisVerticalOutline: IonIcon.EllipsisVerticalOutline,
    EllipsisVerticalSharp: IonIcon.EllipsisVerticalSharp,
    EllipsisVertical: IonIcon.EllipsisVertical,
    EnterOutline: IonIcon.EnterOutline,
    EnterSharp: IonIcon.EnterSharp,
    Enter: IonIcon.Enter,
    ExitOutline: IonIcon.ExitOutline,
    ExitSharp: IonIcon.ExitSharp,
    Exit: IonIcon.Exit,
    ExpandOutline: IonIcon.ExpandOutline,
    ExpandSharp: IonIcon.ExpandSharp,
    Expand: IonIcon.Expand,
    ExtensionPuzzleOutline: IonIcon.ExtensionPuzzleOutline,
    ExtensionPuzzleSharp: IonIcon.ExtensionPuzzleSharp,
    ExtensionPuzzle: IonIcon.ExtensionPuzzle,
    EyeOffOutline: IonIcon.EyeOffOutline,
    EyeOffSharp: IonIcon.EyeOffSharp,
    EyeOff: IonIcon.EyeOff,
    EyeOutline: IonIcon.EyeOutline,
    EyeSharp: IonIcon.EyeSharp,
    Eye: IonIcon.Eye,
    EyedropOutline: IonIcon.EyedropOutline,
    EyedropSharp: IonIcon.EyedropSharp,
    Eyedrop: IonIcon.Eyedrop,
    FastFoodOutline: IonIcon.FastFoodOutline,
    FastFoodSharp: IonIcon.FastFoodSharp,
    FastFood: IonIcon.FastFood,
    FemaleOutline: IonIcon.FemaleOutline,
    FemaleSharp: IonIcon.FemaleSharp,
    Female: IonIcon.Female,
    FileTrayFullOutline: IonIcon.FileTrayFullOutline,
    FileTrayFullSharp: IonIcon.FileTrayFullSharp,
    FileTrayFull: IonIcon.FileTrayFull,
    FileTrayOutline: IonIcon.FileTrayOutline,
    FileTraySharp: IonIcon.FileTraySharp,
    FileTrayStackedOutline: IonIcon.FileTrayStackedOutline,
    FileTrayStackedSharp: IonIcon.FileTrayStackedSharp,
    FileTrayStacked: IonIcon.FileTrayStacked,
    FileTray: IonIcon.FileTray,
    FilmOutline: IonIcon.FilmOutline,
    FilmSharp: IonIcon.FilmSharp,
    Film: IonIcon.Film,
    FilterCircleOutline: IonIcon.FilterCircleOutline,
    FilterCircleSharp: IonIcon.FilterCircleSharp,
    FilterCircle: IonIcon.FilterCircle,
    FilterOutline: IonIcon.FilterOutline,
    FilterSharp: IonIcon.FilterSharp,
    Filter: IonIcon.Filter,
    FingerPrintOutline: IonIcon.FingerPrintOutline,
    FingerPrintSharp: IonIcon.FingerPrintSharp,
    FingerPrint: IonIcon.FingerPrint,
    FishOutline: IonIcon.FishOutline,
    FishSharp: IonIcon.FishSharp,
    Fish: IonIcon.Fish,
    FitnessOutline: IonIcon.FitnessOutline,
    FitnessSharp: IonIcon.FitnessSharp,
    Fitness: IonIcon.Fitness,
    FlagOutline: IonIcon.FlagOutline,
    FlagSharp: IonIcon.FlagSharp,
    Flag: IonIcon.Flag,
    FlameOutline: IonIcon.FlameOutline,
    FlameSharp: IonIcon.FlameSharp,
    Flame: IonIcon.Flame,
    FlashOffOutline: IonIcon.FlashOffOutline,
    FlashOffSharp: IonIcon.FlashOffSharp,
    FlashOff: IonIcon.FlashOff,
    FlashOutline: IonIcon.FlashOutline,
    FlashSharp: IonIcon.FlashSharp,
    Flash: IonIcon.Flash,
    FlashlightOutline: IonIcon.FlashlightOutline,
    FlashlightSharp: IonIcon.FlashlightSharp,
    Flashlight: IonIcon.Flashlight,
    FlaskOutline: IonIcon.FlaskOutline,
    FlaskSharp: IonIcon.FlaskSharp,
    Flask: IonIcon.Flask,
    FlowerOutline: IonIcon.FlowerOutline,
    FlowerSharp: IonIcon.FlowerSharp,
    Flower: IonIcon.Flower,
    FolderOpenOutline: IonIcon.FolderOpenOutline,
    FolderOpenSharp: IonIcon.FolderOpenSharp,
    FolderOpen: IonIcon.FolderOpen,
    FolderOutline: IonIcon.FolderOutline,
    FolderSharp: IonIcon.FolderSharp,
    Folder: IonIcon.Folder,
    FootballOutline: IonIcon.FootballOutline,
    FootballSharp: IonIcon.FootballSharp,
    Football: IonIcon.Football,
    FunnelOutline: IonIcon.FunnelOutline,
    FunnelSharp: IonIcon.FunnelSharp,
    Funnel: IonIcon.Funnel,
    GameControllerOutline: IonIcon.GameControllerOutline,
    GameControllerSharp: IonIcon.GameControllerSharp,
    GameController: IonIcon.GameController,
    GiftOutline: IonIcon.GiftOutline,
    GiftSharp: IonIcon.GiftSharp,
    Gift: IonIcon.Gift,
    GitBranchOutline: IonIcon.GitBranchOutline,
    GitBranchSharp: IonIcon.GitBranchSharp,
    GitBranch: IonIcon.GitBranch,
    GitCommitOutline: IonIcon.GitCommitOutline,
    GitCommitSharp: IonIcon.GitCommitSharp,
    GitCommit: IonIcon.GitCommit,
    GitCompareOutline: IonIcon.GitCompareOutline,
    GitCompareSharp: IonIcon.GitCompareSharp,
    GitCompare: IonIcon.GitCompare,
    GitMergeOutline: IonIcon.GitMergeOutline,
    GitMergeSharp: IonIcon.GitMergeSharp,
    GitMerge: IonIcon.GitMerge,
    GitNetworkOutline: IonIcon.GitNetworkOutline,
    GitNetworkSharp: IonIcon.GitNetworkSharp,
    GitNetwork: IonIcon.GitNetwork,
    GitPullRequestOutline: IonIcon.GitPullRequestOutline,
    GitPullRequestSharp: IonIcon.GitPullRequestSharp,
    GitPullRequest: IonIcon.GitPullRequest,
    GlassesOutline: IonIcon.GlassesOutline,
    GlassesSharp: IonIcon.GlassesSharp,
    Glasses: IonIcon.Glasses,
    GlobeOutline: IonIcon.GlobeOutline,
    GlobeSharp: IonIcon.GlobeSharp,
    Globe: IonIcon.Globe,
    GolfOutline: IonIcon.GolfOutline,
    GolfSharp: IonIcon.GolfSharp,
    Golf: IonIcon.Golf,
    GridOutline: IonIcon.GridOutline,
    GridSharp: IonIcon.GridSharp,
    Grid: IonIcon.Grid,
    HammerOutline: IonIcon.HammerOutline,
    HammerSharp: IonIcon.HammerSharp,
    Hammer: IonIcon.Hammer,
    HandLeftOutline: IonIcon.HandLeftOutline,
    HandLeftSharp: IonIcon.HandLeftSharp,
    HandLeft: IonIcon.HandLeft,
    HandRightOutline: IonIcon.HandRightOutline,
    HandRightSharp: IonIcon.HandRightSharp,
    HandRight: IonIcon.HandRight,
    HappyOutline: IonIcon.HappyOutline,
    HappySharp: IonIcon.HappySharp,
    Happy: IonIcon.Happy,
    HardwareChipOutline: IonIcon.HardwareChipOutline,
    HardwareChipSharp: IonIcon.HardwareChipSharp,
    HardwareChip: IonIcon.HardwareChip,
    HeadsetOutline: IonIcon.HeadsetOutline,
    HeadsetSharp: IonIcon.HeadsetSharp,
    Headset: IonIcon.Headset,
    HeartCircleOutline: IonIcon.HeartCircleOutline,
    HeartCircleSharp: IonIcon.HeartCircleSharp,
    HeartCircle: IonIcon.HeartCircle,
    HeartDislikeCircleOutline: IonIcon.HeartDislikeCircleOutline,
    HeartDislikeCircleSharp: IonIcon.HeartDislikeCircleSharp,
    HeartDislikeCircle: IonIcon.HeartDislikeCircle,
    HeartDislikeOutline: IonIcon.HeartDislikeOutline,
    HeartDislikeSharp: IonIcon.HeartDislikeSharp,
    HeartDislike: IonIcon.HeartDislike,
    HeartHalfOutline: IonIcon.HeartHalfOutline,
    HeartHalfSharp: IonIcon.HeartHalfSharp,
    HeartHalf: IonIcon.HeartHalf,
    HeartOutline: IonIcon.HeartOutline,
    HeartSharp: IonIcon.HeartSharp,
    Heart: IonIcon.Heart,
    HelpBuoyOutline: IonIcon.HelpBuoyOutline,
    HelpBuoySharp: IonIcon.HelpBuoySharp,
    HelpBuoy: IonIcon.HelpBuoy,
    HelpCircleOutline: IonIcon.HelpCircleOutline,
    HelpCircleSharp: IonIcon.HelpCircleSharp,
    HelpCircle: IonIcon.HelpCircle,
    HelpOutline: IonIcon.HelpOutline,
    HelpSharp: IonIcon.HelpSharp,
    Help: IonIcon.Help,
    HomeOutline: IonIcon.HomeOutline,
    HomeSharp: IonIcon.HomeSharp,
    Home: IonIcon.Home,
    HourglassOutline: IonIcon.HourglassOutline,
    HourglassSharp: IonIcon.HourglassSharp,
    Hourglass: IonIcon.Hourglass,
    IceCreamOutline: IonIcon.IceCreamOutline,
    IceCreamSharp: IonIcon.IceCreamSharp,
    IceCream: IonIcon.IceCream,
    ImageOutline: IonIcon.ImageOutline,
    ImageSharp: IonIcon.ImageSharp,
    Image: IonIcon.Image,
    ImagesOutline: IonIcon.ImagesOutline,
    ImagesSharp: IonIcon.ImagesSharp,
    Images: IonIcon.Images,
    InfiniteOutline: IonIcon.InfiniteOutline,
    InfiniteSharp: IonIcon.InfiniteSharp,
    Infinite: IonIcon.Infinite,
    InformationCircleOutline: IonIcon.InformationCircleOutline,
    InformationCircleSharp: IonIcon.InformationCircleSharp,
    InformationCircle: IonIcon.InformationCircle,
    InformationOutline: IonIcon.InformationOutline,
    InformationSharp: IonIcon.InformationSharp,
    Information: IonIcon.Information,
    InvertModeOutline: IonIcon.InvertModeOutline,
    InvertModeSharp: IonIcon.InvertModeSharp,
    InvertMode: IonIcon.InvertMode,
    JournalOutline: IonIcon.JournalOutline,
    JournalSharp: IonIcon.JournalSharp,
    Journal: IonIcon.Journal,
    KeyOutline: IonIcon.KeyOutline,
    KeySharp: IonIcon.KeySharp,
    Key: IonIcon.Key,
    KeypadOutline: IonIcon.KeypadOutline,
    KeypadSharp: IonIcon.KeypadSharp,
    Keypad: IonIcon.Keypad,
    LanguageOutline: IonIcon.LanguageOutline,
    LanguageSharp: IonIcon.LanguageSharp,
    Language: IonIcon.Language,
    LaptopOutline: IonIcon.LaptopOutline,
    LaptopSharp: IonIcon.LaptopSharp,
    Laptop: IonIcon.Laptop,
    LayersOutline: IonIcon.LayersOutline,
    LayersSharp: IonIcon.LayersSharp,
    Layers: IonIcon.Layers,
    LeafOutline: IonIcon.LeafOutline,
    LeafSharp: IonIcon.LeafSharp,
    Leaf: IonIcon.Leaf,
    LibraryOutline: IonIcon.LibraryOutline,
    LibrarySharp: IonIcon.LibrarySharp,
    Library: IonIcon.Library,
    LinkOutline: IonIcon.LinkOutline,
    LinkSharp: IonIcon.LinkSharp,
    Link: IonIcon.Link,
    ListCircleOutline: IonIcon.ListCircleOutline,
    ListCircleSharp: IonIcon.ListCircleSharp,
    ListCircle: IonIcon.ListCircle,
    ListOutline: IonIcon.ListOutline,
    ListSharp: IonIcon.ListSharp,
    List: IonIcon.List,
    LocateOutline: IonIcon.LocateOutline,
    LocateSharp: IonIcon.LocateSharp,
    Locate: IonIcon.Locate,
    LocationOutline: IonIcon.LocationOutline,
    LocationSharp: IonIcon.LocationSharp,
    Location: IonIcon.Location,
    LockClosedOutline: IonIcon.LockClosedOutline,
    LockClosedSharp: IonIcon.LockClosedSharp,
    LockClosed: IonIcon.LockClosed,
    LockOpenOutline: IonIcon.LockOpenOutline,
    LockOpenSharp: IonIcon.LockOpenSharp,
    LockOpen: IonIcon.LockOpen,
    LogInOutline: IonIcon.LogInOutline,
    LogInSharp: IonIcon.LogInSharp,
    LogIn: IonIcon.LogIn,
    LogOutOutline: IonIcon.LogOutOutline,
    LogOutSharp: IonIcon.LogOutSharp,
    LogOut: IonIcon.LogOut,
    LogoAlipay: IonIcon.LogoAlipay,
    LogoAmazon: IonIcon.LogoAmazon,
    LogoAmplify: IonIcon.LogoAmplify,
    LogoAndroid: IonIcon.LogoAndroid,
    LogoAngular: IonIcon.LogoAngular,
    LogoAppleAppstore: IonIcon.LogoAppleAppstore,
    LogoApple: IonIcon.LogoApple,
    LogoBehance: IonIcon.LogoBehance,
    LogoBitbucket: IonIcon.LogoBitbucket,
    LogoBitcoin: IonIcon.LogoBitcoin,
    LogoBuffer: IonIcon.LogoBuffer,
    LogoCapacitor: IonIcon.LogoCapacitor,
    LogoChrome: IonIcon.LogoChrome,
    LogoClosedCaptioning: IonIcon.LogoClosedCaptioning,
    LogoCodepen: IonIcon.LogoCodepen,
    LogoCss3: IonIcon.LogoCss3,
    LogoDesignernews: IonIcon.LogoDesignernews,
    LogoDeviantart: IonIcon.LogoDeviantart,
    LogoDiscord: IonIcon.LogoDiscord,
    LogoDocker: IonIcon.LogoDocker,
    LogoDribbble: IonIcon.LogoDribbble,
    LogoDropbox: IonIcon.LogoDropbox,
    LogoEdge: IonIcon.LogoEdge,
    LogoElectron: IonIcon.LogoElectron,
    LogoEuro: IonIcon.LogoEuro,
    LogoFacebook: IonIcon.LogoFacebook,
    LogoFigma: IonIcon.LogoFigma,
    LogoFirebase: IonIcon.LogoFirebase,
    LogoFirefox: IonIcon.LogoFirefox,
    LogoFlickr: IonIcon.LogoFlickr,
    LogoFoursquare: IonIcon.LogoFoursquare,
    LogoGithub: IonIcon.LogoGithub,
    LogoGitlab: IonIcon.LogoGitlab,
    LogoGooglePlaystore: IonIcon.LogoGooglePlaystore,
    LogoGoogle: IonIcon.LogoGoogle,
    LogoHackernews: IonIcon.LogoHackernews,
    LogoHtml5: IonIcon.LogoHtml5,
    LogoInstagram: IonIcon.LogoInstagram,
    LogoIonic: IonIcon.LogoIonic,
    LogoIonitron: IonIcon.LogoIonitron,
    LogoJavascript: IonIcon.LogoJavascript,
    LogoLaravel: IonIcon.LogoLaravel,
    LogoLinkedin: IonIcon.LogoLinkedin,
    LogoMarkdown: IonIcon.LogoMarkdown,
    LogoMastodon: IonIcon.LogoMastodon,
    LogoMedium: IonIcon.LogoMedium,
    LogoNoSmoking: IonIcon.LogoNoSmoking,
    LogoNodejs: IonIcon.LogoNodejs,
    LogoNpm: IonIcon.LogoNpm,
    LogoOctocat: IonIcon.LogoOctocat,
    LogoPaypal: IonIcon.LogoPaypal,
    LogoPinterest: IonIcon.LogoPinterest,
    LogoPlaystation: IonIcon.LogoPlaystation,
    LogoPwa: IonIcon.LogoPwa,
    LogoPython: IonIcon.LogoPython,
    LogoReact: IonIcon.LogoReact,
    LogoReddit: IonIcon.LogoReddit,
    LogoRss: IonIcon.LogoRss,
    LogoSass: IonIcon.LogoSass,
    LogoSkype: IonIcon.LogoSkype,
    LogoSlack: IonIcon.LogoSlack,
    LogoSnapchat: IonIcon.LogoSnapchat,
    LogoSoundcloud: IonIcon.LogoSoundcloud,
    LogoStackoverflow: IonIcon.LogoStackoverflow,
    LogoSteam: IonIcon.LogoSteam,
    LogoStencil: IonIcon.LogoStencil,
    LogoTableau: IonIcon.LogoTableau,
    LogoTiktok: IonIcon.LogoTiktok,
    LogoTumblr: IonIcon.LogoTumblr,
    LogoTux: IonIcon.LogoTux,
    LogoTwitch: IonIcon.LogoTwitch,
    LogoTwitter: IonIcon.LogoTwitter,
    LogoUsd: IonIcon.LogoUsd,
    LogoVenmo: IonIcon.LogoVenmo,
    LogoVercel: IonIcon.LogoVercel,
    LogoVimeo: IonIcon.LogoVimeo,
    LogoVk: IonIcon.LogoVk,
    LogoVue: IonIcon.LogoVue,
    LogoWebComponent: IonIcon.LogoWebComponent,
    LogoWechat: IonIcon.LogoWechat,
    LogoWhatsapp: IonIcon.LogoWhatsapp,
    LogoWindows: IonIcon.LogoWindows,
    LogoWordpress: IonIcon.LogoWordpress,
    LogoXbox: IonIcon.LogoXbox,
    LogoXing: IonIcon.LogoXing,
    LogoYahoo: IonIcon.LogoYahoo,
    LogoYen: IonIcon.LogoYen,
    LogoYoutube: IonIcon.LogoYoutube,
    MagnetOutline: IonIcon.MagnetOutline,
    MagnetSharp: IonIcon.MagnetSharp,
    Magnet: IonIcon.Magnet,
    MailOpenOutline: IonIcon.MailOpenOutline,
    MailOpenSharp: IonIcon.MailOpenSharp,
    MailOpen: IonIcon.MailOpen,
    MailOutline: IonIcon.MailOutline,
    MailSharp: IonIcon.MailSharp,
    MailUnreadOutline: IonIcon.MailUnreadOutline,
    MailUnreadSharp: IonIcon.MailUnreadSharp,
    MailUnread: IonIcon.MailUnread,
    Mail: IonIcon.Mail,
    MaleFemaleOutline: IonIcon.MaleFemaleOutline,
    MaleFemaleSharp: IonIcon.MaleFemaleSharp,
    MaleFemale: IonIcon.MaleFemale,
    MaleOutline: IonIcon.MaleOutline,
    MaleSharp: IonIcon.MaleSharp,
    Male: IonIcon.Male,
    ManOutline: IonIcon.ManOutline,
    ManSharp: IonIcon.ManSharp,
    Man: IonIcon.Man,
    MapOutline: IonIcon.MapOutline,
    MapSharp: IonIcon.MapSharp,
    Map: IonIcon.Map,
    MedalOutline: IonIcon.MedalOutline,
    MedalSharp: IonIcon.MedalSharp,
    Medal: IonIcon.Medal,
    MedicalOutline: IonIcon.MedicalOutline,
    MedicalSharp: IonIcon.MedicalSharp,
    Medical: IonIcon.Medical,
    MedkitOutline: IonIcon.MedkitOutline,
    MedkitSharp: IonIcon.MedkitSharp,
    Medkit: IonIcon.Medkit,
    MegaphoneOutline: IonIcon.MegaphoneOutline,
    MegaphoneSharp: IonIcon.MegaphoneSharp,
    Megaphone: IonIcon.Megaphone,
    MenuOutline: IonIcon.MenuOutline,
    MenuSharp: IonIcon.MenuSharp,
    Menu: IonIcon.Menu,
    MicCircleOutline: IonIcon.MicCircleOutline,
    MicCircleSharp: IonIcon.MicCircleSharp,
    MicCircle: IonIcon.MicCircle,
    MicOffCircleOutline: IonIcon.MicOffCircleOutline,
    MicOffCircleSharp: IonIcon.MicOffCircleSharp,
    MicOffCircle: IonIcon.MicOffCircle,
    MicOffOutline: IonIcon.MicOffOutline,
    MicOffSharp: IonIcon.MicOffSharp,
    MicOff: IonIcon.MicOff,
    MicOutline: IonIcon.MicOutline,
    MicSharp: IonIcon.MicSharp,
    Mic: IonIcon.Mic,
    MoonOutline: IonIcon.MoonOutline,
    MoonSharp: IonIcon.MoonSharp,
    Moon: IonIcon.Moon,
    MoveOutline: IonIcon.MoveOutline,
    MoveSharp: IonIcon.MoveSharp,
    Move: IonIcon.Move,
    MusicalNoteOutline: IonIcon.MusicalNoteOutline,
    MusicalNoteSharp: IonIcon.MusicalNoteSharp,
    MusicalNote: IonIcon.MusicalNote,
    MusicalNotesOutline: IonIcon.MusicalNotesOutline,
    MusicalNotesSharp: IonIcon.MusicalNotesSharp,
    MusicalNotes: IonIcon.MusicalNotes,
    NavigateCircleOutline: IonIcon.NavigateCircleOutline,
    NavigateCircleSharp: IonIcon.NavigateCircleSharp,
    NavigateCircle: IonIcon.NavigateCircle,
    NavigateOutline: IonIcon.NavigateOutline,
    NavigateSharp: IonIcon.NavigateSharp,
    Navigate: IonIcon.Navigate,
    NewspaperOutline: IonIcon.NewspaperOutline,
    NewspaperSharp: IonIcon.NewspaperSharp,
    Newspaper: IonIcon.Newspaper,
    NotificationsCircleOutline: IonIcon.NotificationsCircleOutline,
    NotificationsCircleSharp: IonIcon.NotificationsCircleSharp,
    NotificationsCircle: IonIcon.NotificationsCircle,
    NotificationsOffCircleOutline: IonIcon.NotificationsOffCircleOutline,
    NotificationsOffCircleSharp: IonIcon.NotificationsOffCircleSharp,
    NotificationsOffCircle: IonIcon.NotificationsOffCircle,
    NotificationsOffOutline: IonIcon.NotificationsOffOutline,
    NotificationsOffSharp: IonIcon.NotificationsOffSharp,
    NotificationsOff: IonIcon.NotificationsOff,
    NotificationsOutline: IonIcon.NotificationsOutline,
    NotificationsSharp: IonIcon.NotificationsSharp,
    Notifications: IonIcon.Notifications,
    NuclearOutline: IonIcon.NuclearOutline,
    NuclearSharp: IonIcon.NuclearSharp,
    Nuclear: IonIcon.Nuclear,
    NutritionOutline: IonIcon.NutritionOutline,
    NutritionSharp: IonIcon.NutritionSharp,
    Nutrition: IonIcon.Nutrition,
    OpenOutline: IonIcon.OpenOutline,
    OpenSharp: IonIcon.OpenSharp,
    Open: IonIcon.Open,
    OptionsOutline: IonIcon.OptionsOutline,
    OptionsSharp: IonIcon.OptionsSharp,
    Options: IonIcon.Options,
    PaperPlaneOutline: IonIcon.PaperPlaneOutline,
    PaperPlaneSharp: IonIcon.PaperPlaneSharp,
    PaperPlane: IonIcon.PaperPlane,
    PartlySunnyOutline: IonIcon.PartlySunnyOutline,
    PartlySunnySharp: IonIcon.PartlySunnySharp,
    PartlySunny: IonIcon.PartlySunny,
    PauseCircleOutline: IonIcon.PauseCircleOutline,
    PauseCircleSharp: IonIcon.PauseCircleSharp,
    PauseCircle: IonIcon.PauseCircle,
    PauseOutline: IonIcon.PauseOutline,
    PauseSharp: IonIcon.PauseSharp,
    Pause: IonIcon.Pause,
    PawOutline: IonIcon.PawOutline,
    PawSharp: IonIcon.PawSharp,
    Paw: IonIcon.Paw,
    PencilOutline: IonIcon.PencilOutline,
    PencilSharp: IonIcon.PencilSharp,
    Pencil: IonIcon.Pencil,
    PeopleCircleOutline: IonIcon.PeopleCircleOutline,
    PeopleCircleSharp: IonIcon.PeopleCircleSharp,
    PeopleCircle: IonIcon.PeopleCircle,
    PeopleOutline: IonIcon.PeopleOutline,
    PeopleSharp: IonIcon.PeopleSharp,
    People: IonIcon.People,
    PersonAddOutline: IonIcon.PersonAddOutline,
    PersonAddSharp: IonIcon.PersonAddSharp,
    PersonAdd: IonIcon.PersonAdd,
    PersonCircleOutline: IonIcon.PersonCircleOutline,
    PersonCircleSharp: IonIcon.PersonCircleSharp,
    PersonCircle: IonIcon.PersonCircle,
    PersonOutline: IonIcon.PersonOutline,
    PersonRemoveOutline: IonIcon.PersonRemoveOutline,
    PersonRemoveSharp: IonIcon.PersonRemoveSharp,
    PersonRemove: IonIcon.PersonRemove,
    PersonSharp: IonIcon.PersonSharp,
    Person: IonIcon.Person,
    PhoneLandscapeOutline: IonIcon.PhoneLandscapeOutline,
    PhoneLandscapeSharp: IonIcon.PhoneLandscapeSharp,
    PhoneLandscape: IonIcon.PhoneLandscape,
    PhonePortraitOutline: IonIcon.PhonePortraitOutline,
    PhonePortraitSharp: IonIcon.PhonePortraitSharp,
    PhonePortrait: IonIcon.PhonePortrait,
    PieChartOutline: IonIcon.PieChartOutline,
    PieChartSharp: IonIcon.PieChartSharp,
    PieChart: IonIcon.PieChart,
    PinOutline: IonIcon.PinOutline,
    PinSharp: IonIcon.PinSharp,
    Pin: IonIcon.Pin,
    PintOutline: IonIcon.PintOutline,
    PintSharp: IonIcon.PintSharp,
    Pint: IonIcon.Pint,
    PizzaOutline: IonIcon.PizzaOutline,
    PizzaSharp: IonIcon.PizzaSharp,
    Pizza: IonIcon.Pizza,
    PlanetOutline: IonIcon.PlanetOutline,
    PlanetSharp: IonIcon.PlanetSharp,
    Planet: IonIcon.Planet,
    PlayBackCircleOutline: IonIcon.PlayBackCircleOutline,
    PlayBackCircleSharp: IonIcon.PlayBackCircleSharp,
    PlayBackCircle: IonIcon.PlayBackCircle,
    PlayBackOutline: IonIcon.PlayBackOutline,
    PlayBackSharp: IonIcon.PlayBackSharp,
    PlayBack: IonIcon.PlayBack,
    PlayCircleOutline: IonIcon.PlayCircleOutline,
    PlayCircleSharp: IonIcon.PlayCircleSharp,
    PlayCircle: IonIcon.PlayCircle,
    PlayForwardCircleOutline: IonIcon.PlayForwardCircleOutline,
    PlayForwardCircleSharp: IonIcon.PlayForwardCircleSharp,
    PlayForwardCircle: IonIcon.PlayForwardCircle,
    PlayForwardOutline: IonIcon.PlayForwardOutline,
    PlayForwardSharp: IonIcon.PlayForwardSharp,
    PlayForward: IonIcon.PlayForward,
    PlayOutline: IonIcon.PlayOutline,
    PlaySharp: IonIcon.PlaySharp,
    PlaySkipBackCircleOutline: IonIcon.PlaySkipBackCircleOutline,
    PlaySkipBackCircleSharp: IonIcon.PlaySkipBackCircleSharp,
    PlaySkipBackCircle: IonIcon.PlaySkipBackCircle,
    PlaySkipBackOutline: IonIcon.PlaySkipBackOutline,
    PlaySkipBackSharp: IonIcon.PlaySkipBackSharp,
    PlaySkipBack: IonIcon.PlaySkipBack,
    PlaySkipForwardCircleOutline: IonIcon.PlaySkipForwardCircleOutline,
    PlaySkipForwardCircleSharp: IonIcon.PlaySkipForwardCircleSharp,
    PlaySkipForwardCircle: IonIcon.PlaySkipForwardCircle,
    PlaySkipForwardOutline: IonIcon.PlaySkipForwardOutline,
    PlaySkipForwardSharp: IonIcon.PlaySkipForwardSharp,
    PlaySkipForward: IonIcon.PlaySkipForward,
    Play: IonIcon.Play,
    PodiumOutline: IonIcon.PodiumOutline,
    PodiumSharp: IonIcon.PodiumSharp,
    Podium: IonIcon.Podium,
    PowerOutline: IonIcon.PowerOutline,
    PowerSharp: IonIcon.PowerSharp,
    Power: IonIcon.Power,
    PricetagOutline: IonIcon.PricetagOutline,
    PricetagSharp: IonIcon.PricetagSharp,
    Pricetag: IonIcon.Pricetag,
    PricetagsOutline: IonIcon.PricetagsOutline,
    PricetagsSharp: IonIcon.PricetagsSharp,
    Pricetags: IonIcon.Pricetags,
    PrintOutline: IonIcon.PrintOutline,
    PrintSharp: IonIcon.PrintSharp,
    Print: IonIcon.Print,
    PulseOutline: IonIcon.PulseOutline,
    PulseSharp: IonIcon.PulseSharp,
    Pulse: IonIcon.Pulse,
    PushOutline: IonIcon.PushOutline,
    PushSharp: IonIcon.PushSharp,
    Push: IonIcon.Push,
    QrCodeOutline: IonIcon.QrCodeOutline,
    QrCodeSharp: IonIcon.QrCodeSharp,
    QrCode: IonIcon.QrCode,
    RadioButtonOffOutline: IonIcon.RadioButtonOffOutline,
    RadioButtonOffSharp: IonIcon.RadioButtonOffSharp,
    RadioButtonOff: IonIcon.RadioButtonOff,
    RadioButtonOnOutline: IonIcon.RadioButtonOnOutline,
    RadioButtonOnSharp: IonIcon.RadioButtonOnSharp,
    RadioButtonOn: IonIcon.RadioButtonOn,
    RadioOutline: IonIcon.RadioOutline,
    RadioSharp: IonIcon.RadioSharp,
    Radio: IonIcon.Radio,
    RainyOutline: IonIcon.RainyOutline,
    RainySharp: IonIcon.RainySharp,
    Rainy: IonIcon.Rainy,
    ReaderOutline: IonIcon.ReaderOutline,
    ReaderSharp: IonIcon.ReaderSharp,
    Reader: IonIcon.Reader,
    ReceiptOutline: IonIcon.ReceiptOutline,
    ReceiptSharp: IonIcon.ReceiptSharp,
    Receipt: IonIcon.Receipt,
    RecordingOutline: IonIcon.RecordingOutline,
    RecordingSharp: IonIcon.RecordingSharp,
    Recording: IonIcon.Recording,
    RefreshCircleOutline: IonIcon.RefreshCircleOutline,
    RefreshCircleSharp: IonIcon.RefreshCircleSharp,
    RefreshCircle: IonIcon.RefreshCircle,
    RefreshOutline: IonIcon.RefreshOutline,
    RefreshSharp: IonIcon.RefreshSharp,
    Refresh: IonIcon.Refresh,
    ReloadCircleOutline: IonIcon.ReloadCircleOutline,
    ReloadCircleSharp: IonIcon.ReloadCircleSharp,
    ReloadCircle: IonIcon.ReloadCircle,
    ReloadOutline: IonIcon.ReloadOutline,
    ReloadSharp: IonIcon.ReloadSharp,
    Reload: IonIcon.Reload,
    RemoveCircleOutline: IonIcon.RemoveCircleOutline,
    RemoveCircleSharp: IonIcon.RemoveCircleSharp,
    RemoveCircle: IonIcon.RemoveCircle,
    RemoveOutline: IonIcon.RemoveOutline,
    RemoveSharp: IonIcon.RemoveSharp,
    Remove: IonIcon.Remove,
    ReorderFourOutline: IonIcon.ReorderFourOutline,
    ReorderFourSharp: IonIcon.ReorderFourSharp,
    ReorderFour: IonIcon.ReorderFour,
    ReorderThreeOutline: IonIcon.ReorderThreeOutline,
    ReorderThreeSharp: IonIcon.ReorderThreeSharp,
    ReorderThree: IonIcon.ReorderThree,
    ReorderTwoOutline: IonIcon.ReorderTwoOutline,
    ReorderTwoSharp: IonIcon.ReorderTwoSharp,
    ReorderTwo: IonIcon.ReorderTwo,
    RepeatOutline: IonIcon.RepeatOutline,
    RepeatSharp: IonIcon.RepeatSharp,
    Repeat: IonIcon.Repeat,
    ResizeOutline: IonIcon.ResizeOutline,
    ResizeSharp: IonIcon.ResizeSharp,
    Resize: IonIcon.Resize,
    RestaurantOutline: IonIcon.RestaurantOutline,
    RestaurantSharp: IonIcon.RestaurantSharp,
    Restaurant: IonIcon.Restaurant,
    ReturnDownBackOutline: IonIcon.ReturnDownBackOutline,
    ReturnDownBackSharp: IonIcon.ReturnDownBackSharp,
    ReturnDownBack: IonIcon.ReturnDownBack,
    ReturnDownForwardOutline: IonIcon.ReturnDownForwardOutline,
    ReturnDownForwardSharp: IonIcon.ReturnDownForwardSharp,
    ReturnDownForward: IonIcon.ReturnDownForward,
    ReturnUpBackOutline: IonIcon.ReturnUpBackOutline,
    ReturnUpBackSharp: IonIcon.ReturnUpBackSharp,
    ReturnUpBack: IonIcon.ReturnUpBack,
    ReturnUpForwardOutline: IonIcon.ReturnUpForwardOutline,
    ReturnUpForwardSharp: IonIcon.ReturnUpForwardSharp,
    ReturnUpForward: IonIcon.ReturnUpForward,
    RibbonOutline: IonIcon.RibbonOutline,
    RibbonSharp: IonIcon.RibbonSharp,
    Ribbon: IonIcon.Ribbon,
    RocketOutline: IonIcon.RocketOutline,
    RocketSharp: IonIcon.RocketSharp,
    Rocket: IonIcon.Rocket,
    RoseOutline: IonIcon.RoseOutline,
    RoseSharp: IonIcon.RoseSharp,
    Rose: IonIcon.Rose,
    SadOutline: IonIcon.SadOutline,
    SadSharp: IonIcon.SadSharp,
    Sad: IonIcon.Sad,
    SaveOutline: IonIcon.SaveOutline,
    SaveSharp: IonIcon.SaveSharp,
    Save: IonIcon.Save,
    ScanCircleOutline: IonIcon.ScanCircleOutline,
    ScanCircleSharp: IonIcon.ScanCircleSharp,
    ScanCircle: IonIcon.ScanCircle,
    ScanOutline: IonIcon.ScanOutline,
    ScanSharp: IonIcon.ScanSharp,
    Scan: IonIcon.Scan,
    SchoolOutline: IonIcon.SchoolOutline,
    SchoolSharp: IonIcon.SchoolSharp,
    School: IonIcon.School,
    SearchCircleOutline: IonIcon.SearchCircleOutline,
    SearchCircleSharp: IonIcon.SearchCircleSharp,
    SearchCircle: IonIcon.SearchCircle,
    SearchOutline: IonIcon.SearchOutline,
    SearchSharp: IonIcon.SearchSharp,
    Search: IonIcon.Search,
    SendOutline: IonIcon.SendOutline,
    SendSharp: IonIcon.SendSharp,
    Send: IonIcon.Send,
    ServerOutline: IonIcon.ServerOutline,
    ServerSharp: IonIcon.ServerSharp,
    Server: IonIcon.Server,
    SettingsOutline: IonIcon.SettingsOutline,
    SettingsSharp: IonIcon.SettingsSharp,
    Settings: IonIcon.Settings,
    ShapesOutline: IonIcon.ShapesOutline,
    ShapesSharp: IonIcon.ShapesSharp,
    Shapes: IonIcon.Shapes,
    ShareOutline: IonIcon.ShareOutline,
    ShareSharp: IonIcon.ShareSharp,
    ShareSocialOutline: IonIcon.ShareSocialOutline,
    ShareSocialSharp: IonIcon.ShareSocialSharp,
    ShareSocial: IonIcon.ShareSocial,
    Share: IonIcon.Share,
    ShieldCheckmarkOutline: IonIcon.ShieldCheckmarkOutline,
    ShieldCheckmarkSharp: IonIcon.ShieldCheckmarkSharp,
    ShieldCheckmark: IonIcon.ShieldCheckmark,
    ShieldOutline: IonIcon.ShieldOutline,
    ShieldSharp: IonIcon.ShieldSharp,
    Shield: IonIcon.Shield,
    ShirtOutline: IonIcon.ShirtOutline,
    ShirtSharp: IonIcon.ShirtSharp,
    Shirt: IonIcon.Shirt,
    ShuffleOutline: IonIcon.ShuffleOutline,
    ShuffleSharp: IonIcon.ShuffleSharp,
    Shuffle: IonIcon.Shuffle,
    SkullOutline: IonIcon.SkullOutline,
    SkullSharp: IonIcon.SkullSharp,
    Skull: IonIcon.Skull,
    SnowOutline: IonIcon.SnowOutline,
    SnowSharp: IonIcon.SnowSharp,
    Snow: IonIcon.Snow,
    SpeedometerOutline: IonIcon.SpeedometerOutline,
    SpeedometerSharp: IonIcon.SpeedometerSharp,
    Speedometer: IonIcon.Speedometer,
    SquareOutline: IonIcon.SquareOutline,
    SquareSharp: IonIcon.SquareSharp,
    Square: IonIcon.Square,
    StarHalfOutline: IonIcon.StarHalfOutline,
    StarHalfSharp: IonIcon.StarHalfSharp,
    StarHalf: IonIcon.StarHalf,
    StarOutline: IonIcon.StarOutline,
    StarSharp: IonIcon.StarSharp,
    Star: IonIcon.Star,
    StatsChartOutline: IonIcon.StatsChartOutline,
    StatsChartSharp: IonIcon.StatsChartSharp,
    StatsChart: IonIcon.StatsChart,
    StopCircleOutline: IonIcon.StopCircleOutline,
    StopCircleSharp: IonIcon.StopCircleSharp,
    StopCircle: IonIcon.StopCircle,
    StopOutline: IonIcon.StopOutline,
    StopSharp: IonIcon.StopSharp,
    Stop: IonIcon.Stop,
    StopwatchOutline: IonIcon.StopwatchOutline,
    StopwatchSharp: IonIcon.StopwatchSharp,
    Stopwatch: IonIcon.Stopwatch,
    StorefrontOutline: IonIcon.StorefrontOutline,
    StorefrontSharp: IonIcon.StorefrontSharp,
    Storefront: IonIcon.Storefront,
    SubwayOutline: IonIcon.SubwayOutline,
    SubwaySharp: IonIcon.SubwaySharp,
    Subway: IonIcon.Subway,
    SunnyOutline: IonIcon.SunnyOutline,
    SunnySharp: IonIcon.SunnySharp,
    Sunny: IonIcon.Sunny,
    SwapHorizontalOutline: IonIcon.SwapHorizontalOutline,
    SwapHorizontalSharp: IonIcon.SwapHorizontalSharp,
    SwapHorizontal: IonIcon.SwapHorizontal,
    SwapVerticalOutline: IonIcon.SwapVerticalOutline,
    SwapVerticalSharp: IonIcon.SwapVerticalSharp,
    SwapVertical: IonIcon.SwapVertical,
    SyncCircleOutline: IonIcon.SyncCircleOutline,
    SyncCircleSharp: IonIcon.SyncCircleSharp,
    SyncCircle: IonIcon.SyncCircle,
    SyncOutline: IonIcon.SyncOutline,
    SyncSharp: IonIcon.SyncSharp,
    Sync: IonIcon.Sync,
    TabletLandscapeOutline: IonIcon.TabletLandscapeOutline,
    TabletLandscapeSharp: IonIcon.TabletLandscapeSharp,
    TabletLandscape: IonIcon.TabletLandscape,
    TabletPortraitOutline: IonIcon.TabletPortraitOutline,
    TabletPortraitSharp: IonIcon.TabletPortraitSharp,
    TabletPortrait: IonIcon.TabletPortrait,
    TelescopeOutline: IonIcon.TelescopeOutline,
    TelescopeSharp: IonIcon.TelescopeSharp,
    Telescope: IonIcon.Telescope,
    TennisballOutline: IonIcon.TennisballOutline,
    TennisballSharp: IonIcon.TennisballSharp,
    Tennisball: IonIcon.Tennisball,
    TerminalOutline: IonIcon.TerminalOutline,
    TerminalSharp: IonIcon.TerminalSharp,
    Terminal: IonIcon.Terminal,
    TextOutline: IonIcon.TextOutline,
    TextSharp: IonIcon.TextSharp,
    Text: IonIcon.Text,
    ThermometerOutline: IonIcon.ThermometerOutline,
    ThermometerSharp: IonIcon.ThermometerSharp,
    Thermometer: IonIcon.Thermometer,
    ThumbsDownOutline: IonIcon.ThumbsDownOutline,
    ThumbsDownSharp: IonIcon.ThumbsDownSharp,
    ThumbsDown: IonIcon.ThumbsDown,
    ThumbsUpOutline: IonIcon.ThumbsUpOutline,
    ThumbsUpSharp: IonIcon.ThumbsUpSharp,
    ThumbsUp: IonIcon.ThumbsUp,
    ThunderstormOutline: IonIcon.ThunderstormOutline,
    ThunderstormSharp: IonIcon.ThunderstormSharp,
    Thunderstorm: IonIcon.Thunderstorm,
    TicketOutline: IonIcon.TicketOutline,
    TicketSharp: IonIcon.TicketSharp,
    Ticket: IonIcon.Ticket,
    TimeOutline: IonIcon.TimeOutline,
    TimeSharp: IonIcon.TimeSharp,
    Time: IonIcon.Time,
    TimerOutline: IonIcon.TimerOutline,
    TimerSharp: IonIcon.TimerSharp,
    Timer: IonIcon.Timer,
    TodayOutline: IonIcon.TodayOutline,
    TodaySharp: IonIcon.TodaySharp,
    Today: IonIcon.Today,
    ToggleOutline: IonIcon.ToggleOutline,
    ToggleSharp: IonIcon.ToggleSharp,
    Toggle: IonIcon.Toggle,
    TrailSignOutline: IonIcon.TrailSignOutline,
    TrailSignSharp: IonIcon.TrailSignSharp,
    TrailSign: IonIcon.TrailSign,
    TrainOutline: IonIcon.TrainOutline,
    TrainSharp: IonIcon.TrainSharp,
    Train: IonIcon.Train,
    TransgenderOutline: IonIcon.TransgenderOutline,
    TransgenderSharp: IonIcon.TransgenderSharp,
    Transgender: IonIcon.Transgender,
    TrashBinOutline: IonIcon.TrashBinOutline,
    TrashBinSharp: IonIcon.TrashBinSharp,
    TrashBin: IonIcon.TrashBin,
    TrashOutline: IonIcon.TrashOutline,
    TrashSharp: IonIcon.TrashSharp,
    Trash: IonIcon.Trash,
    TrendingDownOutline: IonIcon.TrendingDownOutline,
    TrendingDownSharp: IonIcon.TrendingDownSharp,
    TrendingDown: IonIcon.TrendingDown,
    TrendingUpOutline: IonIcon.TrendingUpOutline,
    TrendingUpSharp: IonIcon.TrendingUpSharp,
    TrendingUp: IonIcon.TrendingUp,
    TriangleOutline: IonIcon.TriangleOutline,
    TriangleSharp: IonIcon.TriangleSharp,
    Triangle: IonIcon.Triangle,
    TrophyOutline: IonIcon.TrophyOutline,
    TrophySharp: IonIcon.TrophySharp,
    Trophy: IonIcon.Trophy,
    TvOutline: IonIcon.TvOutline,
    TvSharp: IonIcon.TvSharp,
    Tv: IonIcon.Tv,
    UmbrellaOutline: IonIcon.UmbrellaOutline,
    UmbrellaSharp: IonIcon.UmbrellaSharp,
    Umbrella: IonIcon.Umbrella,
    UnlinkOutline: IonIcon.UnlinkOutline,
    UnlinkSharp: IonIcon.UnlinkSharp,
    Unlink: IonIcon.Unlink,
    VideocamOffOutline: IonIcon.VideocamOffOutline,
    VideocamOffSharp: IonIcon.VideocamOffSharp,
    VideocamOff: IonIcon.VideocamOff,
    VideocamOutline: IonIcon.VideocamOutline,
    VideocamSharp: IonIcon.VideocamSharp,
    Videocam: IonIcon.Videocam,
    VolumeHighOutline: IonIcon.VolumeHighOutline,
    VolumeHighSharp: IonIcon.VolumeHighSharp,
    VolumeHigh: IonIcon.VolumeHigh,
    VolumeLowOutline: IonIcon.VolumeLowOutline,
    VolumeLowSharp: IonIcon.VolumeLowSharp,
    VolumeLow: IonIcon.VolumeLow,
    VolumeMediumOutline: IonIcon.VolumeMediumOutline,
    VolumeMediumSharp: IonIcon.VolumeMediumSharp,
    VolumeMedium: IonIcon.VolumeMedium,
    VolumeMuteOutline: IonIcon.VolumeMuteOutline,
    VolumeMuteSharp: IonIcon.VolumeMuteSharp,
    VolumeMute: IonIcon.VolumeMute,
    VolumeOffOutline: IonIcon.VolumeOffOutline,
    VolumeOffSharp: IonIcon.VolumeOffSharp,
    VolumeOff: IonIcon.VolumeOff,
    WalkOutline: IonIcon.WalkOutline,
    WalkSharp: IonIcon.WalkSharp,
    Walk: IonIcon.Walk,
    WalletOutline: IonIcon.WalletOutline,
    WalletSharp: IonIcon.WalletSharp,
    Wallet: IonIcon.Wallet,
    WarningOutline: IonIcon.WarningOutline,
    WarningSharp: IonIcon.WarningSharp,
    Warning: IonIcon.Warning,
    WatchOutline: IonIcon.WatchOutline,
    WatchSharp: IonIcon.WatchSharp,
    Watch: IonIcon.Watch,
    WaterOutline: IonIcon.WaterOutline,
    WaterSharp: IonIcon.WaterSharp,
    Water: IonIcon.Water,
    WifiOutline: IonIcon.WifiOutline,
    WifiSharp: IonIcon.WifiSharp,
    Wifi: IonIcon.Wifi,
    WineOutline: IonIcon.WineOutline,
    WineSharp: IonIcon.WineSharp,
    Wine: IonIcon.Wine,
    WomanOutline: IonIcon.WomanOutline,
    WomanSharp: IonIcon.WomanSharp,
    Woman: IonIcon.Woman
}