import React from "react";
import Utils from "../../../../utils";
import CommentList from "../../comment/CommentList";
import IWidgetProps from "../../../../model/interface/widget/IWidgetProps";
import IWidgetPropsFunctions from "../../../../model/interface/widget/IWidgetPropsFunctions";
import ICommentOptions from "../../../../model/interface/widget/option/ICommentOptions";


interface IState {
    thread: number | null
}

class CommentWidget extends React.Component<IWidgetProps<IWidgetPropsFunctions, ICommentOptions>, IState> {
    render() {
        const {options, value} = this.props
        const {wysiwyg, attachments, replyAble, editable, wysiwygPackage, readonly, limit} = options

        return (
            <div>
                <CommentList
                    thread={value}
                    wysiwyg={Utils.toBoolean(wysiwyg)}
                    attachments={Utils.toBoolean(attachments)}
                    replyAble={Utils.toBoolean(replyAble)}
                    editable={Utils.toBoolean(editable)}
                    wysiwygPackage={wysiwygPackage}
                    limit={limit}
                    readonly={Utils.toBoolean(readonly)}
                />
            </div>
        );
    }
}

export default CommentWidget