import {IAppState} from "../store";
import ITranslation from "../../model/interface/ui/dictionary/ITranslation";

const COMMON_FALLBACKS: {[code: string]: string} = {
    'general.yes': 'Ano',
    'general.no': 'Ne',
    'general.save': 'Uložit',
    'general.name': 'Název',
    'general.value': 'Hodnota',
    'general.close': 'Zavřít',
    'general.today': 'Dnes',
    'general.employee': 'Zaměstnanec',
    'form.item.required' : 'Tato položka je povinná.'
}

export const getMessage = (store: IAppState, path: string, fallBack?: string, params: {
    [key: string]: string | number
} = {}): string => {
    const term = store.setup.terms.find(t => t.path === path)
    const language = store.setup.user.language
    if (term) {
        let translation = term.translations.find(t => t.language === language)
        if (term.children.length > 0) {
            let randomTranslations: ITranslation[] = []
            term.children.forEach(c => {
                const child = store.setup.terms.find(t => t.uuid === c)
                if (child && child.code === null) {
                    let childTranslation = child.translations.find(t => t.language === language)
                    childTranslation && randomTranslations.push(childTranslation)
                }
            })
            if (randomTranslations.length > 0) {
                translation = randomTranslations[Math.floor(Math.random() * (randomTranslations.length))]
            }
        }
        if (translation) {
            let text = translation.text
            Object.entries(params).forEach(([key, value]) => {
                text?.replace(key, value.toString())
            })
            return text || fallBack || COMMON_FALLBACKS[path] || path
        }
    }
    return fallBack || COMMON_FALLBACKS[path] || path
}

export default {
    getMessage
}