import {Button, Input, Tooltip} from 'antd';
import React from "react";
import IFieldOptions from "../../../../../../model/interface/form/elementOptions/IFieldOptions";
import CodeReaderModal from "../../../../../shared/input/code-reader/CodeReaderModal";
import {CameraOutlined} from "@ant-design/icons";


interface IProps {
    value?: string | number,
    onChange?: (value?: string) => void
    options: IFieldOptions
}

interface IState {
    scan?: boolean
}

class FormFieldCode extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {}
    }

    handleChange = (value?: string) => {
        const {onChange} = this.props

        onChange?.(value)
        value && this.setState({scan: false})
    }

    setScan = () => {
        this.setState(state => ({scan: !state.scan}))
    }

    render() {
        const {scan} = this.state
        const {value, options} = this.props
        const {disabled, showClear, allowCameraScan, autoCloseScanOnResult, placeholder} = options

        return (
            <Input.Group className={'d-flex w-100'} compact={true}>
                {scan && (
                    <CodeReaderModal resultOnSubmit={!autoCloseScanOnResult} onCancel={this.setScan}
                                     onChange={result => this.handleChange(result)}/>
                )}
                <Input onChange={e => this.handleChange(e.target.value)} className={'flex-grow-1 min-w-0'} value={value}
                       allowClear={showClear} disabled={disabled} placeholder={placeholder}/>
                {allowCameraScan && (
                    <Tooltip title={'Naskenujte kód kamerou'}>
                        <Button onClick={this.setScan} icon={<CameraOutlined/>}/>
                    </Tooltip>
                )}
            </Input.Group>
        )
    }
}

export default FormFieldCode