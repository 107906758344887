import React from "react"
import {Button, notification, Progress, Row, Typography} from "antd";
import {IPropsSourceStepUpdate, IStateSourceStep} from "./SourceConfiguration";
import ImportService from "../../../../model/service/import/ImportService";
import ISource from "../../../../model/interface/import/ISource";
import {CloudUploadOutlined, RollbackOutlined, WarningOutlined} from "@ant-design/icons";
import {humanSeconds} from "../../file-manager/utils/TimeDuration";
import Title from "antd/es/typography/Title";
import IResult from "../../../../model/interface/import/source/IResult";
import SourcesService from "../../../../model/service/import/SourcesService";
import selectors from "../../../../redux/selectors";
import store from "../../../../redux/store";
import IAction from "../../../../model/interface/dataStorage/IAction";
import Action from "../../action/Action";

interface IStateSourceImport extends IStateSourceStep {
    saving: boolean
    importedCount: number,
    timeLeft: number,
    timeEstimate: number
    interval: any,
    averageTime: number,
    importedNew: number
}

interface IProps extends IPropsSourceStepUpdate {
    showBackButton?: boolean
    configuration?: boolean
    onImportNew?: () => void,
    action?: IAction
}

class SourceExecution extends React.Component<IProps, IStateSourceImport> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            saving: false,
            resource: {...props.resource} as ISource,
            importedCount: props.resource.importedCount,
            importedNew: 0,
            timeLeft: this.getTimeEstimate(props),
            timeEstimate: this.getTimeEstimate(props),
            interval: 0,
            averageTime: 0
        }
    }

    static defaultProps = {
        showBackButton: true,
        configuration: true
    }

    _isMounted = false;

    getTimeEstimate(props: IPropsSourceStepUpdate) {
        return ((props.resource.totalCount - props.resource.importedCount) / props.resource.chunkSize);
    }

    componentWillUnmount() {
        clearInterval(this.state.interval);
        this._isMounted = false
    }

    componentDidMount() {
        this._isMounted = true;
    }

    timer = () => {
        this.setState(prevState => ({timeLeft: Math.max(prevState.timeLeft - 1, 0)}));
    }

    startImport = () => {
        const interval = setInterval(this.timer, 1000);
        this.setState({interval});
        this.import()
    }

    import = () => {
        const start = performance.now()
        const {history, action, resource} = this.props
        const route = selectors.routes.extractRouteParametersFromUrl(store.getState(), history.location.pathname)
        let promise: Promise<IResult>
        if (action) {
            promise = Action.doAction(action, [], history, {
                source: resource.uuid,
                route
            }).then(result => result.payload.result)
        } else {
            promise = ImportService.import(this.state.resource.id, {route: route})
        }
        promise.then(result => {
            this.afterImport(start, result);
        })
    }

    afterImport(start: number, result: IResult) {
        const {updateResource, action} = this.props
        const end = performance.now()
        const averageTime = (end - start) / 1000
        const timeLeft = this.state.timeLeft
        const timeEstimate = timeLeft > this.state.timeEstimate ? timeLeft : this.state.timeEstimate
        updateResource({
            ...this.state.resource,
            importedCount: this.state.importedCount + result.total
        }, false).then()
        this.updateState(result, timeLeft, timeEstimate, averageTime);
        if (this.state.importedCount >= this.state.resource.totalCount || result.total === 0) {
            !action && this.showResult(result);
            this.setState({timeLeft: 0})
            clearInterval(this.state.interval);
        } else {
            this._isMounted && this.import()
        }
    }

    updateState(result: IResult, timeLeft: number, timeEstimate: number, averageTime: number) {
        this.setState(prevState => ({
            importedCount: prevState.importedCount + result.total, timeLeft, timeEstimate,
            importedNew: prevState.importedNew + result.total,
            averageTime: prevState.averageTime + averageTime
        }), () => {
            const timeLeft = this.getTimeLeftNormalized()
            this.setState({timeLeft})
        })
    }

    showResult(result: IResult) {
        if (result.exception) {
            notification.error({message: result.exception})
        } else {
            notification.success({message: `${this.state.resource.totalCount} rows imported!`})
        }
    }

    private getTimeLeftNormalized() {
        return ((this.state.resource.totalCount - this.state.importedCount)
            / this.state.resource.chunkSize) * ((this.state.averageTime)
            / (this.state.importedNew / this.state.resource.chunkSize));
    }

    reset() {
        this.setState({saving: true})
        ImportService.reset(this.state.resource.id)
            .then(() => SourcesService.resourceRetrieve(this.state.resource.id))
            .then((resource: ISource) => {
                this.setState({
                    resource,
                    saving: false,
                    importedCount: resource.importedCount,
                    importedNew: 0,
                    interval: 0,
                    averageTime: 0
                })
            })
    }

    importNew = () => {
        this.props.onImportNew?.()
    }

    render() {
        const {saving, resource, importedCount, timeLeft} = this.state
        const {showBackButton, configuration, history} = this.props
        const percent = parseFloat(Number((importedCount / resource?.totalCount) * 100).toFixed(2))
        const importedAll = resource.totalCount <= importedCount;

        return (
            <>
                <Typography.Title level={4}>
                    {importedAll && (<div>
                        {configuration ? (
                            <div>
                                {'Zdroj byl nahran, zvolte novy zdroj'}
                                <Button className={'m-3'} type={"primary"} onClick={() => this.props.setStep(-2)}>
                                    Vlastnosti
                                </Button>
                            </div>
                        ) : (
                            <div>
                                {'Soubor byl importován'}
                                <Button className={'m-3'} type={"primary"} onClick={this.importNew}>
                                    Importovat nový soubor
                                </Button>
                            </div>
                        )}
                    </div>)}
                    {configuration && (
                        <Button className={'m-3'} type={"primary"} onClick={() => this.reset()}>
                            Reset
                        </Button>)}
                </Typography.Title>

                <Row align={"middle"} className={"dir-ltr"}>
                    <div className={'mr-3'}>
                        <Button disabled={importedAll} type={"primary"} className={'m-2'} icon={<CloudUploadOutlined/>}
                                loading={saving} onClick={this.startImport}>
                            Import {importedCount}/{resource.totalCount}
                        </Button>
                        {!importedAll && (
                            <Title className={'m-2 text-center'} level={3}>{humanSeconds(timeLeft, {
                                second: {0: 's'},
                                minute: {0: 'm'},
                                hour: {0: 'h'}
                            })}</Title>
                        )}
                    </div>
                    <Progress className={'m-2'} type="circle" percent={percent}/>
                </Row>
                {configuration && (
                    <div className={'my-2'}>
                        <Typography.Text type={"danger"}>
                            <WarningOutlined/>
                            {' Pozor, vybraný soubor bude importován do vybraného typu obsahu,' +
                                ' proveďte to na vlastní databázi nebo se vypořádejte s vytvořenými záznamy!'}
                        </Typography.Text>
                    </div>
                )}
                <Button onClick={() => this.props.setStep(-1)}>Předchozí krok</Button>
                {showBackButton && (<>
                        {configuration ? (
                            <Button type={"primary"}
                                    onClick={() => this.props.history.push('/app/import')}>Dokončit</Button>
                        ) : (
                            <Button icon={<RollbackOutlined/>} type={"primary"}
                                    onClick={() => history.go(-1)}>Zpět</Button>
                        )}
                    </>
                )}
            </>
        )
    }
}

export default SourceExecution