import React from "react";
import {Form} from "antd";
import IWidgetProps from "../../../../../model/interface/widget/IWidgetProps";
import IFormElementFunctions from "../../../../../model/interface/form/IFormElementFunctions";
import IEntityTableOptions from "../../../../../model/interface/form/elementOptions/IEntityTableOptions";
import FormElementEntityTable from "./FormElementEntityTable";
import IField from "../../../../../model/interface/dataStorage/IField";
import {connect, RootStateOrAny} from "react-redux";
import selectors from "../../../../../redux/selectors";
import IRepositoryService from "../../../../../model/interface/IRepositoryService";


interface IProps extends IWidgetProps<IFormElementFunctions, IEntityTableOptions> {
    getServiceByClass: (className: string) => IRepositoryService
}

class FormElementEntityTableWrapper extends React.Component<IProps> {

    render() {
        const {id, functions} = this.props
        const field = functions.getNode(id).field as IField

        return (
            <div>
                {field && (
                    <Form.Item name={field.name} label={field.label}>
                        <FormElementEntityTable {...this.props} className={field.targetEntity!} />
                    </Form.Item>
                ) }
            </div>
        );
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    return {
        getServiceByClass: (className: string) => selectors.services.findOneByFullClassName(state, className)
    }
}

export default connect(mapStateToProps)(FormElementEntityTableWrapper)