import React from "react"
import {InputNumber} from "antd";
import {IFilterBaseValue, IFilterInput} from "../IFilterBase";
import {API_FILTER_TYPE} from "../../../model/constants/ApiConstant";


interface IState {

}


interface IProps extends IFilterInput{
    onPressEnter?: () => void
    inputRef?: (node: HTMLInputElement) => void
}

class Range extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {}
    }

    render() {
        const {field, onChange, onPressEnter, inputRef, selected} = this.props

        const findMin = (selectedKeys: IFilterBaseValue[]) => {
            return selectedKeys.find(key => key.type === API_FILTER_TYPE.GREATER_OR_EQUAL)
        }
        const findMax = (selectedKeys: IFilterBaseValue[]) => {
            return selectedKeys.find(key => key.type === API_FILTER_TYPE.LESSER_OR_EQUAL)
        }
        const minValue = findMin(selected)?.value as undefined | number
        const maxValue = findMax(selected)?.value as undefined | number

        return (
            <>
                <div className={'pb-2'}>
                    <InputNumber
                        ref={inputRef}
                        placeholder={'Min'}
                        value={minValue as number}
                        onChange={min => {
                            onChange([
                                ...selected.filter((key) => key.type !== API_FILTER_TYPE.GREATER_OR_EQUAL),
                                {type: API_FILTER_TYPE.GREATER_OR_EQUAL, value: min, name: field.name}
                            ])
                        }}
                        onPressEnter={onPressEnter}
                    />
                    <InputNumber
                        placeholder={'Max'}
                        value={maxValue as number}
                        onChange={max => onChange([
                            ...selected?.filter(key => key.type !== API_FILTER_TYPE.LESSER_OR_EQUAL),
                            {type: API_FILTER_TYPE.LESSER_OR_EQUAL, value: max, name: field.name}
                        ])}
                        onPressEnter={onPressEnter}
                    />
                </div>
            </>
        )
    }
}

export default Range