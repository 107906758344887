import {Card, Collapse} from 'antd';
import React from "react";
import IBaseProps from "../../../../../model/interface/IBaseProps";
import IField, {
    COMPOSITE_FIELD_TYPE,
    FIELD_MODE_COMPOSITE,
    FIELD_MODE_RELATION,
    FIELD_MODE_SCALAR,
    FIELD_TYPE,
    IFieldOptions
} from "../../../../../model/interface/dataStorage/IField";
import FieldDateOptionsEditor from "./optionEditors/FieldDateOptionsEditor";
import FieldRateOptionsEditor from "./optionEditors/FieldRateOptionsEditor";
import EmployeesService from "../../../../../model/service/company/EmployeesService";
import FieldEmployeeOptionsEditor from "./optionEditors/FieldEmployeeOptionsEditor";
import LikesService from "../../../../../model/service/dataStorage/extension/LikesService";
import FieldLikeOptionsEditor from "./optionEditors/FieldLikeOptionsEditor";
import IContentType from "../../../../../model/interface/dataStorage/IContentType";
import DataStorageService from "../../../../../model/service/dataStorage/DataStorageService";
import FieldContentTypeOptionsEditor from "./optionEditors/FieldContentTypeOptionsEditor";
import {connect, RootStateOrAny} from "react-redux";
import selectors from "../../../../../redux/selectors";
import UnitsService from "../../../../../model/service/company/UnitsService";
import JobsService from "../../../../../model/service/company/JobsService";
import CompaniesService from "../../../../../model/service/company/CompaniesService";
import FieldStructureOptionEditor from "./optionEditors/FieldStructureOptionsEditor";
import FilesService from "../../../../../model/service/file/FilesService";
import FieldTextOptionsEditor from "./optionEditors/FieldTextOptionsEditor";
import WorkflowStatesService from "../../../../../model/service/file/WorkflowStatesService";
import CompositeFieldApprovalOptionsEditor from "./optionEditors/composite/CompositeFieldApprovalOptionsEditor";
import FieldPhoneNumberOptionsEditor from "./optionEditors/FieldPhoneNumberOptionsEditor";
import FieldFileOptionsEditor from "./optionEditors/FieldFileOptionsEditor";
import FieldCodeOptionsEditor from "./optionEditors/FieldCodeOptionsEditor";
import FieldBooleanOptionsEditor from "./optionEditors/FieldBooleanOptionsEditor";
import FieldEmojiOptionsEditor from "./optionEditors/FieldEmojiOptionsEditor";
import FormFieldType from "../../form/FormElement/formField/FormFieldType";

interface IProps extends IBaseProps {
    options: IFieldOptions
    fieldName: string[]
    field: IField
    contentType: IContentType
    findContentTypeByClassName: (className: string) => IContentType,
    collapsable?: boolean,
    title?: string
    forceType?: string
}

export interface IFieldOptionsEditorProps {
    options: IFieldOptions,
    buildFieldName: (...args: string[]) => string[]
}

interface IState {

}

class FieldOptionsEditor extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {};
    }

    static defaultProps = {
        title: 'Vlastnosti'
    }

    getFieldEditor = (field: IField) => {
        const {history, match, findContentTypeByClassName, contentType, options, fieldName, forceType} = this.props
        const sharedProps = {
            options,
            buildFieldName: (...args: string[]) => [...fieldName, ...args]
        }
        if (forceType){
            switch (forceType) {
                case FormFieldType.FIELD_DATE:
                    return <FieldDateOptionsEditor {...sharedProps}/>
                case FormFieldType.FIELD_RATE:
                    return <FieldRateOptionsEditor {...sharedProps}/>
                case FormFieldType.FIELD_WYSIWYG:
                    return <FieldTextOptionsEditor {...sharedProps}/>
                case FormFieldType.FIELD_PHONE_NUMBER:
                    return <FieldPhoneNumberOptionsEditor {...sharedProps}/>
                case FormFieldType.FIELD_CODE:
                    return <FieldCodeOptionsEditor {...sharedProps}/>
                case FormFieldType.FIELD_BOOLEAN:
                    return <FieldBooleanOptionsEditor {...sharedProps}/>
                case FormFieldType.FIELD_EMOJI:
                    return <FieldEmojiOptionsEditor {...sharedProps}/>
                default: break;
            }
            if (field.targetEntity){
                switch (forceType) {
                    case FormFieldType.FIELD_FILE:
                        return <FieldFileOptionsEditor {...sharedProps}/>
                    case FormFieldType.FIELD_EMPLOYEE:
                        return <FieldEmployeeOptionsEditor {...sharedProps} match={match} history={history} field={field}/>
                    case FormFieldType.FIELD_COMPANY_STRUCTURE:
                        return <FieldStructureOptionEditor {...sharedProps} defaultCompanyStructureAccepts={'unit'} match={match} history={history} field={field}/>
                    case WorkflowStatesService.getRecordClassName():
                        return null // not options here yet, maybe limit states in select... etc.
                    // return defaultOptions ? FieldWorkflowStateOptionsEditor.getDefaultOptions() :
                    //     <FieldWorkflowStateOptionsEditor options={this.getOptions(field)}/>
                    case FormFieldType.FIELD_CONTENT_TYPE:
                        return <FieldContentTypeOptionsEditor {...sharedProps} match={match} history={history}
                                                              targetContentType={findContentTypeByClassName(field.targetEntity)}/>
                    default: break;
                }
            }
        }
        if (field.mode === FIELD_MODE_SCALAR) {
            switch (field.type) {
                case FIELD_TYPE.DATE_TIME:
                    return <FieldDateOptionsEditor {...sharedProps}/>
                case FIELD_TYPE.RATE:
                    return <FieldRateOptionsEditor {...sharedProps}/>
                case FIELD_TYPE.HTML:
                    return <FieldTextOptionsEditor {...sharedProps}/>
                case FIELD_TYPE.PHONE_NUMBER:
                    return <FieldPhoneNumberOptionsEditor {...sharedProps}/>
                case FIELD_TYPE.CODE:
                    return <FieldCodeOptionsEditor {...sharedProps}/>
                case FIELD_TYPE.BOOLEAN:
                    return <FieldBooleanOptionsEditor {...sharedProps}/>
                case FIELD_TYPE.EMOJI:
                    return <FieldEmojiOptionsEditor {...sharedProps}/>
            }
        } else if (field.mode === FIELD_MODE_COMPOSITE) {
            switch (field.type) {
                case COMPOSITE_FIELD_TYPE.APPROVAL:
                    return <CompositeFieldApprovalOptionsEditor {...sharedProps} contentType={contentType}/>
            }
        } else if (field.mode === FIELD_MODE_RELATION && field.targetEntity) {
            switch (field.targetEntity) {
                case FilesService.getRecordClassName():
                    return <FieldFileOptionsEditor {...sharedProps}/>
                case EmployeesService.getInstance().getRecordClassName():
                    return <FieldEmployeeOptionsEditor {...sharedProps} match={match} history={history} field={field}/>
                case UnitsService.getInstance().getRecordClassName():
                    return <FieldStructureOptionEditor {...sharedProps} defaultCompanyStructureAccepts={'unit'} match={match} history={history} field={field}/>
                case JobsService.getInstance().getRecordClassName():
                    return <FieldStructureOptionEditor {...sharedProps} defaultCompanyStructureAccepts={'job'} match={match} history={history} field={field}/>
                case CompaniesService.getInstance().getRecordClassName():
                    return <FieldStructureOptionEditor {...sharedProps} defaultCompanyStructureAccepts={'company'} match={match} history={history} field={field}/>
                case LikesService.getRecordClassName():
                    return <FieldLikeOptionsEditor {...sharedProps}/>
                case WorkflowStatesService.getRecordClassName():
                    return null // not options here yet, maybe limit states in select... etc.
                // return defaultOptions ? FieldWorkflowStateOptionsEditor.getDefaultOptions() :
                //     <FieldWorkflowStateOptionsEditor options={this.getOptions(field)}/>
                case findContentTypeByClassName(field.targetEntity) ? (new DataStorageService(findContentTypeByClassName(field.targetEntity))).getRecordClassName() : '':
                    return <FieldContentTypeOptionsEditor {...sharedProps} match={match} history={history}
                                                          targetContentType={findContentTypeByClassName(field.targetEntity)}/>
            }
        }
    }

    render() {
        const {field, collapsable, title} = this.props
        const editor = this.getFieldEditor(field)
        const form = editor
        return editor ? (collapsable ?
                <Collapse>
                    <Collapse.Panel key={1} header={title}>
                        {form}
                    </Collapse.Panel>
                </Collapse>
                :
                <Card title={title}>
                    {form}
                </Card>
        ) : null
    }
}

const mapStateToProps = (store: RootStateOrAny) => {
    return {
        findContentTypeByClassName: (className: string) => selectors.contentTypes.findOneBy(store, 'fullClassName', className)
    }
}

export default connect(mapStateToProps)(FieldOptionsEditor)