import RestService from "model/service/dataStorage/RestService";
import IRestServiceOptions from "../../interface/api/IRestServiceOptions";
import IRestServiceCollectionResponse from "../../interface/api/IRestServiceCollectionResponse";
import ILog from "../../interface/log/ILog";

interface IRestLogsServiceCollectionResponse extends IRestServiceCollectionResponse {
    results: Array<ILog>
}

interface IRestLogsService {
    collectionList(options?: IRestServiceOptions): Promise<IRestLogsServiceCollectionResponse>,
    collectionCreate(data: ILog): Promise<ILog>,
    resourceRetrieve(id: number|string): Promise<ILog>,
    resourceUpdate(id: number|string, data: any): Promise<ILog>,
    resourceDelete(id: number|string): Promise<void>,
}

const LOGS_COLLECTION = 'logs'

const LogsService: IRestLogsService = {
    collectionList: function (options?) {
        return RestService.collectionList(LOGS_COLLECTION, options as unknown as IRestServiceOptions) as Promise<IRestLogsServiceCollectionResponse>
    },
    collectionCreate: function ( data) {
        return RestService.collectionCreate(LOGS_COLLECTION, data) as Promise<ILog>
    },
    resourceRetrieve: function (id) {
        return RestService.resourceRetrieve(LOGS_COLLECTION, id) as Promise<ILog>
    },
    resourceDelete: function (id) {
        return RestService.resourceDelete(LOGS_COLLECTION, id)
    },
    resourceUpdate: function (id, data) {
        return RestService.resourceUpdate(LOGS_COLLECTION, id, data) as Promise<ILog>
    }
}

export default LogsService