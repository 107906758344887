import IContentType from "../../interface/dataStorage/IContentType";
import IRepositoryService from "../../interface/IRepositoryService";
import IPresenter from "../../interface/dataStorage/IPresenter";
import _ from "underscore"
import IRestResource from "../../interface/api/IRestResource";
import IField from "../../interface/dataStorage/IField";
import IRestServiceOptions from "../../interface/api/IRestServiceOptions";
import IRestServiceCollectionResponse from "../../interface/api/IRestServiceCollectionResponse";
import RestService from "model/service/dataStorage/RestService";
import IRestServiceChoiceListResponse from "../../interface/api/IRestServiceChoiceListResponse";
import store from "../../../redux/store";
import {extractRouteParametersFromUrl} from "../../../redux/selectors/Routes";

export default class DataStorageService implements IRepositoryService {
    contentType: IContentType
    entityClassName: string

    constructor(contentType: IContentType) {
        this.contentType = contentType
        this.entityClassName = contentType.fullClassName
    }

    static VIRTUAL_ENTITY = 'App\\DataStorage\\Virtual\\Entity\\'

    getRecordClassName(): string {
        return this.contentType.fullClassName;
    }

    getContentType(): IContentType {
        return this.contentType
    }

    getTitle(): string {
        return this.contentType.label || this.contentType.name;
    }

    getFields(): IField[] {
        return this.contentType.fields
    }

    getStringValue(item: IRestResource): string {
        return item[this.getRepresentativeField().name]
    }

    getPresenter(name: string): IPresenter | null {
        return _.find(this.contentType.presenters, presenter => presenter.name === name) || null
    }

    getDefaultPresenter(): IPresenter {
        const presenters = this.getPresenterList()
        if(presenters.length > 0) {
            return this.getPresenter(presenters[0].value)!;
        }
        const representativeField = _.find(this.contentType.fields, field => field.contentTypeName)
        return {
            type: 'label',
            name: 'label',
            label: 'Nazev',
            options: {
                label: '#' + (representativeField ? representativeField!.name : 'id')
            }
        }
    }

    getRepresentativeField(): IField {
        const representativeField = _.find(this.contentType.fields, field => field.contentTypeName)
        return representativeField || this.contentType.fields[0]
    }

    getPresenterList() {
        return this.contentType.presenters.map(presenter => {return {value: presenter.name, label: presenter.label}})
    }

    collectionList(options?: IRestServiceOptions): Promise<IRestServiceCollectionResponse> {
        const route = extractRouteParametersFromUrl(store.getState(), window.location.href)
        return RestService.collectionList(this.contentType.collection, {route, ...options})
    }
    collectionCreate(data: any, options?: IRestServiceOptions): Promise<IRestResource> {
        return RestService.collectionCreate(this.contentType.collection, data, options)
    }
    resourceRetrieve(id: number | string, options?: IRestServiceOptions): Promise<IRestResource> {
        return RestService.resourceRetrieve(this.contentType.collection, id, options)
    }
    resourceUpdate(id: number | string, data: any, options?: IRestServiceOptions): Promise<IRestResource> {
        return RestService.resourceUpdate(this.contentType.collection, id, data, options)
    }
    resourceDelete(id: number | string): Promise<void> {
        return RestService.resourceDelete(this.contentType.collection, id)
    }

    choiceList(presenterName: string, options?: IRestServiceOptions): Promise<IRestServiceChoiceListResponse> {
        return RestService.collectionList('choices/' + this.contentType.collection + '/' + presenterName, options) as unknown as Promise<IRestServiceChoiceListResponse>
    }
}