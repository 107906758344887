import React, {RefObject} from "react"
import {Card, Form, FormInstance, Input, Row, Select} from "antd";
import Title from "antd/es/typography/Title";
import IContentType from "../../../../../model/interface/dataStorage/IContentType";
import {API_AGGREGATION_TYPE_LIST} from "../../../../../model/constants/ApiConstant";
import FieldPicker from "../../../../shared/pickers/FieldPicker";
import {FIELD_NUMBER_TYPES} from "../../../../../model/interface/dataStorage/IField";
import Button from "../../../../shared/button/Button";
import {CloseOutlined, PlusOutlined} from "@ant-design/icons";
import IViewOptions from "../../../../../model/interface/dataStorage/view/IViewOptions";
import Utils from "../../../../../utils";
import HumanTimeConfigBuilder, {IHumanTimeConfig} from "../../../../shared/human-time/HumanTimeConfigBuilder";

interface IState {
    aggregations: string[]
}

interface IProps {
    contentType: IContentType
    values: IViewOptions,

    formRef: RefObject<FormInstance>
}


class AggregationProperties extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            aggregations: props.values?.aggregations ? Object.keys(props.values?.aggregations) : []
        }
    }

    static FORMAT_TYPES = {
        HUMAN_TIME: 'human-time'
    }

    buildName = (field: string, index: string) => ['options', 'aggregations', index, field]

    addAggregation = () => {
        this.setState(state => ({aggregations: [...state.aggregations, Utils.uuid()]}))
    }

    removeAggregation = (index: string) => {
        this.setState(state => ({aggregations: state.aggregations.filter(a => a !== index)}))
    }

    render() {
        const {contentType, values, formRef} = this.props
        const {aggregations} = this.state

        return (
            <>
                <Card size={"small"}>
                    <Row justify={"space-between"}>
                        <Title level={3}>Aggregace</Title>
                        <Button size={"small"} type={'success'} icon={<PlusOutlined/>} onClick={this.addAggregation}/>
                    </Row>
                    {aggregations.map(a => {
                        const currentAggregation = values?.aggregations?.[a]
                        console.log(currentAggregation?.format)
                        return (
                            <Card size={"small"}
                                  title={<Row justify={"space-between"}>
                                      <Title level={4}>{currentAggregation?.title}</Title>
                                      <Button size={"small"} type={'link'} danger icon={<CloseOutlined/>}
                                              onClick={() => this.removeAggregation(a)}/></Row>}>
                                <Form.Item label={"Pole"} name={this.buildName('field', a)}
                                           rules={[{required: true, message: 'Pole je povinné'}]}>
                                    <FieldPicker serviceClassName={contentType.fullClassName} output={'name'}
                                                 mode={"simple"}
                                                 onChange={(value) => {
                                                     const currentField = contentType.fields.find(f => f.name === value)
                                                     if (currentField && !currentAggregation?.title) {
                                                         formRef.current?.setFields([{
                                                             name: this.buildName('title', a),
                                                             value: currentField.label
                                                         }])
                                                     }
                                                 }
                                                 }
                                                 allowTypes={FIELD_NUMBER_TYPES}/>
                                </Form.Item>
                                <Form.Item label={"Typ aggregace"} name={this.buildName('type', a)}
                                           rules={[{required: true, message: 'Pole je povinné'}]}>
                                    <Select options={API_AGGREGATION_TYPE_LIST}/>
                                </Form.Item>
                                <Form.Item label={"Titulek"} name={this.buildName('title', a)}
                                           rules={[{required: true, message: 'Pole je povinné'}]}>
                                    <Input/>
                                </Form.Item>
                                <Form.Item label={"Formát jako"} name={this.buildName('format', a)}>
                                    <Select>
                                        {Object.entries(AggregationProperties.FORMAT_TYPES)
                                            .map(([label, value]) => (
                                                <Select.Option value={value} key={value}>
                                                    {label}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                                {currentAggregation?.format === AggregationProperties.FORMAT_TYPES.HUMAN_TIME && (
                                    <Form.Item label={"Human time format"} name={this.buildName('humanTimeFormat', a)}
                                               rules={[{
                                                   required: true,
                                                   validator: async (rule, value: IHumanTimeConfig) => {
                                                       if (!value.interval || !value.format) {
                                                           throw new Error('Invalid config')
                                                       }
                                                   }, message: 'Pole je povinné'
                                               }]}>
                                        <HumanTimeConfigBuilder/>
                                    </Form.Item>
                                )}
                            </Card>
                        )
                    })}
                </Card>

            </>
        )
    }
}

export default AggregationProperties