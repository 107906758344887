import React from "react"
import {Card, Dropdown, Menu, notification, Progress, Spin, Tooltip} from "antd";
import {DownloadOutlined} from "@ant-design/icons";
import DownloadRequestsService, {
    DOWNLOAD_REQUEST_FORMAT,
    DOWNLOAD_REQUEST_FORMAT_LABELS,
    DOWNLOAD_REQUEST_TYPES
} from "../../../../model/service/export/DownloadRequestsService";
import IViewUnit from "../../../../model/interface/dataStorage/IViewUnit";
import IRestServiceOptions from "../../../../model/interface/api/IRestServiceOptions";
import IViewSettingsStructure from "../../../../model/interface/dataStorage/view/settings/IViewSettingsStructure";
import Button from "../../../shared/button/Button";

interface IProps {
    viewUnit: IViewUnit
    totalCount: number
    options: IRestServiceOptions
    settings: IViewSettingsStructure,
    type?: string
}

interface IState {
    loading: boolean
}


class ViewTableExportButton extends React.Component<IProps, IState> {


    constructor(props: IProps, context: any) {
        super(props, context);
        this.state = {
            loading: false
        }
    }

    export(format: string) {
        const {viewUnit, totalCount, options, settings, type} = this.props
        this.setState({loading: true})
        DownloadRequestsService.create(
            type || DOWNLOAD_REQUEST_TYPES.DATA_STORAGE_VIEW,
            format,
            viewUnit.label,
            {
                uuid: viewUnit.uuid,
                parameters: options,
                settings: settings
            }).then(resource => {
            notification.open({
                key: resource.uuid,
                message: <div>Připravuje se soubor ke stažení <Spin className={'ml-2'}></Spin></div>,
                description: <div>
                    <Progress percent={0}/>
                </div>,
                duration: 0,
                type: "info"
            })
            this.setState({loading: false})
            DownloadRequestsService.prepareDownload(resource, totalCount);
        }).catch(() => this.setState({loading: false}))
    }

    render() {
        const {loading} = this.state
        const {totalCount, viewUnit} = this.props

        let formats: string[] = []
        if (!viewUnit.options.exportFormats?.length) {
            formats.push(DOWNLOAD_REQUEST_FORMAT.XLSX)
        } else {
            formats = viewUnit.options.exportFormats
        }

        return (
            <div className={"d-inline-block"}>
                {formats.length > 1 ? (
                    <Tooltip title={"Exportovat data"}>
                        <Dropdown disabled={totalCount === 0} trigger={['click']} overlay={
                            <Card size={"small"}>
                                <Menu>
                                    {formats.map(format => (
                                        <Menu.Item className={'m-0'} onClick={() => this.export(format)} key={format}
                                                   icon={DOWNLOAD_REQUEST_FORMAT_LABELS.find(f => f.value === format)?.icon}>
                                            {DOWNLOAD_REQUEST_FORMAT_LABELS.find(f => f.value === format)?.label}
                                        </Menu.Item>
                                    ))}
                                </Menu>
                            </Card>
                        }>
                            <Button
                                disabled={totalCount === 0}
                                loading={loading}
                                icon={<DownloadOutlined/>}
                                size={"small"}
                            ></Button>
                        </Dropdown>
                    </Tooltip>
                ) : (
                    <Tooltip
                        title={'Exportovat do formátu ' + DOWNLOAD_REQUEST_FORMAT_LABELS.find(f => f.value === formats[0])?.label}>
                        <Button
                            disabled={totalCount === 0}
                            loading={loading}
                            onClick={() => this.export(formats[0])}
                            icon={<DownloadOutlined/>}
                            size={"small"}
                        />
                    </Tooltip>
                )}
            </div>
        )
    }
}


export default ViewTableExportButton
