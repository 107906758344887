import React from "react";
import EmployeeLabel from "../../employees/EmployeeLabel";
import {Table} from "antd";
import {ColumnsType} from "antd/lib/table/interface";
import PlanOrEntryOrReportState from "./PlanOrEntryOrReportState";
import IPlan from "../../../../../model/interface/company/workload/IPlan";
import {MomentBuilder} from "../../../../../utils/MomentBuilder";

interface IProps {
    plans: IPlan[]
    loading?: boolean
}

interface IState {
}

class RequestsTable extends React.Component<IProps, IState> {

    constructor(props: IProps, context: any) {
        super(props, context);
        this.state = {}
    }

    buildColumns(): ColumnsType<IPlan> {
        return [
            {
                title: 'Žadatel',
                dataIndex: 'request',
                render: (_value: any, {employee}: IPlan) => (<EmployeeLabel employee={employee}/>)
            },
            {
                title: 'Datum',
                render(_value: any, plan: IPlan) {
                    return (
                        <>
                            {MomentBuilder.build(plan.beginAt).format('D.M. HH:mm')}
                            {' - '}
                            {MomentBuilder.build(plan.endAt).format('D.M. HH:mm')}
                        </>
                    )
                }
            },
            {
                title: 'Typ',
                render: (_value: any, plan: IPlan) => {
                    const type = plan.type!
                    return (
                        <div className={"d-flex"}>
                            <span style={{
                                backgroundColor: type.color || '',
                                width: 16,
                                height: 16,
                                display: 'block',
                                borderRadius: 4
                            }} className={"mr-2"}/>
                            <span>{type?.title}</span>
                        </div>
                    )
                }
            },
            {
                title: 'Type',
                render: (_value: any, entry: IPlan) => (
                    <PlanOrEntryOrReportState resource={entry} waiting={entry.state === 'new'}/>)
            },
            {
                title: 'Zástup',
                render: (_value: any, entry: IPlan) => entry.representatives.map(r =>
                    <EmployeeLabel employee={r}/>
                )
            },
        ]
    }

    render() {
        const {plans, loading} = this.props
        return (
            <>
                <Table
                    rowKey={"uuid"}
                    loading={loading}
                    pagination={false}
                    dataSource={plans}
                    columns={this.buildColumns()}
                />
            </>
        );
    }
}

export default RequestsTable