import antdEsES from 'antd/es/locale/es_ES';

const EsLang = {
  antd: antdEsES,
  locale: 'es-ES',
  messages: {

  },
};
export default EsLang;
