import React from "react";
import {Button, Row, Spin} from "antd";
import Text from "antd/es/typography/Text";
import {QuestionCircleOutlined} from "@ant-design/icons";
import Title from "antd/es/typography/Title";
import IManual from "../../../model/interface/ui/IManual";
import ManualsService from "../../../model/service/ui/ManualsService";
import Modal from "../../shared/modal/Modal";

interface IState {
    loading: boolean
    manual?: IManual
}

interface IProps {
    id: string
    visible: boolean
    onFinish: () => void

}

class ManualModal extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            loading: false
        }
    }

    componentDidMount() {
        this.load()
    }

    load() {
        const {id} = this.props
        this.setState({loading: true})
        ManualsService.resourceRetrieve(id).then(manual => {
            this.setState({manual, loading: false})
        })
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>) {
        const {loading, manual} = this.state
        const {id, visible} = this.props
        if (!loading && visible && (!manual || manual.uuid !== id)) {
            this.load()
        }
    }

    finish = () => {
        this.props.onFinish()
    }

    render() {
        const {visible} = this.props
        const {loading, manual} = this.state

        return (
            <>
                <Modal className={'dir-ltr'}
                       title={
                           <Title level={4} className={'m-0'}>
                               <Text className={'pr-2 font-size-xl'}>
                                   <QuestionCircleOutlined/>
                               </Text>
                               {'Manuál'}
                           </Title>
                       }
                       fullScreenOption={true}
                       width={600}
                       visible={visible}
                       onCancel={this.finish}
                       destroyOnClose={true}
                       footer={[
                           <Button onClick={this.finish}>
                               Ok
                           </Button>
                       ]}
                >
                    {loading ? <Row justify={"center"}><Spin size={"large"}/></Row> : manual ? (
                        <div dangerouslySetInnerHTML={{__html: manual.content}}/>
                    ) : 'Požadovaný manuál zřejmě neexistuje'}
                </Modal>
            </>
        )
    }
}

export default ManualModal