import React from "react"
import {Result} from "antd";
import {IViewStepProps} from "./ViewUnitForm";
import {ArrowLeftOutlined} from "@ant-design/icons";

interface IPropsViewSummary extends IViewStepProps {
}

class ViewUnitSummary extends React.Component<IPropsViewSummary> {
    render() {
        return (
            <>
                <Result
                    status={"success"}
                    title={<div>
                        Sekce pohledu je připravena k uložení
                        <div className={'font-size-md'}>
                            <ArrowLeftOutlined/> Zpět na krok
                        </div>
                        <div className={'font-size-md'}>
                            <ArrowLeftOutlined rotate={225}/> Uložit
                        </div>
                    </div>}
                />
            </>
        )
    }
}

export default ViewUnitSummary