import React, {RefObject} from "react"
import {Button, Form, FormInstance} from "antd";
import IForm from "../../../../model/interface/form/IForm";
import {IFormStepProps} from "./FormConfiguration";
import DragSortingTable from "../../../shared/Table/DragSortingTable";
import FilterTreeBuilder from "../../view/settings/customFilters/FilterTreeBuilder";
import {ColumnsType} from "antd/es/table";
import {ArrowDownOutlined} from "@ant-design/icons";
import {connect, RootStateOrAny} from "react-redux";
import selectors from "../../../../redux/selectors";

interface IStateFormProperties {
    formRef: RefObject<FormInstance>,
    changedForms: IForm[]
}

interface IProps extends IFormStepProps{
    findForm: (value: any) => IForm
}

class FormEvaluation extends React.Component<IProps, IStateFormProperties> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            formRef: React.createRef(),
            changedForms: props.contentType.forms.filter(f => (typeof f === "object" ? f.uuid: f) !== props.form.uuid)
                .map(f => (typeof f === "object" ? f: props.findForm(f)))
        }
    }

    _isMounted = false;

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    confirm = (back: boolean = false) => {
        const {changedForms} = this.state
        const {form} = this.props
        this.props.onChange(form, changedForms, true, back)
    }

    onValuesChange = (values: any) => {
        this.props.onChange(values)
    }

    getDataSource(changedForms: IForm[], form: IForm) {
        return [...changedForms, {...form}]
            .sort((a, b) => (a.priority || 0) - (b.priority || 0));
    }

    getColumns(form: IForm): ColumnsType<IForm> {
        return [{
            title: <div>
                <ArrowDownOutlined className={'mr-2'}/>Priorita zobrazení
            </div>,
            render: (_: any, row: IForm) => {
                let children: any = row.name
                let className: string = ''
                if (row.uuid === form.uuid) {
                    className = 'ant-btn-info'
                }
                return {props: {className}, children}
            },
        }];
    }

    onFormsSort = (data: IForm[]) => {
        const {form} = this.props
        const priority = data.findIndex(c => c.uuid === form.uuid)
        this.setState(() => ({
            changedForms: [...data.map((c, index) => ({...c, priority: index}))
                .filter(c => c.uuid !== form.uuid)]
        }), () => this.onValuesChange({...form, priority: (priority < 0 ? undefined : priority)}))
    }

    render() {
        const {form, contentType, history, match} = this.props
        const {changedForms} = this.state
        return (
            <>
                <Form ref={this.state.formRef} initialValues={{...form}} onValuesChange={(values) => this.onValuesChange(values)}>
                    <DragSortingTable className={'mb-3'} onMove={this.onFormsSort}
                                      columns={this.getColumns(form)}
                                      pagination={false}
                                      dataSource={this.getDataSource(changedForms, form)}
                                      rowKey={card => card.uuid}/>
                    <Form.Item name={'conditions'} label={'Podmínky zobrazení'}>
                        <FilterTreeBuilder contentTypeFullClassName={contentType.fullClassName} fieldPickerMode={"simple"}
                                           history={history} match={match} addNewLabel={'Přidat podmínku'}/>
                    </Form.Item>
                </Form>

                <Button onClick={() => this.confirm(true)}>Předchozí krok</Button>
                <Button type={"primary"} onClick={() => this.confirm()}>
                    Následující krok
                </Button>
            </>
        )
    }
}

const mapStateToProps = (store: RootStateOrAny) => {
    return {
        findForm: (uuid: string) => selectors.forms.findOneBy(store, 'uuid', uuid)
    }
}

export default connect(mapStateToProps)(FormEvaluation)