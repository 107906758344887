import React, {RefObject} from "react";
import {Form, FormInstance, Input, Select, Slider, Switch, Typography} from "antd";
import selectors from "../../../../../../../redux/selectors";
import {connect, RootStateOrAny} from "react-redux";
import ICardWidgetRelationField from "../../../../../../../model/interface/dataStorage/card/ICardWidgetRelationField";
import IContentType from "../../../../../../../model/interface/dataStorage/IContentType";
import {ISetupState} from "../../../../../../../redux/reducers/Setup";
import _ from "underscore";
import IField from "../../../../../../../model/interface/dataStorage/IField";
import IRepositoryService from "../../../../../../../model/interface/IRepositoryService";
import IView from "../../../../../../../model/interface/dataStorage/IView";
import FieldCollapseInfo from "../../../field/FieldCollapseInfo";
import FilterTreeBuilder from "../../../../../view/settings/customFilters/FilterTreeBuilder";
import IBaseProps from "../../../../../../../model/interface/IBaseProps";


interface IProps extends IBaseProps {
    options: ICardWidgetRelationField
    findViewsByContentType: (uuid: string) => IView[]
    findContentTypeByClassName: (name: string) => IContentType,
    findServiceByClassName: (className: string) => IRepositoryService,
    field: IField,
    formRef: RefObject<FormInstance>
}

export const FILTER_RELATION_FIELD_VALUE = 'filter_relation_field_value'

class RelationFieldEditor extends React.Component<IProps> {

    getViews = () => {
        const {findViewsByContentType, findContentTypeByClassName} = this.props
        const {field} = this.props
        const contentType = field.targetEntity && findContentTypeByClassName(field.targetEntity)
        return contentType ? findViewsByContentType(contentType.uuid) : []
    }

    render() {
        const {findContentTypeByClassName, options, history, match} = this.props
        const {view} = options
        const {field} = this.props
        const contentType = field.targetEntity && findContentTypeByClassName(field.targetEntity)

        const views = this.getViews()
        const viewObject = _.findWhere(views, {uuid: view})

        return (
            <div>
                <FieldCollapseInfo field={field}/>
                <Form.Item name={'view'} label={'Pohled'} rules={[{required: true}]}>
                    <Select allowClear={true} disabled={views.length < 1}>
                        {views.map(view => (
                            <Select.Option key={view.uuid}
                                           value={view.uuid}>{view.label} [{view.name}]</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item initialValue={viewObject?.label} name={'title'} label={'Titulek'}>
                    <Input disabled={!view}/>
                </Form.Item>
                <Form.Item name={'titleLevel'} label={'Velikost titulku'} initialValue={2}>
                    <Slider disabled={!view} defaultValue={1} min={1} max={5} tooltipVisible/>
                </Form.Item>
                <Form.Item name={'allowSettings'} initialValue={viewObject?.allowSettings} label={'Povolit nastavení'}
                           valuePropName={'checked'}>
                    <Switch disabled={!view}/>
                </Form.Item>
                {views.length < 1 &&
                    <Typography.Text type={"danger"}>
                        Pro navázaný typ obsahu neexistuje odpovídající pohled
                    </Typography.Text>
                }
                {contentType && view && (
                    <div>
                        <Form.Item name={'filters'} label={'Filtry'}>
                            <FilterTreeBuilder customTokens={[{label: 'Použít vazebné pole', value: FILTER_RELATION_FIELD_VALUE}]}
                                               contentTypeFullClassName={contentType.fullClassName} match={match}
                                               history={history} prefix={'_subViewFilter'}/>
                        </Form.Item>
                    </div>
                )}
            </div>
        )
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    const {views} = state.setup as ISetupState

    return {
        findViewsByContentType: (uuid: string) => views.filter(view => view.contentTypes.includes(uuid)),
        findContentTypeByClassName: (name: string) => selectors.contentTypes.findOneBy(state, 'fullClassName', name),
        findServiceByClassName: (className: string) => selectors.services.findOneByFullClassName(state, className)
    }
}

export default connect(mapStateToProps)(RelationFieldEditor)
