import IRestServiceCollectionResponse from "../../interface/api/IRestServiceCollectionResponse";
import RestService from "model/service/dataStorage/RestService";
import IRestServiceOptions from "model/interface/api/IRestServiceOptions";
import IBookmark from "model/interface/ui/IBookmark";

interface IRestBookmarksServiceCollectionResponse extends IRestServiceCollectionResponse {
    results: Array<IBookmark>
}

interface IRestBookmarksService {
    collectionList(options?: IRestServiceOptions): Promise<IRestBookmarksServiceCollectionResponse>,

    collectionCreate(data: any): Promise<IBookmark>,

    resourceRetrieve(id: number | string): Promise<IBookmark>,

    resourceUpdate(id: number | string, data: any): Promise<IBookmark>,

    resourceDelete(id: number | string): Promise<void>,

    collectionList(): Promise<IRestServiceCollectionResponse>,
}

const BookmarksService_COLLECTION = 'bookmarks'

const BookmarksService: IRestBookmarksService = {
    collectionList: function (options?: IRestServiceOptions) {
        return RestService.collectionList(BookmarksService_COLLECTION, options as unknown as IRestServiceOptions) as Promise<IRestBookmarksServiceCollectionResponse>
    },
    collectionCreate: function (data) {
        return RestService.collectionCreate(BookmarksService_COLLECTION, data) as Promise<IBookmark>
    },
    resourceRetrieve: function (id) {
        return RestService.resourceRetrieve(BookmarksService_COLLECTION, id) as Promise<IBookmark>
    },
    resourceDelete: function (id) {
        return RestService.resourceDelete(BookmarksService_COLLECTION, id)
    },
    resourceUpdate: function (id, data) {
        return RestService.resourceUpdate(BookmarksService_COLLECTION, id, data) as Promise<IBookmark>
    }
}

export default BookmarksService