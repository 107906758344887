import RestService from "model/service/dataStorage/RestService";
import IRestServiceOptions from "../../interface/api/IRestServiceOptions";
import IRestServiceCollectionResponse from "../../interface/api/IRestServiceCollectionResponse";
import IThread from "../../interface/comment/IThread";
import IRepositoryService from "../../interface/IRepositoryService";
import IPresenter from "../../interface/dataStorage/IPresenter";
import IField from "../../interface/dataStorage/IField";

interface IRestThreadsServiceCollectionResponse extends IRestServiceCollectionResponse {
  results: Array<IThread>
}

interface IRestThreadsService extends IRepositoryService {
  collectionList(options?: IRestServiceOptions): Promise<IRestThreadsServiceCollectionResponse>,
  collectionCreate(data: any): Promise<IThread>,
  resourceRetrieve(id: number|string): Promise<IThread>,
  resourceUpdate(id: number|string, data: any): Promise<IThread>,
  resourceDelete(id: number|string): Promise<void>,
}

const ThreadsService_COLLECTION = 'threads'

const ThreadsService: IRestThreadsService = {
  getFields(): IField[] {
    // TODO
    return [];
  },
  getRecordClassName() {
    return 'App\\Comment\\Entity\\Thread'
  },
  getTitle(): string {
    return "Komentáře";
  },
  collectionList: function (options?) {
    return RestService.collectionList(ThreadsService_COLLECTION, options as unknown as IRestServiceOptions) as Promise<IRestThreadsServiceCollectionResponse>
  },
  collectionCreate: function ( data) {
    return RestService.collectionCreate(ThreadsService_COLLECTION, data) as Promise<IThread>
  },
  resourceRetrieve: function (id) {
    return RestService.resourceRetrieve(ThreadsService_COLLECTION, id) as Promise<IThread>
  },
  resourceDelete: function (id) {
    return RestService.resourceDelete(ThreadsService_COLLECTION, id)
  },
  resourceUpdate: function (id, data) {
    return RestService.resourceUpdate(ThreadsService_COLLECTION, id, data) as Promise<IThread>
  },
  getStringValue(thread: IThread): string {
    return 'Komentáře: (' + thread.comments.length + ')'
  },
  getPresenter(name: string): IPresenter | null {
    return this.getDefaultPresenter()
  },
  getDefaultPresenter(): IPresenter {
    return {
      type: 'label',
      name: 'default',
      label: 'Výchozí',
      options: {
        label: "#thread"
      }
    } as IPresenter
  },
  getPresenterList() {
    //TODO ADD PRESENTERS ?
    return [this.getDefaultPresenter()].map(presenter => {return {value: presenter.name, label: presenter.label}})
  }
}

export default ThreadsService