import {Card, Form, Select, Slider, Switch} from 'antd';
import React from "react";
import {IFieldOptionsEditorProps} from "../FieldOptionsEditor";
import IBaseProps from "../../../../../../model/interface/IBaseProps";
import FormElementField from "../../../form/FormElement/FormElementField";
import EmployeesService from "../../../../../../model/service/company/EmployeesService";
import IField from "../../../../../../model/interface/dataStorage/IField";
import IRepositoryService from "../../../../../../model/interface/IRepositoryService";
import {connect, RootStateOrAny} from "react-redux";
import selectors from "../../../../../../redux/selectors";

export type FieldEmployeeOptionsActiveOn = { type: 'month' | 'year' | 'day', value?: number }

export interface IFieldEmployeeOptions {
    employeeOnlyDirectChildren?: boolean
    employeeStructureRoot?: string | string[]
    employeeMultiple?: boolean
    employeeActiveOn?: { type: 'month' | 'year' | 'day', value?: number }
    employeeIncludeInactive?: boolean
    employeeSubordinates?: boolean,
    employeeDisableTree?: boolean,
    employeeAllowUnselect?: boolean
    employeeHideCurrent?: boolean
}

const FIELD_EMPLOYEE_ACTIVE_ON_TYPES = [
    {value: 'day', label: 'Den'},
    {value: 'month', label: 'Mesic'},
    {value: 'year', label: 'Rok'},
]

interface IProps extends IFieldOptionsEditorProps, IBaseProps {
    findServiceByClassName: (className: string) => IRepositoryService,
    field: IField
}

class FieldEmployeeOptionsEditor extends React.Component<IProps> {

    getPresenters = () => {
        return EmployeesService.getInstance().getPresenterList()
    }

    render() {
        const {history, match, field, options, buildFieldName} = this.props
        return (
            <Card>
                <Form.Item name={buildFieldName('employeeOnlyDirectChildren')} label={'Pouze přímé děti'}
                           valuePropName={'checked'}>
                    <Switch/>
                </Form.Item>
                <Form.Item name={buildFieldName('employeeIncludeInactive')} label={'Zobrazit také neaktivní'}
                           valuePropName={'checked'}>
                    <Switch/>
                </Form.Item>
                <FormElementField
                    field={{}}
                    customFieldName={buildFieldName('employeeStructureRoot')}
                    id={"empStructureRoot"}
                    label={"Omezit na strom organigramu"}
                    type={"companyStructure"}
                    preview={true}
                    match={match}
                    functions={{} as any}
                    history={history}
                    options={{
                        ...options,
                        required: false,
                        disabled: false,
                        type: "companyStructure",
                        label: "",
                        companyStructureAccepts: "node"
                    }}
                />
                <Form.Item name={buildFieldName('employeeSubordinates')}
                           label={'Omezit na podřízené přihlášeného zaměstnance'}
                           valuePropName={'checked'}>
                    <Switch/>
                </Form.Item>
                <Form.Item name={buildFieldName('employeeActiveOn', 'type')} label={'Aktivni k'}>
                    <Select allowClear>
                        {FIELD_EMPLOYEE_ACTIVE_ON_TYPES.map(type => (
                            <Select.Option value={type.value} key={type.value}>
                                {type.label}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                {field && (
                    <Form.Item label={"Zobrazení"} name={buildFieldName("contentTypePresenter")}>
                        <Select allowClear>
                            {this.getPresenters().map(type => (
                                <Select.Option key={type.value} value={type.value}>{type.label}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                )}
                <Form.Item name={buildFieldName('employeeDisableTree')} label={'Zakázat stromové zobrazení'}
                           valuePropName={'checked'}>
                    <Switch/>
                </Form.Item>
                {options.employeeActiveOn?.type && (
                    <Form.Item name={buildFieldName('employeeActiveOn', 'value')} noStyle={true}>
                        {
                            {
                                day: (
                                    <Slider
                                        tipFormatter={value => `aktivní k ${value}. dni měsíce`}
                                        marks={{
                                            1: 1,
                                            28: 28
                                        }}
                                        min={1} max={28}/>
                                ),
                                month: (
                                    <Slider tipFormatter={value => `aktivní k ${value}. měsíci v roku`} marks={{
                                        1: 1,
                                        12: 12
                                    }}
                                            min={1} max={12}/>
                                ),
                                year: (
                                    'Aktuální rok'
                                )
                            }[options.employeeActiveOn.type]
                        }
                    </Form.Item>
                )}
            </Card>
        )
    }
}

const mapStateToProps = (store: RootStateOrAny) => {
    return {
        findServiceByClassName: (name: string) => selectors.services.findOneByFullClassName(store, name)
    }
}

export default connect(mapStateToProps)(FieldEmployeeOptionsEditor)