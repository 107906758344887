import React from "react"
import {Card, Col, Divider, Form, Input, Row, Switch, Typography} from "antd";
import {IViewSettingsProps} from "../ViewSettings";
import IViewCalendarSettings from "../../../../model/interface/dataStorage/view/calendar/IViewCalendarSettings";
import {connect, RootStateOrAny} from "react-redux";
import selectors from "../../../../redux/selectors";
import IContentType from "../../../../model/interface/dataStorage/IContentType";
import ViewCalendarFilterSettings from "./ViewCalendarFilterSettings";

interface IProps extends IViewSettingsProps {
    findContentTypeByUuid: (uuid: string) => IContentType
}

class ViewCalendarSettings extends React.Component<IProps, IViewCalendarSettings> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            ...props.settings
        }
    }

    defineColor = (color: string, contentType: string) => {
        this.setState(state => ({
            calendarColors: {...state.calendarColors || {}, [contentType]: color}
        }), this.onChange)
    }

    onChange = () => {
        this.props.onChange({...this.state})
    }

    onChangeForm = (values: any): void => {
        this.setState(state => ({...state, ...values}), this.onChange)
    }

    render() {
        const {view, findContentTypeByUuid, settings} = this.props
        const {calendarColors} = this.state

        return (
            <>
                <Form onValuesChange={this.onChangeForm} initialValues={this.state}>
                    <Row gutter={12}>
                        <Col sm={12}>
                            <Form.Item name={'calendarSaveMonth'} label={'Zapamatovat si vybraný měsíc'}
                                       valuePropName={"checked"}>
                                <Switch/>
                            </Form.Item>
                        </Col>
                        <Col sm={12}>
                            <Form.Item name={'calendarSelectedContentTypes'}
                                       label={'Zapamatovat si vybrané typy obsahu'}
                                       valuePropName={"checked"}>
                                <Switch/>
                            </Form.Item>
                        </Col>
                        <Col sm={12}>
                            <Form.Item name={'calendarSaveMode'} label={'Zapamatovat si vybraný režim zobrazení'}
                                       valuePropName={"checked"}>
                                <Switch/>
                            </Form.Item>
                        </Col>
                        <Col sm={12}>
                            <Form.Item name={'calendarSaveFilters'} label={'Průběžně ukládat filter'}
                                       valuePropName={"checked"}>
                                <Switch/>
                            </Form.Item>
                        </Col>
                        <Col sm={12}>
                            <Form.Item name={'calendarHighlightWeekend'} label={'Zvýraznění víkendu'}
                                       valuePropName={"checked"}>
                                <Switch/>
                            </Form.Item>
                        </Col>
                        <Col sm={12}>
                            <Form.Item name={'calendarHighlightHolidays'} label={'Zvýraznění svátků'}
                                       valuePropName={"checked"}>
                                <Switch/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <Typography.Text strong className={'mb-2 d-block'}>Filtr</Typography.Text>

                {view.contentTypes.map(uuid =>
                    <div>
                        <Divider type={'horizontal'}>{findContentTypeByUuid(uuid).label}</Divider>
                        <ViewCalendarFilterSettings contentTypeUuid={uuid}
                                                    filters={settings?.calendarFilters?.[uuid]} {...this.props}/>
                    </div>
                )}
            </>
        );
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    return {
        findContentTypeByUuid: (uuid: string) => selectors.contentTypes.findOneBy(state, 'uuid', uuid)
    }
}

export default connect(mapStateToProps)(ViewCalendarSettings)