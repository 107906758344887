import React from "react";
import {CloseOutlined, CopyOutlined, EditOutlined, PlusOutlined} from "@ant-design/icons";
import {Modal, Row, Table, Tooltip} from "antd";
import {IContentTypeStepProps} from "./ContentTypeConfiguration";
import {ColumnProps} from "antd/es/table";
import ICard from "../../../../model/interface/dataStorage/ICard";
import CardModal from "./card/CardModal";
import RoutePicker from "../../../shared/pickers/RoutePicker";
import CardsService from "../../../../model/service/dataStorage/CardsService";
import Hotkey from "../../../shared/hotkey/Hotkey";
import Button from "../../../shared/button/Button";

interface IState {
    showModal: boolean,
    card?: ICard
}

class ContentTypeCards extends React.Component<IContentTypeStepProps, IState> {

    constructor(props: IContentTypeStepProps) {
        super(props);
        this.state = {
            showModal: false
        }
    }

    showModal = (card?: ICard) => {
        this.setState(state => ({showModal: !state.showModal, card}))
    }

    onFinish = (card?: ICard, changedCards: ICard[] = []) => {
        if (card) {
            let cards = [...this.props.resource.cards]
            const index = cards.findIndex(value => value.uuid === card.uuid)
            if (index > -1) {
                cards[index] = card
            } else {
                cards.push(card)
            }
            ContentTypeCards.updateWithChangedCards(changedCards, cards);
            this.props.onValuesChange({cards})
        }
        return new Promise<void>(resolve => this.setState({showModal: false, card: undefined}, resolve))
    }

    static updateWithChangedCards(changedCards: ICard[], cards: ICard[]) {
        changedCards.forEach(c => {
            const i = cards.findIndex(item => item.uuid === c.uuid)
            if (i > -1) {
                cards[i] = {...cards[i], ...c}
            }
        })
        return cards
    }

    onRemove(card: ICard) {
        let cards = [...this.props.resource.cards].filter(value => value.uuid !== card.uuid)
        this.props.onValuesChange({cards})
    }

    onClone(card: ICard) {
        const {resource, onValuesChange} = this.props
        Modal.confirm({
            title: 'Opravdu chcete vytvořit kopii této karty?',
            onOk: () => {
                const clonedCard = CardsService.clone(card, Math.max(...resource.cards.map(c => c.routePriority || 0)))
                onValuesChange({cards: [...resource.cards, clonedCard]})
                this.showModal(clonedCard)
            }
        })
    }

    render() {
        const presenterColumns: ColumnProps<ICard>[] = [
            {
                title: 'ID',
                dataIndex: 'id'
            },
            {
                title: 'Název',
                dataIndex: 'name'
            },
            {
                title: 'Cesta',
                dataIndex: 'route',
                render: (_, card) => <RoutePicker canAdd={false} disabled={true} value={card.route}/>
            },
            {
                title: <Row justify={"end"}>
                    <Hotkey help={"Přidat kartu"} keys={["Alt", "p"]} trigger={() => this.showModal()}>
                        <Button type={"success"} onClick={() => this.showModal()} icon={<PlusOutlined/>}>
                            <u className={'pl-2'}>P</u>řidat
                        </Button>
                    </Hotkey>
                </Row>,
                key: 'actions',
                dataIndex: 'actions',
                render: (_, card) => (
                    <div className="text-right d-flex justify-content-end">
                        <Tooltip title={"Upravit"}>
                            <Button onClick={() => this.showModal(card)} type="link" className="mr-2"
                                    icon={<EditOutlined/>} size="small"/>
                        </Tooltip>
                        <Tooltip title={"Vytvořit kopii"}>
                            <Button onClick={() => this.onClone(card)} type="link" className="mr-2"
                                    icon={<CopyOutlined/>} size="small"/>
                        </Tooltip>
                        <Tooltip title={"Odstranit"}>
                            <Button onClick={() => this.onRemove(card)} danger type="link" className="mr-2"
                                    icon={<CloseOutlined/>} size="small"/>
                        </Tooltip>
                    </div>
                )
            }
        ]
        const {resource, history, match} = this.props
        const {showModal, card} = this.state
        const {cards} = resource
        return (
            <>
                <Table
                    pagination={{pageSize: 10, showSizeChanger: true}}
                    columns={presenterColumns}
                    dataSource={cards}
                    rowKey='id'
                />
                {showModal && <CardModal contentType={resource} history={history} match={match} onFinish={this.onFinish}
                                         card={card}/>}
            </>
        )
    }
}

export default ContentTypeCards