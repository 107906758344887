import React from "react";
import {Card} from "antd";
import {motion} from "framer-motion";
import {SettingOutlined} from "@ant-design/icons";
import IBaseProps from "../../../../model/interface/IBaseProps";
import ViewSettings from "../ViewSettings";
import {VIEW_TYPE_PIVOT_TABLE} from "../../../../model/service/dataStorage/ViewTypesService";
import IViewSettingsStructure from "../../../../model/interface/dataStorage/view/settings/IViewSettingsStructure";
import IViewUnit from "../../../../model/interface/dataStorage/IViewUnit";


interface IProps extends IBaseProps {
    open: boolean | undefined,
    viewUnit: IViewUnit,
    settings?: IViewSettingsStructure
    onChange: (settings: IViewSettingsStructure | null) => Promise<void>,
}
export class Configuration extends React.Component<IProps> {
    render() {
        const {open, viewUnit, match, history, onChange, settings} = this.props
        return <motion.div className={"overflow-hidden"}
                           initial={{
                               width: open ? "auto" : "0",
                           }}
                           transition={{duration: 0.3}}
                           animate={{
                               width: open ? "auto" : "0",
                           }}
        >
            <Card title={<SettingOutlined/>}>
                <ViewSettings view={viewUnit} settings={settings} modal={false} viewType={VIEW_TYPE_PIVOT_TABLE}
                              visible={true} onChange={onChange} match={match} history={history}/>
            </Card>
        </motion.div>
    }
}

export default Configuration

