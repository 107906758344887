import RestService from "model/service/dataStorage/RestService";
import INote from "../../../interface/company/workload/INote";
import IRestServiceCollectionResponse from "../../../interface/api/IRestServiceCollectionResponse";
import IRestServiceOptions from "../../../interface/api/IRestServiceOptions";

interface IRestFundsServiceCollectionResponse extends IRestServiceCollectionResponse {
    results: Array<INote>
}

interface IRestNotesService {
    collectionList(options?: IRestServiceOptions): Promise<IRestFundsServiceCollectionResponse>

    collectionCreate(data: any): Promise<INote>

    resourceRetrieve(id: number | string): Promise<INote>

    resourceUpdate(id: number | string, data: any): Promise<INote>

    resourceDelete(id: number | string): Promise<void>
}

const COLLECTION = 'company/workload/notes'

const NotesService: IRestNotesService = {
    collectionList: function (options?) {
        return RestService.collectionList(COLLECTION, options as unknown as IRestServiceOptions) as Promise<IRestFundsServiceCollectionResponse>
    },
    collectionCreate: function (data) {
        return RestService.collectionCreate(COLLECTION, data) as Promise<INote>
    },
    resourceRetrieve: function (id) {
        return RestService.resourceRetrieve(COLLECTION, id) as Promise<INote>
    },
    resourceDelete: function (id) {
        return RestService.resourceDelete(COLLECTION, id)
    },
    resourceUpdate: function (id, data) {
        return RestService.resourceUpdate(COLLECTION, id, data) as Promise<INote>
    }
}

export default NotesService