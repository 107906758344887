import React from "react"
import _ from "underscore"
import {Button, Col, Collapse, Divider, Row, Switch, Typography} from "antd";
import Text from "antd/es/typography/Text";
import IContentType from "model/interface/dataStorage/IContentType";
import IField from "model/interface/dataStorage/IField";
import {connect, RootStateOrAny} from "react-redux";
import selectors from "../../../../../redux/selectors";
import IRepositoryService from "../../../../../model/interface/IRepositoryService";
import Utils from "../../../../../utils";
import {IViewStepProps} from "./ViewUnitForm";
import IFieldType from "../../../../../model/interface/dataStorage/IFieldType";
import ICompositeFieldType from "../../../../../model/interface/dataStorage/field/ICompositeFieldType";
import ViewItemForm from "./item/ViewItemForm";
import IViewItem from "../../../../../model/interface/dataStorage/view/IViewItem";
import FieldsService from "../../../../../model/service/dataStorage/FieldsService";

interface IState {
    openItems: string[]
}

interface IProps extends IViewStepProps {
    findOneByFullClassName: (className: string) => IRepositoryService,
    findContentTypeBy: (property: string, value: any) => IContentType,
    fieldTypes: IFieldType[],
    compositeFieldTypes: ICompositeFieldType[]
}

class ViewUnitFields extends React.Component<IProps, IState> {

    constructor(props: IProps, context: any) {
        super(props, context);
        this.state = {
            openItems: []
        }
    }

    onChangeField(contentType: IContentType, field: IField): void {
        let {items} = this.props.resource
        let index: number = items.findIndex(itemIteratee => itemIteratee.field === field.uuid)

        if (index >= 0) {
            items.splice(index, 1)
            items.forEach((item, index) => item.weight = index + 1)
        } else {
            items.push({
                id: 0,
                field: field.uuid,
                sortable: false, // TODO field enabled sortable
                filterable: false, // TODO field enabled filterable, filter type
                enabled: true,
                weight: items.length + 1
            })
            this.setOpenItems(field.uuid)
        }
        this.props.onChange({items})
    }

    setOpenItems = (item: string | string[]) => {
        if (Array.isArray(item)) {
            return this.setState({openItems: item})
        }
        this.setState(state => ({
            openItems: state.openItems.includes(item) ?
                state.openItems : [...state.openItems, item]
        }))
    }

    onChange(field: IField, values: IViewItem): void {
        let {items} = this.props.resource
        const index = Utils.findIndex(items, {field: field.uuid})
        if (index < 0) {
            throw new Error('Index not found')
        }
        items[index] = values
        this.props.onChange({items})
    }

    render() {
        const {openItems} = this.state
        const {resource, fieldTypes, compositeFieldTypes} = this.props
        const contentTypes = _.filter(this.props.contentTypes, contentType => resource.contentTypes.indexOf(contentType.uuid) >= 0)
        const items = resource.items

        return (
            <>
                {contentTypes.map(contentType => (
                        <div key={contentType.id}>
                            <Typography.Title level={4}>{contentType.label}</Typography.Title>
                            {FieldsService.sort(contentType.fields)
                                .filter(field => field.mode !== "relation" || field.loadInCollection)
                                .map(field => {
                                    const item = items.find(item => item.field === field.uuid)
                                    const fieldType = fieldTypes.find(fieldType => fieldType.value === field.type) || compositeFieldTypes.find(cFT => cFT.value === field.type)
                                    return (
                                        <Collapse expandIconPosition={'right'} onChange={this.setOpenItems}
                                                  activeKey={openItems.includes(field.uuid) && item ? field.uuid : undefined}>
                                            <Collapse.Panel
                                                collapsible={item ? undefined : 'disabled'}
                                                className={' mb-2'} header={
                                                <Row className={'font-size-base'}>
                                                    <Col>
                                                        <Switch
                                                            checked={!!item}
                                                            onChange={() => this.onChangeField(contentType, field)}
                                                            className={"mr-3"}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Text strong>{field.label || field.name}</Text>
                                                    </Col>
                                                    <Col className={'font-weight-normal pl-2'}>
                                                        [{field.name}
                                                        <Divider type={"vertical"}/>
                                                        {field.mode}
                                                        <Divider type={"vertical"}/>
                                                        {fieldType ? fieldType.label : field.type}]
                                                    </Col>
                                                </Row>
                                            } key={field.uuid}>
                                                {item &&
                                                    <ViewItemForm {...this.props} field={field} contentType={contentType}
                                                                  item={item} onChange={values => this.onChange(field, values)}/>}
                                            </Collapse.Panel>
                                        </Collapse>
                                    )
                                })}
                        </div>
                    )
                )}
                <Button type={"primary"} onClick={() => this.props.setStep()}>Následující krok</Button>
                <Button onClick={() => this.props.setStep(true)}>Předchozí krok</Button>
            </>
        )
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    return {
        fieldTypes: state.setup.fieldTypes,
        compositeFieldTypes: state.setup.compositeFieldTypes,
        findOneByFullClassName: (className: string) => selectors.services.findOneByFullClassName(state, className),
        findContentTypeBy: (property: string, value: any) => selectors.contentTypes.findOneBy(state, property, value),
    }
}

export default connect(mapStateToProps)(ViewUnitFields)