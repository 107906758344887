import IViewAction from "../../interface/dataStorage/view/IViewAction";
import IViewUnit from "../../interface/dataStorage/IViewUnit";
import IViewPermissions from "../../interface/dataStorage/view/IViewPermissions";
import _ from "underscore";
import IContentType from "../../interface/dataStorage/IContentType";

interface IRestViewUnitsService {
    getAllowedSchemeActions: (viewUnit: IViewUnit, permissions: IViewPermissions, contentType: IContentType) => IViewAction[]
    getAllowedCollectionActions: (viewUnit: IViewUnit, permissions: IViewPermissions, contentType: IContentType) => IViewAction[]
    getAllowedActions: (actions: IViewAction[], permissions: IViewPermissions, contentType: IContentType) => IViewAction[]
}

const ViewUnitsService: IRestViewUnitsService = {
    getAllowedSchemeActions: (viewUnit, permissions, contentType) => {
        return ViewUnitsService.getAllowedActions(viewUnit.schemaActions, permissions, contentType)
    },
    getAllowedCollectionActions: (viewUnit, permissions, contentType) => {
        return ViewUnitsService.getAllowedActions(viewUnit.collectionActions, permissions, contentType)
    },
    getAllowedActions: (actions, permissions, contentType) => {
        return actions.filter(collectionAction => {
            const action = _.findWhere(contentType.actions, {uuid: collectionAction.action})
            return action && permissions[contentType.fullClassName] && permissions[contentType.fullClassName][action.name]
        })
    }
}

export default ViewUnitsService