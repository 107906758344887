import React, {Component, RefObject} from 'react'
import {Card as CardElement, FormInstance, Skeleton, Spin, Typography} from 'antd';
import IAction from "model/interface/dataStorage/IAction";
import {connect, RootStateOrAny} from "react-redux";
import selectors from "../../../redux/selectors";
import IContentType from "../../../model/interface/dataStorage/IContentType";
import IRestResource from "../../../model/interface/api/IRestResource";
import IForm from "../../../model/interface/form/IForm";
import Form from "../form/Form";
import IRepositoryService from "../../../model/interface/IRepositoryService";
import Action from "./Action";
import DataStorageHelper from "../../../utils/DataStorageHelper";
import {IRoutesRouterDisplay} from "../router/RoutesRouter";
import {IActionResult} from "../../../model/service/dataStorage/ActionsService";
import {match, RouteComponentProps, withRouter} from "react-router-dom";
import {History} from "history";
import FormsService from "../../../model/service/dataStorage/FormsService";
import settingsService from "../../../model/service/SettingsService";

interface IProps extends RouteComponentProps, IRoutesRouterDisplay {
    action: IAction,
    findServiceByContentType: (contentType: IContentType) => IRepositoryService,
    findContentType: (uuid: string) => IContentType,
    extractRouteParametersFromUrl: (url: string) => null | { id: number, parameters: { [name: string]: any } },
    findForm: (value: any) => IForm
    onActionFinish?: (result?: IActionResult) => void
    isModal?: boolean
    match: match,
    history: History<{result?: IActionResult}>
    result?: IActionResult
}

interface IState {
    resource?: IRestResource,
    loadingResource: boolean,
    formRef: RefObject<FormInstance>
}

export class ActionForm extends Component <IProps, IState> {

    constructor(props: IProps, context: any) {
        super(props, context);
        this.state = {
            loadingResource: false,
            formRef: React.createRef()
        }
    }

    getId(): number | null {
        const {findContentType, extractRouteParametersFromUrl, routeParams} = this.props
        const contentType = findContentType(this.props.action.contentType)
        const routeInfo = routeParams || extractRouteParametersFromUrl(window.location.href)
        return routeInfo?.parameters.hasOwnProperty(contentType.name) ? routeInfo.parameters[contentType.name] : null
    }

    load = () => {
        const id = this.getId()
        const {action, extractRouteParametersFromUrl, history, result} = this.props

        const passedResult = result || history.location.state?.result
        if (passedResult){
            return  this.setState({resource: passedResult.resources[0]})
        }

        this.setState(_state => ({loadingResource: true}))

        /** For resource pass, we do not need whole object for action execute */
        Action.doAction(this.props.action, id ? [{id}] as IRestResource[] : null, history, {
            route: extractRouteParametersFromUrl(history.location.pathname),
            // retrieve data from before redirect... don`t know how else to do this...???
            data: id ? undefined : (Action.getLastActionFormData(action.uuid) || undefined)
        }, false).then(context => {
            const {resources} = context
            this.setState({resource: resources[0], loadingResource: false})
        }).catch(() => this.setState({loadingResource: true}))
    }

    onValuesChange = (/*values: IRestResource*/) => {
        if (this.getForm()?.uuid !== this.getForm({
            ...this.state.resource, ...this.state.formRef.current?.getFieldsValue()
        })?.uuid) {
            this.setState(state => ({
                    resource: {...state.resource, ...this.state.formRef.current?.getFieldsValue()}
                }),
                this.state.formRef.current?.resetFields)
        }
    }

    onActionStart = () => {
        return new Promise<void>(resolve => this.setState({loadingResource: true}, resolve))
    }

    onActionEnd = (result?: IActionResult) => {
        this.setState({
            loadingResource: false,
            resource: result?.resources[0]
        })
        return this.props.onActionFinish?.(result) || Promise.resolve()
    }

    onSubmit = (values: any) => {
        const {onActionFinish} = this.props
        this.submit(values).then(context => {
            const {redirect} = context
            this.setState({loadingResource: true})
            if (redirect) {
                this.props.history.push(redirect)
            } else if (onActionFinish) {
                onActionFinish(context)
            } else {
                this.load();
            }
        }).catch(() => {
            this.setState({loadingResource: false})
        })
    }

    submit(values: any) {
        const {resource} = this.state
        if (!resource) {
            throw new Error('No resource found')
        }
        const formValues: any = DataStorageHelper.buildFormData({
            ...resource,
            ...values,
            uuid: resource.uuid
        }, this.getContentType())
        this.setState({loadingResource: true})
        return Action.doAction(this.props.action, [resource], this.props.history, {
            data: formValues,
            submit: values["submit"] || "submit",
            route: this.props.extractRouteParametersFromUrl(this.props.history.location.pathname)
        })
    }

    componentDidMount() {
        this.load()
    }

    getForm(customResource?: IRestResource): IForm | undefined | null {
        const {action, findForm, findContentType} = this.props
        const {resource} = this.state
        if (!action.forms.length) {
            throw new Error('Action:' + action.id + ' form is missing')
        }
        return resource && FormsService
            .findSuitable(action.forms.map(f => findForm(f)), customResource || resource, findContentType)
    }

    getContentType(): IContentType {
        const {action} = this.props
        if (!action.contentType) {
            throw new Error('Action:' + action.id + ' content type is missing')
        }
        return this.props.findContentType(action.contentType)
    }

    getService(): IRepositoryService {
        return this.props.findServiceByContentType(this.getContentType())
    }

    render() {
        const {resource, loadingResource, formRef} = this.state
        const {isModal, action} = this.props
        const form = this.getForm()

        const Container = isModal ? 'div' : CardElement

        return (
            <>
                {!isModal && (loadingResource ? (
                    <Skeleton.Button className={'mb-2'} active={true} style={{width: 200}} size={"small"}/>
                ) : (
                    <Typography.Title level={1} style={{color: settingsService.getThemeColor(), marginBottom: 10}}>
                        {action.label}
                    </Typography.Title>
                ))}
                <Container>
                    <Skeleton loading={loadingResource && !resource}>
                        <Spin spinning={loadingResource}>
                            {resource && (form ?
                                <Form onActionEnd={this.onActionEnd} onActionStart={this.onActionStart} {...this.props}
                                      form={form} onSubmit={this.onSubmit} formRef={formRef}
                                      values={resource}
                                      onValuesChange={this.onValuesChange}/>
                                : 'Chyba konfigurace')}
                        </Spin>
                    </Skeleton>
                </Container>
            </>

        )
    }
}

const mapStateToProps = (store: RootStateOrAny) => {
    return {
        findServiceByContentType: (contentType: IContentType) => selectors.services.findOneByContentType(store, contentType),
        findContentType: (uuid: string) => selectors.contentTypes.findOneBy(store, 'uuid', uuid),
        findForm: (uuid: string) => selectors.forms.findOneBy(store, 'uuid', uuid),
        extractRouteParametersFromUrl: (url: string) => selectors.routes.extractRouteParametersFromUrl(store, url),
    }
}

export default connect(mapStateToProps)(withRouter(ActionForm))