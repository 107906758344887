import React, {RefObject} from "react"
import _ from "underscore"
import {Button, FormInstance} from "antd";
import {IViewSetStepProps} from "./ViewConfiguration";
import FilterTreeBuilder from "../../view/settings/customFilters/FilterTreeBuilder";
import IRestServiceFilters from "../../../../model/interface/api/IRestServiceFilters";


export const getNestedFieldOptions = (field: string) => ['options', field]

interface IStateViewProperties {
    formRef: RefObject<FormInstance>,
    filters: IRestServiceFilters
}

interface IPropsViewPropertiesStep extends IViewSetStepProps {

}

class ViewFilters extends React.Component<IPropsViewPropertiesStep, IStateViewProperties> {
    constructor(props: IPropsViewPropertiesStep) {
        super(props);
        this.state = {
            formRef: React.createRef(),
            filters: _.extend(props.resource.filters, {})
        }
    }

    setValue = (filters: IRestServiceFilters) => {
        this.setState({filters: filters})
        this.setState({filters: filters}, () => this.props.onChange({filters}))
    }

    confirm(back?: boolean) {
        this.props.onChange({filters: this.state.filters})
        this.props.setStep(back)
    }

    render() {
        const {resource, match, history, contentTypes} = this.props
        const {filters} = this.state

        const contentTypeFullClassName = contentTypes.find(ct => ct.uuid === resource.contentType)?.fullClassName

        return (
            <>
                <div className={'mb-3'}>
                    {contentTypeFullClassName &&
                        <FilterTreeBuilder value={filters} match={match} history={history} onChange={this.setValue}
                                           contentTypeFullClassName={contentTypeFullClassName} prefix={'global-filters'}/>}
                </div>

                <Button type={"primary"}
                        onClick={() => this.confirm()}>
                    Následující krok
                </Button>
                <Button onClick={() => this.confirm(true)}>Předchozí krok</Button>
            </>
        )
    }
}

export default ViewFilters