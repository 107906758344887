import React from "react";
import {Layout} from 'antd';
import {connect} from 'react-redux';
import {SIDE_NAV_DARK, SIDE_NAV_WIDTH} from 'model/constants/ThemeConstant';
import MenuContent from './MenuContent'
import IBaseProps from "../../model/interface/IBaseProps";
import ISettings from "../../model/interface/ui/ISettings";
import SettingsService from "../../model/service/SettingsService";
import {IAppState} from "../../redux/store";

const {Sider} = Layout;

interface IProps extends IBaseProps {
    routeInfo: any
    navCollapsed: boolean
    sideNavTheme: string,
    settings: ISettings,
    headerHeight?: number
}

interface IState {
}

class SideNav extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props)
        this.state = {}
    }

    render() {
        const {sideNavTheme, navCollapsed, history, match, headerHeight} = this.props
        return (
            <Sider
                style={{top: (headerHeight || 0)  + 'px',
                    height: `calc(100vh - ${headerHeight || 0}px)` ,
                    background: SettingsService.getValue('ui-theme', 'sideMenu')}}
                className={`side-nav ${sideNavTheme === SIDE_NAV_DARK ? 'side-nav-dark' : ''}`}
                width={SIDE_NAV_WIDTH}
                collapsed={navCollapsed}
            >
                <MenuContent
                    isMobile={false}
                    _history={history}
                    _match={match}
                    hideGroupTitle={false}
                    navCollapsed={navCollapsed}
                />
            </Sider>
        )
    }
}

const mapStateToProps = (state: IAppState) => {
    const {navCollapsed, sideNavTheme} = state.theme;
    return {navCollapsed, sideNavTheme}
};

export default connect(mapStateToProps)(SideNav);
