import React, {RefObject} from "react";
import {Button, Form, FormInstance, Input, Modal} from "antd";
import ICard from "../../../../../model/interface/dataStorage/ICard";
import IContentTypeRoute from "../../../../../model/interface/dataStorage/IContentTypeRoute";
import Utils from "../../../../../utils";
import RoutePicker from "../../../../shared/pickers/RoutePicker";
import LocaleText from "../../../settings/dictionary/LocaleText";

interface IState {
    saving: boolean
    contentTypeRoute: IContentTypeRoute
    formRef: RefObject<FormInstance>
}

interface IProps {
    onFinish?: (card?: IContentTypeRoute) => void
    contentTypeRoute?: IContentTypeRoute
}

export interface ICardStepProps {
    onChange: (card: ICard, stepBack?: boolean) => void
    card: ICard
}

class ContentTypeRouteModal extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            saving: false,
            contentTypeRoute: {
                ...props.contentTypeRoute ? props.contentTypeRoute : {
                    id: null,
                    name: '',
                    route: undefined,
                    uuid: Utils.uuid()
                },
            } as any | IContentTypeRoute,
            formRef: React.createRef()
        }
    }

    onCancel = () => {
        this.updateParent()
    }

    onFinish = () => {
        this.state.formRef.current?.validateFields().then(values => {
            this.updateParent({...this.state.contentTypeRoute, ...values})
        })
    }

    updateParent(contentTypeRoute?: IContentTypeRoute) {
        this.props.onFinish && this.props.onFinish(contentTypeRoute)
    }

    render() {
        const {saving, contentTypeRoute} = this.state
        const {formRef} = this.state

        return (
            <Modal
                title={contentTypeRoute.id ? `Uprava cesty [${contentTypeRoute.name}]` : 'Nová cesta'}
                visible={true}
                bodyStyle={{maxHeight: '60vh', overflow: "auto"}}
                onCancel={this.onCancel}
                width={800}
                destroyOnClose={true}
                footer={[
                    <Button key="back" onClick={this.onCancel}>
                        <LocaleText code={'general.cancel'} fallback={"Zružit"}/>
                    </Button>,
                    <Button key="submit" type="primary" loading={saving} onClick={() => this.onFinish()}>
                        <LocaleText code={'general.save'} fallback={"Uložit"}/>
                    </Button>
                ]}
            >
                <Form initialValues={contentTypeRoute ? contentTypeRoute : {}} ref={formRef}>
                    <Form.Item
                        label={"Název"}
                        name={"name"}
                        rules={[{required: true}]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label={"Cesta"}
                        name={"route"}
                        rules={[{required: true}]}
                    >
                        <RoutePicker/>
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

export default ContentTypeRouteModal