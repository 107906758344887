import React from "react";
import {Form, Input, Slider, Switch} from "antd";
import ICommentOptions from "../../../../../model/interface/widget/option/ICommentOptions";
import FormFieldWYSIWYGEditor from "../../form/FormElement/formField/editor/FormFieldWYSIWYGEditor";

interface IProps {
    options: ICommentOptions
    editableLabel?: boolean
}

class CommentEditor extends React.Component<IProps, ICommentOptions> {

    static defaultProps = {
        editableLabel: false
    }

    getTipFormatter = (value?: number) => {
        return value && value > 50 ?
            <strong className={'text-danger'}>{`${value} (časově náročné)`}</strong> : value;
    }

    render() {
        const {editableLabel, options} = this.props

        return (
            <div>
                {editableLabel && (
                    <Form.Item name={'label'} label={'Titulek'}>
                        <Input/>
                    </Form.Item>
                )}
                <Form.Item name={'replyAble'} label={'Možnost odpovědět'} valuePropName={'checked'}>
                    <Switch/>
                </Form.Item>
                <Form.Item name={'editable'} label={'Možnost editace komentáře'} valuePropName={'checked'}>
                    <Switch/>
                </Form.Item>
                <Form.Item name={'attachments'} label={'Přílohy'} valuePropName={'checked'}>
                    <Switch/>
                </Form.Item>
                <Form.Item name={'readonly'} label={'Pouze ke čtení'} valuePropName={'checked'}>
                    <Switch/>
                </Form.Item>
                <Form.Item name={'wysiwyg'} label={'Wysiwyg'} valuePropName={'checked'}>
                    <Switch/>
                </Form.Item>
                {options.wysiwyg && (
                    <FormFieldWYSIWYGEditor options={options}/>
                )}
                <Form.Item name={'limit'} label={'Limit položek na jedno načtení'} initialValue={5}>
                    <Slider tipFormatter={this.getTipFormatter} min={3} max={100}
                            marks={{
                                3: {label: 3},
                                50: {style: {color: '#f50'}, label: <strong className={'text-danger'}>50+</strong>},
                            }}/>
                </Form.Item>
            </div>
        )
    }
}

export default CommentEditor