import React from "react";
import {Button, Row} from "antd";
import IComment from "../../../model/interface/comment/IComment";
import ThreadsService from "../../../model/service/comment/ThreadsService";
import TextArea from "antd/es/input/TextArea";
import IFile from "../../../model/interface/file/IFile";
import {SendOutlined} from "@ant-design/icons";
import CommentsService from "../../../model/service/comment/CommentsService";
import IUser from "../../../model/interface/security/IUser";
import {IWYSIWYGEditorOptions} from "../configuration/form/FormElement/formField/editor/FormFieldWYSIWYGEditor";
import Utils from "../../../utils";
import FilePicker from "../../shared/pickers/file/FilePicker";
import Wysiwyg from "../../shared/input/Wysiwyg";

interface IProps extends IWYSIWYGEditorOptions {
    onSave: (thread: number|string, comment: IComment, newThread?: boolean) => void
    wysiwyg?: boolean,
    isAttachments?: boolean,
    comment?: IComment
    thread?: number | string,
    parent?: number,
    user: IUser
}

interface IState {
    saving: boolean
    attachments: IFile[]
    valid: boolean,
    text: string
}

class CommentForm extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            saving: false,
            attachments: props.comment ? props.comment.attachments : [],
            valid: false,
            text: props.comment ? props.comment.text : ''
        }
    }

    componentDidMount() {
        this.state.text && this.checkValidity()
    }

    updateText = (text: string) => {
        this.setState({text})
        this.checkValidity()
    }

    checkValidity() {
        this.setState(state => ({valid: !!Utils.stripHtml(state.text)}))
    }

    save() {
        this.setState({saving: true})
        const {thread} = this.props
        if (thread) {
            this.saveComment(thread);
        } else {
            this.createThread();
        }
    }

    saveComment(thread: number|string) {
        const {attachments, text} = this.state
        const {parent, onSave, user, comment} = this.props
        if (comment) {
            CommentsService.resourceUpdate(comment.id, {text, attachments: attachments.map(file => file.id)})
                .then((updatedComment) => {
                    this.resetFields();
                    onSave(thread, updatedComment)
                })
        } else {
            const parentParam = parent ? {parent} : {}
            CommentsService.collectionCreate({
                text,
                attachments: attachments.map(file => file.id),
                thread, ...parentParam,
                user: user.id
            }, {depth: 5})
                .then((comment) => {
                    this.resetFields()
                    onSave(thread, comment)
                })
        }
    }

    addAttachments = (attachments?: IFile[] | IFile) => {
        this.setState({attachments: attachments as IFile[] || []})
    }

    createThread() {
        const {attachments, text} = this.state
        const {onSave, user} = this.props
        const newComment = {
            text,
            attachments: attachments.map(file => file.id),
            user: user.id
        }
        ThreadsService.collectionCreate({
            comments: [newComment]
        })
            .then(thread => {
                this.resetFields()
                thread.uuid && onSave(thread.uuid, thread.comments[0], true)
            })
    }

    resetFields() {
        this.setState({saving: false, text: '', attachments: []})
    }

    render() {
        const {saving, valid, text, attachments} = this.state
        const {wysiwyg, isAttachments, comment, thread, wysiwygPackage} = this.props

        return (
            <div>
                {wysiwyg ?
                    <Wysiwyg value={text} onChange={this.updateText} mode={wysiwygPackage}/>
                    :
                    <TextArea value={text}
                              onChange={(e) => this.updateText(e.target.value)}/>}
                <Row gutter={12} className={'p-2'} justify={"end"}>
                    {isAttachments && (
                        <Row className={'pb-2 pr-2'}>
                            <FilePicker multiple={true} onChange={this.addAttachments} value={attachments}/>
                        </Row>
                    )}
                    <Button icon={<SendOutlined/>} loading={saving} onClick={() => this.save()} disabled={!valid}>
                        Uložit
                    </Button>
                    {comment && thread && (
                        <Button className={'ml-2'} onClick={() => this.props.onSave(thread, comment)}>
                            Zrušit
                        </Button>
                    )}
                </Row>
            </div>
        );
    }
}

export default CommentForm