import IRestServiceOptions from "../../interface/api/IRestServiceOptions";
import IRestServiceCollectionResponse from "../../interface/api/IRestServiceCollectionResponse";
import IPresenter from "../../interface/dataStorage/IPresenter";
import RestService from "./RestService";

interface IRestPresentersServiceCollectionResponse extends IRestServiceCollectionResponse {
    results: Array<IPresenter>
}

interface IRestPresentersService {
    collectionList(contentType: number | string, options?: IRestServiceOptions): Promise<IRestPresentersServiceCollectionResponse>,

    collectionCreate(contentType: number | string, data: any, options?: IRestServiceOptions): Promise<IPresenter>,

    resourceRetrieve(contentType: number | string, id: number | string, options?: IRestServiceOptions): Promise<IPresenter>,

    resourceUpdate(contentType: number | string, id: number | string, data: any, options?: IRestServiceOptions): Promise<IPresenter>,

    resourceDelete(contentType: number | string, id: number | string): Promise<void>,
}

const COLLECTION = (id: number | string) => 'content-types/#id/presenters'.replace('#id', id.toString())


const PresentersService: IRestPresentersService = {
    collectionList: function (contentType, options) {
        return RestService.collectionList(COLLECTION(contentType), options as unknown as IRestServiceOptions) as Promise<IRestPresentersServiceCollectionResponse>
    },
    collectionCreate: function (contentType, data, options?: IRestServiceOptions) {
        return RestService.collectionCreate(COLLECTION(contentType), data, options) as Promise<IPresenter>
    },
    resourceRetrieve: function (contentType, id, options?: IRestServiceOptions) {
        return RestService.resourceRetrieve(COLLECTION(contentType), id, options) as Promise<IPresenter>
    },
    resourceDelete: function (contentType, id) {
        return RestService.resourceDelete(COLLECTION(contentType), id)
    },
    resourceUpdate: function (contentType, id, data, options?: IRestServiceOptions) {
        return RestService.resourceUpdate(COLLECTION(contentType), id, data, options) as Promise<IPresenter>
    }
}

export default PresentersService