import React from "react"
import {Button, Result} from "antd";
import {IViewSetStepProps} from "./ViewConfiguration";
import {ArrowLeftOutlined, SaveOutlined} from "@ant-design/icons";

interface IPropsViewSummary extends IViewSetStepProps{
    save: () => void
}

class ViewSummary extends React.Component<IPropsViewSummary> {
    render() {
        const  { save } = this.props
        return (
            <>
                <Result
                    status={"success"}
                    title={<div>
                        Pohled je připraven k uložení
                        <div className={'font-size-md'}>
                            <ArrowLeftOutlined/> Zpět na krok
                        </div>
                    </div>}
                    extra={<Button type={"primary"} icon={<SaveOutlined/>} onClick={save}>Uložit</Button>}
                />
            </>
        )
    }
}

export default ViewSummary