import React from "react";
import {Button, Modal} from "antd";
import IFile from "../../../../model/interface/file/IFile";
import FilePicker from "../../pickers/file/FilePicker";


interface IProps {
    visible: boolean
    onAdd: (file: IFile) => void
    onCancel: () => void
}

interface IState {
    file?: IFile
}

class WysiwygInlineFileAdd extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props)
        this.state = {}
    }

    onInlineFileChoose = (files?: IFile | IFile[]) => {
        const file = Array.isArray(files) ? files[0] : files
        this.setState({file: file}, this.forceUpdate)
    }

    onAdd(file: IFile){
        const {onAdd} = this.props
        onAdd(file)
        this.setState({file: undefined})
    }

    render() {
        const {onCancel, visible} = this.props
        const {file} = this.state

        return (
            <Modal destroyOnClose={true} title={'Vložit soubor'} visible={visible} onCancel={onCancel} footer={[
                <Button key="back" onClick={onCancel}>
                    Zrušit
                </Button>,
                <Button disabled={!file} key="submit" type="primary"
                        onClick={() => file && this.onAdd(file)}>
                    Vložit
                </Button>
            ]}>
                <FilePicker widget={"extended"} value={file} onChange={this.onInlineFileChoose}/>
            </Modal>
        )
    }
}

export default WysiwygInlineFileAdd