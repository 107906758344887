import React, {Component} from 'react'
import {Spin} from 'antd';
import IAction, {ActionTypes} from "model/interface/dataStorage/IAction";
import ActionForm from "../../../components/app/action/ActionForm";
import IBaseProps from "model/interface/IBaseProps";
import SourceImport from "../../../components/app/import/source-import/SourceImport";
import {IActionResult} from "../../../model/service/dataStorage/ActionsService";
import {IRoutesRouterDisplay} from "../../../components/app/router/RoutesRouter";
import RevisionHistory from "../../../components/app/content-type/revision-history/RevisionHistory";

interface IProps extends IBaseProps, IRoutesRouterDisplay{
    action: IAction,
    isModal?: boolean
    onActionFinish?: (result?: IActionResult) => void
    result?: IActionResult
}

interface IState {
    loadingAction: boolean,
}

export class Action extends Component <IProps, IState> {
    state: IState = {
        loadingAction: false
    }

    render() {
        const { loadingAction} = this.state
        const {action, isModal} = this.props
        let detail
        if(action) {
            switch(action.type) {
                case(ActionTypes.FORM):
                case(ActionTypes.SCRIPT_FORM):
                    detail = <ActionForm {...this.props} action={action}/>
                    break;
                case(ActionTypes.IMPORT):
                    if (!action.importSource){
                        throw new Error('Import action is missing import source!')
                    }
                    detail = <SourceImport standAlone={!isModal} parentSource={action.importSource} {...this.props}/>
                    break;
                case(ActionTypes.REVISION_HISTORY):
                    detail = <RevisionHistory standAlone={!isModal} {...this.props}/>
                    break;
            }
        }
        return (
            <>
                <Spin spinning={loadingAction}>
                    { detail }
                </Spin>
            </>
        )
    }
}

export default Action