import {Component} from "react";
import IPresenterOptions from "../../../model/interface/dataStorage/IPresenterOptions";

interface IProps {
    value: JSX.Element,
    unit: string,
    options: IPresenterOptions
}

interface IState {
}

export class Price extends Component <IProps, IState> {
    state: IState = {}

    render() {
        const {value, unit, options} = this.props
        let rawValue = null

        try {
            rawValue = value.props.children
        } catch (e) {

        }
        return rawValue ? (
            <>
                {parseFloat(parseFloat(rawValue).toFixed( options.numberPrecision || 0)).toLocaleString()} {unit}
            </>
        ) : (
            <span className={"text-muted"}>
                není zadáno
            </span>
        )
    }
}

export default Price