import {Col, Divider, Row, Typography} from "antd";
import Title from "antd/es/typography/Title";
import {IWidgetList, IWidgetListItem} from "./WidgetList";
import React from "react";
import IWidget from "../../../../model/interface/widget/IWidget";

export interface GroupItem {
    label: string
    widgets: IWidgetListItem[]
}

interface IState {

}

interface IProps {
    onSelect: (type: string, ...args: any[]) => void
    list: IWidgetList<IWidget>
    customGroup?: string
    node: IWidget
}

class WidgetGallery<P> extends React.Component<P & IProps, IState> {

    render() {
        const {customGroup, list, node} = this.props

        const type = list.getItemByType(node.type)
        if (type.add && Array.isArray(type.add)) {
            return list.getItemsByTypes(type.add).map((widget, index) =>
                this.getNodeOptionTag(widget, index, () => this.onSelectItem(widget.type)))
        }

        return <div className={"d-block dir-ltr"}>
            {Object.entries(list.getGrouped())
                .filter(value => customGroup ? value[0] === customGroup : true)
                .map(group =>
                    <div className={'p-1'} key={group[0]}>
                        <Title level={4}>{group[1].label}</Title>
                        <Row gutter={[10, 10]}>{this.getGroup(group)}
                        </Row>
                    </div>
                )}
        </div>
    }

    getGroup(group: [string, GroupItem]) {
        return group[1].widgets.map((widget, index) =>
            this.getNodeOptionTag(widget, index, () => this.onSelectItem(widget.type)))
    }

    onSelectItem(type: string, ...args: any[]) {
        const {onSelect} = this.props
        onSelect(type, ...args)
    }

    getNodeOptionTag(
        widget: IWidgetListItem,
        key: number | string,
        onSelect?: () => void,
        disabled: boolean = false
    ) {
        return (
            <Col key={key + widget.type}>
                <Row align={"middle"}
                     className={'border p-1 shadow-sm px-2 ' + (disabled ? 'text-muted' : 'cursor-pointer')}
                     onClick={() => !disabled && (onSelect ? onSelect() : this.onSelectItem(widget.type))}>
                    {widget.icon}
                    <Divider type={"vertical"}/>
                    <Typography.Text strong>{widget.label}</Typography.Text>
                </Row>
            </Col>
        );
    }
}

export default WidgetGallery