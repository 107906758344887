import React from "react";
import {Button, notification, Tooltip} from "antd";
import {CloseOutlined, DownloadOutlined} from "@ant-design/icons";
import IFile from "../../../../model/interface/file/IFile";
import _ from "underscore";
import FilesService from "../../../../model/service/file/FilesService";
import FileEdit from "./FileEdit";
import Utils from "../../../../utils";

export interface IPropsFileActions {
    file: IFile
    onDelete: (uuid: string) => void
    onEdit: (uuid: string) => void
}

export interface IStateFileActions {
    file: IFile
    loadingRemove: boolean,
    loadingDownload: boolean
}

class FileActions extends React.Component<IPropsFileActions, IStateFileActions> {

    constructor(props: IPropsFileActions) {
        super(props);
        this.state = {
            file: _.extend(props.file, {}) as IFile,
            loadingRemove: false,
            loadingDownload: false
        } as IStateFileActions
    }

    downloadFile = (file: IFile) => {
        this.setState({loadingDownload: true})
        FileActions.downloadMultiple([file]).then(() => this.setState({loadingDownload: false}))
    }

    public static download = (file: IFile) => {
        return FileActions.downloadMultiple([file])
    }

    public static downloadMultiple = (files: IFile[]) => {
        return new Promise<void>(resolve => {
            FilesService.resourceDownload(files.map(file => file.uuid))
                .then(({blob, fileName}) => {
                    Utils.downloadFile(blob, fileName)
                    notification.success({message: `Downloaded`})
                    resolve()
                })
        })
    }

    remove = (file: IFile) => {
        this.setState({loadingRemove: true})
        FilesService.resourceDelete(file.uuid).then(() => {
            notification.success({message: `File[ ${file.name} ] was deleted.`})
            this.props.onDelete(file.uuid)
        })
    }

    public static removeAll = (files: IFile[]) => {
        return new Promise<void>(resolve => {
            FilesService.resourceDeleteAll(files.map(file => file.uuid)).then(() => {
                notification.success({message: `Files[ ${files.map(file => file.name).join(",\n")} ] were deleted.`})
                resolve()
            })
        })
    }

    componentWillReceiveProps(props: IPropsFileActions) {
        this.setState({file: props.file})
    }

    edit = (file: IFile) => {
        this.props.onEdit(file.uuid)
    }

    render() {
        const {file, loadingRemove, loadingDownload} = this.state
        return (
            <>
                <div className="text-center d-flex justify-content-center">
                    <Tooltip title={"Stáhnout"}>
                        <Button onClick={() => this.downloadFile(file)} type="link"
                                className="mr-2 ant-btn-info" icon={<DownloadOutlined/>}
                                size="small" loading={loadingDownload}/>
                    </Tooltip>
                    <FileEdit onUpdate={this.edit} file={file}/>
                    <Tooltip title={"Odstranit"}>
                        <Button onClick={() => this.remove(file)} danger type="link"
                                className="mr-2" icon={<CloseOutlined/>}
                                size="small" loading={loadingRemove}/>
                    </Tooltip>
                </div>
            </>
        )
    }
}

export default FileActions
