import React from "react";
import {Form, Switch} from "antd";
import IReportWidgetResourceFinderByCode
    from "../../../../../../model/interface/report/IReportWidgetResourceFinderByCode";
import ContentTypePicker from "../../../../../shared/pickers/ContentTypePicker";
import {FIELD_TYPE} from "../../../../../../model/interface/dataStorage/IField";
import ActionPicker from "../../../../../shared/pickers/ActionPicker";
import {ActionTypes} from "../../../../../../model/interface/dataStorage/IAction";
import FieldPicker from "../../../../../shared/pickers/FieldPicker";
import {connect, RootStateOrAny} from "react-redux";
import selectors from "../../../../../../redux/selectors";
import IContentType from "../../../../../../model/interface/dataStorage/IContentType";

interface IProps {
    options: IReportWidgetResourceFinderByCode
    findContentType: (uuid: string) => IContentType
}

class ResourceFinderByCodeWidgetEditor extends React.Component<IProps> {

    render() {
        const {options, findContentType} = this.props
        const {contentTypeUuid} = options
        const contentType = contentTypeUuid && findContentType(contentTypeUuid)

        return (
            <div>
                <Form.Item name={'contentTypeUuid'} label={'Typ obsahu'} rules={[{required: true}]}>
                    <ContentTypePicker fieldTypes={[FIELD_TYPE.CODE]}/>
                </Form.Item>
                {contentType && (
                    <>
                        <Form.Item name={'showAction'} label={'Akce pro zobrazení'} rules={[{required: true}]}>
                            <ActionPicker contentTypeUuid={contentType.uuid} types={[ActionTypes.SHOW]}/>
                        </Form.Item>
                        <Form.Item name={'codeField'} label={'Pole kódu'} rules={[{required: true}]}>
                            <FieldPicker allowTypes={[FIELD_TYPE.CODE]} output={'uuid'}
                                         serviceClassName={contentType.fullClassName}/>
                        </Form.Item>
                    </>
                )}
                <Form.Item name={'inputSearch'} label={'Povolit pole pro vyhledávání'} valuePropName={'checked'}
                           initialValue={true}>
                    <Switch/>
                </Form.Item>
                <Form.Item name={'scanSearch'} label={'Povolit skenování'} valuePropName={'checked'}
                           initialValue={true}>
                    <Switch/>
                </Form.Item>
                <Form.Item name={'showOnResult'} label={'Automatické ukončení skenování po nalezeném kódu'}
                           valuePropName={'checked'}>
                    <Switch/>
                </Form.Item>
                <Form.Item name={'bordered'} label={'Ohraničení'} valuePropName={'checked'}>
                    <Switch/>
                </Form.Item>
            </div>
        )
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    return {
        findContentType: (uuid: string) => selectors.contentTypes.findOneBy(state, 'uuid', uuid)
    }
}

export default connect(mapStateToProps)(ResourceFinderByCodeWidgetEditor)