import {Button, Card, Divider, Form, FormInstance, Row, Select, Tag} from 'antd';
import {TweenOneGroup} from 'rc-tween-one';
import {
    CheckOutlined,
    DeleteOutlined,
    EditOutlined,
    EyeInvisibleOutlined,
    PlusOutlined,
    SaveOutlined
} from '@ant-design/icons';
import React, {RefObject} from "react";
import IFormStructureNode from "../../../../../../model/interface/form/IFormStructureNode";
import IField from "../../../../../../model/interface/dataStorage/IField";
import FormElementField from "../FormElementField";
import FormFieldType from "../formField/FormFieldType";
import {connect, RootStateOrAny} from "react-redux";
import selectors from "../../../../../../redux/selectors";
import IContentType from "../../../../../../model/interface/dataStorage/IContentType";
import Utils from "../../../../../../utils";

interface IProps {
    value?: ICondition[]
    onChange?: (conditions: ICondition[]) => void
    fields: IField[]
    fieldNodes?: IFormStructureNode[]
    findContentType: (uuid: string) => IContentType
}

interface IState {
    conditions: ICondition[],
    formRef: RefObject<FormInstance>,
    field?: IField,
    editCondition?: ICondition
    fieldNode?: IFormStructureNode
    value?: any
}

export interface ICondition {
    field: string
    value: any
}

const CUSTOM_VALUES = {
    IS_NULL: 'is_null',
    IS_NOT_NULL: 'is_not_null'
}

class ConditionEditor extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            conditions: props.value ? [...props.value] : [],
            formRef: React.createRef()
        }
    }

    static evaluateConditions(conditions: ICondition[] | undefined, getValue: (field: string) => any) {
        let passed = false;
        (conditions || []).forEach((condition: ICondition) => {
            passed = passed || condition.value === Utils.parseObjectToIdentifier(getValue(condition.field))
            if (condition.value === CUSTOM_VALUES.IS_NULL) {
                passed = !getValue(condition.field)
            }
            if (condition.value === CUSTOM_VALUES.IS_NOT_NULL) {
                passed = !!getValue(condition.field)
            }
        })
        return passed
    }

    handleClose = (removeCond: ICondition) => {
        const conditions = this.state.conditions
            .filter(condition => condition.value !== removeCond.value || condition.field !== removeCond.field)
        this.setState({conditions})
        if (this.isSameCondition(removeCond, this.state.editCondition)) {
            this.reset()
        }
        this.props.onChange && this.props.onChange(conditions)
    }

    reset() {
        this.setState({editCondition: undefined, field: undefined}, this.state.formRef.current?.resetFields)
    }

    handleEdit = (editCond?: ICondition) => {
        this.setState({
            editCondition: editCond,
            fieldNode: editCond ? this.getFieldNode(editCond.field) : undefined,
            field: editCond ? this.getField(editCond.field) : undefined,
            value: editCond?.value
        }, this.state.formRef.current?.resetFields)
    };

    confirm = () => {
        const {formRef, editCondition, value} = this.state
        formRef.current?.validateFields().then(values => {
            let {conditions, field} = this.state
            if (editCondition) {
                const index = conditions.findIndex(condition => this.isSameCondition(condition, editCondition))
                if (index > -1) {
                    conditions[index] = {...values, value: value || values.value}
                }
            } else if (conditions.findIndex(condition => JSON.stringify(condition) === JSON.stringify(values)) === -1) {
                values.value = value || (field ? FormFieldType.formatFromForm(field.type, values.value) : values.value)
                conditions = [...conditions, {...values}];
            }
            this.setState({conditions})
            this.props.onChange && this.props.onChange(conditions)
            this.reset()
        })
    }

    isSameCondition(condition?: ICondition, editCondition?: ICondition) {
        return JSON.stringify(condition) === JSON.stringify(editCondition);
    }

    fieldChange = (fieldName: string) => {
        const fieldNode = this.getFieldNode(fieldName)
        this.state.formRef.current?.setFieldsValue({value: undefined})
        this.setState({fieldNode: fieldNode, field: this.getField(fieldName)})
    }

    valueChange = (value: any) => {
        this.setState(state => ({value: state.value === value ? undefined : value}))
    }

    getFieldNode(fieldName: string) {
        return this.props.fieldNodes?.find(value => value.field?.name === fieldName)
    }

    getField(fieldName: string) {
        return this.props.fields.find(value => value.name === fieldName)
    }

    forMap = (condition: ICondition) => {
        const editing = this.isSameCondition(condition, this.state.editCondition);
        const tagElem = (
            <Tag
                className={'mb-2 cursor-pointer' + (editing ? ' bg-gray-lighter' : '')}
                key={condition.value + condition.field}>
                <Row align={"middle"} justify={"space-between"}>
                    {condition.field + ` [${condition.value}]`}
                    <div className={'ml-2'}>
                        <Button size={"small"} type={editing ? "default" : "link"} icon={
                            editing ? <EyeInvisibleOutlined/> : <EditOutlined/>}
                                onClick={() => this.handleEdit(editing ? undefined : condition)}/>
                        <Button size={"small"} type={"link"} danger icon={
                            <DeleteOutlined/>} onClick={() => this.handleClose(condition)}/>
                    </div>
                </Row>
            </Tag>
        );

        return (
            <span key={condition.value} style={{display: 'inline-block'}}>
                {tagElem}
            </span>
        );
    };

    render() {
        const {conditions, formRef, field, editCondition, fieldNode, value} = this.state;
        const {fields, findContentType} = this.props
        const tagChild = conditions.map(this.forMap);

        return (
            <Card>
                <div style={{marginBottom: 16}}>
                    <TweenOneGroup
                        enter={{
                            scale: 0.8,
                            opacity: 0,
                            type: 'from',
                            duration: 100
                        }}
                        leave={{y: -20, opacity: 0, duration: 300}}
                        appear={true}
                    >
                        {tagChild}
                    </TweenOneGroup>
                </div>
                <Form ref={formRef} initialValues={editCondition}>
                    <Form.Item name={'field'} label={'Podminene pole'} rules={[{required: true}]}>
                        <Select onChange={this.fieldChange}>
                            {fields.map(field => {
                                return (
                                    <Select.Option key={field.id} value={field.name}>
                                        {field.label} [{field.name}]
                                    </Select.Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                    {field && field.type &&
                        <div>
                            <FormElementField
                                field={field}
                                functions={{
                                    ...{
                                        getNode: () => ({field}),
                                        getContentType: () => field?.contentTypeId && findContentType(field?.contentTypeId),
                                    } as any
                                }}
                                type={'field'}
                                label={'Očekávaná hodnota'}
                                id={'condition-editor-value'}
                                options={{
                                    ...fieldNode?.options,
                                    showClear: true,
                                    disabled: [CUSTOM_VALUES.IS_NOT_NULL, CUSTOM_VALUES.IS_NOT_NULL].includes(value),
                                    required: !value
                                }}
                                preview={true}
                                customFieldName={'value'}
                            />
                            <Row justify={"space-between"} align={"middle"}>
                                <Button icon={value === CUSTOM_VALUES.IS_NULL ? <CheckOutlined/> : undefined}
                                        type={value === CUSTOM_VALUES.IS_NULL ? 'primary' : 'default'}
                                        onClick={() => this.valueChange(CUSTOM_VALUES.IS_NULL)}>
                                    Je prázdný
                                </Button>
                                <Divider type={'vertical'}/>
                                <Button icon={value === CUSTOM_VALUES.IS_NOT_NULL ? <CheckOutlined/> : undefined}
                                        type={value === CUSTOM_VALUES.IS_NOT_NULL ? 'primary' : 'default'}
                                        onClick={() => this.valueChange(CUSTOM_VALUES.IS_NOT_NULL)}>
                                    Není prázdný
                                </Button>
                            </Row>
                        </div>
                    }
                    <Button type={"dashed"} className={'mt-3'} onClick={this.confirm}
                            icon={editCondition ? <SaveOutlined/> : <PlusOutlined/>}>
                        {editCondition ? 'Upravit' : 'Add'}
                    </Button>
                </Form>
            </Card>
        );
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    return {
        findContentType: (uuid: string) => selectors.contentTypes.findOneBy(state, 'uuid', uuid),
    }
}

export default connect(mapStateToProps)(ConditionEditor)