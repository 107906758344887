import RestService from "model/service/dataStorage/RestService";
import IField from "../../../interface/dataStorage/IField";
import IRestServiceCollectionResponse from "../../../interface/api/IRestServiceCollectionResponse";
import IRepositoryService from "../../../interface/IRepositoryService";
import IRestServiceOptions from "../../../interface/api/IRestServiceOptions";
import ILike from "../../../interface/dataStorage/extension/ILike";
import IRestServiceChoiceListResponse from "../../../interface/api/IRestServiceChoiceListResponse";
import IPresenter from "../../../interface/dataStorage/IPresenter";

interface IRestLikesServiceCollectionResponse extends IRestServiceCollectionResponse {
  results: Array<ILike>
}

interface IRestLikesService extends IRepositoryService {
  collectionList(options?: IRestServiceOptions): Promise<IRestLikesServiceCollectionResponse>,
  collectionCreate(data: any): Promise<ILike>,
  resourceRetrieve(id: number|string): Promise<ILike>,
  resourceUpdate(id: number|string, data: any): Promise<ILike>,
  resourceDelete(id: number|string): Promise<void>,
  relationCreate(like: ILike | any, contentType: string, resource: string | number): Promise<ILike>,
  relationDelete(id: number|string, contentType: string, resource: string | number): Promise<void>,
}

const LikesService_COLLECTION = 'content-type-likes'
const LikesService_COLLECTION_DELETE = 'content-type-likes-delete'
const LikesService_COLLECTION_CREATE = 'content-type-likes-create'


const presenters = [
  {
    type: 'label',
    name: 'default',
    label: 'Výchozí'
  } as IPresenter,
  {
    type: 'label',
    name: 'count',
    label: 'Pouze počet'
  } as IPresenter
]

const LikesService: IRestLikesService = {
  getFields(): IField[] {
    // TODO
    return [];
  },
  getRecordClassName() {
    return 'App\\DataStorage\\Entity\\Extension\\Like'
  },
  getTitle(): string {
    return "Líbí se mi";
  },
  collectionList: function (options?) {
    return RestService.collectionList(LikesService_COLLECTION, options as unknown as IRestServiceOptions) as Promise<IRestLikesServiceCollectionResponse>
  },
  collectionCreate: function ( data) {
    return RestService.collectionCreate(LikesService_COLLECTION, data) as Promise<ILike>
  },
  resourceRetrieve: function (id) {
    return RestService.resourceRetrieve(LikesService_COLLECTION, id) as Promise<ILike>
  },
  resourceDelete: function (id) {
    return RestService.resourceDelete(LikesService_COLLECTION, id)
  },
  resourceUpdate: function (id, data) {
    return RestService.resourceUpdate(LikesService_COLLECTION, id, data) as Promise<ILike>
  },
  getStringValue(like: ILike): string {
    return 'Líbí se to: (' + like.createdAt + ')' //TODO ???
  },
  getPresenter(name: string): IPresenter | null {
    return presenters.find(presenter => presenter.name === name) || null
  },
  getDefaultPresenter(): IPresenter {
    return this.getPresenter('default') as IPresenter
  },
  getPresenterList() {
    return presenters.map(presenter => {return {value: presenter.name, label: presenter.label}})
  },
  choiceList(presenterName: string, options?: IRestServiceOptions): Promise<IRestServiceChoiceListResponse> {
    return new Promise<IRestServiceChoiceListResponse>(resolve => resolve); //TODO ???
  },
  relationCreate(like: ILike, contentType: string, resource: string | number): Promise<ILike> {
    return RestService.collectionCreate(LikesService_COLLECTION_CREATE, {...like, contentType, resource}) as Promise<ILike>
  },
  relationDelete(id: number | string, contentType: string, resource: string | number): Promise<void> {
    return RestService.resourceUpdate(LikesService_COLLECTION_DELETE, id, {contentType, resource}) as Promise<any>
  }
}

export default LikesService