import IRestServiceOptions from "../../interface/api/IRestServiceOptions";
import IRestServiceCollectionResponse from "../../interface/api/IRestServiceCollectionResponse";
import IRestResource from "../../interface/api/IRestResource";
import IRepositoryService from "../../interface/IRepositoryService";
import IPresenter from "../../interface/dataStorage/IPresenter";
import IState from "../../interface/dataStorage/workflow/IState";
import IField from "../../interface/dataStorage/IField";
import IRestServiceChoiceListResponse from "../../interface/api/IRestServiceChoiceListResponse";

interface IRestWorkflowStatesService extends IRepositoryService{
    EXTENSION_NAME: string
}

const WorkflowStatesService: IRestWorkflowStatesService = {
    EXTENSION_NAME: 'App\\DataStorage\\Extension\\Workflow\\State',
    getRecordClassName(): string {
        return "App\\DataStorage\\Entity\\Workflow\\State";
    },
    getFields(): IField[] {
        // TODO
        return [
            {
                uuid: '',
                name: 'name',
                mode: "scalar",
                type: 'string',
                label: 'Název',
                targetEntity: null,
                options: [],
                weight: 1,
                contentTypeId: null,
                contentTypeName: false,
                locked: true,
                arguments: {}
            }
        ];
    },
    getTitle(): string {
        return "Stav";
    },
    collectionList(options?: IRestServiceOptions): Promise<IRestServiceCollectionResponse> {
        throw new Error('Not implemented');
    },
    collectionCreate(data: any, options?: IRestServiceOptions): Promise<IRestResource> {
        throw new Error('Not implemented');
    },
    resourceUpdate(id: number | string, data: any, options?: IRestServiceOptions): Promise<IRestResource> {
        throw new Error('Not implemented');
    },
    resourceRetrieve(id: number | string): Promise<IRestResource> {
        throw new Error('Not implemented');
    },
    resourceDelete(id: number | string): Promise<void> {
        throw new Error('Not implemented');
    },
    getPresenter(name: string): IPresenter | null {
        return this.getDefaultPresenter()
    },
    getStringValue: function (state: IState): string {
        return state.name
    },
    getDefaultPresenter(): IPresenter {
        return {
            name: 'name',
            label: 'Název',
            type: 'tag',
            options: {
                value: 'tagStyle',
                scheme: 'state'
            }
        }
    },
    getPresenterList() {
        //TODO ADD PRESENTERS ?
        return [this.getDefaultPresenter()].map(presenter => {return {value: presenter.name, label: presenter.label}})
    },
    choiceList(presenterName: string, options?: IRestServiceOptions): Promise<IRestServiceChoiceListResponse> {
        throw new Error('Not implemented'); //TODO
    }
}

export default WorkflowStatesService