import React from "react"
import {Col, Row, Spin} from "antd";
import IRepositoryService from "model/interface/IRepositoryService";
import {connect, RootStateOrAny} from "react-redux";
import moment, {Moment, unitOfTime} from "moment";
import Chart from "react-apexcharts";
import {COLOR_1} from "../../../../../model/constants/ChartConstant";
import {IViewGraphSettingsInterval} from "../../../../../model/interface/dataStorage/view/graph/IViewGraphSettings";
import IContentType from "../../../../../model/interface/dataStorage/IContentType";
import DataStorageService from "../../../../../model/service/dataStorage/DataStorageService";
import Utils from "../../../../../utils";
import selectors from "../../../../../redux/selectors";
import {
    ContentTypeStatisticsRecordsIncreaseData
} from "../../../../../model/interface/dataStorage/contentType/IContentTypeStatistics";

interface IChartCategory {
    label: string
    min: any
    max: any
}

interface IChartDataSet {
    name: string,
    data: any
}

interface IState {
    total: number,
    loading: boolean,
    interval: IViewGraphSettingsInterval
    intervalMin: Moment | null,
    intervalMax: Moment | null
    intervalLength: number
}

interface IProps {
    findOneByContentType: (contentType: IContentType) => IRepositoryService,
    contentType: IContentType,
    data: Array<ContentTypeStatisticsRecordsIncreaseData>
}

class StatisticsRecordsGrowthGraph extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            loading: false,
            total: 0,
            interval: "month",
            intervalMax: null,
            intervalMin: null,
            intervalLength: 12
        }
    }

    getService = (): DataStorageService => {
        return this.props.findOneByContentType(this.getContentType()) as DataStorageService
    }

    getContentType() {
        return this.props.contentType
    }

    buildCategories = (): { min: Moment, max: Moment, label: string }[] => {
        const categories: IChartCategory[] = [] as IChartCategory[]
        const {interval, intervalLength, intervalMin, intervalMax} = this.state

        let delta: unitOfTime.StartOf, label
        delta = interval;
        label = 'MMMM YYYY'
        let min = intervalMin?.clone()
            || (intervalMax ? intervalMax.clone()
                    .add(1 - (intervalLength), delta) :
                moment().add(1 - (intervalLength), delta))
        let max = intervalMax?.clone() || (intervalMin ? intervalMin.clone()
            .add((intervalLength) - 1, delta) : moment())
        if (!min || !max) {
            return categories
        }
        let current = min.clone().startOf(delta)
        while (current < max) {
            categories.push({
                label: current.format(label),
                min: current.clone(),
                max: current.clone().add(1, delta),
            });
            current.add(1, delta)
        }

        return categories
    }

    buildSeries = () => {
        const series: IChartDataSet[] = []

        series.push({
            data: this.buildRowData(),
            name: ('Vytvoreno')
        })
        return series
    }

    buildRowData = (): {} => {
        const {data} = this.props
        return this.buildCategories().map(category => {
            return data.find(row => moment(row.min) >= category.min && moment(row.min) <= category.max)?.count || 0
        })
    }

    render() {
        const {contentType} = this.props
        const {loading} = this.state;
        const categories = this.buildCategories().map(category => category.label)
        const series = this.buildSeries()

        return (
            <>
                <Row gutter={20}>
                    <Col span={24}>
                        <Row hidden={!loading} className={'position-absolute w-100 h-100'}
                             style={{background: "rgba(255,255,255, 0.7)", zIndex: 10}}
                             justify={"center"} align={"middle"}>
                            <Spin size={"large"}/>
                        </Row>
                        <Chart
                            options={{
                                plotOptions: {
                                    bar: {
                                        horizontal: false,
                                        columnWidth: '75%'
                                    },
                                },
                                colors: [Utils.adjustColor(COLOR_1, 50)],
                                dataLabels: {
                                    enabled: categories.length <= 10
                                },
                                stroke: {
                                    show: true,
                                    width: 2,
                                    colors: [Utils.adjustColor(COLOR_1, 50)]
                                },
                                xaxis: {
                                    categories,
                                },
                                fill: {
                                    opacity: 1
                                }
                            }}
                            series={series}
                            height={300}
                            type="bar"
                            customOptions={{
                                chart: {id: 'statistics-' + contentType.id, group: 'statistics-' + contentType.id}
                            }}
                        />
                    </Col>
                </Row>
            </>
        )
    }
}


const mapStateToProps = (state: RootStateOrAny) => {
    return {
        findOneByContentType: (contentType: IContentType) => selectors.services.findOneByContentType(state, contentType)
    }
}

export default connect(mapStateToProps)(StatisticsRecordsGrowthGraph)