import IContentType from "../../../../../../model/interface/dataStorage/IContentType";
import IField, {FIELD_MODE_ICONS, RELATION_FIELD_TYPE} from "../../../../../../model/interface/dataStorage/IField";
import {Empty, Row} from "antd";
import CardWidgetType from "./CardWidgetType";
import {TagOutlined} from "@ant-design/icons";
import WidgetGallery, {GroupItem} from "../../../widget/WidgetGallery";
import FormElementType from "../../../form/FormElement/FormElementType";
import IAction from "../../../../../../model/interface/dataStorage/IAction";
import CardWidgetList from "./CardWidgetList";
import ThreadsService from "../../../../../../model/service/comment/ThreadsService";
import LikesService from "../../../../../../model/service/dataStorage/extension/LikesService";
import React from "react";


interface IProps {
    contentType: IContentType
    exists: (group: string, id: number, type?: string) => boolean
}

class CardWidgetGallery extends WidgetGallery<IProps> {

    getGroup(group: [string, GroupItem]) {
        const {contentType} = this.props
        const fields = contentType.fields.sort((a, b) => {
            return (a.label || a.name) > (b.label || b.name) ? 1 : -1
        })
        const list = {
            [CardWidgetType.GROUP_SCALAR]:
                this.getScalarFieldOptions(fields, group[0]),
            [CardWidgetType.GROUP_RELATION]:
                this.getRelationFieldOptions(fields, group[0]),
            [FormElementType.GROUP_ACTION]:
                this.getActionOptions(contentType.actions, group[0]),
        }[group[0]]

        return list ? (list.length > 0 ? list :
                    [<Row className={'w-100'} justify={"center"}>
                        <Empty className={'m-0'} image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                    </Row>]
            ) : group[1].widgets.map((widget, index) => this.getNodeOptionTag(widget, index))
    }

    getScalarFieldOptions(fields: IField[], group: string) {
        return [
            ...fields
                .map((field, index) => {
                    return this.getNodeOptionTag({
                            ...CardWidgetList.getItemByType(CardWidgetType.SCALAR_FIELD),
                            icon: field.mode ? FIELD_MODE_ICONS[field.mode] : <TagOutlined/>,
                            label: field.label || '[' + field.name + ']',
                        }, index,
                        () => this.onSelectItem(CardWidgetType.SCALAR_FIELD, field),
                        this.props.exists(group, field.id as number)
                    )
                })
        ];
    }

    getRelationFieldOptions(fields: IField[], group: string) {
        const {contentType, exists} = this.props
        return [
            ...fields
                .filter(field => [ThreadsService.getRecordClassName(), LikesService.getRecordClassName()].indexOf(field.targetEntity || '') < 0)
                .filter(field => [RELATION_FIELD_TYPE.ONE_TO_MANY, RELATION_FIELD_TYPE.MANY_TO_MANY].indexOf(field.type) >= 0)
                .map((field, index) => {
                    return this.getNodeOptionTag({
                            ...CardWidgetList.getItemByType(CardWidgetType.RELATION_FIELD),
                            label: field.label || '[' + field.name + ']',
                        }, index,
                        () => this.onSelectItem(CardWidgetType.RELATION_FIELD, field),
                        false
                        // this.exists(field.id as number)
                    )
                }),
            ...contentType.extensions.flatMap((extension, index) => {
                switch (extension.type) {
                    case 'App\\DataStorage\\Extension\\Comments':
                        const widget = CardWidgetList.getItemByType(CardWidgetType.COMMENT)
                        return this.getNodeOptionTag(
                            widget,
                            index + fields.length,
                            () => this.onSelectItem(CardWidgetType.COMMENT),
                            exists(group, -1, widget.type)
                        )
                    case 'App\\DataStorage\\Extension\\GeneratedDocument':
                        const widgetG = CardWidgetList.getItemByType(CardWidgetType.GENERATED_DOCUMENTS)
                        return this.getNodeOptionTag(
                            widgetG,
                            index + fields.length,
                            () => this.onSelectItem(CardWidgetType.GENERATED_DOCUMENTS),
                            exists(group, -1, widgetG.type)
                        )
                    case 'App\\DataStorage\\Extension\\Like':
                        const widgetL = CardWidgetList.getItemByType(CardWidgetType.LIKE)
                        return this.getNodeOptionTag(
                            widgetL,
                            index + fields.length,
                            () => this.onSelectItem(CardWidgetType.LIKE),
                            exists(group, -1, widgetL.type)
                        )
                    case 'App\\DataStorage\\Extension\\Workflow\\State':
                        const widgetW = CardWidgetList.getItemByType(CardWidgetType.WORKFLOW_TRANSACTION_CONDITIONS)
                        return this.getNodeOptionTag(
                            widgetW,
                            index + fields.length,
                            () => this.onSelectItem(CardWidgetType.WORKFLOW_TRANSACTION_CONDITIONS),
                            exists(group, -1, widgetW.type)
                        )
                    default:
                        return []
                }
            })
        ];
    }

    getActionOptions(actions: IAction[], group: string) {
        return actions.map((action, index) => {
            return this.getNodeOptionTag({
                    ...CardWidgetList.getItemByType(CardWidgetType.ACTION),
                    label: action.label || '[' + action.name + ']',
                }, index,
                () => this.onSelectItem(FormElementType.ACTION, undefined, action),
                this.props.exists(group, action.id as number)
            )
        });
    }

    onSelectItem(type: string, field?: IField, action?: IAction) {
        super.onSelectItem(type, field, action);
    }
}

export default CardWidgetGallery