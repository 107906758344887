import React from "react"
import Utils from "../../../../utils";
import Chart from "react-apexcharts";
import {IChartDataSet} from "../ViewGraph";
import IViewUnit from "../../../../model/interface/dataStorage/IViewUnit";
import {GraphType} from "../../../../model/interface/dataStorage/view/graph/IViewGraphOptions";

interface IProps {
    viewUnit: IViewUnit
    series: IChartDataSet[],
    type: GraphType,
    colors: string[],
    categories: any
}

export default class XYChart extends React.Component<IProps> {

    render() {
        const {colors, categories, series, type, viewUnit} = this.props
        return (
            <Chart
                options={{
                    chart: {
                        toolbar: {
                            tools: {
                                download: true,
                                selection: false,
                                zoom: false,
                                zoomin: false,
                                zoomout: false,
                                pan: false,
                                reset: false
                            }
                        }
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '75%'
                        },
                    },
                    colors: colors.map(color => Utils.adjustColor(color, 50)),
                    dataLabels: {
                        enabled: categories.length <= 10
                    },
                    stroke: {
                        show: true,
                        curve: 'smooth',
                        width: 2,
                        colors
                    },
                    xaxis: {categories},
                    fill: {opacity: 1},
                }}
                series={series}
                height={300}
                type={type}
                customOptions={{
                    chart: {
                        id: 'view-' + viewUnit.id, group: 'view-' + viewUnit.id
                    },
                }}
            />
        )
    }
}