import React from "react";
import {Form, Input, Radio, Switch} from "antd";
import IconPicker from "../../../../shared/IconPicker";
import ButtonTypePicker from "../../../../shared/button/ButtonTypePicker";
import ColorPicker from "../../../../shared/pickers/ColorPicker";

interface IProps {
    defaultLabel?: string | JSX.Element
}

class ButtonProperties extends React.Component<IProps> {

    render() {
        const {defaultLabel} = this.props
        return (
            <div>
                <Form.Item name={'label'} label={'Zvolte titulek'} rules={[{required: true}]} initialValue={defaultLabel}>
                    <Input/>
                </Form.Item>
                <Form.Item name={'icon'} label={'Zvolte ikonu'}>
                    <IconPicker/>
                </Form.Item>
                <Form.Item name={'danger'} label={'Vystrazne'} valuePropName={'checked'}>
                    <Switch/>
                </Form.Item>
                <Form.Item name={'type'} label={'Zvolte typ tlačítka'} rules={[{required: true}]} initialValue={'primary'}>
                    <ButtonTypePicker/>
                </Form.Item>
                <Form.Item name={'size'} label={'Zvolte velikost'} initialValue={'small'}>
                    <Radio.Group>
                        <Radio value={'small'}>malé</Radio>
                        <Radio value={'middle'}>střední</Radio>
                        <Radio value={'large'}>velké</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item name={'color'} label={'Zvolte barvu'}>
                    <ColorPicker/>
                </Form.Item>
                <Form.Item name={'textColor'} label={'Zvolte barvu textu'}>
                    <ColorPicker/>
                </Form.Item>
            </div>
        )
    }
}

export default ButtonProperties