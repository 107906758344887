import React from "react"
import Utils from "../../../../utils";
import Chart from "react-apexcharts";
import {IChartDataSet} from "../ViewGraph";
import IViewUnit from "../../../../model/interface/dataStorage/IViewUnit";
import {GraphType} from "../../../../model/interface/dataStorage/view/graph/IViewGraphOptions";

interface IProps {
    viewUnit: IViewUnit
    series: IChartDataSet[],
    type: GraphType,
    colors: string[],
    labels: string[]
}

export default class PieChart extends React.Component<IProps> {

    render() {
        const {colors, labels, series, type, viewUnit} = this.props
        return (
            <Chart
                options={{
                    colors: colors.map(color => Utils.adjustColor(color, 50)),
                    dataLabels: {
                        enabled: labels.length <= 10,
                        style: {
                            colors: ['#000000']
                        },
                        dropShadow: {enabled: false}
                    },
                    tooltip: {
                        enabled: true,
                        style: {
                        },
                        theme: 'light'

                    },
                    stroke: {
                        show: true,
                        width: 2,
                        colors
                    },
                    labels: labels,
                    fill: {opacity: 1},
                }}
                series={series.length ? series.map(s => s.data) : ['0']}
                height={300}
                type={type}
                customOptions={{
                    chart: {id: 'view-' + viewUnit.id, group: 'view-' + viewUnit.id}
                }}
            />
        )
    }
}