import React, {RefObject} from "react"
import IMenuItem, {MENU_ITEM_TYPES} from "../../../../../model/interface/ui/IMenuItem";
import {Link} from "react-router-dom";
import MenuItemsService from "../../../../../model/service/ui/MenuItemsService";
import {LoadingOutlined} from "@ant-design/icons";
import {Menu, Tooltip} from "antd";
import MenuGroupBuilder from "./MenuGroupBuilder";
import SettingsService from "../../../../../model/service/SettingsService";
import IconBuilder, {getIconLibrary} from "../../../../../utils/IconBuilder";
import Utils from "../../../../../utils";
import {TooltipPlacement} from "antd/lib/tooltip";
import {onMobileNavToggle} from "../../../../../redux/actions/Theme";
import {IAppState} from "../../../../../redux/store";
import {connect} from "react-redux";
import selectors from "../../../../../redux/selectors";
import history from "./../../../../../history";


interface IProps {
    item: IMenuItem,
    onItemCLick: (item: IMenuItem, parent?: boolean) => void
    items: IMenuItem[],
    customRender?: (item: IMenuItem, icon?: JSX.Element) => JSX.Element,
    className?: string
    iconProps?: any,
    ellipsisTooltipPosition?: TooltipPlacement
    doNotRefParent?: boolean
    isDarkMode: () => boolean
    onMobileNavToggle: (toggle: boolean) => void,
    isMobile: boolean
}

interface IState {
    loading: boolean
    isEllipsisActive: boolean
}

class MenuItemLink extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props)
        this.state = {
            loading: false,
            isEllipsisActive: false
        }
    }

    onClickScript = (item: IMenuItem) => {
        const {onItemCLick} = this.props
        this.setState({loading: true})
        MenuItemsService.runScript(item.menu, item.uuid).then(response => {
            onItemCLick(response)
            this.setState({loading: false})
            if (response.url) {
                if (this.isExternalLink(response.url)) {
                    window.open(response.url, response.blank ? '_blank' : '_self')
                    return
                }
                response.blank ? window.open(response.url) : history.push(response.url)
            }
        })
    }

    isExternalLink(url: string) {
        return (new RegExp('^(?:[a-z+]+:)?//', 'i')).test(url)
            && new URL(url).origin !== window.location.origin;
    }

    itemRef: RefObject<HTMLSpanElement> = React.createRef()

    setEllipsisActive = (active?: boolean) => {
        this.setState({isEllipsisActive: active !== undefined ? active : this.isEllipsisActive()})
    }

    render() {
        const {
            item,
            onItemCLick,
            items,
            customRender,
            iconProps,
            ellipsisTooltipPosition,
            isMobile,
            onMobileNavToggle,
            isDarkMode,
            ...other
        } = this.props
        const {loading, isEllipsisActive} = this.state
        const color = (isDarkMode() && item.iconColorDark) || item.iconColor || SettingsService.getThemeColor()
        const icon = {
            ion: IconBuilder(item.icon, undefined, Utils.deepMerge({color: color?.slice(0, 7)}, iconProps)),
            ant: IconBuilder(item.icon, undefined, Utils.deepMerge({style: {color}}, iconProps)),
        }[getIconLibrary(item.icon || '')]
        const finalIcon = loading ? <LoadingOutlined {...iconProps}/> : icon

        return (
            <Tooltip title={item.title}
                     visible={isEllipsisActive}
                     placement={ellipsisTooltipPosition || "right"} mouseEnterDelay={0.1}>
                {items.filter(value => value.parent && value.parent === item.uuid).length > 0 ?
                    <Menu.SubMenu
                        {...other}
                        icon={customRender ? undefined : icon}
                        onTitleMouseEnter={() => this.setEllipsisActive()}
                        onTitleMouseLeave={() => this.setEllipsisActive(false)}
                        style={{background: (isDarkMode() && item.backgroundColorDark) || item.backgroundColor}}
                        key={item.uuid}
                        title={<span ref={this.itemRef}>{this.buildContent(item, finalIcon)}</span>}
                        onTitleClick={() => onItemCLick(item, true)}
                    >
                        {MenuGroupBuilder.buildChildren(items, item.uuid, onItemCLick)}
                    </Menu.SubMenu> : <Menu.Item {...other} icon={customRender ? undefined : finalIcon} key={item.uuid}
                                                 onClick={() => this.onClick(item)}
                                                 style={{background: (isDarkMode() && item.backgroundColorDark) || item.backgroundColor}}>
                        <span onMouseEnter={() => this.setEllipsisActive()}
                              onMouseLeave={() => this.setEllipsisActive(false)} ref={this.itemRef}>
                            {{
                                [MENU_ITEM_TYPES.ROUTE]: item.route &&
                                <Link onClick={() => onItemCLick(item)} to={(item.route).url}
                                      target={item.blank ? '_blank' : undefined}>
                                    {this.buildContent(item, finalIcon)}
                                </Link>,
                                [MENU_ITEM_TYPES.URL]: this.buildLink(item, finalIcon, onItemCLick),
                                [MENU_ITEM_TYPES.SCRIPT]: this.buildLink(item, finalIcon, onItemCLick),
                                [MENU_ITEM_TYPES.ONCLICK_SCRIPT]: <span onClick={() => this.onClickScript(item)}>
                                                                    {this.buildContent(item, finalIcon)}
                                                                </span>,
                                '': this.buildContent(item, finalIcon)
                            }[item.type]}
                        </span>
                    </Menu.Item>
                }
            </Tooltip>
        )
    }

    onClick(item: IMenuItem) {
        const {onMobileNavToggle, isMobile} = this.props

        if (item.type === MENU_ITEM_TYPES.ONCLICK_SCRIPT){
            this.onClickScript(item)
        }
        isMobile && onMobileNavToggle(false)
    }

    isEllipsisActive() {
        const {doNotRefParent} = this.props
        const parentElement = doNotRefParent ? this.itemRef.current : this.itemRef.current?.parentElement
        return (parentElement?.scrollWidth || 0) > (parentElement?.clientWidth || 0);
    }

    buildLink(item: IMenuItem, finalIcon: JSX.Element | undefined, onClick: (item: IMenuItem, parent?: boolean) => void) {
        const content = this.buildContent(item, finalIcon);
        return item.url && (this.isExternalLink(item.url) ?
            <a href={item.url} target={item.blank ? '_blank' : undefined} className={'d-block'} rel="noreferrer">
                {content}
            </a> :
            <Link onClick={() => onClick(item)} to={item.url} target={item.blank ? '_blank' : undefined}>
                {content}
            </Link>)
    }

    buildContent(item: IMenuItem, finalIcon: JSX.Element | undefined) {
        const {customRender} = this.props
        return customRender?.(item, finalIcon) || <span>{item.title}</span>;
    }
}


const mapStateToProps = (state: IAppState) => {
    return {
        isMobile: state.theme.isMobile,
        isDarkMode: () => selectors.theme.isDark(state)
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        onMobileNavToggle: (toggle: boolean) => dispatch(onMobileNavToggle(toggle)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuItemLink)