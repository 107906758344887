import {Popover, Row} from 'antd';
import React from "react";
import IFieldOptions from "../../../../../../model/interface/form/elementOptions/IFieldOptions";
import EmojiPicker, {Theme} from "emoji-picker-react";
import Button from "../../../../../shared/button/Button";
import {CloseOutlined, PlusOutlined} from "@ant-design/icons";
import Emoji from "../../../content-type/field/presenters/Emoji";
import {IAppState} from "../../../../../../redux/store";
import selectors from "../../../../../../redux/selectors";
import {connect} from "react-redux";

interface IProps {
    value?: string,
    onChange?: (value?: string) => void
    options: IFieldOptions
    isDark: () => boolean
}

interface IState {
}

class FormFieldEmoji extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {}
    }

    setVisible = () => {
        this.setState({visible: true})
    }

    render() {
        const {placeholder, disabled, emojiOriginal, showClear} = this.props.options
        const {value, onChange, options, isDark} = this.props

        return (
            <Row className={'d-inline-flex'}>
                <Popover visible={disabled ? false : undefined}
                         content={<div><EmojiPicker previewConfig={{showPreview: false}} className={'border-0'}
                                                    lazyLoadEmojis={true} theme={isDark() ? Theme.DARK : Theme.LIGHT}
                                                    onEmojiClick={e => onChange?.(emojiOriginal ? e.imageUrl : e.emoji)}/>
                         </div>}>
                    <div className={'cursor-pointer d-inline-block'}>{value ? <Emoji value={value} options={options}/> :
                        <Button icon={<PlusOutlined/>}>{placeholder}</Button>}</div>
                </Popover>
                {value && showClear && (
                    <Button onClick={() => onChange?.()} type={'link'}  size={'extraSmall'} shape={"circle"} icon={<CloseOutlined/>}></Button>
                )}
            </Row>
        )
    }
}
const mapStateToProps = (state: IAppState) => {
    return {
        isDark: () => selectors.theme.isDark(state)
    }
}

export default connect(mapStateToProps)(FormFieldEmoji)