import IRestServiceCollectionResponse from "../../interface/api/IRestServiceCollectionResponse";
import RestService from "model/service/dataStorage/RestService";
import IRestServiceOptions from "model/interface/api/IRestServiceOptions";
import IMessage from "../../interface/ui/IMessage";

interface IRestMessagesServiceCollectionResponse extends IRestServiceCollectionResponse {
    results: Array<IMessage>
}

interface IRestMessagesService {
    collectionList(options?: IRestServiceOptions): Promise<IRestMessagesServiceCollectionResponse>,

    collectionCreate(data: any): Promise<IMessage>,

    resourceRetrieve(id: number | string): Promise<IMessage>,

    resourceUpdate(id: number | string, data: any): Promise<IMessage>,

    resourceDelete(id: number | string): Promise<void>,

    collectionList(): Promise<IRestServiceCollectionResponse>,
}

const MessagesService_COLLECTION = 'messages'

const MessagesService: IRestMessagesService = {
    collectionList: function (options?: IRestServiceOptions) {
        return RestService.collectionList(MessagesService_COLLECTION, options as unknown as IRestServiceOptions) as Promise<IRestMessagesServiceCollectionResponse>
    },
    collectionCreate: function (data) {
        return RestService.collectionCreate(MessagesService_COLLECTION, data) as Promise<IMessage>
    },
    resourceRetrieve: function (id) {
        return RestService.resourceRetrieve(MessagesService_COLLECTION, id) as Promise<IMessage>
    },
    resourceDelete: function (id) {
        return RestService.resourceDelete(MessagesService_COLLECTION, id)
    },
    resourceUpdate: function (id, data) {
        return RestService.resourceUpdate(MessagesService_COLLECTION, id, data) as Promise<IMessage>
    }
}

export default MessagesService