import React from "react"
import ViewTypesService from "model/service/dataStorage/ViewTypesService"
import {Col, Modal, Row, Spin, Steps} from "antd";
import IViewType from "model/interface/dataStorage/view/IViewType";
import {connect, RootStateOrAny} from "react-redux";
import {ISetupState} from "../../../../../redux/reducers/Setup";
import IBaseProps from "../../../../../model/interface/IBaseProps";
import ViewProperties from "./ViewUnitProperties";
import ViewFields from "./ViewUnitFields";
import ViewUnitActions from "./ViewUnitActions";
import ViewUnitContentTypes from "./ViewUnitContentTypes";
import IContentType from "../../../../../model/interface/dataStorage/IContentType";
import IViewUnit from "../../../../../model/interface/dataStorage/IViewUnit";
import ViewUnitType from "./ViewUnitType";
import ViewUnitSummary from "./ViewUnitSummary";
import ViewUnitPreview from "./ViewUnitPreview";
import Utils from "../../../../../utils";
import IView from "../../../../../model/interface/dataStorage/IView";
import ScrollContainer from "../../../../shared/scrollContainer/ScrollContainer";


interface IProps extends IBaseProps {
    resource?: IViewUnit,
    view: IView
    onSave: (view?: IViewUnit) => void
    contentTypes: IContentType[],
}

export interface IViewStepProps extends IBaseProps {
    resource: IViewUnit,
    setStep: (back?: boolean, step?: number) => void,
    onChange: (values: any) => void,
    contentTypes: IContentType[],
    viewTypes: IViewType[],
    view: IView
}

interface IState {
    loading: boolean,
    resource: IViewUnit,
    viewTypes: IViewType[],
    step: number
}

class ViewUnitForm extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props)
        this.state = {
            loading: false,
            viewTypes: [],
            resource: this.props.resource || {
                id: 0,
                uuid: Utils.uuid(),
                label: "",
                type: "",
                contentTypes: [],
                items: [],
                entryActions: [],
                schemaActions: [],
                collectionActions: [],
                options: {}
            } as unknown as IViewUnit,
            step: 0
        }
    }

    load(): void {
        this.setState({loading: true}, () => {
            ViewTypesService.collectionList().then(viewTypes => {
                this.setState({
                    viewTypes,
                    loading: false
                })
            })
        })
    }

    setStep = (back: boolean = false, step?: number) => {
        this.setState(state => ({
            step: step !== undefined ? step : state.step + (back ? -1 : 1)
        }))
    }

    onChange(values: any): void {
        this.setState({
            resource: {
                ...this.state.resource,
                ...values
            }
        })
        this.forceUpdate()
    }

    componentDidMount() {
        this.load()
    }

    save = () => {
        const {items, entryActions, schemaActions, collectionActions} = this.state.resource
        const data: any = {
            ...this.state.resource,
            items: items.map(item => {
                const {id} = item
                return {
                    ...item,
                    id: id || null,
                }
            }),
            entryActions: entryActions ? entryActions.map(entryAction => {
                const {id} = entryAction
                return {
                    ...entryAction,
                    id: id && id > 0 ? id : null,
                }
            }) : entryActions,
            schemaActions: schemaActions ? schemaActions.map(schemaAction => {
                const {id} = schemaAction
                return {
                    ...schemaAction,
                    id: id && id > 0 ? id : null
                }
            }) : [],
            collectionActions: collectionActions ? collectionActions.map(collectionAction => {
                const {id} = collectionAction
                return {
                    ...collectionAction,
                    id: id && id > 0 ? id : null
                }
            }) : []
        }
        this.props.onSave(data)
    }

    cancel = () => {
        this.props.onSave()
    }

    render() {
        const {contentTypes, view} = this.props
        const {step, loading, resource, viewTypes} = this.state
        const {match, history} = this.props

        const stepProps: IViewStepProps = {
            setStep: this.setStep,
            onChange: (values) => this.onChange(values),
            resource: resource,
            contentTypes: contentTypes,
            history: history,
            match: match,
            viewTypes: viewTypes,
            view: view
        }

        return (
            <Modal title={resource.id ? "Upravit sekci" + resource.label : "Nová sekce"} visible={true}
                   onCancel={this.cancel} onOk={this.save}
                   okButtonProps={{disabled: step !== 6 && !resource.id}} okText={'Uložit'} width={800}
                   style={{minWidth: '80vw'}}>
                {loading ? (
                    <Spin spinning={true}/>
                ) : (
                    <Row gutter={16}>
                        <Col sm={6}>
                            <Steps current={step}
                                   onChange={selectedStep => step === 6 || resource.id ? this.setStep(false, selectedStep) : undefined}
                                   direction={"vertical"}>
                                <Steps.Step title="Typ pohledu"/>
                                <Steps.Step title="Typy obsahu"/>
                                <Steps.Step title="Vlastnosti"/>
                                <Steps.Step title="Pole"/>
                                <Steps.Step title="Akce"/>
                                <Steps.Step title="Výchozí zobrazení"/>
                                <Steps.Step title="Souhrn"/>
                            </Steps>
                        </Col>
                        <Col sm={18}>
                            <ScrollContainer style={{maxHeight: '60vh'}} visibility={"visible"} className={'p-1'}>
                                {{
                                    0: <ViewUnitType {...stepProps}/>,
                                    1: <ViewUnitContentTypes {...stepProps}/>,
                                    2: <ViewProperties {...stepProps}/>,
                                    3: <ViewFields {...stepProps}/>,
                                    4: <ViewUnitActions {...stepProps}/>,
                                    5: <ViewUnitPreview {...stepProps}/>,
                                    6: <ViewUnitSummary {...stepProps}/>
                                }[step]}
                            </ScrollContainer>
                        </Col>
                    </Row>
                )}
            </Modal>
        )
    }
}


const mapStateToProps = (state: RootStateOrAny) => {
    const {contentTypes} = state.setup as ISetupState

    return {
        contentTypes
    }
}

export default connect(mapStateToProps)(ViewUnitForm)
