import React, {RefObject} from "react";
import {Button, FormInstance, Input, Tooltip, TreeSelect} from "antd";
import {FolderAddOutlined, LoadingOutlined, PlusOutlined} from "@ant-design/icons";
import IDirectory from "../../../../model/interface/file/IDirectory";
import DirectoriesService from "../../../../model/service/file/DirectoriesService";
import DirectoryEdit from "../../../app/file-manager/DirectoryManagers/DirectoryEdit";

interface IStateFileUpload {
    value?: number | number[]
    loading: boolean,
    directoryTree: IDirectory | null
    formRef: RefObject<FormInstance>,
    visible: boolean
}

interface IPropsFileUpload {
    onChange?: (id: number | number[]) => void
    value?: number,
    multiple?: boolean,
    placeholder?: string,
    add?: boolean,
    expand?: boolean
    defaultRoot?: boolean
    disabled?: boolean
}

class DirectoryPicker extends React.Component<IPropsFileUpload, IStateFileUpload> {

    constructor(props: IPropsFileUpload) {
        super(props);
        this.state = {
            value: props.value,
            loading: true,
            directoryTree: null,
            formRef: React.createRef(),
            visible: false
        } as IStateFileUpload
    }

    static defaultProps = {
        add: true,
        multiple: false,
        open: false,
        defaultRoot: true
    }

    componentDidMount() {
        this.load().then()
    }

    load() {
        return new Promise<void>(resolve => {
            DirectoriesService.collectionListTree().then(response => {
                this.setState(state => ({
                        loading: false,
                        directoryTree: response.tree,
                        value: state.value ? state.value : this.props.defaultRoot ? response.tree.id : undefined
                    }),
                    () => this.state.value && this.onChange(this.state.value))
                resolve()
            })
        })
    }

    onShowDirectoryModal = () => {
        this.setState({visible: true})
    }

    onCancel = () => {
        this.setState({visible: false})
    }

    onCreate = (directory?: IDirectory) => {
        this.load().then(() => this.setState({value: directory?.id, visible: false}))
    }

    onChange = (id: number | number[]) => {
        this.setState({value: id})
        this.props.onChange && this.props.onChange(id)
    }

    render() {
        const {loading, directoryTree, visible, value} = this.state
        const {multiple, placeholder, add, expand, disabled} = this.props

        return (
            <>
                <Input.Group compact>
                    <TreeSelect
                        filterTreeNode={(input, directory) =>
                            directory?.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        showSearch={true}
                        value={directoryTree ? value : undefined}
                        disabled={disabled}
                        style={add ? {width: 'calc(100% - 40px)'} : {width: '100%'}}
                        dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                        treeData={[directoryTree ? directoryTree : {}]}
                        placeholder={placeholder || 'Vyberte adresář'}
                        loading={loading}
                        treeDefaultExpandedKeys={typeof value === 'number' ? [value] : value}
                        treeDefaultExpandAll={expand}
                        onChange={this.onChange}
                        multiple={multiple}
                        allowClear
                        suffixIcon={loading ? <LoadingOutlined/> : <FolderAddOutlined/>}
                    />
                    {add && (
                        disabled ? (
                            <Button disabled={disabled} onClick={this.onShowDirectoryModal}
                                    icon={<PlusOutlined/>}/>
                        ) : (
                            <Tooltip title={"Vytvořit složku"}>
                                <Button onClick={this.onShowDirectoryModal}
                                        icon={<PlusOutlined/>}/>
                            </Tooltip>
                        )
                    )}


                </Input.Group>
                {visible && <DirectoryEdit onUpdate={this.onCreate}/>}
            </>
        )
    }
}

export default DirectoryPicker