import React from "react";
import {Input} from "antd";
import moment from "moment";


interface IProps {
    value?: string,
    onChange?: (key: string) => void
    style?: React.CSSProperties
    placeholder?: string
    disabled?: boolean,
    showHelpLink?: boolean
    className?: string
}

interface IState {
    value?: string,
}

class MomentJsDateFormat extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props)
        this.state = {
            value: props.value ? props.value : '',
        }
    }

    static defaultProps = {
        showHelpLink: true
    }

    handleChange = (value: string) => {
        this.setState({value}, () => {
            this.props.onChange && this.props.onChange(value)
        });
    }

    render() {
        const {style, placeholder, value, disabled, showHelpLink, className} = this.props;

        return (
            <div>
                <Input.Group className={(className ? className : '' ) + ' d-flex w-100'} style={style}>
                    <Input value={value} onChange={e => this.handleChange(e.target.value)} disabled={disabled}
                           placeholder={placeholder}/>
                    <Input disabled value={moment().format(value)}/>
                </Input.Group>
                {showHelpLink && (
                    <a href={'https://momentjs.com/'} rel="noreferrer" target={'_blank'}>Více formátů
                        zde</a>
                )}
            </div>
        )
    }
}

export default MomentJsDateFormat