import React from "react";
import IViewUnit from "../../../model/interface/dataStorage/IViewUnit";
import {Col, Row, Typography} from "antd";
import settingsService from "../../../model/service/SettingsService";

interface IState {
}

interface IProps {
    viewUnit: IViewUnit
    hideTitle?: boolean
    marginBottom?: number
}

class ViewTitle extends React.Component<IProps, IState> {
    render() {
        const {viewUnit, children, hideTitle} = this.props;
        let {marginBottom} = this.props
        if(typeof marginBottom === 'undefined') {
            marginBottom = 2
        }

        return <Row gutter={[12, 8]} align={'middle'} className={"mb-" + marginBottom}>
            {!hideTitle && <Col>
                <Typography.Title className={'mb-0'} level={1} style={{color: settingsService.getThemeColor()}}>
                    {viewUnit.label}
                </Typography.Title>
            </Col>}
            <Col style={{flexBasis: '700px'}}>
                {children}
            </Col>
        </Row>
    }
}

export default ViewTitle