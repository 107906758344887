import {Dropdown, Menu, Tooltip, Typography} from "antd";
import {
    AppstoreOutlined,
    ClusterOutlined,
    CodeOutlined,
    FileOutlined,
    FolderOpenOutlined,
    GlobalOutlined,
    ImportOutlined,
    InfoCircleOutlined,
    ReloadOutlined,
    SettingOutlined,
    SnippetsOutlined,
    ToolOutlined,
    UserOutlined
} from "@ant-design/icons";
import React from "react";
import {connect, RootStateOrAny} from "react-redux";
import {Link} from "react-router-dom";
import {APP_PREFIX_PATH} from "../../configs/AppConfig";
import {reLoad} from "../../redux/actions/Setup";
import IUser from "../../model/interface/security/IUser";
import UsersService from "model/service/security/UsersService";
import LocaleText from "../app/settings/dictionary/LocaleText";

interface IProps {
    reLoad: () => void
    user: IUser
}

interface IState {
}

class NavConfiguration extends React.Component<IProps, IState> {
    render() {
        const {user} = this.props
        return (
            <Tooltip title={"Nástroje pro správu"} placement={'bottom'}>
                <Dropdown placement="bottomCenter" overlay={(
                    <div className="nav-profile nav-dropdown p-2">
                        <Typography.Title level={3} className={"text-center"}>Nástroje</Typography.Title>

                        <Menu className={"border-0"}>
                            <Menu.Item onClick={() => this.props.reLoad()} icon={<ReloadOutlined/>}>
                                <span>Aktualizovat nastavení</span>
                            </Menu.Item>
                            {UsersService.hasCredential(user, 'configuration') && (
                                <Menu.Item icon={<SettingOutlined/>}>
                                    <Link to={`${APP_PREFIX_PATH}/configuration`}>
                                        <span>Konfigurace</span>
                                    </Link>
                                </Menu.Item>
                            )}
                            {UsersService.hasCredential(user, 'template_configuration') && (
                                <Menu.Item icon={<SnippetsOutlined/>}>
                                    <Link to={`${APP_PREFIX_PATH}/configuration/templates`}>
                                        <span>Konfigurovat ze šablony</span>
                                    </Link>
                                </Menu.Item>
                            )}
                            {UsersService.hasCredential(user, 'files') && (
                                <Menu.Item icon={<FolderOpenOutlined/>}>
                                    <Link to={`${APP_PREFIX_PATH}/file-manager`}>
                                        <span>Datový sklad</span>
                                    </Link>
                                </Menu.Item>
                            )}
                            {/*<Menu.Item icon={<CalendarOutlined />}>*/}
                            {/*    <Link to={`${APP_PREFIX_PATH}/calendar`}>*/}
                            {/*        <span>Kalendář</span>*/}
                            {/*    </Link>*/}
                            {/*</Menu.Item>*/}
                            {UsersService.hasCredential(user, 'company') && (
                                <Menu.Item icon={<ClusterOutlined/>}>
                                    <Link to={`${APP_PREFIX_PATH}/company/configuration`}>
                                        <span>Nastavení společnosti</span>
                                    </Link>
                                </Menu.Item>
                            )}
                            {UsersService.hasCredential(user, 'import') && (
                                <Menu.Item icon={<ImportOutlined/>}>
                                    <Link to={`${APP_PREFIX_PATH}/import`}>
                                        <span>Import</span>
                                    </Link>
                                </Menu.Item>
                            )}
                            {UsersService.hasCredential(user, 'settings') && (
                                <Menu.Item icon={<ToolOutlined/>}>
                                    <Link to={`${APP_PREFIX_PATH}/settings`}>
                                        <span>Nastavení</span>
                                    </Link>
                                </Menu.Item>
                            )}
                            {UsersService.hasCredential(user, 'users') && (
                                <Menu.Item icon={<UserOutlined/>}>
                                    <Link to={`${APP_PREFIX_PATH}/security/users`}>
                                        <span>Uživatelé</span>
                                    </Link>
                                </Menu.Item>
                            )}
                            {UsersService.hasCredential(user, 'generatedDocuments') && (
                                <Menu.Item icon={<FileOutlined/>}>
                                    <Link to={`${APP_PREFIX_PATH}/generated-documents`}>
                                        <span><LocaleText code={'tools.document-generator'} fallback={'Generátor dokumentů'}/></span>
                                    </Link>
                                </Menu.Item>
                            )}
                            {UsersService.hasCredential(user, 'symfony-console') && (
                                <Menu.Item icon={<CodeOutlined/>}>
                                    <Link to={`${APP_PREFIX_PATH}/symfony-console`}>
                                        <span>Symfony konzole</span>
                                    </Link>
                                </Menu.Item>
                            )}
                            {UsersService.hasCredential(user, 'dictionary-settings') && (
                                <Menu.Item icon={<GlobalOutlined/>}>
                                    <Link to={`${APP_PREFIX_PATH}/dictionary-settings`}>
                                        <span>Slovník</span>
                                    </Link>
                                </Menu.Item>
                            )}
                            <Menu.Item icon={<InfoCircleOutlined/>}>
                                <Link to={`${APP_PREFIX_PATH}/security/version`}>
                                    <span>Licence a verze</span>
                                </Link>
                            </Menu.Item>
                        </Menu>

                        {/*<Row gutter={16}>*/}
                        {/*    <Col span={8}>*/}
                        {/*        <Tooltip title={"Konfigurace"}>*/}
                        {/*            <Link to={`${APP_PREFIX_PATH}/configuration`}>*/}
                        {/*                <Button type={"link"} style={{width: 64, height: 64}}>*/}
                        {/*                    <SettingOutlined  style={{fontSize: 24}} />*/}
                        {/*                </Button>*/}
                        {/*            </Link>*/}
                        {/*        </Tooltip>*/}
                        {/*    </Col>*/}
                        {/*    <Col span={8}>*/}
                        {/*        <Tooltip title={"Datový sklad"}>*/}
                        {/*            <Link to={`${APP_PREFIX_PATH}/file-manager`}>*/}
                        {/*                <Button type={"link"} style={{width: 64, height: 64}}>*/}
                        {/*                    <FolderOpenOutlined style={{fontSize: 24}}/>*/}
                        {/*                </Button>*/}
                        {/*            </Link>*/}
                        {/*        </Tooltip>*/}
                        {/*    </Col>*/}
                        {/*    <Col span={8}>*/}
                        {/*        <Tooltip title={"Kalendář"}>*/}
                        {/*            <Link to={`${APP_PREFIX_PATH}/calendar`}>*/}
                        {/*                <Button type={"link"} style={{width: 64, height: 64}}>*/}
                        {/*                    <CalendarOutlined style={{fontSize: 24}}/>*/}
                        {/*                </Button>*/}
                        {/*            </Link>*/}
                        {/*        </Tooltip>*/}
                        {/*    </Col>*/}
                        {/*    <Col span={8}>*/}
                        {/*        <Tooltip title={"Import"}>*/}
                        {/*            <Link to={`${APP_PREFIX_PATH}/import`}>*/}
                        {/*                <Button type={"link"} style={{width: 64, height: 64}}>*/}
                        {/*                    <ImportOutlined style={{fontSize: 24}}/>*/}
                        {/*                </Button>*/}
                        {/*            </Link>*/}
                        {/*        </Tooltip>*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}
                    </div>
                )} trigger={["click"]}>
                    <div>
                        <AppstoreOutlined className="nav-icon mx-auto"/>
                    </div>
                </Dropdown>
            </Tooltip>
        )
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    const {user} = state.setup
    return {
        user
    }
}


const mapDispatchToProps = (dispatch: any) => {
    return {
        reLoad: () => dispatch(reLoad())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavConfiguration)
