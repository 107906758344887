import React, {RefObject} from "react"
import {Button, Card, Checkbox, Col, Drawer, Form, FormInstance, Input, Row, Select, Tooltip} from "antd";
import {
    CaretDownOutlined,
    CaretUpOutlined,
    DeleteOutlined,
    EditOutlined,
    PlusOutlined,
    SaveOutlined,
} from "@ant-design/icons";
import {IViewSettingsProps} from "../ViewSettings";
import arrayMove from "array-move";
import ICustomFiltersSettings, {
    ICustomFilterItemSettings
} from "../../../../model/interface/dataStorage/view/settings/ICustomFiltersSettings";
import FilterTreeBuilder from "./customFilters/FilterTreeBuilder";
import IconPicker from "../../../shared/IconPicker";
import IconBuilder from "../../../../utils/IconBuilder";
import IContentType from "../../../../model/interface/dataStorage/IContentType";
import {connect, RootStateOrAny} from "react-redux";
import selectors from "../../../../redux/selectors";
import DragSortList from "../../../shared/list/DragsortList";

interface IState {
    filters: ICustomFiltersSettings
    formRef: RefObject<FormInstance>
    filter?: ICustomFilterItemSettings
    currentIndex?: number
    currentContentType: string
}

interface IProps extends IViewSettingsProps {
    findContentTypeByClassName: (name: string) => IContentType
    findContentTypeByUuid: (uuid: string) => IContentType
}

class CustomFiltersSettings extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            filters: props.settings?.customFilters ? {...props.settings.customFilters} : {},
            formRef: React.createRef(),
            currentContentType: props.findContentTypeByUuid(props.view.contentTypes[0]).fullClassName
        }
    }

    onDefault = (filterItem: ICustomFilterItemSettings, index: number, groupName: string) => {
        const {currentContentType, filters} = this.state
        const filterItems = [...filters[currentContentType]]
        const ordered: ICustomFilterItemSettings[] = []
        Object.values(this.getGroupFilters(filterItems)).forEach(group => {
            group.items.forEach((item, itemIndex) => {
                if (groupName === group.name) {
                    ordered.push({...item, default: index === itemIndex ? !filterItem.default : false})
                } else {
                    ordered.push(item)
                }
            })
        })
        this.setState(state => ({filters: {...state.filters, [currentContentType]: ordered}}), this.onChange)
    }

    onDelete = (index: number, groupName: string) => {
        const {currentContentType} = this.state
        const filterItems = [...this.state.filters[currentContentType]]
        const ordered: ICustomFilterItemSettings[] = []
        Object.values(this.getGroupFilters(filterItems)).forEach(group => {
            let items = group.items
            items.forEach((item, itemIndex) => {
                if (group.name === groupName && index === itemIndex) {
                    return
                }
                ordered.push(item)
            })
        })
        this.setState(state => ({filters: {...state.filters, [currentContentType]: ordered}}), this.onChange)
    }

    onChange() {
        this.resetEditing()
        this.props.onChange({customFilters: this.state.filters}).then();
    }

    onEdit = (index: number, groupName?: string) => {
        const filterItems = this.state.filters[this.state.currentContentType] || []
        let finalIndex = index
        const ordered: ICustomFilterItemSettings[] = []
        Object.values(this.getGroupFilters(filterItems)).forEach(group => {
            let items = group.items
            items.forEach((item, itemIndex) => {
                const pushedIndex = ordered.push(item) - 1
                if (group.name === groupName && index === itemIndex) {
                    finalIndex = pushedIndex
                }
            })
        })
        this.setState({
            filter: ordered?.[finalIndex],
            currentIndex: finalIndex
        }, this.state.formRef.current?.resetFields)
    }

    resetEditing() {
        this.setState({filter: undefined, currentIndex: undefined}, this.state.formRef.current?.resetFields)
    }

    onAdd = () => {
        this.state.formRef.current?.validateFields().then(values => {
            let {filters, currentIndex, currentContentType} = this.state
            if (!filters[currentContentType]) {
                this.setState(state => ({
                    filters: {
                        ...state.filters,
                        [currentContentType]: [{...values}]
                    }
                }), this.onChange)
            } else {
                if (currentIndex !== undefined) {
                    filters[currentContentType][currentIndex] = {...values}
                } else {
                    filters[currentContentType].push(values)
                }
                this.setState(state => ({
                    filters: {...state.filters, [currentContentType]: [...filters[currentContentType]]}
                }), this.onChange)
            }
        })
    };

    onSortEnd = ({oldIndex, newIndex}: { oldIndex: number, newIndex: number }, groupName: string): void => {
        let {currentContentType} = this.state
        const grouped = this.getGroupFilters(this.state.filters[currentContentType])
        const ordered: ICustomFilterItemSettings[] = []
        Object.values(grouped).forEach(group => {
            let items = group.items
            if (group.name === groupName) {
                items = arrayMove(group.items, oldIndex, newIndex)
            }
            items.forEach(item => {
                ordered.push(item)
            })
        })
        this.setState(state => ({filters: {...state.filters, [currentContentType]: ordered}}), this.onChange)
    }

    onSortGroupEnd = ({oldIndex, newIndex}: { oldIndex: number, newIndex: number }): void => {
        let {currentContentType} = this.state
        const grouped = this.getGroupFilters(this.state.filters[currentContentType])
        const groupOrdered = arrayMove(Object.values(grouped), oldIndex, newIndex)
        const ordered: ICustomFilterItemSettings[] = []
        groupOrdered.forEach(group => {
            group.items.forEach(item => {
                ordered.push(item)
            })
        })
        this.setState(state => ({filters: {...state.filters, [currentContentType]: ordered}}), this.onChange)
    }

    onContentTypeChange = (name: string) => {
        this.setState({currentContentType: name}, this.resetEditing)
    }

    getContentType() {
        const {findContentTypeByClassName} = this.props
        const {currentContentType} = this.state
        return findContentTypeByClassName(currentContentType)
    }

    render() {
        const {filters, formRef, filter, currentContentType, currentIndex} = this.state
        const {view, history, match, findContentTypeByUuid} = this.props
        const contentTypeFilters = filters[currentContentType] || []
        const groupedFilters = this.getGroupFilters(contentTypeFilters);
        return (
            <div>
                {view.contentTypes.length > 1 && (
                    <Form.Item label={'Zvolte typ obsahu'}>
                        <Select value={currentContentType} onChange={this.onContentTypeChange}>
                            {view.contentTypes.map(uuid => {
                                const listContentType = findContentTypeByUuid(uuid)
                                return currentContentType && (
                                    <Select.Option value={listContentType.fullClassName} key={uuid}>
                                        {listContentType.label}
                                    </Select.Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                )}
                <DragSortList lockAxis={'y'} item={{
                    style: {zIndex: 1001},
                    render: (group: { name: string, items: ICustomFilterItemSettings[] }, filterIndex, handle) => <div
                        style={{zIndex: 1001}} key={filterIndex}>
                        <Card size={"small"} title={group.name} extra={handle}>
                            <DragSortList lockAxis={'y'} item={{
                                style: {zIndex: 1001},
                                render: (filter: ICustomFilterItemSettings, filterIndex, handle) => <Row
                                    justify={"space-between"} align={"middle"}
                                    className={'mb-2 border p-1 shadow-sm'}
                                    style={{zIndex: 1001}} key={filterIndex}>
                                    {handle}
                                    <Button size={"small"} icon={filter.icon && IconBuilder(filter.icon)}>
                                        {filter.label}
                                    </Button>
                                    <Row align={"middle"} justify={"end"}>
                                        <Button size={"small"} type={"link"} icon={<EditOutlined/>}
                                                onClick={() => this.onEdit(filterIndex, group.name)}/>
                                        <Button size={"small"} type={"link"} danger icon={<DeleteOutlined/>}
                                                onClick={() => this.onDelete(filterIndex, group.name)}/>
                                        <Tooltip title={'Výchozí aktivní'} className={'mx-2'}>
                                            <Checkbox checked={filter.default}
                                                      onChange={() => this.onDefault(filter, filterIndex, group.name)}/>
                                        </Tooltip>
                                    </Row>
                                </Row>
                            }} children={group.items} handle={{
                                render: () => <div className={'d-inline-block'}>
                                    <div className={"d-flex flex-column pl-2 pr-2"} style={{cursor: "move"}}>
                                        <CaretUpOutlined/>
                                        <CaretDownOutlined/>
                                    </div>
                                </div>
                            }} onSortEnd={(filters) => this.onSortEnd(filters, group.name)}/>
                        </Card>
                    </div>
                }} children={Object.entries(groupedFilters).map(([_, group]) => group)} handle={{
                    render: () => <div className={'d-inline-block'}>
                        <div className={"d-flex flex-column pl-2 pr-2"} style={{cursor: "move"}}>
                            <CaretUpOutlined/>
                            <CaretDownOutlined/>
                        </div>
                    </div>
                }} onSortEnd={groups => this.onSortGroupEnd(groups)}/>

                <Row justify={"center"} className={'mt-3'}>
                    <Tooltip mouseEnterDelay={0.5} title={'Přidat'}>
                        <Button shape={'circle'} onClick={() => this.onEdit(contentTypeFilters.length)}
                                icon={<PlusOutlined/>}>
                        </Button>
                    </Tooltip>
                </Row>
                <Drawer placement="right"
                        title={currentIndex === contentTypeFilters.length ? 'Vytvořit filter' : 'Upravit filter'}
                        visible={currentIndex !== undefined} getContainer={false}
                        onClose={() => this.resetEditing()}
                        style={{position: 'absolute', zIndex: 1}}
                        contentWrapperStyle={{width: 600, maxWidth: '100%'}} width={'auto'}>
                    <Form initialValues={{...filter, filters: filter?.filters || {}}}
                          layout={"vertical"}
                          ref={formRef}>
                        <Row gutter={12}>
                            <Col>
                                <Form.Item name={'label'} label={'Titulek'}
                                           rules={[{required: true, message: 'Pole je povinné'}]}>
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item name={'icon'} label={'Ikona'}>
                                    <IconPicker/>
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item name={'group'} label={'Skupina'}>
                                    <Input/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item name={'filters'} label={'Filters'} rules={[{required: true}]}>
                            <FilterTreeBuilder contentTypeFullClassName={currentContentType} history={history}
                                               match={match}/>
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" onClick={() => this.onAdd()} icon={<SaveOutlined/>}>
                                Uložit
                            </Button>
                        </Form.Item>
                    </Form>
                </Drawer>
            </div>
        )
    }

    private getGroupFilters(contentTypeFilters: ICustomFilterItemSettings[]) {
        const groupedFilters: {
            [name: string]: { items: ICustomFilterItemSettings[], name: string }
        } = {}
        contentTypeFilters.forEach(filter => {
            const name = typeof filter.group === 'number' ? filter.group.toString() : (filter.group || '_default')
            const groupIndex = name + +'_group'
            if (!groupedFilters.hasOwnProperty(groupIndex)) {
                groupedFilters[groupIndex] = {
                    items: [],
                    name
                }
            }
            groupedFilters[groupIndex].items.push(filter)
        })

        return groupedFilters;
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    return {
        findContentTypeByClassName: (name: string) => selectors.contentTypes.findOneBy(state, 'fullClassName', name),
        findContentTypeByUuid: (uuid: string) => selectors.contentTypes.findOneBy(state, 'uuid', uuid)
    }
}

export default connect(mapStateToProps)(CustomFiltersSettings)