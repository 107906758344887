import React from "react"
import {Button, Result} from "antd";
import LocaleText from "../settings/dictionary/LocaleText";
import {Link} from "react-router-dom";
import {HomeOutlined} from "@ant-design/icons";

interface IState {

}

interface IProps {
}

class Error404 extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <Result
                status="404"
                title="404"
                subTitle={<LocaleText code={'error.not-found'}
                                      fallback={'Omlouváme se, stránka, kterou jste navštívili, neexistuje.'}/>}
                extra={<Link to={'/app'}>
                    <Button icon={<HomeOutlined/>} type="primary">
                        <div className={'ml-2 d-inline'}>
                            <LocaleText code={'error.back-home'} fallback={'vrátit zpět domovskou stránku'}/>
                        </div>
                    </Button>
                </Link>}
            />
        )
    }
}

export default Error404