import React from "react";
import {Col, Tooltip} from "antd";
import {connect} from "react-redux";
import {CheckOutlined, CloseOutlined, ThunderboltOutlined} from "@ant-design/icons";
import INotification, {STATES} from "model/interface/ui/INotification"
import {changeState} from "../../../../redux/actions/Notifications";
import Button, {ButtonSizes} from "../../../shared/button/Button";
import IAction from "../../../../model/interface/ui/notification-bridge/IAction";
import ActionsService from "../../../../model/service/notification/ActionsService";
import IconBuilder from "../../../../utils/IconBuilder";

interface IState {
    changingState?: number,
    actionsRunning: string[],
}

interface IProps {
    notification: INotification
    size?: ButtonSizes
    changeState: (notification: INotification, state: number) => void,
}

class NotificationActions extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props)
        this.state = {
            actionsRunning: []
        }
    }

    changeState(notification: INotification, state: number, e: React.MouseEvent<HTMLElement, MouseEvent>) {
        this.setState({changingState: state})
        this.props.changeState(notification, state)
        e.stopPropagation()
        e.preventDefault()
        return false
    }

    executeAction(action: IAction, notification: INotification, e: React.MouseEvent<HTMLElement, MouseEvent>) {
        this.setState(state => ({actionsRunning: [...state.actionsRunning, action.uuid]}))
        ActionsService.execute(action, notification).then(not => {
            this.setState(state => ({actionsRunning: [...state.actionsRunning.filter(a => a !== action.uuid)]}))
        })
        e.stopPropagation()
        e.preventDefault()
        return false
    }

    componentDidUpdate(prevProps: IProps) {
        if (prevProps.notification.state === STATES.STATE_LOADING && this.props.notification.state !== STATES.STATE_LOADING){
            if (document.activeElement instanceof HTMLElement) {
                document.activeElement.blur();
            }
            this.setState({changingState: undefined})
        }
    }

    render() {
        const {notification, size} = this.props
        const {changingState, actionsRunning} = this.state
        const loading = notification.state === STATES.STATE_LOADING

        return <>
        {notification.bridge?.actions.filter(a => a.button).map(action => (
            <Tooltip title={action.buttonTooltip}>
                <Button size={size} icon={action.buttonIcon ? IconBuilder(action.buttonIcon) : <ThunderboltOutlined/>}
                        loading={actionsRunning.includes(action.uuid)}
                        type={action.buttonColor}
                        onClick={e => this.executeAction(action, notification, e)}/>
            </Tooltip>
        ))
        }
            <Col>
                {notification.state === STATES.STATE_VISITED || changingState === STATES.STATE_NEW ? (
                    <Tooltip title={"Znovu označit jako nepřečtené"}>
                        <Button size={size} icon={<CheckOutlined/>}
                                loading={loading && changingState === STATES.STATE_NEW}
                                onClick={e => this.changeState(notification, STATES.STATE_NEW, e)}/>
                    </Tooltip>
                ) : (
                    <Tooltip title={"Označit jako přečtené"}>
                        <Button size={size} type={"success"} icon={<CheckOutlined/>}
                                loading={loading && changingState === STATES.STATE_VISITED}
                                onClick={e => this.changeState(notification, STATES.STATE_VISITED, e)}/>
                    </Tooltip>
                )}
            </Col>
            <Col>
                <Tooltip title={"Vymazat upozornění"}>
                    <Button size={size} type={"danger"} icon={<CloseOutlined/>}
                            loading={loading && changingState === STATES.STATE_DELETED}
                            onClick={e => this.changeState(notification, STATES.STATE_DELETED, e)}/>
                </Tooltip>
            </Col>
        </>
    }
}

const mapStateToProps = () => {
    return {}
}

const mapDispatchToProps = {
    changeState
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationActions)
