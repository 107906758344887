import React from "react";
import IFieldOptions from "../../../../../../model/interface/form/elementOptions/IFieldOptions";
import FormFieldType from "./FormFieldType";
import LocaleProvider from "../../../../../../i18n/LocaleProvider";
import DatePicker from "components/shared/input/DatePicker";
import TimePicker from "components/shared/input/TimePicker";

class FormFieldDate {
    public static render = (options: IFieldOptions) => {
        const props = {
            style: options.style,
            placeholder: options.placeholder,
            allowClear: options.showClear,
            disabled: options.disabled,
            value: options.value,
            format: '' || options.datePickerFormat,
            confirm: options.datePickerConfirm
        };
        switch (options.datePicker) {
            case FormFieldType.FIELD_DATE_DATE_PICKER:
                return <DatePicker locale={LocaleProvider.getPicker()} {...props} />
            case FormFieldType.FIELD_DATE_DATE_TIME_PICKER:
                return <DatePicker showTime={{format: options.datePickerTimeFormat}} locale={LocaleProvider.getPicker()} {...props} />
            case FormFieldType.FIELD_DATE_TIME_PICKER:
                return <TimePicker locale={LocaleProvider.getPicker()} {...props} format={options.datePickerTimeFormat} />
            default:
                return <DatePicker mode={options.datePicker} locale={LocaleProvider.getPicker()} {...props} />
        }
    }
}

export default FormFieldDate