import CommentList from "../../../../../comment/CommentList";
import Utils from "../../../../../../../utils";
import React from "react";
import ICommentOptions from "../../../../../../../model/interface/widget/option/ICommentOptions";
import ICardWidgetProps from "../../../../../../../model/interface/dataStorage/card/ICardWidgetProps";
import {connect, RootStateOrAny} from "react-redux";
import selectors from "../../../../../../../redux/selectors";
import IRepositoryService from "../../../../../../../model/interface/IRepositoryService";
import {Button, Empty} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import IContentType from "../../../../../../../model/interface/dataStorage/IContentType";


interface IProps extends ICardWidgetProps<ICommentOptions> {
    findService: (contentType: IContentType) => IRepositoryService,
}

interface IState {
    thread: number | null
    saving: boolean

}

class CommentWidget extends React.Component<IProps, IState> {


    constructor(props: Readonly<IProps> | IProps) {
        super(props);
        this.state = {
            thread: null,
            saving: false
        }
    }

    onCreate = () => {
        const {functions, recordId, findService} = this.props
        const contentType = functions.getContentType()
        if (contentType && recordId) {
            this.setState({saving: true})
            const service = findService(contentType)
            service.resourceUpdate(recordId, {id: recordId, thread: {uuid: Utils.uuid()}})
                .then((resource) => {
                    this.setState({thread: resource['thread'].id, saving: false})
                })
        }

    }

    render() {
        const {options, value, editor, reload} = this.props
        const {thread, saving} = this.state
        const {wysiwyg, attachments, replyAble, editable, wysiwygPackage, readonly, limit} = options
        const threadId = !value ? thread : value

        return (
            <div>
                {(threadId || editor) && !reload ? (
                    <CommentList
                        thread={threadId}
                        wysiwyg={Utils.toBoolean(wysiwyg)}
                        attachments={Utils.toBoolean(attachments)}
                        replyAble={Utils.toBoolean(replyAble)}
                        editable={Utils.toBoolean(editable)}
                        wysiwygPackage={wysiwygPackage}
                        limit={limit}
                        readonly={Utils.toBoolean(readonly)}
                    />
                ) : (
                    <Empty className={'font-size-sm'} image={Empty.PRESENTED_IMAGE_SIMPLE} description={'Zatím nejsou přidané žádné komentáře.'}>
                        <Button loading={saving} onClick={this.onCreate} icon={<PlusOutlined/>}>Přidat první komentář.</Button>
                    </Empty>
                )}

            </div>
        );
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    return {
        findService: (contentType: IContentType) => selectors.services.findOneByContentType(state, contentType),
    }
}

export default connect(mapStateToProps)(CommentWidget)