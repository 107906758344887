import RestService from "model/service/dataStorage/RestService";
import IClaim from "../../../interface/company/workload/IClaim";
import IRestServiceCollectionResponse from "../../../interface/api/IRestServiceCollectionResponse";
import IRestServiceOptions from "../../../interface/api/IRestServiceOptions";
import {API_FILTER_TYPE} from "../../../constants/ApiConstant";
import {DATE_FORMAT_YYYY_MM_DD_HH_mm_ss} from "../../../constants/DateConstant";
import {
    COMPANY_WORKLOAD_FUND_PERIOD_MONTH,
    COMPANY_WORKLOAD_FUND_PERIOD_YEAR
} from "../../../interface/company/workload/IFund";
import {Moment} from "moment";
import {MomentBuilder} from "../../../../utils/MomentBuilder";

interface IRestClaimsServiceCollectionResponse extends IRestServiceCollectionResponse {
    results: Array<IClaim>
}

interface IRestClaimsService {
    collectionList(options?: IRestServiceOptions): Promise<IRestClaimsServiceCollectionResponse>,

    collectionMonthList(month: Moment, options?: IRestServiceOptions): Promise<IRestClaimsServiceCollectionResponse>,

    collectionCreate(data: any): Promise<IClaim>,

    resourceRetrieve(id: number | string): Promise<IClaim>,

    resourceUpdate(id: number | string, data: any): Promise<IClaim>,

    resourceDelete(id: number | string): Promise<void>,

    populate(): Promise<number>,

    populatePreview(obligation: number | string): Promise<IRestClaimsServiceCollectionResponse>

    getPeriodTitle(claim: IClaim): string
}

const ClaimsService_COLLECTION = 'company/workload/claims'
const ClaimsService_POPULATE = 'company/workload/claims-populate'
const ClaimsService_PREVIEW = 'company/workload/claims-build-preview'

const ClaimsService: IRestClaimsService = {
    collectionList: function (options?) {
        return RestService.collectionList(ClaimsService_COLLECTION, options as unknown as IRestServiceOptions) as Promise<IRestClaimsServiceCollectionResponse>
    },
    collectionMonthList: function (month, options?) {
        return RestService.collectionList(ClaimsService_COLLECTION, {
            ...options,
            filters: {
                or: {
                    type: API_FILTER_TYPE.OR, children: {
                        year: {
                            type: API_FILTER_TYPE.AND, children: {
                                from: {
                                    type: API_FILTER_TYPE.GREATER_OR_EQUAL,
                                    field: 'validFrom',
                                    value: month.clone().startOf('year').format(DATE_FORMAT_YYYY_MM_DD_HH_mm_ss)
                                },
                                to: {
                                    type: API_FILTER_TYPE.LESSER_OR_EQUAL,
                                    field: 'validUntil',
                                    value: month.clone().endOf('year').format(DATE_FORMAT_YYYY_MM_DD_HH_mm_ss)
                                },
                                period: {
                                    type: API_FILTER_TYPE.EQUAL,
                                    field: 'fund.period',
                                    value: COMPANY_WORKLOAD_FUND_PERIOD_YEAR
                                }
                            }
                        },
                        month: {
                            type: API_FILTER_TYPE.AND, children: {
                                from: {
                                    type: API_FILTER_TYPE.GREATER_OR_EQUAL,
                                    field: 'validFrom',
                                    value: month.clone().startOf('month').format(DATE_FORMAT_YYYY_MM_DD_HH_mm_ss)
                                },
                                to: {
                                    type: API_FILTER_TYPE.LESSER_OR_EQUAL,
                                    field: 'validUntil',
                                    value: month.clone().endOf('month').format(DATE_FORMAT_YYYY_MM_DD_HH_mm_ss)
                                },
                                period: {
                                    type: API_FILTER_TYPE.EQUAL,
                                    field: 'fund.period',
                                    value: COMPANY_WORKLOAD_FUND_PERIOD_MONTH
                                }
                            }
                        }
                    }
                },
                ...options?.filters
            }
        }) as Promise<IRestClaimsServiceCollectionResponse>
    },
    collectionCreate: function (data) {
        return RestService.collectionCreate(ClaimsService_COLLECTION, data) as Promise<IClaim>
    },
    resourceRetrieve: function (id) {
        return RestService.resourceRetrieve(ClaimsService_COLLECTION, id) as Promise<IClaim>
    },
    resourceDelete: function (id) {
        return RestService.resourceDelete(ClaimsService_COLLECTION, id)
    },
    resourceUpdate: function (id, data) {
        return RestService.resourceUpdate(ClaimsService_COLLECTION, id, data) as Promise<IClaim>
    },
    populate: function () {
        return RestService.collectionList(ClaimsService_POPULATE).then(response => response.totalCount)
    },
    populatePreview: function (obligation) {
        return RestService.collectionList(ClaimsService_PREVIEW + '/' + obligation) as Promise<IRestClaimsServiceCollectionResponse>
    },
    getPeriodTitle(claim: IClaim): string {
       return  {
            [COMPANY_WORKLOAD_FUND_PERIOD_YEAR]: MomentBuilder.build(claim.validFrom).format('YYYY'),
            [COMPANY_WORKLOAD_FUND_PERIOD_MONTH]: MomentBuilder.build(claim.validFrom).format('MMM YYYY')
        }[claim.fund.period] || ''
    }
}

export default ClaimsService