import IRestServiceCollectionResponse from "../../interface/api/IRestServiceCollectionResponse";
import IRoute from "model/interface/dataStorage/IRoute";
import {BarChartOutlined, FileOutlined, ProfileOutlined, SyncOutlined, ThunderboltOutlined} from "@ant-design/icons";
import React from "react";
import IRouteType from "model/interface/dataStorage/IRouteType";
import RestService from "model/service/dataStorage/RestService";
import IRestServiceOptions from "model/interface/api/IRestServiceOptions";
import IRepositoryService from "../../interface/IRepositoryService";
import IField from "../../interface/dataStorage/IField";
import IPresenter from "../../interface/dataStorage/IPresenter";
import RouteLabel from "../../../components/app/configuration/route/RouteLabel";
import IRestServiceChoiceListResponse from "../../interface/api/IRestServiceChoiceListResponse";
import {RouteParametersFromUrl} from "../../../redux/selectors/Routes";

interface IRestRoutesServiceCollectionResponse extends IRestServiceCollectionResponse {
    results: Array<IRoute>
}

interface IRestRoutesService extends IRepositoryService {
    collectionList(options?: IRestServiceOptions): Promise<IRestRoutesServiceCollectionResponse>,

    collectionCreate(data: any): Promise<IRoute>,

    resourceRetrieve(id: number | string): Promise<IRoute>,

    resourceUpdate(id: number | string, data: any): Promise<IRoute>,

    resourceDelete(id: number | string): Promise<void>,

    collectionList(): Promise<IRestServiceCollectionResponse>,

    getRoutesIndex(): Promise<{ [url: string]: IRoute }>,

    getRouteTypes(): IRouteType[]

    getEmpty(): IRoute,

    extractParameters(route: IRoute, url: string): RouteParametersFromUrl | null
}

const RoutesService_COLLECTION = 'routes'
const RoutesService_CHOICES = 'routes-choices/'

const RoutesService: IRestRoutesService = {
    collectionList: function (options?: IRestServiceOptions) {
        return RestService.collectionList(RoutesService_COLLECTION, options as unknown as IRestServiceOptions) as Promise<IRestRoutesServiceCollectionResponse>
    },
    collectionCreate: function (data) {
        return RestService.collectionCreate(RoutesService_COLLECTION, data) as Promise<IRoute>
    },
    resourceRetrieve: function (id) {
        return RestService.resourceRetrieve(RoutesService_COLLECTION, id) as Promise<IRoute>
    },
    resourceDelete: function (id) {
        return RestService.resourceDelete(RoutesService_COLLECTION, id)
    },
    resourceUpdate: function (id, data) {
        return RestService.resourceUpdate(RoutesService_COLLECTION, id, data) as Promise<IRoute>
    },
    extractParameters: (route, url) => {
        const regExp = route.url.split('/')
            .map((part: string) => part.charAt(0) === ':' ? '(?<' + part.slice(1) + '>[0-9a-zA-Z]+)' : part).join('/') + '$'
        const parameters = url.match(regExp)
        if (parameters && route.id) {
            return {
                id: route.id,
                parameters: {...parameters.groups}
            }
        }
        return null
    },
    getRoutesIndex: function () {
        return this.collectionList().then(response => {
            let output: { [url: string]: IRoute } = {}
            response.results.forEach(result => {
                const route = result as unknown as IRoute
                output[route.url] = route
            })
            return output
        })
    },
    getRouteTypes: function () {
        return [
            {
                icon: <FileOutlined/>,
                type: 'view',
                label: 'Pohled'
            },
            {
                icon: <ThunderboltOutlined/>,
                type: 'action',
                label: 'Akce'
            },
            {
                icon: <ProfileOutlined/>,
                type: 'card',
                label: 'Karta'
            },
            {
                icon: <BarChartOutlined/>,
                type: 'report',
                label: 'Report'
            },
            {
                icon: <SyncOutlined/>,
                type: 'redirect',
                label: 'Přesměrování'
            }
        ]
    },
    getEmpty() {
        return {
            type: '',
            url: '',
        } as IRoute
    },
    getRepresentativeField(): IField {
        return this.getFields()[0];
    },
    getDefaultPresenter(): IPresenter {
        return {
            type: 'callback',
            name: 'callback',
            label: 'URL',
            options: {
                callback: (route: IRoute) => {
                    if (!route) {
                        return <span className={"text-muted"}>N/A</span>
                    }
                    return <RouteLabel route={route}/>
                }
            }
        }
    },
    getFields(): IField[] {
        return [
            {
                uuid: '',
                name: 'url',
                mode: "scalar",
                type: 'string',
                targetEntity: null,
                options: [],
                weight: 1,
                contentTypeId: null,
                contentTypeName: false,
                locked: true,
                arguments: {}
            }
        ];
    },
    getPresenter(): IPresenter | null {
        return this.getDefaultPresenter()
    },
    getPresenterList(): { label: string; value: string }[] {
        return [];
    },
    getRecordClassName(): string {
        return 'App\\Ui\\Entity\\Route'
    },
    getStringValue(item: IRoute): string {
        return item.url;
    },
    getTitle(): string {
        return "Cesta";
    },
    choiceList(presenterName: string, options?: IRestServiceOptions): Promise<IRestServiceChoiceListResponse> {
        return RestService.collectionList(RoutesService_CHOICES + presenterName, options) as unknown as Promise<IRestServiceChoiceListResponse>
    }
}

export default RoutesService