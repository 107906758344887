import React from "react"
import {IFilterInput} from "../IFilterBase";
import {API_FILTER_TYPE} from "../../../model/constants/ApiConstant";
import {Moment} from "moment";
import moment from "moment/moment";
import {PickerMode} from 'rc-picker/lib/interface';
import FormFieldCode from "../../../components/app/configuration/form/FormElement/formField/FormFieldCode";


interface IState {

}

interface IProps extends IFilterInput {

}

class Code extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {}
    }

    render() {
        const {field, item, onChange, selected} = this.props

        return (
            <>
                <FormFieldCode value={selected[0] ? selected[0].value + '' : undefined} onChange={result => result && onChange([{
                    value: result,
                    type: API_FILTER_TYPE.LIKE,
                    name: field.name
                }])} options={{label: '', type: '', ...field.options, ...item.options?.fieldOptions}}/>
            </>
        )
    }
}

export default Code