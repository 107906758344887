import IRestServiceFilter from "./IRestServiceFilter";
import IRestServiceFilterNested from "./IRestServiceFilterNested";

type FiltersType<T> = {
    [index in (number | string)]: T
}

export const isFilterNested = (filter: IRestServiceFilter|IRestServiceFilterNested): filter is IRestServiceFilterNested => {
    return 'children' in filter;
}

export default interface IRestServiceFilters<T = IRestServiceFilter|IRestServiceFilterNested> extends FiltersType<T> {

}