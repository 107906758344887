import React from "react"
import {Tree, Typography} from "antd";
import IField from "../../../../../model/interface/dataStorage/IField";
import ITreeMapping from "../../../../../model/interface/import/source/sourceMapping/ITreeMapping";
import {DataNode} from "antd/lib/tree";
import {ISourcePreviewProps} from "../SourcePreview";

type TreeNode = {} & DataNode

interface IStateSourceMapping {
    data: TreeNode[]
}

class SourcePreviewTree extends React.Component<ISourcePreviewProps, IStateSourceMapping> {

    constructor(props: ISourcePreviewProps) {
        super(props);
        this.state = {
            data: this.prepareTreeData(props.data, props.resource.type, props.resource.mapping as ITreeMapping[]),
        }
    }

    prepareTreeData(data: Array<any> | object, typeName: string, mapping: ITreeMapping[], key: string = '') {
        let treeData: DataNode[] = []
        if (Array.isArray(data)) {
            data.forEach((node, index) => {
                const newKey = key + index
                treeData.push({
                    key: newKey,
                    title: this.buildTitle(''),
                    children: this.prepareTreeData(node, typeName, mapping, newKey)
                })
            })
        } else if (typeof data === "object") {
            Object.entries(data).forEach(([fieldName, value]) => {
                const newKey = key + fieldName
                const field = typeName ? this.getField(typeName, fieldName) : null
                field && treeData.push({
                    key: newKey,
                    title: this.buildTitle(field.label || '', typeof value !== 'object' ? value : undefined),
                    children: this.prepareTreeData(value, field?.targetEntity || typeName, mapping, newKey)
                })
            })
        }

        return treeData
    }

    buildTitle(title: string | number, value?: string) {
        return <><Typography.Text strong>{title}</Typography.Text>{value && ': ' + value}</>
    }


    getFields(className: string): IField[] {
        return this.props.findServiceByClassName(className).getFields()
    }

    getField = (className: string, name?: string) => {
        return this.getFields(className).find(field => field.name === name)
    }

    render() {
        const {data} = this.state

        return (
            <>
                <Tree showLine={true} defaultExpandAll={true} treeData={data}/>
            </>
        )
    }
}

export default SourcePreviewTree