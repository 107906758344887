import WidgetGallery, {GroupItem} from "../../widget/WidgetGallery";
import IReport from "../../../../../model/interface/dataStorage/IReport";
import ReportWidgetType from "./ReportWidgetType";
import ReportWidgetList from "./ReportWidgetList";
import WidgetType from "../../widget/WidgetType";

interface IProps {
    report: IReport
    exists: (group: string) => boolean
}

class ReportWidgetGallery extends WidgetGallery<IProps> {

    getGroup(group: [string, GroupItem]) {
        return [
            ...group[1].widgets.map((widget, index) => this.getNodeOptionTag(widget, index)),
            ...group[0] === ReportWidgetType.GROUP_DYNAMIC ? [this.getCommentOption()] : []
        ];
    }

    getCommentOption() {
        const {report, exists} = this.props
        if (report.thread) {
            const widget = ReportWidgetList.getItemByType(WidgetType.COMMENT)
            return this.getNodeOptionTag(widget, 'comment-widget',
                () => this.onSelectItem(widget.type),
                exists(WidgetType.COMMENT)
            )
        }
        return <></>
    }
}

export default ReportWidgetGallery