import React, {Component} from 'react'
import {Spin, Typography} from 'antd';
// import UserView from './UserView';
// import AvatarStatus from 'components/shared-components/AvatarStatus';
// import userData from "assets/data/user-list.data.json";
import viewsService from "model/service/dataStorage/ViewsService";
import ViewTable from "../../../components/app/view/ViewTable";

const {Paragraph} = Typography

export class View extends Component {
    state = {
        loading: false
    }

    load() {
        this.setState((state) => {
            return {
                loading: true
            }
        })
        const {module, id} = this.props
        viewsService.resourceRetrieve(parseInt(module, 10), parseInt(id, 10)).then(({resource}) => {
            this.setState({
                resource,
                loading: false
            })
        })
    }

    componentDidMount() {
        this.load()
    }

    render() {
        const {id} = this.props
        const {resource, loading} = this.state
        let detail = ''
        if (resource) {
            switch (resource.type) {
                case("App\\DataStorage\\ViewType\\Table"):
                    detail = <ViewTable {...this.props} resource={resource}/>
                    break;
            }
        }
        return (
            <>
                <Spin spinning={loading}/>
                {detail}
            </>
        )
    }
}

export default View