import React from "react";
import {InputNumber, InputNumberProps} from "antd";
import IFieldOptions from "../../../../../../model/interface/form/elementOptions/IFieldOptions";
import Utils from "../../../../../../utils";

class FormFieldNumber {
    public static render = (options: IFieldOptions & InputNumberProps) => {
        const separator = Utils.localeDecimalSeparator() + ''
        const thousandSep = separator === '.' ? ',' : new RegExp('\\s', 'g')
        return (
            <InputNumber placeholder={options.placeholder}
                         formatter={value => value ? parseFloat(value + '')
                             .toLocaleString(undefined, {maximumFractionDigits: 20}) : ''}
                         parser={value => value!.replaceAll(thousandSep, '').replace(',', '.')}
                         decimalSeparator={separator}
                         value={options.value}
                         disabled={options.disabled}
                         min={options.min}/>
        )
    }
}

export default FormFieldNumber