import React from "react";
import {IContentTypeStepProps} from "./ContentTypeConfiguration";
import {Col, Row, Spin, Statistic} from "antd";
import StatisticsRecordsGrowthGraph from "./statistics/StatisticsRecordsGrowthGraph";
import {connect, RootStateOrAny} from "react-redux";
import IContentType from "../../../../model/interface/dataStorage/IContentType";
import selectors from "../../../../redux/selectors";
import IRepositoryService from "../../../../model/interface/IRepositoryService";
import DataStorageService from "../../../../model/service/dataStorage/DataStorageService";
import ContentTypesService from "../../../../model/service/dataStorage/ContentTypesService";
import IContentTypeStatistics from "../../../../model/interface/dataStorage/contentType/IContentTypeStatistics";
import moment from "moment";

interface IState {
    loading?: boolean
    data?: IContentTypeStatistics
}

interface IProps extends IContentTypeStepProps {
    findOneByContentType: (contentType: IContentType) => IRepositoryService
}

class ContentTypeStatistics extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        this.load()
    }

    load = () => {
        const {resource} = this.props
        this.setState({loading: true})
        ContentTypesService.statistics(resource.id, {interval: 'month', limit: 12})
            .then((data) => {
                this.setState({
                    data,
                    loading: false
                })
            })
    }

    getService = (): DataStorageService => {
        return this.props.findOneByContentType(this.props.resource) as DataStorageService
    }

    getFormattedDate(date: string) {
        return date ? moment(date).format('LL') : '-';
    }

    render() {
        const {loading, data} = this.state
        const {resource} = this.props

        return (
            <>
                <Spin className={'d-block'} spinning={loading}>
                    {data && (
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <Statistic title="Počet záznamů" value={data.totalCount}/>
                            </Col>
                            <Col span={12}>
                                <Statistic title="Počet záznamů v tabulce historie" value={data.totalHistoryCount}/>
                            </Col>
                            <Col span={12}>
                                <Statistic title="Datum prvního záznamu"
                                           value={this.getFormattedDate(data.firstCreated)}/>
                            </Col>
                            <Col span={12}>
                                <Statistic title="Datum posledního záznamu"
                                           value={this.getFormattedDate(data.lastCreated)}/>
                            </Col>
                            <Col span={24}>
                                <StatisticsRecordsGrowthGraph data={data.recordsIncreaseData} contentType={resource}/>
                            </Col>
                        </Row>
                    )}
                </Spin>
            </>
        )
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    return {
        findOneByContentType: (contentType: IContentType) => selectors.services.findOneByContentType(state, contentType)
    }
}

export default connect(mapStateToProps)(ContentTypeStatistics)