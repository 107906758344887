import React from 'react'
import Action from "views/app-views/dynamic-router/Action";
import {connect, RootStateOrAny} from "react-redux";
import selectors from "redux/selectors";
import IRoute from "../../../model/interface/dataStorage/IRoute";
import Report from "../report/Report";
import Card from "../configuration/content-type/card/Card";
import IReport from "../../../model/interface/dataStorage/IReport";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {RouteParametersFromUrl} from "../../../redux/selectors/Routes";
import IContentType from "../../../model/interface/dataStorage/IContentType";
import _ from "underscore";
import View from "../view/View";
import {IActionResult} from "../../../model/service/dataStorage/ActionsService";
import RoutesService from "../../../model/service/ui/RoutesService";
import Background from "../ui/Background";
import Error404 from "../error/Error404";

interface IProps extends RouteComponentProps {
    path: string
    findContentType: (uuid: string) => IContentType
    isModal?: boolean
    onActionFinish?: (result?: IActionResult) => void | any
    findOneByUrl: (url: string) => IRoute | null
    result?: IActionResult
}

export interface IRoutesRouterDisplay {
    routeParams?: RouteParametersFromUrl
}

interface IState {
    report?: IReport
}

class RoutesRouter extends React.Component<IProps, IState> {

    getRoute = () => {
        const {findOneByUrl, path} = this.props
        return findOneByUrl(path)
    }

    render() {
        const {findContentType, isModal, path} = this.props
        const route = this.getRoute()
        const contentType = route?.contentType ? findContentType(route.contentType) : null

        const props: IProps & IRoutesRouterDisplay = {
            ...this.props,
            routeParams: route && RoutesService.extractParameters(route, path)
        }

        let content = <Error404/>
        if (route) {
            switch (route.type) {
                case('view'):
                    if (!route.view) {
                        throw new Error('View on route:' + route.id + ' with type "view" is missing')
                    }
                    content = <View standalone={true} uuid={route.view} {...props} />
                    break;
                case('action'):
                    const action = contentType && _.findWhere(contentType.actions, {uuid: route.action})
                    if (!action) {
                        throw new Error('Action on route:' + route.id + ' with type "action" is missing')
                    }
                    content = <Action action={action} isModal={isModal} {...props} />
                    break;
                case('report'):
                    if (route.reports?.[0]) {
                        content = <Report report={route.reports[0]} {...props} />
                    }
                    break;
                case('card'):
                    const cards = contentType && contentType.cards.filter(c => route.cards?.includes(c.uuid))
                    if (!cards?.length) {
                        throw new Error('Card on route:' + route.id + ' with type "card" is missing')
                    }
                    content = <Card standAlone={!isModal} card={cards[0]} cards={cards} {...props} />
                    break;
                default:
                    content = <Error404/>
            }
        }

        return (
            <Background className={'h-100' + (isModal ? '' : ' content-padding')} background={route?.background}>
                {route && (
                    <>
                        {content}
                    </>
                )}
            </Background>
        )
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    return {
        findContentType: (uuid: string) => selectors.contentTypes.findOneBy(state, 'uuid', uuid),
        findOneByUrl: (url: string) => selectors.routes.findOneByUrlWithIdentifier(state, url)
    }
}

export default connect(mapStateToProps)(withRouter(RoutesRouter))