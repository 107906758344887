import React, {RefObject} from "react";
import {FormInstance, Modal, Row, Switch} from "antd";
import IAction from "../../../../../model/interface/dataStorage/IAction";
import IActionScript from "../../../../../model/interface/dataStorage/IActionScript";
import INotification from "../../../../../model/interface/dataStorage/INotification";

interface IScriptActionArgument {
    name: string,
    type: string,
    value: any
}

interface IProps {
    resource: IAction|null,
    onSave: (action: IAction, close: boolean) => void,
    onCancel: () => void,
    notifications: INotification[]
}

interface IState {
    refForm: RefObject<FormInstance>,
    actionScript: IActionScript|null,
    argument: IScriptActionArgument,
    type: string
}

const newArgument = {
    name: '',
    type: 'string',
    value: null
}

class ContentTypeActionArguments extends React.Component<IProps, IState> {
    state = {
        refForm: React.createRef() as RefObject<FormInstance>,
        actionScript: null,
        argument: {...newArgument},
        type: newArgument.type
    }

    toggle(notification: INotification) {
        let action = this.props.resource!
        let notifications = action.notifications || []
        let index = notifications.indexOf(notification.uuid)
        if(index >= 0) {
            notifications.splice(index, 1)
        } else {
            notifications.push(notification.uuid)
        }
        action.notifications = notifications;
        this.props.onSave(action, false)
    }

    render() {
        const { resource, onCancel, notifications } = this.props
        const actionNotifications = resource?.notifications || []
        return (
            <Modal title={resource ? resource.label : 'Nová akce'} visible={!!resource} onCancel={onCancel} onOk={onCancel} style={{overflow: 'hidden'}} width={600}>
                {notifications.map((notification: INotification) => (
                    <Row className={'mb-3'} align={"middle"}>
                        <Switch className={'mr-2'}
                            checked={actionNotifications.indexOf(notification.uuid) >= 0}
                            onChange={() => this.toggle(notification)}
                        />
                        {notification.title}
                    </Row>
                ))}
            </Modal>
        )
    }
}

export default ContentTypeActionArguments