import {
    FileExcelOutlined,
    FileImageOutlined,
    FileOutlined,
    FilePdfOutlined,
    FileWordOutlined,
    FileZipOutlined
} from "@ant-design/icons";
import React from "react";

export const extensionToIcon = (extension: string, fontSize: number = 28) => {
    switch (extension) {
        case 'pdf':
            return <FilePdfOutlined style={{fontSize}}/>
        case 'jpg':
        case 'png':
        case 'gif':
            return <FileImageOutlined style={{fontSize}}/>
        case 'docx':
            return <FileWordOutlined style={{fontSize}}/>
        case 'xlsx':
            return <FileExcelOutlined style={{fontSize}}/>
        case 'zip' :
            return <FileZipOutlined style={{fontSize}}/>
        default:
            return <FileOutlined style={{fontSize}}/>
    }
}

export const extensionToLabel = (extension: string) => {
    switch (extension) {
        case "pdf":
            return "PDF dokument"
        case "jpg":
        case "gif":
        case "png":
            return "Obrázek " + extension
        default:
            return "Soubor";
    }
}