import React, {RefObject} from "react";
import {Button, Form, FormInstance, Input, Modal, notification, Switch, Table, Tooltip, TreeSelect, Upload} from "antd";
import {CheckOutlined, EditOutlined, FolderAddOutlined, InboxOutlined} from "@ant-design/icons";
import IDirectory from "../../../../model/interface/file/IDirectory";
import IFile from "../../../../model/interface/file/IFile";
import {UploadFile} from "antd/es/upload/interface";
import DirectoriesService from "../../../../model/service/file/DirectoriesService";
import Utils from "../../../../utils";
import FilesService from "../../../../model/service/file/FilesService";
import IVersion from "../../../../model/interface/file/IVersion";
import {extensionToIcon} from "../utils/Extension";
import {humanFileSize} from "../utils/FileSize";
import {ColumnProps} from "antd/es/table";

interface IStateFileEdit {
    loading: boolean,
    directoryTree: IDirectory | null
    formRef: RefObject<FormInstance>,
    file: IFile,
    fileUpload: UploadFile|null,
    saving: boolean,
    visible: boolean,
    showVersions: boolean
}

interface IPropsFileEdit {
    onUpdate: (file: IFile) => void,
    file: IFile
}

const {Dragger} = Upload;

class FileEdit extends React.Component<IPropsFileEdit, IStateFileEdit> {

    constructor(props: IPropsFileEdit) {
        super(props);
        this.state = {
            loading: false,
            directoryTree: null,
            formRef: React.createRef(),
            file: {...props.file},
            fileUpload: null,
            saving: false,
            visible: false,
            showVersions: false
        } as IStateFileEdit
    }


    showModal = () => {
        if (!this.state.directoryTree) {
            this.setState({loading: true})
            DirectoriesService.collectionListTree().then(response => {
                this.setState({loading: false, directoryTree: response.tree, visible: true})
            })
        } else {
            this.setState({visible: true})
        }
    }

    addFile = (fileUpload: UploadFile) => {
        this.setState({fileUpload});
        return false;
    }

    removeFile = () => {
        this.setState({fileUpload: null});
    }

    componentWillReceiveProps(props: IPropsFileEdit) {
        this.setState({file: props.file})
    }

    uploadFile = () => {
        this.setState({saving: true})
        this.state.formRef.current?.validateFields()
            .then(values => {
                let newVersion = !!this.state.fileUpload;
                let formData = values
                if (newVersion){
                    formData = Utils.convertArrayToFormData(values)
                    formData.append('files[]', this.state.fileUpload as any)
                }
                FilesService.resourceUpdate(this.state.file.uuid, formData, {fileUpload: newVersion}).then(file => {
                    this.setState({saving: false, visible: false, file})
                    notification.success({message: `File successfully updated!`})
                    this.props.onUpdate(file)
                })
            }).catch((error) => {
                console.log(error)
                this.setState({saving: true, visible: false})
        })
    }

    onCancel = () => {
        this.setState({visible: false, saving: false})
    }

    showVersions = () => {
        this.setState(prevState => ({
            showVersions: !prevState.showVersions
        }))
    }

    render() {
        const {loading, directoryTree, saving, visible, file, showVersions} = this.state

        const tableColumns = [
            {
                title: 'ID',
                dataIndex: 'id',
                render: (text: string, record: IVersion) =>  record.uuid === file.currentVersion.uuid ?
                    (<Tooltip title={'Current version'} ><div><CheckOutlined/> {text}</div></Tooltip>) : text
            },
            {
                title: 'Typ',
                dataIndex: 'extension'
            },
            {
                title: 'Velikost',
                dataIndex: 'size',
                render: (text: string, record: IVersion) => humanFileSize(record.size)
            },
            {
                title: 'Vyvoreno',
                dataIndex: 'createdAt',
                defaultSortOrder: "descend",
                sorter: (a: IVersion, b: IVersion) => Date.parse(a.createdAt) -  Date.parse(b.createdAt),
            },
            {
                title: 'Nahled',
                dataIndex: 'size',
                render: (text: string, record: IVersion) => record.thumbnailUrl ? (
                    <img alt={"file preview"} className={'w-100'}  src={record.thumbnailUrl}/>
                ) : (extensionToIcon(record.thumbnailUrl))
            } as ColumnProps<IVersion>
        ]
        return (
            <>
                <Tooltip title={"Upravit"}>
                    <Button onClick={() => this.showModal()} type="link" className="mr-2"
                            icon={<EditOutlined/>} loading={loading} size="small"/>
                </Tooltip>
                <Modal
                    title={'Upravit soubor'}
                    okText={'upload'}
                    onCancel={this.onCancel}
                    visible={visible}
                    destroyOnClose={true}
                    footer={[
                        <Button key="back" onClick={this.onCancel}>
                            Return
                        </Button>,
                        <Button key="submit" type="primary" loading={saving} onClick={this.uploadFile}>
                            Submit
                        </Button>
                    ]}
                >
                    <Form ref={this.state.formRef} initialValues={{...file, directory: file.directory.uuid}}>
                        <Dragger
                            name={'file'}
                            multiple={false}
                            listType={"picture"}
                            beforeUpload={this.addFile}
                            onRemove={this.removeFile}
                        >
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined/>
                            </p>
                            <p className="ant-upload-text">New file version</p>
                            <p className="ant-upload-hint">
                                Make sure you only upload sensitive personal data that we can store and later use against you!
                            </p>
                        </Dragger>
                        <br/>
                        <Form.Item name={'name'} label={"Nazev"}>
                            <Input />
                        </Form.Item>
                        {directoryTree && (
                            <Form.Item label={"Složka"} name={"directory"} className={'mb-0'}>
                                <TreeSelect
                                    style={{width: '100%'}}
                                    dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                                    treeData={[directoryTree]}
                                    placeholder="Please select"
                                    treeDefaultExpandAll
                                    suffixIcon={<FolderAddOutlined/>}
                                />
                            </Form.Item>
                        )}
                        <Form.Item name={'public'} label={"Public"} valuePropName="checked">
                            <Switch/>
                        </Form.Item>
                    </Form>
                    <Button type={"link"} onClick={this.showVersions}>
                        {showVersions ? 'Hide versions' : 'Show versions'}
                    </Button>
                    {showVersions && (
                        <Table
                            pagination={{pageSize: 5}}
                            columns={tableColumns as any}
                            dataSource={file.versions}
                            rowKey='version-preview-id'
                            rowClassName={(record: IVersion) => record.uuid === file.currentVersion.uuid ? 'bg-success' : ''}
                        />
                    )}
                </Modal>
            </>
        )
    }
}

export default FileEdit