import React from "react";
import IWidgetProps from "../../../../model/interface/widget/IWidgetProps";
import IWidgetPropsFunctions from "../../../../model/interface/widget/IWidgetPropsFunctions";
import IIframeOptions from "../../../../model/interface/widget/option/IIframeOptions";
import Utils from "../../../../utils";


class IframeWidget extends React.Component<IWidgetProps<IWidgetPropsFunctions, IIframeOptions>> {

    render() {
        const {url, height} = this.props.options

        return (Utils.isValidHttpUrl(url) || this.props.editor) ? (
            <div>
                <iframe src={url} className={'w-100'} height={height} title={this.props.id}/>
            </div>
        ) : ''
    }
}

export default IframeWidget