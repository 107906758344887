import React from "react"
import {Dropdown, Menu, notification, Tooltip} from "antd";
import Button from "../../../../shared/button/Button";
import {DATE_FORMAT_YYYY_MM_DD_HH_mm_ss} from "../../../../../model/constants/DateConstant";
import {PlusOutlined} from "@ant-design/icons";
import moment, {Moment} from "moment/moment";
import IEntry from "../../../../../model/interface/company/workload/IEntry";
import EntriesService from "../../../../../model/service/company/workload/EntriesService";
import IType, {COMPANY_WORKLOAD_TYPE_MODE_WORKING} from "../../../../../model/interface/company/workload/IType";
import SettingsService from "../../../../../model/service/SettingsService";
import {ButtonSize} from "antd/es/button";
import TypesService from "../../../../../model/service/company/workload/TypesService";
import ObligationsService from "../../../../../model/service/company/workload/ObligationsService";
import IEmployee from "../../../../../model/interface/company/IEmployee";
import {IAppState} from "../../../../../redux/store";
import selectors from "../../../../../redux/selectors";
import {connect} from "react-redux";

interface IProps {
    employee: IEmployee
    types: IType[]
    date?: Moment
    onCreate?: (entry: IEntry, action: 'create' | 'update') => void,
    customRender?: (onClick: (entry?: IEntry) => void, creating: boolean) => React.ReactNode
    disabled?: boolean
    minStart?: Moment
    size?: ButtonSize,
    getText: (code: string, fallBack?: string) => string
}

interface IState {
    creating: boolean
}

class EntryCreateButton extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props)
        this.state = {
            creating: false
        }
    }

    createEntry = (entry?: { [k in keyof IEntry]?: IEntry[k] }) => {
        const {employee, date, onCreate, minStart, types, getText} = this.props
        this.setState({creating: true})
        if (entry?.id) {
            return EntriesService.getInstance().resourceUpdate(entry.id, {
                ...entry,
                endAt: entry.endAt || this.getDateWithCurrentTime(date || moment())
                    .seconds(0).format(DATE_FORMAT_YYYY_MM_DD_HH_mm_ss),
            }).then((value) => {
                this.setState({creating: false})
                onCreate?.(value, 'update')
                return value
            })
        }
        const defaultType = entry?.type || SettingsService.getValue('company', 'report_default_entry_type')
            || types.find(t => t.mode === COMPANY_WORKLOAD_TYPE_MODE_WORKING && t.unlimitedFund && !t.approvalRequired)?.uuid

        if (!defaultType || !ObligationsService.isWorkloadTypeAllowed(employee.obligation, defaultType)) {
            const message = getText('company.workload.entry.create.default-type.missing', 'Typ pracovního záznamu nelze určit. Prosím, kontaktujte administrátory, aby tento problém vyřešili!')
            notification.error({
                message: message,
            })
            return this.setState({creating: false})
        }
        return EntriesService.getInstance().collectionCreate({
            ...entry,
            employee: employee.uuid,
            type: defaultType,
            startAt: (minStart || this.getDateWithCurrentTime(date || moment()))
                .seconds(0).format(DATE_FORMAT_YYYY_MM_DD_HH_mm_ss)
        }).then((value) => {
            this.setState({creating: false})
            onCreate?.(value, 'create')
            return value
        })
    }

    getDateWithCurrentTime(date: Moment, time?: Moment) {
        return (time || moment()).year(date.year()).month(date.month()).date(date.date())
    }

    buildTypeOptions = () => {
        const {employee, types} = this.props
        return TypesService.listReportEditableType(types)
            .filter(type => ObligationsService.isWorkloadTypeAllowed(employee.obligation, type))
            .map(type => ({
                value: type.uuid,
                label: type.title
            }))
    }

    render() {
        const {creating} = this.state
        const {disabled} = this.props

        const types = this.buildTypeOptions()
        if (types.length > 1 && !disabled && !creating) {
            return <Dropdown trigger={['hover']} mouseEnterDelay={0.5} overlay={<Menu
                onClick={(type) => this.createEntry({type: type.key as any})}>
                {types.map(type => (
                    <Menu.Item key={type.value}>
                        {type.label}
                    </Menu.Item>
                ))}
            </Menu>}>
                {this.renderButton()}
            </Dropdown>
        }
        return this.renderButton()
    }

    private renderButton() {
        const {creating} = this.state
        const {customRender, disabled, size} = this.props
        return (
            customRender ? customRender(this.createEntry, creating) : <Tooltip title={'Příchod'}>
                <Button icon={<PlusOutlined/>}
                        shape={'circle'}
                        disabled={disabled}
                        loading={creating}
                        onClick={() => this.createEntry()}
                        size={size}
                        className={"btn-outlined"}/>
            </Tooltip>
        );
    }
}


const mapStateToProps = (state: IAppState) => {
    return {
        getText: (code: string, fallback?: string) => selectors.dictionary.getMessage(state, code, fallback)

    }
}

export default connect(mapStateToProps)(EntryCreateButton)
