import React from "react";
import {Badge, Button, Col, Divider, Input, List, Row, Spin, Typography} from "antd";
import IReportWidgetProps from "../../../../../model/interface/report/IReportWidgetProps";
import {ArrowUpOutlined, FieldTimeOutlined, LeftOutlined, RightOutlined} from "@ant-design/icons";
import {connect} from "react-redux";
import IReportWidgetVacation from "../../../../../model/interface/report/IReportWidgetVacation";
import PlansService from "../../../../../model/service/company/workload/PlansService";
import {API_FILTER_TYPE} from "../../../../../model/constants/ApiConstant";
import {IAppState} from "../../../../../redux/store";
import IUser from "../../../../../model/interface/security/IUser";
import IEmployee from "../../../../../model/interface/company/IEmployee";
import PlanActions from "../../../company/vacation/partials/PlanActions";
import IPlan from "../../../../../model/interface/company/workload/IPlan";
import IClaim from "../../../../../model/interface/company/workload/IClaim";
import {MomentBuilder} from "../../../../../utils/MomentBuilder";
import EmployeeLabel from "../../../company/employees/EmployeeLabel";
import moment from "moment";
import {DATE_FORMAT_YYYY_MM_DD} from "../../../../../model/constants/DateConstant";
import ClaimsService from "../../../../../model/service/company/workload/ClaimsService";
import Status from "../../../company/vacation/claim/Status";
import {Link} from "react-router-dom";
import selectors from "../../../../../redux/selectors";
import EmployeeAvatar from "../../../company/employees/partials/EmployeeAvatar";
import {STATES} from "../../../../../model/interface/ui/INotification";

interface IProps extends IReportWidgetProps<IReportWidgetVacation> {
    user: IUser
    getText: (code: string) => undefined | string
}

interface IState {
    loading?: boolean
    upcoming?: IPlan
    request?: IPlan
    loadingRequest?: boolean
    requestsTotal: number,
    requestOffset: number
    expiring?: IClaim
}

class VacationWidget extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            requestsTotal: 0,
            requestOffset: 1
        }
    }

    componentDidMount() {
        this.load()
    }

    componentDidUpdate(prevProps: Readonly<IProps>) {
        if (JSON.stringify(prevProps.options) !== JSON.stringify(this.props.options)) {
            this.load()
        }
    }

    load = () => {
        const {user, options, functions, id} = this.props
        const employee = user.employees[0]
        if (employee && options.vacationTypes) {
            functions.onChildHidden?.(id, true)
            this.setState({loading: true})
            let promises: Promise<void>[] = []
            promises.push(this.loadUpcoming(employee))
            promises.push(this.loadRequest())
            promises.push(this.loadExpiring(employee))
            Promise.all(promises).then(() => {
                this.setState({loading: false}, () => {
                    const {request, upcoming, expiring} = this.state
                    employee && (request || upcoming || expiring) && functions.onChildHidden?.(id, false)
                })
            })
        }
    }

    loadUpcoming = (employee: IEmployee) => {
        const {vacationUpcomingInterval, vacationTypes} = this.props.options
        return PlansService.collectionList({
            filters: {
                employee: {
                    type: API_FILTER_TYPE.EQUAL,
                    field: 'employee',
                    value: employee.uuid
                },
                type: {
                    type: API_FILTER_TYPE.IN,
                    field: 'type',
                    value: vacationTypes
                },
                nextDate: {
                    type: API_FILTER_TYPE.LESSER_OR_EQUAL,
                    field: 'beginAt',
                    value: moment().add(vacationUpcomingInterval || 7, "d").format(DATE_FORMAT_YYYY_MM_DD)
                },
                nowDate: {
                    type: API_FILTER_TYPE.GREATER_OR_EQUAL,
                    field: 'beginAt',
                    value: moment().format(DATE_FORMAT_YYYY_MM_DD)
                }
            },
            order: {
                soon: {
                    field: 'beginAt',
                    direction: "ASC"
                }
            },
            limit: 1,
            cache: false
        }).then(response => {
            this.setState({upcoming: response.results[0]})
        })
    }

    loadNextLastRequest = (next = true) => {
        this.setState(state => ({requestOffset: state.requestOffset + (next ? 1 : -1)}),
            () => this.loadRequest(true))
    }

    loadRequest = (triggerLoading: boolean = false) => {
        const {vacationTypes} = this.props.options
        const {requestOffset} = this.state
        triggerLoading && this.setState({loadingRequest: true})
        return PlansService.collectionList({
            filters: {
                state: {
                    field: 'state',
                    type: API_FILTER_TYPE.EQUAL,
                    value: 'new'
                }
            },
            order: {
                soon: {
                    field: 'createdAt',
                    direction: "ASC"
                }
            },
            type: {
                type: API_FILTER_TYPE.IN,
                field: 'type',
                value: vacationTypes
            },
            limit: 1,
            depth: 5,
            page: requestOffset,
            cache: false
        }).then(response => {
            this.setState({request: response.results[0], requestsTotal: response.count - 1, loadingRequest: false,})
        })
    }

    loadExpiring = (employee: IEmployee) => {
        const {vacationExpireInterval, vacationTypes} = this.props.options
        return ClaimsService.collectionList({
            filters: {
                employee: {
                    type: API_FILTER_TYPE.EQUAL,
                    field: 'employee',
                    value: employee.uuid
                },
                type: {
                    type: API_FILTER_TYPE.IN,
                    field: 'type',
                    value: vacationTypes
                },
                nextDate: {
                    type: API_FILTER_TYPE.LESSER_OR_EQUAL,
                    field: 'validUntil',
                    value: moment().add(vacationExpireInterval || 7, "d").format(DATE_FORMAT_YYYY_MM_DD)
                },
                fromDate: {
                    type: API_FILTER_TYPE.LESSER_OR_EQUAL,
                    field: 'validFrom',
                    value: moment().format(DATE_FORMAT_YYYY_MM_DD)
                },
                to: {
                    type: API_FILTER_TYPE.GREATER_OR_EQUAL,
                    field: 'validUntil',
                    value: moment().format(DATE_FORMAT_YYYY_MM_DD)
                }
            },
            order: {
                soon: {
                    field: 'createdAt',
                    direction: "ASC"
                }
            },
            limit: 1,
            cache: false
        }).then(response => {
            const expiring = response.results[0]
            if (expiring && expiring.amountFree !== 0) {
                this.setState({expiring: expiring})
            }
        })
    }


    render() {
        const {user, getText, options} = this.props
        const {request, upcoming, expiring, loading, requestsTotal, loadingRequest, requestOffset} = this.state
        let {bordered} = options
        if(typeof bordered == 'undefined') {
            bordered = true
        }
        const employee = user.employees[0]

        return employee && (request || upcoming || expiring) ?
            <div className={bordered && "rounded border overflow-hidden bg-theme"}>
                <List loading={loading}>
                    {upcoming &&
                        <List.Item>
                            <List.Item.Meta
                                className={bordered && "pl-2 pr-2"}
                                title={''}
                                description={<div>
                                    <Row justify={"center"} className={'mb-2'}>
                                        <svg fill="currentColor" height="3em" viewBox="0 0 16 16"
                                             width="3em" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z"></path>
                                        </svg>
                                    </Row>
                                    <Row align={'middle'} justify={"center"}>
                                        {upcoming.type?.title}
                                    </Row>
                                    <Row align={'middle'} justify={"center"} gutter={[6, 6]}>
                                        <Col>
                                            {MomentBuilder.build(upcoming.beginAt).format('D.M. HH:mm')}
                                            {' - '}
                                            {MomentBuilder.build(upcoming.endAt).format('D.M. HH:mm')}
                                        </Col>
                                        {upcoming.representatives.length > 0 &&
                                            <Col className={"d-flex align-items-center"}>
                                                <Typography.Text strong={true} className={'mr-2'}>
                                                    Zástup:
                                                </Typography.Text>
                                                <div className={'d-inline-block'}>
                                                    {upcoming.representatives.map(r => (
                                                        <EmployeeLabel employee={r} mode={'simple'}/>
                                                    ))}
                                                </div>
                                            </Col>
                                        }
                                    </Row>
                                    <Typography.Title level={4} className={'text-center'}>
                                        {getText('vacation.motivation')}
                                    </Typography.Title>
                                </div>}
                            />
                        </List.Item>
                    }
                    {request && request.employee && (
                        <List.Item>
                            <List.Item.Meta
                                className={bordered && "pl-2 pr-2"}
                                title={<Row justify={'space-between'} align={'middle'}>
                                    <Col>
                                        <h4 className={"h4 ant-typography mb-0"}>Žádosti ke schválení</h4>
                                    </Col>
                                    <Col>
                                        {requestsTotal > 1 &&
                                            <Row align={'middle'} wrap={false}>
                                                <Typography.Text className={"text-muted font-weight-normal text-nowrap mr-2"}>
                                                    {requestOffset + ' z ' + requestsTotal}
                                                </Typography.Text>
                                                <Input.Group size={'small'} compact={true} className={'d-inline'}>
                                                    <Button size={"small"} icon={<LeftOutlined/>}
                                                            disabled={requestOffset === 1}
                                                            onClick={() => this.loadNextLastRequest(false)}/>
                                                    <Button size={"small"} icon={<RightOutlined/>}
                                                            disabled={requestsTotal === requestOffset}
                                                            onClick={() => this.loadNextLastRequest()}/>
                                                </Input.Group>
                                            </Row>
                                        }
                                    </Col>
                                </Row>}
                                description={
                                    <Spin spinning={loadingRequest}>
                                        <Row>
                                            <EmployeeAvatar size={72} employee={request.employee}/>
                                            <div className={'ml-3'}>
                                                <Typography.Text strong>{request.employee?.fullName}</Typography.Text>
                                                <div>
                                                    {request.type?.title + ', '}
                                                    {MomentBuilder.build(request.beginAt).format('D.M. HH:mm')}
                                                    {' - '}
                                                    {MomentBuilder.build(request.endAt).format('D.M. HH:mm')}
                                                </div>
                                                {request.representatives.length > 0 &&
                                                    <div className={"d-flex align-items-center"}>
                                                        <Typography.Text strong={true} className={'mr-2'}>
                                                            Zástup:
                                                        </Typography.Text>
                                                        <div className={'d-inline-block'}>
                                                            {request.representatives.map(r => (
                                                                <EmployeeLabel employee={r} mode={'simple'}/>
                                                            ))}
                                                        </div>
                                                    </div>
                                                }
                                                <div className={'d-inline-block mt-2'}>
                                                    <PlanActions plan={request} onFinish={() => this.loadRequest(true)}
                                                                 canEdit={false} canDelete={false}/>
                                                </div>
                                            </div>
                                        </Row>
                                    </Spin>
                                }
                            />
                        </List.Item>
                    )}
                    {expiring && employee.obligation && (
                        <List.Item>
                            <List.Item.Meta
                                className={bordered && "pl-2 pr-2"}
                                title={expiring.type.title}
                                description={<div className={'mt-2'}>
                                    <Typography.Text>{getText('vacation.expire')}</Typography.Text>
                                    <Status obligation={employee.obligation} claim={expiring}/>
                                    <Link to={'app/company/vacation'} key={'use'} className={'d-block mt-2'}>
                                        <Button icon={<ArrowUpOutlined rotate={45}/>}>Vybrat dovolenou</Button>
                                    </Link>
                                </div>}
                            />
                        </List.Item>
                    )}
                </List>
            </div> : ''
    }
}

const mapStateToProps = (state: IAppState) => {
    return {
        user: state.setup.user,
        getText: (code: string) => {
            const text = selectors.dictionary.getMessage(state, code)
            return text === code ? '' : text
        }
    }
}

export default connect(mapStateToProps)(VacationWidget)
