import React from "react";
import IFieldOptions from "../../../../../../model/interface/form/elementOptions/IFieldOptions";
import FilePicker from "../../../../../shared/pickers/file/FilePicker";
import IField, {RELATION_FIELD_TYPE} from "../../../../../../model/interface/dataStorage/IField";

class FormFieldWYSIWYG {

    public static render = (options: IFieldOptions, field: IField) => {
        const {
            fileCanChoose,
            fileCanDownload,
            fileCanRemove,
            fileCanUpload,
            fileDirectory,
            fileDisabled,
            fileWidget
        } = options

        const props = {
            multiple: [RELATION_FIELD_TYPE.MANY_TO_MANY, RELATION_FIELD_TYPE.ONE_TO_MANY].includes(field.type),
            canDownload: fileCanDownload,
            disabled: fileDisabled || options.disabled,
            directory: fileDirectory ? +fileDirectory : fileDirectory,
            canChoose: fileCanChoose,
            canRemove: fileCanRemove,
            canUpload: fileCanUpload,
            widget: fileWidget,
            value: options.value
        }

        return (
            <FilePicker {...props}/>
        )
    }
}

export default FormFieldWYSIWYG