import React, {RefObject} from "react";
import {Form, FormInstance, Input, Modal} from "antd";
import {connect} from "react-redux";
import {IAppState} from "../../../../../redux/store";
import Button from "../../../../shared/button/Button";
import INote from "../../../../../model/interface/company/workload/INote";
import NotesService from "../../../../../model/service/company/workload/NotesService";
import IUser from "../../../../../model/interface/security/IUser";
import {Moment} from "moment";
import {DATE_FORMAT_YYYY_MM_DD_HH_mm_ss} from "../../../../../model/constants/DateConstant";
import IReport from "../../../../../model/interface/company/workload/IReport";

interface IProps {
    user: IUser
    onSave: (note: INote) => void
    resource?: INote
    onCancel?: () => void
    date: Moment,
    report: IReport
}

interface IState {
    saving?: boolean
}

class NoteModal extends React.Component<IProps, IState> {

    formRef: RefObject<FormInstance> = React.createRef()

    constructor(props: IProps) {
        super(props);
        this.state = {}
    }

    submit = () => {
        const {onSave, resource, user, date, report} = this.props
        this.formRef.current?.validateFields().then(values => {
            this.setState({saving: true})
            let promise = resource ?
                NotesService.resourceUpdate(resource.uuid, {
                    ...values,
                }) : NotesService.collectionCreate({
                    ...values, date: date.format(DATE_FORMAT_YYYY_MM_DD_HH_mm_ss),
                    report: report.uuid,
                    employee: user.employees[0].uuid
                })
            promise.then(note => {
                this.setState({saving: false})
                onSave?.(note)
            })
        })
    }

    render() {
        const {onCancel, resource} = this.props
        const {saving} = this.state

        return <Modal destroyOnClose={true} width={800} visible={true} closable={true} onCancel={() => onCancel?.()}
                      title={resource ? 'Upravit poznámku' : 'Vytvořit poznámku'}
                      footer={[
                          <Button key="submit" type="primary" loading={saving} onClick={this.submit}>
                              Uložit
                          </Button>
                      ]}>
            <Form ref={this.formRef}>
                <Form.Item name={'text'} noStyle={true}>
                    <Input.TextArea placeholder={'Zadejte poznámku'}/>
                </Form.Item>
            </Form>
        </Modal>
    }
}

const mapStateToProps = (state: IAppState) => {
    const {user} = state.setup

    return {
        user
    }
}

export default connect(mapStateToProps)(NoteModal)