import React from "react";
import {connect} from "react-redux";
import {Alert, Layout, Menu, Tooltip} from "antd";
import {CalendarOutlined, MenuUnfoldOutlined} from '@ant-design/icons';
import NavNotification from './NavNotification';
import NavProfile from './NavProfile';
import {Link} from "react-router-dom"
// import NavPanel from './NavPanel';
import NavSearch from './NavSearch';
import {onMobileNavToggle, toggleCollapsedNav, setHeaderHeight} from 'redux/actions/Theme';
import {NAV_TYPE_TOP, SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_WIDTH} from 'model/constants/ThemeConstant';
import utils from 'utils'
import NavTools from "./NavTools";
import IBaseProps from "../../model/interface/IBaseProps";
import ISettings from "../../model/interface/ui/ISettings";
import Bookmark from "../app/bookmark/Bookmark";
import settingsService from "../../model/service/SettingsService";
import SettingsService from "../../model/service/SettingsService";
import {APP_PREFIX_PATH} from "../../configs/AppConfig";
import Trigger from "../app/extension/time-tracker/Trigger";
import NavManual from "./headerNav/NavManual";
import {IAppState} from "../../redux/store";

const {Header} = Layout;

interface IProps extends IBaseProps {
    navCollapsed?: boolean
    mobileNav?: boolean
    navType?: string
    headerNavColor?: string
    toggleCollapsedNav?: (toggle: boolean) => void
    onMobileNavToggle?: (toggle: boolean) => void
    setHeaderHeight?: (height: number) => void
    isMobile: boolean
    currentTheme?: string
    direction?: string
    settings: ISettings
    licensed: boolean
}

interface IState {
}

class HeaderNav extends React.Component<IProps, IState> {
    state = {
        searchActive: false
    }

    onSearchClose = () => {
        this.setState({searchActive: false})
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (!this.props.isMobile && this.state.searchActive) {
            this.onSearchClose()
        }
    }

    render() {
        const {
            navCollapsed,
            mobileNav,
            navType,
            headerNavColor,
            toggleCollapsedNav,
            onMobileNavToggle,
            setHeaderHeight,
            isMobile,
            currentTheme,
            licensed
        } = this.props;
        const onToggle = () => {
            if (!isMobile) {
                toggleCollapsedNav && toggleCollapsedNav(!navCollapsed)
            } else {
                onMobileNavToggle && onMobileNavToggle(!mobileNav)
            }
        }
        const isNavTop = navType === NAV_TYPE_TOP
        const mode = () => {
            if (!headerNavColor) {
                return utils.getColorContrast(currentTheme === 'dark' ? '#00000' : '#ffffff')
            }
            return utils.getColorContrast(headerNavColor)
        }
        const navMode = mode()
        const getNavWidth = () => {
            if (isNavTop || isMobile) {
                return '0px'
            }
            if (navCollapsed) {
                return `${SIDE_NAV_COLLAPSED_WIDTH}px`
            } else {
                return `${SIDE_NAV_WIDTH}px`
            }
        }


        return (
            <Header className={`app-header ${navMode} d-block ${isMobile ? 'mobile' : 'desktop'}`} id={'header-nav'}
                    style={{
                        backgroundColor: SettingsService.getValue('ui-theme', 'header'),
                        height: "auto"
                    }}>
                <div ref={elm => elm && setHeaderHeight?.(elm?.clientHeight)}>
                    {!licensed && licensed !== undefined && (
                        <Alert className={'centered line-break'} banner
                               message="Vaše licence není platná. Prosím kontaktujte administrátora systému."/>)}
                    <div className={`app-header-wrapper w-100 ${isNavTop ? 'layout-top-nav' : ''}`}>
                        <div className="nav" style={isMobile ? {width: `calc(100% - ${getNavWidth()})`} : {}}>
                            {!isNavTop && isMobile && (
                                <li className="ant-menu-item ant-menu-item-only-child" onClick={() => {
                                    onToggle()
                                }}>
                                    <MenuUnfoldOutlined className="nav-icon"/>
                                </li>
                            )}
                            {/*<div className="nav-left" style={{flex: '1 1 0', minWidth: 0}}>*/} {/*//TODO bookmarks on desktop*/}
                            {/*    {!isMobile && <Bookmarks history={history} match={match} isMobile={isMobile}/>}*/}
                            {/*</div>*/}
                            {this.getNavRight()}
                            {this.state.searchActive &&
                                <NavSearch active={this.state.searchActive} close={this.onSearchClose}/>
                            }
                        </div>
                    </div>
                </div>

            </Header>
        )
    }


    getNavRight() {
        const {
            isMobile,
            history,
            match
        } = this.props;
        return <div className="nav-right">
            <Menu mode="horizontal" className={"text-right"}>
                {!isMobile && (
                    <Menu.Item>
                        <Bookmark history={history} match={match}/>
                    </Menu.Item>
                )}
                {settingsService.getValue('personalCalendar', 'enable') && (
                    <Menu.Item>
                        <Tooltip title={'Osobní kalendář'} placement={"bottom"}>
                            <Link to={`${APP_PREFIX_PATH}/personal-calendar`} className={'d-block'}>
                                <CalendarOutlined className={'nav-icon'}/>
                            </Link>
                        </Tooltip>
                    </Menu.Item>
                )}
                {settingsService.getValue('timeTracker', 'enabled') && (
                    <Menu.Item>
                        <div className={'nav-extended-icon'}>
                            <Trigger history={history} match={match} isMobile={isMobile}/>
                        </div>
                    </Menu.Item>
                )}
                {settingsService.isEnabled('ui', 'allow-manuals') && (
                    <Menu.Item>
                        <NavManual {...this.props}/>
                    </Menu.Item>
                )}
                <Menu.Item>
                    <NavNotification/>
                </Menu.Item>
                <Menu.Item>
                    <NavTools/>
                </Menu.Item>
                <Menu.Item>
                    <NavProfile/>
                </Menu.Item>
            </Menu>
        </div>;
    }
}

const mapStateToProps = (state: IAppState) => {
    const {licensed} = state.setup
    const {theme} = state
    const {navCollapsed, navType, headerNavColor, mobileNav, currentTheme, direction} = theme;
    return {navCollapsed, navType, headerNavColor, mobileNav, currentTheme, direction, licensed}
};

export default connect(mapStateToProps, {toggleCollapsedNav, onMobileNavToggle, setHeaderHeight})(HeaderNav);