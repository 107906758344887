import React from "react";
import {Form, Select, Switch} from "antd";
import ICardWidgetWorkflowTransactionConditions
    from "../../../../../../../model/interface/dataStorage/card/ICardWidgetWorkflowTransactionConditions";


interface IProps {
    options: ICardWidgetWorkflowTransactionConditions
}

class WorkflowTransactionConditionsEditor extends React.Component<IProps> {

    render() {
        return (
            <div>
                <Form.Item name={'notMetOnly'} label={'Pouze nesplněné podmínky'} valuePropName={'checked'}>
                    <Switch/>
                </Form.Item>
                <Form.Item name={'showIcon'} label={'Zobrazit ikony'} valuePropName={'checked'}>
                    <Switch/>
                </Form.Item>
                <Form.Item name={'showColor'} label={'Zobrazit barvy'} valuePropName={'checked'}>
                    <Switch/>
                </Form.Item>
                <Form.Item name={'background'} label={'Barva pozadí'} initialValue={''}>
                    <Select options={[{value: '', label: 'Bílá'}, {value: 'bg-gray-lightest', label: 'Šedá'}]}/>
                </Form.Item>
            </div>
        )
    }
}

export default WorkflowTransactionConditionsEditor
