import RestService from "model/service/dataStorage/RestService";
import IPlan, {PlanState} from "../../../interface/company/workload/IPlan";
import IRestServiceCollectionResponse from "../../../interface/api/IRestServiceCollectionResponse";
import IRestServiceOptions from "../../../interface/api/IRestServiceOptions";
import IType from "../../../interface/company/workload/IType";
import IEntry from "../../../interface/company/workload/IEntry";
import moment from "moment";
import Utils from "../../../../utils";
import React from "react";
import {Modal, Typography} from "antd";
import RequestsTable from "../../../../components/app/company/vacation/partials/RequestsTable";
import IFreeDay from "../../../interface/company/workload/IFreeDay";
import {MomentBuilder} from "../../../../utils/MomentBuilder";
import IUser from "../../../interface/security/IUser";
import UsersService from "../../security/UsersService";

interface IRestPlansServiceCollectionResponse extends IRestServiceCollectionResponse {
    results: Array<IPlan>
}

interface IRestPlansService {
    prepareFormData(plan: IPlan, values: any): any,

    collectionList(options?: IRestServiceOptions): Promise<IRestPlansServiceCollectionResponse>,

    collectionCreate(data: any): Promise<IPlan>,

    collectionCreatePreview(data: any): Promise<IPlan>,

    resourceRetrieve(id: number | string): Promise<IPlan>,

    resourceUpdate(id: number | string, data: any): Promise<IPlan>,

    resourceUpdateState(plan: IPlan, state: PlanState, data?: {[V in keyof IPlan]: IPlan[V]}): Promise<IPlan>,

    resourceDelete(id: number | string): Promise<void>,

    buildCalendarCellToolTip(plans: IPlan[], workloadTypes: IType[], freeDay?: IFreeDay): string | undefined

    buildCalendarCellStyle(entries: IEntry[], workloadTypes: IType[], plans?: IPlan[]): React.CSSProperties,

    getColumnClassName(weekend?: boolean, selected?: boolean, isFreeDay?: boolean): string,

    canUserCreate(user: IUser, employeeUuid?: string): boolean,
}

const PlansService_COLLECTION = 'company/workload/plans'

const PlansService: IRestPlansService = {
    collectionList: function (options?) {
        return RestService.collectionList(PlansService_COLLECTION, options as unknown as IRestServiceOptions) as Promise<IRestPlansServiceCollectionResponse>
    },
    collectionCreate: function (data) {
        return RestService.collectionCreate(PlansService_COLLECTION, data) as Promise<IPlan>
    },
    resourceRetrieve: function (id) {
        return RestService.resourceRetrieve(PlansService_COLLECTION, id) as Promise<IPlan>
    },
    resourceDelete: function (id) {
        return RestService.resourceDelete(PlansService_COLLECTION, id)
    },
    resourceUpdate: function (id, data) {
        return RestService.resourceUpdate(PlansService_COLLECTION, id, data) as Promise<IPlan>
    },
    collectionCreatePreview: function (data) {
        data['preview'] = true
        return data.id ? PlansService.resourceUpdate(data.id, data) : RestService.collectionCreate(PlansService_COLLECTION, data) as Promise<IPlan>
    },
    resourceUpdateState: function (plan, state, data) {
        const update = () => RestService.resourceUpdate(PlansService_COLLECTION, plan.uuid, {...data, state}) as Promise<IPlan>

        const {representativeCollisions} = plan
        if (representativeCollisions?.length && state === 'approved') {
            return new Promise<IPlan>((resolve, reject) => {
                if (representativeCollisions.some(p => p.state === "approved")) {
                    const warning = Modal.confirm({
                        type: "error",
                        title: "Střet zástupců",
                        width: 1000,
                        content: <div>
                            <Typography.Text type={"danger"}>
                                Tento plán je v rozporu s následujícím schváleným plánem kvůli určeným zástupcům.
                                Tento plán teď nemůžete schválit
                            </Typography.Text>
                            <RequestsTable plans={representativeCollisions}></RequestsTable>
                        </div>,
                        cancelButtonProps: {hidden: true},
                        onOk: () => {
                            reject()
                            warning.destroy()
                        }
                    })
                } else {
                    const warning = Modal.confirm({
                        type: "warning",
                        title: "Střet zástupců, opravdu provést?",
                        width: 1000,
                        content: <div>
                            <Typography.Text type={"warning"}>
                                Tento plán je v rozporu s následujícími plány kvůli stanoveným zástupcům.
                                Rozhodněte se, který plán chcete schválit.
                                Po schválení jednoho nebudou moci být schváleni ostatní.
                            </Typography.Text>
                            <RequestsTable plans={representativeCollisions}></RequestsTable>
                        </div>,
                        okText: 'Schválit',
                        cancelText: 'Zrušit',
                        onOk: () => {
                            resolve(update())
                            warning.destroy()
                        },
                        onCancel: () => {
                            reject()
                        }
                    })
                }
            })
        }

        return update()
    },
    prepareFormData(plan: IPlan, values: any) {
        return {
            employee: plan.employee ? plan.employee.uuid : null,
            type: plan.type ? plan.type.uuid : null,
            beginAt: moment(values.beginAt).format('YYYY-MM-DD HH:mm:ss'),
            endAt: moment(values.endAt).format('YYYY-MM-DD HH:mm:ss'),
            state: plan.state,
            files: plan.files ? plan.files.map(file => file.id) : [],
            description: plan.description
        }
    },
    buildCalendarCellToolTip(plans, workloadTypes, freeDay) {
        let toolTip = ''
        toolTip += freeDay?.type ? freeDay.type.description + "\n\n" : ''

        if (plans.length > 0) {
            toolTip += plans
                .map(plan => {
                    const type = workloadTypes.find(_type => _type.uuid === Utils.parseObjectToIdentifier(plan.type, 'uuid'))
                    return ''
                        + MomentBuilder.build(plan.beginAt).format('D. MMMM H:mm') + ' - ' + MomentBuilder.build(plan.endAt).format('D. MMMM H:mm')
                        + ' '
                        + type!.title
                        + (plan.state === 'new' ? ' (neschválené)' : (plan.state === 'rejected' ? ' (zamítnuté)' : ''))
                        + (plan.state === 'planned' ?' (rozvrženo)' : '')
                        + (plan.state === 'spent' ? ' (vyčerpáno)' : '')
                        + (plan.description ? ' - ' + plan.description : '')
                        + (plan.representatives.length ? ' - zástup ' + plan.representatives.map(r => r.fullName).join(', ') : '')
                }).join(" | ")
        }
        return toolTip
    },
    buildCalendarCellStyle(entries: IEntry[], workloadTypes, plans = []): React.CSSProperties {
        let style: React.CSSProperties = {
            cursor: entries.length > 0 ? "pointer" : "default",
            padding: 0,
            textAlign: 'center'
        }

        function getBgColor(plan: IPlan) {
            const bgColor = workloadTypes.find(t => t.uuid === Utils.parseObjectToIdentifier(plan.type, 'uuid'))?.color!
            return plan.state === 'rejected' ? '#72849a' : plan.state === 'planned' ? Utils.hexaOpacity(bgColor, 0.5) : bgColor
        }

        const getPlan = (entry: IEntry) => plans?.find(p => entry.plans.includes(p.uuid))

        if (entries.length > 0) {
            let isSingleHalfDay: string | false = false
            const backgroundConfiguration = {
                angle: -45,
                layers: [] as { color: string, stripped: boolean }[]
            }
            if (entries.length === 1) {
                const entry = entries[0]
                const plan = getPlan(entry)
                const employee = plan?.employee
                plan && backgroundConfiguration.layers.push({
                    color: getBgColor(plan),
                    stripped: plan.state === 'new'
                })
                if (plan && employee?.obligation && (employee.obligation.shifts.length || 0) > 1
                    && (entry.hours || 0) < (employee.obligation.dayWorkHours || 0)) {
                    const layer = {
                        color: 'transparent',
                        stripped: false
                    }

                    const firstShift = MomentBuilder.build(employee?.obligation?.firstShiftStartAt)
                    if (MomentBuilder.build(entry.startAt).isAfter(MomentBuilder.build(entry.startAt).clone()
                        .hour(firstShift.hour()).minute(firstShift.minute()))) {
                        backgroundConfiguration.layers.push(layer)
                        isSingleHalfDay = "last"
                    } else {
                        backgroundConfiguration.layers.unshift(layer)
                        isSingleHalfDay = "first"
                    }

                }
            } else if (entries.length === 2) {
                entries.sort((a, b) =>
                    -MomentBuilder.build(a.startAt).diff(MomentBuilder.build(b.startAt))).forEach(e => {
                    const plan = getPlan(e)
                    plan && backgroundConfiguration.layers.push({
                        color: getBgColor(plan),
                        stripped: plan.state === 'new'
                    })
                })
            } else if (entries.length > 2) {
                backgroundConfiguration.layers.push({
                    color: '#cccccc',
                    stripped: false
                })
            }

            const date = MomentBuilder.build(entries[0].endAt)
            let borderFirst, borderLast = false
            if (entries.length < 3) {
                borderFirst = borderLast = plans[0]?.state === 'spent'
                if (isSingleHalfDay){
                    borderFirst = isSingleHalfDay === "first" ? plans[0]?.state === 'spent' : false
                    borderLast = isSingleHalfDay === "last" ? plans[0]?.state === 'spent' : false
                }
                if (plans?.length === 2) {
                    borderFirst = plans[0]?.state === 'spent'
                    borderLast = plans[1]?.state === 'spent'
                }
            }

            const borderStyle = '3px ridge #ededed'
            style = {
                ...style,
                ...Utils.buildBackgroundStyles(backgroundConfiguration),
                color: isSingleHalfDay ? '#000' : '#fff',
                borderTop: borderFirst ? borderStyle : undefined,
                borderBottom: borderLast ? borderStyle : undefined,
                borderRight: borderLast && date.isSame(MomentBuilder.build(plans[0].endAt), 'date') ? borderStyle : undefined,
                borderLeft: borderFirst && date.isSame(MomentBuilder.build((plans[1] || plans[0]).beginAt), 'date') ? borderStyle : undefined
            }
        }

        return style
    },
    getColumnClassName(weekend?: boolean, selected = false, isFreeDay = false): string {
        const classes = [] as string[]
        classes.push("text-center")
        if (!selected) {
            if (weekend || isFreeDay) {
                classes.push('bg-gray-1')
            }
        }

        if (selected) {
            classes.push('bg-info text-white')
        }

        return classes.join(' ')
    },
    canUserCreate(user: IUser, employeeUuid?: string): boolean {
        return !!employeeUuid &&
            (UsersService.hasCredential(user, 'vacation_request', ['all', 'subordinates']) || (user.employees.some(e => e.uuid === employeeUuid) && UsersService.hasCredential(user, 'vacation_request', 'me')))
    }
}

export default PlansService