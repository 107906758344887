import React from "react";
import ReactQuill, {Quill} from "react-quill";
import Utils from "../../../utils";
import ReactDOM from "react-dom";
import IFile from "../../../model/interface/file/IFile";
import WysiwygInlineFileAdd from "./wysiwyg/WysiwygInlineFileAdd";
import WysiwygInlineFileInfo from "./wysiwyg/WysiwygInlineFileInfo";
import WysiwygInlineFileButton from "./wysiwyg/WysiwygInlineFileButton";


/*
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */

/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */

const basicPackage = {
    modules: {
        toolbar: {
            container: [
                ['bold', 'italic'],
                ['link']
            ],
            handlers: {}
        },
    },
    formats: [
        'bold', 'italic', 'link'
    ]
}

const mediumPackage = {
    modules: {
        toolbar: {
            container: [
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                ['link'],
                [{'direction': 'rtl'}],                         // text direction
                [{'header': [1, 2, 3, 4, 5, 6, false]}],
                [{'align': []}],
                [{'list': 'ordered'}, {'list': 'bullet'}],
                ['clean']
            ],
            handlers: {}
        },
    },
    formats: [
        'header', 'font', 'bold', 'italic', 'underline',
        'strike', 'blockquote', 'list', 'bullet', 'link'
    ]
}

const fullPackage = {
    modules: {
        toolbar: {
            container: [
                ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                ['blockquote', 'code-block'],
                [{'list': 'ordered'}, {'list': 'bullet'}],
                [{'script': 'sub'}, {'script': 'super'}],      // superscript/subscript
                [{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent
                [{'direction': 'rtl'}],                         // text direction
                [{'header': [1, 2, 3, 4, 5, 6, false]}],
                [{'color': []}, {'background': []}],          // dropdown with defaults from theme
                [{'align': []}],
                [{'font': []}],
                ['blockquote', 'code-block'],
                ['direction', {'align': []}],
                ['link', 'image', 'video', 'formula'],
                ['clean']
            ],
            handlers: {}
        },
        clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
        }
    },
    formats: [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image', 'video', 'code-block'
    ]
}

interface IProps {
    value?: string,
    onChange?: (key: string) => void
    mode?: 'basic' | 'medium' | 'full'
    style?: React.CSSProperties
    placeholder?: string
    disabled?: boolean
    inlineFile?: boolean,
    readOnly?: boolean
}

interface IState {
    value?: string,
    loading: boolean,
    showFileModal: boolean
    cursorPosition: number
}

class Wysiwyg extends React.Component<IProps, IState> {

    static MODE_OPTIONS = [
        {code: 'basic', label: 'Zakladný'},
        {code: 'medium', label: 'Střední'},
        {code: 'full', label: 'Úplný'}
    ]
    quill: React.RefObject<ReactQuill>;

    constructor(props: IProps) {
        super(props)
        this.state = {
            value: props.value ? props.value : '',
            loading: false,
            showFileModal: false,
            cursorPosition: 0
        }
        this.quill = React.createRef();
    }

    componentDidMount() {
        WysiwygInlineFileInfo.replaceInlineFile(this.props.value || '')
        const parent = document.getElementsByClassName('ql-inline-file').item(0)?.parentElement
        parent && ReactDOM.render(<WysiwygInlineFileButton onClick={this.openFileModal}/>, parent);
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        WysiwygInlineFileInfo.replaceInlineFile(this.props.value || '')
    }

    openFileModal = () => {
        this.setState(state => ({
            showFileModal: !state.showFileModal,
            cursorPosition: this.quill.current?.getEditor().getSelection()?.index || 0
        }), this.forceUpdate)
    }

    //THIS IS TO PREVENT UPDATING WHEN VALUE PROP IS DEFINED AND CHANGES DYNAMICALLY
    shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<IState>, nextContext: any): boolean {
        WysiwygInlineFileInfo.replaceInlineFile(this.props.value || '')
        return (nextProps.hasOwnProperty('value') && Utils.stripHtml(nextProps.value) !== Utils.stripHtml(this.state.value))
            || nextProps.mode !== this.props.mode
    }

    handleChange = (html: string) => {
        html = WysiwygInlineFileInfo.rollbackUponUpdate(html)
        this.setState({value: html}, () => {
            this.props.onChange && this.props.onChange(html)
        });
    }

    onInlineFileAdd = (inlineFile: IFile) => {
        const {cursorPosition} = this.state
        const quill = this.quill.current?.getEditor()
        if (inlineFile && quill) {
            quill.clipboard.dangerouslyPasteHTML(cursorPosition,
                '<a href="' + WysiwygInlineFileInfo.buildIdentifier(inlineFile)+'">' + inlineFile.fullName + '</a>', "silent")
            this.openFileModal()
        }
    }

    render() {
        const {mode, style, placeholder, value, disabled} = this.props
        const {showFileModal} = this.state

        const selectedPackage = mode ? {
            basic: {...basicPackage},
            medium: {...mediumPackage},
            full: {...fullPackage}
        }[mode] : basicPackage

        const modules = {
            toolbar: {
                container: [...selectedPackage.modules.toolbar.container],
                handlers: {}
            },
        }

        if (this.props.inlineFile) {
            modules.toolbar.container.push(['inline-file'])
        }

        const Block = Quill.import('blots/block');
        Block.tagName = 'DIV';
        Quill.register(Block, true);

        return (
            <div>
                <WysiwygInlineFileAdd visible={showFileModal} onAdd={this.onInlineFileAdd}
                                      onCancel={this.openFileModal}/>
                <ReactQuill
                    ref={this.quill}
                    readOnly={disabled}
                    style={style}
                    placeholder={placeholder}
                    theme={'snow'}
                    onChange={this.handleChange}
                    value={value || ''}
                    modules={modules}
                    formats={selectedPackage.formats}
                    bounds={'.app'}
                />
            </div>
        )
    }
}

export default Wysiwyg