import React, {RefObject} from "react";
import ContainerWidget from "./ContainerWidget";
import {Col, FormInstance} from "antd";
import WidgetType from "./WidgetType";
import IWidgetProps from "../../../../model/interface/widget/IWidgetProps";
import ColumnsWidget from "./ColumnsWidget";
import ColumnWidget from "./ColumnWidget";
import TabsWidget from "./TabsWidget";
import TabWidget from "./TabWidget";
import IWidget from "../../../../model/interface/widget/IWidget";
import ITabOptions from "../../../../model/interface/widget/option/ITabOptions";
import IColumnOptions from "../../../../model/interface/widget/option/IColumnOptions";
import TextWidget from "./TextWidget";
import TextWidgetEditor from "./optionEditor/TextWidgetEditor";
import ColumnWidgetEditor from "./optionEditor/ColumnWidgetEditor";
import TabWidgetEditor from "./optionEditor/TabWidgetEditor";
import IWidgetOptions from "../../../../model/interface/widget/IWidgetOptions";
import IWidgetPropsFunctions from "../../../../model/interface/widget/IWidgetPropsFunctions";
import ITextOptions from "../../../../model/interface/widget/option/ITextOptions";
import CommentEditor from "./optionEditor/CommentEditor";
import ICommentOptions from "../../../../model/interface/widget/option/ICommentOptions";
import CommentWidget from "./CommentWidget";
import BackButtonWidget from "./BackButtonWidget";
import IContainerOptions from "../../../../model/interface/widget/option/IContainerOptions";
import ContainerEditor from "./optionEditor/ContainerEditor";
import TabsWidgetEditor from "./optionEditor/TabsWidgetEditor";
import ITabsOptions from "../../../../model/interface/widget/option/ITabsOptions";
import BackButtonWidgetEditor from "./optionEditor/BackButtonWidgetEditor";
import IBackButtonOptions from "../../../../model/interface/widget/option/IBackButtonOptions";
import LinkWidget from "./LinkWidget";
import LinkWidgetEditor from "./optionEditor/LinkWidgetEditor";
import ILinkOptions from "../../../../model/interface/widget/option/ILinkOptions";
import ImageWidgetEditor from "./optionEditor/ImageWidgetEditor";
import ImageWidget from "./ImageWidget";
import IImageWidget from "../../../../model/interface/widget/option/IImageWidget";
import IframeWidget from "./IframeWidget";
import IframeWidgetEditor from "./optionEditor/IframeWidgetEditor";
import IIframeOptions from "../../../../model/interface/widget/option/IIframeOptions";
import TableWidget from "./TableWidget";
import TableWidgetEditor from "./optionEditor/TableWidgetEditor";
import TableCellWidget from "./TableCellWidget";

class Widget extends React.Component<IWidgetProps> {

    static getWidget(type: string, props: IWidgetProps) {
        switch (type) {
            case(WidgetType.CONTAINER):
                return <ContainerWidget {...props} />
            case(WidgetType.COLUMNS):
                return <ColumnsWidget {...props as IWidgetProps<IWidgetPropsFunctions<IWidget<IColumnOptions>>, IColumnOptions>}/>
            case(WidgetType.COLUMN):
                return <ColumnWidget {...props}/>
            case(WidgetType.TABS):
                return <TabsWidget {...props as IWidgetProps<IWidgetPropsFunctions<IWidget<ITabsOptions>>, ITabsOptions>}/>
            case(WidgetType.TAB):
                return <TabWidget {...props}/>
            case(WidgetType.TEXT):
                return <TextWidget {...props as IWidgetProps<IWidgetPropsFunctions, ITextOptions>}/>
            case(WidgetType.COMMENT):
                return <CommentWidget {...props as IWidgetProps<IWidgetPropsFunctions, ITextOptions>}/>
            case(WidgetType.BACK_BUTTON):
                return <BackButtonWidget {...props}/>
            case(WidgetType.LINK):
                return <LinkWidget {...props}/>
            case(WidgetType.IMAGE):
                return <ImageWidget {...props}/>
            case(WidgetType.TABLE):
                return <TableWidget {...props}/>
            case(WidgetType.TABLE_CELL):
                return <TableCellWidget {...props}/>
            case(WidgetType.IFRAME):
                return <IframeWidget {...props as IWidgetProps<IWidgetPropsFunctions, IIframeOptions>}/>
            default:
                return (
                    <div>
                        Not implemented
                    </div>
                )
        }
    }

    static getOptionEditor(type: string, options: IWidgetOptions, formRef: RefObject<FormInstance>, onChange: (options?: IWidgetOptions) => Promise<void>) {
        switch (type) {
            case WidgetType.TEXT:
                return <TextWidgetEditor onChange={onChange} formRef={formRef} options={options as ITextOptions}/>
            case WidgetType.COLUMN:
                return <ColumnWidgetEditor options={options as IColumnOptions}/>
            case WidgetType.TAB:
                return <TabWidgetEditor options={options as ITabOptions}/>
            case WidgetType.TABS:
                return <TabsWidgetEditor options={options as ITabsOptions}/>
            case(WidgetType.COMMENT):
                return <CommentEditor options={options as ICommentOptions}/>
            case(WidgetType.CONTAINER):
                return <ContainerEditor options={options as IContainerOptions}/>
            case(WidgetType.BACK_BUTTON):
                return <BackButtonWidgetEditor options={options as IBackButtonOptions}/>
            case(WidgetType.LINK):
                return <LinkWidgetEditor options={options as ILinkOptions}/>
            case WidgetType.IMAGE:
                return <ImageWidgetEditor formRef={formRef} options={options as IImageWidget}
                                          onFinish={onChange}/>
            case WidgetType.IFRAME:
                return <IframeWidgetEditor options={options as IImageWidget}/>
            case WidgetType.TABLE:
                return <TableWidgetEditor options={options as IImageWidget}/>
            default:
                return null
        }
    }

    static renderWidget(props: IWidgetProps, getWidget: (type: string, props: IWidgetProps<any>) => JSX.Element) {
        const {type, editor, current, id, functions, className} = props;
        const sidePanel: RefObject<HTMLDivElement> = React.createRef()
        return <Col ref={sidePanel} onClick={editor ? (e) => {
            e.preventDefault()
            e.stopPropagation()
            functions.setCurrent?.(id)
        } : undefined} onMouseOver={editor ? (e) => {
            e.preventDefault()
            e.stopPropagation()
            const div = sidePanel.current
            if (div) {
                div.className = div.className + ' hover'
            }
        } : undefined} onMouseOut={editor ? () => {
            const div = sidePanel.current
            if (div) {
                div.className = div.className.replace(' hover', '')
            }
        } : undefined} className={'widget p-0 ' + (editor && current === id ? ' current' : '') + (' ' + className || '')}>
            {getWidget(type, props)}
        </Col>
    }

    render() {
        return Widget.renderWidget(this.props, Widget.getWidget)
    }
}

export default Widget