import WidgetType from "./WidgetType";
import ReportWidgetType from "../report/widget/ReportWidgetType";
import {antIcons} from "../../../../utils/Icons/AntIcons";
import IWidget from "../../../../model/interface/widget/IWidget";


export interface IWidgetListItem {
    type: string,
    label: string
    icon?: JSX.Element
    group: string,
    hidden?: boolean,
    add?: boolean | string[]
    addGroup?: string
}


export interface IWidgetList<T extends  IWidget> {
    getAll: () => IWidgetListItem[],
    getGrouped: () => { [x: string]: { label: string, widgets: IWidgetListItem[] } }
    getByType: (type: string) => unknown & T
    getItemByType: (type: string) => IWidgetListItem
    getItemsByTypes: (types: string[]) => IWidgetListItem[]
}

export class WidgetListManager<T extends IWidget> implements IWidgetList<T> {

    widgets: IWidgetListItem[] = [
        {
            label: 'Container',
            type: WidgetType.CONTAINER,
            icon: antIcons.BorderOuterOutlined,
            group: WidgetType.GROUP_CONSTRUCT,
            add: true
        },
        {
            label: 'Záložky',
            type: WidgetType.TABS,
            icon: antIcons.BookOutlined,
            group: WidgetType.GROUP_CONSTRUCT,
            add: [WidgetType.TAB]
        },
        {
            label: 'Záložka',
            type: WidgetType.TAB,
            icon: antIcons.BookOutlined,
            group: WidgetType.GROUP_CONSTRUCT,
            hidden: true,
            add: true
        },
        {
            label: 'Sloupce',
            type: WidgetType.COLUMNS,
            icon: antIcons.AppstoreOutlined,
            group: WidgetType.GROUP_CONSTRUCT,
            add: [WidgetType.COLUMN]
        },
        {
            label: 'Sloupec',
            type: WidgetType.COLUMN,
            icon: antIcons.AppstoreOutlined,
            group: WidgetType.GROUP_CONSTRUCT,
            hidden: true,
            add: true
        },
        {
            label: 'Tabulka',
            type: WidgetType.TABLE,
            icon: antIcons.TableOutlined,
            group: WidgetType.GROUP_CONSTRUCT
        },
        {
            label: 'Buňka',
            type: WidgetType.TABLE_CELL,
            icon: antIcons.BorderlessTableOutlined,
            group: WidgetType.GROUP_CONSTRUCT,
            hidden: true,
            add: true
        },
        {
            label: 'Text',
            type: WidgetType.TEXT,
            icon: antIcons.FontSizeOutlined,
            group: WidgetType.GROUP_STATIC
        },
        {
            type: WidgetType.COMMENT,
            group: WidgetType.GROUP_DYNAMIC,
            label: 'Komentáře',
            icon: antIcons.CommentOutlined,
            hidden: true
        },
        {
            type: WidgetType.IFRAME,
            group: WidgetType.GROUP_DYNAMIC,
            label: 'Iframe',
            icon: antIcons.FundViewOutlined
        },
        {
            type: WidgetType.BACK_BUTTON,
            group: WidgetType.GROUP_STATIC,
            label: 'Tlačítko zpět',
            icon: antIcons.RollbackOutlined
        },
        {
            type: WidgetType.PRINT,
            group: WidgetType.GROUP_STATIC,
            label: 'Tisk',
            icon: antIcons.PrinterOutlined
        },
        {
            type: WidgetType.LINK,
            group: WidgetType.GROUP_STATIC,
            label: 'Odkaz',
            icon: antIcons.LinkOutlined
        },
        {
            label: 'Obrázek',
            type: ReportWidgetType.IMAGE,
            icon: antIcons.PictureOutlined,
            group: ReportWidgetType.GROUP_STATIC
        }
    ]

    getAll = () => this.widgets
    getGrouped = () => {
        let groups = {
            [WidgetType.GROUP_CONSTRUCT]: {label: 'Modifikace uživatelské rozhraní', widgets: [] as IWidgetListItem[]},
            [WidgetType.GROUP_STATIC]: {label: 'Statická data', widgets: [] as IWidgetListItem[]},
            [ReportWidgetType.GROUP_DYNAMIC]: {label: 'Dynamické struktury', widgets: []}
        }
        return this.mapWidgetsToGroups(groups);
    }

    mapWidgetsToGroups(groups: {
        [p: string]: { label: string; widgets: IWidgetListItem[] } | {
            label: string;
            widgets: any[]
        }
    }) {
        this.widgets.filter(widget => !widget.hidden).forEach(widget => {
            if (!groups[widget.group].widgets.some(w => w.type === widget.type)) {
                groups[widget.group].widgets.push(widget)
            }
        })
        return groups
    }

    getByType = (type: string)  => {
        const item = this.widgets.find(widget => widget.type === type) as IWidgetListItem
        if (item) {
            const {label, type} = item
            return {
                label,
                type,
                weight: 1,
                children: [],
                id: '',
                key: '',
                options: {},
                parent: null
            } as unknown as T
        }
        throw new Error(`Widget type[${type}] does not exist!`)
    }
    getItemByType = (type: string) => {
        const item = this.widgets.find(widget => widget.type === type) as IWidgetListItem
        if (item) {
            return item
        }
        throw new Error(`Widget type[${type}] does not exist!`)
    }
    getItemsByTypes = (types: string[]) => {
        return this.widgets.filter(w => types.includes(w.type))
    }
}

const WidgetList = new WidgetListManager()

export default WidgetList
