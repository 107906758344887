import IShift, {SHIFT_TYPE, SHIFT_TYPES, ShiftType} from "../../../interface/company/IShift";
import moment, {Moment} from "moment";
import IFreeDay from "../../../interface/company/workload/IFreeDay";
import RestService from "../../dataStorage/RestService";
import IRestServiceOptions from "../../../interface/api/IRestServiceOptions";
import IRestServiceCollectionResponse from "../../../interface/api/IRestServiceCollectionResponse";
import ILabelValue from "../../../interface/util/ILabelValue";
import IEmployee from "../../../interface/company/IEmployee";

interface IRestShiftsServiceCollectionResponse extends IRestServiceCollectionResponse {
    results: Array<IShift>
}

interface IRestShiftsService {
    collectionList(options?: IRestServiceOptions): Promise<IRestShiftsServiceCollectionResponse>,

    collectionCreate(data: any): Promise<IShift>

    resourceRetrieve(id: number | string): Promise<IShift>

    resourceUpdate(id: number | string, data: any): Promise<IShift>

    resourceDelete(id: number | string): Promise<void>
    
    getShiftTypeOptions(): ILabelValue<string, string>[],

    getShiftTypeLabel(type: ShiftType): string,

    getShiftTypeValueLabel(type: ShiftType, value?: number): string,

    isShiftActive(shift: IShift, date: Moment, employee?: IEmployee, freeDays?: IFreeDay[]): boolean

    getStartAtDate: (shift: IShift, date?: Moment) => Moment

    getEndAtDate: (shift: IShift, date?: Moment) => Moment

    getCurrentShift: (shifts: IShift[], time: Moment) => undefined | IShift
}

const COLLECTION = 'company/shifts'


const ShiftsService: IRestShiftsService = {
    collectionList: function (options?) {
        return RestService.collectionList(COLLECTION, options as unknown as IRestServiceOptions) as Promise<IRestShiftsServiceCollectionResponse>
    },
    collectionCreate: function (data) {
        return RestService.collectionCreate(COLLECTION, data) as Promise<IShift>
    },
    resourceRetrieve: function (id) {
        return RestService.resourceRetrieve(COLLECTION, id) as Promise<IShift>
    },
    resourceDelete: function (id) {
        return RestService.resourceDelete(COLLECTION, id)
    },
    resourceUpdate: function (id, data) {
        return RestService.resourceUpdate(COLLECTION, id, data) as Promise<IShift>
    },
    getShiftTypeOptions() {
        return SHIFT_TYPES
    },
    getShiftTypeLabel(type) {
        const object = SHIFT_TYPES.find(t => t.value === type)
        if (!object){
            throw new Error('Invalid shift type' + type + ' !')
        }
        return  object.label
    },
    getShiftTypeValueLabel(type, value) {
       return value ? {
           [SHIFT_TYPE.WEEKLY]: moment().isoWeekday(value).format('dddd'),
           [SHIFT_TYPE.EVEN_WEEKLY]: moment().isoWeekday(value).format('dddd'),
           [SHIFT_TYPE.ODD_WEEKLY]: moment().isoWeekday(value).format('dddd'),
           [SHIFT_TYPE.MONTHLY]: moment().date(value).format('DD.'),
           [SHIFT_TYPE.YEARLY]: moment().dayOfYear(value).format('DD.MMMM')
       }[type] : ''
    },
    isShiftActive(shift: IShift, date: Moment, employee?: IEmployee, freeDays: IFreeDay[] = []): boolean {
        const isFreeDay = freeDays.find(f => moment(f.date).isSame(date))
        if (employee){
            if ((employee.beginDate && date.unix() < employee.beginDate.timestamp) || (employee.terminationDate && date.unix() > employee.terminationDate.timestamp)) {
                return false;
            }
        }
        if (isFreeDay && shift.type !== 'on_holiday'){
            return false
        }

        switch(shift.type) {
            case('monthly'):
                return date.date() === shift.value
            case('daily'):
                return true
            case('on_holiday'):
                return !!freeDays.find(f => moment(f.date).isSame(date))
            case('weekly'):
                return date.isoWeekday() === shift.value
            case('even_weekly'):
                return !(date.week() % 2) && date.isoWeekday() === shift.value
            case('odd_weekly'):
                return (date.week() % 2) === 1 && date.isoWeekday() === shift.value
            case('yearly'):
                return date.dayOfYear() === shift.value
            case('work_day'):
                return [6,7].indexOf(date.isoWeekday()) < 0 && !freeDays.find(f => moment(f.date).isSame(date))
            default:
                throw new Error('Invalid shift type' + shift.type + ' !')
        }
    },
    getStartAtDate: (shift: IShift, date?: Moment) =>
        (date || moment()).clone().hour(shift.startAtTimeHours).minute(shift.startAtTimeMinutes),
    getEndAtDate: (shift: IShift, date?: Moment) =>
        (date || moment()).clone().hour(shift.endAtTimeHours).minute(shift.endAtTimeMinutes),
    getCurrentShift: (shifts: IShift[], time: Moment) => {
        const sorted = shifts.sort((a, b) => (a.endAtTimeHours + (a.endAtTimeMinutes / 60) - (b.endAtTimeHours + (b.endAtTimeMinutes / 60))))
        return sorted.find(s => ShiftsService.getStartAtDate(s, time).isBefore(time)) || sorted[0]
    }
}

export default ShiftsService