import fetch from 'model/auth/ETagCachedFetchInterceptor'

const setupService = {
    load: function (cache = true) {
        return Promise.all([
            // @ts-ignore
            fetch({
                cacheKey: 'user-setup',
                url: '/user-setup',
                method: 'get'
            }),
            // @ts-ignore
            fetch({
                cacheKey: 'setup',
                url: '/setup',
                method: 'get',
                params: {cache: cache}
            })
        ]).then(([setupResponse, userSetupResponse]) => {

            return {...setupResponse, ...userSetupResponse}
        })
    }
}

export default setupService