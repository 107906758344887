import IRestServiceCollectionResponse from "../../interface/api/IRestServiceCollectionResponse";
import RestService from "model/service/dataStorage/RestService";
import IRestServiceOptions from "model/interface/api/IRestServiceOptions";
import IManual from "model/interface/ui/IManual";

interface IRestManualsServiceCollectionResponse extends IRestServiceCollectionResponse {
    results: Array<IManual>
}

interface IRestManualsService {
    collectionList(options?: IRestServiceOptions): Promise<IRestManualsServiceCollectionResponse>,

    collectionCreate(data: any): Promise<IManual>,

    resourceRetrieve(id: number | string): Promise<IManual>,

    resourceUpdate(id: number | string, data: any): Promise<IManual>,

    resourceDelete(id: number | string): Promise<void>,

    collectionList(): Promise<IRestServiceCollectionResponse>,
}

const ManualsService_COLLECTION = 'manuals'

const ManualsService: IRestManualsService = {
    collectionList: function (options?: IRestServiceOptions) {
        return RestService.collectionList(ManualsService_COLLECTION, options as unknown as IRestServiceOptions) as Promise<IRestManualsServiceCollectionResponse>
    },
    collectionCreate: function (data) {
        return RestService.collectionCreate(ManualsService_COLLECTION, data) as Promise<IManual>
    },
    resourceRetrieve: function (id) {
        return RestService.resourceRetrieve(ManualsService_COLLECTION, id) as Promise<IManual>
    },
    resourceDelete: function (id) {
        return RestService.resourceDelete(ManualsService_COLLECTION, id)
    },
    resourceUpdate: function (id, data) {
        return RestService.resourceUpdate(ManualsService_COLLECTION, id, data) as Promise<IManual>
    }
}

export default ManualsService