import React from "react"
import {Badge, Col, Popover, Row, Timeline} from "antd";
import {Moment} from "moment";
import Text from "antd/es/typography/Text";
import {IEvent} from "./Calendar";

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
    date: Moment,
    eventRender?: <T extends IEvent>(object: T) => string | JSX.Element
    events: IEvent[]
}

class Cell extends React.Component<IProps> {

    getLabel(e: IEvent) {
        return <span>{e.startAt.format('LT') + " "}{e.label}</span>
    }

    render() {
        const {events, date, eventRender, ...rest} = this.props

        return (
            <Row {...rest} key={date.toString()} >
                {events.map(event => <Col key={event.startAt.toString() + event.name}
                                          onClick={e => e.stopPropagation()} span={24} className={'cursor-pointer d-flex'}>
                        <Badge className={'d-block'} color={event.color} text={''}/>
                        {eventRender ? eventRender(event) :
                            <Popover trigger="click" content={
                                <Row>
                                    <Col span={24}>
                                        <Timeline>
                                            <Timeline.Item color="green">
                                                {event.startAt.format('lll')}
                                            </Timeline.Item>
                                            <Timeline.Item className={'pb-0'} color="red">
                                                {event.endAt?.format('lll')}
                                            </Timeline.Item>
                                        </Timeline>
                                    </Col>
                                </Row>
                            } title={event.name}>
                                <Text className={'font-size-sm'}>
                                    {this.getLabel(event)}
                                </Text>
                            </Popover>}
                    </Col>
                )}
            </Row>
        )
    }
}

export default Cell