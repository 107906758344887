import React, {Key, RefObject} from 'react'
import {FormInstance} from 'antd';
import IForm from "../../../model/interface/form/IForm";
import FormElement from "../configuration/form/FormElement";
import FormEditor from "../configuration/form/FormEditor";
import IFormElementFunctions from "../../../model/interface/form/IFormElementFunctions";
import IBaseProps from "../../../model/interface/IBaseProps";
import IContentType from "../../../model/interface/dataStorage/IContentType";
import {IActionResult} from "../../../model/service/dataStorage/ActionsService";

interface IState {
}

interface IProps extends IBaseProps {
    form: IForm,
    formRef: RefObject<FormInstance>
    contentType: IContentType,
    namePrefix?: string
    values: { [name: string]: any }
    onActionStart?: () => Promise<IActionResult>
    onActionFinish?: (resource?: IActionResult) => Promise<void>
    namespace?: Key[]
}

class FormElements extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {}
    }

    getCurrentValue(field: string) {
        return this.props.formRef.current?.getFieldValue(field) === undefined ? this.props.values[field] : this.props.formRef.current?.getFieldValue(field)
    }

    render() {
        const {
            history,
            match,
            formRef,
            form,
            namePrefix,
            contentType,
            values,
            onActionStart,
            onActionFinish,
            namespace
        } = this.props
        const structure = FormEditor.widgetsToFormStructure(form.widgets, contentType);

        return (
            <>
                {Object.entries(structure).map(([, node]) => {
                    if (node && !node.parent) {
                        let functions = {
                            getNode: (id: string) => structure[id],
                            getSortedChildren: (id: string) => FormEditor.getSortedChildren(id, structure),
                            getFormRef: () => formRef,
                            getCurrentValue: (field: string) => this.getCurrentValue(field),
                            getContentType: () => contentType,
                            getResource: () => values,
                            ...(onActionStart && {onActionStart: () => onActionStart()}),
                            ...(onActionFinish && {onActionFinish: (result) => onActionFinish(result)}),
                        } as IFormElementFunctions
                        return (
                            <FormElement
                                current={node.id}
                                {...node}
                                children={node.children}
                                key={node.id}
                                functions={functions}
                                namePrefix={namePrefix}
                                history={history}
                                match={match}
                                namespace={namespace}
                                resource={values}
                            />
                        )
                    }
                    return null
                })}
            </>
        );
    }
}


export default FormElements