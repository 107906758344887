import React from "react";
import {Form, Slider} from "antd";
import IColumnOptions from "../../../../../model/interface/form/elementOptions/IColumnOptions";

interface IProps {
    options: IColumnOptions
}

class ColumnWidgetEditor extends React.Component<IProps> {

    render() {
        return (
            <Form.Item name={'width'} label={'Zvolte velikost nadpisu'} initialValue={24}>
                <Slider marks={{4: '4/24', 24: '24/24'}} min={4} max={24} tooltipVisible/>
            </Form.Item>
        )
    }
}

export default ColumnWidgetEditor