import _ from 'underscore'
import {RootStateOrAny} from "react-redux";
import IRoute from "../../model/interface/dataStorage/IRoute";
import Utils from "../../utils";
import RoutesService from "../../model/service/ui/RoutesService";

export const findAll = (store: RootStateOrAny): IRoute[] => store.setup.routes

export const findOneBy = (store: RootStateOrAny, property: string, value: any): IRoute => _.findWhere(findAll(store), {[property]: value}) as IRoute

export const findOneByUrl = (store: RootStateOrAny, url: string): IRoute => store.setup.routesIndex[url] || null

export const findOneByUrlWithIdentifier = (store: RootStateOrAny, url: string, strict: boolean = true): IRoute | null => {
    return store.setup.routes.find((route: IRoute) => Utils.urlMatches(route.url, url, strict)) || null
}
export type RouteParametersFromUrl = null | {id: number, parameters: {[name: string]: any}}

export const extractRouteParametersFromUrl = (store: RootStateOrAny, url: string): RouteParametersFromUrl => {
    const routes = findAll(store).sort((a: IRoute, b: IRoute) => a.url > b.url ? -1 : 1)
    for(let i in routes) {
        if(routes.hasOwnProperty(i)) {
            const route = routes[i]
            const params = RoutesService.extractParameters(route, url)
            if (params){
                return params
            }
        }
    }
    return null
}

export const getReferrer = (store: RootStateOrAny): null|string => {
    return store.setup.locations.length > 1 ? store.setup.locations[store.setup.locations.length - 1] : null
}

export default {
    findAll,
    findOneBy,
    findOneByUrl,
    findOneByUrlWithIdentifier,
    extractRouteParametersFromUrl,
    getReferrer
}