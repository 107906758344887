import React from "react";
import {Button, Image, Input, Modal, Row, Table, Tag} from "antd";
import {CheckOutlined, FolderOutlined, PlusOutlined} from "@ant-design/icons";
import IFile from "../../../../../model/interface/file/IFile";
import {extensionToIcon} from "../../../../app/file-manager/utils/Extension";
import Utils from "../../../../../utils";
import DirectoryPicker from "../DirectoryPicker";
import {ColumnProps} from "antd/es/table";
import IRestServiceOptions from "../../../../../model/interface/api/IRestServiceOptions";
import {API_FILTER_TYPE} from "../../../../../model/constants/ApiConstant";
import FilesService from "../../../../../model/service/file/FilesService";

interface IState {
    search?: string,
    directory?: number[],
    loading: boolean,
    saving: boolean,
    showAll: boolean
    fileList: IFile[]
    fileFoundList: IFile[],
}

interface IProps {
    onFinish: (files: IFile[]) => void
    fileList: IFile[]
    multiple?: boolean
    forceDirectory?: number
    extensions?: string[]
}

class PickerModal extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            loading: false,
            saving: false,
            showAll: false,
            fileList: [...props.fileList],
            fileFoundList: [],
        }
    }

    isFileSelected = (id: number) => {
        return !!this.state.fileList.find(file => file.id === id);
    }

    addFile = (file: IFile) => {
        this.setState(state => {
            if (this.isFileSelected(file.id)) {
                return {fileList: [...state.fileList.filter(listFile => listFile.id !== file.id)]}
            }
            return {fileList: this.props.multiple ? [...state.fileList, file] : [file]}
        })
    }

    load() {
        const {search, directory} = this.state
        this.setState({loading: true})
        let params: IRestServiceOptions = {filters: {}, limit: 0}
        if (this.state.search && params.filters) {
            params.filters.name = {field: 'name', type: API_FILTER_TYPE.LIKE, value: search}
        } else if(this.state.directory && params.filters) {
            params.filters.direcotry = {field: 'directory', type: API_FILTER_TYPE.IN, value: directory}
        }

        if(this.props.forceDirectory && params.filters) {
            params.filters.direcotry = {field: 'directory', type: API_FILTER_TYPE.IN, value: [this.props.forceDirectory]}
        }

        if(this.props.extensions && params.filters) {
            params.filters.extension = {field: 'currentVersion.extension', type: API_FILTER_TYPE.IN,
                value: this.props.extensions.map(extenstion => extenstion.replace(/^\./, ""))}
        }
        FilesService.collectionList(params).then(response => {
            this.setState({fileFoundList: response.results, loading: false})
        })
    }

    onSearch = (value: string) => {
        this.setState({search: value}, this.load)
    }

    onDirectoryChoose = (id: number | number[]) => {
        this.setState({directory: typeof id === 'number' ? [id] : id}, this.load)
    }

    getTableColumns() {
        return [
            {
                render: (text: string, record: IFile) => record.thumbnailUrl ? (
                    <Image alt={"file preview"} height={40} src={record.thumbnailUrl} style={{background: "white"}}
                           fallback={Utils.imageFallback()}/>
                ) : (extensionToIcon(record.currentVersion.extension))
            },
            {
                render: (text: string, record: IFile) => record.fullName
            },
            {
                render: (text: string, record: IFile) => <Tag icon={<FolderOutlined/>}>{record.directory.name}</Tag>
            },
            {
                render: (value, record) => {
                    const selected = this.isFileSelected(record.id)
                    return (
                        <Row justify={"end"}>
                            <Button size={'small'} key={record.id} onClick={() => this.addFile(record)}
                                    type={selected ? 'primary' : 'default'}
                                    icon={selected ? <CheckOutlined/> : <PlusOutlined/>}/>
                        </Row>

                    )
                }
            }
        ] as ColumnProps<IFile>[]
    }

    render() {
        const {saving, fileList, loading, directory, fileFoundList, search} = this.state
        const {onFinish, forceDirectory} = this.props

        return (
            <Modal
                title={'Vybrat soubory'}
                okText={'Confirm'}
                visible={true}
                onCancel={() => onFinish(this.props.fileList)}
                destroyOnClose={true}
                footer={[
                    <Button key="back" onClick={() => onFinish(this.props.fileList)}>
                        Return
                    </Button>,
                    <Button key="submit" type="primary" disabled={fileList.length === 0} loading={saving}
                            onClick={() => onFinish(fileList)}>
                        Submit
                    </Button>
                ]}
            >
                <Input.Search allowClear className={'mb-3'} placeholder={'Hledat podle jména'}
                              enterKeyHint={"enter"} onSearch={this.onSearch}/>
                <DirectoryPicker value={forceDirectory} disabled={!!search || !!forceDirectory}
                                 onChange={this.onDirectoryChoose}
                                 placeholder={'Hledat podle složky'} add={false}/>
                <Table size={'small'}
                       pagination={{pageSize: 5}}
                       locale={{emptyText: 'Žádné soubory'}}
                       loading={loading || !directory}
                       rowKey={'id'}
                       rowClassName={'bg-gray-lightest'}
                       columns={this.getTableColumns()}
                       dataSource={fileFoundList}/>
            </Modal>
        )
    }
}

export default PickerModal