import React from "react";
import ICardWidgetProps from "../../../../../../model/interface/dataStorage/card/ICardWidgetProps";
import {API_FILTER_TYPE} from "model/constants/ApiConstant";
import GeneratedDocumentsService from "../../../../../../model/service/file/generated-document/DocumentsService";
import DocumentsService from "../../../../../../model/service/file/generated-document/DocumentsService";
import {Button, notification, Table, Tooltip} from "antd";
import {TablePaginationConfig} from "antd/lib/table/interface";
import IDocument from "../../../../../../model/interface/file/generated-document/IDocument";
import UserAvatar from "../../../../security/UserAvatar";
import {DownloadOutlined} from "@ant-design/icons";
import moment from "moment";

interface IProps extends ICardWidgetProps {

}

interface IState {
    sources: IDocument[]
    loading: boolean,
    children: { [id: string]: IDocument[] }
    page: number
    total: number
    downloading: string[]
    pageSize: number
}

class CardWidgetGeneratedDocuments extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            loading: false,
            sources: [],
            children: {},
            page: 1,
            total: 0,
            pageSize: 10,
            downloading: []
        }
    }

    componentDidMount() {
        this.loadSources()
    }

    loadSources = () => {
        const {page, pageSize} = this.state
        const {resource} = this.props
        this.setState({loading: true})
        GeneratedDocumentsService.collectionList({
            filters: resource ? {
                0: {
                    type: API_FILTER_TYPE.OR,
                    children: {
                        0: {
                            type: API_FILTER_TYPE.EQUAL,
                            field: 'entityUuid',
                            value: resource?.uuid
                        },
                    }
                }
            } : {},
            page,
            limit: pageSize
        }).then(sources => {
            this.setState({sources: sources.results, loading: false, total: sources.totalCount})
        })
    }

    // remove = (id: number) => {
    //     GeneratedDocumentsService.resourceDelete(id).then(() => {
    //         notification.success({message: 'Zdroj byl smazan!'})
    //         let sources = this.state.sources.filter(value => value.id !== id)
    //         this.setState({sources, loading: false})
    //     })
    // }

    download = (document: IDocument) => {
        this.setState(state => ({downloading: [...state.downloading, document.uuid]}))
        DocumentsService.resourceDownload(document.id).then(() => {
            notification.success({message: 'Dokument byl stažen!'})
            this.setState(state => ({downloading: [...state.downloading.slice(state.downloading.indexOf(document.uuid))]}))
        })
    }

    onChange = (
        pagination: TablePaginationConfig,
        // filters: Record<string, FilterValue | null>,
        // sorter: SorterResult<any> | SorterResult<any>[]
    ) => {
        this.setState(_state => {
            return {
                page: pagination.current || this.state.page,
            }
        }, this.loadSources)
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (prevProps.reload !== this.props.reload && this.props.reload) {
            this.loadSources()
        }
    }


    render() {

        const {loading, sources, downloading, page, total, pageSize} = this.state
        const pagination = {
            current: page,
            total: total,
            pageSize,
            showTotal: (total: number) => <span>Celkem: {total}</span>,
            hideOnSinglePage: true
        }

        const tableColumns = [
            {
                title: 'Soubor',
                dataIndex: 'file',
                render: (_: any, item: IDocument) => item.file.fullName
            },
            {
                title: 'Vzor',
                dataIndex: 'pattern',
                render: (_: any, item: IDocument) => item.pattern.file.fullName
            },
            {
                title: 'Vytvořil',
                dataIndex: 'createdBy',
                render: (_: any, item: IDocument) => item.createdBy && <UserAvatar user={item.createdBy}/>
            },
            {
                title: 'Vytvořeno',
                dataIndex: 'createdAt',
                render: (_: any, item: IDocument) => moment(item.createdAt.raw).format('LL')
            },
            {
                title: <div className={"d-flex align-items-center justify-content-between"}>
                    Akce
                </div>,
                key: 'actions',
                dataIndex: 'actions',
                render: (_: any, item: IDocument) => (
                    <div className="text-right d-flex justify-content-end">
                        <Tooltip title={"Stahnout"}>
                            <Button type="link" loading={downloading.includes(item.uuid)} className="mr-2"
                                    icon={<DownloadOutlined/>}
                                    size="small" onClick={() => this.download(item)}/>
                        </Tooltip>
                        {/*TODO enable with rights maybe ??*/}
                        {/*<Tooltip title={"Odstranit"}>*/}
                        {/*    <Button danger type="link" className="mr-2" icon={<CloseOutlined/>} size="small"*/}
                        {/*            onClick={() => this.remove(item.id)}/>*/}
                        {/*</Tooltip>*/}
                    </div>
                )
            }
        ];

        return (
            <>
                <Table
                    onChange={this.onChange}
                    pagination={pagination}
                    loading={loading}
                    rowKey={'id'}
                    columns={tableColumns}
                    dataSource={sources}
                />
            </>
        )
    }
}


export default CardWidgetGeneratedDocuments;