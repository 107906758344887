import React from "react";
import {Form, Select} from "antd";
import IReportWidgetView from "../../../../../../model/interface/report/IReportWidgetView";
import {connect, RootStateOrAny} from "react-redux";
import {ISetupState} from "../../../../../../redux/reducers/Setup";
import IView from "../../../../../../model/interface/dataStorage/IView";
import selectors from "../../../../../../redux/selectors";
import IContentType from "../../../../../../model/interface/dataStorage/IContentType";
import Utils from "../../../../../../utils";

interface IProps {
    options: IReportWidgetView
    views: IView[]
    findContentType: (uuid: string) => IContentType
}

class ViewWidgetEditor extends React.Component<IProps> {

    render() {
        const {views, findContentType} = this.props

        return (
            <Form.Item label={'Pohled'} name={'view'} rules={[{required: true, message: 'Pohled je vyžadován'}]}>
                <Select showSearch filterOption={(input, option) =>
                    Utils.stringContains(option?.props.children.join(), input)
                }>
                    {views.map(view => (
                        <Select.Option
                            value={view.uuid}>{view.label} -
                            [{view.contentTypes.map(contentType => findContentType(contentType).label).join(', ')}]
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
        )
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    const {views} = state.setup as ISetupState

    return {
        views,
        findContentType: (uuid: string) => selectors.contentTypes.findOneBy(state, 'uuid', uuid),
    }
}

export default connect(mapStateToProps)(ViewWidgetEditor)
