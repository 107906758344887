import React, {lazy, Suspense} from "react";
import {Mode} from "../redux/reducers/Theme";

const Light = lazy(() => import('./LightTheme'));
const Dark = lazy(() => import('./DarkTheme'));

export const isOsDark = () => window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches

const ThemeProvider: React.FC<{ theme: Mode }> = ({children, theme}) => {

    return <>
        <Suspense fallback={<></>}>
            {theme === 'light' && <Light/>}
            {theme === 'dark' && <Dark/>}
            {theme === 'auto' && (isOsDark() ? <Dark/> : <Light/>)}
        </Suspense>
        {children}
    </>
}
export default ThemeProvider;
