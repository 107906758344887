import React, {Component} from 'react';
import {ColorResult, SketchPicker} from "react-color";
import {Button, Col, Dropdown, Row} from "antd";
import {CloseOutlined} from "@ant-design/icons";

type Output = 'hex' | 'hexa' | 'rgba'

interface IProps {
    value?: string
    onChange?: (key?: string | null) => void
    className?: string
    placeholder?: string
    disabled?: boolean
    output?: Output
    allowClear?: boolean
    popupContainer?: HTMLElement
    styles?: React.CSSProperties
    icon?: JSX.Element
}

interface IState {
    value?: string
    visible?: boolean
}

class ColorPicker extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            value: props.value
        }
    }

    static defaultProps = {
        output: 'hexa',
        allowClear: true
    }

    onColorChange = (value: ColorResult) => {
        const {onChange, output} = this.props
        let result
        const {rgb} = value
        if (output === 'hexa') {
            result = '#' + ((1 << 24) | (rgb.r << 16) | (rgb.g << 8) | rgb.b).toString(16).slice(1)
                + (rgb.a ? (Math.round(rgb.a * 255) | (1 << 8)).toString(16).slice(1) : '')
        } else if (output === 'hex') {
            result = '#' + ((1 << 24) | (rgb.r << 16) | (rgb.g << 8) | rgb.b).toString(16).slice(1)
        } else {
            result = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a})`
        }
        onChange && onChange(result)
    }

    parseValue(output?: Output, value?: string) {
        if (!value) {
            return undefined
        }
        if (output && ['hex', 'hexa'].includes(output)) {
            const c = value.slice(1).match(/.{1,2}/g);
            const rgb: number[] = [];
            const a = c?.[3] ? parseFloat((parseInt(((parseInt(c[3], 16) / 255) * 1000) + "") / 1000) + '') : 1
            c?.slice(0, 3).forEach(v => rgb.push(parseInt(v, 16)))
            return {
                r: rgb[0],
                g: rgb[1],
                b: rgb[2],
                a: a
            }
        } else {
            const digits = value.match(/[.?\d]+/g)
            return {
                r: digits?.[0] ? Number(digits[0]) : 0,
                g: digits?.[1] ? Number(digits[1]) : 0,
                b: digits?.[2] ? Number(digits[2]) : 0,
                a: digits?.[3] ? parseFloat(digits[3]) : 1
            }
        }
    }

    onClear = () => {
        this.props.onChange?.(null)
    }

    render() {
        const {value, disabled, placeholder, output, allowClear, className, popupContainer, styles, icon} = this.props
        let parsedValue = this.parseValue(output, value);

        return (
            <Dropdown overlayStyle={{minWidth: "auto"}}
                      getPopupContainer={popupContainer ? () => popupContainer : undefined} disabled={disabled}
                      trigger={["click"]} overlay={
                <SketchPicker color={parsedValue} onChange={this.onColorChange}/>
            }>
                <Button style={styles} className={(className || '') + ' w-100 ant-input'}>
                    <Row justify={"space-between"} align={'middle'} className={'h-100'}>
                        {icon ? <text style={{color: value || this.rainbowDefault()}}>{icon}</text> : (
                        <Col className={'h-100'} style={{background: value || this.rainbowDefault()}}
                             flex={'1 1'}>{placeholder || ''}</Col>
                        )}
                        {allowClear && (
                            <CloseOutlined onClick={e => {
                                e.stopPropagation()
                                this.onClear()
                            }} className={'cursor-pointer pl-2'}/>
                        )}
                    </Row>
                </Button>

            </Dropdown>
        )
    }

    private rainbowDefault() {
        return 'linear-gradient(\n' +
            '        90deg,\n' +
            '        rgba(255, 0, 0, 1) 0%,\n' +
            '        rgba(255, 154, 0, 1) 10%,\n' +
            '        rgba(208, 222, 33, 1) 20%,\n' +
            '        rgba(79, 220, 74, 1) 30%,\n' +
            '        rgba(63, 218, 216, 1) 40%,\n' +
            '        rgba(47, 201, 226, 1) 50%,\n' +
            '        rgba(28, 127, 238, 1) 60%,\n' +
            '        rgba(95, 21, 242, 1) 70%,\n' +
            '        rgba(186, 12, 248, 1) 80%,\n' +
            '        rgba(251, 7, 217, 1) 90%,\n' +
            '        rgba(255, 0, 0, 1) 100%\n' +
            '    )';
    }
}

export default ColorPicker
