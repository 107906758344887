import IAction from "../../../../model/interface/dataStorage/IAction";
import React from "react";
import {Col, Modal, Row, Table, Tag, Tooltip} from "antd";
import {
    CloseOutlined,
    EditOutlined,
    LockOutlined,
    MessageOutlined,
    PartitionOutlined,
    PlusOutlined
} from "@ant-design/icons";
import {IContentTypeStepProps} from "./ContentTypeConfiguration";
import IForm from "../../../../model/interface/form/IForm";
import IRoute from "../../../../model/interface/dataStorage/IRoute";
import Utils from "../../../../utils";
import {connect} from "react-redux";
import ContentTypeActionModal from "./action/ContentTypeActionModal";
import ContentTypeActionArguments from "./action/ContentTypeActionArguments";
import selectors from "../../../../redux/selectors";
import PresenterBuilder from "../../../../views/dataStorage/PresenterBuilder";
import RoutesService from "../../../../model/service/ui/RoutesService";
import ContentTypeActionNotifications from "./action/ContentTypeActionNotifications";
import {ColumnsType} from "antd/es/table";
import {IAppState} from "../../../../redux/store";
import Hotkey from "../../../shared/hotkey/Hotkey";
import Button from "components/shared/button/Button";

interface IStateStepActions {
    action: IAction | null,
    modalType: string
}

interface IContentTypeActionsStepProps extends IContentTypeStepProps {
    forms: IForm[],
    routes: IRoute[],
    findRouteByUuid: (uuid: string) => IRoute | undefined
}

class ContentTypeActions extends React.Component<IContentTypeActionsStepProps, IStateStepActions> {
    state = {
        action: null,
        modalType: 'edit'
    }

    componentDidMount() {
        if (this.props.resource.actions.filter(action => !action.locked).length === 0) {
            Modal.confirm({
                title: "Nejsou nastavené žádné akce, chcete provést úvodní nastavení základních akcí?",
                onOk: () => this.buildBasicActions()
            })
        }
    }

    buildBasicActions(): any {
        const {resource} = this.props
        const actions = resource.actions
        const form = (resource.forms.length === 1 ? resource.forms[0].uuid : null) || null
        actions.push({
            uuid: Utils.uuid(),
            name: 'create',
            label: 'Vytvořit',
            type: 'form',
            forms: form ? [form] : [],
            locked: false,
            contentType: resource.uuid,
            actionScripts: [],
            notifications: []
        })
        actions.push({
            uuid: Utils.uuid(),
            name: 'edit',
            label: 'Upravit',
            type: 'form',
            forms: form ? [form] : [],
            locked: false,
            contentType: resource.uuid,
            actionScripts: [],
            notifications: []
        })
        actions.push({
            uuid: Utils.uuid(),
            name: 'delete',
            label: 'Smazat',
            type: 'delete',
            forms: form ? [form] : [],
            locked: false,
            contentType: resource.uuid,
            actionScripts: [],
            notifications: []
        })
        actions.push({
            uuid: Utils.uuid(),
            name: 'menu',
            label: 'Položka v menu',
            type: 'script',
            forms: form ? [form] : [],
            locked: false,
            contentType: resource.uuid,
            actionScripts: [],
            notifications: []
        })
        this.props.onValuesChange({
            actions
        })
    }

    editAction(action: IAction | null) {
        this.setState({
            action,
            modalType: 'edit'
        })
    }

    removeAction(action: IAction) {
        const actions = this.props.resource.actions
        const index = Utils.findIndex(actions, {id: action.id})
        actions.splice(index, 1)
        this.props.onValuesChange({actions})
    }

    addAction() {
        const {uuid, actions} = this.props.resource
        const action = ContentTypeActions.createNewActionObject(uuid);
        actions.push(action)
        this.props.onValuesChange({
            actions
        })
        this.editAction(action)
    }

    static createNewActionObject(uuid: string) {
        return {
            uuid: Utils.uuid(),
            name: '',
            label: '',
            type: '',
            forms: [],
            locked: false,
            contentType: uuid,
            actionScripts: [],
            notifications: []
        } as IAction;
    }

    cancelAction() {
        this.setState({
            action: null
        })
    }

    manageScripts = (action: IAction | null) => {
        this.setState({action, modalType: 'scripts'})
    }

    manageNotifications = (action: IAction | null) => {
        this.setState({action, modalType: 'notifications'})
    }

    onUpdate = (action: IAction, close: boolean) => {
        this.props.onValuesChange({
            actions: Utils.arrayAddOrUpdateWhere(
                this.props.resource.actions,
                {uuid: action.uuid},
                action
            )
        })
        this.setState({action})
        if (close) {
            this.setState({
                action: null
            })
        }
    }

    render() {
        const actionColumns: ColumnsType<IAction> = [
            {
                title: 'ID',
                dataIndex: 'id'
            },

            {
                title: <LockOutlined/>,
                dataIndex: 'locked',
                render: (locked: boolean) => (
                    <Tooltip title={"Tato akce nejde smazat ani změnit její název"}>
                        {locked && <LockOutlined/>}
                    </Tooltip>
                ),
            },
            {
                title: 'Titulek',
                dataIndex: 'label'
            },
            {
                title: 'Název',
                dataIndex: 'name'
            },
            {
                title: 'Typ',
                dataIndex: 'type'
            },
            {
                title: 'Formulář',
                dataIndex: 'form',
                render: (_, action) => {
                    const forms = this.props.forms.filter(f => f.uuid && action.forms.includes(f.uuid))
                    return (
                        <Row gutter={[0, 4]}>
                            {forms.length ? (
                                forms.map(form =>
                                    <Col>
                                        <Tag>{form.label}</Tag>
                                    </Col>
                                )) : (
                                <span className={"text-muted"}>
                                    N/A
                                </span>
                            )}
                        </Row>
                    )
                }
            },
            {
                title: 'URL',
                dataIndex: 'route',
                render: (uuid: string | null) => {
                    const route = uuid ? this.props.findRouteByUuid(uuid) : undefined
                    return (
                        <>
                            {route ? (
                                <>
                                    {PresenterBuilder.build(RoutesService.getDefaultPresenter(), route, RoutesService.getDefaultPresenter().options)}
                                </>
                            ) : (
                                <span className={"text-muted"}>
                                N/A
                            </span>
                            )}
                        </>
                    )
                }
            },
            {
                title: 'Přesměrování',
                dataIndex: 'afterRedirect',
                render: (uuid: string | null) => {
                    const route = uuid ? this.props.findRouteByUuid(uuid) : undefined
                    return (
                        <>
                            {route ? (
                                <>
                                    {PresenterBuilder.build(RoutesService.getDefaultPresenter(), route, RoutesService.getDefaultPresenter().options)}
                                </>
                            ) : (
                                <span className={"text-muted"}>
                                N/A
                            </span>
                            )}
                        </>
                    )
                }
            },
            {
                title: <Row justify={"end"}>
                    <Hotkey help={"Přidat akci"} keys={["Alt", "p"]} trigger={() => this.addAction()}>
                        <Button type={"success"} onClick={() => this.addAction()} icon={<PlusOutlined/>}>
                            <u className={'pl-2'}>P</u>řidat
                        </Button>
                    </Hotkey>
                </Row>,
                key: 'actions',
                dataIndex: 'actions',
                render: (_: any, elm: IAction) => (
                    <div className="text-right d-flex justify-content-end">
                        <Tooltip title={"Nastavit notifikace"}>
                            <Button onClick={() => this.manageNotifications(elm)} type="link"
                                    className={"mr-2 btn-info" + (elm.notifications && elm.notifications.length === 0 ? " disabled" : "")}
                                    icon={<MessageOutlined/>} size="small"/>
                        </Tooltip>
                        <Tooltip title={"Nastavit skripty před a po provedení"}>
                            <Button onClick={() => this.manageScripts(elm)} type="link"
                                    className={"mr-2 btn-info" + (elm.actionScripts.length === 0 ? " disabled" : "")}
                                    icon={<PartitionOutlined/>} size="small"/>
                        </Tooltip>
                        <Tooltip title={"Zobrazit"}>
                            <Button onClick={() => this.editAction(elm)} type="link" className="mr-2"
                                    icon={<EditOutlined/>} size="small"/>
                        </Tooltip>
                        <Tooltip title={"Odstranit"}>
                            <Button disabled={elm.locked} onClick={() => this.removeAction(elm)} danger type="link"
                                    className="mr-2" icon={<CloseOutlined/>} size="small"/>
                        </Tooltip>
                    </div>
                )
            }
        ]

        const {resource, forms} = this.props
        const {modalType, action} = this.state
        const tableData = [...resource.actions].sort((a, b) => a.label > b.label ? 1 : -1)
        return (
            <>
                {{
                    'edit': <ContentTypeActionModal
                        onSave={(action: IAction, close: boolean) => this.onUpdate(action, close)}
                        onCancel={() => this.cancelAction()} contentType={resource} resource={action}
                        forms={forms}/>,
                    'scripts': <ContentTypeActionArguments
                        onSave={(action: IAction, close: boolean) => this.onUpdate(action, close)}
                        onCancel={() => this.cancelAction()} resource={action}/>,
                    'notifications': <ContentTypeActionNotifications
                        onSave={(action: IAction, close: boolean) => this.onUpdate(action, close)}
                        onCancel={() => this.cancelAction()} resource={action}
                        notifications={this.props.resource.notifications}/>
                }[modalType]}
                <Table
                    pagination={false}
                    columns={actionColumns}
                    dataSource={tableData}
                    rowKey='id'
                />
            </>
        )
    }
}

const mapStateToProps = (state: IAppState) => {
    return {
        routes: state.setup.routes,
        forms: state.setup.forms,
        findRouteByUuid: (uuid: string) => selectors.routes.findOneBy(state, 'uuid', uuid)
    }
}

export default connect(mapStateToProps)(ContentTypeActions)