import React, {RefObject} from "react";
import {Checkbox, Form, FormInstance, Input, Select, Switch} from "antd";
import Textarea from "../../../../views/app-views/components/data-entry/input/Textarea";
import {IContentTypeStepProps} from "./ContentTypeConfiguration";
import IconPicker from "../../../shared/IconPicker";
import {CheckboxValueType} from "antd/es/checkbox/Group";
import {Link} from "react-router-dom";
import IWorkflow from "../../../../model/interface/dataStorage/workflow/IWorkflow";
import {connect} from "react-redux";
import {IAppState} from "../../../../redux/store";
import ContentTypesService from "../../../../model/service/dataStorage/ContentTypesService";
import IContentType from "../../../../model/interface/dataStorage/IContentType";

interface IState {
    createBasicRoutes: boolean
    debounce?: number
    originalCollection?: string
}

interface IProps extends IContentTypeStepProps {
    masterClasses: string[],
    formRef: RefObject<FormInstance>,
    hidden: boolean
    workflows: IWorkflow[]
    contentTypes: IContentType[]
}

class ContentTypeProperties extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props)
        this.state = {
            createBasicRoutes: false,
            originalCollection: props.resource.collection
        }
    }

    onExtensionsChange = (checkedValues: CheckboxValueType[]) => {
        let extensions = checkedValues.map(value => ({type: value, id: null}))
        this.props.onValuesChange({extensions})
    }

    onParentChange = (uuid: string) => {
        this.props.onValuesChange({parent: uuid})
    }

    populateCollectionAndUrl() {
        const resource = this.props.resource
        if (resource.name) {
            let collection = resource.name.replaceAll('_', '-')
            if (collection[collection.length - 1] !== 's') {
                collection += 's'
            }
            console.log('xxx', resource, !resource.collection)
            let changes = {} as any
            if (!resource.collection) {
                changes.collection = collection
            }
            if (!resource._basicRouteName) {
                changes._basicRouteName = collection
            }
            if (Object.keys(changes).length > 0) {
                this.props.formRef.current?.setFieldsValue(changes)
                this.props.onValuesChange(changes)
            }
        }
    }

    private buildContentTypeOptions() {
        return this.props.contentTypes.map(contentType => ({
            value: contentType.uuid,
            label: contentType.label || contentType.name
        })).sort((a, b) => {
            return a.label > b.label ? 1 : -1
        })
    }

    enableBasicRoutesCreation = () => {
        this.setState(state => ({createBasicRoutes: !state.createBasicRoutes}))
    }

    setDebounce = (debounce: any) => {
        this.setState({debounce})
    }

    getDebounce = () => {
        return this.state.debounce
    }

    render() {
        const {
            resource,
            extensionTypes,
            masterClasses,
            formRef,
            onValuesChange,
            hidden,
            workflows,
            contentTypes
        } = this.props
        const {createBasicRoutes, originalCollection} = this.state
        let extensionsEnabled: string[] = []
        if (resource && resource.extensions) {
            resource.extensions.forEach(extension =>
                extensionsEnabled.push(extension.type)
            )
        }
        const values = {
            ...resource,
            parent: resource.parent,
            extensionsEnabled
        }
        const workflow = resource.workflow ? workflows.find(w => w.uuid === resource.workflow) : null
        const extensionOptions = extensionTypes.map(({label, type}) => {
            if (type === 'App\\DataStorage\\Extension\\Workflow\\State') {
                return {
                    label: <div>{label}{workflow &&
                        <Link className={'ml-2'}
                              to={'/app/configuration/workflows/' + workflow.id}>[{workflow.label}]</Link>}</div>,
                    value: type
                }
            }
            return {label, value: type}
        })
        const masterClassOptions = masterClasses.map(masterClass => ({label: masterClass, value: masterClass}))

        return (
            <>
                <Form
                    initialValues={values}
                    onValuesChange={onValuesChange}
                    ref={formRef}
                    hidden={hidden}
                >
                    <Form.Item
                        label={"Titulek"}
                        name={"label"}
                        help={"Čitelný titulek jak bude typ obsah prezentován."}
                        rules={[{required: true}]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label={"Strojový název"}
                        name={"name"}
                        extra={"Jak bude pojmenována třída. Pouze malá základní abeceda a podtržítka."}
                        rules={[{required: true, message: 'Strojový název je povinný'}, {
                            pattern: /^[a-z_]+$/,
                            message: 'Pouze malá základní abeceda a podtržítka'
                        }, () => ({
                            validator: (_, value: string) => {
                                return new Promise<void>((resolve, reject) => {
                                    if (contentTypes.find(c => c.name === value && c.uuid !== resource.uuid)){
                                        reject(new Error('Typ obsahu s tímto názvem již existuje'))
                                    }
                                    resolve()
                                })
                            },
                        })]}
                    >
                        <Input onBlur={() => this.populateCollectionAndUrl()}/>
                    </Form.Item>
                    <Form.Item
                        hasFeedback={true}
                        label={"Kolekce"}
                        name={"collection"}
                        rules={[{required: true, message: 'Kolekce je povinná'}, {
                            pattern: /^[a-z-]+$/,
                            message: 'Pouze malá základní abeceda a pomlčky.'
                        },
                            () => ({
                                validator: (_, value: string) => {
                                    clearTimeout(this.getDebounce())
                                    return new Promise<void>((resolve, reject) => {
                                        this.setDebounce(setTimeout(() => {
                                            if (value && (originalCollection !== value) && value.match(/^[a-z-]+$/)) {
                                                return ContentTypesService.checkUniqueCollection(value)
                                                    .then((result: boolean) => {
                                                            result ? resolve() : reject(new Error('Tento název nelze použít, trasa s tímto názvem již existuje'))
                                                        }
                                                    )
                                            }
                                            resolve()
                                        }, 800))
                                    })
                                },
                            })]}
                        extra={'Zadejte název kolekce pro REST API. Pouze malá základní abeceda a pomlčky.'}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label={"Popis"}
                        name={"description"}
                        rules={[{required: false}]}
                    >
                        <Textarea/>
                    </Form.Item>
                    <Form.Item
                        label={"Ikona"}
                        name={"icon"}
                        rules={[{required: false}]}
                    >
                        <IconPicker/>
                    </Form.Item>
                    <Form.Item
                        label={"Rozšíření"}
                        name={"extensionsEnabled"}
                        rules={[{required: false}]}
                    >
                        <Checkbox.Group
                            onChange={this.onExtensionsChange}
                            options={extensionOptions}
                        />
                    </Form.Item>
                    <Form.Item
                        name={"parent"}
                        label={"Nadřazený typ obsahu"}
                    >
                        <Select
                            onChange={(value: string) => this.onParentChange(value)}
                            options={this.buildContentTypeOptions()}
                        />
                    </Form.Item>
                    <Form.Item name={"revisionHistory"} label={"Historie revizí"} valuePropName={'checked'}
                               help={"Zaznamenávání všech změn provedených v záznamu"}>
                        <Switch/>
                    </Form.Item>
                    <Form.Item
                        name={"masterClass"}
                        label={"Řídící třída"}
                        help={"Vazba na řídící třídu systému"}
                    >
                        <Select
                            allowClear={true}
                            options={masterClassOptions}
                        />
                    </Form.Item>
                    {resource.routes.length === 0 && (
                        <div>
                            <Form.Item
                                name={"_createBasicRoutes"}
                                label={"Založit základní URL"}
                                valuePropName={'checked'}
                            >
                                <Checkbox onChange={this.enableBasicRoutesCreation}/>
                            </Form.Item>
                            <Form.Item
                                name={"_basicRouteName"}
                                label={"Cesta"}
                                help={"Kořenová adresa URL cest. Pouze malá základní abeceda a pomlčky"}
                                rules={[{required: createBasicRoutes}, {pattern: /^[a-z-]+$/}]}
                            >
                                <Input/>
                            </Form.Item>
                        </div>
                    )}

                </Form>
            </>
        )
    }
}

const mapStateToProps = (state: IAppState) => {
    return {
        workflows: state.setup.workflows,
        contentTypes: state.setup.contentTypes
    }
}

export default connect(mapStateToProps)(ContentTypeProperties)